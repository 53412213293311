import React, { Component } from 'react';
import { BsListCheck, BsBookmark, BsEye } from 'react-icons/bs'
import { RiGlobalLine, RiDirectionLine } from 'react-icons/ri'
import { BiPhoneCall } from 'react-icons/bi'
import { AiOutlineMessage } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { AccordionItemPanel } from 'react-accessible-accordion';
import LoginBox from '../../other/account/LoginBox';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { ApiUrl } from '../../../config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import RButton from 'react-bootstrap/Button'
import useToken, { uToken } from '../../../useToken';
class WidgetStaticsInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoginModal: false,
            RequestModal: false,
            lists: [
                {
                    icon: <RiGlobalLine />,
                    text: this.props.business.website,
                    url: this.props.business.website
                },
                {
                    icon: <BiPhoneCall />,
                    text: this.props.business.phone_number,
                    url: '#'
                },
                {
                    icon: <RiDirectionLine />,
                    text: 'Get Directions',
                    url: "https://www.google.com/maps/search/?api=1&query=" + this.props.business.business_lat + ',' + this.props.business.business_long,
                },
                {
                    icon: <AiOutlineMessage />,
                    text: 'Message the Business',
                    url: '/#'
                }
            ]
        }
        this.onHide = this.onHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handler1 = this.handler1.bind(this);
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
                RequestModal: true,
            })
        }
    }
    handleSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.business_id = this.props.business.business_id;
            axios.post(ApiUrl + 'sendBusinessMessage', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({ RequestModal: false, LoginModal: false });
                        self.props.loginActivity(true);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    onHide() {
        this.setState({ RequestModal: false, LoginModal: false });
        this.props.loginActivity(true);

    }

    render() {
        return (
            <>
                <ToastContainer />
                <div className="sidebar-widget">
                    <div className="info-list static-info padding-top-0px">
                        <ul>
                            {
                                this.props.business.website && (
                                    <li className="justify-content-between info-website" key={0}>
                                        {
                                            /(http(s?)):\/\//i.test(this.props.business.website) ?

                                                <><a href={`${this.props.business.website}`} className="custom-color-326D62" target="_blank" > {this.props.business.website.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '')}</a> <i className="la"><RiGlobalLine /></i></>
                                                :
                                                <><a href={`https://${this.props.business.website}`} className="custom-color-326D62" target="_blank" > {this.props.business.website.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '')}</a> <i className="la"><RiGlobalLine /></i></>
                                        }
                                    </li>
                                )
                            }
                            <li className="justify-content-between" key={1}>
                                <a href={`tel:${this.props.business.phone_number}`}> {this.props.business.phone_number}</a> <i className="la"><BiPhoneCall /></i>
                            </li>
                            {this.props.business.online_business != 'No' ?
                                <li className="justify-content-between b-address" key={2}>

                                    <Link className="custom-color-326D62" to={`/maps/${this.props.business.business_slug}`}>Get Directions</Link> <i className="la"><RiDirectionLine /></i>
                                    <address>{this.props.business.business_address}</address>

                                    {/* <a target="_blank" className="custom-color-326D62" href={"https://www.google.com/maps/search/?api=1&query=" + this.props.business.business_lat + ',' + this.props.business.business_long}> Get Directions</a> <i className="la"><RiDirectionLine /></i> */}
                                </li>
                                :
                                <li className="justify-content-between b-address" key={2}>

                                    <Link className="custom-color-326D62" to={`#`}>Online business</Link> <i className="la"><RiDirectionLine /></i>

                                </li>

                            }
                            {/* <li className="justify-content-between" key={3}>
                                <a> Message the Business </a> <i className="la"><AiOutlineMessage /></i>
                            </li> */}
                            <li className="justify-content-between" key={4}>
                                {(!uToken()) ?
                                    <>
                                        <RButton onClick={() => this.setState({ LoginModal: true })}
                                            type="button" className="remove-btn-bg custom-color-326D62" variant="none">
                                            Message the Business
                                        </RButton>
                                        <i className="la"><AiOutlineMessage /></i>

                                    </>
                                    :
                                    <>
                                        <RButton onClick={() => this.setState({ RequestModal: true })}
                                            type="button" className="remove-btn-bg custom-color-326D62" variant="none">
                                            Message the Business
                                        </RButton>
                                        <i className="la"><AiOutlineMessage /></i>
                                    </>
                                }
                            </li>
                            {/* {this.state.lists.map((item, index) => {
                                return (
                                    <li className="justify-content-between" key={index}>
                                        <a> {item.text}</a> <i className="la">{item.icon}</i>
                                    </li>
                                )
                            })} */}
                        </ul>
                    </div>
                </div>

                <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Send Message </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            {/* <h5>Login required to perform this action </h5> */}
                            <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                        </>
                    </Modal.Body>
                </Modal>

                <Modal className="send-message-business" show={this.state.RequestModal} onHide={this.onHide} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Send a Message </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <AvForm onSubmit={this.handleSubmit}>
                                <AvGroup>
                                    <label><b>To:</b> {this.props.business.business_name} </label>
                                    <p className="messgae-share">Share a few details so we can get you in touch with the business</p>
                                    <AvInput
                                        type="textarea"
                                        placeholder="Can you tell me more about your services?"
                                        className="form-control"
                                        name="message"
                                        required />
                                    <AvFeedback>Required Field *</AvFeedback>
                                </AvGroup>
                                <Modal.Footer>
                                    {/* <RButton variant="secondary" onClick={() => this.setState({ RequestModal: false, LoginModal: false })}>Close</RButton> */}
                                    <RButton type="submit" variant="primary" >Send a Message</RButton>
                                    <p className="notes display-inline">By sending this request, you agree that katika can email you when businesses reply.</p>
                                </Modal.Footer>
                            </AvForm>
                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default WidgetStaticsInfo;