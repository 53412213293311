import React, { Component } from 'react';
import { FaQuoteLeft } from 'react-icons/fa'
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import SlideFour from '../../assets/images/SlideFour.png';
import SlideFive from '../../assets/images/SlideFive.png';
import SlideSix from '../../assets/images/SlideSix.png';
import SlideSeven from '../../assets/images/SlideSeven.png';
import SlideEight from '../../assets/images/SlideEight.png';
import SlideNine from '../../assets/images/SlideNine.png';


class Testimonial extends Component {
    testimonialState = {
        
        sliders: [
            {
                id: 1,

                title: 'Richard Doe',
                heading: 'The Power of Search',
                content: 'We know the only way to watch black businesses grow is to connect them to consumers. Katika makes it easy for consumers to find your business. Our AI-powered search engine provides suggestions to consumers looking for businesses or services similar to yours.',
                SlideImg: SlideSeven,
            },
            {
                id: 2,

                title: 'Denwen Evil',
                heading: 'Bring Your Favorite Apps Along',
                content: 'Katika offers easy connection to third-party-apps allowing you to manage all of your social media & utility accounts in one place.<p> You’ll be able to scale your business through already established, tried and true efforts. </p>       ',
                SlideImg: SlideNine,
            },
            {
                id: 3,

                title: 'Collis Pong',
                heading: 'Message with Customers',
                content: "Katika empowers business owners to engage with customers by providing the ability to send direct messages between you and your customers if they have questions about your products or services.",
                SlideImg: SlideEight,
            }
        ]
    }
    render() {
        return (
            <>
                <Slider className="places-carousel testimonial-carousel" dots={true} infinite={true} slidesToScroll={1} slidesToShow={1} centerMode={false} centerPadding="50px" arrows={true}>
                    {this.testimonialState.sliders.map(slider => {
                        return (
                            <Row className="hello" key={slider.id+1}>
                                <Col lg={6} sm={6}>
                                    <div className="testimonial-item" key={slider.id}>
                                        <div className="testi-comment">
                                            <h1>
                                                {slider.heading}
                                           </h1>
                                           <p className="testi__desc" dangerouslySetInnerHTML={{ __html: slider.content }}>
                                            </p>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg={6} sm={6}>
                                <div className="sign-up-image"><img key={0} src={slider.SlideImg} alt="testimonial" className="random-img" /></div>
                                </Col>
                            </Row>

                        )
                    })}
                </Slider>
            </>
        );
    }
}

export default Testimonial;