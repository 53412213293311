import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';

class Dashboard extends Component {
    constructor() {
        super();
        this.state = { checked: false, reason: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.DeactivateAccount = this.DeactivateAccount.bind(this);

    }

    handleChange(checked) {
        this.setState({ checked });
    }

    handleSubmit(event, errors, values) {
        if (errors.length < 1) {
            axios.post(ApiUrl + 'changePassword', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    DeactivateAccount() {
        let reason = this.state.reason;
        if (reason != '') {
            console.log(reason)
            axios.post(ApiUrl + 'deactiveBusiness', { 'deactivate_reason': reason }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.error('Please select reason');
        }
    }


    render() {
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area security">
                                <div className="y-page-link security-area">
                                    <h4>Security</h4>
                                    <div className="change-password">
                                        <h5>Change  Password</h5>
                                        <div className="form-area">
                                            <AvForm onSubmit={this.handleSubmit}>
                                                <div className="password">
                                                    <AvGroup>
                                                        <Row>
                                                            <Col lg={4} md={4}>
                                                                <label>Current Password</label>
                                                            </Col>
                                                            <Col lg={8} md={8}>
                                                                <AvInput
                                                                    type="password"
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="old_password"
                                                                    minLength="8"
                                                                    required />
                                                                <AvFeedback>This field is invalid</AvFeedback>
                                                            </Col>
                                                        </Row>
                                                    </AvGroup>
                                                </div>
                                                <div className="password">
                                                    <AvGroup>
                                                        <Row>
                                                            <Col lg={4} md={4}>
                                                                <label>New Password</label>
                                                            </Col>
                                                            <Col lg={8} md={8}>
                                                                <AvInput
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="new_password"
                                                                    type="password"
                                                                    minLength="8"
                                                                    required />
                                                                <AvFeedback>This field is invalid</AvFeedback>
                                                            </Col>
                                                        </Row>
                                                    </AvGroup>
                                                </div>
                                                <div className="password">
                                                    <AvGroup>
                                                        <Row>
                                                            <Col lg={4} md={4}>
                                                                <label>Confirm Password</label>
                                                            </Col>
                                                            <Col lg={8} md={8}>
                                                                <AvInput
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="confirm_password"
                                                                    type="password"
                                                                    minLength="8"
                                                                    required />
                                                                <AvFeedback>This field is invalid *</AvFeedback>
                                                            </Col>
                                                        </Row>
                                                    </AvGroup>
                                                </div>
                                                <div className="password">
                                                    <Row>
                                                        <Col lg={4} md={4}>
                                                            <label></label>
                                                        </Col>
                                                        <Col lg={8} md={8}>
                                                            <label lassName="Instructions">8 characters or longer. Combine upper and lowercase letters and numbers.</label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="password save-button">
                                                    <Row>
                                                        <Col lg={4} md={4}>
                                                            <label></label>
                                                        </Col>
                                                        <Col lg={8} md={8}>
                                                            <Button type="submit" className="save-changes" variant="primary" style={{
                                                                float: "right",
                                                                backgroundColor: "#5CD3B7",
                                                                borderColor: "#5CD3B7",
                                                            }}
                                                            >Save Changes</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </AvForm>
                                            {/* <div className="password-description">
                                                <Row>
                                                    <Col lg={4} md={4}>
                                                        <label>PHONE VERIFICATION</label>
                                                    </Col>
                                                    <Col lg={8} md={8}>
                                                        <p>Your phone is verified with Katika. Click Edit to change your phone number</p>
                                                    </Col>
                                                </Row>
                                            </div> */}
                                            {/* <div className="password-description">
                                                <Row>
                                                    <Col lg={4} md={4}>
                                                        <label>SECURITY QUESTION</label>
                                                    </Col>
                                                    <Col lg={8} md={8}>
                                                        <p>By creating a security question, you will add an additional layer of protection for your revenue withdrawals and for changing your password.</p>
                                                    </Col>
                                                </Row>
                                            </div> */}
                                            {/* <div className="password-description">
                                                <Row>
                                                    <Col lg={4} md={4}>
                                                        <label>TWO FACTOR AUTHENTICATION</label>
                                                        <label className="small">RECOMMENDED</label>
                                                    </Col>
                                                    <Col lg={8} md={8}>
                                                        <label>
                                                            <Switch onChange={this.handleChange} checked={this.state.checked} /></label>
                                                        <p>To help keep your account secure, we'll ask you to submit a code when using a new device to log in. We'll send the code via SMS, email, or Fiverr notification.</p>
                                                    </Col>
                                                </Row>
                                            </div> */}
                                            <div className="padding-bottom-80px"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="dashboard-area deactive-account">
                                <div className="y-page-link">
                                    <div className="RowOne">
                                        <div className="top-area-left">
                                            <h5>Account deactivation</h5>
                                        </div>
                                        <div className="top-area-right">
                                            <h4>What happens when you deactivate your account?</h4>
                                            <p>Your business page and photos won’t be shown on Katika anymore.</p>
                                            <p>Messages in PRO accounts will be deleted.</p>
                                            {/* <p>Messages in PRO accounts will be deleted.</p> */}
                                        </div>
                                    </div>
                                    <div className="RowTwo">
                                        <div className="top-area-left">
                                            <h5>I’m leaving because...</h5>
                                        </div>
                                        <div className="top-area-right">

                                            <select onChange={(e) => this.setState({ reason: e.target.value })}>
                                                <option value="">Select reason</option>
                                                <option value="Katika was too confusing to use.">Katika was too confusing to use.</option>
                                                <option value="I don’t think this platform can help my business.">I don’t think this platform can help my business.</option>
                                                <option value="My business isn’t ready for an online presence.">My business isn’t ready for an online presence.</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="margin-top-25px deactivate">
                                        <button disabled={this.state.reason == '' ? true : false} onClick={() => this.DeactivateAccount()} className="btn btn-default btn-lg">Deactivate Account</button>
                                    </div>
                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;