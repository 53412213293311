import React, { Component } from 'react';
import { MdStar, MdClose, MdStarHalf } from 'react-icons/md'

import { BsPencil, BsBookmark } from 'react-icons/bs'
import { AiOutlineFlag } from 'react-icons/ai'
import { FaFacebookF, FaTwitter, FaInstagram, FaTumblr, FaSnapchatGhost, FaGooglePlusG, FaPinterest, FaVk, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { RiSendPlane2Line, RiArrowLeftSLine, RiCheckLine } from 'react-icons/ri'

import { Link } from "react-router-dom";
import $ from 'jquery';
import Info from '../../assets/images/info.png'
import ListingGallery from "../../components/sliders/ListingGallery";
import Rater from 'react-rater'
import Slider from "react-slick";
import { RiCloseLine } from 'react-icons/ri'
import { Row, Col } from "react-bootstrap";
import PrettyRating from "pretty-rating-react";
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
class ListingDetailsBreadcrumb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            business: this.props.state.business,
            title: "Booker's Restaurant",
            rating: '3',
            logo: this.props.state.logo,
            images: this.props.state.images,
            /*stitle: '101 East Parkview Road, New York',*/
            shareLinks: [
                {
                    icon: <FaFacebookF />,
                    title: 'facebook',
                    url: 'https://facebook.com'
                },
                {
                    icon: <FaTwitter />,
                    title: 'twitter',
                    url: 'https://twitter.com'
                },
                {
                    icon: <FaInstagram />,
                    title: 'twitter',
                    url: 'https://instagram.com'
                },
                {
                    icon: <FaTumblr />,
                    title: 'tumblr',
                    url: 'https://tumblr.com'
                },
                {
                    icon: <FaSnapchatGhost />,
                    title: 'snapchat',
                    url: 'https://snapchat.com'
                },
                {
                    icon: <FaGooglePlusG />,
                    title: 'google plus',
                    url: 'https://plus.google.com'
                },
                {
                    icon: <FaPinterest />,
                    title: 'pinterest',
                    url: 'https://pinterest.com'
                },
                {
                    icon: <FaVk />,
                    title: 'vkontakte',
                    url: 'https://vkontakte.com'
                },
                {
                    icon: <FaLinkedinIn />,
                    title: 'linkedin',
                    url: 'https://linkedin.com'
                },
                {
                    icon: <FaYoutube />,
                    title: 'youtube',
                    url: 'https://youtube.com'
                }
            ],
            image_click: "",
            click: false,
            props_data: this.props.state.props_data
        }

        this.toggleChildMenu = this.toggleChildMenu.bind(this);
    }

    toggleChildMenu(e) {
        this.setState({
            image_click: e,
            click: true
        });
    }



    componentDidMount() {
        $(document).on('click', '.report-list-items .report-modal-btn', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".report-modal-box").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.report-modal-box .modal-bg, .report-modal-box .modal-top .close', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".report-modal-box").removeClass('show')
            e.preventDefault();
        })

        $(document).on('click', '.delete-account-info', function (e) {

            // $('body').addClass('modal-open').css({ paddingRight: '17px' });
            // $(".account-delete-modal").addClass('show')
            // e.preventDefault();
        })

        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            // $('body').removeClass('modal-open').css({ paddingRight: '0' });
            // $(".account-delete-modal").removeClass('show')
            // e.preventDefault();
        })
    }
    render() {

        return (
            // console.log(this.state.business, 'this.state.business'),
            <>
                <section className="breadcrumb-area listing-detail-breadcrumb detail-gallery">

                    <div className="breadcrumb-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 position-relative">
                                    <div className="breadcrumb-content">
                                        <h2 className="breadcrumb__title">
                                            {/* <PrettyRating value={3.5} colors={colors.star} iconsNumber={10} icons={icons.star} /> */}

                                            {this.state.business.business_name}
                                        </h2>
                                        {/*<p className="breadcrumb__desc">
                                            <span className="la d-inline-block"><GiPositionMarker /></span> {this.state.stitle}
                                        </p>*/}
                                        <div className="BookMarkIcon pull-right"><a href="#"><BsBookmark /></a></div>
                                        <div className="review-rating-summary ratings">
                                            <div className="review-rating-summary-inner">

                                                <div className="stats-average__rating">
                                                    <div className="rating-rating d-flex rating-detail landing">
                                                        <Rating
                                                            placeholderRating={this.state.business.rating}
                                                            readonly={true}
                                                            stop={5}
                                                            emptySymbol={<img src={Empty} className="icon" />}
                                                            placeholderSymbol={<img src={Fill} className="icon" />}
                                                            fullSymbol={<img src={Fill} className="icon" />}
                                                        />
                                                        {/* <Rater total={5} rating={this.state.business.rating} interactive={false} /> */}
                                                    </div>
                                                    <p className="stats-average__rating-rating"> {this.state.business.review} reviews</p>
                                                </div>
                                            </div>
                                            {/* <div className="course-rating-text">
                                                <a>Details</a>
                                            </div> */}


                                        </div>
                                        <div className="PriceBox">
                                            <div className="ProPrice">
                                                {(this.state.business.amount_value > 0 && this.state.business.amount_value <= 1)
                                                    ? "$"
                                                    : (this.state.business.amount_value >= 2 && this.state.business.amount_value < 3)
                                                        ? "$$"
                                                        : (this.state.business.amount_value >= 3 && this.state.business.amount_value < 4)
                                                            ? "$$$"
                                                            : (this.state.business.amount_value >= 4)
                                                                ? "$$$$"
                                                                :
                                                                ""
                                                }
                                            </div>

                                            <span className="PriceDot"></span>
                                            <span className="DishName">{this.state.business.business_sub_category}</span>

                                        </div>
                                        <div className="OpenTime">
                                            <span><b>{this.state.business.day_status}</b></span>
                                            <span className="PriceDot"></span>
                                            <p>{this.state.business.s_time} - {this.state.business.e_time}</p>
                                        </div>
                                        <div className="Hours">
                                            <p>Hours updated {this.state.business.last_update}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bread-svg">
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                            <path d="M-4.22,89.30 C280.19,26.14 324.21,125.81 511.00,41.94 L500.00,150.00 L0.00,150.00 Z" />
                        </svg>
                    </div>
                </section>

                <div className="modal-form">
                    <div className="modal fade report-modal-box bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-top">
                                    <button type="button" className="close close-arrow" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" className="mb-0"><MdClose /></span>
                                    </button>
                                    <h4 className="modal-title"><span className="mb-0"><AiOutlineFlag /></span> Report this Listing</h4>
                                </div>
                                <div className="contact-form-action">
                                    <form method="post">
                                        <div className="msg-box">
                                            <label className="label-text">Write Message</label>
                                            <div className="form-group">
                                                <i className="form-icon"><BsPencil /></i>
                                                <textarea className="message-control form-control" name="message" placeholder="What's wrong with this listing?" required></textarea>
                                            </div>
                                        </div>
                                        <div className="btn-box text-right">
                                            <button type="submit" className="theme-btn button-success border-0"><i><RiSendPlane2Line /></i> Send message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content p-4">
                                <div className="modal-top border-0 mb-4 p-0">
                                    <div className="alert-content">
                                        {/* <ListingGallery images={this.state.images} image_click={this.state.image_click} /> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </>
        );
    }
}

export default ListingDetailsBreadcrumb;