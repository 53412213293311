import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { buyerUrl, communityUrl } from '../../../config';

class FooterQuickLinkWidget extends Component {
    state = {
        title: 'Get to Know Us',
        links: [
            {
                path: '/about-us',
                text: 'About Katika'
            },
            {
                path: '/labs',
                text: 'Katika Labs'
            },
            {
                path: '/partners',
                text: 'Partners'
            },

        ]
    }
    render() {
        return (
            <>
                <div className="col-lg-3 col-md-3 col-sm-3 column-td-6">
                    <div className="footer-item">
                        <h4 className="footer__title">
                            {this.state.title}
                        </h4>
                        <ul className="list-items">
                            <li key={0}>
                                <a href={`${buyerUrl}/about-us`} >About Katika</a>
                            </li>
                            <li key={1}>
                                <a href={`${buyerUrl}/labs`} >Katika Labs</a>
                            </li>
                            <li key={2}>
                                <a href={`${buyerUrl}/partners`} >Partners</a>
                            </li>
                            <li key={3}>
                                <a href={`${communityUrl}`} >Katika Community</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterQuickLinkWidget;