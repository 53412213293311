import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { BiCheck, BiEdit } from 'react-icons/bi';
import SingleFileUpload from "../../components/common/SingleFileUpload"
import Loader from 'react-loader';
import Modal from 'react-bootstrap/Modal'


class CreatePost extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            photo: '',
            loader: true,
            business: {},
            totalNeighborhood: 0,
            totalNeighbors: 0,
            PostTypes: [],
            totalNeighbors: 0,
            totalNeighborhood: 0,
            zipcode: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileSet = this.fileSet.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.child = React.createRef()
    }


    fileSet = (files) => {
        if (files) {
            this.setState({
                file: files,
                photo: 'set'
            })
        } else {
            this.setState({
                file: null,
                photo: ''
            })
        }
    }

    handleSubmit(event, values) {
        if(this.state.zipcode == 0 || this.state.zipcode == null || this.state.zipcode == ''){
            toast.error('Select target zipcode');
            return;
        }
        this.setState({
            loader: false,
        });
        var formData = new FormData();
        formData.append("type", values.post_type);
        formData.append("title", values.post_title);
        formData.append("description", values.message);
        formData.append("link", values.post_link);
        formData.append("link_text", values.link_text);
        formData.append("zip_code", this.state.zipcode);
        formData.append("business_id", this.state.business.business_id);
        formData.append("device", 'Web');
        this.state.file.map((item) => {
            formData.append('photo[]', item);
            formData.append('photo_type[]', item.type);
        });

        var self = this;
        // if (errors.length < 1) {
        axios.post(ApiUrl + 'addBusinessPost', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        file: null,
                        loader: true,
                        photo: ''
                    })
                    self.form && self.form.reset();
                } else {
                    toast.error(res.data.message);
                    self.setState({
                        file: null,
                        loader: true,
                        photo: ''
                    })
                }
            })
            .catch(function (error) {
                self.setState({
                    file: null,
                    loader: true
                })
                console.log(error);
            });
        // }
    }

    componentDidMount() {
        this.getTargetAudience();
    }
    getTargetAudience = () => {
        var self = this;
        axios.post(ApiUrl + 'getTargetAudience', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        business: res.data.data,
                        totalNeighborhood: res.data.totalNeighborhood,
                        totalNeighbors: res.data.totalNeighbors,
                        PostTypes: res.data.getPostTypes,
                        zipcode: res.data.zipcode
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit1 = async (event, values) => {
        let self = this;
        axios.post(ApiUrl + 'getTargetAudience', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        business: res.data.data,
                        totalNeighborhood: res.data.totalNeighborhood,
                        totalNeighbors: res.data.totalNeighbors,
                        PostTypes: res.data.getPostTypes,
                        zipcode: res.data.zipcode,
                        WarningAlert: false
                    })
                    toast.success(res.data.message);


                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    resetForm = async () => {
        var self = this;
        this.setState({
            loader: false
        }, () => {
            setTimeout(() => {
                console.log('helllo')
                self.setState({
                    loader: true
                });
            }, 300);
        })
        this.form && this.form.reset();
        this.setState({
            file: null,
            photo: '',
        })
    }

    render() {
        return (
            <main className="dashboard-page">
                <Loader loaded={this.state.loader}></Loader>
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area create-post">
                                <div className="y-page-link ">
                                    <h4>Create New Post</h4>
                                    <AvForm encType="multipart/form-data" ref={c => (this.form = c)} onValidSubmit={this.handleSubmit}>

                                        <Row>
                                            <Col lg={7}>
                                                <div className="content-area pt-4 personal target checkout pt-5">
                                                    <h3>Target Audience</h3>
                                                    <ul>
                                                        <li><span className="check pr-1"><BiCheck /> </span>Target Zipcode:  <span className="zip-code-">{this.state.zipcode}</span>
                                                            <span className='pl-5' onClick={() => this.setState({
                                                                WarningAlert: !this.state.WarningAlert
                                                            })}><BiEdit /></span></li>

                                                        <li><span className="check pr-1"><BiCheck /></span>{this.state.totalNeighborhood} neighborhoods</li>
                                                        <li><span className="check pr-1"><BiCheck /></span>{this.state.totalNeighbors} Neighbors</li>

                                                    </ul>

                                                </div>
                                                <AvGroup>
                                                    <label>Post Type</label>
                                                    <AvInput
                                                        type="select"
                                                        placeholder=""
                                                        className="form-control"
                                                        name="post_type"
                                                        required
                                                    >
                                                        <option value={''}>Select Type</option>
                                                        {this.state.PostTypes.map((type, i) => {
                                                            return (

                                                                <option key={i} value={type.id}>{type.title}</option>
                                                            )
                                                        })}
                                                    </AvInput>
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>

                                                <AvGroup>
                                                    <label>Post Title</label>
                                                    <AvInput
                                                        placeholder=""
                                                        className="form-control"
                                                        name="post_title"
                                                        required
                                                    />
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>

                                                <AvGroup>
                                                    <label>Enter your message</label>
                                                    <AvInput
                                                        type="textarea"
                                                        placeholder=""
                                                        className="form-control"
                                                        name="message"
                                                        rows={6}
                                                        maxLength={280}
                                                        required
                                                    />
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>

                                                <AvGroup>
                                                    <label>Add a link (optional)</label>
                                                    <AvInput
                                                        type="url"
                                                        placeholder=""
                                                        className="form-control"
                                                        name="post_link"
                                                    />
                                                    <AvFeedback> Enter a valid url *</AvFeedback>
                                                </AvGroup>


                                                <AvGroup>
                                                    <label>Select button (optional)</label>
                                                    <AvInput
                                                        type="select"
                                                        placeholder=""
                                                        className="form-control"
                                                        name="link_text"
                                                    >
                                                        <option value={''}>Select Button</option>
                                                        <option value="Learn More">Learn More</option>
                                                        <option value="RSVP Now">RSVP Now</option>
                                                        <option value="Shop Now">Shop Now</option>
                                                        <option value="Buy Now">Buy Now</option>
                                                        <option value="Watch Video">Watch Video</option>
                                                        <option value="Contact Us">Contact Us</option>
                                                        <option value="Sign Up">Sign Up</option>
                                                    </AvInput>
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                            <Col lg={5}>
                                                <div className="personal types margin-top-100px">
                                                    {
                                                        this.state.PostTypes.map((type, i) => {
                                                            return (
                                                                <div key={i} className='post-type'>
                                                                    <span className={type.color_code}></span>
                                                                    <p><b>{type.title}</b> {type.description}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <AvGroup>
                                                    <label>Add photos or videos <p> Recommended dimensions: (500px500px)</p></label>
                                                    {this.state.loader && (<SingleFileUpload fileSet={this.fileSet} ref="child" />)}
                                                    <AvInput type='hidden' name="selectfile" required value={this.state.photo} />
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                        <div className="post-action save-button">
                                            <Button type="submit" className="save-changes" variant="primary">Post</Button>
                                            <Button onClick={this.resetForm} type="reset" className="save-changes cancel" variant="none">Cancel</Button>
                                        </div>

                                        <div className="padding-bottom-80px"></div>
                                    </AvForm>
                                </div>


                                <div className="modal-form text-center warning-list ">
                                    <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                                        <Modal show={this.state.WarningAlert} onHide={this.onHide} size="md" backdrop="static">
                                            <div className="modal-content warning-list  p-4">
                                                <AvForm onValidSubmit={this.handleSubmit1}>
                                                    <div className="modal-top border-0 mb-4 p-0">
                                                        <div className="alert-content">
                                                            <div className="form-area">
                                                                <div className="tabs-content">
                                                                    <AvGroup>
                                                                        <label>Zipcode</label>
                                                                        <AvInput
                                                                            placeholder="Enter target Zipcode"
                                                                            className="form-control mt-3"
                                                                            name="zip_code"
                                                                            required
                                                                            type="number"
                                                                            validate={{ pattern: { value: /(^\d{5}$)|(^\d{5}-\d{4}$)/ } }}
                                                                        />
                                                                        <AvFeedback>Invalid Input*</AvFeedback>
                                                                    </AvGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-box">
                                                        <button onClick={() => this.setState({ WarningAlert: false })} type="button" className="border-0 btn btn-sm mr-1" data-dismiss="modal">
                                                            Close
                                                        </button>
                                                        <button type="submit" className="border-0 button-success mr-1">
                                                            Submit
                                                        </button>

                                                    </div>
                                                </AvForm>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default CreatePost;