import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { RiCloseLine } from 'react-icons/ri'
import { Row, Col } from "react-bootstrap";



import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import g_Close from "../../assets/images/g_Close.png"
import katika_K from "../../assets/images/katika_K.png"


class PhotoGallery extends React.Component {
    constructor() {
        super()
        this.state = {
            value: this.props ? this.props.image_click : 0,
            images: [],
            thumbnailsImg: [],
            business: "",
            count: 0,
            currentImage: this.props ? this.props.current : {
                title: '',
                date: '',
                user_image: '',
                fname: '',
                lname: '',
                friends: '',
                total_reviews: '',
            },
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.setState({ value });
        if (this.state.images[value]) {
            this.setState({
                currentImage: this.state.images[value]
            });
        }

    }

    componentDidMount() {
        const imagess = [];
        this.props.images.map((slide, i) => {
            imagess.push(<img key={i + 1} src={slide.image} alt="Gallery" />,)
        });
        if (imagess) {
            this.setState({
                value: this.props.image_click,
                images: this.props.images,
                thumbnailsImg: imagess,
                count: this.props.images.length,
            });
            if (this.props.images[this.props.image_click]) {
                this.setState({
                    currentImage: this.props.images[this.props.image_click]
                });
            }
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        const imagess = [];
        nextProps.images.map((slide, i) => {
            imagess.push(<img key={i + 1} src={slide.image} alt="Gallery" />,)
        });
        if (imagess) {
            this.setState({
                value: nextProps.image_click,
                images: nextProps.images,
                thumbnailsImg: imagess,
                business: nextProps.business,
                count: nextProps.images.length,
            });

            if (nextProps.images[nextProps.image_click]) {
                this.setState({
                    currentImage: nextProps.images[nextProps.image_click]
                });
            }
        }
        // this.onImage();
    }



    render() {
        return (
            <div>
                <input
                    type="hidden"
                    value={this.props.image_click ? this.props.image_click : this.state.value}
                    onChange={e => this.onChange(parseInt(e.target.value || 0))}
                />
                <div className="chat-header-gallery">
                    <div className="btn-box gallery-header">
                        <button type="button" className="theme-btn close-btn border-0 border-0 mr-1" data-dismiss="modal">
                            <img src={g_Close} alt="close" />
                        </button>
                    </div>
                    <div className="k-logo">
                        <img src={katika_K} alt="close" />
                    </div>
                </div>
                {/* <div className="chat-header-gallery">
                    <div className="gallery-header">
                        <button type="button" className="theme-btn close-btn border-0 border-0 mr-1" data-dismiss="modal">
                            <img src={g_Close} alt="close" />
                        </button>
                    </div>
                    <div className="gallery-header k-logo">
                        <img src={katika_K} alt="close" />
                    </div>
                </div> */}

                <Row>

                    <Col lg={12} md={12}>


                        <div className="craousel-slider">
                            <Carousel
                                arrows
                                value={this.state.value}
                                onChange={this.onChange}
                                animationSpeed={0}
                            >
                                {this.state.images.map((slide, i) => {
                                    return (
                                        <img key={i} className="img-example" src={slide.image} alt="Gallery" />
                                    )
                                })}
                            </Carousel>
                        </div>
                        <div className="" >


                            <div className="slider-dots chat-gallery-view">
                                <Dots
                                    value={this.state.value}
                                    onChange={this.onChange}
                                    thumbnails={this.state.thumbnailsImg}
                                />

                                {/* <div className="see-all-btn">
                                    <Link to="/">See All Photos</Link>
                                </div> */}
                            </div>
                        </div>
                    </Col>




                </Row>

            </div>
        );
    }
}

export default PhotoGallery;