import React, { Component } from 'react'
import SecondaryNavbar from './SecondaryNavbar'
import { Link } from "react-router-dom";


export default class GeneralHeader extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    
                                        {/* <div className="owner">
                                            <a href="http://localhost:3001/">Are you a Business Owner?</a>
                                        </div> */}
                                        {/* SecondaryNavbar */}
                                        <SecondaryNavbar />

                                        {/* Author Access */}
                                        {/* <HeaderAuthorAccess /> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
