import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import '../../assets/css/style2.css';
import Edit from "../../assets/images/bx_bx-edit.png";
import { Link } from 'react-router-dom';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
    AvForm, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckbox,
    AvCheckboxGroup
} from 'availity-reactstrap-validation';
import Select from 'react-select'
import { AiOutlinePicture, AiOutlineClose } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import { Collapse, CardBody, Card } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Complete from "../../assets/images/complete.png";
import Incomplete from "../../assets/images/incomplete.png";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaInfo } from 'react-icons/fa';

const popover = (title, body) => (
    <Popover id={`popover-positioned`}>
        <Popover.Title as="h3">{title}
        </Popover.Title>
        <Popover.Content>
            {body}
        </Popover.Content>
    </Popover>
);
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detailedInfo: {
                non_profit: "",
                woman_owned: "",
                llc: "",
                association: "",
                codes: "",
                naics: "",
            },
            externalData: false,
            covidInfo: {},
            businessAmenitie: [],
            safetyMeasure: [],
            bSMeasure: [],
            bSelectSafetyMeasure: [],
            bSafetyMeasure: [],
            openModal: false,
            editDetailedInfoModal: false,
            editCovidInfoModal: false,
            editPhotoModal: false,
            editSocialMediaModal: false,
            businessId: '',
            businessPercent: 0,
            images: [],
            foodTypes: [],
            socialmedia: [
                {
                    desc: 'facebook.com/thebestshop',
                    text: 'Facebook'
                },
                {
                    desc: 'instagram.com/thebestshop',
                    text: 'Instagram'
                },
                {
                    desc: 'twitter.com/thebestshop',
                    text: 'Twitter'
                }
            ],
            covid: [
                {
                    id: 1,
                    title: 'Sanitizing between customers'
                },
                {
                    id: 2,
                    title: 'Hand sanitizer provided'
                },
                {
                    id: 3,
                    title: 'Contactless payments'
                },
                {
                    id: 4,
                    title: 'Temperature checks'
                },
                {
                    id: 5,
                    title: 'Staff wears gloves'
                },
                {
                    id: 6,
                    title: 'Staff wears masks'
                },
                {
                    id: 7,
                    title: 'Masks required'
                },
                {
                    id: 8,
                    title: 'Social distancing enforced'
                },
                {
                    id: 9,
                    title: 'Limited capacity'
                }

            ],
            amenities: [
                {
                    active: true,
                    text: 'Accepts Credit Cards',
                },
                {
                    active: false,
                    text: 'Good for Kids',
                },
                {
                    active: false,
                    text: 'Wheelchair Accessible',
                },
                {
                    active: false,
                    text: 'By Appointment Only',
                },
                {
                    active: false,
                    text: 'Offers Military Discount',
                },
                {
                    active: false,
                    text: 'Free Wi-Fi',
                },
                {
                    active: false,
                    text: 'Street Parking',
                },
                {
                    active: false,
                    text: 'Bike Parking',
                },
                {
                    active: false,
                    text: 'Dogs Not Allowed',
                }

            ],
            ActiveKey: 0,
            editActiveKey: 0,
            amountValue: 0,
            complete_section: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onHide = this.onHide.bind(this);
        this.change = this.change.bind(this);
    }

    onHide() {
        this.setState({ openModal: false });
    }

    change(e) {
        var filter = [];
        if (e) {
            e.map(fil => {
                filter.push(fil.value);
            })
        }
        this.setState({ bSMeasure: filter, bSelectSafetyMeasure: e })
    }

    handleSubmit(event, errors, values) {
        this.setState({ errors, values });
        var self = this;
        if (errors.length < 1) {
            let url = '';
            values.business_id = this.state.businessId;
            if (this.state.editDetailedInfoModal) {
                url = 'updateBusinessDetailedInformation';
            }

            if (this.state.editFoodUrlModal) {
                url = 'updateBusinessFoodUrl';
            }

            if (this.state.editFoodTypeModal) {
                url = 'updateBusinessFoodType'
                values.business_food_type = values.food_type.toString()
            }


            if (this.state.editAmenitieModal) {
                url = 'updateBusinessAmenities'
                const array = [];
                for (const [key, value] of Object.entries(values)) {
                    if (value == 'Yes') {
                        array.push(key)
                    }
                }
                values = {};
                values.business_amenities = array.toString();
                values.business_id = this.state.businessId;
            }

            if (this.state.editCovidInfoModal) {
                url = 'updateBusinessCovidInformation';
                values.business_safety_measures = this.state.bSMeasure.toString();
            }

            if (this.state.editSocialMediaModal) {
                url = 'updateBusinessSocialMedia';
            }

            if (this.state.editPriceModal) {
                url = 'updateBusinessAmountValue';
            }

            axios.post(ApiUrl + url, values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({
                            editDetailedInfoModal: false,
                            editCovidInfoModal: false,
                            editSocialMediaModal: false,
                            editAmenitieModal: false,
                            editPriceModal: false,
                            editFoodUrlModal: false,
                            editFoodTypeModal: false
                        });
                        self.getBusinessDetail();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }


    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'userBusinessDetails', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                let filter = [];
                if (res.data.data.safety_measures) {
                    filter = res.data.data.safety_measures.split(',')
                }
                self.setState({
                    detailedInfo: res.data.data.detailed_info,
                    covidInfo: res.data.data.covid_info,
                    socialMedia: {
                        fb_url: res.data.data.fb_url,
                        insta_url: res.data.data.insta_url,
                        twitter_url: res.data.data.twitter_url,
                    },
                    business: res.data.data,
                    businessId: res.data.data.business_id,
                    amountValue: res.data.data.amount_value,
                    businessPercent: res.data.data.complete_percent,
                    businessImages: res.data.data.business_images,
                    bSelectSafetyMeasure: res.data.data.business_safety_measures,
                    bSMeasure: filter,
                    safetyMeasure: res.data.safety_measures,
                    bSafetyMeasure: res.data.data.business_safety_measures,
                    businessAmenitie: res.data.data.features,
                    complete_section: res.data.complete_section,
                    foodTypes: res.data.food_types,
                    externalData: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {

        return (

            <main className="dashboard-page details-info">
                <DashboardNavbar />
                {(this.state.externalData) ?
                    <Container>
                        <Row>
                            <Col lg={3} md={3} id="sidebar-wrapper">
                                <Sidebar percent={this.state.businessPercent} />
                            </Col>
                            <Col lg={9} md={9} id="page-content-wrapper">
                                <section className="dashboard-area business-detail">
                                    <div className="y-page-link">
                                        <h2>Detailed Information
                                            <div className="info-box-overlay">
                                                <OverlayTrigger
                                                    delay={200}
                                                    trigger={["hover", "hover"]}
                                                    placement={'auto'}
                                                    overlay={popover('Detailed Information',
                                                        'Select the every option from this section that applies to your business.')}
                                                >
                                                    <FaInfo />
                                                </OverlayTrigger>
                                            </div>
                                            {this.state.complete_section.includes('detailed information') ?
                                                <img src={Complete} alt="complete" />
                                                :
                                                <img src={Incomplete} alt="incomplete" />
                                            }
                                        </h2>
                                        <div className="edit-content">
                                            <button onClick={() => this.setState({ editDetailedInfoModal: !this.state.editDetailedInfoModal })} className="remove-btn-bg" >
                                                <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                            </button>
                                        </div>
                                        {(this.state.editDetailedInfoModal) ?
                                            <Collapse isOpen={this.state.editDetailedInfoModal}>
                                                <Card className="card-custom-1">
                                                    <CardBody >
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <div className="custom-legend">
                                                                <AvRadioGroup value={this.state.detailedInfo.non_profit} inline name="non_profit" label="Non-Profit" required errorMessage="Pick one!">
                                                                    <AvRadio customInput label="Yes" value="Yes" />
                                                                    <AvRadio customInput label="No" value="No" />
                                                                </AvRadioGroup>
                                                            </div>

                                                            <div className="custom-legend">
                                                                <AvRadioGroup value={this.state.detailedInfo.woman_owned} inline name="woman_owned" label="Woman-owned" required errorMessage="Pick one!">
                                                                    <AvRadio customInput label="Yes" value="Yes" />
                                                                    <AvRadio customInput label="No" value="No" />
                                                                </AvRadioGroup>
                                                            </div>

                                                            <div className="custom-legend">
                                                                <AvRadioGroup value={this.state.detailedInfo.llc} inline name="llc" label="LLC or Incorporated" required errorMessage="Pick one!">
                                                                    <AvRadio customInput label="Yes" value="Yes" />
                                                                    <AvRadio customInput label="No" value="No" />
                                                                </AvRadioGroup>
                                                            </div>

                                                            <div className="custom-legend">
                                                                <AvRadioGroup value={this.state.detailedInfo.association} inline name="association" label="Member of industry association" required errorMessage="Pick one!">
                                                                    <AvRadio customInput label="Yes" value="Yes" />
                                                                    <AvRadio customInput label="No" value="No" />
                                                                </AvRadioGroup>
                                                            </div>

                                                            <div className="custom-legend">
                                                                <AvRadioGroup value={this.state.detailedInfo.codes} inline name="codes" label="MBE Certified" required errorMessage="Pick one!">
                                                                    <AvRadio customInput label="Yes" value="Yes" />
                                                                    <AvRadio customInput label="No" value="No" />
                                                                </AvRadioGroup>
                                                                {/* <AvInput
                                                                    placeholder="Enter codes"
                                                                    className="form-control"
                                                                    name="codes"
                                                                    type="textarea"
                                                                    rows="6"
                                                                    value={this.state.detailedInfo.codes}
                                                                /> */}
                                                            </div>

                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="Enter NAICS"
                                                                    className="form-control"
                                                                    name='naics'
                                                                    value={this.state.detailedInfo.naics}
                                                                />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>



                                                            <div className="button-modal-footer">
                                                                <Button variant="secondary" onClick={() => this.setState({ editDetailedInfoModal: !this.state.editDetailedInfoModal })}>Close</Button>
                                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                                            </div>
                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>

                                            :
                                            <ul>
                                                <li className="justify-content-between" key={0}>
                                                    <span className="text-left"> Non-Profit</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.non_profit}
                                                    </span>
                                                </li>
                                                <li className="justify-content-between" key={1}>
                                                    <span className="text-left">Woman-owned</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.woman_owned}
                                                    </span>
                                                </li>
                                                <li className="justify-content-between" key={2}>
                                                    <span className="text-left"> LLC or Incorporated</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.llc}
                                                    </span>
                                                </li>
                                                <li className="justify-content-between" key={3}>
                                                    <span className="text-left"> Member of industry association</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.association}
                                                    </span>
                                                </li>
                                                <li className="justify-content-between" key={5}>
                                                    <span className="text-left"> MBE Certified</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.codes}
                                                    </span>
                                                </li>
                                                <li className="justify-content-between" key={4}>
                                                    <span className="text-left"> NAICS</span>
                                                    <span className="text-rigt">
                                                        {this.state.detailedInfo.naics}
                                                    </span>
                                                </li>

                                            </ul>
                                        }
                                    </div>
                                </section>
                                {
                                    this.state.business.online_business != 'No' && (
                                        <section className="dashboard-area business-detail d-none">
                                            <div className="y-page-link">
                                                <h2>COVID Information
                                                    {this.state.complete_section.includes('covid information') ?
                                                        <img src={Complete} alt="complete" />
                                                        :
                                                        <img src={Incomplete} alt="incomplete" />
                                                    }
                                                </h2>
                                                <div className="edit-content">
                                                    <button onClick={() => this.setState({ editCovidInfoModal: !this.state.editCovidInfoModal })} className="remove-btn-bg" >
                                                        <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                                    </button>
                                                </div>
                                                {(this.state.editCovidInfoModal) ?
                                                    <Collapse isOpen={this.state.editCovidInfoModal}>
                                                        <Card className="card-custom-1">
                                                            <CardBody >
                                                                <AvForm onSubmit={this.handleSubmit}>
                                                                    <>
                                                                        {this.state.business.business_category_id == 1 ?
                                                                            <>
                                                                                <div className="custom-legend">
                                                                                    <AvRadioGroup value={this.state.covidInfo.pickup} inline name="pickup" label="Curbside pickup" required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value="Yes" />
                                                                                        <AvRadio customInput label="No" value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>

                                                                                <div className="custom-legend">
                                                                                    <AvRadioGroup value={this.state.covidInfo.dining} inline name="dining" label="Sit-down dining" required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value="Yes" />
                                                                                        <AvRadio customInput label="No" value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>

                                                                                <div className="custom-legend">
                                                                                    <AvRadioGroup value={this.state.covidInfo.delivery} inline name="delivery" label="Delivery" required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value="Yes" />
                                                                                        <AvRadio customInput label="No" value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>

                                                                                <div className="custom-legend">
                                                                                    <AvRadioGroup value={this.state.covidInfo.takeout} inline name="takeout" label="Takeout" required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value="Yes" />
                                                                                        <AvRadio customInput label="No" value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>

                                                                                <div className="custom-legend">
                                                                                    <AvRadioGroup value={this.state.covidInfo.seating} inline name="seating" label="Outdoor seating" required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value="Yes" />
                                                                                        <AvRadio customInput label="No" value="No" />
                                                                                    </AvRadioGroup>
                                                                                </div>
                                                                            </>
                                                                            : ""
                                                                        }
                                                                        <p className="black-text">What safety measures are in place?</p>

                                                                        <Select placeholder='Select safety measures'
                                                                            options={this.state.safetyMeasure}
                                                                            defaultValue={this.state.bSelectSafetyMeasure}
                                                                            isMulti
                                                                            value={this.state.bSelectSafetyMeasure}
                                                                            name="colors"
                                                                            className="basic-multi-select mt-3 mb-3"
                                                                            classNamePrefix="select"
                                                                            onChange={this.change}
                                                                        />
                                                                    </>
                                                                    <div className="button-modal-footer">
                                                                        <Button variant="secondary" onClick={() => this.setState({ editCovidInfoModal: !this.state.editCovidInfoModal })}>Close</Button>
                                                                        <Button type="submit" variant="primary" >Save Changes</Button>
                                                                    </div>
                                                                </AvForm>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                    :
                                                    <>
                                                        <div className="safety safety-measure">
                                                            <div className="sub-heading">
                                                                <span>What safety measures are in place?</span>
                                                            </div>
                                                            {this.state.bSafetyMeasure.map((item, i) => {
                                                                return (
                                                                    <div className="custom-checkbox" key={i}>
                                                                        <input defaultChecked={true} disabled type="checkbox" id={'chb3-' + i} />
                                                                        <label htmlFor={'chb3-' + i}>
                                                                            {item.label}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        {this.state.business.business_category_id == 1 ?
                                                            <AvForm onSubmit="#">
                                                                <ul className="covid-point">
                                                                    <li className="" key={0}>
                                                                        <div className="custom-legend">
                                                                            <span className="text-left"> Curbside pickup</span>
                                                                            <span className="text-rigt">
                                                                                {this.state.covidInfo.pickup}
                                                                            </span>
                                                                        </div>

                                                                    </li>
                                                                    <li className="" key={1}>
                                                                        <div className="custom-legend">
                                                                            <span className="text-left"> Sit-down dining</span>
                                                                            <span className="text-rigt">
                                                                                {this.state.covidInfo.dining}
                                                                            </span>
                                                                        </div>

                                                                    </li>
                                                                    <li className="" key={2}>
                                                                        <div className="custom-legend">
                                                                            <span className="text-left"> Delivery</span>
                                                                            <span className="text-rigt">
                                                                                {this.state.covidInfo.delivery}
                                                                            </span>
                                                                        </div>

                                                                    </li>
                                                                    <li className="" key={3}>
                                                                        <div className="custom-legend">
                                                                            <span className="text-left"> Takeout</span>
                                                                            <span className="text-rigt">
                                                                                {this.state.covidInfo.takeout}
                                                                            </span>
                                                                        </div>

                                                                    </li>
                                                                    <li className="" key={4}>
                                                                        <div className="custom-legend">
                                                                            <span className="text-left"> Outdoor seating</span>
                                                                            <span className="text-rigt">
                                                                                {this.state.covidInfo.seating}
                                                                            </span>
                                                                        </div>

                                                                    </li>

                                                                    {/* <li key={0}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={this.state.covidInfo.pickup} name="a" inline label="Curbside pickup">
                                                                        <AvRadio disabled customInput label="Yes" value="Yes" />
                                                                        <AvRadio disabled customInput label="No" value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={1}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={this.state.covidInfo.dining} name="aa" inline label="Sit-down dining">
                                                                        <AvRadio disabled customInput label="Yes" value="Yes" />
                                                                        <AvRadio disabled customInput label="No" value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={2}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={this.state.covidInfo.delivery} name="aaa" inline label="Delivery">
                                                                        <AvRadio disabled customInput label="Yes" value="Yes" />
                                                                        <AvRadio disabled customInput label="No" value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={3}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={this.state.covidInfo.takeout} name="aaaa" inline label="Takeout">
                                                                        <AvRadio disabled customInput label="Yes" value="Yes" />
                                                                        <AvRadio disabled customInput label="No" value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={4}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={this.state.covidInfo.seating} name="aaaaa" inline label="Outdoor seating">
                                                                        <AvRadio disabled customInput label="Yes" value="Yes" />
                                                                        <AvRadio disabled customInput label="No" value="No" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                             */}
                                                                    {/* <li key={0}>
                                                            <span className="la-star">Curbside pickup</span>
                                                            <label className="review-label">
                                                                {this.state.covidInfo.pickup}
                                                            </label>
                                                        </li> */}

                                                                    {/* {this.state.ratings.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                            <span className="la-star">{item.text}</span>
                                                            <label className="review-label">
                                                            <input type="radio" defaultChecked={item.active ? 'checked' : ''} name="review-radio" /> Yes <span className="radio-btns"><input type="radio" defaultChecked={item.active ? 'checked' : ''} name="review-radio" /> No</span>
                                                            <span className="review-mark"></span>
                                                            </label>
                                                            </li>
                                                            )
                                                        })} */}
                                                                </ul>
                                                            </AvForm>
                                                            : ""
                                                        }

                                                    </>
                                                }
                                            </div>
                                        </section>
                                    )}
                                {this.state.business.business_category_id == 1 ?
                                    <>
                                        <section className="dashboard-area business-detail">
                                            <div className="y-page-link">
                                                <h2>Food Menu
                                                    {this.state.complete_section.includes('business_food_url') ?
                                                        <img src={Complete} alt="complete" />
                                                        :
                                                        <img src={Incomplete} alt="incomplete" />
                                                    }
                                                </h2>
                                                <div className="edit-content">
                                                    <button onClick={() => this.setState({ editFoodUrlModal: !this.state.editFoodUrlModal })} className="remove-btn-bg" >
                                                        <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                                    </button>
                                                </div>
                                                {(this.state.editFoodUrlModal) ?
                                                    <Collapse isOpen={this.state.editFoodUrlModal}>
                                                        <Card className="card-custom-1">
                                                            <CardBody >
                                                                <AvForm onSubmit={this.handleSubmit}>
                                                                    <AvGroup>
                                                                        <AvInput
                                                                            placeholder="Enter food menu url"
                                                                            className="form-control"
                                                                            type="url"
                                                                            name="business_food_url"
                                                                            value={this.state.business.business_food_url}
                                                                            required />
                                                                        <AvFeedback> Required field and enter a valid url*</AvFeedback>
                                                                    </AvGroup>
                                                                    <div className="button-modal-footer">
                                                                        <Button variant="secondary" onClick={() => this.setState({ editFoodUrlModal: !this.state.editFoodUrlModal })}>Close</Button>
                                                                        <Button type="submit" variant="primary" >Save Changes</Button>
                                                                    </div>
                                                                </AvForm>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                    :

                                                    <a href={`${this.state.business.business_food_url}`} className="video" target="_blank">
                                                        {
                                                            this.state.business.business_food_url ?
                                                                this.state.business.business_food_url.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '')
                                                                : ""
                                                        }
                                                    </a>
                                                }
                                            </div>
                                        </section>

                                        <section className="dashboard-area business-detail">
                                            <div className="y-page-link">
                                                <h2>Food Type
                                                    {this.state.complete_section.includes('business_food_type') ?
                                                        <img src={Complete} alt="complete" />
                                                        :
                                                        <img src={Incomplete} alt="incomplete" />
                                                    }
                                                </h2>
                                                <div className="edit-content">
                                                    <button onClick={() => this.setState({ editFoodTypeModal: !this.state.editFoodTypeModal })} className="remove-btn-bg" >
                                                        <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                                    </button>
                                                </div>
                                                {(this.state.editFoodTypeModal) ?
                                                    <Collapse isOpen={this.state.editFoodTypeModal}>
                                                        <Card className="card-custom-1">
                                                            <CardBody >
                                                                <AvForm onSubmit={this.handleSubmit}>
                                                                    <label>Select up to 3 options <span></span></label>
                                                                    <AvCheckboxGroup inline value={this.state.business.select_food_type} name="food_type" label="" required validate={{ max: { value: 3 } }}>
                                                                        <div className="row">
                                                                            {this.state.foodTypes.map((item, i) => {

                                                                                return (
                                                                                    <div key={i} className="col-md-3">
                                                                                        <AvCheckbox label={item.name} value={item.id} />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </AvCheckboxGroup>

                                                                    <div className="button-modal-footer">
                                                                        <Button variant="secondary" onClick={() => this.setState({ editFoodTypeModal: !this.state.editFoodTypeModal })}>Close</Button>
                                                                        <Button type="submit" variant="primary" >Save Changes</Button>
                                                                    </div>
                                                                </AvForm>
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                    :

                                                    <div className="safety food-types">
                                                        <div className="sub-heading">
                                                            {/* <span>What safety measures are in place?</span> */}
                                                        </div>
                                                        <div className="row">
                                                            {this.state.business.food_type.map((item, i) => {
                                                                return (
                                                                    <div className="custom-checkbox" key={item.id}>
                                                                        <div className="col-md-3">
                                                                            <input defaultChecked={true} disabled type="checkbox" id={'chb8-' + item.id} />
                                                                            <label htmlFor={'chb8-' + i}>
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </section>
                                    </>
                                    : ""
                                }
                                <section className="dashboard-area business-detail">
                                    <div className="y-page-link">
                                        <h2>Amenities
                                            <div className="info-box-overlay">
                                                <OverlayTrigger
                                                    delay={200}
                                                    trigger={["hover", "hover"]}
                                                    placement={'auto'}
                                                    overlay={popover('Amenities',
                                                        'Select the options that your business provides as a courtesy to customers in this section.')}
                                                >
                                                    <FaInfo />
                                                </OverlayTrigger>
                                            </div>
                                            {this.state.complete_section.includes('amenities') ?
                                                <img src={Complete} alt="complete" />
                                                :
                                                <img src={Incomplete} alt="incomplete" />
                                            }
                                        </h2>
                                        <div className="edit-content">
                                            <button onClick={() => this.setState({ editAmenitieModal: !this.state.editAmenitieModal })} className="remove-btn-bg" >
                                                <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                            </button>
                                        </div>
                                        {(this.state.editAmenitieModal) ?
                                            <Collapse isOpen={this.state.editAmenitieModal}>
                                                <Card className="card-custom-1 edit-tabs">
                                                    <CardBody >
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <Tabs
                                                                id="controlled-tab-example1"
                                                                activeKey={this.state.editActiveKey}
                                                                onSelect={(k) => this.setState({ editActiveKey: k })}
                                                            >
                                                                {this.state.businessAmenitie.map((tab, index) => {
                                                                    return (
                                                                        <Tab key={index} eventKey={index} title={tab.name}>
                                                                            {tab.sub_features.map((feature, i) => {
                                                                                return (
                                                                                    <div key={i} className="custom-legend">
                                                                                        <AvRadioGroup value={feature.selected} inline name={feature.value} label={feature.label} required errorMessage="Pick one!">
                                                                                            <AvRadio customInput label="Yes" value="Yes" />
                                                                                            <AvRadio customInput label="No" value="No" />
                                                                                        </AvRadioGroup>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </Tab>
                                                                    )
                                                                })}

                                                            </Tabs>

                                                            <div className="button-modal-footer">
                                                                <Button variant="secondary" onClick={() => this.setState({ editAmenitieModal: !this.state.editAmenitieModal })}>Close</Button>
                                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                                            </div>

                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>

                                            :
                                            <ul className="covid-point business-price">
                                                <AvForm onSubmit="#" className="amenties">
                                                    <Tabs
                                                        id="controlled-tab-example"
                                                        activeKey={this.state.ActiveKey}
                                                        onSelect={(k) => this.setState({ ActiveKey: k })}
                                                    >
                                                        {this.state.businessAmenitie.map((tab, index) => {
                                                            return (
                                                                <Tab key={index} eventKey={index} title={tab.name}>
                                                                    <ul className="price">
                                                                        {tab.sub_features.map((feature, i) => {
                                                                            return (
                                                                                <li className="" key={i}>
                                                                                    <div className="custom-legend">
                                                                                        <span className="text-left">{feature.label}</span>
                                                                                        <span className="text-rigt">
                                                                                            {feature.selected}
                                                                                        </span>
                                                                                    </div>

                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </Tab>
                                                            )
                                                        })}

                                                    </Tabs>
                                                </AvForm>
                                            </ul>
                                        }
                                    </div>
                                </section>
                                <section className="dashboard-area business-detail business-price">
                                    <div className="y-page-link">
                                        <h2>Price
                                            <div className="info-box-overlay">
                                                <OverlayTrigger
                                                    delay={200}
                                                    trigger={["hover", "hover"]}
                                                    placement={'auto'}
                                                    overlay={popover('Price',
                                                        'Select the price tier that best demonstrates the pricing for your product or service in this section.')}
                                                >
                                                    <FaInfo />
                                                </OverlayTrigger>
                                            </div>
                                            {this.state.complete_section.includes('price') ?
                                                <img src={Complete} alt="complete" />
                                                :
                                                <img src={Incomplete} alt="incomplete" />
                                            }
                                        </h2>
                                        <div className="edit-content">
                                            <button onClick={() => this.setState({ editPriceModal: !this.state.editPriceModal })} className="remove-btn-bg" >
                                                <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                            </button>
                                        </div>
                                        {(this.state.editPriceModal) ?
                                            <Collapse isOpen={this.state.editPriceModal}>
                                                <Card className="card-custom-1">
                                                    <CardBody >
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <>
                                                                <div className="amount">
                                                                    <AvRadioGroup value={`${this.state.amountValue}`} name="amount_value" label="" required errorMessage="Pick one!">
                                                                        <AvRadio customInput label="Inexpensive" value="1" />
                                                                        <AvRadio customInput label="Moderate" value="2" />
                                                                        <AvRadio customInput label="Expensive" value="3" />
                                                                        <AvRadio customInput label="Luxury" value="4" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </>
                                                            <div className="button-modal-footer">
                                                                <Button variant="secondary" onClick={() => this.setState({ editPriceModal: !this.state.editPriceModal })}>Close</Button>
                                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                                            </div>
                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            :

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <AvForm onSubmit="#">
                                                        <ul className="price p1">
                                                            <li key={0}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={`${this.state.amountValue}`} inline name="q" label="$" required errorMessage="Pick one!">
                                                                        <AvRadio disabled customInput label="Inexpensive" value="1" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={1}>

                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={`${this.state.amountValue}`} inline name="qq" label="$$" required errorMessage="Pick one!">
                                                                        <AvRadio disabled customInput label="Moderate" value="2" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={2}>
                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={`${this.state.amountValue}`} inline name="qqq" label="$$$" required errorMessage="Pick one!">
                                                                        <AvRadio disabled customInput label="Expensive" value="3" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                            <li key={3}>

                                                                <div className="custom-legend">
                                                                    <AvRadioGroup value={`${this.state.amountValue}`} inline name="qqqq" label="$$$$" required errorMessage="Pick one!">
                                                                        <AvRadio disabled customInput label="Luxury" value="4" />
                                                                    </AvRadioGroup>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </AvForm>
                                                </div>
                                                {/* <div className="col-md-4 heading">
                                                    <h5>The dollar signs represent cost based on a three-course meal.</h5>
                                                </div> */}
                                            </div>
                                        }
                                    </div>
                                </section>
                                {/* <section className="dashboard-area business-detail social-media">
                                    <div className="y-page-link">
                                        <h2>Social Media
                                            {this.state.complete_section.includes('social media') ?
                                                <img src={Complete} alt="complete" />
                                                :
                                                <img src={Incomplete} alt="incomplete" />
                                            }
                                        </h2>
                                        <div className="edit-content">
                                            <button onClick={() => this.setState({ editSocialMediaModal: !this.state.editSocialMediaModal })} className="remove-btn-bg" >
                                                <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                            </button>
                                        </div>
                                        {(this.state.editSocialMediaModal) ?
                                            <Collapse isOpen={this.state.editSocialMediaModal}>
                                                <Card className="card-custom-1">
                                                    <CardBody >
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <>
                                                                <AvGroup>
                                                                    <AvInput
                                                                        placeholder="Enter facebook profile url"
                                                                        className="form-control"
                                                                        name="fb_url"
                                                                        type="url"
                                                                        value={this.state.socialMedia.fb_url}
                                                                    />
                                                                    <AvFeedback> Enter a url *</AvFeedback>
                                                                </AvGroup>
                                                                <AvGroup>
                                                                    <AvInput
                                                                        placeholder="Enter instagram profile url"
                                                                        className="form-control"
                                                                        name="insta_url"
                                                                        type="url"
                                                                        value={this.state.socialMedia.insta_url}
                                                                    />
                                                                    <AvFeedback> Enter a url *</AvFeedback>
                                                                </AvGroup>

                                                                <AvGroup>
                                                                    <AvInput
                                                                        placeholder="Enter twitter profile url"
                                                                        className="form-control"
                                                                        name="twitter_url"
                                                                        type="url"
                                                                        value={this.state.socialMedia.twitter_url}
                                                                    />
                                                                    <AvFeedback> Enter a url *</AvFeedback>
                                                                </AvGroup>
                                                            </>
                                                            <div className="button-modal-footer">
                                                                <Button variant="secondary" onClick={() => this.setState({ editSocialMediaModal: !this.state.editSocialMediaModal })}>Close</Button>
                                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                                            </div>
                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            :
                                            <ul>

                                                <li className="justify-content-between" key={0}>
                                                    <span className="text-left"> Facebook</span>
                                                    <span className="text-rigt">
                                                        {this.state.socialMedia.fb_url}
                                                    </span>
                                                </li>

                                                <li className="justify-content-between" key={1}>
                                                    <span className="text-left"> Instagram</span>
                                                    <span className="text-rigt">
                                                        {this.state.socialMedia.insta_url}
                                                    </span>
                                                </li>

                                                <li className="justify-content-between" key={2}>
                                                    <span className="text-left"> Twitter</span>
                                                    <span className="text-rigt">
                                                        {this.state.socialMedia.twitter_url}
                                                    </span>
                                                </li>
                                                
                                            </ul>
                                        }
                                    </div>
                                </section> */}

                            </Col>
                        </Row>

                    </Container>
                    :
                    ""
                }
                <ToastContainer />
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}

export default Dashboard;