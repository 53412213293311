'use strict';
import React, { Component } from 'react';
import NavbarListing from "../../components/common/NavbarListing";
import ListingDetailsBreadcrumb from "./ListingDetailsBreadcrumb";
import ListingDetailsSidebar from "../../components/sidebars/ListingDetailsSidebar";
import { BsCheckCircle } from 'react-icons/bs'
import ModalVideo from 'react-modal-video'
import { useLocation } from "react-router";
import GeneralMap from "../../components/contact/GeneralMap";
import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHours";
import ListingDetailsComments from "../../components/contact/ListingDetailsComments";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import videoimg from '../../assets/images/img22.jpg'
import { Link, Redirect } from "react-router-dom";
import { RiStarLine, RiFacebookCircleLine, RiTwitterFill } from 'react-icons/ri'
import Modal from 'react-bootstrap/Modal'
import { AiOutlineCamera } from 'react-icons/ai'
import { FiUpload, FiBookmark } from 'react-icons/fi'
import { FaFacebook, FaExternalLinkAlt } from 'react-icons/fa'
import OutDoor from '../../assets/images/tick.png'
import bg from '../../assets/images/bread-bg2.jpg'
import { BiVideo } from 'react-icons/bi'
import { ApiUrl, buyerUrl, adminUrl } from '../../config';
import axios from 'axios';
import RButton from 'react-bootstrap/Button'
import LoginBox from '../../components/other/account/LoginBox';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { ShareButtonRectangle, ShareBlockStandard, ShareButton } from 'react-custom-share';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import ReactGA from 'react-ga';
import FriendOne from '../../assets/images/FriendOne.png';
import FriendTwo from '../../assets/images/FriendTwo.png';
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import DocumentMeta from 'react-document-meta';

class ListingDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            business: {},
            logo: bg,
            search: "",
            location: "",
            isSignedUp: false,
            externalData: false,
            workingHours: [],
            images: [],
            reviews: [],
            LoginModal: false,
            shareModal: false,
            redirectPage: '',
            props_data: this.props.location,
            bookmarked: false,
            loaded: false,
            video: false,
        }
        this.handler = this.handler.bind(this)
        this.openModal = this.openModal.bind(this)
        this.handler1 = this.handler1.bind(this);
        this.loginActivity = this.loginActivity.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    handler1(e) {
        if (e) {
            if (this.state.redirectPage == 'review') {
                setTimeout(
                    () => this.props.history.push({
                        pathname: `/write-review/${this.state.business.business_slug}`,
                        state: { business: this.state.business, reviews: this.state.reviews }
                    }),
                    4000
                );
            } else if (this.state.redirectPage == 'photos') {
                setTimeout(
                    () => this.props.history.push({
                        pathname: `/add-photos/${this.state.business.business_slug}`,
                        state: {
                            business: this.state.business,
                            search: this.state.props_data.state ? this.state.props_data.state.search : '',
                            location: this.state.props_data.state ? this.state.props_data.state.location : ''
                        }
                    }),
                    4000
                );
            } else if (this.state.redirectPage == 'bookmarks') {
                this.businessBookmark();
                this.setState({
                    LoginModal: false
                })
            } else if (this.state.redirectPage == 'share') {
                console.log('hi')
                this.setState({
                    shareModal: true,
                    LoginModal: false
                })

            }
            else {
                this.setState({
                    LoginModal: false
                })
            }
        }
    }


    loginActivity(data) {
        this.setState({ externalData: false });
        if (data) {
            this.setState({ externalData: true });
        }
    }

    onHide() {
        this.setState({ LoginModal: false, shareModal: false });
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    businessBookmark() {
        var self = this;
        axios.post(ApiUrl + 'addToBookmark', { business_id: this.state.business.business_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        bookmarked: true,
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleShareSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.url = window.location.href;
            axios.post(ApiUrl + 'shareBusinessEmail', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({ shareModal: false });
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    contentstate = {
        descTitle: 'COVID-19 Updates ',
        update: 'Updated Services',
        health: 'Health & Safety Measures',
        business: 'About our business',
        direction: '1304 Walnut St Philadelphia, PA 19107',
        shop_address: 'Avenue of the Arts South',
        flists: [
            {
                icon: <BsCheckCircle />,
                title: 'Instant Noodles'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Smoking Allowed'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Bike Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Wireless Internet'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Street Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Accepts Credit Cards'
            }
        ],
        videoImg: videoimg,
        buttonText: 'Watch Video',
        mapTitle: 'Location & Hours',
    }

    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'singleBusiness', { business_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    business: res.data.business,
                    logo: res.data.business.business_logo,
                    externalData: true,
                    workingHours: res.data.workingHours,
                    images: res.data.images,
                    reviews: res.data.reviews,
                    bookmarked: res.data.user_bookmark,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {

        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        const url = `${buyerUrl}/p/${this.state.business.business_slug}`;
        const shareButtonPropsFb = {
            url: url,
            network: "Facebook",
            text: "",
            longtext: ""
        };
        let description = '';
        if (this.state.business.business_description) {
            description = this.state.business.business_description.replace(/\s+/g, '-').substring(0, 10) + '...';
        }
        const url1 = `${buyerUrl}/p/${this.state.business.business_slug}`;
        const shareButtonPropsTw = {
            url: url1,
            network: "Twitter",
            text: "",
            longtext: ""
        };


        ReactGA.initialize([{
            trackingId: this.state.business.track_uid,
            gaOptions: { name: 'tracker2' },
            debug: true,
        }]);

        ReactGA.set(['tracker2']);
        ReactGA.pageview(window.location.href, ['tracker2']);

        // ReactGA.pageview(window.location.href);

        const meta = {
            title: this.state.business.business_name,
            description: description,
            canonical: this.state.business.business_logo,
            meta: {
                property: {
                    'og:title': this.state.business.business_name,
                    'og:url': window.location.href,
                    'og:image': this.state.business.business_logo,
                    'og:description': description,
                    'twitter:card': 'summary_large_image',
                    'twitter:title': this.state.business.business_name,
                    'twitter:description': description,
                    'twitter:image': this.state.business.business_logo
                }
            }
        };


        return (

            <>
                <Helmet>
                    <title>{`${this.state.business.business_name}, ${this.state.business.business_address}`}</title>
                    <meta name="title" content={`${this.state.business.business_name}, ${this.state.business.business_address}`} />
                    <meta name="description"
                        content={`${this.state.business.business_description}`} />
                    <meta property="og:title" content={`${this.state.business.business_name}, ${this.state.business.business_address} `} />
                    <meta property="og:description"
                        content={`${this.state.business.business_description}`} />
                    <meta property="og:image" content={`${this.state.business.business_logo}`} />

                </Helmet>

                <main className="listing-details">
                    <ToastContainer />
                    {/* Header */}
                    <NavbarListing state={this.props.location ? this.props.location.state : ""} handler={this.handler} />
                    {/* Breadcrumb */}
                    {(this.state.externalData) ? <ListingDetailsBreadcrumb state={this.state} handler={this.handler} /> : ""}

                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='R2kiP9Qu7Pc' onClose={() => this.setState({ isOpen: false })} />
                    <section className="single-listing-area padding-top-35px">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="single-listing-wrap details">
                                        {/*<ListingDetailsGallery />*/}
                                        <div className="write-review">
                                            <div className="ReviewBtns">
                                                {(!uToken()) ?
                                                    <RButton onClick={() => this.setState({ LoginModal: true, redirectPage: 'review' })}
                                                        type="button" className="review-button" variant="none">
                                                        <RiStarLine /> Write a Review
                                                    </RButton>
                                                    :
                                                    <Link to={{
                                                        pathname: `/write-review/${this.state.business.business_slug}`,
                                                        state: {
                                                            business: this.state.business,
                                                            search: this.state.props_data.state ? this.state.props_data.state.search : '',
                                                            location: this.state.props_data.state ? this.state.props_data.state.location : ''
                                                        },
                                                    }}
                                                        className="review-button"
                                                    ><RiStarLine /> Write a Review</Link>
                                                }

                                                {(!uToken()) ?
                                                    <RButton onClick={() => this.setState({ LoginModal: true, redirectPage: 'photos' })}
                                                        type="button" className="" variant="none">
                                                        <AiOutlineCamera /> Add Photo
                                                    </RButton>
                                                    :
                                                    <Link to={{
                                                        pathname: `/add-photos/${this.state.business.business_slug}`,
                                                        state: {
                                                            business: this.state.business,
                                                            search: this.state.props_data.state ? this.state.props_data.state.search : '',
                                                            location: this.state.props_data.state ? this.state.props_data.state.location : ''
                                                        }
                                                    }}>
                                                        <AiOutlineCamera /> Add Photo</Link>

                                                }

                                                {(!uToken()) ?
                                                    <RButton onClick={() => this.setState({ LoginModal: true, redirectPage: 'share' })}
                                                        type="button" className="" variant="none">
                                                        <FiUpload /> Share
                                                    </RButton>
                                                    :

                                                    <RButton onClick={() => this.setState({ shareModal: true, redirectPage: 'share' })}
                                                        type="button" className="" variant="none">
                                                        <FiUpload /> Share
                                                    </RButton>

                                                }

                                                {(this.state.bookmarked) ?

                                                    <RButton
                                                        type="button" className="" variant="none">
                                                        <FiBookmark /> Saved
                                                    </RButton>
                                                    :
                                                    (!uToken()) ?
                                                        <RButton onClick={() => this.setState({ LoginModal: true, redirectPage: 'bookmarks' })}
                                                            type="button" className="" variant="none">
                                                            <FiBookmark /> Save
                                                        </RButton>
                                                        :

                                                        <RButton onClick={() => this.businessBookmark()}
                                                            type="button" className="" variant="none">
                                                            <FiBookmark /> Save
                                                        </RButton>

                                                    // <Link to="/"><FiBookmark /> Save</Link>
                                                }
                                            </div>

                                            {
                                                (this.state.business.userFriend) ?
                                                    this.state.business.userFriend.map((friend, i) => {
                                                        return (
                                                            <div className="FriendCount">
                                                                <span>
                                                                    {i == 0 ?
                                                                        <div className="ImageOne"><img src={friend.image} alt="Friend" /></div>
                                                                        : ""
                                                                    }
                                                                    {i == 1 ?
                                                                        <div className="ImageTwo"><img src={friend.image} alt="Friend" /></div>
                                                                        : ""
                                                                    }

                                                                    <div className="CountNumber">{this.state.business.userFriendcount > 2 ? '+' + this.state.business.userFriendcount : ""}</div>
                                                                </span>
                                                                <div className="SavedThis">Friends who saved this</div>
                                                            </div>
                                                        );
                                                    })
                                                    : ""
                                            }




                                            <Modal show={this.state.shareModal} onHide={this.onHide} size="md" >
                                                <Modal.Header closeButton>
                                                    <Modal.Title> Share business </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row social-share">
                                                        <div className="col-md-6 fb">
                                                            <ShareButton {...shareButtonPropsFb}>
                                                                <FaFacebook /> Share on Facebook
                                                            </ShareButton>
                                                        </div>
                                                        <div className="col-md-6 tw">
                                                            <ShareButton {...shareButtonPropsTw}>
                                                                <RiTwitterFill /> Share on Twitter
                                                            </ShareButton>
                                                        </div>
                                                    </div>
                                                    <div className="form-group share-link margin-top-20px">

                                                        <span><FaExternalLinkAlt /></span> <input type="url" className="form-control" value={`${buyerUrl}/biz/${this.state.business.business_slug}`} />

                                                    </div>
                                                    <div className="col-lg-12 share-line">
                                                        <div className="account-assist mt-4 mb-4 text-center">
                                                            <p className="account__desc">OR</p>
                                                        </div>
                                                    </div>

                                                    <AvForm onSubmit={this.handleShareSubmit}>
                                                        <AvGroup>
                                                            <AvInput
                                                                type="email"
                                                                placeholder="To"
                                                                className="form-control"
                                                                name="send_to"
                                                                required />
                                                            <AvFeedback>Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <AvGroup>
                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="business_name"
                                                                value={this.state.business.business_name}
                                                                required />
                                                            <AvFeedback>Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <AvGroup>
                                                            <AvInput
                                                                type="textarea"
                                                                placeholder="Add note"
                                                                className="form-control"
                                                                name="message"
                                                                rows={5}
                                                                required />
                                                            <AvFeedback>Required Field *</AvFeedback>
                                                        </AvGroup>
                                                        <Modal.Footer className="share-footer">
                                                            <RButton type="submit" variant="primary" >Share</RButton>
                                                        </Modal.Footer>
                                                    </AvForm>
                                                </Modal.Body>
                                            </Modal>

                                            <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {/* <h5>Login required to perform this action </h5> */}
                                                    <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                                                </Modal.Body>
                                            </Modal>
                                            {/* <div className="saved-friend">
                                                <ul class="comments-list">
                                                    <li><div class="comment">
                                                        <img class="avatar__img" alt="Comment"
                                                            src={bg} />
                                                    </div>
                                                    </li>
                                                    <li><div class="comment">
                                                        <img class="avatar__img" alt="Comment"
                                                            src={bg} />
                                                    </div>
                                                    </li>
                                                </ul>


                                            </div> */}

                                        </div>

                                        <div className="listing-description padding-bottom-35px">

                                            {this.state.business.online_business != 'No' && (
                                                <div className='d-none'>
                                                    <h2 className="widget-title">
                                                        {this.contentstate.descTitle}
                                                    </h2>
                                                    <div className="covid">
                                                        <h2 className="covid-head">
                                                            {this.contentstate.update}
                                                        </h2>
                                                        <ul>
                                                            {
                                                                (this.state.business.business_covid_information) ?
                                                                    Object.keys(this.state.business.business_covid_information).map(key => {
                                                                        if (this.state.business.business_covid_information[key] == 'Yes') {
                                                                            if (key == 'pickup') {
                                                                                return (
                                                                                    <li key={key}><img src={OutDoor} alt="OutDoor" /> Curbside pickup </li>
                                                                                )
                                                                            } else if (key == 'delivery') {
                                                                                return (
                                                                                    <li key={key}><img src={OutDoor} alt="OutDoor" /> Delivery </li>
                                                                                )
                                                                            } else if (key == 'dining') {
                                                                                return (
                                                                                    <li key={key}><img src={OutDoor} alt="OutDoor" /> Sit-down dining </li>
                                                                                )
                                                                            } else if (key == 'seating') {
                                                                                return (
                                                                                    <li key={key}><img src={OutDoor} alt="OutDoor" /> Outdoor seating </li>
                                                                                )
                                                                            } else if (key == 'takeout') {
                                                                                return (
                                                                                    <li key={key}><img src={OutDoor} alt="OutDoor" /> Takeout </li>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                    : <p className="default-text">Contact the business for more information about recent service changes.</p>
                                                            }

                                                            {/* <li><img src={OutDoor} alt="Dining" /> Sit-down dining</li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="covid two">
                                                        <h2 className="covid-head">
                                                            {this.contentstate.health}
                                                        </h2>
                                                        <ul>
                                                            {
                                                                (this.state.business.business_safety_measures) ?
                                                                    this.state.business.business_safety_measures.map((saftey, i) => {
                                                                        return (
                                                                            <li key={i}><img src={OutDoor} alt="Masks" /> {saftey}</li>
                                                                        );
                                                                    })
                                                                    :
                                                                    ""
                                                            }

                                                            {/* <li><img src={OutDoor} alt="Capacity" /> Limited capacity</li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="businesses">
                                                <h2 className="business-head">
                                                    {this.contentstate.business}
                                                </h2>
                                                {
                                                    this.state.business.business_video != '' && this.state.business.business_video != null ? <a target="_blank" href={this.state.business.business_video}><BiVideo /> Watch our Video</a> : ""
                                                }

                                                <div className="video-desc">
                                                    <p>{this.state.business.business_description}</p>
                                                </div>
                                            </div>
                                            {/* <div className="section-heading mt-4">
                                            <p className="sec__desc font-size-16">
                                                {this.contentstate.desc}
                                            </p>
                                        </div> */}
                                        </div>
                                        {/*<div className="feature-listing padding-bottom-20px">
                                        <h2 className="widget-title">
                                            {this.contentstate.featureTitle}
                                        </h2>
                                        <div className="title-shape"></div>
                                        <ul className="list-items mt-4">
                                            {this.contentstate.flists.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        <i className="color-text font-size-18">{item.icon}</i> {item.title}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div> 
                                    <div className="video-listing padding-bottom-40px">
                                        <h2 className="widget-title">
                                            {this.contentstate.videoTitle}
                                        </h2>
                                        <div className="title-shape"></div>
                                        <div className="video__box margin-top-35px text-center">
                                            <img src={this.contentstate.videoImg} alt="video" />
                                            <div className="video__box-content">
                                                <span className="mfp-iframe video-popup-btn video-play-btn"
                                                   onClick={this.openModal}
                                                   title="Play Video">
                                                    <span className="d-inline-block">
                                                        <AiOutlinePlayCircle />
                                                    </span>
                                                </span>
                                                <p className="video__desc">
                                                    {this.contentstate.buttonText}
                                                </p>
                                            </div>
                                        </div>
                                    </div>*/}
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="listing-map gmaps">
                                                    <h2 className="widget-title">
                                                        {this.contentstate.mapTitle}
                                                    </h2>
                                                    {(this.state.externalData) ? <GeneralMap state={this.state.business} /> : ""}
                                                    <div className="get-direction">
                                                        <p className="ShopAddress">
                                                            {(this.state.externalData) ? this.state.business.suite_number : ""}
                                                        </p>
                                                        <h3 className="heading-direction">
                                                            {this.state.business.business_address}
                                                        </h3>
                                                        {/* <p className="ShopAddress">
                                                            {this.contentstate.shop_address}
                                                        </p> */}
                                                        <p className="ShopAddress">
                                                            {(this.state.externalData) ? this.state.business.business_neighborhoods.toString() : ""}
                                                        </p>

                                                    </div>
                                                    <div className="direction-btn">
                                                        <Link to={`/maps/${this.state.business.business_slug}`}>Get Directions</Link>
                                                        {/* <a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + this.state.business.business_lat + ',' + this.state.business.business_long} > Get directions</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="listing-map gmaps">
                                                    <h2 className="widget-title">
                                                        &nbsp;
                                                    </h2>
                                                    {(this.state.externalData) ? <WidgetOpenHours online={this.state.business.online_business} state={this.state.workingHours} /> : ""}

                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="padding-top-20px">
                                            <h2 className="widget-title">
                                                Neighborhoods
                                                </h2>
                                            <div className="row">
                                                {(this.state.externalData) ? this.state.business.business_neighborhoods.map((neighborhood, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-3">
                                                                <div key={i}><img src={OutDoor} alt="Masks" /> {neighborhood.name}</div>
                                                            </div>
                                                        </>
                                                    );
                                                }) : ""}
                                            </div>
                                        </div> */}
                                        {/*<ContactInfo />
                                    <CustomerFeedback />*/}
                                        <div className="comments-wrap">
                                            <h2 className="widget-title">
                                                Recommended Reviews
                                            </h2>
                                            {(this.state.externalData) ? <ListingDetailsComments loginActivity={this.loginActivity} business={this.state.business} reviews={this.state.reviews} /> : ""}
                                        </div>
                                        {/* <ReviewFields />*/}
                                    </div>


                                </div>
                                <div className="col-lg-4">
                                    {(this.state.externalData) ? <ListingDetailsSidebar loginActivity={this.loginActivity} business={this.state.business} /> : ""}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="card-area padding-top-80px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-heading">
                                    <h2 className="widget-title">
                                        {this.contentstate.peopleViewtitle}
                                    </h2>
                                </div>
                            </div>
                        </div>
                       <PlaceOne />
                    </div>
                </section>*/}
                    {/* Newsletter 
                <NewsLetter />*/}

                    {/* Footer */}
                    <Footer />
                    <ScrollTopBtn />
                </main>
            </>
        );
    }
}
export default ListingDetails;