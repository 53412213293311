'use strict';
import React, { Component } from 'react';
import GeneralHeader from '../../components/webapp/common/GeneralHeader';
import ListingDetailsBreadcrumb from "./ListingDetailsBreadcrumb";
import { BsCheckCircle } from 'react-icons/bs'
import ModalVideo from 'react-modal-video'
import { Dropdown } from "react-bootstrap";
import ListingDetailsComments from "../../components/webapp/ListingDetailsComments";
import Footer from "../../components/webapp/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import videoimg from '../../assets/images/img22.jpg'
import { Link, Redirect } from "react-router-dom";
import OutDoor from '../../assets/images/tick.png'
import bg from '../../assets/images/bread-bg2.jpg'
import Review from '../../assets/images/b-review.png'
import Call from '../../assets/images/b-call.png'
import Direction from '../../assets/images/b-direction.png'
import Web from '../../assets/images/b-web.png'
import { ApiUrl, buyerUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';
import Modal from 'react-bootstrap/Modal'
import RButton from 'react-bootstrap/Button'
class ListingDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            business: {},
            logo: bg,
            search: "",
            location: "",
            isSignedUp: false,
            externalData: false,
            workingHours: [],
            images: [],
            reviews: [],
            LoginModal: false,
            shareModal: false,
            redirectPage: '',
            props_data: this.props.location,
            bookmarked: false,
            loaded: false,
            video: false,
            categories: [],
            setNavOpen: false,
            openModal: false,
        }
        this.handler = this.handler.bind(this)
        this.handler1 = this.handler1.bind(this);
        this.loginActivity = this.loginActivity.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    handler1(e) {
        if (e) {
            if (this.state.redirectPage == 'review') {
                setTimeout(
                    () => this.props.history.push({
                        pathname: `/write-review/${this.state.business.business_slug}`,
                        state: { business: this.state.business, reviews: this.state.reviews }
                    }),
                    4000
                );
            } else if (this.state.redirectPage == 'photos') {
                setTimeout(
                    () => this.props.history.push({
                        pathname: `/add-photos/${this.state.business.business_slug}`,
                        state: {
                            business: this.state.business,
                            search: this.state.props_data.state ? this.state.props_data.state.search : '',
                            location: this.state.props_data.state ? this.state.props_data.state.location : ''
                        }
                    }),
                    4000
                );
            } else if (this.state.redirectPage == 'bookmarks') {
                this.businessBookmark();
                this.setState({
                    LoginModal: false
                })
            } else if (this.state.redirectPage == 'share') {
                console.log('hi')
                this.setState({
                    shareModal: true,
                    LoginModal: false
                })

            }
            else {
                this.setState({
                    LoginModal: false
                })
            }
        }
    }


    loginActivity(data) {
        this.setState({ externalData: false });
        if (data) {
            this.setState({ externalData: true });
        }
    }

    onHide() {
        this.setState({ LoginModal: false, openModal: false });
    }


    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    businessBookmark() {
        var self = this;
        axios.post(ApiUrl + 'addToBookmark', { business_id: this.state.business.business_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        bookmarked: true,
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleShareSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.url = window.location.href;
            axios.post(ApiUrl + 'shareBusinessEmail', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({ shareModal: false });
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    contentstate = {
        descTitle: 'COVID-19 Updates ',
        update: 'Updated Services',
        health: 'Health & Safety Measures',
        business: 'About our business',
        direction: '1304 Walnut St Philadelphia, PA 19107',
        shop_address: 'Avenue of the Arts South',
        flists: [
            {
                icon: <BsCheckCircle />,
                title: 'Instant Noodles'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Smoking Allowed'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Bike Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Wireless Internet'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Street Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Accepts Credit Cards'
            }
        ],
        videoImg: videoimg,
        buttonText: 'Watch Video',
        mapTitle: 'Location & Hours',
    }

    componentDidMount() {
        this.getBusinessDetail();
        this.getSearchPageCategoriesList();
    }

    getSearchPageCategoriesList = () => {
        var self = this;
        axios.get(ApiUrl + 'getSearchPageCategoriesListMobileWeb').then((response) => {
            if (response.data.success) {
                self.setState({
                    categories: response.data.lists
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'singleBusiness', { business_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    business: res.data.business,
                    logo: res.data.business.business_logo,
                    externalData: true,
                    workingHours: res.data.workingHours,
                    images: res.data.images,
                    reviews: res.data.reviews,
                    bookmarked: res.data.user_bookmark,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {

        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        const url = `https://admin.shopkatika.com/metaData?b-slug=${this.state.business.business_slug}&b-title=${this.state.business.business_name}&b-description=${this.state.business.business_description}&b-image=${this.state.business.business_logo}&b-url=${window.location.href}`;

        const shareButtonPropsFb = {
            url: url,
            network: "Facebook",
            text: "",
            longtext: ""
        };
        let description = '';
        if (this.state.business.business_description) {
            description = this.state.business.business_description.replace(/\s+/g, '-').substring(0, 10) + '...';
        }
        const url1 = `https://admin.shopkatika.com/metaData?b-slug=${this.state.business.business_slug}&b-title=${this.state.business.business_slug}&b-description=${description}&b-image=${this.state.business.business_logo}&b-url=${window.location.href}`;
        const shareButtonPropsTw = {
            url: url1,
            network: "Twitter",
            text: "",
            longtext: ""
        };


        ReactGA.initialize([{
            trackingId: this.state.business.track_uid,
            gaOptions: { name: 'tracker2' },
            debug: true,
        }]);

        ReactGA.set(['tracker2']);
        ReactGA.pageview(window.location.href, ['tracker2']);

        // ReactGA.pageview(window.location.href);


        return (

            <>

                <main className="Mobile home-1 listing-details">
                    {/* Header */}
                    <GeneralHeader state={this.props.location ? this.props.location.state : ""} handler={this.handler} />
                    {/* Breadcrumb */}
                    <div className="DropDownBtn">
                        {this.state.categories.map((cate, i) => {
                            return (
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {cate.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {cate.sub_category.map((sub, s) => {
                                            return (

                                                <Dropdown.Item href={`/listing/${sub.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}`}>{sub.name}</Dropdown.Item>

                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            );
                        })}
                    </div>

                    {(this.state.externalData) ? <ListingDetailsBreadcrumb state={this.state} handler={this.handler} /> : ""}

                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='R2kiP9Qu7Pc' onClose={() => this.setState({ isOpen: false })} />
                    <section className="single-listing-area padding-top-35px">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="single-listing-wrap details">
                                        <div className="listing-description padding-top-20px">
                                            <div className="d-btns padding-bottom-40px">
                                                <ul>
                                                    <li>
                                                        <a className="btn" href={`${buyerUrl}/write-review/${this.state.business.business_slug}`}>
                                                            <img src={Review} alt="Review" />
                                                            <span>Review</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="btn" href={`tel:${this.state.business.phone_number}`}>
                                                            <img src={Call} alt="Call" />
                                                            <span>Call</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link className="btn" to={`/maps/${this.state.business.business_slug}`}>
                                                            <img src={Direction} alt="Direction" />
                                                            <span>Directions</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        {/(http(s?)):\/\//i.test(this.state.business.website) ?

                                                            <><a href={`${this.state.business.website}`} className="btn" target="_blank" ><img src={Web} alt="Web" /> <span>Website</span></a> </>
                                                            :
                                                            <><a href={`https://${this.state.business.website}`} className="btn" target="_blank" ><img src={Web} alt="Web" /> <span>Website</span> </a> </>
                                                        }

                                                    </li>
                                                </ul>
                                            </div>
                                            <h2 className="widget-title mobile-version">
                                                {this.contentstate.descTitle}
                                            </h2>
                                            <p className="LastUpdate">Posted on November 15, 2020</p>
                                            <div className="covid two mobile">
                                                <h2 className="covid-head">
                                                    {this.contentstate.update}
                                                </h2>

                                                <ul>
                                                    {
                                                        (this.state.business.business_covid_information) ?
                                                            Object.keys(this.state.business.business_covid_information).map(key => {
                                                                if (this.state.business.business_covid_information[key] == 'Yes') {
                                                                    if (key == 'pickup') {
                                                                        return (
                                                                            <li key={key}><img src={OutDoor} alt="OutDoor" /> Curbside pickup </li>
                                                                        )
                                                                    } else if (key == 'delivery') {
                                                                        return (
                                                                            <li key={key}><img src={OutDoor} alt="OutDoor" /> Delivery </li>
                                                                        )
                                                                    } else if (key == 'dining') {
                                                                        return (
                                                                            <li key={key}><img src={OutDoor} alt="OutDoor" /> Sit-down dining </li>
                                                                        )
                                                                    } else if (key == 'seating') {
                                                                        return (
                                                                            <li key={key}><img src={OutDoor} alt="OutDoor" /> Outdoor seating </li>
                                                                        )
                                                                    } else if (key == 'takeout') {
                                                                        return (
                                                                            <li key={key}><img src={OutDoor} alt="OutDoor" /> Takeout </li>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                            : ""
                                                    }

                                                </ul>
                                            </div>
                                            <div className="covid two mobile">
                                                <h2 className="covid-head">
                                                    {this.contentstate.health}
                                                </h2>
                                                <ul>
                                                    {
                                                        (this.state.business.business_safety_measures) ?
                                                            this.state.business.business_safety_measures.map((saftey, i) => {
                                                                return (
                                                                    <li key={i}><img src={OutDoor} alt="Masks" /> {saftey}</li>
                                                                );
                                                            })
                                                            : ""
                                                    }
                                                    {/* <li><img src={OutDoor} alt="Capacity" /> Limited capacity</li> */}
                                                </ul>
                                            </div>


                                            {
                                                this.state.business.business_service_type && this.state.business.business_service_type.length > 0 ?
                                                    <div className="service">
                                                        <p>Business Service</p>
                                                        <>

                                                            {
                                                                this.state.business.business_service_type.map((service, index) => {
                                                                    return (
                                                                        <div className="m-bottom_buttons details">
                                                                            {
                                                                                index == 0 || index == 1 ?
                                                                                    <>
                                                                                        {(service.type == 'anchor') ?
                                                                                            <a target="_blank" className="btn-link" href={/(http(s?)):\/\//i.test(service.url) ? service.url : `https://${service.url}`}> {service.name}</a>
                                                                                            : (service.type == 'tel') ?
                                                                                                // ""
                                                                                                <a className="btn-link" href={`tel:${service.url}`}> {service.name}</a>
                                                                                                : (service.type == 'calandly') ?
                                                                                                    <a target="_blank" className="btn-link" href={service.url}> {service.name}</a>
                                                                                                    : ""
                                                                                        }
                                                                                        {/* <Link to={'/biz/' + item.business_slug} className="call-btn">{c.type}</Link> */}
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                        </div>

                                                                    );


                                                                })

                                                            }
                                                        </>
                                                    </div>
                                                    : ""
                                            }

                                            <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title> Make an Appointment </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                                                </Modal.Body>
                                            </Modal>

                                            <div className="service">
                                                <p>PHOTOS</p>
                                                <div className="img-list">
                                                    <div className="GalleryPhotos">

                                                        {
                                                            this.state.images.length > 0 ?
                                                                <>
                                                                    {
                                                                        this.state.images[0] ?
                                                                            <div class="card-item SingleItem">
                                                                                <Link to={`/listing-gallery/${this.state.business.business_slug}`} >
                                                                                    <div class="card-image ">
                                                                                        <img src={this.state.images[0].image} alt="Blog" class="card__img0" />
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                            : ""

                                                                    }

                                                                    {this.state.images[1] ?
                                                                        <div class="card-item TwoItem">
                                                                            <Link to={`/listing-gallery/${this.state.business.business_slug}`} >
                                                                                <div class="card-image ">
                                                                                    <img src={this.state.images[1].image} alt="Blog" class="card__img0" />
                                                                                </div>
                                                                            </Link>
                                                                            <div className="TwoImages">
                                                                                {
                                                                                    this.state.images[2] ?
                                                                                        <div class="card-item">
                                                                                            <Link to={`/listing-gallery/${this.state.business.business_slug}`} >
                                                                                                <div class="card-image ">
                                                                                                    <img src={this.state.images[2].image} alt="Blog" class="card__img0" />
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                        : ""

                                                                                }
                                                                                {
                                                                                    this.state.images[2] ?
                                                                                        <div class="card-item">
                                                                                            <Link to={`/listing-gallery/${this.state.business.business_slug}`} >
                                                                                                <div class="card-image ShowCount">
                                                                                                    <div className="ImagesCount"><span>+{this.state.images.length}</span></div>
                                                                                                    <img src={this.state.images[2].image} alt="Blog" class="card__img0" />
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                        : ""

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        : ""
                                                                    }




                                                                </>
                                                                : ""

                                                        }
                                                        {/* {
                                                            this.state.images.map((image, i) => {
                                                                return (
                                                                    
                                                                );
                                                            })
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comments-wrap">
                                            <h2 className="widget-title">
                                                Recommended Reviews
                                        </h2>
                                            {(this.state.externalData) ? <ListingDetailsComments loginActivity={this.loginActivity} business={this.state.business} reviews={this.state.reviews} /> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Footer */}
                    <Footer />
                    <ScrollTopBtn />
                </main>
            </ >
        );
    }
}
export default ListingDetails;