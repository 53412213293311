import React, { Component } from 'react';
import NavbarListing from "../../components/common/NavbarListing";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { Link, Redirect } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { BsGrid, BsListUl } from "react-icons/bs";
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import Marker from '../../assets/images/map-marker.png';
import { ApiUrl, AutoApiKey } from '../../config';
import axios from 'axios';
import Autocomplete from 'react-google-autocomplete';
import { Button } from 'react-bootstrap';
import { uToken } from '../../useToken';
class ListingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            directions: null,
            myMarkers: [],
            Maps: false,
            origin: 'Islamabad , pakistan',
            destination: 'Rawalpindi , Pakistan',
            travelMode: 'DRIVING',
            company: 'FirstTruckCompany',
            center: { lat: 19.434940, lng: -99.195697 },
            MapError: false,
            zoom: 8,
            business: {
                business_lat: 19.434940, business_long: -99.195697
            },
            externalData: true,
        }

        this.handler = this.handler.bind(this);
        this.directions = this.directions.bind(this)
        this.RenderMaps = this.RenderMaps.bind(this)
    }


    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'singleBusiness', { business_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    self.setState({
                        business: res.data.business,
                        externalData: true,
                        destination: res.data.business.business_address,
                        center: { lat: res.data.business.business_lat, lng: res.data.business.business_long },
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    directions(directions) {
        this.setState({
            directions: directions
        })
    }



    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }



    apiIsLoaded = (map, maps) => {
        this.setState({
            map: map,
            maps: maps,
        })
        this.Marker();
    };

    Marker = () => {
        let maps = {};
        let map = {};
        maps = this.state.maps;
        map = this.state.map;
        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer();
        const origin = this.state.origin;
        const destination = this.state.destination;
        const travelMode = this.state.travelMode;
        console.log(this.state.destination, 'h');
        directionsService.route({
            origin: origin,
            destination: destination,
            travelMode: travelMode
        }, (response, status) => {
            if (status === 'OK') {
                console.log(this.props, 'sssssssssssssssssssssss')
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
                this.setState({
                    directions: response,
                    MapError: false,
                });
            } else {
                this.setState({
                    MapError: true,
                })
                // window.alert('Directions request failed due to ' + status);
            }
        });
    }
    RenderMaps = () => {
        this.setState({
            Maps: true
        })

        this.forceUpdate();
        // this.Marker();
    }






    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        console.log(this.state.directions, 'dir');


        return (

            <main className="directions">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                <div className="container">
                    <div className="row">
                    <div className="col-md-2"></div>
                        <div className="col-md-9">
                            <div className="DirectionMap">
                                <div className="generic-header margin-bottom-30px">
                                    <div className="filter">
                                        <p>Get directions</p>
                                        <ul className="generic-nav">
                                            <li key={0}>
                                                <Button variant="none" onClick={() => this.setState({ Maps: false, travelMode: 'DRIVING' })}
                                                    className={this.state.travelMode == 'DRIVING' ? 'active' : ''}>
                                                    <span className="d-inline-block">
                                                    <svg id="24x24_car" height="24" width="24"><path d="M22 10.219V9.5a.5.5 0 00-.5-.5H19l-1.176-3.528C17.529 4.586 16.785 4 15.955 4h-7.91c-.83 0-1.574.586-1.869 1.472L5 9H2.5a.5.5 0 00-.5.5v.719a1 1 0 00.757.97l.743.186-.3.225A3.002 3.002 0 002 14v5a1 1 0 001 1h2a1 1 0 001-1v-1h12v1a1 1 0 001 1h2a1 1 0 001-1v-5c0-.944-.445-1.833-1.2-2.4l-.3-.225.743-.186a1 1 0 00.757-.97zM8.045 6h7.91l1.143 4H6.902l1.143-4zm-.958 9H5.182a1.182 1.182 0 110-2.363c.481 0 1.492.819 2.242 1.493a.5.5 0 01-.337.87zm11.731 0h-1.905a.5.5 0 01-.337-.87c.75-.674 1.76-1.493 2.242-1.493a1.182 1.182 0 110 2.363z"></path></svg>
                                                    </span>
                                                </Button>
                                            </li>

                                            <li key={1}>
                                                <Button variant="none" onClick={() => this.setState({ Maps: false, travelMode: 'TRANSIT' })}
                                                    className={this.state.travelMode == 'TRANSIT' ? 'active' : ''}>
                                                    <span className="d-inline-block">
                                                    <svg id="24x24_transit" height="24" width="24"><path d="M16 2h-1c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1H8C6.34 2 5 3.34 5 5v11a2.99 2.99 0 002.52 2.95L6 22h2l1.5-3h5l1.5 3h2l-1.52-3.05A2.99 2.99 0 0019 16V5c0-1.66-1.34-3-3-3zM9 16c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2-4H7V5c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v7z"></path></svg>
                                                    </span>
                                                </Button>
                                            </li>

                                            

                                            <li key={3}>
                                                <Button variant="none" onClick={() => this.setState({ Maps: false, travelMode: 'WALKING' })}
                                                    className={this.state.travelMode == 'WALKING' ? 'active' : ''}>
                                                    <span className="d-inline-block">
                                                    <svg id="24x24_walk" height="24" width="24"><path d="M17.803 11.497l-3.307-.568-1.326-2.317-.619 4.197 2.173 2.827 1.8 4.459a1 1 0 01-.611 1.323l-.073.024a1 1 0 01-1.237-.558l-1.817-4.278-1.911-2.029-2.43 6.615a.946.946 0 01-1.794-.598l2.195-7.318.351-4.832-1.73.982-.524 3.5-.959-.012.022-4.338 3.376-2.401c1.08-.78 4.04-.297 4.412.209.704 1.196 1.565 3.108 1.565 3.108l2.729 1.08-.285.925zm-5.797-6.246a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"></path></svg>
                                                    </span>
                                                </Button>
                                            </li>
                                            <li key={2}>
                                                <Button variant="none" onClick={() => this.setState({ Maps: false, travelMode: 'BICYCLING' })}
                                                    className={this.state.travelMode == 'BICYCLING' ? 'active' : ''}>
                                                    <span className="d-inline-block">
                                                    <svg id="24x24_bicycle" height="24" width="24"><path d="M19.5 11c-.086 0-.167.021-.252.025l-1.58-5.566A2.007 2.007 0 0015.742 4H13v2l2.743.002.605 2.129a8.886 8.886 0 00-5.146 4.638L9.067 8H11V6H5v2h1.876l3.134 7H8.95c-.252-2.247-2.136-4-4.45-4a4.5 4.5 0 000 9c1.956 0 3.605-1.256 4.224-3h2.181l.182.408a.999.999 0 101.826-.816l-.642-1.434a7.12 7.12 0 014.553-5.349l.503 1.773C15.946 12.35 15 13.807 15 15.5a4.5 4.5 0 104.5-4.5zm-15 7.25a2.754 2.754 0 01-2.75-2.75 2.754 2.754 0 012.75-2.75 2.754 2.754 0 012.75 2.75 2.754 2.754 0 01-2.75 2.75zm15 0a2.754 2.754 0 01-2.75-2.75 2.754 2.754 0 012.75-2.75 2.754 2.754 0 012.75 2.75 2.754 2.754 0 01-2.75 2.75z"></path></svg>
                                                    </span>
                                                </Button>
                                            </li>
                                        </ul>
                                        <div className="form-group">
                                            <label className="orgin">Start From</label>
                                            <div className="AddAddress">
                                            <span><img className="media-object" src={Marker} alt="author-img" /></span>
                                            <Autocomplete
                                                apiKey={AutoApiKey}
                                                placeholder=""
                                                onPlaceSelected={(place) => {
                                                    this.setState({
                                                        origin: place.formatted_address,
                                                        Maps: false,
                                                    })
                                                }}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') e.preventDefault();
                                                }}
                                                className="form-control c1 near-feild"
                                                fields={[
                                                    'formatted_address'
                                                ]}
                                                types={[]}
                                                componentRestrictions={{ country: "us" }}
                                            />
                                            </div>
                                        </div>

                                        <div className="media m-round destination">
                                            <div className="media-left">
                                                <img className="media-object" src={Marker} alt="author-img" />
                                            </div>
                                            <div className="media-body m-body destination-name">
                                                <h4 className="media-heading m-h4">
                                                    <Link to={{
                                                        pathname: `/biz/${this.state.business.business_slug}`,
                                                    }}> {this.state.business.business_name}</Link>
                                                </h4>
                                                <address className="destination-address">{this.state.destination}	</address>
                                            </div>

                                        </div>

                                        <button type="button" className="btn get-direction" onClick={() => this.RenderMaps()}>Get Direction</button>
                                    </div>

                                    {
                                        this.state.MapError ?
                                            <div className="direction-suggestion">
                                                <p>Sorry, we can't find a route between those two places.</p>
                                            </div>
                                            :

                                            <div className="direction-suggestion">
                                                <p>{this.state.travelMode} Directions</p>
                                                {this.state.directions && (
                                                    <div>
                                                        <div className="media m-round border-bottom">
                                                            <div className="media-left">
                                                                <img className="media-object" src={Marker} alt="author-img" />
                                                            </div>
                                                            <div className="media-body m-body">
                                                                <address className="destination-address">{this.state.directions.routes[0].legs[0].end_address}	</address>
                                                            </div>
                                                        </div>
                                                        <p className="distance-calculate border-bottom">Total Distance {this.state.directions.routes[0].legs[0].distance.text}
                                                &nbsp; Duration {this.state.directions.routes[0].legs[0].duration.text}</p>
                                                        <table>
                                                            <tbody>
                                                                {this.state.directions.routes[0].legs[0].steps.map((step, i) => {
                                                                    return (
                                                                        <tr className="border-bottom">
                                                                            <td>{i + 1}.</td>
                                                                            <td dangerouslySetInnerHTML={{ __html: step.instructions }}></td>
                                                                            <td dangerouslySetInnerHTML={{ __html: step.distance.text }}></td>
                                                                        </tr>
                                                                    );
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                    }
                                </div>


                                {
                                    this.state.Maps ?
                                        <div>
                                            <div className="MapHeight" style={{ height: '100%', width: '100%' }}>
                                                <GoogleMapReact bootstrapURLKeys={{ key: AutoApiKey }}
                                                    defaultCenter={this.state.center}
                                                    defaultZoom={this.state.zoom}
                                                    onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}>
                                                </GoogleMapReact>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="MapHeight" style={{ height: '100%', width: '100%', background: '#ccc' }}>

                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>






                </div>
                {/* Footer */}
                <Footer />

                <ScrollTopBtn />
            </main>
        );


    }
}

export default ListingList;