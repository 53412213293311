import React, { Component } from 'react';
import CopyrightMenu from "./CopyrightMenu";
import CopyrightMenuMobile from "./CopyrightMenuMobile";
import { FiHeart } from 'react-icons/fi'

class Copyright extends Component {
    render() {
        return (
            <>
                <div className="k-class white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copy-right justify-content-between d-none d-lg-block d-xl-block"><CopyrightMenu /></div>
                                <div className="copy-right justify-content-between d-flex d-block d-sm-none flex-column align-items-start"><CopyrightMenuMobile /></div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default Copyright;