import React, { Component } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import review from '../../assets/images/useful-2.png';
import smile from '../../assets/images/smile-2.png';
import happy from '../../assets/images/cool-2.png';
import review_1 from '../../assets/images/review-1.png';
import smile_1 from '../../assets/images/smile-1.png';
import happy_1 from '../../assets/images/happy-1.png';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Rater from 'react-rater';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import { uToken } from '../../useToken';
import LoginBox from '../other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'
import PhotoGallery from './PhotoGallery';
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import Like from '../../assets/images/like1.png';
import Comment from '../../assets/images/comment.png';
import Share from '../../assets/images/share1.png';
import Slider from "react-slick";
import { BsDot } from 'react-icons/bs'


export default class Posts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            lists: [],
            externalData: false,
            loginTrue: false,
            currentPage: 1,
            HideShowMore: true,
            like: false,
            location: 'Philadelphia, PA'
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler1 = this.handler1.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.setState({ LoginModal: false, GalleryModal: false });
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            });

        }
        this.props.history.go('/')
        console.log(e, 'saaaaaaaaaaaaaaaaaa')
    }



    changeCoolNo = (id, activty) => {
        $('.cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('active');
        $('.cool-count-' + id).addClass('default');
        let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeCool = (id, activty) => {
        $('.cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('active');
        $('.cool-count-' + id).removeClass('default');
        let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    changeSmileNo = (id, activty) => {
        $('.smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('active');
        $('.smile-count-' + id).addClass('default');
        let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeSmile = (id, activty) => {
        $('.smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('active');
        $('.smile-count-' + id).removeClass('default');
        let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    changeUsefullNo = (id, activty) => {
        $('.usefull-' + id).removeClass('none');
        $('.no-usefull-' + id).addClass('none');
        $('.no-usefull-' + id).removeClass('active');
        $('.useful-count-' + id).addClass('default');
        let count = parseInt($('.useful-count-' + id).text());
        $('.useful-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeUsefull = (id, activty, index) => {
        var self = this;
        axios.post(ApiUrl + 'addBusinessPostLike', { 'is_like': activty, 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    let stateCopy = { ...self.state.lists }
                    let oilSite = stateCopy[index]; //Get site by index
                    oilSite.is_like = activty;
                    //Add property to site
                    stateCopy[index] = oilSite;//update array
                    //Update the state
                    self.setState(stateCopy);
                }
                // self.getBusinessList();
            })
            .catch(function (error) {
                console.log(error);
            });
    }




    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getBusinessList(count);
    }

    componentDidMount() {

        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })
        this.getBusinessList();
    }

    ShowMoreText(id) {
        $('.length-limit-' + id).css("display", "none");
        $('.hide-more-' + id).css("display", "none");
        $('.r-length-limit-' + id).css("display", "block");
    }

    getBusinessList = (count) => {
        var loc = JSON.parse(localStorage.getItem('location'));
        let data = {};
        if (loc != null) {
            data = {
                'city': loc.city,
                'region': loc.region,
                'country': loc.country
            };
        } else {


            data = {
                'city': 'Philadelphia',
                'region': 'PA',
                'country': 'USA'
            };
        }
        var self = this;
        self.setState({
            externalData: false,
        })

        // console.log(data)
        axios.post(ApiUrl + `locationBusinessPost`, data,
            {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    console.log(res.data, 'hello ars')
                    self.setState({
                        lists: res.data.lists,
                        externalData: true,
                        location: data.city + ', ' + data.region
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>

                {this.state.externalData ?
                    <div className="cities recent posts" style={{ paddingTop: "30px !important" }}>
                        <div className="container">
                            <div className='text-align-center mb-3'>

                                <h2 className='mb-0'>What’s Happening Locally</h2>
                                <br />
                                <span className='see-more'>(<Link
                                    to={{
                                        pathname: `/theblock/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                        // pathname: `/listing/${(item.name) ? item.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : ""}`,
                                    }}
                                >See More</Link>)</span>
                            </div>
                            <div className="list1">
                                <div className="row mt-5">
                                    {
                                        this.state.lists.map((item, i) => {
                                            return (
                                                <div key={i + '-' + '-' + item.full_name} className="col-md-4" >
                                                    <div className="card-item col-last first-col">
                                                        <div className="ReviewsWrote">
                                                            <div className="card-image slider-gallery">
                                                                <Slider {...settings}>
                                                                    {
                                                                        item.images.map((image, i) => {
                                                                            return (
                                                                                <div key={i} className="slide-slider">
                                                                                    <Link to={`/block/details/${item.id}`} >
                                                                                        {
                                                                                            image.type == 'video' ? (
                                                                                                <video controls
                                                                                                    preload="auto" loop=""   >
                                                                                                    <source type="video/mp4"
                                                                                                        src={image.image}>
                                                                                                    </source>
                                                                                                </video>
                                                                                            ) :
                                                                                                <img src={image.image} />
                                                                                        }
                                                                                    </Link>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Slider>
                                                            </div>
                                                            <div className="media m-round last-col">
                                                                <div className="media-left">
                                                                    <div className="User_Info">
                                                                        <Link to={`/biz/${item.business_slug}`}>
                                                                            <img width="100%" height="100%" className="media-object" src={item.business_logo} alt="author-img" />
                                                                        </Link>

                                                                        <div className="media-body m-body">
                                                                            <h4 className="media-heading m-h4">
                                                                                <Link to={`/biz/${item.business_slug}`}>{item.business_name}</Link>
                                                                            </h4>
                                                                            <p className="m-p">{item.created_at}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="post-desc">
                                                                    <h1>{item.title}</h1>
                                                                    <p className={`descs`}> {item.description} </p>
                                                                </div>
                                                            </div>



                                                            <div className={`media-body item-heading b-heading ${item.post_type_color_code}`}>
                                                                <div className="bus-name">
                                                                    <h4 className="media-heading m-h4">
                                                                        <Link to="#" > {item.business_name}</Link>
                                                                    </h4>
                                                                    <span className="post-zipcode-meta" >{item.post_type_title} <BsDot /> Located in {item.zipcode}</span>
                                                                </div>

                                                                {
                                                                    item.link != null && (
                                                                        <div className="learn-btn"><a href={item.link} target={'_blank'} className="learn">
                                                                            {
                                                                                item.link_text != null ? item.link_text : "Learn More"
                                                                            }
                                                                        </a></div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        {(!uToken()) ?
                                                            <div className="user_review">
                                                                <Button variant="none" title="Like"
                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                >
                                                                    <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="Like" /><span>Like</span>
                                                                </Button>


                                                                <Button variant="none" title="Comment"
                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                >
                                                                    <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="Comment" /><span>Comment</span>
                                                                </Button>

                                                            </div>
                                                            :
                                                            <div className="user_review">

                                                                {
                                                                    item.is_like == 0 ? (
                                                                        <Button variant="none" title="Like"
                                                                            onClick={() => this.changeUsefull(item.id, 1, i)}
                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                        >
                                                                            <RiThumbUpLine /> Like
                                                                            {/* <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="Like" /><span>Like</span> */}
                                                                        </Button>
                                                                    ) :
                                                                        (
                                                                            <Button variant="none" title="Like"
                                                                                onClick={() => this.changeUsefull(item.id, 0, i)}
                                                                                className={`btn btn-link card__img img-thumbnail `}
                                                                            >
                                                                                <RiThumbUpFill /> Like
                                                                                {/* <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="Like" /><span>Like</span> */}
                                                                            </Button>
                                                                        )
                                                                }

                                                                <Link py={true} smooth={true} to={`/block/details/${item.id}`}
                                                                    className={`btn btn-link card__img img-thumbnail default mr-0`}>
                                                                    <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="Comment" /><span>Comment</span>
                                                                </Link>



                                                                {/* <Button variant="none" title="Comment"
                                                                    onClick={() => console.log('hi')}
                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                >
                                                                    <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="Comment" /><span>Comment</span>
                                                                </Button> */}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                </div>
                            </div>


                            <div className="modal-form text-center">
                                <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                                    <div className="modal-bg"></div>
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content p-4">
                                            <div className="modal-top border-0 mb-4 p-0">
                                                <div className="alert-content">
                                                    {this.state.click ? <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* <h5>Login required to perform this action </h5> */}
                                    <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                                </Modal.Body>
                            </Modal>

                            {/* {
                                this.state.HideShowMore ?
                                    <button onClick={() => this.sendRequest(this.state.currentPage + 1)} className="remove-btn-bg border-0 mr-2" >
                                        <span className=""><IoIosArrowDown /> Show more activity</span>
                                    </button>
                                    : ""
                            } */}
                        </div>
                    </div>
                    : ""
                }
            </>
        )
    }

}
