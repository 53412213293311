import React, { Component } from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import NavbarListing from '../../components/common/NavbarListing'
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Rater from 'react-rater'
import 'react-tabs/style/react-tabs.css';
import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'
import { AiFillCamera } from 'react-icons/ai'
import PhotosUploader from "../../components/user/PhotosUploader";
import { RiSearchLine } from 'react-icons/ri'
import axios from 'axios';
import { ApiUrl } from '../../config';
import { ToastContainer, toast } from 'react-toastify';

class PhotoUploader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            location: '',
            externalData: false,
            business_id: '',
            business_name: '',
            props_data: this.props.location
        }
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        this.getBusinessReview();
    }

    close(message) {
        if (message) {
            toast.success(message);
            this.setState({
                externalData: false,
            })
            this.getBusinessReview();
        }
    }

    getBusinessReview = () => {
        var self = this;
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'getBusinessReviews', { business_slug: id }, {})
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        externalData: true,
                        business_name: res.data.business_name,
                        business_id: res.data.business_id,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        const { id } = this.props.match.params;
        return (
            <main className="UploadPhotos">
                <div className="navbars">
                    {/* Header */}
                    <NavbarListing state={this.props.location ? this.props.location.state : ""} handler={this.handler} />
                </div>
                <Container>
                    <Row>
                        <Col lg={2} md={2}></Col>
                        <Col lg={8} md={8}>
                            <div className="UploadPhotosHeading margin-top-20px margin-bottom-10px">
                                <h1>{this.state.business_name}: <span>Add Photos</span></h1>
                                <Link to={{
                                    pathname: `/listing-gallery/${id}`,
                                    state: {
                                        search: this.state.props_data.state ? this.state.props_data.state.search : "",
                                        location: this.state.props_data.state ? this.state.props_data.state.location : "",
                                    }
                                }}> View All Photos</Link>
                                {/* <a href="#"></a> */}
                            </div>
                            <div className="add-review-photo">
                                {(this.state.externalData) ?
                                    <>
                                        <PhotosUploader closeModal={this.close.bind(this)} business_id={this.state.business_id} />
                                    </>
                                    : ""
                                }
                            </div>
                            <div className="instructions">
                                <div className="instruction">
                                    <div className="pic"></div>
                                    <p>Refrain from posting shaky or out of focus photos.</p>
                                </div>
                                <div className="instruction">
                                    <div className="pic"></div>
                                    <p>Your photos should be well lit and bright.  Don’t be afraid to use the flash on your camera.</p>
                                </div>
                                <div className="instruction">
                                    <div className="pic"></div>
                                    <p>If you’re applying filters, don’t overdo them. Subtlety is key.</p>
                                </div>
                                <div className="instruction">
                                    <div className="pic"></div>
                                    <p>Lastly please only post photos of the business.</p>
                                </div>
                            </div>
                            <div className="empty-space-50"></div>
                        </Col>
                        <Col lg={2} md={2}></Col>
                        <ToastContainer />
                    </Row>
                </Container>




                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default PhotoUploader;