import React, { Component } from 'react';
import SignUpBox from "../components/other/account/SignUpBox";
// import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import ImageSignUp from '../assets/images/katika_signup.png';
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/katika-logo_wht.svg';
import { Container, Row, Col } from "react-bootstrap";
import { BiCheckCircle } from 'react-icons/bi';

class SignUp extends Component {
    state = {
        SignUpImg: ImageSignUp,
    }
    render() {
        return (
            <main className="signup-page login-page">
                <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-12">
                                <div className="logo listing center ">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
                {/* Header */}
                {/* <GeneralHeader /> */}

                {/* Breadcrumb 
                <Breadcrumb CurrentPgTitle="Sign Up" img={this.state.breadcrumbimg} />*/}

                <section className="form-shared  login-page padding-top-40px padding-bottom-50px">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-1"></div> */}
                            {/* <div className="col-lg-6 signup-picture">
                                <div className="login-detais">
                                    <h5>Inclusion made easy.<sup><small>TM</small></sup></h5>
                                    <p className="muted">No filters or labels required.</p>
                                </div>
                                <div className="user-img-place"><img key={0} src={this.state.SignUpImg} alt="SignUpImage" className="random-img" /></div>
                                <div className="content-area-signup">
                                    <ul>
                                        <li><span className="check"><BiCheckCircle /> </span> AI Powered search engine providing instant search results</li>
                                        <li><span className="check"><BiCheckCircle /></span> Discover new businesses through Katika video stories</li>
                                        <li><span className="check"><BiCheckCircle /></span> Message with businesses directly when you have a question</li>
                                        <li><span className="check"><BiCheckCircle /></span> Create group chats with friends & family to share businesses</li>

                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-6 offset-lg-3">
                                <div className='SignUpSelection LoginSection padding-top-50px padding-bottom-50px padding-left-40px padding-right-40px margin-top-50px text-center margin-bottom-50px'>
                                    <SignUpBox title="Sign up for Katika" />
                                </div>
                            </div>

                            {/* <div className="col-lg-1"></div> */}
                        </div>
                    </div>
                </section>


                {/* Newsletter 
            <NewsLetter />*/}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default SignUp;