import React, { Component } from 'react';
import WidgetAuthor from "./widgets/WidgetAuthor";
import WidgetStaticsInfo from "./widgets/WidgetStaticsInfo";
import WidgetCategory from "./widgets/WidgetCategory";
import WidgetCertification from "./widgets/WidgetCertification";
import SocialMedia from "./widgets/SocialMedia";


class ListingDetailsSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            business: this.props.business,
            loginActivity: this.props.loginActivity,
        }
    }
    render() {
        return (
            <>

                <div className="sidebar section-bg details-sidebar">
                    <WidgetAuthor loginActivity={this.state.loginActivity} business={this.state.business} />
                    <WidgetStaticsInfo loginActivity={this.state.loginActivity} business={this.state.business} />
                    {/* {
                        this.state.business.business_certificates.length > 0 ?

                            <WidgetCertification business={this.state.business} />
                            : ""
                    } */}
                    <WidgetCategory business={this.state.business} />
                    <SocialMedia business={this.state.business} />


                </div>
            </>
        );
    }
}

export default ListingDetailsSidebar;