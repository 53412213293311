import React, { useState } from 'react'
// import HomePageHeader from '../../components/business/common/PageHeader'
import HomePageHeader from '../../components/webapp_view/common/Navbar'
import BannerOne from '../../components/business/BannerOne'
import BannerMobile from '../../components/business/BannerMobile'
import Testimonial from '../../components/business/Testimonial'
import TestimonialTwo from '../../components/business/TestimonialTwo'
import { Row, Col } from "react-bootstrap";
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Link } from 'react-router-dom'
import kVideo from "../../assets/images/k-video.png";
import SlideOne from '../../assets/images/SlideOne.png';
import SlideTwo from '../../assets/images/SlideTwo.png';
import SlideThree from '../../assets/images/SlideThree.png';
import Community from '../../assets/images/community.png';
import BizOne from '../../assets/images/BizOne.png';
import BizTwo from '../../assets/images/BizTwo.png';
import BizThree from '../../assets/images/BizThree.png';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { sellerUrl } from '../../config'
import { random_item } from '../../useToken'

const Business = () => {

    const [items, setItems] = useState([
        {
            title: 'What business types are eligible for Katika business?',
            desc: `Businesses that register on Katika must be owned (50% +)
                by a person of African Descent. If you have never marked down Black,
                African or Caribbean on any type of application or census, 
                you do not qualify to be a business on Katika. Individuals with multi-level 
                marketing companies such as Herbalife, Total Life Changes, and ACN are not permitted to register on Katika.
                <br> <br> For-profit, non-profit and government agencies can create accounts on Katika.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'What are the benefits of joining Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is 
                    constantly seeking your products and services. We are focused on connecting local consumer to the businesses in
                    their neighborhoods or adjacent. We provide exposure for your brand, send information about funding opportunities
                    and events to participate in. Katika is working to facilitate dollars staying within communities for a longer 
                    period of time by making it easy for people to find local services and products in their neighborhoods.
                    Katika also provides educational resources to help close the information and education gap between business owners.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to join Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is constantly seeking your
             products and services. We are focused on connecting local consumer to the businesses in their
              neighborhoods or adjacent. We provide exposure for your brand, send information about funding
               opportunities and events to participate in. Katika is working to facilitate dollars staying 
               within communities for a longer period of time by making it easy for people to find local
                services and products in their neighborhoods. Katika also provides educational resources 
                to help close the information and education gap between business owners.<br>  <br>
                1. Go to <a target="_blank" href="${sellerUrl}"> ${sellerUrl} </a> and select Create Your Free Business Page <br>  <br>
                2. Fill out the organization's information on the 2 forms filling out your personal and business information.
                You will need to go to the email address you listed for the business and click on the confirmatin link 
                inside of the email to verify your account. <br>  <br> 
                3. Login to your account from <a  target="_blank" href="${sellerUrl}/sign-in" >${sellerUrl}/sign-in</a> and finish populating 
                your business profile. Your page will become live when your profile has 70% of the informatin filled out.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to claim your business page',
            desc: `Business information on your existing Katika for Business page was sourced from 3rd party public data, 
            or Katika Team Members who've created your profile. Your login information was sent to you when the profile was created. 
            If you need to claim your business page, please email us on our Contact Page.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to post on Katika as a business?',
            desc: `Businesses with a paid account can post to our neighborhood newsfeed called
             “The Block”. In our newsfeed businesses can create 4 types of post: General announcements, Useful Information, Events, and Promotions.
             To post on Katika as a business, following the steps below: <br> <br>
            1. Log in to your Katika for Business Page.<br> <br>
            2. Head to the ‘Create New Post’  tab in the left menu.Select the pencil icon and enter the zip code that you want to target with your post.<br> <br>
            3. Select your Post Type, and enter a title which describes the post, followed by a detailed description.<br> <br>
            4. You have the option to add a link to take your customer to a specific destination.<br> <br>
            5. If you use entered a link, then you will need to select a button option from the drop down menu.<br> <br>
            6. You must add a graphic or video to your post.<br> <br>
            7. Select Post to send your post to the newsfeed wall.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },

        // {
        //     title: 'Is there a phone app for Katika?',
        //     desc: 'The Android apps for Katika will be available in October. The iOS apps will be available by the end of the year. For general users, the app is called Katika. For business owners, the app is called Katika for Business.',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: 'mb-3'
        // },
        // {
        //     title: 'How do I get paid?',
        //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: 'mb-3'
        // },
        // {
        //     title: 'How do I sell my products in other Katika stores?',
        //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
        //     plus: <AiOutlinePlus />,
        //     minus: <AiOutlineMinus />,
        //     cardClass: ''
        // },

    ])
    return (
        <main className="BusinessHome BizHome">
            {/* Header */}
            <HomePageHeader />

            <div className='d-none d-lg-block d-xl-block'>


                {/* Hero Banner */}
                <BannerOne />

                {/* {Slider} */}
                <div className="katika-wraper"><div className="container"><h1>Katika was created for people to discover <br></br>local black businesses like yours.</h1></div></div>
                <section className="presence-content">
                    <div class="container">
                        <div className="row">
                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence BusinessInfo">
                                    <h1>Build a local online<br></br>presence with a Katika for<br></br>Business Page</h1>
                                    <p>Set up a Katika for Business profile in your neighborhood where local customers are searching for and recommending businesses like yours. </p>
                                    <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="content-presence">
                                    <img src={SlideOne} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div class="content-presence">
                                    <img src={SlideTwo} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="content-presence BusinessInfo">
                                    <h1>Get the word out locally<br></br>through Business Posts</h1>
                                    <p>Share updates, crowd source feedback, and draw in new customers with a post in the most visible place on Katika, “The Feed” wall.  Katika business posts can help you build and nurture relationships with local customers in your specific neighborhood who are most likely to support you.</p>
                                    <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link>
                                </div>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-12 bottom">
                                <div class="content-presence BusinessInfo">
                                    <h1>Engage with customers,<br></br>businesses and organizations<br></br>with messaging.</h1>
                                    <p>Message with other businesses and organizations on the platform empowering you to strenghten your network.  Engage with customers through Katika.</p>
                                    <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="content-presence">
                                    <img src={SlideThree} />
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="testimonial-area padding-top-0px padding-bottom-60px text-center">
                    <div className="container-fluid">
                        <div className="row section-title-width text-center">
                            <div className="Rectangle Video">
                                <Row>
                                    <Col sm={6} lg={6}>
                                        <div className="VideoContent">
                                            <img src={Community} />
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={6}>
                                        <div className="VideoBox">
                                            <h1>Watch free business<br></br>education videos</h1><br></br>
                                            <p>Understanding basic to advanced business practices is key for business owners to become successful. We provide our Katika for business users with a free library of  educational videos to support all stages of entrepreneurship from setup, to branding, to operations, and more!</p>
                                            <Link to={'/business-sign-up'} className="btn btn-default px-5">Get Started Today</Link>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonial-area padding-top-30px text-center">
                    <div className="container">
                        <div className="row section-title-width text-center border-bottom">
                            {/* <div className="BHeading">
                            <h2>Here’s what other business owners on <span>Katika</span> have to say</h2>
                        </div>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “People love scheduling<br></br>  their appointments on Katika”
                                </div>
                                <div className="OwnerName">
                                    Jennifer Gore, CEO of Weleet
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “Katika allows me to message my customers directly.”
                                </div>
                                <div className="OwnerName">
                                    Jamal Rashad, CEO of Mashful
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “I love getting discovered and booked all in one place now, it makes my life easier.”
                                </div>
                                <div className="OwnerName">
                                    Christina Gore, Music DJ
                                </div>
                            </div>
                        </Col>
                         */}
                            <Col sm={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                                <div className="FaqsQuestion padding-bottom-100px">
                                    <div className="SubHeading padding-bottom-30px">Frequently Asked Questions</div>
                                    <section className="faq-area">
                                        <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                            {items.map((item, i) => {
                                                return (
                                                    <div className={'card ' + item.cardClass} key={i}>
                                                        <AccordionItem>
                                                            <AccordionItemHeading className="card-header">
                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                    {item.title}
                                                                    <i className="minus">{item.minus}</i>
                                                                    <i className="plus">{item.plus}</i>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className="card-body text-align-left">
                                                                    <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    </div>
                                                )
                                            })}

                                        </Accordion>
                                    </section>
                                </div>
                            </Col>
                        </div>

                    </div>
                </section>

            </div>

            <div className='d-block d-sm-none'>
                <div className='MobileView'>
                    <BannerMobile />
                    <div className="text-center my-4 mb-5">
                        <div className="AppContent text-center padding-bottom-50px">
                            <p><b>Katika for business</b> connects your <b>black business</b> to shoppers that live in your community.</p>
                            <a target='_blank' href={random_item(['https://apps.apple.com/us/app/katika/id1595089103', 'https://play.google.com/store/apps/details?id=com.katikabuyer'])} className="btn btn-primary rounded mt-4">Get the App</a>
                        </div>
                        <b className="color-primary mb-3">Available on iOS, Android and Web</b>
                    </div>
                    <div className="katika-wraper"><div className="container"><h1>Katika was created for people to discover local black businesses like yours.</h1></div></div>
                    <section className="presence-content">
                        <div class="container">
                            <div className="row">
                                <div className="col-lg-6 col-12 bottom">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Build a local online<br></br>presence with a Katika<br></br>for Business Page</h1>
                                        <Row className='mt-4'>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizOne} /></div>
                                            </div>
                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Set up a Katika for Business profile in your neighborhood where local customers are searching for and recommending businesses like yours. </p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-12">
                                    <div class="content-presence">
                                        <img src={SlideOne} />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Get the word out locally<br></br>through Business Posts</h1>
                                        <Row className='mt-4'>

                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Share updates, crowd source feedback, and draw in new customers with a post in the most visible place on Katika, ‘The Feed’ wall.  Katika business posts can help you build and nurture relationships with local customers in your specific neighborhood who are most likely to support you.</p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizTwo} /></div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-6 col-12 bottom">
                                    <div class="content-presence BusinessInfo">
                                        <h1>Engage with customers, businesses and organizations with messaging.</h1>
                                        <Row className='mt-4'>
                                            <div className='col-5'>
                                                <div className='MobileBiz'><img src={BizThree} /></div>
                                            </div>
                                            <div className='col-7'>
                                                <p className='w-100 mt-0'>Message with other businesses and organizations on the platform empowering you to strenghten your network.  Engage with customers through Katika.</p>
                                                {/* <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> */}
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <Row className='mt-1 mb-5'>
                                <div className='col-lg-12'>
                                    <div className='GetStarted'>
                                        <button className='btn btn-primary rounded-50'>GET STARTED NOW</button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </section>
                    <section className="testimonial-area padding-top-0px padding-bottom-60px text-center">
                        <div className="container-fluid">
                            <div className="row section-title-width text-center">
                                <Col lg={12}>
                                    <div className="Rectangle Video">
                                        <Row>
                                            <Col sm={6} lg={6}>
                                                <div className="VideoContent">
                                                    {/* <img src={Community} /> */}
                                                    <div className="ratio ratio-16x9">
                                                        {/* <iframe
                                                        src="https://www.youtube.com/embed/vlDzYIIOYmM"
                                                        title="YouTube video"
                                                        allowfullscreen
                                                    ></iframe> */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={6} lg={6}>
                                                <div className="VideoBox text-center">
                                                    <h1 className="text-start">Watch free business education videos</h1>
                                                    <p className="text-start">Understanding basic to advanced business practices is key for business owners to become successful. We provide our Katika for business users with a free library of  educational videos to support all stages of entrepreneurship from setup, to branding, to operations, and more!</p>
                                                    {/* <Link to={'/business-sign-up'} className="btn btn-primary rounded">WATCH MORE VIDEOS</Link> */}
                                                    <Col lg={12}>
                                                        <div className="GetStartedBtn mt-5">
                                                            <button className="btn btn-primary rounded-50">WATCH MORE VIDEOS</button>
                                                        </div>
                                                    </Col>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </section>
                    <section className="testimonial-area padding-top-30px padding-bottom-0px text-center">
                        <div className="container">
                            <div className="row section-title-width text-center">
                                {/* <div className="BHeading">
                            <h2>Here’s what other business owners on <span>Katika</span> have to say</h2>
                        </div>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “People love scheduling<br></br>  their appointments on Katika”
                                </div>
                                <div className="OwnerName">
                                    Jennifer Gore, CEO of Weleet
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “Katika allows me to message my customers directly.”
                                </div>
                                <div className="OwnerName">
                                    Jamal Rashad, CEO of Mashful
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4}>
                            <div className="OwnersSay">
                                <div className="img-area">
                                </div>
                                <div className="comment">
                                    “I love getting discovered and booked all in one place now, it makes my life easier.”
                                </div>
                                <div className="OwnerName">
                                    Christina Gore, Music DJ
                                </div>
                            </div>
                        </Col>
                         */}
                                <Col sm={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                                    <div className="FaqsQuestion padding-bottom-100px">
                                        <div className="SubHeading padding-bottom-30px text-start">Frequently Asked Questions</div>
                                        <section className="faq-area">
                                            <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                                {items.map((item, i) => {
                                                    return (
                                                        <div className={'card ' + item.cardClass} key={i}>
                                                            <AccordionItem>
                                                                <AccordionItemHeading className="card-header">
                                                                    <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                        {item.title}
                                                                        <i className="minus">{item.minus}</i>
                                                                        <i className="plus">{item.plus}</i>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="card-body text-align-left">
                                                                        <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </div>
                                                    )
                                                })}
                                            </Accordion>
                                        </section>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Business;