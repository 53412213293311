import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import ScheduleTabs from "../";
import ScheduleTabs from "../../components/user/ScheduleTabs";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaInfo } from 'react-icons/fa';

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            externalData: false,
            business: {
                complete_percent: '',
                business_service_type: 'Place an Order',
                calendly_url: '',
            },
            checkboxes: []
        };
        this.serviceType = this.serviceType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    serviceType(e) {
        if (e == 'Place an Order' || e == 'Make an Appointment') {
            this.setState({ checked: true });
        } else {
            this.setState({ checked: false });
        }
    }

    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getSelectedCallToAction', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // if (res.data.data.business_service_type == 'Place an Order'
                //     || res.data.data.business_service_type == 'Make an Appointment') {
                //         self.setState({ checked: true });
                // } else {
                //     self.setState({ checked: false });
                // }

                self.setState({
                    externalData: true,
                    checkboxes: res.data.data,
                })


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit(event, errors, values) {
        if (errors.length < 1) {
            axios.post(ApiUrl + 'callToAction', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    toggleCheckBox = (event, toggledIndex, toggledValue) => {
        event.preventDefault();
        if (toggledValue == 'Yes') {
            this.state.checkboxes[toggledIndex].select = 'No'
        } else {
            this.state.checkboxes[toggledIndex].select = 'Yes'
        }
        this.forceUpdate()
    };


    render() {
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            {(this.state.externalData) ? <Sidebar percent={this.state.business.complete_percent} /> : ""}
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area security">
                                <div className="y-page-link call-to-act">
                                    <h4>Call to Action
                                        <div className="info-box-overlay">
                                            <OverlayTrigger
                                                delay={200}
                                                trigger={["hover", "hover"]}
                                                placement={'auto'}
                                                overlay={
                                                    <Popover id={`popover-positioned`}>
                                                        <Popover.Title as="h3">Call to Action
                                                        </Popover.Title>
                                                        <Popover.Content>
                                                            Creates a button on your business detail page that sends a customer to a destination of your choice like ordering food, booking a date, or to an e-commerce site.
                                                        </Popover.Content>
                                                    </Popover>
                                                }>
                                                <FaInfo />
                                            </OverlayTrigger>
                                        </div>
                                    </h4>
                                    <div className="call-action">
                                        <div className="call-action-form">
                                            <div className="api-key">
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <div className="fields">
                                                                <label>You can send visitors on your Katika page to a specific destination by selecting an engagement option below.</label>

                                                                {
                                                                    this.state.checkboxes.map((checkbox, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <div className="custom-legend1 legend-2">
                                                                                    <label>{checkbox.label} {checkbox.type == 'tel' ? '(Mobile Only)' : ''} </label>
                                                                                    <AvRadioGroup value={checkbox.select} inline name={`select-${index}`} onClick={(e) => this.toggleCheckBox(e, index, checkbox.select)} required errorMessage="Pick one!">
                                                                                        <AvRadio customInput label="Yes" value='Yes' />
                                                                                        <AvRadio customInput label="No" value='No' />
                                                                                    </AvRadioGroup>
                                                                                    <AvInput
                                                                                        type="hidden"
                                                                                        className="form-control"
                                                                                        name={`name-${index}`}
                                                                                        value={checkbox.name}
                                                                                        required />
                                                                                    <AvInput
                                                                                        type="hidden"
                                                                                        className="form-control"
                                                                                        name={`type-${index}`}
                                                                                        value={checkbox.type}
                                                                                        required />
                                                                                </div>
                                                                                {
                                                                                    checkbox.select == 'Yes' ?
                                                                                        <>
                                                                                            {
                                                                                                checkbox.type != "button" ?
                                                                                                    <div className="key-field key-field1">
                                                                                                        <AvGroup>
                                                                                                            <AvInput
                                                                                                                placeholder={checkbox.placeholder}
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                name={`url-${index}`}
                                                                                                                value={checkbox.url}
                                                                                                                required />
                                                                                                            <AvFeedback>This field is invalid</AvFeedback>
                                                                                                        </AvGroup>
                                                                                                        {/* <label className="e-small">Paste URL or API Key</label> */}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <AvGroup>
                                                                                                        <AvInput
                                                                                                            placeholder="Paste URL or API Key"
                                                                                                            type="hidden"
                                                                                                            className="form-control"
                                                                                                            name={`url-${index}`}
                                                                                                            value='request'
                                                                                                            required />
                                                                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                                                                    </AvGroup>
                                                                                            }

                                                                                        </>
                                                                                        : ""
                                                                                }
                                                                            </div>

                                                                        );
                                                                    })
                                                                }

                                                                {/* <div className="key-field">
                                                                    <AvField type="select"
                                                                        name="business_service_type"
                                                                        onChange={(e) => this.serviceType(e.target.value)}
                                                                        value={this.state.business.business_service_type}
                                                                        required
                                                                    >
                                                                        <option value="">Select service</option>
                                                                        <option value="Place an Order">Place an Order</option>
                                                                        <option value="Request a Quote">Request a Quote</option>
                                                                        <option value="Request a Virtual Consultation">Request a Virtual Consultation</option>
                                                                        <option value="Make an Appointment">Make an Appointment</option>
                                                                    </AvField>

                                                                </div>
                                                                {
                                                                    (this.state.checked) ?
                                                                        <>
                                                                            <div className="empty-space-50"></div>
                                                                            <div className="key-field">
                                                                                <AvGroup>
                                                                                    <AvInput
                                                                                        type="url"
                                                                                        className="form-control"
                                                                                        name="calendly_url"
                                                                                        value={this.state.business.calendly_url}
                                                                                        required />
                                                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                                                </AvGroup>
                                                                                <label className="e-small">Paste URL or API Key</label>
                                                                            </div>
                                                                            <div className="empty-space-50"></div>
                                                                        </>
                                                                        : ""
                                                                } */}

                                                                <div className="password save-button">
                                                                    <Button type="submit" disabled={this.state.checkboxes.length < 1 ? true : false} className="r-button-reply" variant="primary">
                                                                        Save</Button>
                                                                </div>
                                                            </div>

                                                        </AvForm>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;