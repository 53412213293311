import React, { Component } from 'react';
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Footer from "../../components/common/footer/Footer";
import BuyerForm from "../../components/user/BuyerForm";
import { Container } from "react-bootstrap";
import '../../assets/css/style2.css';
import { Link, Redirect } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import NavbarListing from '../../components/common/NavbarListing';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';

class PrivacySettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: '',
            user: '',
            externalData: false,
            address: '',
            auto: false,
            setting: {
                search_by_name_email: 0,
                bookmark_public: 0,
                direct_message: 0,
                ads: 0,
                about_me: '',
            },
        }
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        this.getPrivacySetting();

    }

    getPrivacySetting = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getBuyerPrivacySetting', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        setting: res.data.lists,
                        externalData: true,
                    })
                } else {
                    self.setState({
                        externalData: true,
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleSubmit = async (event, errors, values) => {
        console.log(errors, 'assa', values)
        let self = this;
        axios.post(ApiUrl + 'updateBuyerPrivacySetting', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }


    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerEmailNoti">
                {/* Header */}
                {/* <GeneralHeader /> */}
                <NavbarListing handler={this.handler} />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}



                <Container>

                    <div className="Buyer-wrapper BuyerEmail">
                        <div className="sidebarBuyer">
                            <BuyerSidebarTwo />
                        </div>
                        {this.state.externalData ?
                            <div className="BuyerContent">
                                <div className="faq-forum">
                                    <div className="contact-form-action">
                                        <AvForm onSubmit={(event, errors, values) => this.handleSubmit(event, errors, values)} id="form-button">

                                            <div className="BuyerEmailList">
                                                <div className="BuyerEmail">
                                                    <div className="BuyerPagesHeading">Your Profile</div>
                                                    <p>Manage the visibility of your profile</p>
                                                </div>
                                            </div>
                                            <div className="EmailKatika">
                                                <AvInput value={this.state.setting.search_by_name_email == 0 ? false : true} tag={CustomInput} type="checkbox" name="search_by_name_email" label=" Let others find my profile using my name or email address" />
                                                <p>Users added as friends can always find your profile</p>
                                            </div>
                                            <div className="SettingSubHeading">Bookmarks</div>
                                            <div className="EmailKatika">
                                                <AvInput value={this.state.setting.bookmark_public == 0 ? false : true} tag={CustomInput} type="checkbox" name="bookmark_public" label="Make my bookmaks public" />

                                            </div>
                                            <div className="SettingSubHeading">Direct Messages from Businesses</div>
                                            <div className="EmailKatika">
                                                <AvInput value={this.state.setting.direct_message == 0 ? false : true} tag={CustomInput} type="checkbox" name="direct_message" label="Allow business owners to send you direct messages in reponse to your review" />
                                            </div>
                                            <div className="SettingSubHeading">Ads Displayed Elsewhere</div>
                                            <div className="EmailKatika">
                                                <AvInput value={this.state.setting.ads == 0 ? false : true} tag={CustomInput} type="checkbox" name="ads" label="Ads Displayed Elsewhere" />
                                            </div>



                                            <div className="BuyerEmailList SettingPage">
                                                <div className="BuyerEmail">
                                                    <div className="BuyerPagesHeading padding-top-50px">Businss Visibility Settings</div>
                                                    <p>Manage what a business sees when you interact with its Katika listing.</p>
                                                </div>
                                            </div>
                                            <div className="PrivacyDesc">
                                                <p>These settings govern how businesses will see actions you take Katika, such as mobile calls, directions
                                        requests, map views, and visits to a business’s website.  The date, time, and whether you’re acceesing Katika via web or mobile device are always shown.</p>
                                                <p>Businesses can always see your public contributions (like reviews and photos), and information about your transactions with them through Katika.</p>
                                            </div>
                                            <div className="SeeAbout">
                                                <div className="Heading">What businesses can see about me</div>
                                                <AvRadioGroup value={this.state.setting.about_me} name="about_me" required errorMessage="Pick one!">
                                                    <div className="input-box">
                                                        <AvRadio value='Profile' label="My name and Katika profile" />
                                                        <p className="note">First name and last initial, link to profile, gender, and age range (20, 30s, etc.).</p>
                                                    </div>
                                                    <div className="input-box">
                                                        <AvRadio value='Demographics' label="My demographics" />
                                                        <p className="note">Gender and age range (20, 30s, etc.).</p>
                                                    </div>
                                                    <div className="input-box">
                                                        <AvRadio value='Basic' label="Basic information" />
                                                    </div>
                                                </AvRadioGroup>

                                            </div>
                                            <div className="EmailAdd Settings">
                                                <button>Save Settings</button>
                                                <Link to="/Path" >Cancel</Link>
                                            </div>

                                        </AvForm>
                                    </div>
                                </div>

                            </div>
                            : ""
                        }
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default PrivacySettings;