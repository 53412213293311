import { useState } from 'react';
import axios from "axios";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}

export function random_item(items) {
  return items[Math.floor(Math.random()*items.length)];
}

export function uToken() {
  const tokenString = localStorage.getItem('token');
  let token = JSON.parse(tokenString);
  if (token) {
    return token.token
  } else {
    return '';
  }

}

export function uInfo() {
  const tokenString = localStorage.getItem('token');
  let token = JSON.parse(tokenString);
  if (token) {
    return token
  } else {
    return '';
  }

}

export function uLocation() {
  try {
    const tokenString = localStorage.getItem('location');
    if(tokenString){
      let location = JSON.parse(tokenString);
      return location;
    }else{
      return {
        'city': 'Philadelphia',
        'region': 'PA',
        'country': 'USA',
        'country_name': 'USA',
        'latitude': 39.9525839,
        'longitude': -75.1652215,
      }
    }
  } catch (error) {
    return {
      'city': 'Philadelphia',
      'region': 'PA',
      'country': 'USA',
      'country_name': 'USA',
      'latitude': 39.9525839,
      'longitude': -75.1652215,
    }
  }
}


export function uRole() {
  const tokenString = localStorage.getItem('token');
  let token = JSON.parse(tokenString);
  if (token) {
    return token.role
  } else {
    return -1
  }

}

export function getGeoInfo1() {
  let loc = '';
  var location = JSON.parse(localStorage.getItem('location'));
  if (!location) {
    axios.get('https://ipapi.co/json/').then((response) => {
      if (response.data.country_name != 'United States') {
        let data = {
          'city': 'New York',
          'region': 'NY',
          'country': 'USA',
          'country_name': 'USA',
          'latitude': 40.7128,
          'longitude': -74.0060,
        };
        localStorage.setItem('location', JSON.stringify(data));
        loc = 'New York, NY, USA'
      } else {
        localStorage.setItem('location', JSON.stringify(response.data));
        loc = response.data.city + ',' + response.data.country;
      }
      return loc;
    }).catch((error) => {
      console.log(error);
      let data = {
        'city': 'New York',
        'region': 'NY',
        'country': 'USA',
        'country_name': 'USA',
        'latitude': 40.7128,
        'longitude': -74.0060,
      };
      localStorage.setItem('location', JSON.stringify(data));
      loc = 'New York, NY, USA'
      return loc;
    });
  } else {
    loc = location.city + ', ' + location.region + ', ' + location.country;
    return loc;
  }


}

function showLocation(position) {
  var latlongvalue = position.coords.latitude + ","
    + position.coords.longitude;
  var img_url = "https://maps.googleapis.com/maps/api/geocode/json?latlng="
    + latlongvalue + "&key=AIzaSyDQy9sCLvDj2anzl8152anNw-nXC3rBpik&fields=[address_components,place_id,formatted_address,name]&types=[regions]";

  axios.get(img_url).then((response) => {
    let loc = '';
    response.data.results.map(item => {
      if (item.types[0] == 'locality' && item.types[1] == 'political') {
        let city = '';
        let region = '';
        let country = '';
        item.address_components.map(adre => {
          if (adre.types[0] == 'locality' && adre.types[1] == 'political') {
            city = adre.short_name;
          }
          if (adre.types[0] == 'administrative_area_level_1' && adre.types[1] == 'political') {
            region = adre.short_name;
          }

          if (adre.types[0] == 'country' && adre.types[1] == 'political') {
            country = adre.short_name;
          }
          let latitude = item.geometry.location.lat;
          let longitude = item.geometry.location.lng;
          var data = {};
          if (country != 'US') {
            data = {
              'city': 'Philadelphia',
              'region': 'PA',
              'country': 'USA',
              'country_name': 'USA',
              'latitude': 39.9525839,
              'longitude': -75.1652215,
            }
            localStorage.setItem('location', JSON.stringify(data));
            loc = 'Philadelphia, PA, USA'
          } else {

            data = {
              'city': city,
              'region': region,
              'country': country,
              'country_name': '',
              'latitude': latitude,
              'longitude': longitude,
            }
            localStorage.setItem('location', JSON.stringify(data));
            loc = city + ', ' + region + ', ' + country;
          }
        })
      }
    })
    return loc;
  }).catch((error) => {
    console.log(error);
  });
}
function errorHandler(err) {
  if (err.code == 1) {
    console.log("Error: Access is denied!");
    let data = {
      'city': 'Philadelphia',
      'region': 'PA',
      'country': 'USA',
      'country_name': 'USA',
      'latitude': 39.9525839,
      'longitude': -75.1652215,
    }
    localStorage.setItem('location', JSON.stringify(data));
    // alert("Sorry, browser does not support geolocation!");
    let loc = 'Philadelphia, PA, USA'
    return loc;

  } else if (err.code == 2) {
    console.log("Error: Position is unavailable!");
  }
}
export function getGeoInfo() {
  let loc = '';
  var location = JSON.parse(localStorage.getItem('location'));
  if (!location) {
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      var options = { timeout: 60000 };
      navigator.geolocation.getCurrentPosition
        (showLocation, errorHandler, options);
    } else {
      let data = {
        'city': 'Philadelphia',
        'region': 'PA',
        'country': 'USA',
        'country_name': 'USA',
        'latitude': 39.9525839,
        'longitude': -75.1652215,
      }
      localStorage.setItem('location', JSON.stringify(data));
      // alert("Sorry, browser does not support geolocation!");
      loc = 'Philadelphia, PA'
      return loc;
    }
  } else {
    loc = location.city + ', ' + location.region;
    return loc;
  }
}



async function showLocationCurrent(position) {
  var latlongvalue = position.coords.latitude + ","
    + position.coords.longitude;
  var img_url = "https://maps.googleapis.com/maps/api/geocode/json?latlng="
    + latlongvalue + "&key=AIzaSyDQy9sCLvDj2anzl8152anNw-nXC3rBpik&fields=[address_components,place_id,formatted_address,name]&types=[regions]";

  await axios.get(img_url).then((response) => {
    let loc = '';
    response.data.results.map(item => {
      if (item.types[0] == 'locality' && item.types[1] == 'political') {
        let city = '';
        let region = '';
        let country = '';
        item.address_components.map(adre => {
          if (adre.types[0] == 'locality' && adre.types[1] == 'political') {
            city = adre.short_name;
          }
          if (adre.types[0] == 'administrative_area_level_1' && adre.types[1] == 'political') {
            region = adre.short_name;
          }

          if (adre.types[0] == 'country' && adre.types[1] == 'political') {
            country = adre.short_name;
          }
          let latitude = item.geometry.location.lat;
          let longitude = item.geometry.location.lng;
          var data = {};
          data = {
            'city': city,
            'region': region,
            'country': country,
            'country_name': '',
            'latitude': latitude,
            'longitude': longitude,
          }
          localStorage.setItem('location', JSON.stringify(data));
          loc = city + ', ' + region + ', ' + country;

        })
      }
    })
    console.log('hiss',loc)
    return loc;
  }).catch((error) => {
    console.log(error);
  });
}

export async function  getCurrentLocation() {
  if (navigator.geolocation) {
    // timeout at 60000 milliseconds (60 seconds)
    var options = { timeout: 60000 };
    navigator.geolocation.getCurrentPosition
      (showLocationCurrent, errorHandler, options);
  } else {
    let data = {
      'city': 'Philadelphia',
      'region': 'PA',
      'country': 'USA',
      'country_name': 'USA',
      'latitude': 39.9525839,
      'longitude': -75.1652215,
    }
    localStorage.setItem('location', JSON.stringify(data));
    // alert("Sorry, browser does not support geolocation!");
    let loc = 'Philadelphia, PA'
    return loc;
  }

}
