import React, { Component } from 'react'
import no_more_guessing_1 from '../../assets/images/no_more_guessing_1.jpg'
import engage_2 from '../../assets/images/engage_2.jpg'
import learn_landing from '../../assets/images/learn_landing.jpg'
import engage_21 from '../../assets/images/engage_21.jpg'
import Button from "../common/Button";

export default class Discover extends Component {
    render() {
        return (
            <>
                <div className="k-class">
                    <div className="container">
                        <div className="row k-inner">
                            <div className="col-md-6">
                                <img src={no_more_guessing_1} alt="no_more_guessing_1" />
                            </div>
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Discover</h3>
                                    <p>Using our Directory, it is easy to find black businesses near you from 28 categories.
                                 You can also use the directory when you travel the world to continue to support black businesses.</p>

                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Search for a service or business" url="/about" />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="row k-inner">
                            <div className="col-md-6">
                                <img src={engage_2} alt="engage_2" />
                            </div>
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Shop</h3>
                                    <p>Buy black-owned products from talented entrepreneurs and creatives
                                 from the US to South Africa! Get access to the newest trends and cultural trinkets..</p>

                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Shop in the Marketplace" url="/about" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <img src={learn_landing} alt="learn_landing" />
                            </div>
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Learn</h3>
                                    <p>Learn more about different black cultures from across the world through Katika Experiences.
                                 You can also consume our newest projects “for the culture” in Katika Labs</p>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Find a new Experience" url="/about" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <img src={engage_21} alt="engage_21" />
                            </div>
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>On The Go</h3>
                                    <p>Find businesses on the go with the Katika app available in both the Apple and Google app stores.
                                The Katika app allows you to find businesses or shop for products with your smart phone.</p>
                                    <div className="btn-box padding-top-30px">
                                        <Button className="radius-rounded" text="Download Apple iOS" url="/about" />
                                    </div>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Download Google Android" url="/about" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="k-class-2 bg-black1 min-height-130">
                    <div className="container">
                        <div className="row text-align" >
                            <div className="col-md-12">
                                <h3> Black Businesses can join the Katika Platform for FREE! Sign up today.</h3>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
