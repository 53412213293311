import React, { Component } from 'react';
import ImageSignUp from '../../assets/images/sign-up-img-4.png';
import { Row, Col } from "react-bootstrap";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignUpImg: ImageSignUp
        }
    }

    setPage = (page) => {
        this.props.getPage(page)
    }
    render() {
        return (

            <Row>
                <div className="col-lg-8 col-lg-offset-2 mx-auto">
                    <div className="about step-4">
                        <h1>Welcome to the <b>Katika</b> Directory!</h1>
                    </div>

                    <div className="steps">
                        <h2>Step 3 of 3</h2>
                    </div>

                    <div className="signup-desc step4 location">
                        <p>You’ll receive an email shortly to <b>Verify your account</b> in order to login. If you do not receive an email, check your spam folder.</p>
                        {/* <p> Please <b> verify your email address</b> in order to login.</p> */}
                    </div>
                    <div className="sign-up-image center"><img key={0} src={this.state.SignUpImg} alt="testimonial" className="random-img" /></div>

                </div>
               


            </Row>

        );
    }
}

export default SignUp;