import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import Table from 'react-bootstrap/Table'
class AccordionList extends Component {
    state = {
        items: [
            // {
            //     title: 'How do I manage my selling account?',
            //     desc: 'You must be of African descent in order to open a Katika for Business account on our private platform. Anyone from the African Diaspora identifying as African-American, Afro-Latino, Afro-Caribbean, or directly from the continent from Africa. If you do not fall into any of the aforementioned demographics but would still like to your business to partner with Katika, please email support@katika.us.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do  add inventory?',
            //     desc: 'There is no fee to create an account on Katika. Katika is for the black business community and will always have a free basic account.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How will I know when I have a sale?',
            //     desc: 'There is no marketplace on this version of Katika. We allow you to link your business website to your Katika detail business page, directing traffic to customers.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How much does it cost to sell on Katika.com?',
            //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'What are the differenes between professional and Individual accounts?',
            //     desc: 'A Personal Account with Katika is registered and managed completely separate from your Katika for Business Account. You can not use the same login for both sides of the platform. You will need to establish a separate account for Personal and for Business.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'When do I start getting charge the monthly subscription fee?',
            //     desc: "In order to provide users with genuine feedback, we do not allow business owners to delete or modify a user's review. If a user post an explicit review that breaks the rules of our terms of service, we will remove the review. If you believe a user has violated our terms of service, email support@katika.us to report the review.",
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },


            {
                title: 'How do I create a Katika for Business account?',
                desc: 'You can create a Katika for Business account by signing up online or by downloading the Katika for Business app.  Once you complete your profile, your business will be populated into the directory.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I create a post on Katika?',
                desc: 'Katika business post can be created by signing into your web or mobile app account and selecting the, create a post option. You will need to designate the zip code you want to target, a title for the post, a brief description, and attach a graphic for your post.  You also have the option of providing an external url link to the post for users to click.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I access Katika Business School videos?',
                desc: 'Katika Business School videos are free and accessible once you create your Katika for Business account.  Videso can be viewed in the seller account for both the mobile and website, Katika for Business.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I sign up for a Social Media Manager?',
                desc: 'Once your Katika for Business account is created, you can upgrade your account type by subscribing for $25/mo to have the Katika team manager your social media. You can sign up on both the mobile and website Katika for Business accounts.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I reply to a review?',
                desc: 'You can reply to reviews inside of the Katika for Business app once a review has been created.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Can I delete a review?',
                desc: "Reviews can not be deleted. However, if a review is in violation of our privacy policy or terms of use, you can request to have the review removed.",
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How can I promote my business on Katika?',
                desc: "You can promote your business on Katika by creating a business post or by creating a short form marketing video through the mobile app. ",
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },

            // {
            //     title: 'How do I reply to a review?',
            //     desc: "You can reply to a review of a user from your Katika Manager.",
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'I’m having trouble logging in, how do I change or reset my password?',
            //     desc: "If you can not log in, go to the website login page and select, forgot password to have your password resent. Please be sure to check your spam mail if you do not receive a message in a few minutes.",
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do I share my business page to grow my business and connect with customers on Katika?',
            //     desc: "You can share your Katika Business Page onto Facebook right from the Business Profile page. It will show your company name, logo and business description. Also be sure to create videos on the Katika for Business mobile app to share news about your business.",
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'What apps should I link to my Katika account to maximize my exposure?',
            //     desc: "We’ve provided the following guideline for apps that you can connect to your Katika profile based on your industry:",
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
        ]
    }
    render() {
        return (
            <>
                <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                    {this.state.items.map((item, i) => {
                        return (
                            <div className={'card ' + item.cardClass} key={i}>
                                <AccordionItem>
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                            {item.title}
                                            <i className="minus">{item.minus}</i>
                                            <i className="plus">{item.plus}</i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="card-body">
                                            {item.desc}
                                        </div>
                                        {
                                            i == this.state.items.length - 1 && (
                                                <div className="card-body none">
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>&nbsp;&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Barbershops</td>
                                                                <td>Squire, Boulevard, Barberly</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Restaurants</td>
                                                                <td>Uber Eats, DoorDash, Black and Mobile, Open Table</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lawyers</td>
                                                                <td>Clio, Practice Panther, MyCase, Rocket Matter</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Retail Shopping</td>
                                                                <td>Shopify, Etsy, Square Space</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hair Salon</td>
                                                                <td>Calendly, Vagaro, Rosuy, Salon Iris, Fresha, Acuity</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Child Care</td>
                                                                <td>Calendly</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )
                                        }
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        )
                    })}

                </Accordion>
            </>
        );
    }
}

export default AccordionList;