import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import CameraIcon from '../../assets/images/reviews-add-photo.png'
import Rater from 'react-rater'
import Loader from 'react-loader';

import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 0,
    marginRight: 0,
    // width: '100%',
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    alignItems: 'start'
};

const img = {
    display: 'block',
    width: 'auto',
    maxWidth: '200px',
    height: '100px'
};


function ReviewsPhotoUploader(props) {
    const [myFiles, setMyFiles] = useState([])
    const [rate, setRate] = useState(1);
    const [description, setDescription] = useState('');
    const [id] = useState(props.business_id);
    const [loaded, setLoaded] = useState(true)
    const onDrop = useCallback(acceptedFiles => {
        // setMyFiles([...myFiles, ...acceptedFiles])
        setMyFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

    }, [myFiles]);

    const { getRootProps, getInputProps, acceptedFileItems, fileRejections } = useDropzone({
        onDrop, maxFiles: 4, accept: 'image/*',
    });

    const submitForm = async e => {
        e.preventDefault();
        var type = '';
        var formData = new FormData();
        if (myFiles.length > 0) {
            setLoaded(false)
            myFiles.map((item) =>
                formData.append('image[]', item)
            );
            type = myFiles[0].type;
        }
        formData.append('business_id', id);
        formData.append('rating', rate);
        formData.append('description', description);
        await axios.post(ApiUrl + 'addBusinessReview', formData, {
            headers: {
                'Content-Type': type,
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    setLoaded(false)
                    props.closeModal(true)
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }
    const thumbs = myFiles.map(file => (
        <li style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="Author Profile"
                />
                <button
                    data-toggle="tooltip" data-placement="bottom" title="Remove"
                    className="btn btn-default badge-toggle b-left"
                    onClick={removeFile(file)}>
                    <AiOutlineClose />
                </button>
            </div>
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className="text-danger" key={errors[0].code}>{errors[0].message}</li>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        myFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [myFiles]);

    return (
        <>
            <Loader loaded={loaded}></Loader>
            <form onSubmit={submitForm}>
                <div className="ReviewsProduct  margin-bottom-25px">

                    <div className="rating-rating">
                        <Rating
                            placeholderRating={rate}
                            readonly={false}
                            stop={5}
                            onChange={( value ) => { setRate(value) }}
                            emptySymbol={<img src={Empty} className="icon" />}
                            placeholderSymbol={<img src={Fill} className="icon" />}
                            fullSymbol={<img src={Fill} className="icon" />}
                        />
                        {/* <Rater total={5} rating={rate} interactive={true} onRate={({ rating }) => { setRate(rating) }} /> */}
                        <span className="rating-count">Select your rating</span>
                    </div>
                    <textarea required value={description} onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Loved that they showed up in a mask,as safety is number one right now.
                    Everything was great, except a few small snafus, including waiting a little longer than usual given COVID.I totally get that can’t be helped right now...">
                    </textarea>


                </div>
                <div className="photo-heading"><h2>Attach Photos</h2></div>
                <div className="add-review-photo">
                    <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <img src={CameraIcon} alt="Camera" className="card__img" />

                        </div>
                        <aside>
                            <ul>{thumbs}</ul>
                            <ul>{acceptedFileItems}</ul>
                            {/* <ul>{fileRejectionItems}</ul> */}
                            {(fileRejectionItems.length > 0) ?
                                <em className="text-danger">(maximum images allowed limit exceed or file you select may not be type of image)</em>
                                : ""
                            }
                        </aside>
                    </section>
                </div>

                <div className="post-btn">
                    <button type="submit" className="btn btn-secondary">Post Review</button>
                </div>
            </form>
            <ToastContainer />
        </>
    )
}


export default ReviewsPhotoUploader;