import React, { Component } from "react";
import Slider from "react-slick";
import { Row, Col, Container } from "react-bootstrap";
import $ from 'jquery';
import img1 from '../../assets/images/img31.jpg';
import img2 from '../../assets/images/img32.jpg';
import img3 from '../../assets/images/img30.jpg';
import Image from '../../assets/images/1.jpg'
import ICC from '../../assets/images/icc.jpg'
import ICC2 from '../../assets/images/2.jpg'
import g_Close from "../../assets/images/g_Close.png"
import katika_K from "../../assets/images/katika_K2.png"
import bi_flag from "../../assets/images/bi_flag.png"
import video from '../../assets/video/ssstiktok_1623214518.mp4';
import { InlineShareButtons } from 'sharethis-reactjs';
import { HiLink } from "react-icons/hi";
import Button from 'react-bootstrap/Button'

import heart from "../../assets/images/Heart1.png"
import chat from "../../assets/images/chat4.svg"
import heart2 from "../../assets/images/Heart2.png"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { BsChevronUp, BsChevronDown, BsInfoCircle } from 'react-icons/bs'
import { ApiUrl, buyerUrl } from "../../config";
import axios from "axios";
import { uToken } from "../../useToken";
import { Redirect } from "react-router";


const Emojis = [
    { id: 1533, symbol: '❤️', tags_EN: 'orange, heart, love', tags_ES: 'corazón, naranja, amor', category: 'gente' },
    { id: 3, symbol: '😂', tags_EN: 'smile, tears, face', tags_ES: 'sonrisa, cara, llorar de risa', category: 'smileys' },
    { id: 32, symbol: '😯', tags_EN: 'face, open, mouth', tags_ES: 'cara, boca, abierta', category: 'smileys' },
    { id: 31, symbol: '😢', tags_EN: 'sad, relieved , face', tags_ES: 'cara, triste, aliviada', category: 'smileys' },
    { id: 1, symbol: '😠', tags_EN: 'smile, grinning, face', tags_ES: 'risa, cara, sonriente', category: 'smileys' },
    { id: 2, symbol: '👍', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },
    { id: 2, symbol: '👎', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },

];

export default class AutoPlayMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reply: '',
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            autoPlay: false,
            replyID: 0,
            current: {},
            initialSlide: this.props.location.state ? this.props.location.state.image_click : 0,
            data: this.props.location.state ? this.props.location.state.images : [],
            comments: [],
            redirect: false,
        };
        // this.play = this.play.bind(this);
        // this.pause = this.pause.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentDidMount() {
        console.log(this.props.location.state, 'this.props.location.state')
        if (this.props.location.state === undefined) {
            console.log(this.props.location.state, 'this.props.location.state')
            this.setState({
                redirect: true
            })
        }
        // this.slider.current.slickGoTo(2);
        this.getStoryComment();
    }



    getStoryComment = async () => {
        console.log('commen')
        let id = 0;
        if (this.state.data.length > 0) {
            if (this.state.activeSlide2 == 0) {
                id = this.state.data[this.state.initialSlide].stories[0].id;
            } else {
                id = this.state.data[this.state.activeSlide2].stories[0].id;
            }
        }

        var self = this;
        axios.post(ApiUrl + 'getStoryComment', { 'story_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    comments: res.data.comments
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    // play() {
    //     this.setState({ autoPlay: true })
    //     this.slider.slickPlay();
    //     if (this.state.activeSlide == 2) {
    //         $(`#video2`)[0].play();
    //     }
    //     console.log('play', this.state.activeSlide)
    // }
    // pause() {
    //     this.setState({ autoPlay: false })
    //     this.slider.slickPause();
    //     if (this.state.activeSlide == 2) {
    //         $(`#video2`)[0].pause();
    //     }

    //     console.log('pause', this.state.activeSlide)
    // }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    handleEmoji = (emoji) => {
        let text = this.state.reply + emoji;
        this.setState({
            reply: text,
            text: text
        })
    }

    handleText = (e) => {
        let text = e.target.value;
        let lastChar = text[text.length - 1];
        if (lastChar === ' ') {
            let array = text.split(' ');
            let lastWord = array[array.length - 2].toLowerCase();
            let emojis = Emojis.filter(emoji => emoji.tags_ES.indexOf(lastWord) !== -1 || emoji.tags_EN.indexOf(lastWord) !== -1).slice(0, 50);
            this.setState({
                emojis: emojis
            })
        }
        this.setState({
            reply: text,
            text: text
        })
        console.log(this.state.reply)
    }


    addStoryComment = async () => {
        var self = this;
        axios.post(ApiUrl + 'addStoryComment', { 'story_id': self.state.data[this.state.activeSlide2].stories[0].id, comment: self.state.reply, reply_id: 0 }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    self.setState({
                        reply: '',
                        showEmojis: false
                    })
                    self.getStoryComment();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handleText1 = (e) => {
        let text = e.target.value;
        let lastChar = text[text.length - 1];
        if (lastChar === ' ') {
            let array = text.split(' ');
            let lastWord = array[array.length - 2].toLowerCase();
            let emojis = Emojis.filter(emoji => emoji.tags_ES.indexOf(lastWord) !== -1 || emoji.tags_EN.indexOf(lastWord) !== -1).slice(0, 50);
            this.setState({
                emojis: emojis
            })
        }
        this.setState({
            reply1: text,
            text: text
        })
    }

    handleEmoji1 = (emoji) => {
        let text = this.state.reply1 + emoji;
        this.setState({
            reply1: text,
            text: text
        })
    }


    addStoryCommentReply = async () => {
        var self = this;
        axios.post(ApiUrl + 'addStoryComment', { 'story_id': self.state.data[this.state.activeSlide2].stories[0].id, comment: self.state.reply1, reply_id: this.state.replyID }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    self.setState({
                        reply1: '',
                        showEmojis1: false
                    })
                    self.getStoryComment();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {

        if (this.state.redirect) {
            if (uToken()) {
                return <Redirect to={{ pathname: "/buyer-stories" }} />

            } else {
                return <Redirect to={{ pathname: "/" }} />
            }
        }
        const settings = {
            dots: false,
            arrows: false,
            lazyLoad: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: this.state.initialSlide,
            autoplay: this.state.autoPlay,

            beforeChange: (current, next) =>
                this.setState({ oldSlide: current, activeSlide: next }, () => this.getStoryComment()),
            afterChange: current => this.setState({ activeSlide2: current }, () => this.getStoryComment())
        };

        return (
            <div className="stroy-media">
                {this.state.data.length > 0 ?
                    <Container fluid>
                        <Row>
                            <Col lg={8} md={8}>
                                <div className="story-player">
                                    <div className="story-top">
                                        <div className="TopHeaderLeft">
                                            <div className="close-story" onClick={() => window.history.back()}>
                                                <img src={g_Close} alt="katika_K" />
                                            </div>

                                            <div className="k-story-logo">
                                                <img src={katika_K} alt="katika_K" />
                                            </div>
                                        </div>
                                        <div className="topHeaderRight">
                                            <div className="close-report" onClick={() => console.log('hi')}>
                                                {/* <img src={bi_flag} alt="katika_K" />     Report */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="story-slider view-story">
                                        <Slider ref={slider => (this.slider = slider)} {...settings}>

                                            {
                                                this.state.data.map((slide, i) => {
                                                    return (
                                                        <div className="slick-slide-item">
                                                            <div className="storyHeader detasil">
                                                                <a href="#"><img src="https://katika-images.s3.amazonaws.com/category/download1623300297.png" /></a>
                                                                <a href="#"><span className="name">{slide.username}</span></a>
                                                            </div>
                                                            {
                                                                slide.stories[0].type == 'image' ?
                                                                    <img src={slide.stories[0].url} />
                                                                    :
                                                                    <video id="video2" autoPlay className="slide-video slide-media" loop muted preload="metadata" >
                                                                        <source src={slide.stories[0].url} type="video/mp4" />
                                                                    </video>

                                                            }
                                                        </div>
                                                    )

                                                })
                                            }
                                        </Slider>
                                        <div className="navigate-arrow-slick">
                                            <button className="left" onClick={this.previous}><img src="https://katika-images.s3.amazonaws.com/category/left1622112236.png" /></button>
                                            <button className="right" onClick={this.next}><img src="https://katika-images.s3.amazonaws.com/category/right1622112269.png" /></button>
                                        </div>



                                        {/* <div className="VolumeButton">
                                    <button className="button" onClick={this.play}>Play </button>
                                    <button className="button" onClick={this.pause}>Pause</button>
                                </div> */}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4}>
                                <div className="details-info story-details-info">
                                    <ul className="comments-list listing-gallery-p padding-top-10px">
                                        <li>
                                            <div className="comment listing-details gallery-story">
                                                <div className="user-img-details">
                                                    <img className="avatar__img" alt="Comment"
                                                        src={this.state.data[this.state.activeSlide2].profile} />
                                                </div>
                                                <div className="user-body">

                                                    <div className="left">
                                                        <div className="name">
                                                            {`${this.state.data[this.state.activeSlide2].username}`} </div>
                                                    </div>
                                                    <div className="right">
                                                        {/* <button className="follow">Follow</button> */}
                                                    </div>



                                                </div>

                                            </div>

                                            <div className="story-data">
                                                <p className="description">
                                                    {`${this.state.data[this.state.activeSlide2].title}`}
                                                </p>
                                            </div>

                                            <div className="count-data">
                                                <div className="count-story">
                                                    <div className="story">
                                                        <div className="like">
                                                            <div className="imga">
                                                                <img className="direct" src={heart} />

                                                            </div>
                                                            <p className="count">{this.state.data[this.state.activeSlide2].stories[0].total_like}</p>
                                                        </div>
                                                        <div className="comment">
                                                            <div className="imga">
                                                                <img className="direct" src={chat} />
                                                            </div>
                                                            <p className="count">{this.state.data[this.state.activeSlide2].stories[0].comment_count}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="share-story">
                                                    <p className="share-text">Share to</p>
                                                    <div className="ShareButton">
                                                        <InlineShareButtons
                                                            config={{
                                                                alignment: 'center',  // alignment of buttons (left, center, right)
                                                                color: 'social',      // set the color of buttons (social, white)
                                                                enabled: true,        // show/hide buttons (true, false)
                                                                font_size: 30,        // font size for the buttons
                                                                labels: '',        // button labels (cta, counts, null)
                                                                language: 'en',       // which language to use (see LANGUAGES)
                                                                networks: [           // which networks to include (see SHARING NETWORKS)
                                                                    'facebook',
                                                                    'reddit',
                                                                    'pinterest',
                                                                    'twitter',
                                                                    'whatsapp',
                                                                    'messenger',


                                                                ],
                                                                padding: 8,          // padding within buttons (INTEGER)
                                                                radius: 30,            // the corner radius on each button (INTEGER)
                                                                show_total: false,
                                                                size: 30,             // the size of each button (INTEGER)

                                                                // OPTIONAL PARAMETERS
                                                                url: `${buyerUrl}/story/${this.state.data[this.state.activeSlide2].stories[0].id}`, // (defaults to current url)
                                                                image: `${buyerUrl}/story/${this.state.data[this.state.activeSlide2].stories[0].id}`,  // (defaults to og:image or twitter:image)
                                                                description: 'custom text',       // (defaults to og:description or twitter:description)
                                                                title: 'custom title',            // (defaults to og:title or twitter:title)
                                                                message: 'custom email text',     // (only for email sharing)
                                                                subject: 'custom email subject',  // (only for email sharing)
                                                                username: 'custom twitter handle' // (only for twitter sharing)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="manual-link">
                                                <div className="form-group share-link margin-top-20px">
                                                    <input type="url" className="form-control" value={`${buyerUrl}/story/${this.state.data[this.state.activeSlide2].stories[0].id}`} />
                                                    <span><HiLink />
                                                        <button className="copy-link"
                                                            onClick={() => navigator.clipboard.writeText(`${buyerUrl}/story/${this.state.data[this.state.activeSlide2].stories[0].id}`)}
                                                        >
                                                            COPY LINK
                                                        </button>
                                                    </span>

                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="comment-section">
                                        <ul className="comments-list padding-top-20px">
                                            {
                                                this.state.comments.map((comment, c) => {
                                                    return (
                                                        <li key={c}>
                                                            <div>
                                                                <div className="comment storyComment">
                                                                    <img className="avatar__img" alt="Comment"
                                                                        src={comment.user_image} />

                                                                    <div className="comment-body">
                                                                        <div className="meta-data">
                                                                            <span className="comment__author">{comment.user_name}</span>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <p className="comment-content">{comment.message}</p>
                                                                            <div className="heart-count">
                                                                                <img className="" alt="Comment" src={heart2} />
                                                                                <span className="total-heart">{comment.reply.length}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="comment-reply justify-content-between align-items-center">
                                                                            <span className="comment-date">{comment.time}</span>
                                                                            <button onClick={() => this.setState({ replyID: comment.id })} className="btn-link reply-coment-story" href="/blog-single">
                                                                                Reply</button>
                                                                        </div>

                                                                        {
                                                                            this.state.replyID == comment.id ?
                                                                                <div className="add-comment">
                                                                                    <div className="form-group basic-textarea reaction-2">

                                                                                        <div className={this.state.showEmojis1 ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showEmojis1: !this.state.showEmojis1 })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>

                                                                                        </div>

                                                                                        <div className="d-flex">
                                                                                            <input className="form-control pl-2 my-0 storycoment"
                                                                                                placeholder="Add comment..."
                                                                                                onChange={(e) => this.handleText1(e)} value={this.state.reply1}
                                                                                            ></input>


                                                                                            <Button
                                                                                                color="info"
                                                                                                onClick={() => this.addStoryCommentReply()}
                                                                                                size="sm"
                                                                                                className="float-right Emoji-Submit-comment"

                                                                                            >
                                                                                                Post</Button>
                                                                                        </div>

                                                                                        <div className='Emoji story-comment'>
                                                                                            {this.state.showEmojis1
                                                                                                ? <div className='Emoji-Grid'>
                                                                                                    {Emojis.map((value, key) => <span key={key} onClick={() => this.handleEmoji1(value.symbol)}>{value.symbol}</span>)}
                                                                                                </div>
                                                                                                : null
                                                                                            }
                                                                                        </div>





                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                ""
                                                                        }

                                                                        {
                                                                            comment.reply.length > 0 && (
                                                                                <Accordion className="commentReply accordion accordion-item" allowZeroExpanded={true} id="accordionExample1">

                                                                                    <div className={'card'} key={c}>
                                                                                        <AccordionItem>
                                                                                            <AccordionItemHeading className="card-header">
                                                                                                <AccordionItemButton className="reply-comment-shown btn btn-link d-flex align-items-center">
                                                                                                    View more replies ({comment.reply.length})
                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                </AccordionItemButton>
                                                                                            </AccordionItemHeading>
                                                                                            <AccordionItemPanel>
                                                                                                {
                                                                                                    comment.reply.map((rep, r) => {

                                                                                                        return (
                                                                                                            <div key={r} className="comment storyComment reply-coment">
                                                                                                                <img className="avatar__img" alt="Comment"
                                                                                                                    src={rep.user_image} />

                                                                                                                <div className="comment-body">
                                                                                                                    <div className="meta-data">
                                                                                                                        <span className="comment__author">{rep.user_name}</span>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex">
                                                                                                                        <p className="comment-content">{rep.message}
                                                                                                                        </p>
                                                                                                                        {/* <div className="heart-count">
                                                                                                                                    <img className="" alt="Comment"
                                                                                                                                        src={heart2} />
                                                                                                                                    <span className="total-heart">12.3k</span>
                                                                                                                                </div> */}
                                                                                                                    </div>

                                                                                                                    {/* <div className="comment-reply justify-content-between align-items-center">
                                                                                                                                <span className="comment-date">4 -17 </span>
                                                                                                                                <button onClick={() => this.setState({ replyID: 6 })} className="btn-link reply-coment-story" href="/blog-single">
                                                                                                                                    Reply</button>
                                                                                                                            </div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )

                                                                                                    })
                                                                                                }
                                                                                            </AccordionItemPanel>
                                                                                        </AccordionItem>
                                                                                    </div>


                                                                                </Accordion>
                                                                            )
                                                                        }



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>




                                    <div className="add-comment">
                                        <div className="form-group basic-textarea reaction-2">





                                            <div className={this.state.showEmojis ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showEmojis: !this.state.showEmojis })}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>

                                            </div>

                                            <div className="d-flex">
                                                <input className="form-control pl-2 my-0 storycoment"
                                                    placeholder="Add comment..."
                                                    onChange={(e) => this.handleText(e)} value={this.state.reply}
                                                ></input>


                                                <Button
                                                    color="info"
                                                    onClick={() => this.addStoryComment()}
                                                    // rounded
                                                    size="sm"
                                                    className="float-right Emoji-Submit-comment"

                                                >
                                                    Post</Button>
                                            </div>

                                            <div className='Emoji story-comment'>
                                                {this.state.showEmojis
                                                    ? <div className='Emoji-Grid'>
                                                        {Emojis.map((value, key) => <span key={key} onClick={() => this.handleEmoji(value.symbol)}>{value.symbol}</span>)}
                                                    </div>
                                                    : null
                                                }
                                            </div>





                                        </div>
                                    </div>

                                </div>
                            </Col>

                        </Row>
                    </Container>
                    : ""
                }
            </div >
        );
    }
}