import React, { Component } from 'react';
import Copyright from "./Copyright";
import FooterLeftWidget from "./FooterLeftWidget";
import FooterQuickLinkWidget from "./FooterQuickLinkWidget";
import FooterCategoryWidget from "./FooterCategoryWidget";
import FooterHelpWidget from "./FooterHelpWidget";
import FooterKatikaWidget from "./FooterKatikaWidget";
import FooterLegalWidget from "./FooterLegalWidget";
import Logo from "../../../components/business/Logo";
import Logosrc from '../../../assets/images/katika-logo.png';
import Playstore from '../../../assets/images/google-app.png';
import Appstore from '../../../assets/images/ios-app.png';
import { AppstoreURL, PlaystoreUrl } from '../../../config';
class Footer extends Component {
    render() {
        return (
            <>
                <section className="k-class footer-area BizFooter">
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="container">
                        <div className="row">
                            <FooterQuickLinkWidget />
                            <FooterHelpWidget />
                            <FooterKatikaWidget />
                            <div className='d-block d-sm-none px-0'><FooterLegalWidget /></div>
                            <div className="col-lg-3 col-md-3 col-sm-3 column-td-6 logo d-none d-lg-block d-xl-block">
                                <Logo url={Logosrc} />
                                <div className='apps-logo d-none d-sm-none d-md-inline-flex'>
                                    <a href={AppstoreURL} target="_blank">
                                        <img src={Appstore} className='appstore ' />
                                    </a>
                                    <a href={PlaystoreUrl} target="_blank">
                                        <img src={Playstore} className='playstore ' />
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                {/* Copyright */}
                <Copyright />
            </>
        );
    }
}

export default Footer;