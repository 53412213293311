import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import Terms from '../../components/business/terms-privacy/Terms'
import BannerOne from '../../components/business/BannerOne'
import Testimonial from '../../components/business/Testimonial'
import { Row, Col } from "react-bootstrap";
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Helmet } from "react-helmet";




const Business = () => {
    return (
        <main className="BusinessHome FaqPages GetHelp TermsOfService">
            {/* Header */}
            <Helmet>
                <title>{`Terms of Service`}</title>
                <meta name="title" content={`Terms of Service`} />
                <meta property="og:title" content={`Terms of Service`} />
            </Helmet>
            <HomePageHeader />
            <Terms />
            <Footer />
            <ScrollTopBtn />
        </main>
    )
}

export default Business;