import React, { Component } from 'react';
import Copyright from "../../business/Footer/Copyright";
import FooterLeftWidget from "./FooterLeftWidget";
import FooterQuickLinkWidget from "../../business/Footer/FooterQuickLinkWidget";
import FooterCategoryWidget from "./FooterCategoryWidget";
import FooterHelpWidget from "../../business/Footer/FooterHelpWidget";
import FooterKatikaWidget from "../../business/Footer/FooterKatikaWidget";
import Logo from "../../business/Logo";
import Logosrc from '../../../assets/images/katika-logo.png';
import Playstore from '../../../assets/images/google-app.png';
import Appstore from '../../../assets/images/ios-app.png';
import { AppstoreURL, PlaystoreUrl } from '../../../config';
import Footer from '../../business/Footer/Footer';

class Footer1 extends Component {
    render() {
        return (
            <>
            <Footer />
                {/* <section className="k-class footer-area BizFooter">
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="box-icon"></div>
                    <div className="container">
                        <div className="row">
                            <FooterQuickLinkWidget />
                            <FooterHelpWidget />
                            <FooterKatikaWidget />
                            <div className="col-lg-3 col-md-3 col-sm-3 column-td-6 logo">
                                <Logo url={Logosrc} />
                                <div className='apps-logo d-none d-sm-none d-md-inline-flex'>
                                    <a href={AppstoreURL} target="_blank">
                                        <img src={Appstore} className='appstore ' />
                                    </a>
                                    <a href={PlaystoreUrl} target="_blank">
                                        <img src={Playstore} className='playstore ' />
                                    </a>

                                </div>
                            </div>
                        </div>


                    </div>
                </section> */}
                {/* Copyright */}
                {/* <Copyright /> */}
            </>
        );
    }
}

export default Footer1;