import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
// import "./styles.css";
import { Line } from "react-chartjs-2";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            externalData: false,
            lists: [],
            page: 0,
        };
    }


    componentDidMount = async () => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);

        await this.getBusinessVideo();

    }

    handleObserver = async (entities, observer) => {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y) {
            this.setState({
                page: this.state.page + 1
            }, () => this.getBusinessVideo())

        }
        this.setState({ prevY: y });
    }

    getBusinessVideo = async () => {
        var self = this;

        await axios.post(ApiUrl + `getYoutubeVideos?page=${this.state.page}&limit=10`, {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        lists: self.state.page == 0 ? res.data.lists : [...self.state.lists, ...res.data.lists]
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
   
    render() {

        return (
            <main className="dashboard-page stats-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar percent={this.state.percent} />
                        </Col>

                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area youtube-videos">
                                <div className="y-page-link security-area">
                                    <h4 className='text-dark'>Katika Business School Videos</h4>
                                    {/* {
                                        this.state.lists.map((row, i) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{row.title}</h5>
                                                        <div className="embed-responsive embed-responsive-16by9">
                                                            <iframe allowtransparency="true" className="embed-responsive-item" src={row.youtube_url} allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    } */}

                                    <Accordion className="accordion accordion-item margin-top-10px" id="accordionExample" allowZeroExpanded={true}>

                                        {
                                            this.state.lists.map((row, i) => {
                                                return (
                                                    <AccordionItem eventKey={i} key={i}>
                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between text-black">
                                                            {row.title}
                                                        </AccordionItemButton>
                                                        <AccordionItemPanel>
                                                            <div className="embed-responsive embed-responsive-16by9">
                                                                <iframe allowtransparency="true" className="embed-responsive-item" src={row.youtube_url} allowfullscreen></iframe>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                )
                                            })
                                        }
                                    </Accordion>
                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div
                    ref={loadingRef => (this.loadingRef = loadingRef)}
                >
                </div>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}

export default Dashboard;