import React, { Component } from 'react'
import GeneralHeader from '../../components/webapp/common/GeneralHeader'
import BannerOneSearchInput from '../../components/webapp/BannerOneSearchInput'
import PlaceList from '../../components/webapp/PlaceList';
import MapViewCluster from '../../components/webapp/MapViewCluster';


import Footer from "../../components/webapp/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import axios from 'axios';
import { ApiUrl } from '../../config';
import { getGeoInfo } from '../../useToken';
import Loader from 'react-loader';

class MListingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: "",
            previous: "",
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            search_data: {},
            businesses: [],
            suggested: [],
            View: 'List',
            Sort: 'Default',
            Filter: false,
            externalData: false,
            loaded: false,
            checkbox: [
                {
                    id: 1,
                    name: '$'
                },
                {
                    id: 2,
                    name: '$$'
                },
                {
                    id: 3,
                    name: '$$$'
                },
                {
                    id: 4,
                    name: '$$$$'
                }
            ],
            SelectPrice: [],
            SelectSuggestion: [],
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handleSuggestion = this.handleSuggestion.bind(this);
        this.handlePrice = this.handlePrice.bind(this);
    }

    componentDidMount() {
        console.log('propsss', this.props)
        this.getBusinessList();
    }



    handleSuggestion = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var number = Math.floor((Math.random() * 1000) + 1);
        if (target.checked) {
            this.state.SelectSuggestion[`${value}${number}`] = value;
        } else {
            const index1 = this.state.SelectSuggestion.indexOf(value);
            if (index1 > -1) {
                this.state.SelectSuggestion.splice(index1, 1);
            }
        }
    }

    handlePrice = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var number = Math.floor((Math.random() * 1000) + 1);
        if (target.checked) {
            this.state.SelectPrice[`${value}${number}`] = value;
        } else {
            const index1 = this.state.SelectPrice.indexOf(value);
            // const index = this.state.SelectPrice.indexOf(value, value);
            if (index1 > -1) {
                this.state.SelectPrice.splice(index1, 1);
            }
            // this.state.SelectPrice.splice(value, 1);
        }
    }

    sendRequest = (count) => {
        this.setState({ pageNumber: count });
        // console.log('new' , this.state.pageNumber)
        this.getBusinessList(this.state.search_data, count);
    }

    handle() {

        var suggestion = this.state.SelectSuggestion.filter(function (e4) {
            return e4 != null;
        });

        var prices = this.state.SelectPrice.filter(function (e3) {
            return e3 != null;
        });

        var datam = {
            'search': this.state.search_data.search,
            'location': this.state.search_data.location,
            'lat': this.state.search_data.lat,
            'lng': this.state.search_data.lng,
            'category_id': this.state.search_data.category_id,
            'sub_category_id': this.state.search_data.sub_category_id,
            'suggested': suggestion,
            'amount_value': prices,
            'sort': this.state.Sort,
        }
        this.setState({
            Filter: false,
        })
        // console.log(datam , 'all')

        this.getBusinessList(datam);
    }

    getBusinessList = (datam, count) => {

        var self = this;
        self.setState({
            externalData: false,
            loaded: false,
        })
        var data = {};
        if (datam) {
            data = datam;
        } else if (this.props.location.state) {
            console.log('new', this.props.location.state.location)
            data = {
                'search': this.props.location.state.search,
                'location': this.props.location.state.location,
                'category_id': this.props.location.state.category_id,
                'sub_category_id': this.props.location.state.sub_category_id,
                'lat': this.props.location.state.lat,
                'lng': this.props.location.state.lng,
            };
        } else {
            let last = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1).replace('-', ' ');
            let search = '';
            if (last != 'm-listing') {
                search = last
            }
            data = {
                'search': decodeURI(search),
                'location': getGeoInfo(),
                'category_id': '',
                'sub_category_id': '',
                'lat': 39.9525839,
                'lng': -75.1652215,
            };
        }

        // console.log('as',data)

        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        // console.log(data)
        axios.post(ApiUrl + `searchBusinessMobileWeb?page=${pg}&limit=10`, data, {})
            .then(function (res) {
                // console.log(data)

                self.setState({
                    suggested: res.data.suggested,
                    businesses: res.data.lists,
                    search_data: data,
                    externalData: true,
                    suggested: res.data.suggested,
                    pageNumber: pg,
                    totalpage: res.data.total_pages,
                    show: res.data.show,
                    loaded: true
                })
                // $('html, body').animate({ scrollTop: 0 }, 1000);
            })
            .catch(function (error) {
                self.setState({
                    suggested: [],
                    businesses: [],
                    search_data: data,
                    totalpage: 0,
                    externalData: true,
                    show: '0-0 of 0',
                    loaded: true
                })
                console.log(error);
            });
    }
    render() {
        const { id } = this.props.match.params;
        return (
            <main className="Mobile home-1 listing">
                <Loader loaded={this.state.loaded}></Loader>
                {/* Header */}

                <GeneralHeader />

                {/* Hero Banner */}

                <BannerOneSearchInput search={id} />



                <div className={`${this.state.Filter ? 'block' : 'none'}`}>
                    <div className="btns-search">
                        <button onClick={() => this.setState({ Filter: false })} className="Filters">Cancel</button>
                        <div className="right-btns pull-right">
                            <button onClick={() => this.handle()} className="Search">Search</button>
                        </div>
                    </div>
                    <div className="list-filter">

                        <div className="Sort">
                            <p>Sort by</p>
                            <div className="SelectSort">

                                <div className="custom-checkbox" key={0}>
                                    <input checked={this.state.Sort == 'Default' ? true : false}  onChange={() => this.setState({ Sort: 'Default' })} type="radio" title='Recommended' id={'chb3-Recommended'} name='sort' value='Default' />
                                    <label htmlFor={'chb3-Recommended'}> Recommended</label>
                                </div>
                                <div className="custom-checkbox"  key={1}>
                                    <input checked={this.state.Sort == 'Heighest Rated' ? true : false} onChange={() => this.setState({ Sort: 'Heighest Rated' })} type="radio" title='Highest Rated' id={'chb3-Highest-Rated'} name='sort' value='Heighest Rated' />
                                    <label htmlFor={'chb3-Highest-Rated'}>Highest Rated</label>
                                </div>
                            </div>
                        </div>

                        <div className="Price">
                            <p>Filter by</p>
                            <div className="SelectPrice">

                                {this.state.checkbox.map(check => {
                                    return (
                                        <div className="custom-checkbox" key={check.id}>
                                            <input type="checkbox" title={check.name} id={'chb2-' + check.id} name={check.id} value={check.id} onChange={this.handlePrice.bind(this)} />
                                            <label htmlFor={'chb2-' + check.id}>
                                                {check.name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>


                            {this.state.suggested.map(check => {
                                return (
                                    <div className="SelectPrice SelectSuggestion" key={check.name}>
                                        <div className="custom-checkbox">
                                            <input type="checkbox" title={check.name} id={'chb4-' + check.id} name={check.id} value={check.id} onChange={this.handleSuggestion.bind(this)} />
                                            <label htmlFor={'chb4-' + check.id}>
                                                {check.name}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>



                <div className={`${!this.state.Filter ? 'block' : 'none'}`}>
                    <div className="btns">
                        <button onClick={() => this.setState({ Filter: true })} className="Filters">Filters</button>
                        <div className="right-btns pull-right">
                            <button onClick={() => this.setState({ View: 'List' })} className={`List ${this.state.View == 'List' ? 'active' : ''}`}>List</button>
                            <button onClick={() => this.setState({ View: 'Map' })} className={`Map ${this.state.View == 'Map' ? 'active' : ''}`}>Map</button>
                        </div>
                    </div>
                    <div className={`${this.state.View == 'List' ? 'block' : 'none'}`}>
                        <div className="Results"><h1>All Results</h1></div>
                        <div className="BreadCrumb">
                            <p>Showing {this.state.show}</p>
                        </div>
                    </div>

                    {(this.state.externalData && this.state.View == 'List') ? <PlaceList businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}


                    {(this.state.externalData && this.state.View == 'Map') ? <MapViewCluster businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}

                    {


                        (this.state.totalpage > 1) ?
                            <div className="pagination-btn">
                                {
                                    this.state.pageNumber != 1 ?

                                        <button onClick={() => this.sendRequest(this.state.pageNumber - 1)} className="prev-btn" >
                                            <span className="">
                                                <span className="icon-label">Previous</span></span>
                                        </button>
                                        : ""
                                }
                                {
                                    this.state.totalpage != this.state.pageNumber ?
                                        <button onClick={() => this.sendRequest(this.state.pageNumber + 1)} className="next-btn" >
                                            <span className="">
                                                <span className="icon-label">Next</span></span>
                                        </button>
                                        : ""
                                }
                            </div>

                            : ""
                    }
                </div>


                {/* Footer */}
                <Footer />

                <ScrollTopBtn />
            </main>
        );
    }
}

export default MListingList;