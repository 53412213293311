import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import useToken, { uToken, uInfo, uRole } from '../../../useToken';
import { Container, Dropdown } from "react-bootstrap";
import UserInfoSignIn from "../../../components/user/UserInfoSignIn";
import Chat1 from '../../../assets/images/chat1.png';
import Notification1 from '../../../assets/images/bell1.png';
import { buyerUrl } from '../../../config';

export default function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [token, setToken] = useState(!uToken() ? '' : uToken());
    let history = useHistory();

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    useEffect(() => {
    }, []);

    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
        // history.push("/");
        // setTimeout(
        //     () => history.push("/"),
        //     4000
        // );
    }

    function WarningBanner() {
        if (!token) {
            return (
                <ul>
                    <li><Link to="/login">Log In</Link></li>
                    <li><Link to={{ pathname: '/sign-up', state: { prevPath: history.location.pathname } }}>Sign Up</Link></li>
                </ul>
            );
        } else {
            return (
                <ul>
                    {window.location.pathname == '/' ?
                        <li className="user-profile-name">
                            <div className="user-side directory">
                                <Link to="/buyer-message"><img src={Chat1} alt="Chat" />
                                </Link>
                            </div>
                            <div className="user-side Chat">
                                <Link to="/buyer-message"><img src={Notification1} alt="Notification" />
                                </Link>
                            </div>
                            {/* <Link onClick={LogoutBtn} to="/"> LOGOUT </Link> */}
                            <div className="user-area">
                                <div className="user-side login">

                                    {
                                        (uRole() == 1) ?
                                            <>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <img src={uInfo().image} alt="user" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <UserInfoSignIn />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            : (uRole() == 2 || uRole() == 0) ?



                                                <Link to="/dashboard"><img src={uInfo().image} alt="user" /></Link>
                                                : ""

                                    }

                                </div>

                            </div>
                        </li>
                        :
                        <li> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li>
                    }
                </ul>
            );
        }
    }

    return (
        <>
            <div className="main-menu-content">
                <nav>
                    <WarningBanner />
                </nav>
            </div>
            <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li><a href={`${buyerUrl}/login`}>Log In</a></li>
                        <li><a href={`${buyerUrl}/sign-up`}>Sign Up</a></li>
                    </ul>
                </div> 
            </div>
        </>
    )
}
