import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo({ url, className }) {
    console.log(url, 'URL')
    return (
        <>
            <div className="D-Logo">
                <Link to="/" className={className}>
                    <img src={url} alt="logo" />
                </Link>
                
            </div>
        </>
    )
}
