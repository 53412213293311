import React, { Component } from 'react';
import WidgetSelectFilter from "./widgets/WidgetSelectFilter";
import WidgetFilterSuggested from "./widgets/WidgetFilterSuggested";
import WidgetFilterFeatures from "./widgets/WidgetFilterFeatures";
import WidgetNeighborhoods from "./widgets/WidgetNeighborhoods";
import WidgetFilterDistance from "./widgets/WidgetFilterDistance";
import $ from 'jquery'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Label, FormGroup, CustomInput } from 'reactstrap';
import { Card, Button } from 'react-bootstrap'
import Collapsible from 'react-collapsible';

class ListingListSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // title: '1 filter',
            Suggestedtitle: 'Post Category',
            SelectSuggestion: [],
            suggestion: [],
            Suggestedcategories: [
                {
                    id: 1,
                    cat: 'Open Now',
                    catNum: 11
                },
                {
                    id: 2,
                    cat: 'Katika Delivery',
                    catNum: 22
                },
                {
                    id: 3,
                    cat: 'Katika Takeout',
                    catNum: 9
                }
            ],
            Neighborhoodstitle: 'Neighborhoods',
            isNeighborhoods: false,
            SelectNeighborhoods: [],
            neighborhoods: [],
            Distancetitle: 'Distance',
            dSelect: '',
            SelectDistance: '',
            distance: [
                {
                    active: true,
                    text: 'Bird’s-eye View',
                    value: '',

                },
                {
                    active: false,
                    text: 'Driving (5 mi.)',
                    value: 5,

                },
                {
                    active: false,
                    text: 'Biking (2 mi.)',
                    value: 2,

                },
                {
                    active: false,
                    text: 'Walking (1 mi.)',
                    value: 1,

                },
                {
                    active: false,
                    text: 'Within 4 blocks',
                    value: 0.5,

                }
            ],
            SubmitNeighborhood: [],
            SubmitNeighborhoodSelect: [],
            NeighborhoodItem: [],

            ZipCodetitle: 'ZipCode',
            isZipCode: false,
            SelectZipCode: 'All',
            zipcodes: [],
            SubmitZipCode: [],
            SubmitZipCodeSelect: [],
            ZipCodeItem: [],
            dZipSelect: 'All',
            dZipSelect_F: 'All',
            SelectZipCode_F: '',
            cityName: this.props.cityName
        }
        this.handleSuggestion = this.handleSuggestion.bind(this);
        this.handleDistance = this.handleDistance.bind(this);
        this.handleNeighborhood = this.handleNeighborhood.bind(this);
        this.onHide = this.onHide.bind(this);
        this.SubmitNeighborhood = this.SubmitNeighborhood.bind(this);

        this.handleZipCode = this.handleZipCode.bind(this);
        this.SubmitZipCode = this.SubmitZipCode.bind(this);
    }


    SubmitZipCode = (event, errors, values) => {
        this.setState({
            SelectZipCode: this.state.SelectZipCode_F,
            dZipSelect: this.state.dZipSelect_F,
            ZipCodeModal: false,
        })

        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            neighborhoods: this.state.SubmitNeighborhoodSelect,
            zipcodes: this.state.SelectZipCode,
        })
    }

    handleZipCode = (event) => {
        const target = event.target;
        var value = target.value;
        var title = target.title;
        this.setState({
            SelectZipCode: value,
            dZipSelect: title
        });

        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            neighborhoods: this.state.SelectNeighborhoods,
            // distance: this.state.dSelect,
            zipcodes: value,
        })

    }

    handleZipCodeFilter = (event) => {

        const target = event.target;
        var value = target.value;
        var title = target.title;
        this.setState({
            SelectZipCode_F: value,
            dZipSelect_F: title
        });

    }




    SubmitNeighborhood = (event, errors, values) => {
        // // console.log(this.state.SubmitNeighborhoodSelect, 'hi check')
        // // console.log(this.state.items, 'hi Selectitems')
        // console.log(this.state.NeighborhoodItem, 'hi SelectFeatures')

        // console.log(oldBooks.concat(this.state.NeighborhoodItem) ,'consat') ;
        this.setState({
            NeighborhoodsModal: false,
            SelectNeighborhoods: this.state.SubmitNeighborhoodSelect,
        })

        // console.log(this.state.items, 'newItem')
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            neighborhoods: this.state.SubmitNeighborhoodSelect,
            distance: this.state.SelectDistance,
            zipcodes: this.state.SubmitZipCodeSelect
        })
    }



    onHide() {
        this.setState({ NeighborhoodsModal: false , ZipCodeModal: false });
    }

    componentWillReceiveProps(newProps, prevprops) {
        console.log(newProps, 'newProps')
        this.setState({
            neighborhoods: newProps.neighborhoods,
            suggestion: newProps.suggested,
            zipcodes: newProps.postZipCodes,
        });
    }


    componentDidMount() {
        this.setState({
            neighborhoods: this.props.neighborhoods,
            suggestion: this.props.suggested,
            zipcodes: this.props.postZipCodes
        });
        $(document).ready(function () {
            $(".sidebar-widget .filter-by-category").css({ height: 'auto', overflow: 'hidden' })

            ////////////////////////////// Features
            $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
            $(document).on('click', '#showfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).addClass('lessfilterbyfeature');
            })
            $(document).on('click', '.lessfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).removeClass('lessfilterbyfeature');
            })

            ////////////////////////////// Neighborhoods
            $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
            $(document).on('click', '#showfilterbyneighbour', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: '468px', overflow: 'hidden' })
                $(this).addClass('lessfilterbyneighbour');
            })
            $(document).on('click', '.lessfilterbyneighbour', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).removeClass('lessfilterbyneighbour');
            })
        })
    }


    handleSuggestion = (event) => {
        const target = event.target;
        var value = target.value;
        var title = target.title;
        if (target.checked) {
            this.state.SelectSuggestion[value] = value;
        } else {
            const index = this.state.SelectSuggestion.indexOf(value);
            if (index > -1) {
                this.state.SelectSuggestion.splice(index, 1);
            }
            // this.state.SelectSuggestion.splice(value, 1);
        }
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: this.state.SelectDistance,
            zipcodes: this.state.SelectZipCode,
        })
    }

    handleNeighborhoodFilter = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 7893;
        if (target.checked) {
            this.state.SubmitNeighborhoodSelect[value] = text;
            this.state.NeighborhoodItem[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SubmitNeighborhoodSelect.indexOf(value);
            const index1 = this.state.NeighborhoodItem.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SubmitNeighborhoodSelect.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.NeighborhoodItem.splice(index1, 1);
            }

            // this.state.SelectFeatures.splice(value, 1);
        }

        console.log(this.state.SubmitNeighborhoodSelect, 'this.state.SubmitNeighborhoodSelect')
    }

    handleNeighborhood = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 7893;
        if (target.checked) {
            this.state.SelectNeighborhoods[value] = text;
            this.state.SubmitNeighborhoodSelect[value] = text;
        } else {
            const index = this.state.SelectNeighborhoods.indexOf(value);
            const index1 = this.state.SubmitNeighborhoodSelect.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SelectNeighborhoods.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.SubmitNeighborhoodSelect.splice(index1, 1);
            }

        }

        if (!this.state.NeighborhoodsModal) {
            this.forceUpdate();
            this.props.handler1({
                suggestion: this.state.SelectSuggestion,
                neighborhoods: this.state.SelectNeighborhoods,
                distance: this.state.SelectDistance,
                zipcodes: this.state.SelectZipCode,
            })
        }
    }

    handleDistance = (event) => {
        const target = event.target;
        var value = target.value;
        var title = target.title;
        this.setState({
            SelectDistance: value,
            dSelect: title
        });

        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: value,
            price: this.state.SelectPrice,
            zipcodes: this.state.SelectZipCode,
        })
    }

    removeFilter = () => {
        $('input[type="radio"]:checked').prop('checked', false);
        $('input[type="checkbox"]:checked').prop('checked', false);
        this.state.SelectSuggestion = [];
        this.state.SelectFeatures = [];
        this.state.SelectNeighborhoods = [];
        this.state.SelectDistance = '';
        this.state.dSelect = '';
        this.state.SelectZipCode = 'All';
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            zipcodes: this.state.SelectZipCode,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    handleSubmit = () => {
        console.log(this.state.SelectSuggestion)
    }
    render() {
        const neighborhoodToRender = this.state.SelectNeighborhoods.filter(neighborhood => neighborhood)
        const totalNeighborhood = neighborhoodToRender.length;

        return (
            <>

                <div className="sidebar listing-page post-list-sidebar" >
                    <h3 className="widget-title" style={{ padding: 0 }}>Filters</h3>
                    <div id="removeFilter" className="showmore-btn filter" onClick={this.removeFilter.bind(this)}>
                        <span className="showmore-txt ">Clear Filter</span>
                    </div>
                    <div className="sidebar-widget pb-3">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Suggestedtitle}
                        </h3>
                        <div className="check-box-list show-more-item filter-by-category filter-category mt-4 mb-4">

                            {this.state.suggestion.map((item, i) => {
                                return (
                                    <div className="custom-checkbox hello" key={item.id}>
                                        <input type="checkbox" title={item.title} name={item.id} value={item.id} id={'chb' + item.id} onChange={this.handleSuggestion} />
                                        <label htmlFor={'chb' + item.id}>
                                            {item.title}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* <WidgetFilterFeatures /> */}


                    <div className="sidebar-widget none">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Neighborhoodstitle}
                        </h3>

                        <div className="check-box-list show-more-item-neighbour filter-by-neighbour filter-category-neighbour" style={{ height: "auto", maxHeight: "140px", overflow: 'hidden' }}>
                            {
                                (totalNeighborhood >= 4)
                                    ?

                                    this.state.SelectNeighborhoods.map((sFeature, key) => {
                                        return (
                                            <>
                                                {this.state.neighborhoods.map((item, i) => {
                                                    return (
                                                        <>
                                                            {(sFeature == item.id) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" defaultChecked className="active" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                    <label htmlFor={'chb411-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </>
                                        );
                                    })
                                    : (totalNeighborhood == 0) ?
                                        this.state.neighborhoods.map((item, i) => {
                                            return (
                                                (i == 0 || i <= 3) ?
                                                    <div className="custom-checkbox" key={i}>
                                                        <input type="checkbox" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                        <label htmlFor={'chb411-' + i}>
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                    : ""
                                            )
                                        })

                                        : (totalNeighborhood > 0 && totalNeighborhood <= 4) ?
                                            [
                                                this.state.SelectNeighborhoods.map((sFeature, key) => {
                                                    return (
                                                        <>
                                                            {this.state.neighborhoods.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        {(sFeature == item.id) ?
                                                                            <div className="custom-checkbox" key={i}>
                                                                                <input type="checkbox" defaultChecked className="active" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                                <label htmlFor={'chb411-' + i}>
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }),
                                                this.state.neighborhoods.map((item, i) => {
                                                    return (
                                                        (i == 0 || i <= 3) ?
                                                            (!this.state.SelectNeighborhoods.includes(`${item.id}`)) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                    <label htmlFor={'chb411-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            : ""
                                                    )
                                                })
                                            ]
                                            : ""
                            }


                        </div>
                        {(this.state.neighborhoods) && this.state.neighborhoods.length > 2 ?
                            <div id="" className="showmore-btn filter">
                                {this.state.isNeighborhoods ?
                                    <span className="lessmore-txt" onClick={() => this.setState({ isNeighborhoods: false })}>Show Less</span>
                                    :
                                    <span className="showmore-txt " onClick={() => this.setState({ NeighborhoodsModal: !this.state.NeighborhoodsModal })}>See all</span>
                                    // <span className="showmore-txt " onClick={() => this.setState({ isNeighborhoods: true })}>See all</span>
                                }



                            </div>
                            : " "}
                    </div>

                    <Modal className="NeighborhoodsModal" show={this.state.NeighborhoodsModal} onHide={this.onHide} size="lg"
                    ><AvForm onSubmit={this.SubmitNeighborhood}>
                            <Modal.Header closeButton>
                                <Modal.Title> More Neighborhoods </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="sidebar listing-page">

                                    <div className="row">
                                        {(this.state.neighborhoods) ? this.state.neighborhoods.map((item, i) => {
                                            return (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="panel-body">
                                                            <div className="custom-checkbox pop-check-box" key={i}>
                                                                {
                                                                    (this.state.SelectNeighborhoods.includes(`${item.id}`)) ?
                                                                        <input type="checkbox" defaultChecked id={`chb4-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhoodFilter} />
                                                                        :
                                                                        <input type="checkbox" id={`chb4-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhoodFilter} />
                                                                }
                                                                <label htmlFor={'chb4-' + i}>
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }) : ""}
                                    </ div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer closeButton>
                                <div className="button-modal-footer">
                                    <Button variant="secondary" onClick={() => this.setState({ NeighborhoodsModal: !this.state.NeighborhoodsModal })}>Close</Button>
                                    <Button type="submit" variant="primary" >Apply</Button>
                                </div>
                            </Modal.Footer>
                        </AvForm>
                    </Modal>
                    {/* <WidgetFilterDistance /> */}


                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.ZipCodetitle}
                        </h3>

                        <ul className="rating-list distance filter-category mt-4" onChange={this.handleZipCode.bind(this)} style={{ height: "auto", maxHeight: "140px", overflow: 'hidden' }}>
                            <li key={-1}>
                                <span className="la-star">All</span>
                                <label className="review-label">
                                    <input className={`c-${this.state.SelectZipCode}`} type="radio" checked={this.state.SelectZipCode == 'All' ? true : false} defaultChecked={this.state.SelectZipCode == 'All' ? 'checked' : ''} title="All" value="All" name="review-radio" />
                                    <span className="review-mark"></span>
                                </label>
                            </li>
                            {this.state.zipcodes.map((item, index) => {
                                return (
                                    (index == 0 || index <= 3) && (
                                        <li key={index}>
                                            <span className="la-star">{item.zip_code}</span>
                                            <label className="review-label">
                                                <input className={`c-${this.state.SelectZipCode}`} type="radio" checked={this.state.SelectZipCode == item.zip_code ? true : false} defaultChecked={this.state.SelectZipCode == item.zip_code ? 'checked' : ''} title={item.zip_code} value={item.zip_code} name="review-radio" />
                                                <span className="review-mark"></span>
                                            </label>
                                        </li>
                                    )
                                )
                            })}

                        </ul>
                        {(this.state.zipcodes) && this.state.zipcodes.length > 2 ?
                            <div id="" className="showmore-btn filter">
                                {this.state.isZipCode ?
                                    <span className="lessmore-txt" onClick={() => this.setState({ isZipCode: false })}>Show Less</span>
                                    :
                                    <span className="showmore-txt " onClick={() => this.setState({ ZipCodeModal: !this.state.ZipCodeModal })}>See all</span>
                                    // <span className="showmore-txt " onClick={() => this.setState({ isNeighborhoods: true })}>See all</span>
                                }



                            </div>
                            : " "}
                    </div>

                    <Modal className="ZipCodeModal" show={this.state.ZipCodeModal} onHide={this.onHide} size="lg"
                    ><AvForm onSubmit={this.SubmitZipCode}>
                            <Modal.Header closeButton>
                                <Modal.Title> More ZipCode </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="sidebar listing-page zipcode-modal-post">

                                    <ul className="rating-list distance filter-category mt-4 zipcode-post" onChange={this.handleZipCodeFilter.bind(this)}>
                                        <li key={-1}>
                                            <span className="la-star">All</span>
                                            <label className="review-label">
                                                <input className={`c-${this.state.SelectZipCode}`} type="radio" defaultChecked={this.state.SelectZipCode == 'All' ? 'checked' : ''} title="All" value="All" name="review-radio" />
                                                <span className="review-mark"></span>
                                            </label>
                                        </li>
                                        {(this.state.zipcodes) ? this.state.zipcodes.map((item, index) => {
                                            return (
                                                <>
                                                    <li key={index}>
                                                        <span className="la-star">{item.zip_code}</span>
                                                        <label className="review-label">
                                                            <input type="radio" className={`c-${this.state.SelectZipCode}`} defaultChecked={this.state.SelectZipCode == item.zip_code ? 'checked' : ''} title={item.zip_code} value={item.zip_code} name="review-radio" />
                                                            <span className="review-mark"></span>
                                                        </label>
                                                    </li>


                                                </>
                                            );
                                        }) : ""}
                                    </ ul>
                                </div>
                            </Modal.Body>
                            <Modal.Footer closeButton>
                                <div className="button-modal-footer">
                                    <Button variant="secondary" onClick={() => this.setState({ ZipCodeModal: !this.state.ZipCodeModal })}>Close</Button>
                                    <Button type="submit" variant="primary" >Apply</Button>
                                </div>
                            </Modal.Footer>
                        </AvForm>
                    </Modal>

                    {/* 

                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Distancetitle}
                        </h3>
                        <ul className="rating-list distance filter-category mt-4" onChange={this.handleDistance.bind(this)}>

                            {this.state.distance.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <span className="la-star">{item.text}</span>
                                        <label className="review-label">
                                            <input type="radio" defaultChecked={item.active ? 'checked' : ''} title={item.text} value={item.value} name="review-radio" />
                                            <span className="review-mark"></span>
                                        </label>
                                    </li>
                                )
                            })}

                        </ul>
                    </div> */}

                </div>
            </>
        );
    }
}

export default ListingListSidebar;