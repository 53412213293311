import React, { Component } from 'react';
import AddCamera from "../../assets/images/add-camera.png"
import UpdateProfile from "../../assets/images/update-profile.png"
import FindFriend from "../../assets/images/find-friend.png"
import { Container, Row, Col } from "react-bootstrap";
import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'
import { BsPersonFill, BsGearFill } from "react-icons/bs";
import { AiFillCamera } from 'react-icons/ai'
import PhotoUploader3 from '../../components/addlisting/PhotoUploader3';
import { Link } from 'react-router-dom';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import { ToastContainer, toast } from 'react-toastify';

class Directory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            editPhotoModal: false,
        }
        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.setState({ editPhotoModal: false });
    }

    componentDidMount() {
        this.getProfile();
    }

    close(message) {
        if (message) {
            // toast.success(message);
            this.getProfile();
            this.setState({
                editPhotoModal: false,
            })
        } else {
            this.setState({
                editPhotoModal: false,
            })

        }
    }

    LogoutBtn() {
        localStorage.removeItem('token');
    }

    getProfile = () => {
        var self = this;
        axios.post(ApiUrl + 'getBuyerProfile', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    user: res.data.user,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <>

                <Col id="user-picture">
                    <div className="user-profile">
                        <img src={this.state.user.image} alt="User" />
                    </div>
                    <div className="user-information">
                        <div className="user-name">
                            <h2 className="name-link"><Link to="/user-profile">{this.state.user.fname} {this.state.user.lname}</Link></h2> 
                            <h6>{this.state.user.address}</h6>
                        </div>
                        <ul className="UserSocialInfo">
                            <li><FaUserFriends /> <strong>{this.state.user.total_friends}</strong> </li>
                            <li><span><GoStar /></span> <strong>{this.state.user.reviews}</strong> </li>
                        </ul>

                    </div>


                </Col>
                <ul className="OptionMenu">
                    <li><BsPersonFill /> <Link to="/user-profile">About Me </Link> </li>
                    <li><FaUserFriends /> <Link to="/user-friends">Friends</Link> </li>
                    <li><BsGearFill /> <Link to="/user-email-notifications">Account Settings</Link> </li>
                    <li><Link onClick={this.LogoutBtn} to="/">Logout</Link></li>
                </ul>
            </>
        );
    }
}

export default Directory;