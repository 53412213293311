import React, { Component } from 'react'
import PrimaryNavbar from './PrimaryNavbar'
import HeaderAccess from '../other/account/HeaderAccess'
import Logo from './Logo'
import logosrc from '../../assets/images/katika-logo.png'
import $ from 'jquery';
export default class Header extends Component {
    componentDidMount() {
        $(window).on('scroll', function () {
            //header fixed animation and control
            if ($(window).scrollTop() > 10) {
                $('.header-menu-wrapper').addClass('header-fixed');
                $('.main-menu-content').addClass('h-fixed');
            } else {
                $('.header-menu-wrapper').removeClass('header-fixed');
                $('.main-menu-content').removeClass('h-fixed');
            }
        });
    }
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        {/* Logo */}
                                        <div className="owner">
                                            Are you a Business Owner?
                                        </div>

                                        {/* Navbar  */}
                                            <PrimaryNavbar />
                                       

                                        {/* Author Access */}
                                        {/* <HeaderAccess /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
