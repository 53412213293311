import React, {Component} from 'react';
import $ from 'jquery'

class WidgetFilterCategory extends Component {
    componentDidMount() {
        $(document).ready(function () {
            $(".sidebar-widget .filter-by-category").css({height: 'auto', overflow: 'hidden'})
            $(document).on('click', '#showfilterbycategory', function () {
                $(".sidebar-widget .filter-by-category").css({height: '468px', overflow: 'hidden'})
                $(this).addClass('lessfilterbyfeature');
            })
            $(document).on('click', '.lessfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-category").css({height: '244px', overflow: 'hidden'})
                $(this).removeClass('lessfilterbyfeature');
            })
        })
    }
    state = {
        title: 'Suggested',
        categories: [
            {
                id: 1,
                cat: 'Open Now',
                catNum: 11
            },
            {
                id: 2,
                cat: 'Katika Delivery',
                catNum: 22
            },
            {
                id: 3,
                cat: 'Katika Takeout',
                catNum: 9
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>
                    <div className="check-box-list show-more-item filter-by-category filter-category mt-4 mb-4">

                        {this.state.categories.map(item => {
                            return (
                                <div className="custom-checkbox" key={item.id}>
                                    <input type="checkbox" id={'chb'+item.id} />
                                    <label htmlFor={'chb'+item.id}>
                                        {item.cat} <span>{item.catNum}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    {/*<div id="showfilterbycategory" className="showmore-btn font-weight-semi-bold text-capitalize d-block ml-auto mr-auto text-center radius-rounded p-0">
                        <span className="showmore-txt ">Show More</span>
                        <span className="lessmore-txt d-none">Show Less</span>
                    </div>*/}
                </div>
            </>
        );
    }
}

export default WidgetFilterCategory;