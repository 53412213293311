import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import Footer from "../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';

import InFb from '../../assets/images/In-fb.png';
import InEmail from '../../assets/images/In-email.png';


import CheckIn from '../../assets/images/CheckIn.png';
import { FaTrashAlt } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';
import { ApiUrl, buyerUrl } from '../../config';
import axios from 'axios';
import { uInfo, uToken } from '../../useToken';
import { AvForm, AvGroup, AvInput, AvFeedback, } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import { RiSearchLine } from 'react-icons/ri'

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";

import Autosuggest from 'react-autosuggest';

const getSuggestionValue = (suggestion) => suggestion.full_name
const renderSuggestion = (suggestion) => <Link to={`user-profile/${suggestion.id}`}>{suggestion.full_name}</Link>


class BuyerLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            location: { title: '', address: '' },
            value: '',
            suggestions: [],
            values: []

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handler = this.handler.bind(this);

    }


    onChange = (event, { newValue, method }) => {
        this.setState({ value: newValue, search: newValue });
    }


    onSuggestionsFetchRequested = ({ value }) => {
        const headers = { 'Authorization': `Bearer ${uToken()}` }
        fetch(`${ApiUrl}getFindFriends?search=${value}`, { headers })
            .then(response => response.json())
            .then(data => this.setState({ suggestions: data.lists }))
    }


    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionSelected = () => {

    }

    componentDidMount() {
        for (let i = 0; i <= 2; i++) {
            this.addClick();
        }

    }

    createUI() {
        return this.state.values.map((el, i) =>
            <div className="form-group" key={i}>
                <input type="email" required={i == 0 ? true : false} placeholder={i == 0 ? "e.g. rashida@email.com" : ""} className="form-control" value={el || ''} onChange={this.handleChange.bind(this, i)} />
                {/* {i > 2 ? <input type='button' className="btn btn-link remove-email-link" value='remove' onClick={this.removeClick.bind(this, i)} /> : ""} */}
            </div>
        )
    }

    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
    }

    addClick() {
        this.setState(prevState => ({ values: [...prevState.values, ''] }))
    }

    removeClick(i) {
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let self = this;
        let email = this.state.values;
        await axios.post(ApiUrl + 'inviteFriendsViaEmail', { 'email': email }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        values: []
                    })
                    for (let i = 0; i <= 2; i++) {
                        self.addClick();
                    }

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // handleSubmit = async (event, errors, values) => {
    //     console.log(errors, 'assa', values)
    //     let self = this;

    // const { id } = this.props.match.params;
    // let url = '';
    // if (id) {
    //     url = 'updateBuyerLocation'
    //     values.id = id;
    // } else {
    //     url = 'addBuyerLocation'
    // }



    // axios.post(ApiUrl + url, values, {
    //     headers: {
    //         'Authorization': `Bearer ${uToken()}`
    //     }
    // })
    //     .then(function (res) {
    //         if (res.data.success === true) {
    //             toast.success(res.data.message);
    //             self.setState({
    //                 location: { title: '', address: '' },
    //             })
    //         } else {
    //             toast.error(res.data.message);
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
    // }


    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'e.g. Bob Shaw, Keyana P,',
            value,
            className: 'search-friend form-control',
            type: "text",
            onChange: this.onChange
        };
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews FindFriend">
                {/* Header */}
                <NavbarListing handler={this.handler} />

                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <div className="sidebar section-bg mt-2 mb-4 BuyerProfile">
                                <div className="sidebar-widget">
                                    <div className="section-block-2 mb-3"></div>
                                    <div className="BuyerName">
                                        Find Friends
                                    </div>
                                    <div className="info-list">

                                        <ul className="list-items">

                                            <li key={0}>
                                                <Link to='/find-friends-fb' className={(window.location.pathname == '/find-friends-fb') ? 'active' : ''}>
                                                    <span className="dashboard-icon"><img src={InFb} alt="Dashboard Icon" /></span>
                                                    On Facebook
                                                </Link>
                                            </li>
                                            <li key={1}>
                                                <Link to='/find-friends' className={(window.location.pathname == '/find-friends') ? 'active' : ''}>
                                                    <span className="dashboard-icon"><img src={InEmail} alt="Dashboard Icon" /></span>
                                                    Invite Friends to Katika
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="info-list-2">
                                        <div className="BuyerEmail">
                                            <div className="BuyerPagesHeading">Search Friends on Katika </div>
                                            <div className="find-friend-search SearchArea">
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}

                                                />
                                                <div className="EmailAdd friend">
                                                    <button type="button"><RiSearchLine /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="BuyerContent BuyerLocation BuyerFindFriend">
                            <div className="y-page-link reviews ">
                                <div className="BuyerEmailList">
                                    <div className="BuyerEmail">
                                        <div className="BuyerPagesHeading">Send Katika Invites to These Email Addresses:</div>
                                    </div>

                                </div>
                                <div className="reviews-tabs">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-area">
                                            <div className="tabs-content">
                                                <div className="EditLocationForm">
                                                    <label>Email address</label>
                                                    {this.createUI()}
                                                </div>
                                            </div>
                                        </div>

                                        <input type='button' className="btn btn-link" value='Add another email address' onClick={this.addClick.bind(this)} />
                                        <div className="EmailAdd EditLocation">
                                            <button>Send</button>
                                        </div>
                                        {/* <input type="submit" value="Submit" /> */}
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerLocation;