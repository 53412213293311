import React, { Component } from 'react';
import axios from "axios";
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';


class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userImg: '',
            userName: '',
            userStatus: '',
            userType: '',
            userFrom: '',
            userMembership: '',
            userRole: '',
            percent: 0
        };

    }

  
    componentDidMount() {
        console.log(this.props , 'Sidebar')
        if(this.props.percent){
            this.setState({
                percent: this.props.percent
            })
        }
        this.getProfileInfo();
    }


    getProfileInfo = () => {
        var self = this;

        axios.post(ApiUrl + 'user', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    userImg: res.data.user.image,
                    userName: res.data.user.fname + ' ' + res.data.user.lname,
                    userStatus: res.data.user.status,
                    userType: res.data.user.user_type,
                    userFrom: res.data.user.address,
                    userMembership: res.data.user.member_science,
                    userRole: res.data.user.role_id
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <section>
                    <div className="sidebar section-bg">
                        <div className="sidebar-widget">
                            <div className="author-bio text-align-center mb-1 author-bio-img">
                                <div className="align-items-center">
                                    <img src={this.state.userImg} alt="author" />
                                    <div className="author-inner-bio">
                                        <h4 className="author__title font-weight-bold pt-2 pb-0 mb-1">
                                            {this.state.userName}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="section-block-2 mb-3"></div>
                            <div className="info-list">
                                <ul className="list-items">
                                    <li className="d-flex justify-content-between">
                                        Status <strong className="font-weight-bold">{this.state.userStatus}</strong>
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        From <strong className="font-weight-bold">{this.state.userFrom}</strong>
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        Member since <strong className="font-weight-bold">{this.state.userMembership}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        );

    }
}


export default Sidebar;