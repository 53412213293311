import React, { Component } from 'react'
import iosapp from '../../assets/images/ios-app.png'
import googleapp from '../../assets/images/google-app.png'
import { Link } from "react-router-dom";



export default class Kapp extends Component {
    render() {
        return (
            <>
                <div className="k-class-2 bg-black1 min-height-130">
                    <div className="container ">
                        <div className="row text-align" >
                            <div className="col-md-6 col-sm-6">
                                <h1>Download the Katika App Today</h1>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <ul className="inline-flex">
                                    <li className="pl-10 pr-10">
                                        <Link to="#"  >
                                            <img height="45px" src={iosapp} alt="ios-app" />
                                        </Link>
                                    </li>
                                    <li className="pl-10 pr-10">
                                        <Link to="#"  >
                                            <img height="45px" src={googleapp} alt="google-app" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
