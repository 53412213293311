import React, { Component, useState } from 'react';
import SignUpBox from "../components/other/account/SignUpBox";
// import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import ImageSignUp from '../assets/images/katika_signup.png';
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/dashboard-logo.svg';
import { Container, Row, Col } from "react-bootstrap";
import { BiCheckCircle } from 'react-icons/bi';
import katika_K2 from '../assets/images/katika_K2.svg';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { ApiUrl } from '../config';
import { useHistory } from "react-router-dom";
import useToken from '../useToken';
function VerifySellerAccount(props) {

    const { setToken } = useToken();
    const [code, setCode] = useState();
    const [email, setEmail] = useState(props.match.params.email);
    let history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('code', code);
        formData.append('email', email);
        axios.post(ApiUrl + 'verifySellerApp', formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    setTimeout(
                        () => history.push({
                            pathname: '/business-login',
                        }),
                        1000
                    );
                    // if (history.location.state.prevPath == '/' || history.location.state.prevPath == '/login') {
                    //     setTimeout(
                    //         () => history.push("/buyer-profile"),
                    //         4000
                    //     );
                    // } else {

                    //     history.goBack();
                    // }

                    // setToken(res.data);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <main className="signup-page login-page Verify-buyer-account">
            <div className="SignUpTopBar">
                <Container>
                    <Row>
                        <div className="col-lg-3">
                            <div className="logo listing">
                                <Logo url={Logosrc} />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
            {/* Header */}
            {/* <GeneralHeader /> */}

            {/* Breadcrumb 
                <Breadcrumb CurrentPgTitle="Sign Up" img={this.state.breadcrumbimg} />*/}

            <section className="form-shared  login-page padding-top-40px padding-bottom-50px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="about step-4">
                                <h1>One last step!</h1>
                            </div>

                            <div className="steps margin-top-20px">
                                <h2>Step 3 of 3</h2>
                            </div>
                            <div className='SignUpSelection LoginSection padding-top-50px padding-bottom-50px padding-left-40px padding-right-40px margin-top-50px text-center margin-bottom-50px'>
                                <div className="billing-form-item mb-0">


                                    <div className="billing-title-wrap border-bottom-0 pt-0 pr-0 pl-0 pb-0 text-center">
                                        <img src={katika_K2} />
                                        <h3 className="widget-title font-size-28 padding-top-40px pb-0">
                                            Activate Your Account
                                        </h3>

                                    </div>
                                    <div className="billing-content verify-acc">
                                        <div class="alert alert-success fade show" role="alert">
                                            <strong>Success! </strong>
                                            You're almost finished. We emailed you a One Time Password. Check your email and enter your One Time Password here.
                                        </div>
                                        <div className="contact-form-action sign-up mt-0">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <div className="form-group">
                                                                <input readOnly value={email} className="form-control" type="email" name="email" placeholder="Enter email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <div className="form-group">
                                                                <input onChange={e => setCode(e.target.value)} className="form-control" required type="text" minLength="6" name="code" placeholder="Enter One Time Password" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="btn-box">
                                                            <button className="theme-btn btn-login border-0 margin-top-10px" type="submit">
                                                                Activate  My Account
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />

            <ScrollTopBtn />

        </main>
    );
}
export default VerifySellerAccount;