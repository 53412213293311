import React, { Component } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import { BsMap, BsBookmark } from "react-icons/bs";
import { BiCalendarWeek, BiPhone } from "react-icons/bi";
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import RButton from 'react-bootstrap/Button'

import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import { Link } from 'react-router-dom';
import PhotoGallery from '../sliders/ReviewPhotoGallery';
import $ from 'jquery';

class ProfileOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            comments: [],
            externalData: false,
            currentPage: 1,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this);
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    removeReview(index, id) {
        const reducedArr = [...this.state.comments];
        var self = this;
        axios.post(ApiUrl + 'deleteUserReview', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success) {
                    reducedArr.splice(index, 1);
                    self.setState({ comments: reducedArr })
                    self.getBusinessList(self.state.currentPage);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getBusinessList(count);
    }

    componentDidMount() {
        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })
        this.getBusinessList();
    }

    getBusinessList = (count) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserReviews?page=${pg}&limit=5`, {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        comments: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <div className="Buyer-Reviews overView">
                    <div className="BuyerContent">
                        <div className="y-page-link reviews">
                            <h4>Reviews</h4>
                            <div className="reviews-tabs">
                                <div className="form-area">
                                    <div className="tabs-content">
                                        <ul className="comments-list padding-top-10px">
                                            {this.state.comments.map((item, i) => {
                                                return (
                                                    <>
                                                        <li key={i}>
                                                            <div className="comment listing-details" key={i}>
                                                                <div className="user-img">
                                                                    <img className="avatar__img" alt="Comment" src={item.business_logo} />
                                                                </div>
                                                                <div className="comment-body">
                                                                    <div className="meta-data">
                                                                        <Link to={`/biz/${item.business_slug}`} className="comment__author">
                                                                            {item.business_name}
                                                                        </Link>
                                                                        <p className="comment-user">{item.category} , {item.sub_category}  </p>
                                                                        <p>{item.business_address.replace(", USA", "")}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="ReviewsDate">{item.date}</div>
                                                            </div>
                                                            <div className="rating-rating rating-detail landing">
                                                                <Rating
                                                                    placeholderRating={item.rating}
                                                                    readonly={true}
                                                                    stop={5}
                                                                    emptySymbol={<img src={Empty} className="icon" />}
                                                                    placeholderSymbol={<img src={Fill} className="icon" />}
                                                                    fullSymbol={<img src={Fill} className="icon" />}
                                                                />
                                                                <span className="comment-date">
                                                                    {/* {item.diff} */}
                                                                </span>
                                                            </div>
                                                            <p className="comment-content">
                                                                {item.description}
                                                            </p>

                                                            {item.business_review_image.map((img, i) => {
                                                                return (
                                                                    <div className="product-img delete-account-info" key={i} onClick={() => this.setState({
                                                                        currentImg: item.business_review_image[i],
                                                                        business: item,
                                                                        all_images: item.business_review_image,
                                                                        image_click: i,
                                                                        click: true

                                                                    })}>
                                                                        <img src={img.image} alt="Photos" />
                                                                    </div>
                                                                )
                                                            })}
                                                            {/* <div className="DeleteReviews">
                                                                    <RButton onClick={() => this.removeReview(i, item.id)}
                                                                        type="button" className="" variant="none">
                                                                        <span><FaTrashAlt /></span>
                                                                    </RButton>
                                                                </div> */}

                                                        </li>
                                                    </>

                                                )
                                            })}
                                        </ul>
                                    </div>

                                    <div className="col-lg-12 text-center mt-3">
                                        {
                                            (this.state.totalpage > 1) ?
                                                Array.from(Array(this.state.totalpage), (e, i) => {
                                                    return (
                                                        <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                            <span className="">
                                                                <span className="icon-label">{i + 1}</span></span>
                                                        </button>
                                                    );
                                                })
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content p-4">
                                <div className="modal-top border-0 mb-4 p-0">
                                    <div className="alert-content">
                                        {this.state.click ? <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProfileOverview;