import axios from 'axios';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ApiUrl } from '../../../config';


class TermsOfService extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getPrivacyPolicy', { 'type': 'privacy policy' }, {
            headers: {}
        })
            .then(function (res) {

                if (res.data.success === true) {
                    self.setState({
                        profile: res.data.lists,
                        externalData: true,
                    })
                } else {
                    self.setState({
                        externalData: true,
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <main className="signup-page padding-top-30px padding-bottom-60px">
                <Container>
                    <div className="TermsBG margin-top-100px">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="TermsHeading">
                                    <h1>Privacy Policy</h1>
                                </div>
                                {
                                    this.state.externalData ?
                                        <div className="TermsContent">
                                            <p dangerouslySetInnerHTML={{ __html: this.state.profile.description }}></p>
                                        </div>
                                        : ""
                                }

                            </Col>
                        </Row>
                    </div>
                </Container>

            </main>
        );
    }
}

export default TermsOfService;