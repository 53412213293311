import React, { Component } from 'react';
import GeneralHeader from "../../../components/common/GeneralHeader";
import BuyerSidebar from "../../../components/user/public/BuyerSidebar";
import Footer from "../../../components/common/footer/Footer";
import UserInfo from "../../../components/user/public/UserInfo";
import { Container, Dropdown } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import BLogo from '../../../assets/images/img30.jpg';
import NavbarListing from '../../../components/common/NavbarListing';
import axios from 'axios';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            friends: [],
            externalData: false,
            currentPage: 1,
            sortFilter: 'Alphabetic A-Z',
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this)
    }
    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getFriends(count);
    }

    componentDidMount() {
        this.getFriends();
    }

    getFriends = (count, sort) => {
        var self = this;
        const { id } = this.props.match.params;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        if (sort) {
            self.setState({
                sortFilter: sort
            })
        }

        let data = {
            'user_id': id,
            'sorting': sort,
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserFriends?page=${pg}&limit=10`, data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        friends: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        const { id } = this.props.match.params;
        return (
            <main className="dashboard-page BuyerProfile BuyerFriends">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="user-info">

                    <UserInfo userID={id} />

                </div>



                <Container>

                    <div className="Buyer-wrapper">
                        <div className="sidebarBuyer">
                            <BuyerSidebar userID={id} />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <h4>Friends</h4>
                            </div>
                            <div className="sorted">
                                <Dropdown onSelect={(sort) => this.getFriends(this.state.currentPage, sort)}>
                                    <label>Sort by:&nbsp;</label>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <strong>{this.state.sortFilter}</strong>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="Alphabetic A-Z">Alphabetic A-Z</Dropdown.Item>
                                        <Dropdown.Item eventKey="Alphabetic Z-A">Alphabetic Z-A</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <div className="manage-friend">Manage Friends</div> */}
                            </div>
                            <div className="frineds-list">
                                <ul>
                                    {this.state.friends.map((item, i) => {
                                        return (
                                            <>
                                                <li key={i}>
                                                    <div className="friend-pic">
                                                        <img src={item.image} alt="Blog" className="card__img" />
                                                    </div>
                                                    <div className="friend-info">

                                                        {
                                                            item.type == 'Manual' ?

                                                                item.manual_user.id ?
                                                                    <Link to={`/user-profile/${item.manual_user.id}`} >{item.manual_user.fname} {item.manual_user.lname}</Link>
                                                                    :
                                                                    <Link to="#" >{item.name}</Link>
                                                                :
                                                                item.user.id ?
                                                                    <Link to={`/user-profile/${item.user.id}`} >{item.user.fname} {item.user.lname}</Link>
                                                                    :
                                                                    <Link to="#" >{item.name}</Link>

                                                        }
                                                        {/* <h5>Washington, DC</h5>
                                                        <span>244</span><span>8</span> */}
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="col-lg-12 text-center mt-3">
                                {
                                    (this.state.totalpage > 1) ?
                                        Array.from(Array(this.state.totalpage), (e, i) => {
                                            return (
                                                <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                    <span className="">
                                                        <span className="icon-label">{i + 1}</span></span>
                                                </button>
                                            );
                                        })
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;