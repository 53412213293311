import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import '../../assets/css/style2.css';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';

import $ from 'jquery';
import StoryGallery from './StoryGallery';
import video from '../../assets/video/ssstiktok_1623214518.mp4';
import img4 from '../../assets/images/1.jpg';
import img5 from '../../assets/images/2.jpg';
import { Link } from 'react-router-dom';

class Account extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stories: []
        }
    }

    componentDidMount() {
        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })


        this.getUserStories();
    }

    getUserStories = (count) => {
        var self = this;
        self.setState({
            externalData: false,
        })
        // console.log(data)
        axios.post(ApiUrl + `getUserStories`, {},
            {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
            .then(function (res) {
                console.log(res.data.data)
                if (res.data.success == true) {

                    self.setState({
                        externalData: true,
                        stories: res.data.data,
                    })



                } else {
                    self.setState({
                        externalData: true,
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    render() {
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={7} md={7} id="page-content-wrapper">
                            <Row>
                                <Col lg={12} md={12}>
                                    <section className="dashboard-area account-page seller-stories">
                                        <div className="BuyerContent">
                                            <div className="reviews">
                                                <h4>My Stories</h4>
                                                <div className="stories-landing-user ">
                                                    <div className="container">
                                                        <div className="row">
                                                            <h2>Video</h2>
                                                        </div>
                                                        <div className="reviews-tabs buyer-story ">
                                                            <div className="row">

                                                                {this.state.stories.map((story, storyId) => {

                                                                    return (

                                                                        <div key={storyId} className={'story  custom-story col-md-4'} >
                                                                            <Link className="item-link"
                                                                                to={{
                                                                                    pathname: `/seller-view-story`,
                                                                                    state: {
                                                                                        current: story,
                                                                                        image_click: storyId,
                                                                                        images: this.state.stories
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* <span className="user-preview">
                                                                                    <img src={story.profile_picture} />
                                                                                </span> */}
                                                                                <span className="item-preview">
                                                                                    <img src={story.preview} />

                                                                                </span>
                                                                                <span className="info user">
                                                                                    <img width="20" height="20" src="https://katika-images.s3.us-east-2.amazonaws.com/category/Heart%20Icon1632822639.png" />
                                                                                    <strong className="name pl-10" itemProp="name">{story.stories[0].total_like}</strong>
                                                                                    {/* <span className="time">{story.lastUpdated}</span> */}
                                                                                </span>
                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                    <ToastContainer />
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Account;