import React, { Component } from 'react';
import { FaQuoteLeft } from 'react-icons/fa'
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import SlideOne from '../../assets/images/SlideOne.png';
import SlideTwo from '../../assets/images/SlideTwo.png';
import SlideThree from '../../assets/images/SlideThree.png';
import SlideFive from '../../assets/images/SlideFive.png';


class Testimonial extends Component {
    testimonialState = {



        sliders: [
            {
                id: 1,
                title: 'Richard Doe',
                heading: 'Buying Black Just Got Easier',
                content: 'We’re bringing the spotlight to Black Owned Businesses because at Katika you’re not just an added attraction, you’re our business.',
                SlideImg: SlideOne,
            },
            {
                id: 3,
                title: 'Collis Pong',
                heading: 'You’re the Right Size',
                content: "At Katika, no business is too small or too big; our directory scales with the tools needed for you to create seamless, friendly and feature rich experiences, and we're still growing!",
                SlideImg: SlideThree,
            },
            {
                id: 2,
                title: 'Denwen Evil',
                heading: 'Let Us Promote You',
                content: 'Katika wants to be your helping hand, promoting and empowering Black Business Owners; we want to support you in creating wealth in communities of color, our communities.',
                SlideImg: SlideFive,
            },
        ]
    }
    render() {
        return (
            <>
                <Slider className="places-carousel testimonial-carousel" dots={true} infinite={true} slidesToScroll={1} slidesToShow={1} centerMode={false} centerPadding="50px" arrows={true}>
                    {this.testimonialState.sliders.map(slider => {
                        return (
                            <Row className="hello" key={slider.id + 1}>
                                <Col lg={6} sm={6}>
                                    <div className="testimonial-item" key={slider.id}>
                                        <div className="testi-comment">
                                            <h1>
                                                {slider.heading}
                                            </h1>
                                            <p className="testi__desc" dangerouslySetInnerHTML={{ __html: slider.content }}>
                                            </p>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg={6} sm={6}>
                                    <div className="sign-up-image"><img key={0} src={slider.SlideImg} alt="testimonial" className="random-img" /></div>
                                </Col>
                            </Row>

                        )
                    })}
                </Slider>
            </>
        );
    }
}

export default Testimonial;