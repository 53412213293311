import React, {Component} from 'react';
// import {Link} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col, Dropdown } from "react-bootstrap";


class GalleryPics extends Component {
    state = {
        tabs: [
                {
                    title: 'Upcoming',
                    id: 'Upcoming',
                },
                {
                    title: 'Pending',
                    id: 'Pending',
                },
                {
                    title: 'Past',
                    id: 'Past',
                },
        ], 
              
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                        
                        <Row>
                            
                        <Col lg={12} md={12}>
                            <TabList>
                                {this.state.tabs.map((tab, index) => {
                                    return (
                                        <Tab key={index} >{tab.title}</Tab>
                                    )
                                })}
                            </TabList>
                            
                                    <TabPanel>
                                        <div className="tabs-content">
                                            <div className="schdule-date">
                                                <h4>Friday, January 8, 2021</h4>
                                            </div>
                                            <div className="schdule-info">
                                                <span className="bulit"></span>
                                                <span className="schdule-time">09:00am - 09:15am</span>
                                                <div className="name-meeting">
                                                    <span className="client-name">Alonzo Coles</span><br></br>
                                                    <span className="metting-time">Event type <strong>15 Minute Meeting</strong></span>
                                                </div>
                                                <div className="details-meeting">
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">Details</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Col> 
                            </Row>
                        </Tabs>
                    </div>
                </div>
            </>
        );
    }
}
export default GalleryPics;