import React, { Component } from 'react';
import GeneralHeader from '../../components/webapp/common/GeneralHeader'
import Footer from "../../components/webapp/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { ApiUrl, AutoApiKey } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import GeneralMap from '../../components/webapp/GeneralMap';
import { FaCaretLeft } from 'react-icons/fa';
class ListingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            business: {
                business_lat: 19.434940, business_long: -99.195697
            },
            externalData: false,
        }
    }


    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'singleBusiness', { business_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    self.setState({
                        business: res.data.business,
                        externalData: true,
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {

        return (

            <main className="Mobile Directions">
                {/* Header */}
                <GeneralHeader />

                <div className="Mdirection">
                    <div className="m-direction">
                        <button onClick={() => window.history.back()} className="back"><FaCaretLeft />Back</button>
                        <div className="business-name">{this.state.business.business_name}</div>
                        <a href={`http://maps.google.com/maps?daddr=${this.state.business.business_address}&saddr=Current+Location`} className="navigate">Navigate</a>
                    </div>

                    {(this.state.externalData) ? <GeneralMap state={this.state.business} /> : ""}
                </div>

                <Footer />

                <ScrollTopBtn />
            </main>
        );


    }
}

export default ListingList;