import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import ScheduleTabs from "../";
import ScheduleTabs from "../../components/user/ScheduleTabs";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import { Collapse, CardBody, Card } from 'reactstrap';
import Edit from "../../assets/images/bx_bx-edit.png";


class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            externalData: true,
            business: {
                complete_percent: '',
                calendly_url: '',
                business_id: '',
                track_uid: '',
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getBusinessDetail();
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'userBusinessTagUID', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    externalData: true,
                    business: res.data.lists,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit(event, errors, values) {
        var self = this;
        self.setState({
            externalData: false
        })
        if (errors.length < 1) {
            values.business_id = this.state.business.business_id;
            axios.post(ApiUrl + 'updateBusinessUID', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        self.setState({
                            editVideoModal: false,
                            externalData: true
                        })
                        self.getBusinessDetail();
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    render() {
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    {(this.state.externalData) ?
                        <Row>
                            <Col lg={3} md={3} id="sidebar-wrapper">
                                <Sidebar percent={this.state.business.complete_percent} />
                            </Col>
                            <Col lg={9} md={9} id="page-content-wrapper">
                                <section className="dashboard-area business-detail">
                                    <div className="y-page-link">
                                        <h2>Analytics/Tag Manager</h2>
                                        <div className="edit-content">
                                            <button onClick={() => this.setState({ editVideoModal: !this.state.editVideoModal })} className="remove-btn-bg" >
                                                <span className="dashboard-icon">Edit <img src={Edit} alt="Copy" /></span>
                                            </button>
                                        </div>
                                        {(this.state.editVideoModal) ?
                                            <Collapse isOpen={this.state.editVideoModal}>
                                                <Card className="card-custom-1">
                                                    <CardBody >
                                                        <AvForm onSubmit={this.handleSubmit}>
                                                            <AvGroup>
                                                                <AvInput
                                                                    placeholder="UA-XXXXXXXXX-X"
                                                                    className="form-control"
                                                                    minLength="14"
                                                                    maxLength="14"
                                                                    type="text"
                                                                    name="track_uid"
                                                                    value={this.state.business.track_uid}
                                                                    required />
                                                                <AvFeedback> Required Field *</AvFeedback>
                                                            </AvGroup>
                                                            <div className="button-modal-footer">
                                                                <Button variant="secondary" onClick={() => this.setState({ editVideoModal: !this.state.editVideoModal })}>Close</Button>
                                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                                            </div>
                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            :

                                            <h5 className="video" target="_blank">
                                                {this.state.business.track_uid}
                                            </h5>
                                        }
                                    </div>
                                </section>
                                <ToastContainer />
                            </Col>
                        </Row>
                        : ""
                    }
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;