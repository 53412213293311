import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { FaTwitter, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { buyerUrl } from '../../../../config';


class CopyrightMenu extends Component {
    state = {
        links: [
            {
                path: '/terms-of-service',
                title: 'Terms of Service'
            },
            {
                path: '/privacy-policy',
                title: 'Privacy Policy'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="k-class white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copy-right">
                                    <div className="copyright-left-side justify-content-end d-flex">
                                        <ul className="list-items">
                                            <li>Copyright © 2020-2021 Katika, LLC. and related<br></br>marks are registered trademarks of Katika.</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CopyrightMenu;