import React, { Component } from 'react'
import new_landing_kp from '../../assets/images/new_landing_kp.png'
import new_landing_blog from '../../assets/images/new_landing_blog.png'
import new_directory3 from '../../assets/images/new_directory3.png'
import Button from "../common/Button";

export default class Discover extends Component {
    render() {
        return (
            <>
                <div className="k-class">
                    <div className="container">
                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Katika Labs is our Afrotech
                                         project for the world to see
                                         and experience</h3>
                                    <p>The team at Katika is enthusiastic about creating
                                         technology that showcases the best of black culture
                                         and talent. In the Katika Labs, we create projects that
                                         stay true to our overall company mission but also
                                         allow us to stray away from our traditional products.
                                         Our hope is to create cool technology that sparks
                                         ideas for other innovators and entrepreneurs of color
                                         to build upon.</p>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Go to Katika Labs >" url="/about-us" />
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_landing_kp} alt="new_landing_kp" />

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Keep up with the 
                                        latestnews, events, and 
                                        stories on the Katika Blog.</h3>
                                    <p>Being informed about current events is very 
                                        important as well as news happening around the 
                                        world. We share stories from news outlets around the 
                                        world in addition to writing our own stories about 
                                        black entrepreneurs around the world.</p>
                                        <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Read the Katika Blog >" url="/about-us" />
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_landing_blog} alt="new_landing_blog" />

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Manage your e-store with our shop manager tool</h3>
                                    <p>From your shop manager dashboard, you'll be able to
                                          manage orders as they come in and keep up with
                                          your inventory as it fluctuates day to day.</p>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Learn more about selling" url="/about-us" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_directory3} alt="new_directory3" />
                            </div>
                        </div>

                    </div>

                </div>

            </>
        )
    }
}
