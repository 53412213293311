import React, { useEffect, useState, Suspense, lazy } from 'react'
import GeneralHeader from '../../components/common/GeneralHeader'
import BannerOne from '../../components/directories/BannerOne'
import CityList from '../../components/directories/CityList'
import RecentActivity from '../../components/directories/RecentActivity'
import BrowseCategories from "../../components/directories/BrowseCategories";
import MobileApp from "../../components/directories/MobileApp";
import Stories from '../../components/directories/Stories'
import Posts from '../../components/directories/Posts'


// const BannerOne = lazy(() => import('../../components/directories/BannerOne'));

// const renderLoader = () => <p>Loading</p>;



import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import PostsListingList from '../listings/PostListBanner'




const Home = (props) => {

    const [location, setLocation] = useState({});


    useEffect(() => {
        var loc = JSON.parse(localStorage.getItem('location'));
        let data = {};
        if (loc != null) {
            data = {
                'city': loc.city,
                'region': loc.region,
                'country': loc.country,
                'lat': loc.latitude,
                'lng': loc.longitude,

            };
        } else {
            data = {
                'city': 'Philadelphia',
                'region': 'PA',
                'country': 'USA',
                'lat': 39.9525839,
                'lng': -75.1652215,
            };
        }
        setLocation(data);
    }, [!location]);

    return (
        <main className="home-1">
            {/* Header */}

            <GeneralHeader />

            {/* Hero Banner */}

            {/* 
            <Suspense fallback={<div>Loading...</div>}>
                <BannerOne />
                <CityList />
                <Stories />
                <RecentActivity history={props.history} />
                <BrowseCategories />
                <MobileApp />
            </Suspense> */}

            <Suspense fallback={<div>Loading...</div>}>
                <BannerOne />

                {
                    location.city && (
                        <PostsListingList location={location} />
                    )
                }

                {/* <CityList /> */}
                {/* <Posts /> */}
                {/* <Stories /> */}
                {/* <RecentActivity history={props.history} /> */}
                <BrowseCategories />
                {/* <MobileApp /> */}
            </Suspense>



            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Home;