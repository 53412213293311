import React, { Component } from 'react';
import GeneralHeader from "../../../components/common/GeneralHeader";
import BuyerSidebar from "../../../components/user/public/BuyerSidebar";
import UserInfo from "../../../components/user/public/UserInfo";
import Footer from "../../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../../assets/images/avatar.jpg';
import UseFull from '../../../assets/images/UseFull.png';
import Funny from '../../../assets/images/funny.png';
import Cool from '../../../assets/images/cool.png';
import NoCool from '../../../assets/images/cool-1.png';
import NoUseFull from '../../../assets/images/UseFull-1.png';
import NoFunny from '../../../assets/images/funny-1.png';


import { FaTrashAlt } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
import RButton from 'react-bootstrap/Button'
import { Button } from 'react-bootstrap';
import NavbarListing from '../../../components/common/NavbarListing';
import LoginBox from '../../../components/other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'
import $ from 'jquery';


import Rating from 'react-rating';
import Empty from '../../../assets/images/empty.png';
import Half from '../../../assets/images/half.png';
import Fill from '../../../assets/images/fill.png';

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
class BuyerReviews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            comments: [],
            externalData: false,
            currentPage: 1,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            });
        }
        this.getBusinessList();
    }

    onHide() {
        this.setState({ LoginModal: false, GalleryModal: false });
    }

    changeCoolNo = (id, activty, count) => {
        var self = this;
        $('.cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('active');

        // let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeCool = (id, activty, count) => {
        var self = this;
        $('.cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('active');

        // let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    changeSmileNo = (id, activty, count) => {
        var self = this;
        $('.smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('active');

        // let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeSmile = (id, activty, count) => {
        var self = this;
        $('.smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('active');

        // let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeUsefullNo = (id, activty, count) => {
        var self = this;
        $('.usefull-' + id).removeClass('none');
        $('.no-usefull-' + id).addClass('none');
        $('.no-usefull-' + id).removeClass('active');

        // let count = parseInt($('.useful-count-' + id).text());
        $('.useful-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeUsefull = (id, activty, count) => {
        var self = this;
        $('.usefull-' + id).addClass('none');
        $('.no-usefull-' + id).removeClass('none');
        $('.no-usefull-' + id).addClass('active');

        // let count = parseInt($('.useful-count-' + id).text());
        $('.useful-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.state.pageNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeReview(index, id) {
        // const reducedArr = [...this.state.comments];
        // var self = this;
        // axios.post(ApiUrl + 'deleteUserReview', {'id' : id}, {
        //     headers: {
        //         'Authorization': `Bearer ${uToken()}`
        //     }
        // })
        //     .then(function (res) {
        //         if (res.data.success) {
        //             reducedArr.splice(index, 1);
        //             self.setState({ comments: reducedArr })
        //             self.getBusinessList(self.state.currentPage);
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getBusinessList(count);
    }

    componentDidMount() {
        this.getBusinessList();
    }

    getBusinessList = (count) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }
        self.setState({
            externalData: false,
            pageNumber: count
        })
        const { id } = this.props.match.params;
        // console.log(data)
        axios.post(ApiUrl + `getReviews?page=${pg}&limit=5`, { user_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        comments: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                } else {
                    self.setState({
                        externalData: true,
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        const { id } = this.props.match.params;
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews UserReviews">
                {/* Header */}
                {/* <GeneralHeader /> */}
                <NavbarListing handler={this.handler} />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="user-info">
                    <UserInfo userID={id} />
                </div>
                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebar userID={id} />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <h4>Reviews</h4>
                                <div className="reviews-tabs">
                                    <div className="form-area">
                                        <div className="tabs-content">
                                            <ul className="comments-list padding-top-10px">
                                                {this.state.comments.map((item, i) => {
                                                    return (
                                                        <>
                                                            <li key={i}>
                                                                <div className="comment listing-details" key={i}>
                                                                    <div className="user-img">
                                                                        <img className="avatar__img" alt="Comment" src={item.business_logo} />
                                                                    </div>
                                                                    <div className="comment-body">
                                                                        <div className="meta-data">
                                                                            <Link to={`/biz/${item.business_slug}`} className="comment__author">
                                                                                {item.business_name}
                                                                            </Link>
                                                                            <p className="comment-user">{item.category} , {item.sub_category}  </p>
                                                                            <p>{item.business_address.replace(", USA", "")}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="rating-rating rating-detail landing PublicPage">
                                                                    <Rating
                                                                        placeholderRating={item.rating}
                                                                        readonly={true}
                                                                        stop={5}
                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                    />
                                                                    {/* <Rater total={5} rating={item.rating} interactive={false} /> */}
                                                                    <span className="comment-date">
                                                                        {/* {item.diff} */}
                                                                    </span>
                                                                    <div className="ReviewsDate">{item.date}</div>
                                                                </div>
                                                                <p className="comment-content">
                                                                    {item.description}
                                                                </p>
                                                                {item.business_review_image.map((img, i) => {
                                                                    return (
                                                                        <div className="product-img" key={i}>
                                                                            <img src={img.image} alt="Photos" />
                                                                        </div>
                                                                    )
                                                                })}
                                                                <br />
                                                                {(!uToken()) ?
                                                                    <div className="ReviewsBtn">
                                                                        <RButton onClick={() => this.setState({ LoginModal: true })}
                                                                            type="button" className="" variant="none">
                                                                            <span><img className="avatar__img" alt="Comment" src={UseFull} /></span>
                                                                        Useful
                                                                        <p className={`useful`}>
                                                                                <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                                                        </RButton>
                                                                        <RButton onClick={() => this.setState({ LoginModal: true })}
                                                                            type="button" className="" variant="none">
                                                                            <span><img className="avatar__img" alt="Comment" src={Funny} /></span>
                                                                        Funny
                                                                        <p className={`useful`}>
                                                                                <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>

                                                                        </RButton>
                                                                        <RButton onClick={() => this.setState({ LoginModal: true })}
                                                                            type="button" className="" variant="none">
                                                                            <span><img className="avatar__img" alt="Comment" src={Cool} /></span>
                                                                        Cool
                                                                        <p className={`useful`}>
                                                                                <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                                                        </RButton>
                                                                    </div>
                                                                    :
                                                                    <div className="ReviewsBtn">
                                                                        {item.review_useful == 1 ?

                                                                            <div className="use">


                                                                                <Button variant="none" title="Usefull"
                                                                                    onClick={() => this.changeUsefull(item.id, 1, item.totalBusinessReviewUseful)}
                                                                                    className={`btn btn-link card__img none usefull-${item.id}`}
                                                                                >
                                                                                    <img src={UseFull} className="card__img" alt="review" />
                                                                                    <span className="ml-20"></span>Useful
                                                                                    <p className={`useful1`}>
                                                                                        <span className={`useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                                                                </Button>
                                                                                <Button variant="none" title="Usefull"
                                                                                    onClick={() => this.changeUsefullNo(item.id, 0, item.totalBusinessReviewUseful)}
                                                                                    className={`btn btn-link card__img active  no-usefull-${item.id}`}
                                                                                >
                                                                                    <img src={NoUseFull} className="card__img" alt="review" />
                                                                                    <span className="ml-20"></span>Useful
                                                                                    <p className={`useful1`}>
                                                                                        <span className={`useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                                                                </Button>
                                                                            </div>
                                                                            :

                                                                            <div className="use">


                                                                                <Button variant="none" title="Usefull"
                                                                                    onClick={() => this.changeUsefull(item.id, 1, item.totalBusinessReviewUseful)}
                                                                                    className={`btn btn-link avatar__img usefull-${item.id}`}
                                                                                >
                                                                                    <img src={UseFull} className="card__img" alt="review" />
                                                                                    <span className="ml-20"></span>Useful
                                                                                    <p className={`useful1`}>
                                                                                        <span className={`useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                                                                </Button>
                                                                                <Button variant="none" title="Usefull"
                                                                                    onClick={() => this.changeUsefullNo(item.id, 0, item.totalBusinessReviewUseful)}
                                                                                    className={`btn btn-link card__img none no-usefull-${item.id}`}
                                                                                >
                                                                                    <img src={NoUseFull} className="avatar__img" alt="review" />
                                                                                    <span className="ml-20"></span>Useful
                                                                                    <p className={`useful1`}>
                                                                                        <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                                                                </Button>


                                                                            </div>
                                                                        }

                                                                        {
                                                                            item.review_funny == 1 ?

                                                                                <div className="use">


                                                                                    <Button variant="none" title="Funny"
                                                                                        onClick={() => this.changeSmile(item.id, 1, item.totalBusinessReviewFunny)}
                                                                                        className={`btn btn-link card__img none funny-${item.id}`}
                                                                                    >
                                                                                        <img src={Funny} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Funny
                                                                                    <p className={`funny`}>
                                                                                            <span className={`funny-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>
                                                                                    </Button>
                                                                                    <Button variant="none" title="Funny"
                                                                                        onClick={() => this.changeSmileNo(item.id, 0, item.totalBusinessReviewFunny)}
                                                                                        className={`btn btn-link card__img active  no-funny-${item.id}`}
                                                                                    >
                                                                                        <img src={NoFunny} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Funny
                                                                                    <p className={`funny`}>
                                                                                            <span className={`funny-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>
                                                                                    </Button>
                                                                                </div>
                                                                                :

                                                                                <div className="use">



                                                                                    <Button variant="none" title="Funny"
                                                                                        onClick={() => this.changeSmile(item.id, 1, item.totalBusinessReviewFunny)}
                                                                                        className={`btn btn-link avatar__img funny-${item.id}`}
                                                                                    >
                                                                                        <img src={Funny} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Funny
                                                                                    <p className={`funny`}>
                                                                                            <span className={`funny-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>
                                                                                    </Button>
                                                                                    <Button variant="none" title="Funny"
                                                                                        onClick={() => this.changeSmileNo(item.id, 0, item.totalBusinessReviewFunny)}
                                                                                        className={`btn btn-link card__img none no-funny-${item.id}`}
                                                                                    >
                                                                                        <img src={NoFunny} className="avatar__img" alt="review" />
                                                                                        <span className="ml-20"></span>Funny
                                                                                    <p className={`funny`}>
                                                                                            <span className={`funny-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>
                                                                                    </Button>


                                                                                </div>
                                                                        }

                                                                        {
                                                                            item.review_cool == 1 ?

                                                                                <div className="use">


                                                                                    <Button variant="none" title="Cool"
                                                                                        onClick={() => this.changeCool(item.id, 1, item.totalBusinessReviewCool)}
                                                                                        className={`btn btn-link card__img none cool-${item.id}`}
                                                                                    >
                                                                                        <img src={Cool} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Cool
                                                                                    <p className={`cool`}>
                                                                                            <span className={`cool-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                                                                    </Button>
                                                                                    <Button variant="none" title="Cool"
                                                                                        onClick={() => this.changeCoolNo(item.id, 0, item.totalBusinessReviewCool)}
                                                                                        className={`btn btn-link card__img active  no-cool-${item.id}`}
                                                                                    >
                                                                                        <img src={NoCool} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Cool
                                                                                    <p className={`cool`}>
                                                                                            <span className={`cool-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                                                                    </Button>
                                                                                </div>
                                                                                :

                                                                                <div className="use">




                                                                                    <Button variant="none" title="Cool"
                                                                                        onClick={() => this.changeCool(item.id, 1, item.totalBusinessReviewCool)}
                                                                                        className={`btn btn-link avatar__img cool-${item.id}`}
                                                                                    >
                                                                                        <img src={Cool} className="card__img" alt="review" />
                                                                                        <span className="ml-20"></span>Cool
                                                                                    <p className={`cool`}>
                                                                                            <span className={`cool-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                                                                    </Button>
                                                                                    <Button variant="none" title="Cool"
                                                                                        onClick={() => this.changeCoolNo(item.id, 0, item.totalBusinessReviewCool)}
                                                                                        className={`btn btn-link card__img none no-cool-${item.id}`}
                                                                                    >
                                                                                        <img src={NoCool} className="avatar__img" alt="review" />
                                                                                        <span className="ml-20"></span>Cool
                                                                                    <p className={`cool`}>
                                                                                            <span className={`cool-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                                                                    </Button>


                                                                                </div>
                                                                        }


                                                                    </div>
                                                                }



                                                            </li>
                                                        </>

                                                    )
                                                })}
                                            </ul>
                                        </div>

                                        <div className="col-lg-12 text-center mt-3">
                                            {
                                                (this.state.totalpage > 1) ?
                                                    Array.from(Array(this.state.totalpage), (e, i) => {
                                                        return (
                                                            <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                                <span className="">
                                                                    <span className="icon-label">{i + 1}</span></span>
                                                            </button>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <h5>Login required to perform this action </h5> */}
                            <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                        </Modal.Body>
                    </Modal>

                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerReviews;