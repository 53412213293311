import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import Footer from "../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';
import CheckIn from '../../assets/images/CheckIn.png';
import { FaTrashAlt } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { AvForm, AvGroup, AvInput, AvFeedback, } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
class BuyerLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            location: { title: '', address: '' },
        }


        this.handler = this.handler.bind(this);

    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.getLocation(id);
            this.setState({
                type: 'Edit'
            })
        }
    }

    getLocation = (id) => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'editBuyerLocation', { location_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if( res.data.success ===  true){
                    self.setState({
                        location: res.data.lists,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handleSubmit = async (event, errors, values) => {
        console.log(errors, 'assa', values)
        let self = this;

        const { id } = this.props.match.params;
        let url = '';
        if (id) {
            url = 'updateBuyerLocation'
            values.id = id;
        }else{
            url = 'addBuyerLocation'
        }

       

        axios.post(ApiUrl + url , values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        location: { title: '', address: '' },
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />

                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent BuyerLocation">
                            <div className="y-page-link reviews ">
                                <div className="BuyerEmailList">
                                    <div className="BuyerEmail">
                                        <div className="BuyerPagesHeading">{this.state.type ? 'Edit location' : 'Add location'}</div>
                                    </div>

                                </div>
                                <div className="reviews-tabs">
                                    <AvForm onSubmit={this.handleSubmit}>
                                        <div className="form-area">
                                            <div className="tabs-content">
                                                <div className="EditLocationForm">
                                                    <AvGroup>
                                                        <label>Location Name (Home, Office)</label>
                                                        <AvInput
                                                            placeholder="Home"
                                                            className="form-control"
                                                            name="title"
                                                            value={this.state.location.title}
                                                        />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>

                                                    {/* <input className="form-control" placeholder="Home"></input><br></br> */}

                                                    <AvGroup>
                                                        <label>Address, City, State, and/or Zip</label>
                                                        <AvInput
                                                            placeholder="Philadelphia, 19139, US"
                                                            className="form-control"
                                                            name="address"
                                                            value={this.state.location.address}
                                                        />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>

                                                </div>
                                            </div>
                                            <div className="EmailAdd EditLocation">
                                                <button>Save</button>
                                                <Link to="/user-location" >Cancel</Link>
                                            </div>
                                        </div>
                                    </AvForm>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerLocation;