import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import CameraIcon from '../../assets/images/reviews-add-photo.png'
import Loader from 'react-loader';

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 0,
    marginRight: 0,
    width: '100%',
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    alignItems: 'start'
};

const img = {
    display: 'block',
    width: 'auto',
    maxWidth: '200px',
    height: '110px'
};


function ReviewsPhotoUploader(props) {
    const [myFiles, setMyFiles] = useState([])
    const [title, setTitle] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [id] = useState(props.business_id)
    const onDrop = useCallback(acceptedFiles => {
        // setMyFiles([...myFiles, ...acceptedFiles])
        setMyFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

    }, [myFiles]);

    const { getRootProps, getInputProps, acceptedFileItems, fileRejections } = useDropzone({
        onDrop, maxFiles: 4, accept: 'image/*',
    });

    const submitForm = async e => {
        e.preventDefault();
        if (myFiles.length > 0) {
            setLoaded(false)
            var formData = new FormData();
            myFiles.map((item) =>
                formData.append('business_images[]', item)
            );
            // formData.append('business_images', myFiles);
            formData.append('business_id', id);
            formData.append('title', Object.values(title));
            var type = myFiles[0].type;
            await axios.post(ApiUrl + 'addBusinessImages', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        // toast.success(res.data.message);
                        setLoaded(false)
                        props.closeModal(res.data.message)
                    } else {
                        toast.error(res.data.message);
                        // props.closeModal(res.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.warning('Select Images');
        }
    }

    function handleChange(evt) {
        const { name, value } = evt.target;
        setTitle({ ...title, [name]: value });
    }

    const removeFile = (file, t) => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
        const newTitle = title
        delete newTitle[`title${t}`]
    }
    const thumbs = myFiles.map(file => (
        <li style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="Author Profile"
                />
                <textarea type="text" placeholder="Enter image title" rows="4" cols="100" className="form-control ml-3" name={`title${file.name}`} onChange={handleChange} >
                </textarea>
                <button
                    data-toggle="tooltip" data-placement="bottom" title="Remove"
                    className="btn btn-default badge-toggle b-left"
                    onClick={removeFile(file, file.name)}>
                    <AiOutlineClose />
                </button>
            </div>
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className="text-danger" key={errors[0].code}>{errors[0].message}</li>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        myFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [myFiles]);

    return (
        <>
            <Loader loaded={loaded}></Loader>
            <form onSubmit={submitForm}>
                <section>
                    <div className="photo-upload">
                        <div className="upload-btn">
                            <h1>Drag and drop photos here</h1>
                            <span>or</span>
                            <div className="">
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <button type="button" className="browes-btn drag-drop-btn">
                                        Browse Files
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <aside>
                        <ul>{thumbs}</ul>
                        <ul>{acceptedFileItems}</ul>
                        {/* <ul>{fileRejectionItems}</ul> */}
                        {(fileRejectionItems.length > 0) ?
                            <em className="text-danger">(maximum images allowed limit exceed or file you select may not be type of image)</em>
                            : ""
                        }
                    </aside>
                </section>
                <div className="post-btn">
                    <button type="submit" className="btn btn-secondary">Post Photos</button>
                </div>
            </form>
            <ToastContainer />
        </>
    )
}


export default ReviewsPhotoUploader;