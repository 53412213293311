import React, { Component, useState } from 'react'
import Logo from "../common/Logo";
import { RiSearchLine } from 'react-icons/ri'
import logosrc from '../../assets/images/katika_landing_logo.png'
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { algoliaAppId, ApiUrl, algoliaAppKeyId } from '../../config';
import { RiBuilding4Line, RiPlaneLine, RiHotelBedLine } from 'react-icons/ri'
import { GiChickenOven } from 'react-icons/gi'
import { IoMdFitness } from 'react-icons/io'
import PlacesAutocomplete from "./PlacesAutocomplete";
import { Link } from "react-router-dom";
import { BsEye, BsMusicNoteBeamed } from 'react-icons/bs'
import { getGeoInfo } from '../../useToken';
import Autosuggest from 'react-autosuggest';
import Autosuggest1 from "./AutoSuggest";

import algoliasearch from "algoliasearch";

const getSuggestionValue = (suggestion) => (suggestion.business_name) ? suggestion.business_name : suggestion.name
const renderSuggestion = (suggestion, { query }) => <div className='bg-search-box'>{(suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)} {query.length > 0 && ( <span className='bg-search'>{query}</span> )}</div>;

export default class BannerOneSearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedUp: false,
            isBusiness: false,
            location: '',
            location2: '',
            search: 'All',
            category: '',
            lat: '',
            lng: '',
            showSuggest: false,
            categories: [],
            value: '',
            suggestions: [],
            businessSlug: '',
        }
        this.handler = this.handler.bind(this)
        this.Chandler = this.Chandler.bind(this)
    }

    onChange = (event, { newValue, method }) => {
        this.setState({ value: newValue, search: newValue });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
        const index = client.initIndex('katika_business');
        // Search for a first name
        index.search(value, {
            numericFilters: `is_active = 1,complete_percent > 69`,
            hitsPerPage: 10,
        }).then(({ hits }) => {
            this.setState({ suggestions: hits })
        });
        // fetch(`${ApiUrl}searchBusinessSuggestion?search=${value}`)
        //     .then(response => response.json())
        //     .then(data => this.setState({ suggestions: data.lists }))
    }

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        var self = this;
        if (suggestion.business_id) {
            var str = suggestion.business_slug + ' ' + suggestion.business_state;
            str = str.replace(/\s+/g, '-').toLowerCase();
            self.setState({ businessSlug: str, isBusiness: true });

        } else {
            self.setState({ isSignedUp: true });

        }
    }

    shouldRenderSuggestions = () => {
        return true;
    }

    Chandler(e) {
        // console.log(e)
        let location1 = JSON.parse(localStorage.getItem('location'));
        this.setState(
            {
                location2: e.location,
                location: location1.city + ', ' + location1.region,
                lat: location1.latitude,
                lng: location1.longitude,
            }
        );
    }


    handler(e) {
        let city = '';
        let region = '';
        let country = '';
        e.allloction.address_components.map(adre => {
            if (adre.types[0] == 'locality' && adre.types[1] == 'political') {
                city = adre.short_name;
            }
            if (adre.types[0] == 'administrative_area_level_1' && adre.types[1] == 'political') {
                region = adre.short_name;
            }

            if (adre.types[0] == 'country' && adre.types[1] == 'political') {
                country = adre.short_name;
            }
            let latitude = e.allloction.geometry.location.lat();
            let longitude = e.allloction.geometry.location.lng();
            var data = {
                'city': city,
                'region': region,
                'country': country,
                'country_name': '',
                'latitude': latitude,
                'longitude': longitude,
            }
            localStorage.setItem('location', JSON.stringify(data));
            this.setState(
                {
                    location: city + ', ' + region,
                    lat: e.allloction.geometry.location.lat(),
                    lng: e.allloction.geometry.location.lng(),
                    showSuggest: false
                }
            );
        })
    }


    handleSearch = (e) => {
        this.setState(
            { search: e.target.value }
        );
    }

    handlelocation = (e) => {
        this.setState(
            {
                location: e.target.value,
            }
        );
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ isSignedUp: true });
    }

    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));
        // console.log(loc, 'sasas')
        if (!loc) {
            if (getGeoInfo() == undefined) {
                this.setState(
                    {
                        location: 'Philadelphia, PA',
                        location2: 'Philadelphia, PA',
                        lat: 39.9525839,
                        lng: -75.1652215,
                        showSuggest: true,

                    }
                );

            } else {
                this.setState(
                    {
                        location: getGeoInfo(),
                        location2: getGeoInfo(),
                        lat: loc.latitude,
                        lng: loc.longitude,
                        showSuggest: true,
                    }
                );
            }

        } else {
            let string = loc.city + ', ' + loc.region;
            this.setState(
                {
                    location: string,
                    location2: string,
                    lat: loc.latitude,
                    lng: loc.longitude,
                    showSuggest: true,
                }
            );
        }

        // console.log(this.state, 'a')

        this.getCategory();

    }

    getCategory = () => {
        axios.get(ApiUrl + 'getHomePageCategoriesList').then((response) => {
            this.setState(
                { categories: response.data.top_lists }
            );
        }).catch((error) => {
            console.log(error);
        });
    }


    // handleSearchAlgolia = (e) => {
    //     const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
    //     const index = client.initIndex('katika_restaurant');

    //     // Search for a first name
    //     index.search(e.target.value).then(({ hits }) => {
    //         console.log(hits);
    //     });

    // }

    render() {


        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'jerk chicken, barbers, and brunch...',
            value,
            type: "text",
            onChange: this.onChange
        };

        if (this.state.isSignedUp) {
            // redirect to home if signed up

            let location_a = this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/");
            return <Redirect to={{
                pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location_a}`,
                state: {
                    search: this.state.search,
                    location: this.state.location,
                    category: this.state.category,
                    lat: this.state.lat,
                    lng: this.state.lng
                }
            }}
            />;
        }

        if (this.state.isBusiness) {
            // redirect to home if signed up
            return <Redirect to={{
                pathname: `/biz/${this.state.businessSlug}`
            }}
            />;
        }
        return (
            <>
                <div className="logo">
                    <Logo url={logosrc} />
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="main-search-input">
                        <div className="main-search-input-item">
                            <div className="contact-form-action">

                                <div className="form-group mb-0">
                                    <span className="form-icon">
                                        Find
                                    </span>

                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                    />
                                    {/* <input onChange={e => this.handleSearchAlgolia(e)} className="form-control c1" type="text"
                                        placeholder=" jerk chicken, barbers, and brunch..." /> */}
                                </div>

                            </div>
                        </div>
                        <span className="HBorder"></span>

                        <div className="main-search-input-item">
                            <div className="contact-form-action">
                                {/* {!this.state.showSuggest ?
                                    <div className="form-group mb-0">
                                        <span className="form-icon">
                                            Near
                                    </span>
                                        <input value={this.state.location} onClick={e => this.setState({ showSuggest: true })} onChange={e => this.handlelocation(e)} className="form-control c1 near-feild" type="text"
                                            placeholder="City name" />
                                    </div>
                                    : */}
                                <div className="form-group mb-0">
                                    <span className="form-icon">
                                        Near
                                    </span>
                                    {this.state.showSuggest ? <Autosuggest1 location2={this.state.location2} Chandler={this.Chandler} /> : ""}
                                    {/* <PlacesAutocomplete location={this.state.location} handler={this.handler} /> */}
                                </div>
                                {/* } */}

                            </div>
                        </div>

                        <div className="main-search-input-btn">
                            <button className="button theme-btn1" type="submit"><RiSearchLine /></button>
                        </div>
                    </div>
                </form>

                <div className="highlighted-categories">
                    <div className="highlight-lists d-flex">
                        {this.state.categories.map((list, index) => {
                            return (
                                <div className="category-item radius-rounded" key={index}>
                                    {(list.parent_id != 0) ?
                                        <Link

                                            to={{
                                                pathname: `/listing/${list.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                state: {
                                                    search: list.name,
                                                    location: this.state.location,
                                                    lat: this.state.lat,
                                                    lng: this.state.lng,
                                                    category_id: list.parent_id,
                                                    category: list.name,
                                                    sub_category_id: list.id
                                                }
                                            }}
                                            className="d-block">
                                            <span className="icon-element">
                                                <img className="img-thumbnail" src={list.icon} alt="" />
                                            </span>
                                            {/* <span className="icon-element"> {list.icon}</span> */}
                                            {list.name}
                                        </Link>
                                        :
                                        <Link

                                            to={{
                                                pathname: `/listing/${list.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                state: {
                                                    search: list.name,
                                                    location: this.state.location,
                                                    lat: this.state.lat,
                                                    lng: this.state.lng,
                                                    category_id: list.id,
                                                    category: list.name,
                                                }
                                            }}
                                            className="d-block">
                                            <span className="icon-element">
                                                <img className="img-thumbnail" src={list.icon} alt="" />
                                            </span>
                                            {/* <span className="icon-element"> {list.icon}</span> */}
                                            {list.name}
                                        </Link>
                                    }

                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
}
