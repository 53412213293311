import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { RiCloseLine } from 'react-icons/ri'
import { Row, Col } from "react-bootstrap";
import img1 from '../../assets/images/l-1.jpg';
import img2 from '../../assets/images/l-2.jpg';
import img3 from '../../assets/images/l-3.jpg';
import img4 from '../../assets/images/l-4.jpg';
import img5 from '../../assets/images/l-5.jpg';
import img6 from '../../assets/images/l-6.jpg';



import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

class ListingDetailsGallery extends React.Component {
    constructor() {
        super()
        this.state = {
            value: this.props ? this.props.image_click : 0,
            images: [],
            thumbnailsImg: [],
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        console.log(value, 'value')
        this.setState({ value });
    }

    componentWillReceiveProps(nextProps) {
        const imagess = [];
        nextProps.images.map((slide, i) => {
            imagess.push(<img key={i + 1} src={slide.image} alt="Gallery" />,)
        });

        if (imagess) {
            this.setState({
                value: nextProps.image_click,
                images: nextProps.images,
                thumbnailsImg: imagess
            });
        }

        console.log(nextProps, 'nextProps')
        // this.onImage();
    }

    

    render() {

        return (
            <div>
                <input
                    type="hidden"
                    value={this.props.image_click ? this.props.image_click : this.state.value}
                    onChange={e => this.onChange(parseInt(e.target.value || 0))}
                />

                <Row>
                    <Col lg={8} md={8}>
                        <div>
                            <Carousel
                                arrows
                                value={this.state.value}
                                onChange={this.onChange}
                            >
                                {this.state.images.map((slide, i) => {
                                    return (
                                        <img key={i} className="img-example" src={slide.image} alt="Gallery" />
                                    )
                                })}
                            </Carousel>
                        </div>
                    </Col>
                    <Col lg={4} md={4}>
                        <div className="" >
                            <div className="slider-content">
                                <h3>Booker's Restaurant</h3>
                                <div className="photo-count">
                                    2 of 8<Link to="/"> See all photos</Link>
                                </div>
                                <div className="last-updated">
                                    from <Link to="/">Booker's Restaurant Windows</Link> on January 14, 2021
                                    </div>
                            </div>
                            <div className="btn-box">
                                <button type="button" className="theme-btn border-0 button-success mr-1" data-dismiss="modal">
                                    <RiCloseLine />
                                </button>
                            </div>
                            <div className="slider-dots">
                                <Dots
                                    value={this.state.value}
                                    onChange={this.onChange}
                                    thumbnails={this.state.thumbnailsImg}
                                />

                                <div className="see-all-btn">
                                    <Link to="/">See All Photos</Link>
                                </div>
                            </div>
                        </div>
                    </Col>



                </Row>

            </div>
        );
    }
}
function ListingDetailsGallerysas(props) {


    return (
        <>

            <Row>
                <Col lg={8} md={8}>
                    <Carousel
                        arrows
                        plugins={[
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 2
                                }
                            },
                        ]}
                    >
                        <img src={img1} />
                        <img src={img2} />
                        <img src={img3} />
                    </Carousel>
                </Col>
                <Col lg={4} md={4}>
                    <div className="gallery-carousel-dots" >
                        <div className="slider-content">
                            <h3>Booker's Restaurant</h3>
                            <div className="photo-count">
                                2 of 8<Link to="/"> See all photos</Link>
                            </div>
                            <div className="last-updated">

                                from <Link to="/">Booker's Restaurant Windows</Link> on January 14, 2021
                    </div>
                        </div>
                        <div className="btn-box"><button type="button" className="theme-btn border-0 button-success mr-1" data-dismiss="modal">
                            <RiCloseLine />
                        </button>
                        </div>
                        <div className="dots-slider">


                            <div className="see-all-btn">
                                <Link to="/">See All Photos</Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ListingDetailsGallery;