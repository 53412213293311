import React, { Component } from 'react'
import katikaworldmap from '../../assets/images/katika-world-map.png'
import Button from "../common/Button";



export default class Connection extends Component {
    render() {
        return (
            <>
                <div className="container connection">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>
                                Connecting everyone to black business & culture
                            </h1>
                            <p> Katika is a global directory, marketplace and events platform that provides
                            our community with a central location to find, shop and experience black
                                 business and culture </p>

                            <div className="btn-box padding-top-30px">
                                <Button className="mb_5 radius-rounded" text="Learn more" url="/about" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="map-image">
                                <img height="290" src={katikaworldmap} alt="katikaworldmap" />
                            </div>
                        </div>
                    </div>
                </div> 
            
            </>
        )
    }
}
