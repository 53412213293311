import React, { Component } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import NavbarListing from "../../components/common/NavbarListing";
import Footer from "../../components/common/footer/Footer";
import { Link, Redirect } from "react-router-dom";
import { ApiUrl } from '../../config';
import axios from 'axios';
import { getGeoInfo, uToken } from '../../useToken';


export default class CategoriesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            externalData: false,
            location: 'Philadelphia, PA',
            lat: 39.9525839,
            lng: -75.1652215,
        }
        this.handler = this.handler.bind(this)
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }


    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));
        if (!loc) {
            if (getGeoInfo() == undefined) {
                this.setState(
                    {
                        location: 'Philadelphia, PA',
                        lat: 39.9525839,
                        lng: -75.1652215,

                    }
                );

            } else {
                this.setState(
                    {
                        location: getGeoInfo(),
                        lat: loc.latitude,
                        lng: loc.longitude,
                    }
                );
            }

        } else {
            let string = loc.city + ', ' + loc.region;
            this.setState(
                {
                    location: string,
                    lat: loc.latitude,
                    lng: loc.longitude,
                }
            );
        }
        this.getAllCategoriesList();
    }




    getAllCategoriesList = (count) => {
        var self = this;
        self.setState({
            externalData: false,
        })
        // console.log(data)
        axios.get(ApiUrl + `getAllCategoriesList`, {},
            {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {

                    self.setState({
                        externalData: true,
                        categories: res.data.lists,
                    })



                } else {
                    self.setState({
                        externalData: true,
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <>

                <div className="cities recent BuyerPhotos categories ">
                    <NavbarListing handler={this.handler} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-lg-2 col-sm-12"></div>
                            <div className="col-md-12 col-lg-10">
                                <div className="all-categories">
                                    <h3 className="categories-head">All Black Business Industries and Categories</h3>
                                    <ul className="comparison-card-list">
                                        {
                                            this.state.categories.map((c, i) => {

                                                return (

                                                    <>
                                                        <li key={i} className="parent">
                                                            <Link
                                                                to={{
                                                                    pathname: `/listing/${c.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                                    // pathname: `/listing/${(c.name) ? c.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : ""}`,
                                                                    state: {
                                                                        search: c.name,
                                                                        location: this.state.location,
                                                                        lat: this.state.lat,
                                                                        lng: this.state.lng,
                                                                        category_id: c.id,
                                                                        category: c.name,
                                                                    }
                                                                }}
                                                            >
                                                                {c.name}
                                                            </Link>
                                                        </li>
                                                        {
                                                            c.sub_category.map((sub, k) => {
                                                                return (
                                                                    <li key={k + '-' + i} className="child"> <Link
                                                                        to={{
                                                                            pathname: `/listing/${sub.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                                            // pathname: `/listing/${(sub.name) ? sub.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : ""}`,
                                                                            state: {
                                                                                search: sub.name,
                                                                                location: this.state.location,
                                                                                lat: this.state.lat,
                                                                                lng: this.state.lng,
                                                                                category_id: sub.parent_id,
                                                                                category: sub.name,
                                                                                sub_category_id: sub.id
                                                                            }
                                                                        }}
                                                                    >
                                                                        {sub.name}
                                                                    </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </>

                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="margin-top-20px">
                    {/* Footer */}
                    <Footer />
                </div>
            </>
        )
    }
}
