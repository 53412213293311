import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import as1st from '../../assets/images/1st.jpg';
import as2nd from '../../assets/images/2nd.jpg';
import as3rd from '../../assets/images/3rd.jpg';
import AboutBox from '../../assets/images/AboutBox.jpg';
import { Helmet } from "react-helmet";




const Business = () => {
    return (
        <main className="BusinessHome AboutUs">
            <Helmet>
                <title>{`About`}</title>
                <meta name="title" content={`About`} />
                <meta name="description"
                    content={`Create and share experiences while discovering black businesses in your community.`} />
                <meta property="og:title" content={`About`} />
                <meta property="og:description"
                    content={`Create and share experiences while discovering black businesses in your community.`} />
            </Helmet>
            {/* Header */}
            <HomePageHeader />
            <div className='d-none d-lg-block d-xl-block'>
                <div className="CommonBreadcrumb">
                    <h1>A tech platform for<br></br> the culture.</h1>
                    {/* <p>No filter or labels required</p> */}
                </div>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="PressPage padding-top-60px padding-bottom-90px">
                                <h2>Our Mission</h2>
                                <p>To accelerate equality for the black community by connecting black businesses with conscious consumers.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <section className="testimonial-area padding-top-0px padding-bottom-60px text-center">
                    <div className="container">
                        <div className="row section-title-width text-center">
                            <div className="Rectangle Video">
                                <Row>
                                    <Col sm={6} lg={6}>
                                        <div className="VideoContent">
                                            <h1><span>Katika</span> was created for conscious consumers to connect to black businesses.</h1>
                                            <p className='mt-5'>Katika was created to provide an easy way for conscious consumers to connect to local black businesses through an app.  Building upon the legacy of the Green Book, our aim is to create a platform that faciliates commerce, community and culture for the black community - three essential pillars of a thriving economy.  By leveraging technology, our goal is to help circulate more dollars within the black community, creating economic equality & prosperity.</p>
                                        </div>
                                    </Col>
                                    <Col sm={6} lg={6}>
                                        <div className="VideoBox">
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="WhatConnectUs padding-bottom-100px">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="ConnectHeading padding-bottom-100px">
                                    <h1>OUR 3 PILLARS</h1>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="partners">
                                    <h4>COMMERCE</h4>
                                    <p>We empower shoppers to actively patron and transact with local black businesses using our platform. </p>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="partners">
                                    <h4>COMMUNITY</h4>
                                    <p>Katika provides a space for sharing common goals, attitudes, and interests in order to collectively advance the black community.</p>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="partners">
                                    <h4>CULTURE</h4>
                                    <p> Black culture encompasses backgrounds of Afro-Latinos, Afro-Carribeans, African-Americans, and Africans.  We celebrate this rich and diverse history by making it easy to connect conscious consumers to all black businesses.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="testimonial-area padding-top-100px text-center">
                    <div className="container">
                        <div className="row section-title-width text-center">
                            <div className="BHeading padding-bottom-40px">
                                <h2>THE TEAM</h2>
                                {/* <p>To accelerate equality for the black community by connecting black-owned businesses with conscious consumers.</p> */}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container border-bottom">
                    <Col lg={6} className="offset-lg-3">
                        <section className="TeamItem about-team padding-bottom-100px d-flex">
                            <div className="Items">
                                <img src={as1st} alt="3rd" />
                            </div>
                            <div className="Items text-start color-black">
                                <p>Katika is the creation of Jason M. Coles and is passionately run and operated by a team of dedicated Philadelphia community advocates.</p>
                            </div>
                            {/*<div className="Items">
                            <img src={as3rd} alt="3rd" />
                        </div> */}
                        </section>
                    </Col>
                </div>
            </div>
            <div className='d-block d-sm-none'>
                <div className="MobileAbout">
                    <div className="CommonBreadcrumb">
                        <h1>About Us</h1>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="PressPage padding-top-60px padding-bottom-90px">
                                    <h2>Our Mission</h2>
                                    <p>To accelerate equity for the black community by educating and promoting black businesses.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <section className="testimonial-area padding-top-0px padding-bottom-60px text-center">
                        <div className="container">
                            <div className="row section-title-width text-center">

                                <div className="Rectangle Video">
                                    <Row>
                                        <Col sm={6} lg={6}>
                                            <div className="VideoContent">
                                                <h1><span>Katika</span> was created for conscious consumers to connect to black businesses.</h1>
                                                <p>Katika was created to provide an easy way for conscious consumers to connect to local black businesses through an app.  Building upon the legacy of the Green Book, our aim is to create a platform that facilitates commerce, community and culture for the black community - three essential pillars of a thriving economy.  By leveraging technology, our goal is to help circulate more dollars within the black community, creating economic equality & prosperity.</p>
                                            </div>
                                        </Col>
                                        <Col sm={6} lg={6}>
                                            <div className="VideoBox">
                                                <img src={AboutBox} alt="AboutBox" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="WhatConnectUs padding-bottom-100px">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <div className="ConnectHeading padding-bottom-100px">
                                        <h1>OUR 3 PILLARS</h1>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="partners">
                                        <h4>COMMERCE</h4>
                                        <p>We empower shoppers to actively patron and transact with local black businesses using our platform.</p>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="partners">
                                        <h4>COMMUNITY</h4>
                                        <p>Katika provides a space for sharing common goals, attitudes, and interests in order to create a feeling of togetherness and fellowship.</p>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="partners">
                                        <h4>CULTURE</h4>
                                        <p>Black culture encompasses backgrounds of Afro-Latinos, Afro-Carribeans, Africans-Americans, and Africans.  We celebrate this rich and diverse history by making it easy to connect conscious consumers to black businesses.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="testimonial-area padding-top-100px mt-5 text-center">
                        <div className="container">
                            <div className="row section-title-width text-center">
                                <div className="BHeading mt-5">
                                    <h2>THE TEAM</h2>
                                    {/* <p>To accelerate equality for the black community by connecting black-owned businesses with conscious consumers.</p> */}
                                </div>
                            </div>

                        </div>

                    </section>
                    <div className="container">
                        <section className="TeamItem about-team padding-bottom-100px">

                            <div className="Items">
                                <img src={as1st} alt="3rd" />
                            </div>
                            <div className='text-left mb-5'>
                                <p className='color-black'>Katika is the creation of Jason M. Coles and is passionately run and operated by a team of dedicated Philadelphia community advocates.</p>
                            </div>
                            {/* <div className="Items">
                                <img src={as2nd} alt="2nd" />
                            </div>
                            <div className="Items">
                                <img src={as3rd} alt="3rd" />
                            </div> */}


                        </section>
                    </div>
                </div>
            </div>

            <Footer />

            <ScrollTopBtn />
        </main >
    )
}

export default Business;