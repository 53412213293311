import React, { Component } from 'react';
import HomePageHeader from '../../components/business/common/HomePageHeader'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import AccordionListGeneral from "../../components/business/AccordionListGeneral";
import AccordionListOwners from "../../components/business/AccordionListOwners";
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegEnvelope } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import { RiSendPlane2Line } from 'react-icons/ri'
import { FiPhone } from 'react-icons/fi'
import { GoLocation } from 'react-icons/go'
import { FaTwitter, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { ApiUrl, AutoApiKey } from "../../config"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

class GetHelp extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loader: true
        }
    }

    handleSubmit(event, values) {


        console.log('hi,')

        var self = this;
        axios.post(ApiUrl + 'getHelp', values, {
            headers: {
                'Authorization': `Bearer`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        loader: false
                    })
                    setTimeout(() => {
                        self.setState({
                            loader: true
                        })
                    }, 200);
                } else {

                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });




    }
    render() {
        return (
            <main className="BusinessHome FaqPages GetHelp">
                {/* Header */}
                <HomePageHeader />
                <div className='d-none d-lg-block d-xl-block'>
                    <div className="CommonBreadcrumb">
                        <h1 className='text-white'>WE’RE HERE TO HELP</h1>
                    </div>
                    <Container>
                        <ToastContainer />
                        <Row>
                            <Col sm={12}>
                                <div className="PressPage padding-top-60px">
                                    <h2>Support on Demand</h2>
                                    <p>Our team is here to provide whatever support  you need or to answer any question you may have.</p>
                                </div>
                            </Col>
                            <Col sm={{ span: 10, offset: 1 }}>
                                <div className="contact-help">
                                    <div className="padding-top-40px padding-bottom-40px heading">
                                        <h3 className="widget-title text-center">Get In Touch</h3>
                                    </div>
                                    <section className="contact-area padding-bottom-80px help">
                                        <div className="container">
                                            <h2 className="send-message"> Send us a message</h2>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <AvForm onValidSubmit={this.handleSubmit} ref={(el) => this.form = el} className="form-help padding-bottom-40px">
                                                        {
                                                            this.state.loader && (
                                                                <>
                                                                    <div className="input-box">
                                                                        <AvGroup>
                                                                            <AvField
                                                                                type="select"
                                                                                name="help_with"
                                                                                placeholder='I need help with:'
                                                                                className="help_reason"
                                                                                required
                                                                            >
                                                                                <option disabled value="">I need help with:</option>
                                                                                <option value="I need help creating my Katika for Business account">I need help creating my Katika for Business account</option>
                                                                                <option value="I never received my Verification email">I never received my Verification email</option>
                                                                                <option value="I’m having trouble with my Katika account">I’m having trouble with my Katika account</option>
                                                                                <option value="I’d like to report an issue">I’d like to report an issue</option>
                                                                                <option value="I want create a Partnership">I want create a Partnership</option>
                                                                                <option value="Claim my page">Claim my page</option>                                                                                
                                                                                <option value="General Question">General Question</option>
                                                                            </AvField>
                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                        </AvGroup>
                                                                    </div>

                                                                    <div className="input-box">
                                                                        <AvGroup>
                                                                            <AvInput
                                                                                className="form-control"
                                                                                placeholder="First & Last Name "
                                                                                name="name"
                                                                                required />
                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                        </AvGroup>
                                                                    </div>
                                                                    <div className="input-box">
                                                                        <AvGroup>
                                                                            <AvInput
                                                                                className="form-control"
                                                                                placeholder="Email Address"
                                                                                type="email"
                                                                                name="email"
                                                                                required />
                                                                            <AvFeedback> Required Field *</AvFeedback>
                                                                        </AvGroup>
                                                                    </div>
                                                                    <div className="input-box">
                                                                        <div className="form-group">
                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    className="message-control form-control"
                                                                                    placeholder="Your Message"
                                                                                    type="textarea"
                                                                                    name="message"
                                                                                    rows={5}
                                                                                    required />
                                                                                <AvFeedback> Required Field *</AvFeedback>
                                                                            </AvGroup>

                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        <div className="btn-box">
                                                            <button className="theme-btn border-0 help-submit">
                                                                Send
                                                            </button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="help-address-box">
                                                        <ul className="help-map-address">
                                                            <li>
                                                                <span className="la"><GoLocation /></span>
                                                                <p className="map__desc">
                                                                    3401 Market Street <br />Philadelphia, PA 19104
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <span className="la"><FiPhone /></span>
                                                                <p className="map__desc">(267) 281-5747</p>
                                                            </li>
                                                            <li>
                                                                <span className="la"><FaRegEnvelope /></span>
                                                                <p className="map__desc">support@katika.us</p>
                                                            </li>
                                                        </ul>
                                                        <div className="copyright-left-side help">
                                                            <ul className="list-items SocialIcons">

                                                                <li><a href="https://www.youtube.com/channel/UC6ML8hqMCowM6yMXZt8CmuQ" target="_blank"><FaYoutube /></a> </li>
                                                                <li><a href="https://www.instagram.com/shopkatika/" target="_blank"><FaInstagram /></a> </li>
                                                                <li><a href="https://www.facebook.com/shopkatika/" target="_blank"><FaFacebookF /></a> </li>
                                                                <li><a href="https://twitter.com/shopkatika/" target="_blank"><FaTwitter /></a> </li>
                                                            </ul>
                                                        </div>

                                                        <div className="contact-map">
                                                            <iframe
                                                                width="100%"
                                                                height="150"
                                                                loading="lazy"
                                                                allowfullscreen
                                                                src={`https://www.google.com/maps/embed/v1/place?key=${AutoApiKey}&q=3401%20Market%20Street%20Philadelphia,%20PA%2019104`}>
                                                            </iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>


                <div className='d-block d-sm-none'>
                    <div className='MobileHelp'>
                        <div className="CommonBreadcrumb">
                            <h1 className='text-white'>WE’RE HERE TO HELP</h1>
                        </div>
                        <Container>
                            <ToastContainer />
                            <Row>
                                <Col sm={12}>
                                    <div className="PressPage padding-top-60px">
                                        <h2>Support on Demand</h2>
                                        <p>Our team is here to provide whatever support  you need or to answer any question you may have.</p>
                                    </div>
                                </Col>
                                <Col sm={{ span: 10, offset: 1 }}>
                                    <div className="contact-help margin-top-70px">
                                        {/* <div className="padding-top-40px padding-bottom-40px heading">
                                            <h3 className="widget-title text-center">Get In Touch</h3>
                                        </div> */}
                                        <section className="contact-area padding-bottom-30px help padding-top-30px">
                                            <div className="container">
                                                <h2 className="send-message"> Send us a message</h2>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <AvForm onValidSubmit={this.handleSubmit} ref={c => (this.form = c)} className="form-help padding-bottom-20px">

                                                            <div className="input-box">
                                                                <AvGroup>
                                                                    <AvField
                                                                        type="select"
                                                                        name="help_with"
                                                                        placeholder='I need help with:'
                                                                        className="help_reason"
                                                                        required
                                                                    >
                                                                        <option disabled value="">I need help with</option>
                                                                        <option value="I need help creating my Katika for Business account">I need help creating my Katika for Business account</option>
                                                                        <option value="I never received my Verification email">I never received my Verification email</option>
                                                                        <option value="I’m having trouble with my Katika account">I’m having trouble with my Katika account</option>
                                                                        <option value="I’d like to report an issue">I’d like to report an issue</option>
                                                                        <option value="I want create a Partnership">I want create a Partnership</option>
                                                                        <option value="General Question">General Question</option>
                                                                    </AvField>
                                                                    <AvFeedback> Required Field *</AvFeedback>
                                                                </AvGroup>
                                                            </div>

                                                            <div className="input-box">
                                                                <AvGroup>
                                                                    <AvInput
                                                                        className="form-control"
                                                                        placeholder="First & Last Name "
                                                                        name="name"
                                                                        required />
                                                                    <AvFeedback> Required Field *</AvFeedback>
                                                                </AvGroup>
                                                            </div>
                                                            <div className="input-box">
                                                                <AvGroup>
                                                                    <AvInput
                                                                        className="form-control"
                                                                        placeholder="Email Address"
                                                                        type="email"
                                                                        name="email"
                                                                        required />
                                                                    <AvFeedback> Required Field *</AvFeedback>
                                                                </AvGroup>
                                                            </div>
                                                            <div className="input-box">
                                                                <div className="form-group">
                                                                    <AvGroup>
                                                                        <AvInput
                                                                            className="message-control form-control"
                                                                            placeholder="Your Message"
                                                                            type="textarea"
                                                                            name="message"
                                                                            rows={5}
                                                                            required />
                                                                        <AvFeedback> Required Field *</AvFeedback>
                                                                    </AvGroup>

                                                                </div>
                                                            </div>
                                                            <div className="btn-box">
                                                                <button className="theme-btn border-0 help-submit">
                                                                    Send
                                                                </button>
                                                            </div>
                                                        </AvForm>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="help-address-box">
                                                            <ul className="help-map-address">
                                                                <li>
                                                                    <span className="la"><GoLocation /></span>
                                                                    <p className="map__desc">
                                                                        3401 Market Street <br />Philadelphia, PA 19104
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <span className="la"><FiPhone /></span>
                                                                    <p className="map__desc">(267) 281-5747</p>
                                                                </li>
                                                                <li>
                                                                    <span className="la"><FaRegEnvelope /></span>
                                                                    <p className="map__desc">support@katika.us</p>
                                                                </li>
                                                            </ul>
                                                            <div className="copyright-left-side help">
                                                                <ul className="list-items SocialIcons">

                                                                    <li><a href="https://www.youtube.com/channel/UC6ML8hqMCowM6yMXZt8CmuQ" target="_blank"><FaYoutube /></a> </li>
                                                                    <li><a href="https://www.instagram.com/shopkatika/" target="_blank"><FaInstagram /></a> </li>
                                                                    <li><a href="https://www.facebook.com/shopkatika/" target="_blank"><FaFacebookF /></a> </li>
                                                                    <li><a href="https://twitter.com/shopkatika/" target="_blank"><FaTwitter /></a> </li>
                                                                </ul>
                                                            </div>

                                                            <div className="contact-map">
                                                                <iframe
                                                                    width="100%"
                                                                    height="150"
                                                                    loading="lazy"
                                                                    allowfullscreen
                                                                    src={`https://www.google.com/maps/embed/v1/place?key=${AutoApiKey}&q=3401%20Market%20Street%20Philadelphia,%20PA%2019104`}>
                                                                </iframe>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='border-bottom margin-top-100px'></div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
                <ScrollTopBtn />
            </main>
        );
    }
}

export default GetHelp;