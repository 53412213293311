import React, { Component } from 'react';
import ImageSignUp from '../../assets/images/Group-2.png';
import { Row, Col } from "react-bootstrap";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignUpImg: ImageSignUp
        }
    }

    setPage = (page) => {
        this.props.getPage(page)
    }
    render() {
        return (

            <Row>

                <Col lg={12} md={12}>
                    <div className="about">
                        <h1>Where’s your business?</h1>
                    </div>
                </Col>

                <div className="col-lg-6 mx-auto">
                    <div className="signup-desc location">
                        <p> Do you have a <b>physical location </b>for customers to visit your business?</p>
                    </div>

                    <div className="bottom-btn">
                        <button className="btn btn-default" onClick={() => this.setPage('No')}>Yes</button>
                        <button className="btn btn-default ml-5" onClick={() => this.setPage('Yes')}>No </button>
                    </div>
                    <div className="signup-step-2">
                        <p>Whether you’re a home business, an Instagram creative, online business, or have a commercial space, Katika’s got you covered; our directory welcomes Black Businesses all across Philadelphia.  Let us know the option that best fits you.</p>
                    </div>
                </div>
                <div className="col-lg-6 mx-auto">
                    {/* <div className="signup-desc">
                        <p>To get started, begin by providing a little information about yourself and create your Katika login.</p>
                    </div> */}
                    <div className="sign-up-image"><img key={0} src={this.state.SignUpImg} alt="testimonial" className="random-img" /></div>
                </div>

            </Row>

        );
    }
}

export default SignUp;