import React, {Component} from 'react';

class BannerParticle extends Component {
    render() {
        return (
            <>
               
            </>
        );
    }
}

export default BannerParticle;