import React, { Component } from 'react';
import BuisnessLoginBox from "../components/other/account/BuisnessLoginBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg';
import { Link } from "react-router-dom";
import ImageSignUp from '../assets/images/katika_signup.png';
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/dashboard-logo.svg';

class BusinessLogin extends Component {
    state = {
        SignUpImg: ImageSignUp,
    }
    render() {
        const { setToken } = this.props;
        const { goFarward } = this.props;
        return (
            <main className="login-page b-login b">
                {/* Header */}

                <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-12">
                                <div className="logo listing text-center RemoveLogoWidth">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>


                <section className="form-shared login-page padding-top-40px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2 mx-auto">
                                <div className="login-top">
                                    <BuisnessLoginBox goFarward={goFarward} setToken={setToken} title="Log in to Katika" subtitle="New to Katika?" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Newsletter 
                <NewsLetter />*/}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default BusinessLogin;