import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import Footer from "../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';
import CheckIn from '../../assets/images/CheckIn.png';
import { FaTrashAlt } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';
import { BsPencil } from "react-icons/bs";
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
class BuyerLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            externalData: false,
            comments: [
                {
                    img: commentimg1,
                    name: 'Adam Smith',
                },
                {
                    img: commentimg1,
                    name: 'Matt Derry',

                }
            ]
        }
        this.handler = this.handler.bind(this);
    }


    componentDidMount() {
        this.getLocation();

    }

    getLocation = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getBuyerLocation', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    comments: res.data.lists,
                    externalData: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeLocation(id) {
        var self = this;
        axios.post(ApiUrl + 'deleteBuyerLocation', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getLocation();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />

                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent BuyerLocation">
                            <div className="y-page-link reviews ">
                                <div className="BuyerEmailList">
                                    <div className="BuyerEmail">
                                        <div className="BuyerPagesHeading">Your Saved Locations</div>
                                    </div>
                                    <div className="EmailAdd ">
                                        <Link to="/user-location-edit" >Add a new location</Link>
                                    </div>
                                </div>
                                {
                                    this.state.externalData ?

                                        <div className="reviews-tabs">
                                            <div className="form-area">
                                                <div className="tabs-content">
                                                    <ul className="comments-list">
                                                        {this.state.comments.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <li key={i}>
                                                                        <div className="comment listing-details">

                                                                            <div className="comment-body">
                                                                                <div className="meta-data">
                                                                                    <p> <b>{item.title}</b><span></span> {item.address}</p>
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                        <div className="DeleteReviews">

                                                                            <Link title="Edit" to={`/user-location-edit/${item.id}`} ><span><BsPencil /></span></Link>

                                                                            {item.is_primary == 1 ?
                                                                                <button title="You cannot delete your primary location" disabled ><span><FaTrashAlt /></span></button>
                                                                                :
                                                                                <button title="Delete" onClick={() => this.removeLocation(item.id)} ><span><FaTrashAlt /></span></button>
                                                                            }
                                                                        </div>

                                                                    </li>
                                                                </>

                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerLocation;