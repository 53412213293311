import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebar from "../../components/user/BuyerSidebar";
import UserInfo from "../../components/user/UserInfo";
import Footer from "../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';
import $ from 'jquery';
import StoryGallery from './StoryGallery';
import video from '../../assets/video/ssstiktok_1623214518.mp4';
import img4 from '../../assets/images/1.jpg';
import img5 from '../../assets/images/2.jpg';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';

class BuyerReviews extends Component {
    constructor(props) {
        super(props);

        // React ^16.3
        // this.storiesElement = React.createRef();

        this.storiesElement = null;
        this.storiesApi = null;

        this.state = {
            stories: [
                // {
                //     id: "Tony-1",               // story id
                //     profil_url: video,            // story photo (or user photo)
                //     profile_picture: "https://katika-images.s3.amazonaws.com/category/Ellipse%20111622116427.png",
                //     name: "Tony Montana",             // story name (or user name)
                //     link: "https://ramon.codes",             // story link (useless on story generated by script)
                //     lastUpdated: "3 min ago",      // last updated date in unix time format
                //     seen: false,
                //     view: "2.3k",
                //     type: "video"

                // },
                // {
                //     id: "Tom-1",               // story id
                //     profil_url: img4,           // story photo (or user photo)
                //     profile_picture: "https://katika-images.s3.amazonaws.com/category/Ellipse%20111622116427.png",
                //     name: "Tom Catter",             // story name (or user name)
                //     link: "https://ramon.codes",             // story link (useless on story generated by script)
                //     lastUpdated: "3 min ago",      // last updated date in unix time format
                //     seen: false,
                //     type: "image",
                //     view: "2.3k",
                // },
                // {
                //     id: "Jerry-1",               // story id
                //     profil_url: img5,            // story photo (or user photo)
                //     profile_picture: "https://katika-images.s3.amazonaws.com/category/Ellipse%20111622116427.png",
                //     name: "Jerry Mousier",             // story name (or user name)
                //     link: "https://ramon.codes",             // story link (useless on story generated by script)
                //     lastUpdated: "3 min ago",      // last updated date in unix time format
                //     seen: false,
                //     type: "image",
                //     view: "2.3k",
                // }
            ]
        }
        this.handler = this.handler.bind(this);

    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    componentDidMount() {
        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })

        this.getUserStories();
    }


    getUserStories = (count) => {
        var self = this;
        self.setState({
            externalData: false,
        })
        // console.log(data)
        axios.post(ApiUrl + `getUserStories`, {},
            {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
            .then(function (res) {
                console.log(res.data.data)
                if (res.data.success == true) {

                    self.setState({
                        externalData: true,
                        stories: res.data.data,
                    })



                } else {
                    self.setState({
                        externalData: true,
                    })

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BuyerStory">

                <NavbarListing handler={this.handler} />
                <div className="user-info">
                    <UserInfo />
                </div>

                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebar />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <h4>My Stories</h4>
                                <div className="stories-landing-user ">
                                    <div className="container">
                                        <div className="row">
                                            <h2>Video</h2>
                                        </div>
                                        <div className="reviews-tabs buyer-story ">
                                            <div className="row">

                                                {this.state.stories.map((story, storyId) => {

                                                    return (

                                                        <div className={'story  custom-story col-md-4'} >
                                                            <Link className="item-link"
                                                                to={{
                                                                    pathname: `/view-story`,
                                                                    state: {
                                                                        current: story,
                                                                        image_click: storyId,
                                                                        images: this.state.stories
                                                                    }
                                                                }}
                                                            >
                                                                {/* <span className="user-preview">
                                                                    <img src={story.profile_picture} />
                                                                </span> */}
                                                                <span className="item-preview">
                                                                    <img src={story.preview} />

                                                                </span>
                                                                <span className="info user">
                                                                    <img width="20" height="20" src="https://katika-images.s3.us-east-2.amazonaws.com/category/Heart%20Icon1632822639.png" />
                                                                    <strong className="name pl-10" itemProp="name">{story.stories[0].total_like}</strong>
                                                                    {/* <span className="time">{story.lastUpdated}</span> */}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}

                                            </div>

                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </Container>
                <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content p-4">
                                <div className="modal-top border-0 mb-4 p-0">
                                    <div className="alert-content">
                                        {/* {this.state.click ? <StoryGallery current={this.state.currentImg} business={this.state.business} images={this.state.stories} image_click={this.state.image_click} /> : ""} */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );



    }

}
export default BuyerReviews;