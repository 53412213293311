import React, { Component } from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import NavbarListing from '../../components/common/NavbarListing'
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { Container, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'
import { AiFillCamera } from 'react-icons/ai'
import ReviewsPhotoUploader from "../../components/user/ReviewsPhotoUploader";
import { RiSearchLine } from 'react-icons/ri'
import Rater from 'react-rater'
import { ApiUrl } from '../../config';
import axios from 'axios';
import Scrollbar from 'smooth-scrollbar-react';
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';


class ListingGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            location: '',
            externalData: false,
            business_id: '',
            business_name: '',
            reviews: [],
        }
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        this.getBusinessReview();
    }

    close(message) {
        if (message) {
            this.setState({
                externalData: false,
            })
            this.getBusinessReview();
        }
    }

    getBusinessReview = () => {
        var self = this;
        const { id } = this.props.match.params;
        axios.post(ApiUrl + 'getBusinessReviews', { business_slug: id }, {})
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        externalData: true,
                        business_name: res.data.business_name,
                        business_id: res.data.business_id,
                        reviews: res.data.reviews,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="ReviwsPage">
                <div className="navbars">
                    {/* Header */}
                    <NavbarListing state={this.props.location ? this.props.location.state : ""} handler={this.handler} />
                </div>
                <Container>
                    <Row>
                        {(this.state.externalData) ?
                            <>
                                <Col lg={1} md={1}></Col>
                                <Col lg={7} md={7}>
                                    <div className="ReviwsPageHeading margin-top-20px">
                                        <h1>{this.state.business_name}</h1>
                                        <div className="guidelines"><a href="#">Read our review guidelines</a></div>
                                    </div>
                                    <ReviewsPhotoUploader closeModal={this.close.bind(this)} business_id={this.state.business_id} />

                                </Col>
                                <Col lg={1} md={1}></Col>
                                <Col lg={3} md={3}>
                                    <Scrollbar alwaysShowTracks={true}>
                                        <div className="reviews-sidebar" style={{ maxHeight: '700px' }}>
                                            <h2>Recent Reviews</h2>
                                            <ul>
                                                {this.state.reviews.map((review, i) => {
                                                    return (
                                                        <>
                                                            <li key={i}>
                                                                <div className="recent">
                                                                    <div >
                                                                        <img src={review.image} className="reviws-user" alt="" ></img>
                                                                    </div>
                                                                    <div className="review-user">
                                                                        <h4>{review.fname} {review.lname}</h4>
                                                                        <ul className="UserSocialInfo">
                                                                            <li><FaUserFriends /> <strong>{review.friends}</strong></li>
                                                                            <li><span><GoStar /></span> <strong>{review.total_user_reviews}</strong></li>
                                                                            <li><AiFillCamera /> <strong>{review.total_user_images}</strong></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="rating-rating rating-detail landing">

                                                                    <Rating
                                                                        placeholderRating={review.rating}
                                                                        readonly={true}
                                                                        stop={5}
                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                    />
                                                                    {/* <Rater total={5} rating={review.rating} interactive={false} /> */}
                                                                    <span className="rating-count">{review.date}</span>
                                                                </div>
                                                                <p>{review.description}</p>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Scrollbar>
                                </Col>
                            </>
                            : ""}



                    </Row>
                </Container>




                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default ListingGallery;