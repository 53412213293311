import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import img1 from '../../assets/images/img32.jpg';
import img2 from '../../assets/images/img32.jpg';
import img3 from '../../assets/images/img30.jpg';
import BLogo from '../../assets/images/img30.jpg';
import { Container, Row, Col } from "react-bootstrap";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import "rc-tabs/assets/index.css";
import $ from 'jquery';
import PhotoGallery from './PhotoGallery';

const defaultTabKey = "0";

const PanelContent = ({ children }) => (
    <div className="row">{children}</div>
);

class GalleryPics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            start: 0,
            tabKey: defaultTabKey,
            tabs: this.props.images,
            all_images: this.props.all_images,
            business: this.props.business,
            image_click: 0,
            currentImg: {},
            click: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.tick = this.tick.bind(this);
        this.toggleChildMenu = this.toggleChildMenu.bind(this);
    }

    toggleChildMenu(e) {
        this.setState({
            image_click: e,
            click: true,
            currentImg: this.state.all_images[e]
        });
        console.log(e, 'e4')
    }

    onChange(key) {
        this.setState({
            all_images: this.state.tabs[key].image,
        });
        console.log(`onChange ${key}`);
    }

    onTabClick(key) {
        console.log(`onTabClick ${key}`);
        this.setState({
            tabKey: key
        });
    }

    tick() {
        const { start } = this.state;

        this.setState({
            start: start + 10
        });
    }

    componentDidMount() {
        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })
    }

    render() {
        console.log(this.props ,'props1')
        const { start } = this.state;
        return (
            <>
                <div className="tabs-top">
                    <Tabs
                        defaultActiveKey={defaultTabKey}
                        renderTabBar={() => (
                            <ScrollableInkTabBar pageSize={2} onTabClick={this.onTabClick} />
                        )}
                        renderTabContent={() => <TabContent />}
                        onChange={this.onChange}
                    >
                        {this.state.tabs.map((tab, index) => {
                            return (

                                <TabPane tab={tab.title} key={index} id={'test' + index} >
                                    <PanelContent id={start}>
                                        {tab.image.map((item, index) => {
                                            return (
                                                <div className="col-lg-2 GalleryPics" key={index}>
                                                    <div className="card-item blog-card delete-account-info">
                                                        {/* <a href={item.image} target="_blank" */}
                                                        {/* className="card-image-wrap"> */}
                                                        <div className="card-image " onClick={() => this.toggleChildMenu(index)}>
                                                            <img src={item.image} alt="Blog" className={`card__img${index}`}

                                                            />
                                                        </div>
                                                        {/* </a> */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </PanelContent>
                                </TabPane>

                            )
                        })}
                    </Tabs>

                </div>

                <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content p-4">
                                <div className="modal-top border-0 mb-4 p-0">
                                    <div className="alert-content">
                                        <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="total-pages">
                            Page 1 of 1
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default GalleryPics;