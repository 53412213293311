import React, { Component } from 'react';
import ImageSignUp from '../../assets/images/sign-up-img-2.png';
import { Row, Col } from "react-bootstrap";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import { ApiUrl, AutoApiKey } from '../../config';
import { uToken } from '../../useToken';
import Autocomplete from 'react-google-autocomplete';

class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            location: props.location,
            SignUpImg: ImageSignUp,
            SamePersonal: false,
            categories: [],
            states:[],
            subcategories: [],
            address: '',
            auto: false,
            email: props.formValue ? props.formValue.email : ""
        }

        this.changeMainCategory = this.changeMainCategory.bind(this);
    }

    changeMainCategory(e) {
        var self = this;
        axios.post(ApiUrl + 'getSubCategories', {
            'category_id': e
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    subcategories: res.data.lists,
                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getCategory() {
        var self = this;
        axios.post(ApiUrl + 'getSubCategories', {
            'category_id': 'ALl'
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    categories: res.data.lists,
                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getStates() {
        var self = this;
        axios.post(ApiUrl + 'getStates', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    states: res.data.lists,
                })

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getCategory();
        this.getStates();
    }


    /**
      * Get the city and set the city input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0] && 'political' === addressArray[i].types[1]) {
                city = addressArray[i].short_name;
                return city;
            }
        }
    };
    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };


    getZipCode = (addressArray) => {
        let zipcode = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
                    zipcode = addressArray[i].short_name;
                    return zipcode;
                }
            }
        }
    };


    getLat = (addressArray) => {
        return addressArray.lat();
    };

    getLng = (addressArray) => {
        return addressArray.lng();
    };

    /**
      * And function for city,state and address input
      * @param event
      */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onPlaceSelected = (place) => {
        console.log(place, 'place')
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            zipcode = this.getZipCode(addressArray),
            business_lat = this.getLat(place.geometry.location),
            business_long = this.getLng(place.geometry.location),
            state = this.getState(addressArray);

        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            zipcode: (zipcode) ? zipcode : '',
            city: (city) ? city : '',
            business_lat: (business_lat) ? business_lat : '',
            business_long: (business_long) ? business_long : '',
            state: (state) ? state : '',
        })
    };


    render() {
        return (

            <Row>

                <Col lg={12} md={12}>
                    <div className="about">
                        <h1>What type of business is this?</h1>
                    </div>
                </Col>

                <Col lg={12} md={12}>
                    <div className="steps">
                        <h2>Step 2 of 3</h2>
                    </div>
                </Col>
                <div className="col-lg-6 mx-auto">

                    <div className="sign-up-image">
                        <div className="step-3">
                            <h5>Tell us about your business</h5>
                            <p>We're here to connect you with customers waiting to find you.  To better serve your future customers, they will need to know how to find you and which service and products that you offer. Please fill out your business information to the right.</p>
                        </div>
                        <img key={0} src={this.state.SignUpImg} alt="testimonial" className="random-img" /></div>
                </div>
                <div className="col-lg-6 mx-auto">
                    <div className="billing-form-item mb-0">

                        <div className="billing-content BSignupForm">
                            <div className="contact-form-action sign-up">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Email</label>
                                                <div className="online-business">
                                                    <div className="custom-checkbox" key={0}>
                                                        <input type="checkbox" id={'chb3-'} onChange={(e) => this.setState({ SamePersonal: e.target.checked })} />
                                                        <label htmlFor={'chb3-'}>
                                                            Same as personal
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    !this.state.SamePersonal ?
                                                        <>
                                                            <AvInput
                                                                type="email"
                                                                className="form-control"
                                                                name="business_email"
                                                                value=""
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>

                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="personal"
                                                                value='No'
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </>
                                                        :
                                                        <>
                                                            <AvInput
                                                                className="form-control"
                                                                name="business_email"
                                                                readOnly={true}
                                                                value={this.state.email}
                                                            />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="personal"
                                                                value='Yes'
                                                                required />
                                                            <AvFeedback> Required Field *</AvFeedback>
                                                        </>
                                                }



                                            </AvGroup>


                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Business Name</label>
                                                <AvInput
                                                    className="form-control"
                                                    name="business_name"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Main Business Industry</label>
                                                <AvField type="select"
                                                    name="business_category_id"
                                                    label=""
                                                    onChange={(e) => this.changeMainCategory(e.target.value)}
                                                // value={this.state.business.business_category_id}
                                                >
                                                    <option value='' >Select Service</option>
                                                    {
                                                        this.state.categories.map(cat => {
                                                            return (
                                                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                            )
                                                        })
                                                    }

                                                </AvField>
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Specific Business Category</label>
                                                <AvField type="select"
                                                    name="business_sub_category_id"
                                                    label=""
                                                    placeholder='Select subcategory'
                                                    required
                                                >
                                                    <option value='' >Select Service</option>
                                                    {
                                                        this.state.subcategories.map(subcat => {
                                                            return (
                                                                <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
                                                            )
                                                        })
                                                    }

                                                </AvField>
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Business Website</label>
                                                <AvInput
                                                    className="form-control"
                                                    name="website"
                                                    value="https://"
                                                     />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>
                                </div>
                                {(this.state.location == 'No') ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <AvGroup>
                                                    <label>Business Address</label>
                                                    <Autocomplete
                                                        apiKey={AutoApiKey}
                                                        placeholder=""
                                                        style={{ width: '100%' }}
                                                        onPlaceSelected={this.onPlaceSelected}
                                                        onKeyPress={e => {
                                                            if (e.key === 'Enter') e.preventDefault();
                                                        }}
                                                        className="form-control"
                                                        fields={[
                                                            'address_components',
                                                            'geometry.location',
                                                            'place_id',
                                                            'formatted_address'
                                                        ]}
                                                        types={[]}
                                                        componentRestrictions={{ country: "us" }}
                                                    />
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="business_address"
                                                        value={this.state.address}
                                                        required />
                                                    <AvFeedback>This field is required.*</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <AvGroup>
                                                    <label>Business Suite #</label>
                                                    <AvInput
                                                        className="form-control"
                                                        name="suite_number"
                                                    />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="input-box1">
                                                <AvGroup>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="business_lat"
                                                        onChange={this.onChange}
                                                        value={this.state.business_lat}
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="business_long"
                                                        onChange={this.onChange}
                                                        value={this.state.business_long}
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                    <AvInput
                                                        placeholder="City"
                                                        className="form-control"
                                                        name="city"
                                                        onChange={this.onChange}
                                                        readOnly={true}
                                                        value={this.state.city}
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-box1">
                                                <AvGroup>
                                                    <AvInput
                                                        placeholder="State"
                                                        className="form-control"
                                                        name="state"
                                                        onChange={this.onChange}
                                                        readOnly={true}
                                                        value={this.state.state}
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-box1">
                                                <AvGroup>
                                                    <AvInput
                                                        name="zipcode"
                                                        placeholder="Zip Code"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        readOnly={true}
                                                        value={this.state.zipcode}
                                                    />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        {/* <div className="col-md-5">
                                            <div className="input-box1">
                                                <label>City</label>
                                                <AvGroup>
                                                    <AvInput
                                                        placeholder="City"
                                                        className="form-control"
                                                        name="city"
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="input-box1">
                                                <label>State</label>
                                                <AvField type="select"
                                                    name="state"
                                                    label=""
                                                >
                                                    <option value='' >State</option>
                                                    {
                                                        this.state.states.map(state => {
                                                            return (
                                                                <option key={state.id} value={state.state}>{state.state}</option>
                                                            )
                                                        })
                                                    }

                                                </AvField>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <div className="input-box1">
                                                <label>Location</label>
                                                <AvGroup>
                                                    <AvInput
                                                        placeholder="Zip Code"
                                                        className="form-control"
                                                        name="zipcode"
                                                        required />
                                                    <AvFeedback> Required Field *</AvFeedback>
                                                </AvGroup>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <>
                                    <AvGroup>
                                        <AvInput
                                            type="hidden"
                                            className="form-control"
                                            name="online"
                                            value={this.state.location}
                                            required
                                        />
                                        <AvFeedback> Required Field *</AvFeedback>

                                    </AvGroup>


                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </Row >

        );
    }
}

export default SignUp;