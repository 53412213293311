import React, { Component, useState } from 'react'
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { algoliaAppId, ApiUrl, algoliaAppKeyId } from '../../config';

import { getGeoInfo } from '../../useToken';
import Autosuggest from 'react-autosuggest';

import algoliasearch from "algoliasearch";

const getSuggestionValue = (suggestion) => (suggestion.business_name) ? suggestion.business_name : suggestion.name
const renderSuggestion = (suggestion) => (suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)

export default class BannerOneSearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedUp: false,
            location: '',
            search: '',
            category: '',
            lat: '',
            lng: '',
            showSuggest: false,
            categories: [],
            value: props.search ? props.search : "",
            suggestions: []
        }
        this.handler = this.handler.bind(this)
    }

    onChange = (event, { newValue, method }) => {
        this.setState({ value: newValue, search: newValue });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
        const index = client.initIndex('katika_business');
        // Search for a first name
        index.search(value).then(({ hits }) => {
            this.setState({ suggestions: hits })
        });
        // fetch(`${ApiUrl}searchBusinessSuggestion?search=${value}`)
        //     .then(response => response.json())
        //     .then(data => this.setState({ suggestions: data.lists }))
    }

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionSelected = () => {
        var self = this;
        self.setState({ isSignedUp: true });
    }



    handler(e) {
        let city = '';
        let region = '';
        let country = '';
        e.allloction.address_components.map(adre => {
            if (adre.types[0] == 'locality' && adre.types[1] == 'political') {
                city = adre.short_name;
            }
            if (adre.types[0] == 'administrative_area_level_1' && adre.types[1] == 'political') {
                region = adre.short_name;
            }

            if (adre.types[0] == 'country' && adre.types[1] == 'political') {
                country = adre.short_name;
            }
            let latitude = e.allloction.geometry.location.lat();
            let longitude = e.allloction.geometry.location.lng();
            var data = {
                'city': city,
                'region': region,
                'country': country,
                'country_name': '',
                'latitude': latitude,
                'longitude': longitude,
            }
            localStorage.setItem('location', JSON.stringify(data));
            this.setState(
                {
                    location: city + ', ' + region,
                    lat: e.allloction.geometry.location.lat(),
                    lng: e.allloction.geometry.location.lng(),
                    showSuggest: false
                }
            );
        })
    }


    handleSearch = (e) => {
        this.setState(
            { search: e.target.value }
        );
    }

    handlelocation = (e) => {
        this.setState(
            {
                location: e.target.value,
            }
        );
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ isSignedUp: true });
    }

    componentDidMount() {
        console.log('props', this.props)
        var loc = JSON.parse(localStorage.getItem('location'));
        if (!loc) {
            if (getGeoInfo() == undefined) {
                this.setState(
                    {
                        location: 'Philadelphia, PA',
                        lat: 39.9525839,
                        lng: -75.1652215,

                    }
                );

            } else {
                this.setState(
                    {
                        location: getGeoInfo(),
                        lat: loc.latitude,
                        lng: loc.longitude,
                    }
                );
            }

        } else {
            let string = loc.city + ', ' + loc.region;
            this.setState(
                {
                    location: string,
                    lat: loc.latitude,
                    lng: loc.longitude,
                }
            );
        }

        this.getCategory();

    }

    getCategory = () => {
        axios.get(ApiUrl + 'getHomePageCategoriesList').then((response) => {
            this.setState(
                { categories: response.data.top_lists }
            );
        }).catch((error) => {
            console.log(error);
        });
    }


    // handleSearchAlgolia = (e) => {
    //     const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
    //     const index = client.initIndex('katika_restaurant');

    //     // Search for a first name
    //     index.search(e.target.value).then(({ hits }) => {
    //         console.log(hits);
    //     });

    // }

    render() {


        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'jerk chicken, barbers, and brunch...',
            value,
            type: "text",
            onChange: this.onChange
        };

        if (this.state.isSignedUp) {
            // redirect to home if signed up
            return <Redirect to={{
                pathname: "/listing/" + encodeURI(value), state: {
                    search: this.state.search,
                    location: this.state.location,
                    category: this.state.category,
                    lat: this.state.lat,
                    lng: this.state.lng
                }
            }}
            />;
        } 
        return (
            <>
                <section className="hero-wrapper hero-wrapper2">
                    {/* <div className="hero-overlay"></div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="main-search-input">
                                        <div className="main-search-input-item">
                                            <div className="contact-form-action">

                                                <div className="form-group mb-0">
                                                    <span className="form-icon">
                                                        <BiSearch />
                                                    </span>

                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onSuggestionSelected}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}

                                                    />
                                                    {/* <input onChange={e => this.handleSearchAlgolia(e)} className="form-control c1" type="text"
                                        placeholder=" jerk chicken, barbers, and brunch..." /> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
