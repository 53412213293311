import React, { Component } from 'react'


export default class Searching extends Component {

    render() {
        return (
            <>
                <div>

               <p>kjkj</p>


                </div>
                </>
        )
    }
}
