import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Restaurants from '../../assets/images/restaurants.png';
import Beauty from '../../assets/images/beauty_spas.png';
import Child from '../../assets/images/child_care.png';
import Retail from '../../assets/images/briefcase.png';
import Shopping from '../../assets/images/shopping-bag.png';
import All from '../../assets/images/cate-dott.png';
import { ApiUrl } from '../../config';
import axios from "axios";
import { getGeoInfo } from '../../useToken';


class BrowseCategories extends Component {
    state = {
        location: '',
        lat: '',
        lng: '',
        categories: [
            {
                id: 1,
                icon: Restaurants,
                title: 'Restaurants',
                listingsNum: 42,
                cardLink: '#'
            },
            {
                id: 2,
                icon: Beauty,
                title: 'Beauty & Spas',
                listingsNum: 31,
                cardLink: '#'
            },
            {
                id: 3,
                icon: Child,
                title: 'Child Care',
                listingsNum: 33,
                cardLink: '#'
            },
            {
                id: 4,
                icon: Retail,
                title: 'Retail Shopping',
                listingsNum: 36,
                cardLink: '#'
            },
            {
                id: 5,
                icon: Shopping,
                title: 'Retail Shopping',
                listingsNum: 7,
                cardLink: '#'
            },
            {
                id: 6,
                icon: All,
                title: 'All Categories',
                listingsNum: 7,
                cardLink: '/all-categories'
            }
        ]
    }



    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));
        if (!loc) {
            if (getGeoInfo() == undefined) {
                this.setState(
                    {
                        location: 'Philadelphia, PA',
                        lat: 39.9525839,
                        lng: -75.1652215,

                    }
                );

            } else {
                this.setState(
                    {
                        location: getGeoInfo(),
                        lat: loc.latitude,
                        lng: loc.longitude,
                    }
                );
            }

        } else {
            let string = loc.city + ', ' + loc.region;
            this.setState(
                {
                    location: string,
                    lat: loc.latitude,
                    lng: loc.longitude,
                }
            );
        }
        this.getCategories();
    }


    getCategories = () => {
        axios.get(ApiUrl + 'getHomePageCategoriesList').then((response) => {
            this.setState(
                { categories: response.data.bottom_lists }
            );
        }).catch((error) => {
            console.log(error);
        });
    }
    render() {
        return (
            <>
                <div className="category  text-center" style={{ background: "#f5f5f5" }}>
                    <div className="container">
                        <h2>Browse Businesses by Category</h2>
                        <div className="row mt-5">
                            {this.state.categories.map((item, index) => {
                                return (
                                    <div className="col-lg-4 column-td-6 col-6" key={item.id}>
                                        <div className={'browse-category browse-category-color' + item.id}>
                                            {(item.parent_id != 0) ?
                                                <Link
                                                    to={{
                                                        pathname: `/listing/${(item.name) ? item.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase() : ""}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                        // pathname: `/listing/${(item.name) ? item.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : ""}`,
                                                        state: {
                                                            search: item.name,
                                                            location: this.state.location,
                                                            lat: this.state.lat,
                                                            lng: this.state.lng,
                                                            category_id: item.parent_id,
                                                            category: item.name,
                                                            sub_category_id: item.id
                                                        }
                                                    }}
                                                    className="category-inner d-block">
                                                    <span>
                                                        <img className="img-thumbnail" width="100%" height="100%" src={item.image} alt="not found" />
                                                    </span>
                                                    <p className="cat__name">
                                                        {item.name}
                                                    </p>
                                                </Link>
                                                :
                                                <Link
                                                    to={{
                                                        pathname: `/listing/${item.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,

                                                        // pathname: `/listing/${item.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}`,
                                                        state: {
                                                            search: item.name,
                                                            location: this.state.location,
                                                            lat: this.state.lat,
                                                            lng: this.state.lng,
                                                            category_id: item.id,
                                                            category: item.name,
                                                        }
                                                    }}
                                                    className="d-block">
                                                    <span>
                                                        <img className="img-thumbnail" width="100%" height="100%" src={item.image} alt="not found" />
                                                    </span>
                                                    <p className="cat__name">
                                                        {item.name}
                                                    </p>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="col-lg-4 column-td-6" key={0}>
                                <div className={'browse-category browse-category-color'}>
                                    <Link to='/all-categories' className="category-inner d-block">
                                        <span>
                                            <img className="img-thumbnail" width="100%" height="100%" src={All} alt="not found" />
                                        </span>
                                        <p className="cat__name">
                                            All Categories
                                        </p>

                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default BrowseCategories;