import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import NavbarListing from '../../components/common/NavbarListing';
import { Link, Redirect } from 'react-router-dom';
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";

class Dashboard extends Component {
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handler = this.handler.bind(this);
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    handleChange(checked) {
        this.setState({ checked });
    }


    handleSubmit(event, errors, values) {
        if (errors.length < 1) {
            axios.post(ApiUrl + 'changePassword', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    render() {

        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <div className="Buyer-Reviews BuyerSecurity">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <div class="BuyerPagesHeading">Change your password</div>
                                <div className="change-password">
                                    {/* <h5>Change  Password</h5> */}
                                    <div className="form-area">
                                        <AvForm onSubmit={this.handleSubmit}>
                                            <div className="password">
                                                <AvGroup>
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <label>Current password</label>
                                                            <p>Enter your existing password.</p>
                                                            <AvInput
                                                                type="password"
                                                                placeholder=""
                                                                className="form-control"
                                                                name="old_password"
                                                                minLength="8"
                                                                required />
                                                            <AvFeedback>This field is invalid</AvFeedback>
                                                        </Col>
                                                    </Row>
                                                </AvGroup>
                                            </div>
                                            <div className="password">
                                                <AvGroup>
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <label>New Password</label>
                                                            <p>Enter the new password you would like.</p>
                                                            <AvInput
                                                                placeholder=""
                                                                className="form-control"
                                                                name="new_password"
                                                                type="password"
                                                                minLength="8"
                                                                required />
                                                            <AvFeedback>This field is invalid</AvFeedback>
                                                        </Col>
                                                    </Row>
                                                </AvGroup>
                                            </div>
                                            <div className="password">
                                                <AvGroup>
                                                    <Row>
                                                        <Col lg={12} md={12}>
                                                            <label>Confirm Password</label>
                                                            <p>Reenter your password to verify.</p>
                                                            <AvInput
                                                                placeholder=""
                                                                className="form-control"
                                                                name="confirm_password"
                                                                type="password"
                                                                minLength="8"
                                                                required />
                                                            <AvFeedback>This field is invalid *</AvFeedback>
                                                        </Col>
                                                    </Row>
                                                </AvGroup>
                                            </div>
                                            <div className="password save-button">
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <Button type="submit" className="save-changes" variant="primary">Save New Password</Button>
                                                        <Link className="save-changes" variant="primary">Cancel</Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >

        );
    }
}

export default Dashboard;