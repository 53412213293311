import React, { Component } from 'react'
import SecondaryNavbar from './SecondaryNavbar'
import { Link } from "react-router-dom";
import { sellerUrl } from '../../../config';
import Logo from "../../../assets/images/logo.png";
import AppIcon from "../../../assets/images/app-icon.png";


export default class GeneralHeader extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        <a className="app-btn" href="https://play.google.com/store/apps/details?id=com.katikabuyer"><img src={AppIcon} alt="Icon" /> Open in App</a>
                                        <div className="logo">
                                            <Link to="/"><img src={Logo} alt="Logo" /></Link>
                                        </div>
                                        {/* SecondaryNavbar */}
                                        <SecondaryNavbar />

                                        {/* Author Access */}
                                        {/* <HeaderAuthorAccess /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
