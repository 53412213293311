import React, { Component } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaDribbble, FaBehance } from 'react-icons/fa'
import authorimg from '../../../assets/images/testi-img2.jpg';
import Button from "../../common/Button";
import Modal from 'react-bootstrap/Modal'
import RButton from 'react-bootstrap/Button'
import useToken, { uToken } from '../../../useToken';
import { Link } from "react-router-dom";
import LoginBox from '../../other/account/LoginBox';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { ApiUrl } from '../../../config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

class WidgetAuthor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            RequestModal: false,
            LoginModal: false,
            authorImg: authorimg,
            food: 'Business Service',
            OrderType: 'Delivery',
            calandlyUrl: '',
            serviceType: this.props.business.business_service_type,
            modelTitle: '',
        }
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
                RequestModal: true,
            })
        }
    }
    onHide() {
        this.setState({ openModal: false, RequestModal: false, LoginModal: false, calandlyUrl: '' });
        this.props.loginActivity(true);
    }

    handleSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.request_type = this.props.business.business_service_type;
            values.business_id = this.props.business.business_id;
            axios.post(ApiUrl + 'addBusinessServiceRequest', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({ openModal: false, RequestModal: false, LoginModal: false });
                        self.props.loginActivity(true);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <>
                {
                    // console.log('sasas', this.props.business.business_service_type),
                    this.props.business.business_service_type.length > 0 ?
                        <div className="sidebar-widget">
                            <div className="author-bio margin-bottom-10px">
                                <div className="d-flex align-items-center">
                                    <div className="author-inner-bio">
                                        <h4 className="author__title font-weight-bold pb-0 mb-1">
                                            {this.state.food}
                                        </h4>
                                    </div>
                                </div>
                                {
                                    this.state.serviceType.map((service, index) => {

                                        return (
                                            <div className="service" key={index}>
                                                {/* <p className="author__meta">
                                                    {service.name}
                                                </p> */}
                                                <div className="btn-box text-center padding-top-35px">

                                                    {
                                                        (service.type == 'anchor') ?
                                                            <Button target="_blank" url={/(http(s?)):\/\//i.test(service.url) ? service.url : `https://${service.url}`} text={service.name} className="d-block" />
                                                            : (service.type == 'tel') ?
                                                                ""
                                                                // <a className="theme-btn d-block" href={`tel:${service.url}`}> {service.name}</a>
                                                                : (service.type == 'calandly') ?
                                                                    <>
                                                                        <RButton onClick={() => this.setState({ openModal: true, calandlyUrl: service.url })}
                                                                            type="button" className="r-button-d-block" variant="primary">
                                                                            {service.name}
                                                                        </RButton>
                                                                    </>
                                                                    : (service.type == 'button') ?
                                                                        <>
                                                                            {(!uToken()) ?
                                                                                <RButton onClick={() => this.setState({ modelTitle: service.name, LoginModal: true })}
                                                                                    type="button" className="r-button-d-block" variant="primary">
                                                                                    {service.name}
                                                                                </RButton>
                                                                                :
                                                                                <RButton onClick={() => this.setState({ modelTitle: service.name, RequestModal: true })}
                                                                                    type="button" className="r-button-d-block" variant="primary">
                                                                                    {service.name}
                                                                                </RButton>
                                                                            }
                                                                        </>
                                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        );

                                    })
                                }

                            </div>
                            <ToastContainer />
                        </div>
                        : ''
                }
                <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Make an Appointment </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />

                    </Modal.Body>
                </Modal>

                <Modal className="send-message-business" show={this.state.RequestModal} onHide={this.onHide} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> {this.state.modelTitle} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AvForm onSubmit={this.handleSubmit}>
                            <AvGroup>
                                <label><b>To:</b> {this.props.business.business_name} </label>
                                <p className="messgae-share">Share a few details so we can get you in touch with the business</p>

                                <AvInput
                                    type="textarea"
                                    placeholder="Can you tell me more about your services?"
                                    className="form-control"
                                    name="message"
                                    required />
                                <AvFeedback>Required Field *</AvFeedback>
                            </AvGroup>
                            <Modal.Footer>
                                <RButton type="submit" variant="primary" >{this.state.modelTitle == 'Request a Quote' ? 'Request Quote' : this.state.modelTitle}</RButton>
                                <p className={`notes ${this.state.modelTitle == 'Request a Quote' ? 'display-inline' : ''} `}>By sending this request, you agree that katika can email you when businesses reply.</p>

                            </Modal.Footer>
                        </AvForm>

                    </Modal.Body>
                </Modal>

                {/* <Modal show={this.state.RequestModal} onHide={this.onHide} size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Request </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                </Modal> */}

            </>
        );
    }
}

export default WidgetAuthor;