import React, { Component } from 'react';
import { FaRegEnvelope } from 'react-icons/fa'
import facebook from '../../../assets/images/facebook.png'
import twitter from '../../../assets/images/twitter.png'
import instagram from '../../../assets/images/instagram_new.png'
import youtube from '../../../assets/images/youtube.png'


class NewsLetter extends Component {
    render() {
        return (
            <>
                <div className="k-footer-logo">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="media">
                                    <div className="media-left">
                                        <a href="#">
                                            <img className="media-object" src="https://www.shopkatika.com/katika/front/images/k-footer.png" alt="..." />
                                        </a>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="media-heading m-h4">Get the latest deals and updates.</h4>
                                        <p className="m-p">Receive our weekly email with new products, special offers, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-group mail contact-form-action">
                                    <form method="post">
                                        <div className="form-group inline-flex mb-0">
                                            <span className="form-icon">
                                                <FaRegEnvelope />
                                            </span>
                                            <input className="form-control mr-5 inline-block" type="email" placeholder="Enter your email" />
                                            <button className="theme-btn inline-block" type="submit">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 social-row">
                                <ul>
                                    <li><span>United States</span></li> |
                                    <li><a href="https://www.facebook.com/shopkatika">
                                        <img className="media-object" src={facebook}
                                            alt="..." />
                                    </a>
                                    </li>
                                    <li><a href="https://www.instagram.com/shopkatika/">
                                        <img className="media-object" src={instagram} alt="..." />
                                    </a></li>
                                    <li><a href="https://twitter.com/shopkatika/">
                                        <img className="media-object" src={twitter} alt="..." />
                                    </a></li>
                                    <li><a href="https://www.youtube.com/channel/UC6ML8hqMCowM6yMXZt8CmuQ">
                                        <img className="media-object" src={youtube} alt="..." />
                                    </a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 social-row text-right">
                                <ul>
                                    <li><span>South Africa </span></li> |
                                    <li><a href="https://www.facebook.com/shopkatikafrica/">
                                        <img className="media-object" src={facebook} alt="..." />
                                    </a></li>
                                    <li><a href="https://www.instagram.com/shopkatikaafrica/">
                                        <img className="media-object" src={instagram} alt="..." />
                                    </a></li>
                                    <li><a href="https://twitter.com/katikaafrica/">
                                        <img className="media-object" src={twitter} alt="..." />
                                    </a></li>
                                    <li><a href="#">
                                        <img className="media-object" src={youtube} alt="..." />
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default NewsLetter;