import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import HeadPhones from '../../assets/images/Katika_headphones.png';
import { Helmet } from "react-helmet";


const Business = () => {

    return (
        <main className="BusinessHome FaqPages Labs">
            <Helmet>
                <title>{`Katika Labs`}</title>
                <meta name="title" content={`Katika Labs`} />
                <meta name="description"
                    content={`We birth new ideas all the time in our lab. Katika Labs is the creative space to further afrotech ideas.`} />
                <meta property="og:title" content={`Katika Labs`} />
                <meta property="og:description"
                    content={`We birth new ideas all the time in our lab. Katika Labs is the creative space to further afrotech ideas.`} />
            </Helmet>
            {/* Header */}
            <HomePageHeader />
            <div className="CommonBreadcrumb">
                <h1>Katika Labs</h1>
            </div>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="PressPage padding-top-60px padding-bottom-90px">
                            <h2>Explore Our Afrotech Projects</h2>
                            <p>Katika creates fun web projects that allows us to showcase and share our creative ideas for the culture.</p>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="KatikaMusic padding-bottom-100px border-bottom">
                            <div className="Music">
                                <img src={HeadPhones} alt="Member" />
                                <h2>Katika Music</h2>
                                <p>Black People Music Worldwide</p>
                                <p>
                                    <b>Project Description</b> Listen to music from the African Diaspora by clicking a country on the map.</p>
                                <a href="https://katikamusic.netlify.app/" target="_blank">Go to Katika Music</a>
                                <p className="FooterMusic">Used: Sound Cloud API, REACTjs, CSS3, HTML</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
            <ScrollTopBtn />
        </main>
    )
}

export default Business;