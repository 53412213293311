import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import UserInfo from "../../components/user/UserInfo";
import Footer from "../../components/common/footer/Footer";
import UserForm from "../../components/user/UserForm";
import { Container } from "react-bootstrap";
import '../../assets/css/style2.css';
import { Link, Redirect } from "react-router-dom";
import NavbarListing from '../../components/common/NavbarListing';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.handler = this.handler.bind(this)
    }



    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }


    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile">
                {/* Header */}
                {/* <GeneralHeader /> */}
                <NavbarListing handler={this.handler} />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <div className="Buyer-wrapper">
                        <div className="sidebarBuyer">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent">
                            <div className="BuyerPagesHeading">Update Buyer Profile</div>
                            <UserForm history={this.props.history} />
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;