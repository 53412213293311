import React, { Component } from "react";
import Slider from "react-slick";
import { Row, Col, Container } from "react-bootstrap";
import $ from 'jquery';
import g_Close from "../../../assets/images/g_Close.png"
import katika_K from "../../../assets/images/katika_K2.png"
import bi_flag from "../../../assets/images/bi_flag.png"
import { InlineShareButtons } from 'sharethis-reactjs';
import { HiLink } from "react-icons/hi";
import Button from 'react-bootstrap/Button'

import heart from "../../../assets/images/Heart1.png"
import chat from "../../../assets/images/chat4.svg"
import heart2 from "../../../assets/images/Heart2.png"
import Comment from '../../../assets/images/comment.png';
import Like from '../../../assets/images/like1.png';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { BsChevronUp, BsChevronDown, BsInfoCircle } from 'react-icons/bs'
import axios from "axios";
import { adminUrl, ApiUrl, buyerUrl } from "../../../config";
import { uInfo, uToken } from "../../../useToken";
import { RiThumbUpFill, RiThumbUpLine } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import Picker from 'emoji-picker-react';
import LoginBox from '../../../components/other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'

const Emojis = [
    { id: 1533, symbol: '❤️', tags_EN: 'orange, heart, love', tags_ES: 'corazón, naranja, amor', category: 'gente' },
    { id: 3, symbol: '😂', tags_EN: 'smile, tears, face', tags_ES: 'sonrisa, cara, llorar de risa', category: 'smileys' },
    { id: 32, symbol: '😯', tags_EN: 'face, open, mouth', tags_ES: 'cara, boca, abierta', category: 'smileys' },
    { id: 31, symbol: '😢', tags_EN: 'sad, relieved , face', tags_ES: 'cara, triste, aliviada', category: 'smileys' },
    { id: 1, symbol: '😠', tags_EN: 'smile, grinning, face', tags_ES: 'risa, cara, sonriente', category: 'smileys' },
    { id: 2, symbol: '👍', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },
    { id: 2, symbol: '👎', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },

];

export default class AutoPlayMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reply: '',
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            autoPlay: false,
            replyID: 0,
            current: {},
            initialSlide: 0,
            data: [],
            comments: [],
            block: {},
            reply1: '',
            heightSet: 0,
        };


        this.updateDimensions = this.updateDimensions.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    onHide() {
        this.setState({ LoginModal: false });
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            });

            this.getSingleStory();

            // this.props.handler2();
            // this.props.history.go('/')
        }
    }

    componentDidMount() {
        // this.slider.current.slickGoTo(2);
        // this.getStoryComment();
        this.getSingleStory();

        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {

        var windowHeight = $(document).height();
        var area = $('#postDetailsPage').height() + $('#info').height();

        var height = windowHeight - area - 90;
        this.setState({ heightSet: height });
        console.log(windowHeight, 'windowHeight ', area, 'height', height);
    }




    getSingleStory = async () => {
        const { id } = this.props.match.params;
        var self = this;

        var data = {
            'city': 'Philadelphia',
            'region': 'PA',
            'country': 'USA'
        };

        axios.post(ApiUrl + 'singleBusinessPost', { 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                console.log(res.data)
                self.setState({
                    block: res.data.lists
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    onEmojiReplyClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_reply_' + id];
        let text = stuff + emojiObject.emoji;
        this.setState({
            ['comment_reply_' + id]: text
        });
    };


    handleReplyText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }



    handleReplyKeyDown(e, id, index, total_comments, commentIndex, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_reply_' + replyId];
            self.setState({
                ['comment_reply_' + replyId]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {

                        let stateCopy = self.state.block;
                        stateCopy.comments[commentIndex] = res.data.comments;
                        //Update the state
                        self.setState(stateCopy);


                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    actionLike = (id, activty, index, total_like) => {
        var self = this;
        axios.post(ApiUrl + 'addBusinessPostLike', { 'is_like': activty, 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    let stateCopy = self.state.block;
                    stateCopy.is_like = activty;
                    stateCopy.total_likes = total_like;
                    self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    actionCommentLike = (id, activty, index, total_like, commentIndex, parent_id) => {

        console.log(id, activty, index, total_like, commentIndex, parent_id, 'id, activty, index, total_like, commentIndex, parent_id')
        var self = this;
        axios.post(ApiUrl + 'addPostCommentReaction', { 'is_like': activty, 'comment_id': id, 'parent_id': parent_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {

                    let stateCopy = self.state.block;
                    stateCopy.comments[commentIndex] = res.data.comments;
                    self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleKeyDown(e, id, index, total_comments, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_' + id];
            self.setState({
                ['comment_' + id]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {

                        let stateCopy = self.state.block;
                        stateCopy.total_comments = total_comments;
                        stateCopy.comments = [...stateCopy.comments, res.data.comments]
                        //Update the state
                        self.setState(stateCopy);
                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    onEmojiClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_' + id];

        console.log(stuff, 'ssss')
        let text = '';
        if (stuff != undefined) {
            text = stuff + emojiObject.emoji;
        } else {
            text = emojiObject.emoji;
        }


        // let text = stuff + emojiObject.emoji;
        this.setState({
            ['comment_' + id]: text
        });
    };
    handleText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }


    componentWillUnmount() {
        var info = $('#info').height();
        var comment = $('#postDetailsPage').height();

        var window = $(document).height();

        var height = info;
        var total = window - height;

        console.log(height, 'windowHeight', window, total)
        $('#comentSetionArea').css('height', `${total}`);
    }


    render() {

        const { id } = this.props.match.params;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const divStyle = {
            height: this.state.heightSet + 'px',
        };


        return (
            <div className="stroy-media post-details-page review_page">
                {this.state.data ?
                    <Container fluid>
                        <Row>
                            <Col lg={6} md={6} style={{ padding: 0 }}>
                                <div className="story-player">
                                    <div className="LeftSideArea">
                                        <div className="story-top">
                                            <div className="TopHeaderLeft">
                                                <div className="close-story" onClick={() => window.history.go(-1)}>
                                                    <img src={g_Close} alt="katika_K" />
                                                </div>

                                                <div className="k-story-logo">
                                                    <img src={katika_K} alt="katika_K" />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="story-slider view-story">
                                            <Slider {...settings}>
                                                {
                                                    this.state.block.images && this.state.block.images.map((image, i) => {
                                                        return (
                                                            <div key={i} className="slide-slider">
                                                                {
                                                                    image.type == 'video' ? (
                                                                        <video controls
                                                                            preload="auto" loop=""   >
                                                                            <source type="video/mp4"
                                                                                src={image.image}>
                                                                            </source>
                                                                        </video>
                                                                    ) :
                                                                        <img src={image.image} />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={2} style={{ padding: 0 }}>
                                <div className="topHeaderRight">
                                    <div className="">
                                        <h4>{this.state.block.title}</h4>
                                        <h6>{this.state.block.post_type_title} · Located in {this.state.block.zipcode}</h6>
                                        <a href={`${this.state.block.link}`}>{this.state.block.link_text ? this.state.block.link_text : "Learn More"}</a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4}>
                                <div className="details-info story-details-info" >
                                    <ul className="comments-list listing-gallery-p padding-top-10px" id="info">
                                        <li>
                                            <div className="comment listing-details gallery-story">
                                                <div className="user-img-details">
                                                    <img className="avatar__img" alt="Comment"
                                                        src={this.state.block.business_logo} />
                                                </div>
                                                <div className="user-body">

                                                    <div className="left">
                                                        <div className="name">
                                                            {this.state.block.business_name} </div>
                                                        <p className="comment_hour">{this.state.block.created_at}</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="story-data">
                                                <h5>{this.state.block.title}</h5>
                                                <p className="description">
                                                    {this.state.block.description}
                                                </p>
                                                <div className="Like_Comment">
                                                    <span>{this.state.block.total_likes} Likes</span>
                                                    <span>{this.state.block.total_comments} Comments</span>
                                                </div>

                                            </div>

                                            <div className='post-meta postMetaDetails' >
                                                {(uToken()) ?
                                                    <div className="user_review">

                                                        {
                                                            this.state.block.is_like == 0 ? (
                                                                <Button variant="none" title="Like"
                                                                    onClick={() => this.actionLike(this.state.block.id, 1, 0, this.state.block.total_likes + 1)}
                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                >
                                                                    <span><RiThumbUpLine /> Like</span>
                                                                </Button>
                                                            ) :
                                                                (
                                                                    <Button variant="none" title="Like"
                                                                        onClick={() => this.actionLike(this.state.block.id, 0, 0, this.state.block.total_likes - 1)}
                                                                        className={`btn btn-link card__img img-thumbnail `}
                                                                    >
                                                                        <span><RiThumbUpFill /> Like </span>
                                                                    </Button>
                                                                )
                                                        }

                                                        <Button variant="none" title="Comment"
                                                            onClick={() => { this.setState({ postID: this.state.block.id }); $('#comment_' + this.state.block.id).focus() }}
                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                        >
                                                            <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                                        </Button>
                                                    </div>
                                                    : <div className="user_review">
                                                        <Button variant="none" title="Like"
                                                            onClick={() => this.setState({ LoginModal: true })}
                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                        >
                                                            <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="review" /><span>Like</span>
                                                        </Button>

                                                        <Button variant="none" title="Comment"
                                                            onClick={() => this.setState({ LoginModal: true })}
                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                        >
                                                            <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="comment-section comments" id="comentSetionArea" style={divStyle}>
                                        <div className="comments-list comment_area padding-top-20px block">
                                            {
                                                this.state.block.comments && this.state.block.comments.map((comment, c) => {
                                                    return (
                                                        <div key={c} className="comment storyComment reply-coment">
                                                            <div className="coment-user">
                                                                <img className="avatar__img" alt="Comment"
                                                                    src={comment.image} />
                                                                <div className="meta-data">
                                                                    <span className="comment__author">{comment.full_name} <span className="time-com">{comment.created_at}</span></span>
                                                                </div>
                                                            </div>

                                                            <div className="comment-body">
                                                                <div className="msg-body">

                                                                    <div className="d-flex">
                                                                        <p className="comment-content">{comment.comment}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="comment-time">
                                                                    <Button variant="none" title="Like"
                                                                        onClick={() => console.log('hi')}
                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                    >
                                                                        <span> {comment.total_likes} Like</span>
                                                                    </Button>
                                                                    {
                                                                        uToken() ? (
                                                                            <>
                                                                                <Button variant="none" title="Comment"
                                                                                    onClick={() => this.setState({ postCommentReplyID: this.state.postCommentReplyID != comment.id ? comment.id : 0 })}
                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                >
                                                                                    <span>Reply</span>
                                                                                </Button>
                                                                                {
                                                                                    comment.is_like == 0 ? (
                                                                                        <Button variant="none" title="Like"
                                                                                            onClick={() => this.actionCommentLike(comment.id, 1, 0, comment.total_likes + 1, c, comment.id)}
                                                                                            className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                        >
                                                                                            <span> <RiThumbUpLine /></span>
                                                                                        </Button>
                                                                                    ) :
                                                                                        (
                                                                                            <Button variant="none" title="Like"
                                                                                                onClick={() => this.actionCommentLike(comment.id, 0, 0, comment.total_likes - 1, c, comment.id)}
                                                                                                className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                            >
                                                                                                <span><RiThumbUpFill /></span>
                                                                                            </Button>
                                                                                        )
                                                                                }
                                                                            </>

                                                                        )
                                                                            :
                                                                            <>
                                                                                <Button variant="none" title="Comment"
                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                >
                                                                                    <span>Reply</span>
                                                                                </Button>

                                                                                <Button variant="none" title="Like"
                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                    className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                >
                                                                                    <span><RiThumbUpLine /></span>
                                                                                </Button>




                                                                            </>
                                                                    }
                                                                </div>

                                                                <div className={`user_review comment-reply ${this.state.postCommentReplyID == comment.id ? 'block' : 'none'} `}>
                                                                    <div className='post-meta'>
                                                                        <div className="user_review">
                                                                            <div className='comment-post-area'>

                                                                                <div className="d-flex mt-3">
                                                                                    <div className="user-img-comment">
                                                                                        <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                    </div>

                                                                                    <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                        placeholder="Write a reply..."
                                                                                        name={`comment_reply_${comment.id}`}
                                                                                        value={this.state[`comment_reply_${comment.id}`]}
                                                                                        onChange={(e) => this.handleReplyText1(e, comment.id, 0, this.state.block.total_comments + 1)}
                                                                                        onKeyDown={(e) => this.handleReplyKeyDown(e, this.state.block.id, 0, this.state.block.total_comments + 1, c, comment.id)}
                                                                                    ></textarea>

                                                                                    <div className='comment-emoji-area'>

                                                                                        <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: comment.id })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        this.state.showPicker && this.state.SelectPickerID == comment.id && (
                                                                                            <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, comment.id)} />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="reple">
                                                                {comment.replies.map((reply, r) => {

                                                                    return (
                                                                        <div key={c + '' + r} className="comment storyComment reply-coment">
                                                                            <div className="coment-user">
                                                                                <img className="avatar__img" alt="Comment"
                                                                                    src={reply.image} />
                                                                                <div className="meta-data">
                                                                                    <span className="comment__author">{reply.full_name} <span className="time-com">{reply.created_at}</span></span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="comment-body">
                                                                                <div className="msg-body">

                                                                                    <div className="d-flex">
                                                                                        <p className="comment-content">{reply.comment}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment-time">
                                                                                    {/* {comment.created_at}  */}



                                                                                    <Button variant="none" title="Like"
                                                                                        onClick={() => console.log('hi')}
                                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                                    >
                                                                                        <span> {reply.total_likes} Like</span>
                                                                                    </Button>



                                                                                    {
                                                                                        uToken() ? (
                                                                                            <>

                                                                                                {
                                                                                                    reply.is_like == 0 ? (
                                                                                                        <Button variant="none" title="Like"
                                                                                                            onClick={() => this.actionCommentLike(reply.id, 1, 0, reply.total_likes + 1, c, comment.id)}
                                                                                                            className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                        >
                                                                                                            <span> <RiThumbUpLine /></span>
                                                                                                        </Button>
                                                                                                    ) :
                                                                                                        (
                                                                                                            <Button variant="none" title="Like"
                                                                                                                onClick={() => this.actionCommentLike(reply.id, 0, 0, reply.total_likes - 1, c, comment.id)}
                                                                                                                className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                            >
                                                                                                                <span><RiThumbUpFill /></span>
                                                                                                            </Button>
                                                                                                        )
                                                                                                }
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                <Button variant="none" title="Like"
                                                                                                    onClick={() => this.setState({ LoginModal: true })}
                                                                                                    className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                >
                                                                                                    <span> <RiThumbUpLine /></span>
                                                                                                </Button>
                                                                                            </>
                                                                                        )
                                                                                    }


                                                                                </div>


                                                                                <div className={`user_review comment-reply ${this.state.postCommentReplyID == reply.id ? 'block' : 'none'} `}>

                                                                                    <div className='comment-post-area'>

                                                                                        <div className="d-flex mt-3">
                                                                                            <div className="user-img-comment">
                                                                                                <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                            </div>

                                                                                            <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                placeholder="Write a comment…"
                                                                                                name={`comment_reply_${reply.id}`}
                                                                                                value={this.state[`comment_reply_${reply.id}`]}
                                                                                                onChange={(e) => this.handleReplyText1(e, reply.id, 0, reply.total_comments + 1)}
                                                                                                onKeyDown={(e) => this.handleReplyKeyDown(e, reply.id, 0, reply.total_comments + 1, comment.id)}
                                                                                            ></textarea>

                                                                                            <div className='comment-emoji-area'>

                                                                                                <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: reply.id })}>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                this.state.showPicker && this.state.SelectPickerID == reply.id && (
                                                                                                    <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, reply.id)} />
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>

                                    {
                                        uToken() && (
                                            <>
                                                <div className="add-comment postDetailsPage" id="postDetailsPage">
                                                    <div className="form-group basic-textarea reaction-2">
                                                        <div className="d-flex mt-3">
                                                            <div className="user-img-comment">
                                                                <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                            </div>
                                                            <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                placeholder={`Write a comment…`}
                                                                name={`comment_${this.state.block.id}`}
                                                                value={this.state[`comment_${this.state.block.id}`]}
                                                                id={`comment_${this.state.block.id}`}
                                                                autoFocus
                                                                onChange={(e) => this.handleText1(e, this.state.block.id, 0, this.state.block.total_comments + 1)}
                                                                onKeyDown={(e) => this.handleKeyDown(e, this.state.block.id, 0, this.state.block.total_comments + 1, 0)}
                                                            ></textarea>
                                                            <div className='comment-emoji-area'>
                                                                <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: this.state.block.id })}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {
                                                                this.state.showPicker && this.state.SelectPickerID == this.state.block.id && (

                                                                    <Picker onEmojiClick={(event, object) => this.onEmojiClick(event, object, this.state.block.id)} />
                                                                )
                                                            }
                                                        </div>


                                                    </div>

                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </Col>


                        </Row>


                        <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>  </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <h5>Login required to perform this action </h5> */}
                                <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                            </Modal.Body>
                        </Modal>

                        <ToastContainer />

                    </Container>
                    : ""
                }
            </div >
        );
    }
}