import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import { FaTrashAlt } from "react-icons/fa";
import { Label } from 'reactstrap';
import { uToken } from '../../useToken';
import { ApiUrl, AutoApiKey } from '../../config';
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { Collapse, CardBody, Card } from 'reactstrap';

class BuyerForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: '',
            emails: [],
            user: '',
            externalData: false,
            address: '',
            auto: false,
            items: [],
            GetEmailUpdate: new Map([['friend_requests', true], ['new_followers', true]]),
            checkbox: [
                {
                    id: 1,
                    name: 'Receive emails from Katika'
                },

            ],
            emailUpdate: [

                {
                    id: 2,
                    key: 'friend_requests',
                    name: 'Friend requests',
                    value: 'No'
                },
                {
                    id: 3,
                    key: 'new_followers',
                    name: 'New followers',
                    value: 'No'
                }
            ],
            KnowAbout: [
                {
                    id: 10,
                    key: 'new_followers',
                    name: 'Businesses you might like',
                    value: 'No'
                },
                {
                    id: 11,
                    key: 'new_followers',
                    name: 'Suggested businesses to review',
                    value: 'No',
                }
            ],
            Activity: [
                {
                    id: 20,
                    key: 'check-ins',
                    name: 'Check-ins',
                    value: 'No'
                },
            ],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);

    }

    handleSubmit = async (event, errors, values) => {
        let email_update = [];

        for (let [key, value] of this.state.GetEmailUpdate) {
            email_update.push([`${key}--${value}`]);
        }


        const obj = {};
        obj.permission = email_update;
        console.log('obj', obj.permission)
        var self = this;
        axios.post(ApiUrl + 'addUserProfileSetting', obj.permission, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        auto: false,
                        externalData: false,
                    })
                    self.getProfile();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    handlePhoneSubmit = async (event, errors, values) => {
        var self = this;
        axios.post(ApiUrl + 'updateBuyerPhoneNumber', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleEmailSubmit = async (event, errors, values) => {
        var self = this;
        axios.post(ApiUrl + 'addUserEmail', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        addEmailModal: false,
                        externalData: false,
                    })
                    self.getProfile();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getProfile();
    }


    putEmailUpdate = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        this.setState(prevState => ({
            GetEmailUpdate: prevState.GetEmailUpdate.set(item, isChecked)
        }));
    }


    removeEmail(id) {
        var self = this;
        axios.post(ApiUrl + 'deleteUserEmail', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getProfile();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getProfile = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getBuyerEmailNotificationSetting', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if(res.data.success === true){
                    self.setState({
                        profile: res.data.lists,
                        emails: res.data.lists.user_email,
                        externalData: true,
                    })
                }else{
                    self.setState({
                        externalData: true,
                    })
                }
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <>

                <div className="faq-forum">
                    <div className="contact-form-action">
                        <div className="BuyerEmailList">
                            <div className="BuyerEmail">
                                <div className="BuyerPagesHeading">Email Accounts</div>
                                <p>Add accounts, remove accounts, and change your primary account.</p>
                            </div>
                            <div className="EmailAdd">
                                <button onClick={() => this.setState({ addEmailModal: !this.state.addEmailModal })} >
                                    Add Email
                                </button>
                            </div>
                            {(this.state.addEmailModal) ?
                                <Collapse isOpen={this.state.addEmailModal}>
                                    <Card className="card-custom-1">
                                        <CardBody >
                                            <AvForm onSubmit={this.handleEmailSubmit}>
                                                <>
                                                    <AvGroup>
                                                        <AvInput
                                                            placeholder="Enter Email"
                                                            className="form-control"
                                                            name="email"
                                                            type="email"
                                                            required />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>
                                                </>
                                                <div className="button-modal-footer">
                                                    <Button variant="secondary" onClick={() => this.setState({ addEmailModal: !this.state.addEmailModal })}>Close</Button>
                                                    <Button type="submit" variant="primary" >Save Changes</Button>
                                                </div>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                                : ""
                            }
                        </div>
                        <div className="BuyerEmailList EmailListing">
                            <p>{this.state.profile.email} · Primary account</p>
                            <div className="TrashIcon">
                                <button title="Cannot delete primary account" disabled ><span><FaTrashAlt /></span></button>
                            </div>
                        </div>
                        {
                            this.state.emails.map((email, i) => {
                                return (
                                    <div className="BuyerEmailList EmailListing">
                                        <p>{email.email} </p>
                                        <div className="TrashIcon">
                                            <button title="Delete" onClick={() => this.removeEmail(email.id)}  ><span><FaTrashAlt /></span></button>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {/* <div className="BuyerPagesHeading padding-top-35px">Phone Number</div>
                        <div className="EmailDesc">
                            <p>Add or edit your phone number. We’ll automatically enter it when you request a quote, make restaurant
reservations, order food delivery, or make other transactions on Katika.</p>
                        </div>


                        <AvForm onSubmit={this.handlePhoneSubmit}>
                            <>
                                <div className="PhoneNumber">
                                    <AvInput
                                        placeholder="+1"
                                        className="form-control"
                                        value="+1"
                                        readOnly={true}
                                        name="code"
                                        type="text"
                                        maxLength="3"
                                        required />
                                    <AvInput
                                        placeholder=""
                                        className="form-control"
                                        name="phone_number"
                                        value={this.state.profile.phone}
                                        type="number"
                                        required />
                                </div>
                            </>
                            <div className="EmailAdd PhoneNo">
                                <div className="button-modal-footer">
                                    <Button type="submit" variant="primary" >Save Phone Number</Button>
                                </div>
                            </div>
                        </AvForm> */}

                        <div className="BuyerEmailList Notification">
                            <div className="BuyerEmail">
                                <div className="BuyerPagesHeading padding-top-50px">Email Notification Settings</div>
                                <p>Manage what emails are sent to {this.state.profile.email}</p>
                            </div>

                        </div>
                        <AvForm onSubmit={this.handleSubmit}>
                            <div className="EmailKatika">
                                {this.state.checkbox.map(check => {
                                    return (
                                        <div className="custom-checkbox" key={check.id}>
                                            <input type="checkbox" title={check.name} id={'chb3-' + check.id} name={check.id} />
                                            <label htmlFor={'chb3-' + check.id}>
                                                {check.name}
                                            </label>
                                        </div>
                                    )
                                })}
                                <p>Note: you will still receive certain legal, transactional or administrative emails.</p>
                            </div>

                            <div className="Permissions padding-top-20px">
                                <div className="PermissinHeading"><h5>Get email updates about:</h5></div>
                                <div className="PermissionCheckBox">
                                    {this.state.emailUpdate.map(check => {
                                        return (
                                            // <AvField value={check.value} type="checkbox" name={check.key} label={check.name} />

                                            <div className="custom-checkbox" key={check.id}>
                                                <input defaultChecked={true} value={check.value} type="checkbox" title={check.name} id={'chb3-' + check.id} name={check.key} onChange={this.putEmailUpdate} />
                                                <label htmlFor={'chb3-' + check.id}>
                                                    {check.name}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>


                            </div>
                            <div className="Permissions padding-top-20px">
                                <div className="PermissinHeading"><h5>We’ll also let you know about:</h5></div>
                                <div className="PermissionCheckBox">
                                    {this.state.KnowAbout.map(check => {
                                        return (
                                            <div className="custom-checkbox" key={check.id}>
                                                <input type="checkbox" title={check.name} id={'chb3-' + check.id} name={check.id} />
                                                <label htmlFor={'chb3-' + check.id}>
                                                    {check.name}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="BuyerEmailList">
                                <div className="BuyerEmail">
                                    <div className="BuyerPagesHeading padding-top-50px">Push Notification Settings</div>
                                    <p>Receive push notifications to mobile devices that are connected to your Katika account</p>
                                </div>

                            </div>
                            <div className="Permissions padding-top-20px">
                                <div className="PermissinHeading"><h5>Friend activity</h5></div>
                                <div className="PermissionCheckBox">
                                    {this.state.Activity.map(check => {
                                        return (
                                            <div className="custom-checkbox" key={check.id}>
                                                <input type="checkbox" title={check.name} id={'chb3-' + check.id} name={check.id} />
                                                <label htmlFor={'chb3-' + check.id}>
                                                    {check.name}
                                                </label>
                                            </div>
                                        )
                                    })}
                                    <div className="input-box">
                                        <AvRadioGroup value={this.state.user.gender} name="gender" required errorMessage="Pick one!">
                                            <AvRadio label="By friends in your city" />
                                            <AvRadio label="By friends in all cities" />
                                        </AvRadioGroup>
                                    </div>


                                </div>
                            </div>
                            <div className="EmailAdd PhoneNo SaveBtn">
                                <button>Save Notification Settings</button>
                                <Link to="#" >Cancel</Link>
                            </div>

                        </AvForm>
                    </div>
                </div>
                {/* <ToastContainer /> */}

            </>
        );
    }
}

export default BuyerForm;