import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebar from "../../components/user/BuyerSidebar";
import UserInfo from "../../components/user/UserInfo";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';
import CheckIn from '../../assets/images/CheckIn.png';
import { FaTrashAlt, FaUsers, FaRegCopy, FaInfo } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import Overview from '../../assets/images/overview.svg';
import Friends from '../../assets/images/friends.svg';
import Reviews from '../../assets/images/reviews.svg';
import Messages from '../../assets/images/chat3.svg';
import Bookmarks from '../../assets/images/bookmark.svg';
import CheckIns from '../../assets/images/check-in.svg';
import Warning from '../../assets/images/warning.png';
import iconMore from '../../assets/images/icons_more-horizontal.png';
import newTab from '../../assets/images/new-tab.png';
import Newsletters from '../../assets/images/newsletter.svg';
import MyStory from '../../assets/images/MyStory.svg';

import Security from "../../assets/images/security.png";
import Photo from "../../assets/images/gallery.svg"
import NavbarListing from '../../components/common/NavbarListing';
import { AiOutlineStar, AiFillStar, AiOutlineInfo } from "react-icons/ai";
import { BiBell, BiMessageError, BiLeftArrowAlt } from "react-icons/bi";
import { MdExitToApp } from "react-icons/md";
import { VscTrash } from "react-icons/vsc";
import { BsTag, BsPersonFill, BsPlus } from "react-icons/bs";
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
// import 'react-pro-sidebar/dist/css/styles.css';

import help from "../../assets/images/carbon_help.png"
import userProfile from "../../assets/images/carbon_user-profile.png"
import userOnline from "../../assets/images/carbon_user-online.png"
import DocumentAttachment from "../../assets/images/carbon_document_attachment.svg"
import InformationCircle from "../../assets/images/informationCircle.png"


import Tick from "../../assets/images/tick_2.png"
import mute from "../../assets/images/mute.png"
import archive from "../../assets/images/archive.png"
import bin from "../../assets/images/bin.png"


import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import { MdImage, MdContentCopy } from "react-icons/md";
import { BiSearch, BiChevronDown, BiChevronUp } from "react-icons/bi";


import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import highlight from './Highlight'
import { AvForm, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { BsChevronUp, BsChevronDown, BsInfoCircle } from 'react-icons/bs'
////////////////////////Chat////////////////

// =========================Chat==============================
import Button from 'react-bootstrap/Button'
import Scrollbar from 'smooth-scrollbar-react';
import ScrollToBottom from 'react-scroll-to-bottom';
import ChatIcon from '../../assets/images/bx_bx-chat.png';
import {
    MDBCard, MDBCardBody, MDBRow, MDBCol, MDBListGroup, MDBListGroupItem, MDBBadge, MDBIcon,
    MDBBtn
} from "mdbreact";
import { FaRegClock } from "react-icons/fa";
import axios from 'axios';
import { ApiUrl, buyerUrl, PusherKey } from '../../config';
import { uInfo, uToken } from '../../useToken';
import Pusher from 'pusher-js'
import { FaCheck, FaReply } from 'react-icons/fa';
import Autosuggest from 'react-autosuggest';
import { FcTreeStructure } from 'react-icons/fc';
import '../../assets/css/styles.css';
import PhotoUploader4 from '../../components/addlisting/PhotoUploader4';
import ChatPhotoGallery from './ChatPhotoGallery';
import { IoMdInformation } from 'react-icons/io';


// =========================Chat==============================

let pusher = new Pusher(PusherKey, {
    authEndpoint: `${buyerUrl}/pusher/auth`,
    cluster: "mt1"
})
var channel = pusher.subscribe('my-channel');


// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, friends) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : friends.filter(lang =>
        lang.full_name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

function getSuggestionValue(suggestion) {
    let data = suggestion;
    console.log(suggestion, 'suggestion')

    return { data };
}
// const getSuggestionValue = suggestion => suggestion.fname + suggestion.fname;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div className="suggestionClick">
        {suggestion.fname} {suggestion.lname}
    </div>
);

const Emojis = [
    { id: 1533, symbol: '❤️', tags_EN: 'orange, heart, love', tags_ES: 'corazón, naranja, amor', category: 'gente' },
    { id: 3, symbol: '😂', tags_EN: 'smile, tears, face', tags_ES: 'sonrisa, cara, llorar de risa', category: 'smileys' },
    { id: 32, symbol: '😯', tags_EN: 'face, open, mouth', tags_ES: 'cara, boca, abierta', category: 'smileys' },
    { id: 31, symbol: '😢', tags_EN: 'sad, relieved , face', tags_ES: 'cara, triste, aliviada', category: 'smileys' },
    { id: 1, symbol: '😠', tags_EN: 'smile, grinning, face', tags_ES: 'risa, cara, sonriente', category: 'smileys' },
    { id: 2, symbol: '👍', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },
    { id: 2, symbol: '👎', tags_EN: 'smile, beaming, face', tags_ES: 'sonrisa, cara, dientes', category: 'smileys' },

];

const popover = (
    <Popover id="popover-basic">
        <Popover.Content>
            <img src={DocumentAttachment} /> Share Photos and Documents.
        </Popover.Content>
    </Popover>
);

const popover1 = (
    <Popover id="popover-basic1">
        <Button
            size="sm"
            variant="outline-dark"
            className="pt-0 pb-0"
        >
            Hide Popover
        </Button>

    </Popover>
);

class BuyerMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            friends: [],
            allFriends: [],
            allChatFriends: [],
            messages: [],
            selectUser: false,
            chatUser: {},
            groupUser: {},
            chatMedia: [],
            userId: '',
            reply: '',
            replyto: '',
            loading: false,
            value: '',
            suggestions: [],
            list: [],
            collSidebar: true,
            items: [
                {
                    title: 'Group Settings',
                    desc: '',
                    plus: <BsChevronUp />,
                    minus: <BsChevronDown />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Chat Members',
                    desc: '',
                    plus: <BsChevronUp />,
                    minus: <BsChevronDown />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Gallery',
                    desc: '',
                    plus: <BsChevronUp />,
                    minus: <BsChevronDown />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Privacy & Support',
                    desc: '',
                    plus: <BsChevronUp />,
                    minus: <BsChevronDown />,
                    cardClass: 'mb-3'
                }
            ],
            menus: [
                {
                    icon: Overview,
                    title: 'Profile Overview',
                    url: '/user-profile',
                    className: ''
                },
                {
                    icon: Friends,
                    title: 'Friends',
                    url: '/user-friends',
                    className: ''
                },
                {
                    icon: MyStory,
                    title: 'My Stories',
                    url: '/user-stories',
                    className: ''
                },
                {
                    icon: Reviews,
                    title: 'Reviews',
                    url: '/user-reviews',
                    className: ''
                },
                {
                    icon: Photo,
                    title: 'Photos',
                    url: '/user-photo',
                    className: ''
                },
                {
                    icon: Messages,
                    title: 'Messages',
                    url: '/user-message',
                    className: 'active'
                },
                {
                    icon: Bookmarks,
                    title: 'Bookmarks',
                    url: '/user-bookmarks',
                    className: ''
                },
                {
                    icon: CheckIns,
                    title: 'Check-Ins',
                    url: '/user-check-in',
                    className: ''
                },
                // {
                //     icon: Following,
                //     title: 'Following',
                //     url: '/user-following',
                //     className: ''
                // },
                // {
                //     icon: Followers,
                //     title: 'Followers',
                //     url: '/user-followers',
                //     className: ''
                // },
                {
                    icon: Newsletters,
                    title: 'Newsletters',
                    url: '/user-newsletters',
                    className: ''
                },
                {
                    icon: Security,
                    title: 'Security',
                    url: '/user-security'
                }


            ],
            archive: 0,
            loadFilter: 'All Conversations',
            isFeatures: false,
            searchChat: false,
            isMouseInside: 0,
            showEmojisR: 0,
            isMouseChatInside: 0,
            MuteFrom: 'Right',
            MuteLeft: {},
            search_data: {},
            Imgage: '',
            bSMeasure: [],
            bSelectSafetyMeasure: []
        }
        this.myRef = React.createRef();
        this.getChatOtherUsers = this.getChatOtherUsers.bind(this);
        this.handler = this.handler.bind(this)
        // this.submitMessage = this.submitMessage(this);
        this.onHide = this.onHide.bind(this);
        this.selectFriend = this.selectFriend.bind(this);

        this.CreateGroup = this.CreateGroup.bind(this);
        this.ChangeGroupName = this.ChangeGroupName.bind(this);
        this.Mute = this.Mute.bind(this);
        this.Report = this.Report.bind(this);
        this.LeaveGroup = this.LeaveGroup.bind(this);
        this.Block = this.Block.bind(this);
        this.BlockUser = this.BlockUser.bind(this);
        this.RemoveMember = this.RemoveMember.bind(this);
    }

    handleBoxToggle = (id) => this.setState({ isMouseInside: id });

    handleBoxHide = (id) => this.setState({ isMouseInside: 0, showEmojisR: 0 });

    handleChatToggle = (id) => this.setState({ isMouseChatInside: id });
    handleChatBoxHide = (id) => this.setState({ isMouseChatInside: 0 });



    onHide() {
        this.setState({
            GroupModal: false, DirectModal: false, MuteModal: false,
            ChangeGroupNameModal: false, ChangeGroupPhotoModal: false,
            ReportModal: false, LeaveGroupModal: false,
            BlockMemberModal: false, RemoveMemberModal: false,
            BlockUserModal: false, GroupShareModal: false,
        });
    }


    handler(e) {
        console.log(e, 'called')
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'All', location: e.location, search_data: { search: e.search ? e.search : 'All', location: e.location } });
    }

    Star = (datam, bool) => {

        var self = this;
        let user_id = datam.id;
        let archive = bool;

        axios.post(ApiUrl + 'markAsStar', { 'user_id': user_id, 'star': archive }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    console.log(res.data, 'response');
                    datam.star = bool;
                    toast.success(res.data.message);
                    self.setState({
                        chatUser: datam
                    });
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    Archived = (datam, bool) => {

        var self = this;
        let user_id = datam.id;
        let archive = bool;

        axios.post(ApiUrl + 'markAsArchived', { 'user_id': user_id, 'archive': archive, type: datam.type }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    console.log(res.data, 'response');
                    datam.is_archived = bool;
                    toast.success(res.data.message);
                    self.setState({
                        chatUser: datam
                    });
                    self.getChatOtherUsers();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    Read = (datam, bool) => {

        var self = this;
        let user_id = datam.id;
        let read = bool;

        axios.post(ApiUrl + 'markAsUnread', { 'user_id': user_id, 'read': read, type: datam.type }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    datam.is_read = bool;
                    toast.success(res.data.message);
                    self.setState({
                        chatUser: datam
                    });
                    self.getChatOtherUsers();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    Delete = (datam, bool) => {

        var self = this;
        let user_id = datam.id;
        let remove = bool;

        axios.post(ApiUrl + 'markAsDelete', { 'user_id': user_id, 'remove': remove, type: datam.type }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    // datam.is_read = bool;
                    toast.success(res.data.message);
                    self.getChatOtherUsers();
                    self.setState({
                        selectUser: false
                    });
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    componentWillMount() {
        this.forceUpdate();
        // window.removeEventListener('beforeunload', this.beforeunload.bind(this));

        // this.pusher = new Pusher('117c7e34402ccc064b0e', {
        //     cluster: 'ap2',
        //     encrypted: true,
        // });
        // this.channel = this.pusher.subscribe('fluffy-train-399');

        // Establish a connection to Pusher.
        // this.pusher = new Pusher('117c7e34402ccc064b0e', {
        //     authEndpoint: '/broadcasting/auth',
        //     cluster: 'ap2',
        //     encrypted: true,
        //     // As I'm using JWT tokens, I need to manually set up the headers.
        //     auth: {
        //         headers: {
        //             'X-CSRF-Token': `Bearer ${uToken()}`,
        //             Accept: 'application/json',
        //         },
        //     },
        // });
        // // Subscribe to the 'private-reactchat' channel
        // this.chatRoom = this.pusher.subscribe('private-reactchat');
    }
    componentDidMount() {

        var self = this;
        // var ch = pusher.ge("my-channel")

        // console.log(ch , 'my-channel')
        // channel.bind('pusher:subscription_succeeded', function () {
        //     console.log('subscription_succeeded')
        // });

        // channel.bind('pusher:subscription_error', function () {
        //     console.log('subscription_error')
        // });

        channel.bind("chat-event", function (data) {
            var message = data;
            if (message.data.sent_to == self.state.userId) {
                console.log('Message', message.data)
                self.getChatOtherUsers();
            }

            if (message.data.sent_from == self.state.replyto && message.data.sent_to == self.state.userId) {
                self.getChat(self.state.replyto)
                console.log('Reply', message.data)
            }

            if (message.data.sent_to == self.state.userId && self.state.chatUser.type_user == 'group') {
                self.getChatOtherUsers();
                console.log('Group count', message.data)
            }

            if (self.state.chatUser.type_user == 'group') {
                self.getChat(self.state.replyto, 'group')
                console.log('Group', message.data)
            }

        });

        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })
        $('body').addClass('sb-sidenav-toggled');


        const params = new URLSearchParams(window.location.search).get('join_group')

        if (params) {
            this.JoinGroup();
        }

        // You can access specific parameters:




        // const echo = new Echo(options);
        // console.log(' this.channel', this.channel)
        // this.channel.bind('created', this.updateEvents);
        // echo.channel(`App.User.${this.state.userId}`).notification((data) => {
        //     console.log(data , 'DA.....................');
        // });
        // this.chatRoom.bind('messages', newmessage => {
        //     console.log(newmessage, 'newmessage')
        // }, this);

        // const pusher = new Pusher('117c7e34402ccc064b0e', {
        //     cluster: 'ap2',
        //     encrypted: true
        // });
        // const channel = pusher.subscribe('fluffy-train-399');

        // console.log(channel ,'channel')
        // channel.bind('message', data => {
        //    console.log(data , 'dadasdasdas')
        // });
        // this.updateEvents = this.updateEvents.bind(this);
        this.getChatOtherUsers();
        this.getChatOtherUsersSearch();
    }

    JoinGroup = () => {
        var self = this;
        let groupID = new URLSearchParams(window.location.search).get('join_group');

        axios.post(ApiUrl + 'addUserToGroup', { 'slug': groupID }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({ chatUser: res.data.list })
                    self.getChat(res.data.list.id, res.data.list.type_user);
                    self.getChatOtherUsers();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onChange = (event, { newValue }) => {
        if (newValue.data) {
            this.getUserChat(newValue.data)
            this.setState({
                value: newValue.data.fname
            });
        } else {
            this.setState({
                value: newValue
            });
        }
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.state.allFriends)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
            value: '',
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchChatRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.state.allChatFriends)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearChatRequested = () => {
        this.setState({
            suggestions: [],
            value: '',
        });
    };



    // updateEvents(data) {
    //     console.log('sdasdh')
    // }

    getUserChat(friend) {
        this.setState({ chatUser: friend })
        this.getChat(friend.id, friend.type_user);
        this.onHide();
    }

    checkStatusMessage = (e) => {
        if (e.key === 'Enter') {
            this.submitMessage();
        }
    }

    submitMessage = () => {
        let reply = this.state.reply;
        let replyTo = this.state.replyto;
        let type = this.state.chatUser.type_user;
        var self = this;

        console.log(type, 'shshss');

        // self.setState(prevState => ({
        //     messages: [...prevState.messages, data]
        // }))

        axios.post(ApiUrl + 'sendMessage', { 'send_to': replyTo, 'message': reply, 'type': type }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    if (self.state.messages.length == 0) {
                        self.getChatOtherUsers();
                    }
                    let data = {
                        fname: res.data.fname,
                        image: res.data.image,
                        when: "now",
                        message: reply,
                        sent_from: self.state.userId,
                    }
                    self.setState(prevState => ({
                        messages: [...prevState.messages, data],
                        reply: ''
                    }))
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getChat = (id, type) => {
        var self = this;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        self.setState({ messages: [], selectUser: true, replyto: id });
        console.log(type, 'assss')
        axios.post(ApiUrl + 'getUserChat', { 'other_user': id, 'timezone': timezone, 'type': type }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        messages: res.data.data,
                        selectUser: true,
                        replyto: id,
                        chatMedia: res.data.media,
                    })

                    self.getChatOtherUsers();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    getChatOtherUsersSearch = () => {
        var self = this;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        axios.post(ApiUrl + 'getChatOtherUsersSearch', { 'timezone': timezone }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        allChatFriends: res.data.chat_user,
                        allFriends: res.data.data,
                        userId: res.data.user_id
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getChatOtherUsers = (filter) => {
        var self = this;
        let fil = 'All Conversations';
        if (filter) {
            fil = filter
        }

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        axios.post(ApiUrl + 'getChatOtherUsers', { 'timezone': timezone, 'filter': fil }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        friends: res.data.data,
                        userId: res.data.user_id,
                        loadFilter: fil,
                        searchChat: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    selectFriend(e) {
        var filter = [];
        if (e) {
            e.map(fil => {
                if (fil) {
                    filter.push(fil.value);
                }
            })
            this.setState({ bSMeasure: filter, bSelectSafetyMeasure: e })
        }
    }

    CreateGroup(event, errors, values) {
        this.setState({ errors, values });
        var self = this;
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            var formData = new FormData();
            formData.append('image', self.state.uploadfImage)
            formData.append('name', values.name)
            formData.append('members', values.members)
            formData.append('description', values.description)

            let type = '';
            if (self.state.uploadfImage) {
                type = self.state.uploadfImage.type;
            }

            axios.post(ApiUrl + 'addGroup', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({
                            bSMeasure: [],
                            bSelectSafetyMeasure: [],
                            GroupModal: false,
                        });
                        self.getChatOtherUsers();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    ChangeGroupName(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'updateGroupData', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.getUserChat(self.state.chatUser)
                        self.setState({
                            ChangeGroupNameModal: false,
                        });

                        self.setState(prevState => ({
                            chatUser: {                   // object that we want to update
                                ...prevState.chatUser,    // keep all other key-value pairs
                                full_name: res.data.name       // update the value of specific key
                            }
                        }))
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    Mute(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'markAsMute', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    Report(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'reportChat', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }




    RemoveMember(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'removeUserFromGroup', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();
                        self.setState({
                            chatUser: {},
                            selectUser: false,
                        })

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }




    BlockUser(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'addBlockFriend', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();

                        self.setState(prevState => ({
                            chatUser: {},
                            selectUser: false
                        }))

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }

    Block(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'addBlockFriend', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }


    UnBlock = () => {
        var self = this;
        axios.post(ApiUrl + 'addBlockFriend', { 'to_user': self.state.chatUser.id, 'block': 0 }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState(prevState => ({
                        chatUser: {                   // object that we want to update
                            ...prevState.chatUser,    // keep all other key-value pairs
                            blocked: 0       // update the value of specific key
                        }
                    }))

                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    LeaveGroup(event, errors, values) {
        var self = this;
        self.setState({ errors, values });
        if (errors.length < 1) {
            // values.ids = this.state.bSMeasure.toString();
            axios.post(ApiUrl + 'leaveChatGroup', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    console.log(res.data, 'response here')
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.onHide();
                        self.getChatOtherUsers();
                        self.setState({
                            selectUser: false
                        })

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    }



    ChangeGroupPhoto(message) {
        console.log('he')
        if (message) {
            this.onHide();
            this.getChatOtherUsers();
            this.setState(prevState => ({
                chatUser: {                   // object that we want to update
                    ...prevState.chatUser,    // keep all other key-value pairs
                    image: message      // update the value of specific key
                }
            }))
        } else {
            this.onHide();
        }
    }

    getImage = () => {
        $('#inputImage').trigger('click');
        console.log('hi')
    }

    getImage1 = () => {
        $('#file-input1').trigger('click');
    }

    handleText = (e) => {

        let text = e.target.value;
        let lastChar = text[text.length - 1];

        if (lastChar === ' ') {

            let array = text.split(' ');
            let lastWord = array[array.length - 2].toLowerCase();
            let emojis = Emojis.filter(emoji => emoji.tags_ES.indexOf(lastWord) !== -1 || emoji.tags_EN.indexOf(lastWord) !== -1).slice(0, 50);

            this.setState({
                emojis: emojis
            })
            // setEmojis(emojis);

        }
        this.setState({
            reply: text,
            text: text
        })

        // setComment(text);
        // setText(text);  

    }

    handleEmoji = (emoji) => {

        let text = this.state.reply + emoji;

        console.log(text, 'et')
        this.setState({
            reply: text,
            text: text
        })

    }

    handleChatEmojiReaction = (emoji, i) => {

        var index = this.state.messages.findIndex(x => x.id === this.state.isMouseInside);
        var self = this;
        axios.post(ApiUrl + 'addChatReaction', { 'chat_id': this.state.isMouseInside, 'action': emoji }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        messages: [
                            ...self.state.messages.slice(0, index),
                            Object.assign({}, self.state.messages[index], res.data.data),
                            ...self.state.messages.slice(index + 1)
                        ]
                    });
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                toast.error('Try again');
                console.log(error);
            });

    }

    uploadfImage1 = (file) => {
        let reply = '';
        let replyTo = this.state.replyto;
        let type1 = this.state.chatUser.type_user;
        var self = this;

        console.log(type, 'shshss');
        // axios.post(ApiUrl + 'sendMessage', { 'send_to': replyTo, 'message': reply, 'type': type }, {

        // self.setState(prevState => ({
        //     messages: [...prevState.messages, data]
        // }))

        if (!this.state.uploadfImage1) {
            var formData = new FormData();
            formData.append('send_to', replyTo)
            formData.append('message', reply)
            formData.append('type', type1)
            formData.append('image', file)
            var type = file.type;
            axios.post(ApiUrl + 'addChatMedia', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        if (self.state.messages.length == 0) {
                            self.getChatOtherUsers();
                        }

                        self.getUserChat(self.state.chatUser)

                        self.setState({
                            uploadfImage1: false,
                        })
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    toast.error('Try again');
                    console.log(error);
                });
        }

    }

    SearhChat = (search) => {
        var text = search.target.value;
        $(".chat-search").highlite({
            text: text
        });
    }

    CloseSearhChat = () => {
        $('.chat-search li span.highlight').removeClass('highlight');
        this.setState({
            searchChat: false
        })
    }





    SearhChatUP = () => {
        if ($('.current').next('li.chat-message').length > 0) {

            var $next = $('.current').next('.chat-message');
            var top = $next.offset().top;
            $('.current').removeClass('current');
            $next.addClass('current');
        }
        $(".chat-search li.current")[0].scrollIntoView({ top: top, block: 'end', behavior: 'smooth' });

    }
    SearhChatDown = () => {
        if ($('li.current').prev('li.chat-message').length > 0) {

            var $next = $('li.current').prev('.chat-message');
            var top = $next.offset().top;
            console.log(top, 'yop')

            $('.current').removeClass('current');
            $next.addClass('current');



        }
        $(".chat-search li.current")[0].scrollIntoView({ top: top, block: 'end', behavior: 'smooth' });

    }


    // handle copy to clipboard
    copyToClipboard = e => {
        let text = document.getElementById('holdtext');
        text.select();
        // copy the text in the textarea
        try {
            let status = document.execCommand("Copy");
            if (!status) {
                console.log('Cannot copy text');
            } else {
                // toastr.success('Copied');
                this.setState({ btnText: 'Copied!' });
            }
        } catch (err) {
            // toastr.error('Could not copy');
            // handle error
        }
    }


    render() {
        var self = this;

        if (self.state.isSignedUp) {
            // redirect to main listing page if user again search
            // return <Redirect to={{ pathname: "/listing", state: this.state.search_data }} />;
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state.search_data }} />;

        }

        $('#inputImage').on('change', function () {
            console.log('hi')
            if (this.files.length > 0) {
                self.setState({
                    uploadfImage: this.files[0],
                    Imgage: 'true'
                })
            }
        });

        $('#file-input1').on('change', function () {
            console.log('this.files')
            if (this.files.length > 0) {
                self.uploadfImage1(this.files[0]);
                self.setState({
                    uploadfImage1: this.files[0],
                })
            }
        });


        let options = [];

        this.state.allFriends.map((friend, item) => {
            let obj = { label: friend.full_name, value: friend.id }
            options.push(obj)
        })

        const { value, suggestions } = this.state;
        $('.search__label-init').on('click', function () {
            $('.search').addClass('open_search');
        })

        $('.search__label-active').on('click', function () {
            self.setState({ value: '' })
            $('.search').removeClass('open_search');
        })


        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search Chats',
            value,
            onChange: this.onChange,
            className: 'search__input',
        };

        const inputProps1 = {
            placeholder: 'Search contacts by name',
            value,
            onChange: this.onChange,
            className: 'search__input1',
        };

        $('#sidebarToggle').on('click', function (e) {
            $('body').addClass('sb-sidenav-toggled');
            $('#sidebarToggle').addClass('none');
            $('#sidebarHideToggle').removeClass('none');
        });

        $('#sidebarHideToggle').on('click', function (e) {
            $('body').removeClass('sb-sidenav-toggled');
            $('#sidebarToggle').removeClass('none');
            $('#sidebarHideToggle').addClass('none');
        });



        // $(".he #search").on("search change keyup", function () {


        // });


        // $('#scrollup, #scrolldown').click(function (e) {
        //     e.preventDefault();
        //     var id = e.target.id;
        //     if (id == 'scrollup') {
        //         $('li:first').appendTo('ul');
        //     } else {
        //         $('li:last').prependTo('ul');
        //     }
        // });


        let url = '';

        return (
            <div className="d-flex" id="wrapper">

                <div id="page-content-wrapper">

                    <main className="dashboard-page BuyerProfile BuyerReviews BookMarks chat-page-style">
                        {/* Header */}
                        <NavbarListing handler={this.handler} />
                        {/* <GeneralHeader /> */}
                        {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                        {/* <div className="user-info">
                    <UserInfo />
                </div> */}
                        <Container fluid>
                            {/* <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebar />
                        </div> */}
                            <Row>
                                <div className="collaps-sidebar">
                                    <div className="fix-sidebar fix-sidebar-top">
                                        <SideNav
                                            onSelect={(selected) => {
                                                // Add your code here
                                            }}
                                        >
                                            <SideNav.Toggle />
                                            <SideNav.Nav defaultSelected="home">
                                                {this.state.menus.map((menu, i) => {
                                                    return (
                                                        <>
                                                            <NavItem eventKey={menu.title}>
                                                                <NavIcon>
                                                                    <OverlayTrigger
                                                                        key={'right'}
                                                                        placement={'right'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-right`}>
                                                                                <strong>{menu.title}</strong>
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Link to={menu.url} className={menu.className}>
                                                                            <div className="icon">
                                                                                <img src={menu.icon} alt="Icon" className="card__img" />
                                                                            </div>
                                                                        </Link>
                                                                    </OverlayTrigger>


                                                                </NavIcon>
                                                                <NavText><Link to={menu.url} >{menu.title}</Link></NavText>
                                                            </NavItem>
                                                        </>
                                                    );
                                                })}
                                            </SideNav.Nav>
                                        </SideNav>
                                    </div>
                                </div>
                            </Row>
                            <div className="BuyerContent">
                                <div className="y-page-link reviews">
                                    {/* <h4>Messages</h4> */}
                                </div>
                                <Container fluid>
                                    <Modal className="direct-modal" show={this.state.DirectModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> New Direct Message </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <>
                                                <div className="find-user-direct">
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps1}
                                                    />
                                                </div>

                                                <div className="horizontal-line"></div>

                                                <div className="users">
                                                    <Scrollbar alwaysShowTracks={true}>
                                                        <MDBListGroup className="users-list" style={{ maxHeight: '570px' }}>
                                                            {(this.state.messages) ?
                                                                this.state.allFriends.map(friend => {
                                                                    return (
                                                                        <>
                                                                            <MDBListGroupItem
                                                                                className={`d-flex`}
                                                                                onClick={(e) => this.getUserChat(friend)}
                                                                            >
                                                                                <img
                                                                                    // tag="img"
                                                                                    src={friend.image}
                                                                                    alt="avatar"

                                                                                    className="mr-2 z-depth-1 rounded-circle img-fluid"
                                                                                />
                                                                                <div className="user-name-chat" style={{ fontSize: "0.95rem" }}>
                                                                                    <strong>{friend.full_name}</strong>

                                                                                </div>

                                                                            </MDBListGroupItem>
                                                                        </>
                                                                    )

                                                                }) : ""}
                                                        </MDBListGroup>
                                                    </Scrollbar>
                                                </div>
                                            </>
                                        </Modal.Body>
                                    </Modal>


                                    <Modal className="direct-modal" show={this.state.ChangeGroupNameModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Change Chat Name </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.ChangeGroupName} className="create-group">
                                            <Modal.Body className="p-3">
                                                <>
                                                    <AvGroup>
                                                        <label>Changing the name of the group chat changes it for everyone.</label>
                                                        <AvInput
                                                            placeholder=""
                                                            className="form-control"
                                                            name="name"
                                                            required />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="id"
                                                        value={this.state.chatUser.id}
                                                        required />
                                                    <AvGroup>
                                                        <label>Change Group Topic</label>
                                                        <AvInput
                                                            placeholder=""
                                                            className="form-control"
                                                            name="description"

                                                        />
                                                    </AvGroup>


                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group">
                                                <Button type="button" onClick={() => this.setState({ ChangeGroupNameModal: false })} variant="secondary" >Cancel</Button>
                                                <Button type="submit" variant="primary" >Save</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>



                                    <Modal className="direct-modal" show={this.state.MuteModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Mute conversation </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.Mute} className="create-group mute">
                                            <Modal.Body className="p-3">
                                                <>
                                                    <AvGroup>

                                                        <AvRadioGroup value="For 30 minutes" name="mute" label="" required>
                                                            <AvRadio customInput label="For 30 minutes" value="For 30 minutes" />
                                                            <AvRadio customInput label="For 1 Hour" value="For 1 Hour" />
                                                            <AvRadio customInput label="For 8 Hours" value="For 8 Hours" />
                                                            <AvRadio customInput label="For 24 Hours" value="For 24 Hours" />
                                                            <AvRadio customInput label="Forver" value="Forver" />

                                                        </AvRadioGroup>
                                                    </AvGroup>

                                                    {this.state.MuteFrom == 'Right' ?
                                                        <>
                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="user_id"
                                                                value={this.state.chatUser.id}
                                                                required />

                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="type"
                                                                value={this.state.Mtype}
                                                                required />
                                                        </>
                                                        :
                                                        <>
                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="user_id"
                                                                value={this.state.MuteLeft.id}
                                                                required />

                                                            <AvInput
                                                                type="hidden"
                                                                className="form-control"
                                                                name="type"
                                                                value={this.state.Mtype}
                                                                required />
                                                        </>
                                                    }


                                                    <p className="note">Chat window will stay closed, and you won’t get push notifications on yourphone.</p>






                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group mute">
                                                <Button type="button" onClick={() => this.setState({ MuteModal: false })} variant="secondary" >Cancel</Button>
                                                <Button type="submit" variant="primary" >Mute</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>

                                    <Modal className="direct-modal" show={this.state.ReportModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Report </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.Report} className="create-group report">
                                            <Modal.Body className="p-3">
                                                <>

                                                    <h4 className="heading">Let us know what’s going on</h4>
                                                    <p className="sub-heading">We use your feedback to help us learn when something’s not right. </p>
                                                    <AvGroup>

                                                        <AvRadioGroup name="message" label="" required>
                                                            <AvRadio customInput label="Harassment" value="Harassment" />
                                                            <AvRadio customInput label="Suicide or Self-Injury" value="Suicide or Self-Injury" />
                                                            <AvRadio customInput label="Sharing Inappropriate Things" value="Sharing Inappropriate Things" />
                                                            <AvRadio customInput label="Hate Speech" value="Hate Speech" />
                                                            <AvRadio customInput label="Unauthorized Sales" value="Unauthorized Sales" />
                                                            <AvRadio customInput label="Other" value="Other" />

                                                        </AvRadioGroup>
                                                    </AvGroup>


                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="id"
                                                        value={this.state.chatUser.id}
                                                        required />

                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="type"
                                                        value={this.state.Mtype}
                                                        required />
                                                    <div className="WarningMessage">
                                                        <Row>
                                                            <Col lg={1}><div className="w-icon"><img src={Warning} /></div></Col>
                                                            <Col lg={11}> <p className="note one"> {this.state.chatUser.full_name}, if someone is in immediate danger, call local emergency services. Don’t wait. </p></Col>
                                                        </Row>
                                                    </div>







                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group">
                                                <Button type="submit" variant="primary" >Send Feedback</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>
                                    <Modal className="direct-modal" show={this.state.BlockMemberModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Block {this.state.groupUser.full_name} ? </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.Block} className="create-group block">
                                            <Modal.Body className="p-3">
                                                <>
                                                    <h4 className="heading">Block all Messages</h4>
                                                    <p className="sub-heading">Your Katika account won’t receive messages from {this.state.groupUser.full_name} account.</p>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="to_user"
                                                        value={this.state.groupUser.id}
                                                        required />
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="block"
                                                        value={1}
                                                        required />
                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group">
                                                <Button type="submit" variant="primary" >Block</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>


                                    <Modal className="direct-modal" show={this.state.BlockUserModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Block {this.state.groupUser.full_name} ? </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.BlockUser} className="create-group block">
                                            <Modal.Body className="p-3">
                                                <>
                                                    <h4 className="heading">Block all Messages</h4>
                                                    <p className="sub-heading">Your Katika account won’t receive messages from {this.state.groupUser.full_name} account.</p>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="to_user"
                                                        value={this.state.groupUser.id}
                                                        required />
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="block"
                                                        value={1}
                                                        required />
                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group">
                                                <Button type="submit" variant="primary" >Block</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>

                                    <Modal className="direct-modal" show={this.state.RemoveMemberModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Remove From Chat? </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.RemoveMember} className="create-group block">
                                            <Modal.Body className="p-3">
                                                <>
                                                    <p className="sub-heading">Are you sure you want to remove this person from the conversation? They will no longer be able to send or receive new messages.</p>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="id"
                                                        value={this.state.chatUser.id}
                                                        required />

                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="user_id"
                                                        value={this.state.groupUser.id}
                                                        required />
                                                </>
                                            </Modal.Body>
                                            <Modal.Footer className="group mute">
                                                <Button type="button" onClick={() => this.setState({ RemoveMemberModal: false })} variant="secondary" >Cancel</Button>
                                                <Button type="submit" variant="primary" >Remove From Chat</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>


                                    <Modal className="direct-modal" show={this.state.LeaveGroupModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Leave Group Chat? </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.LeaveGroup} className="create-group">
                                            <Modal.Body className="p-3">
                                                <>

                                                    <h6 className="heading">You will stop receiving messages from
                                                        this conversation and people will see that you left.</h6>
                                                    <AvInput
                                                        type="hidden"
                                                        className="form-control"
                                                        name="id"
                                                        value={this.state.chatUser.id}
                                                        required />


                                                </>
                                            </Modal.Body>
                                            <Modal.Footer >
                                                <Button type="button" onClick={() => this.setState({ LeaveGroupModal: false })} variant="secondary" >Cancel</Button>
                                                <Button type="submit" variant="primary" >Leave Group</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>


                                    <Modal className="direct-modal" show={this.state.ChangeGroupPhotoModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Change Photo </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-3">
                                        </Modal.Body>
                                        <>
                                            <PhotoUploader4 closeModal={this.ChangeGroupPhoto.bind(this)} id={this.state.chatUser.id} />
                                        </>

                                    </Modal>

                                    <Modal className="direct-modal" show={this.state.GroupShareModal} onHide={this.onHide} size="md" >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Group Sharing</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-3">
                                            <div className="manual-link group-link">
                                                <div className="form-group share-link group-link">
                                                    <input id="holdtext" readOnly type="url" className="form-control" value={`${buyerUrl}/user-message?join_group=${this.state.chatUser.slug}`} />
                                                    <span onClick={this.copyToClipboard}><MdContentCopy /> </span>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>


                                    </Modal>

                                    <Modal className="create-group-modal" show={this.state.GroupModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title> Create Group </Modal.Title>
                                        </Modal.Header>
                                        <AvForm onSubmit={this.CreateGroup} className="create-group">
                                            <Modal.Body>
                                                <>


                                                    <div className="group-photo"  >


                                                        <FaUsers />
                                                        <button type="button" onClick={() => this.getImage()} className="btn btn-default add-group-photo">Set Group Avatar</button>

                                                        <div className="UpdatePicture">
                                                            <BsPersonFill />
                                                        </div>
                                                    </div>



                                                    <AvGroup>
                                                        <AvInput
                                                            type="hidden"
                                                            value={this.state.Imgage}
                                                            id="file-input"
                                                            placeholder="Enter group name"
                                                            className="form-control none"
                                                            name="profiley"
                                                            required />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>

                                                    <AvGroup>
                                                        <AvInput
                                                            placeholder="Enter group name"
                                                            className="form-control"
                                                            name="name"
                                                            required />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>


                                                    <AvGroup>
                                                        <Select placeholder='Add friend to group'
                                                            options={options}
                                                            isMulti
                                                            name="colors"
                                                            className="basic-multi-select mt-3 mb-3"
                                                            classNamePrefix="select"
                                                            defaultValue={this.state.bSelectSafetyMeasure}
                                                            value={this.state.bSelectSafetyMeasure}
                                                            onChange={this.selectFriend}
                                                        />
                                                        <AvInput
                                                            type="hidden"
                                                            placeholder="Enter group name"
                                                            className="form-control"
                                                            name="members"
                                                            value={this.state.bSMeasure ? this.state.bSMeasure.toString() : ""}
                                                            required />
                                                        <AvFeedback>Required Field *</AvFeedback>
                                                    </AvGroup>


                                                    <AvGroup>
                                                        <AvInput
                                                            type="textarea"
                                                            placeholder="Enter Group Topic (optional)"
                                                            className="form-control"
                                                            name="description"
                                                        />
                                                    </AvGroup>


                                                </>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button type="submit" variant="primary" >Create Group</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </Modal>


                                    <Row>
                                        <input id="inputImage" name="profile1" type="file" accept=".gif, .jpg, .png," className="none" />

                                        <div className="col-md-11 col-lg-11 pl-0">
                                            <main className="chat-module">
                                                <MDBCard className="grey lighten-3 chat-room">
                                                    <MDBCardBody className="no-padding">
                                                        <MDBRow>

                                                            <MDBCol md="3" xl="3" sm="12" className="px-0 mb-2 mb-md-0">
                                                                <div className="white z-depth-1 p-3">

                                                                    {this.state.loadFilter == 'All Conversations' ?
                                                                        <div className="auto-search">
                                                                            <div className="caht-option-area">
                                                                                <div className="Chat">
                                                                                    <p>Chat</p>
                                                                                </div>
                                                                                <div className="chat-option">
                                                                                    <div className="filter">
                                                                                        <Dropdown variant="none">
                                                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                                <img src={iconMore} alt="user" />
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>


                                                                                                <ul className="OptionMenu">
                                                                                                    <li onClick={() => this.getChatOtherUsers('People')}><img src={userOnline} /> <button>Active Contacts</button> </li>
                                                                                                    <li onClick={() => this.getChatOtherUsers('Archived')}><img src={userProfile} /> <button>Archived Chats </button> </li>
                                                                                                    <li onClick={() => window.location.href = '/help'}><img src={help} /> <button>Help </button> </li>

                                                                                                </ul>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                    <div className="new-chat">

                                                                                        <Dropdown variant="none">
                                                                                            <Dropdown.Toggle variant="none" id="dropdown-basic1">
                                                                                                <img src={newTab} />
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <ul className="OptionMenu">
                                                                                                    <li onClick={() => this.setState({ DirectModal: true })} ><BsPersonFill /> <button>Start Direct Message </button> </li>
                                                                                                    <li onClick={() => this.setState({ GroupModal: true })}><FaUsers /> <button>Start New Group </button> </li>

                                                                                                </ul>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>


                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="chat-search-area">
                                                                                <span></span>
                                                                                <Autosuggest
                                                                                    suggestions={suggestions}
                                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchChatRequested}
                                                                                    onSuggestionsClearRequested={this.onSuggestionsClearChatRequested}
                                                                                    getSuggestionValue={getSuggestionValue}
                                                                                    renderSuggestion={renderSuggestion}
                                                                                    inputProps={inputProps}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        : this.state.loadFilter == 'Archived' ?
                                                                            <div className="auto-search back-arrow archived">
                                                                                <div className="caht-option-area arrow archived">
                                                                                    <div className="Chat chat-arrow-filter archived">
                                                                                        <button className="chat-back-arrow archived" onClick={() => this.getChatOtherUsers('All Conversations')}><BiLeftArrowAlt /></button> <p>Archived  Chats</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="auto-search people back-arrow">
                                                                                <div className="caht-option-area arrow people">
                                                                                    <div className="Chat chat-arrow-filter people">
                                                                                        <button className="chat-back-arrow people" onClick={() => this.getChatOtherUsers('All Conversations')}><BiLeftArrowAlt /></button> <p>People</p>
                                                                                    </div>
                                                                                    <p className="text-muted active-people"> Active contacts ({this.state.friends.length})</p>
                                                                                </div>
                                                                            </div>
                                                                    }





                                                                    <Scrollbar alwaysShowTracks={true}>
                                                                        <MDBListGroup className="friend-list" style={{ maxHeight: '570px' }} onMouseLeave={() => this.handleChatBoxHide(0)}>
                                                                            {(this.state.messages) ?
                                                                                this.state.friends.map(friend => {
                                                                                    return (
                                                                                        <div className="left-bar-chat">

                                                                                            <MDBListGroupItem
                                                                                                className={`d-flex justify-content-between p-2 border-light btn
                                                                                            ${this.state.chatUser.id == friend.id ? "active" : ""}`}
                                                                                                style={{ backgroundColor: friend.active ? "#eeeeee" : "" }}
                                                                                                onClick={(e) => this.getUserChat(friend)}
                                                                                                onMouseEnter={() => this.handleChatToggle(friend.id)}
                                                                                            >


                                                                                                <div className="left-sidebar-chat">
                                                                                                    <img
                                                                                                        // tag="img"
                                                                                                        src={friend.image}
                                                                                                        alt="avatar"

                                                                                                        className="mr-2 z-depth-1 rounded-circle img-fluid"
                                                                                                    />
                                                                                                    {
                                                                                                        friend.is_people == 1 ?

                                                                                                            <span className={`user-onlie-status ${friend.user_online_status}`}></span>
                                                                                                            : ""
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="user-name-chat" style={{ fontSize: "0.95rem" }}>
                                                                                                    {
                                                                                                        friend.toRespond ? (
                                                                                                            <strong>{friend.full_name}</strong>
                                                                                                        )
                                                                                                            :
                                                                                                            friend.full_name

                                                                                                    }

                                                                                                    <p className="text-muted">{friend.message}</p>
                                                                                                </div>
                                                                                                <div className="user-chat-meta">
                                                                                                    <p style={{ fontSize: "0.75rem" }}>
                                                                                                        {friend.when}
                                                                                                    </p>
                                                                                                    {friend.star == 0 ? (
                                                                                                        <></>
                                                                                                        // <span className="text-muted float-right">
                                                                                                        //     <AiOutlineStar className="fa-check" aria-hidden="true" />
                                                                                                        //      <MDBIcon className="fa-check" aria-hidden="true" /> 
                                                                                                        // </span>
                                                                                                    ) :
                                                                                                        <MDBBadge className="float-right">
                                                                                                            <AiFillStar className="fa-check" aria-hidden="true" />
                                                                                                        </MDBBadge>
                                                                                                    }
                                                                                                    {
                                                                                                        friend.toRespond ? (
                                                                                                            <MDBBadge color="success" className="float-right">
                                                                                                                {friend.toRespond}
                                                                                                            </MDBBadge>
                                                                                                        )
                                                                                                            :
                                                                                                            ""

                                                                                                    }



                                                                                                </div>
                                                                                            </MDBListGroupItem>
                                                                                            <div className={`chat-meta-button ${(this.state.isMouseChatInside == friend.id) ? " d-block" : "d-none "}`}>

                                                                                                <Dropdown variant="none">
                                                                                                    <Dropdown.Toggle variant="none" id={`dropdown-basic` + friend.id}>
                                                                                                        <img src={iconMore} alt="user" />
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>





                                                                                                        <ul className="User-Chat-Button">
                                                                                                            <li onClick={() => this.Read(friend, 1)} ><img src={Tick} /> <button className="user-control">Mark as Unread </button> </li>
                                                                                                            <li onClick={() => this.setState({ MuteModal: true, Mtype: friend.type, MuteFrom: 'Left', MuteLeft: friend })}><img src={mute} /> <button className="user-control">Mute conversation </button> </li>
                                                                                                            <li onClick={() => this.Archived(friend, 1)} ><img src={archive} /> <button className="user-control">Archive chat </button> </li>
                                                                                                            <li onClick={() => this.Delete(friend, 1)}><img src={bin} /> <button className="user-control">Delete chat </button> </li>

                                                                                                        </ul>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    )

                                                                                }) : ""}
                                                                        </MDBListGroup>
                                                                    </Scrollbar>
                                                                </div>

                                                            </MDBCol>

                                                            {(this.state.selectUser) ?
                                                                <MDBCol md="9" xl="9" sm="12" className="" id="style-1" >
                                                                    <div className="app">
                                                                        <div className="he" style={{ width: '100%', position: 'relative' }}>
                                                                            <div className="chat-user-info">

                                                                                <div className="user-pic">

                                                                                    <img className="user-avtar" src={this.state.chatUser.image} alt="author" />

                                                                                    {/* <span></span> */}
                                                                                    <h4>{this.state.chatUser.full_name} </h4>

                                                                                    <div className="toggle-button">
                                                                                        <button className="btn btn-none none" id="sidebarToggle">
                                                                                            <span className="info-icon">
                                                                                                <IoMdInformation />
                                                                                            </span>
                                                                                            {/* <img src={InformationCircle} /> */}
                                                                                        </button>
                                                                                        <button className="btn btn-none" id="sidebarHideToggle"> <span className="info-icon">
                                                                                            <IoMdInformation />
                                                                                        </span></button>

                                                                                    </div>

                                                                                </div>
                                                                                {/* <div className="online-time">
                                                                                    <p>Local time {this.state.chatUser.local_time}</p>
                                                                                </div> */}



                                                                            </div>
                                                                            {this.state.searchChat ?
                                                                                <div className="user-chat-search">
                                                                                    <div className="search-bar">
                                                                                        <div className="search-icon">
                                                                                            <span></span>
                                                                                        </div>
                                                                                        <input type="search" className="form-control" onChange={(e) => this.SearhChat(e)} id="search" placeholder="Search" />
                                                                                    </div>
                                                                                    <div className="search-button">
                                                                                        <div className="prev">
                                                                                            <button className="pagnering_number" onClick={() => this.SearhChatDown()}><BiChevronUp /></button>
                                                                                        </div>
                                                                                        <div className="next">
                                                                                            <button className="pagnering_number-prev" onClick={() => this.SearhChatUP()} > <BiChevronDown /></button>
                                                                                        </div>

                                                                                        <div className="close1">
                                                                                            <button onClick={() => this.CloseSearhChat()} className="">close</button>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                                : ""}
                                                                            <ScrollToBottom className="full-width">




                                                                                {(this.state.messages.length > 0) ?

                                                                                    <MDBListGroup className="list-unstyled pl-12 chat-search" style={{ maxHeight: '485px' }}>
                                                                                        {this.state.messages.map((message, i) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <li className={`chat-message mb-12 ${message.sent_from == this.state.userId ? 'reply' : ''} ${i == 0 ? 'last' : i == this.state.messages.length - 1 ? 'current' : 'between'} `}>

                                                                                                        <small className="d-block text-center text-muted">
                                                                                                            {message.when}

                                                                                                        </small>

                                                                                                        <div className="d-block">
                                                                                                            <img
                                                                                                                // tag="img"
                                                                                                                src={message.image}
                                                                                                                alt="avatar"
                                                                                                                // circle
                                                                                                                className="mx-2 z-depth-1 rounded-circle img-fluid"
                                                                                                            />

                                                                                                            <div className="message-time">
                                                                                                                <div className="name-user"><strong className="primary-font">{message.fname}</strong></div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <hr /> */}


                                                                                                        <div className={`ShowHover ${(this.state.isMouseInside == message.id) ? " d-block" : "d-none "}`} onMouseEnter={() => this.handleBoxToggle(message.id)} >
                                                                                                            <div className={this.state.showEmojisR ? 'Emoji-Off' : 'Emoji-On'} onClick={() => this.setState({ showEmojisR: message.id })}>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>

                                                                                                            </div>



                                                                                                        </div>

                                                                                                        <div className="h-emoji">

                                                                                                            <div className={`Emoji-Grid ${this.state.showEmojisR == message.id ? 'd-block' : 'd-none'}`}>
                                                                                                                {Emojis.map((value, key) => <span key={key} onClick={() => this.handleChatEmojiReaction(value.symbol, i)}>{value.symbol}</span>)}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="chat-message-area">
                                                                                                            {
                                                                                                                message.chat_image == null ?

                                                                                                                    message.is_url != 1 ?
                                                                                                                        <p className={`mouseOver message mb-0 ${message.sent_from == this.state.userId ? 'reply' : 'receive'}`}
                                                                                                                            onMouseEnter={() => this.handleBoxToggle(message.id)} onMouseLeave={() => this.handleBoxHide(message.id)}>
                                                                                                                            {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium */}
                                                                                                                            {message.message}
                                                                                                                        </p>

                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                url = JSON.parse(message.message),
                                                                                                                                <div className="link-preview">
                                                                                                                                    <a target="_blank" href={url.url.target_url}>
                                                                                                                                        <Card>
                                                                                                                                            <Card.Img variant="top" src={url.url.logo} />
                                                                                                                                            <Card.Body>
                                                                                                                                                <Card.Title>{url.url.name}</Card.Title>
                                                                                                                                                <Card.Text>
                                                                                                                                                    {url.url.category}
                                                                                                                                                </Card.Text>
                                                                                                                                            </Card.Body>
                                                                                                                                        </Card>
                                                                                                                                    </a>
                                                                                                                                </div>

                                                                                                                            }

                                                                                                                        </>

                                                                                                                    :
                                                                                                                    // <portal id="exampleportal" src={`${message.chat_image}`}></portal>

                                                                                                                    <div className="link-preview">
                                                                                                                        <a target="_blank" href={message.chat_image}>
                                                                                                                            <Card>
                                                                                                                                <Card.Img variant="top" src={message.chat_image} />
                                                                                                                            </Card>
                                                                                                                        </a>
                                                                                                                    </div>

                                                                                                                // <a target="_blank" href={message.chat_image} className="chatMedia">
                                                                                                                //     <img className="img-thumbnail" src={message.chat_image} />
                                                                                                                // </a>
                                                                                                            }



                                                                                                            {message.reactions && message.reactions.length > 0 ?

                                                                                                                <div className={`reactionId${message.id}`}>
                                                                                                                    <div className={`reaction-count`}>
                                                                                                                        {
                                                                                                                            message.reactions.map((reaction, i) => {
                                                                                                                                return (
                                                                                                                                    <OverlayTrigger
                                                                                                                                        key='top'
                                                                                                                                        placement='top'
                                                                                                                                        overlay={
                                                                                                                                            <Tooltip id={`tooltip-top`}>
                                                                                                                                                {reaction.names.map((name, k) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <span> {name} </span><br />
                                                                                                                                                        </>
                                                                                                                                                    );
                                                                                                                                                })}
                                                                                                                                            </Tooltip>
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <span>{reaction.action} {reaction.total}</span>

                                                                                                                                    </OverlayTrigger>
                                                                                                                                );

                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : ""
                                                                                                            }


                                                                                                        </div>



                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        })}

                                                                                    </MDBListGroup>

                                                                                    :
                                                                                    <div className="chat-area">
                                                                                        <div className="chat-empty">
                                                                                            <img src={ChatIcon} alt="Blog" className="card__img" />
                                                                                            <h5>Select a conversation</h5>
                                                                                            <p>Try selecting a conversation or searching for someone specific.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </ScrollToBottom>
                                                                            <div className="form-group basic-textarea">
                                                                                {this.state.chatUser.blocked == 0 ?
                                                                                    <>
                                                                                        <div className={this.state.showEmojis ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showEmojis: !this.state.showEmojis })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>

                                                                                        </div>
                                                                                        <input className="form-control pl-2 my-0" id="exampleFormControlTextarea2"
                                                                                            placeholder="Type your message here..."
                                                                                            rows="3" autoComplete="off" onChange={(e) => this.handleText(e)} value={this.state.reply}
                                                                                            onKeyUp={(e) => this.checkStatusMessage(e)}
                                                                                        ></input>

                                                                                        <input id="file-input1" name="profile1" type="file" accept=".gif, .jpg, .png, .doc" className="none" />
                                                                                        <OverlayTrigger trigger={["hover", "hover"]} placement="top" overlay={popover}>
                                                                                            <div className={'file-upload-image'} onClick={() => this.getImage1()}  >
                                                                                                <BsPlus />
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                        {/* 
                                                                                    <Button
                                                                                        color="info"
                                                                                        // rounded
                                                                                        size="sm"
                                                                                        className="float-right mt-4 Emoji-Submit"
                                                                                        onClick={() => this.submitMessage()}
                                                                                    >
                                                                                        Send</Button> */}
                                                                                        <div className='Emoji'>
                                                                                            {this.state.showEmojis
                                                                                                ? <div className='Emoji-Grid'>
                                                                                                    {Emojis.map((value, key) => <span key={key} onClick={() => this.handleEmoji(value.symbol)}>{value.symbol}</span>)}
                                                                                                </div>
                                                                                                : null
                                                                                            }


                                                                                        </div>

                                                                                    </>



                                                                                    :
                                                                                    <Button
                                                                                        color="info"
                                                                                        // rounded
                                                                                        size="sm"
                                                                                        className="mt-4"
                                                                                        onClick={() => this.UnBlock()}
                                                                                    >
                                                                                        Click here to Unblock user</Button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="border-start bg-white" id="sidebar-wrapper">
                                                                            <div className="group-info-1">
                                                                                <div className="author-bio text-align-center mb-1 author-bio-img">
                                                                                    <div className="align-items-center">
                                                                                        <img src={this.state.chatUser.image} alt="author" />
                                                                                        <div className="author-inner-bio">
                                                                                            <h4 className="author__title font-weight-bold pt-2 pb-0 mb-1">
                                                                                                {this.state.chatUser.full_name}
                                                                                            </h4>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="section-block-2 mb-3"></div>
                                                                                <div className="info-list">
                                                                                    <Accordion className="accordion accordion-item" allowZeroExpanded={true} id="accordionExample1">
                                                                                        {
                                                                                            this.state.chatUser.type_user == 'group' ?
                                                                                                <>
                                                                                                    <div className={'card'} key={0}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Group Settings
                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body">
                                                                                                                    <ul className="group-setting group-participant">
                                                                                                                        <li><span className="img-group"><FaUsers /> </span > <p onClick={() => this.setState({ ChangeGroupNameModal: true })}>Change Chat Name</p> </li>
                                                                                                                        <li><span className="img-group"><MdImage /> </span > <p onClick={() => this.setState({ ChangeGroupPhotoModal: true })}>Change Photo</p> </li>
                                                                                                                        <li><span className="img-group"><FaRegCopy /> </span > <p onClick={() => this.setState({ GroupShareModal: true })}>Group Sharing</p> </li>
                                                                                                                        <li><span className="img-group"><BiSearch /> </span> <p onClick={() => this.setState({ searchChat: true })}> Search in Conversation</p> </li>


                                                                                                                    </ul>

                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>
                                                                                                    <div className={'card '} key={1}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Chat Members
                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body8">
                                                                                                                    <ul className="social-profile group-participant member">
                                                                                                                        {
                                                                                                                            this.state.chatUser.group_users.map((user, i) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <li id={i}>
                                                                                                                                            <span className="img-group float-left">
                                                                                                                                                <img src={user.image} />
                                                                                                                                            </span >
                                                                                                                                            <span className="user-name float-left">{user.full_name}</span>
                                                                                                                                            <span className="more float-right">

                                                                                                                                                <Dropdown variant="none">
                                                                                                                                                    <Dropdown.Toggle variant="success" id={`dropdown-basic` + i + user.id}>
                                                                                                                                                        <img src={iconMore} alt="user" />
                                                                                                                                                    </Dropdown.Toggle>
                                                                                                                                                    <Dropdown.Menu>


                                                                                                                                                        <ul className="OptionMenu">
                                                                                                                                                            <li onClick={(e) => this.getUserChat(user)}> <button>Message</button> </li>
                                                                                                                                                            <li> <button>View Profile </button> </li>
                                                                                                                                                            <li><button onClick={() => this.setState({ groupUser: user, BlockMemberModal: true })}>Block </button> </li>
                                                                                                                                                            <li><button onClick={() => this.setState({ groupUser: user, RemoveMemberModal: true })}>Remove Member </button> </li>

                                                                                                                                                        </ul>
                                                                                                                                                    </Dropdown.Menu>
                                                                                                                                                </Dropdown>


                                                                                                                                            </span> </li>

                                                                                                                                    </>
                                                                                                                                );
                                                                                                                            })
                                                                                                                        }

                                                                                                                    </ul>


                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>
                                                                                                    <div className={'card'} key={2}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Gallery
                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body8">
                                                                                                                    <ul className="social-profile group-gallery ">
                                                                                                                        {
                                                                                                                            this.state.chatMedia.map((user, i) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <li id={i} className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                                                                                                                            currentImg: this.state.chatMedia[i],
                                                                                                                                            all_images: this.state.chatMedia,
                                                                                                                                            image_click: i,
                                                                                                                                            click: true

                                                                                                                                        })}>

                                                                                                                                            <span className="img-group">
                                                                                                                                                <img src={user.image} /> </span >
                                                                                                                                        </li>



                                                                                                                                    </>
                                                                                                                                );
                                                                                                                            })
                                                                                                                        }

                                                                                                                    </ul>


                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>

                                                                                                    <div className={'card'} key={3}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Privacy & Support

                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body8">


                                                                                                                    <ul className="group-setting group-participant">
                                                                                                                        <li><span className="img-group"><BiBell /> </span > <p onClick={() => this.setState({ MuteModal: true, Mtype: 'group' })}>Mute Conversation</p> </li>
                                                                                                                        <li><span className="img-group"><BiMessageError /> </span > <p onClick={() => this.setState({ ReportModal: true, Mtype: 'group' })}>Something’s Wrong</p> </li>
                                                                                                                        <li><span className="img-group"><MdExitToApp /> </span> <p onClick={() => this.setState({ LeaveGroupModal: true })}>Leave Group</p> </li>
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>

                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div className={'card'} key={0}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Chat Options
                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body">
                                                                                                                    <ul className="group-setting group-participant">
                                                                                                                        <li><span className="img-group"><BiSearch /> </span> <p onClick={() => this.setState({ searchChat: true })}>Search Chat</p> </li>
                                                                                                                    </ul>

                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>
                                                                                                    <div className={'card'} key={2}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Shared Media
                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body8">
                                                                                                                    <ul className="social-profile group-gallery ">
                                                                                                                        {this.state.chatMedia.map((user, i) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <li id={i} className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                                                                                                                        currentImg: this.state.chatMedia[i],
                                                                                                                                        all_images: this.state.chatMedia,
                                                                                                                                        image_click: i,
                                                                                                                                        click: true

                                                                                                                                    })}>

                                                                                                                                        <span className="img-group">
                                                                                                                                            <img src={user.image} /> </span >
                                                                                                                                    </li>

                                                                                                                                </>
                                                                                                                            );
                                                                                                                        })}

                                                                                                                    </ul>


                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>
                                                                                                    <div className={'card'} key={3}>
                                                                                                        <AccordionItem>
                                                                                                            <AccordionItemHeading className="card-header">
                                                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                                                    Privacy & Support

                                                                                                                    <i className="minus"><BsChevronDown /></i>
                                                                                                                    <i className="plus"><BsChevronUp /></i>
                                                                                                                </AccordionItemButton>
                                                                                                            </AccordionItemHeading>
                                                                                                            <AccordionItemPanel>
                                                                                                                <div className="card-body8">


                                                                                                                    <ul className="group-setting group-participant">
                                                                                                                        <li><span className="img-group"><BiBell /> </span > <p onClick={() => this.setState({ MuteModal: true, Mtype: 'user' })}>Mute Conversation</p> </li>
                                                                                                                        <li><span className="img-group"><BiMessageError /> </span > <p onClick={() => this.setState({ ReportModal: true, Mtype: 'user' })}>Something’s Wrong</p> </li>
                                                                                                                        <li><span className="img-group"><MdExitToApp /> </span> <p onClick={() => this.setState({ groupUser: this.state.chatUser, BlockUserModal: true })}>Block</p> </li>
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </AccordionItemPanel>
                                                                                                        </AccordionItem>
                                                                                                    </div>


                                                                                                </>
                                                                                        }


                                                                                    </Accordion>
                                                                                </div>
                                                                                <div className="modal-form text-center Chat-Gallery">
                                                                                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                                                                                        <div className="modal-bg"></div>
                                                                                        <div className="modal-dialog modal-fullscreen-sm-down">
                                                                                            <div className="modal-content">
                                                                                                <div className="modal-top border-0">
                                                                                                    <div className="alert-content">
                                                                                                        {this.state.click ? <ChatPhotoGallery current={this.state.currentImg} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </MDBCol>
                                                                : <>
                                                                    <MDBCol md="9" xl="9" sm="12" className="pl-md-3 px-lg-auto mt-2 mt-md-0" id="style-1" >
                                                                        <div className="form-group basic-textarea empty-box">
                                                                            <div className="chat-area">
                                                                                <div className="chat-empty">

                                                                                    <img src={ChatIcon} alt="Blog" className="card__img" />
                                                                                    <h5>Select a conversation</h5>
                                                                                    <p>Try selecting a conversation or searching for someone specific.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCol>


                                                                </>
                                                            }


                                                        </MDBRow>
                                                    </MDBCardBody>
                                                </MDBCard>

                                            </main>
                                        </div>
                                        <div className="col-md-1 col-lg-1"></div>
                                    </Row>
                                </Container >

                            </div>
                        </Container>
                        <div className="margin-top-50px">
                            {/* Footer */}
                            <Footer />
                        </div>
                    </main >


                </div>

            </div >
        );
    }
}
export default BuyerMessage;