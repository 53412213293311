import React, { Component } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import NavbarListing from "../../components/common/NavbarListing";
import Footer from "../../components/common/footer/Footer";
import { Link, Redirect } from 'react-router-dom';
import review from '../../assets/images/review.png';
import smile from '../../assets/images/smile.png';
import happy from '../../assets/images/happy.png';
import review_1 from '../../assets/images/review-1.png';
import smile_1 from '../../assets/images/smile-1.png';
import happy_1 from '../../assets/images/happy-1.png';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Rater from 'react-rater';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import { uToken } from '../../useToken';
import Modal from 'react-bootstrap/Modal'
import PhotoGallery from './PhotoGallery';
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import BreadArrow from '../../assets/images/BreadArrow.png';

export default class CityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 1,
      pageNumber: 1,
      totalpage: "",
      reviews: [],
      externalData: false,
      loginTrue: false,
      currentPage: 1,
      HideShowMore: true,
      like: false,
      user: { fname: "", lname: "" }
    }
    this.sendRequest = this.sendRequest.bind(this);
    this.handler1 = this.handler1.bind(this);
    this.onHide = this.onHide.bind(this);
    this.handler = this.handler.bind(this);
  }

  onHide() {
    this.setState({ LoginModal: false, GalleryModal: false });
  }
  handler1(e) {
    if (e) {
      this.setState({
        LoginModal: false,
      });

    }
    this.props.history.go('/')
    console.log(e, 'saaaaaaaaaaaaaaaaaa')
  }

  handler(e) {
    this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
  }



  sendRequest = (count) => {
    this.setState({ currentPage: count })
    this.getBusinessList(count);
  }

  componentDidMount() {

    $(document).on('click', '.delete-account-info', function (e) {
      $('body').addClass('modal-open').css({ paddingRight: '17px' });
      $(".account-delete-modal").addClass('show')
      e.preventDefault();
    })
    $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
      $('body').removeClass('modal-open').css({ paddingRight: '0' });
      $(".account-delete-modal").removeClass('show')
      e.preventDefault();
    })
    this.getBusinessList();
  }

  ShowMoreText(id) {
    $('.length-limit-' + id).css("display", "none");
    $('.hide-more-' + id).css("display", "none");
    $('.r-length-limit-' + id).css("display", "block");
  }

  getBusinessList = (count) => {
    var self = this;
    self.setState({
      externalData: false,
    })
    let pg = 1;
    if (count != undefined && count != '') {
      pg = count;
    }
    // console.log(data)
    axios.post(ApiUrl + `getBuyerPhotos?page=${pg}&limit=10`, {},
      {
        headers: {
          'Authorization': `Bearer ${uToken()}`
        }
      })
      .then(function (res) {
        // console.log(data)
        if (res.data.success == true) {

          self.setState({
            externalData: true,
            reviews: res.data.lists,
            user: res.data.user,
            totalpage: res.data.total_pages,
          })



        } else {
          self.setState({
            externalData: true,
          })

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    if (this.state.isSignedUp) {
      // redirect to main listing page if user again search
      return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
    }
    return (
      <>
        {this.state.externalData ?

          <div className="cities recent BuyerPhotos ">
            <NavbarListing handler={this.handler} />
            <div className="container">

              <div className="list1">
                <div className="row mt-5">
                  <div className="col-lg-2 column-td-6 d-none d-xl-block"></div>
                  <div className="col-lg-10 col-md-12">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="CrumbBread">
                          <Link to="/user-profile" > {this.state.user.fname} {(this.state.user.lname) ? this.state.user.lname : ""} </Link> <img className="avatar__img" alt="Comment" src={BreadArrow} />
                          <Link className="Active" to="#" >Local photos & videos </Link>
                        </div>
                        <div className="BusinessHeading">{this.state.reviews.length} photos/videos by {this.state.user.fname} {(this.state.user.lname) ? this.state.user.lname : ""}</div>
                      </div>
                      {this.state.reviews.map((item, i) => {
                        return (
                          <>
                            <div className="col-lg-2 col-md-3">
                              <div className="card-item">
                                <div className="card-content-wrap two-part">
                                  <div className="card-content">
                                    <div className="part">
                                      <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                        currentImg: item,
                                        business: item,
                                        all_images: this.state.reviews,
                                        image_click: i,
                                        click: true
                                      })}>
                                        <img src={item.image} className="card__img" alt="Place" />
                                        <div className="BName">{item.business_name}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        )
                      })}

                      <div className="col-lg-12 text-right mt-3">
                        {
                          (this.state.totalpage > 1) ?
                            Array.from(Array(this.state.totalpage), (e, i) => {
                              return (
                                <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                  <span className="">
                                    <span className="icon-label">{i + 1}</span></span>
                                </button>
                              );
                            })
                            : ""
                        }
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="total-pages">
                          Page 1 of 1
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                      <div className="modal-bg"></div>
                      <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content p-4">
                          <div className="modal-top border-0 mb-4 p-0">
                            <div className="alert-content">
                              {this.state.click ? <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </div>
          </div>
          : ""
        }
        <div className="margin-top-20px">
          {/* Footer */}
          <Footer />
        </div>
      </>
    )
  }
}
