import React, { Component } from 'react';
import BuisnessLoginBox from "../components/other/account/BuisnessLoginBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg';
import { Link } from "react-router-dom";
import ImageSignUp from '../assets/images/PSignUp.png';
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/katika-logo_wht.svg';

import { buyerUrl, sellerUrl } from '../config';

import { BiCheckCircle } from 'react-icons/bi';


import processBusiness from '../assets/images/processBusiness.png';
import processPersonal from '../assets/images/processPersonal.png';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignUpImg: ImageSignUp,
            default: 0,
        }
    }


    render() {
        const { setToken } = this.props;
        return (
            <main className="login-page b-login">
                {/* Header */}

                <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-12">
                                <div className="logo listing center ">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
                {/* <section className="form-shared login-page padding-top-40px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mx-auto processSignUp">
                                <div className="login-top">
                                    <div className="billing-form-item mb-0">
                                        <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                                            <p className="font-size-16 font-weight-medium">
                                                <h2>Sign Up for Katika</h2>
                                            </p>
                                            {/* <h5 className="h5">Inclusion made easy.</h5> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row processSignUp">
                            <div className="col-md-6">
                                <div className="Img-p">
                                    <div className="sign-up-image">
                                        <h3 className="h3">I’m a business owner</h3>
                                        <img key={0} src={processBusiness} alt="testimonial" className="random-img" />
                                    </div>
                                </div>
                                <div className="content-area">
                                    <ul>
                                        <li><span className="check"><BiCheckCircle /> </span> A social directory created for and by black owned businesses</li>
                                        <li><span className="check"><BiCheckCircle /></span> Connect existing business apps you currently use to Katika</li>
                                        <li><span className="check"><BiCheckCircle /></span> Message directly with customers and potential clients</li>
                                        <li><span className="check"><BiCheckCircle /></span> Create marketing videos with Katika Stories to share</li>

                                    </ul>
                                </div>
                                <div className="processBtn">
                                    <div className="btn btn-p">
                                        <a href={`${sellerUrl}/business-sign-up`} className="business">Katika for Business Sign Up</a>
                                    </div>
                                </div>

                            </div>
                            <div className="mid-border"> </div>

                            <div className="col-md-6">
                                <div className="Img-p">
                                    <div className="sign-up-image">
                                        <h3 className="h3">I support local business owners</h3>
                                        <img key={1} src={processPersonal} alt="testimonial" className="random-img" />
                                    </div>

                                </div>
                                <div className="content-area personal">
                                    <ul>
                                        <li><span className="check"><BiCheckCircle /> </span> AI Powered search engine providing instant search results</li>
                                        <li><span className="check"><BiCheckCircle /></span> Discover new businesses through Katika video stories</li>
                                        <li><span className="check"><BiCheckCircle /></span> Message with businesses directly when you have a question</li>
                                        <li><span className="check"><BiCheckCircle /></span> Create group chats with friends & family to share businesses</li>

                                    </ul>
                                </div>
                                <div className="processBtn">
                                    <div className="btn btn-p">
                                        <a href={`${buyerUrl}/sign-up`} className="buyer">General User Sign Up</a>
                                    </div>

                                </div>
                            </div>

                        </div>





                    </div>
                </section> */}
                <Container>
                    <Row>
                        <Col lg={8} className="offset-lg-2">
                            <div className='SignUpSelection padding-top-60px padding-bottom-30px padding-left-60px padding-right-60px margin-top-100px text-center'>
                                <h3 className='color-black'>Join as a business owner or shopper </h3>
                                <Col lg={12} className="margin-top-40px">
                                    <Row>
                                        <Col lg={6}>
                                            <div onClick={() => this.setState({
                                                default: 1
                                            })}
                                                className={`SelectUser text-start p-3 color-black ${this.state.default == 1 && 'active-user'}`}>
                                                <span className='SelectRadio'><input checked={this.state.default == 1 ? true : false} onChange={() => this.setState({
                                                    default: 1
                                                })} type='radio' name='selectuser'></input></span>
                                                <h5 className='margin-top-20px'>I’m a business owner</h5>
                                                <p>promoting my</p>
                                                <p>business</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div onClick={() => this.setState({
                                                default: 2
                                            })}
                                                className={`SelectUser text-start p-3 color-black ${this.state.default == 2 && 'active-user'}`}>
                                                <span className='SelectRadio'><input
                                                    onChange={() => this.setState({
                                                        default: 2
                                                    })}
                                                    checked={this.state.default == 2 ? true : false}
                                                    type='radio' name='selectuser'></input></span>
                                                <h5 className='margin-top-20px'>I’m a shopper</h5>
                                                <p>looking for products</p>
                                                <p>& services</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                {
                                    this.state.default == 0 ?
                                        <>
                                            <button className='btn btn-info signupBtn mt-4 mb-4 padding-right-30px padding-left-30px'>Create account</button>
                                            <p>Already have an account? <a href={`${buyerUrl}/login`}>Log In</a></p>
                                        </>
                                        : this.state.default == 1 ?
                                            <>
                                                <a href={`${sellerUrl}/business-sign-up`} className='btn btn-primary signupBtn mt-4 mb-4 padding-right-30px padding-left-30px text-white'>Join as a business owner</a>
                                                <p>Already have an account? <a href={`${sellerUrl}/business-login`}>Log In</a></p>
                                            </>
                                            : this.state.default == 2 ?
                                                <>
                                                    <a href={`${buyerUrl}/sign-up`} className='btn btn-primary signupBtn mt-4 mb-4 padding-right-30px padding-left-30px text-white'>Join as a shopper</a>
                                                    <p>Already have an account? <a href={`${buyerUrl}/login`}>Log In</a></p>
                                                </>
                                                :
                                                <>
                                                    <button className='btn btn-info signupBtn mt-4 mb-4 padding-right-30px padding-left-30px'>Create account</button>
                                                    <p>Already have an account? <a href={`${buyerUrl}/login`}>Log In</a></p>
                                                </>
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Newsletter 
                <NewsLetter />*/}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default Login;