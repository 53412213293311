import React, { useState } from 'react'
import HomePageHeader from '../../components/webapp_view/common/PageHeader'
import BannerOne from '../../components/webapp_view/BannerOne'
import BannerMobile from '../../components/webapp_view/BannerMobile'
import Testimonial from '../../components/webapp_view/Testimonial'
import TestimonialTwo from '../../components/webapp_view/TestimonialTwo'
import { Row, Col } from "react-bootstrap";
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/webapp_view/common/ScrollTopBtn";
import { Link } from 'react-router-dom'
import kVideo from "../../assets/images/k-video.png";
import SlideOne from '../../assets/images/SlideOne.png';
import SlideTwo from '../../assets/images/SlideTwo.png';
import SlideThree from '../../assets/images/SlideThree.png';
import Community from '../../assets/images/community.png';
import MobileApps from '../../assets/images/mobile-app.png';
import MobileAppView from '../../assets/images/searchApp.png';
import Discover from '../../assets/images/discover.png';
import Chats from '../../assets/images/chats.png';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { buyerUrl, sellerUrl } from '../../config'
import { random_item } from '../../useToken'


const Business = () => {

    const [items, setItems] = useState([
        {
            title: 'What business types are eligible for Katika business?',
            desc: `Businesses that register on Katika must be owned (50% +)
                by a person of African Descent. If you have never marked down Black,
                African or Caribbean on any type of application or census, 
                you do not qualify to be a business on Katika. Individuals with multi-level 
                marketing companies such as Herbalife, Total Life Changes, and ACN are not permitted to register on Katika.
                <br> <br> For-profit, non-profit and government agencies can create accounts on Katika.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'What are the benefits of joining Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is 
                    constantly seeking your products and services. We are focused on connecting local consumer to the businesses in
                    their neighborhoods or adjacent. We provide exposure for your brand, send information about funding opportunities
                    and events to participate in. Katika is working to facilitate dollars staying within communities for a longer 
                    period of time by making it easy for people to find local services and products in their neighborhoods.
                    Katika also provides educational resources to help close the information and education gap between business owners.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to join Katika as a business?',
            desc: `When you join Katika for Business, you are connecting to an audience that is constantly seeking your
             products and services. We are focused on connecting local consumer to the businesses in their
              neighborhoods or adjacent. We provide exposure for your brand, send information about funding
               opportunities and events to participate in. Katika is working to facilitate dollars staying 
               within communities for a longer period of time by making it easy for people to find local
                services and products in their neighborhoods. Katika also provides educational resources 
                to help close the information and education gap between business owners.<br>  <br>
                1. Go to <a target="_blank" href="${sellerUrl}"> ${sellerUrl} </a> and select Create Your Free Business Page <br>  <br>
                2. Fill out the organization's information on the 2 forms filling out your personal and business information.
                You will need to go to the email address you listed for the business and click on the confirmatin link 
                inside of the email to verify your account. <br>  <br> 
                3. Login to your account from <a  target="_blank" href="${sellerUrl}/sign-in" >${sellerUrl}/sign-in</a> and finish populating 
                your business profile. Your page will become live when your profile has 70% of the informatin filled out.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to claim your business page',
            desc: `Business information on your existing Katika for Business page was sourced from 3rd party public data, 
            or Katika Team Members who've created your profile. Your login information was sent to you when the profile was created. 
            If you need to claim your business page, please email us on our Contact Page.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
        {
            title: 'How to post on Katika as a business?',
            desc: `Businesses with a paid account can post to our neighborhood newsfeed called
             “The Block”. In our newsfeed businesses can create 4 types of post: General announcements, Useful Information, Events, and Promotions.
             To post on Katika as a business, following the steps below: <br> <br>
            1. Log in to your Katika for Business Page.<br> <br>
            2. Head to the ‘Create New Post’  tab in the left menu.Select the pencil icon and enter the zip code that you want to target with your post.<br> <br>
            3. Select your Post Type, and enter a title which describes the post, followed by a detailed description.<br> <br>
            4. You have the option to add a link to take your customer to a specific destination.<br> <br>
            5. If you use entered a link, then you will need to select a button option from the drop down menu.<br> <br>
            6. You must add a graphic or video to your post.<br> <br>
            7. Select Post to send your post to the newsfeed wall.`,
            plus: <AiOutlinePlus />,
            minus: <AiOutlineMinus />,
            cardClass: 'mb-3'
        },
    ])
    return (
        <main className="BusinessHome BizHome">
            {/* Header */}
            <HomePageHeader />


            <div className='d-block d-sm-none'>
                <div className='MobileView'>
                    {/* <BannerMobile /> */}
                    <div className="text-center my-4 mb-5">
                        <div className="MobileViewHead my-2 d-inline-block"> <h1 className='mt-3 mb-5'>A tech platform for the culture.</h1></div>
                        <div className="AppContent text-center mb-3 padding-bottom-50px">
                            <p className="mb-3"><b>Katika</b> is the easy way to connect to <b>black businesses.</b></p>
                            <a target='_blank' href={ random_item(['https://apps.apple.com/us/app/katika/id1595089103','https://play.google.com/store/apps/details?id=com.katikabuyer']) } className="btn btn-primary rounded mt-4">Get the App</a>
                        </div>
                        <b className="color-primary mb-3">Available on iOS, Android and Web</b>
                    </div>
                    <div className="MobileImages">
                        <img src={MobileApps} />
                    </div>
                    <div className="heading my-5">
                        <h2>Find, connect, and engage with black businesses.</h2>
                    </div>
                    <div className="MobileAppView py-5">
                        <img src={MobileAppView} />
                        <div className="px-4">
                            <h1>Search.</h1>
                            <p className="mt-2 text-white">Find black businesses with our AI powered search bar or use the popular category suggestions we recommend.</p>
                        </div>
                    </div>
                    <div className="FullBgMobile my-5">
                        <div className="MobileAppView py-5">
                            <div className="px-4">
                                <h1>Discover.</h1>
                                <p className="mt-2 text-white mb-4">Check out “The Feed” to see what black businesses are up to! Discover upcoming events, industry tips, and the latest news from black businesses in your area.</p>
                            </div>
                            <img src={Discover} />
                        </div>
                    </div>
                    <div className="MobileAppView py-5">
                        <div className="px-4">
                            <h1>Connect.</h1>
                            <p className="mt-2 text-white mb-4">Leave reviews, create short videos for brands you support, and make group chats with your friends on Katika.</p>
                        </div>
                        <img src={Chats} />
                    </div>
                    <div className="text-center my-4 mb-5">
                        <div className="MobileViewHead my-2 d-inline-block"> <h1 className='mt-3 mb-5'>This is  inclusion made easy.</h1></div>
                        <div className="AppContent text-center mb-3">
                            <p className="mb-3">It’s simple and 100% free</p>
                            <a href={`${buyerUrl}/signup`} className="btn btn-primary rounded mt-4">Sign up for free</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Business;