import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { buyerUrl } from '../../../config';

class FooterKatikaWidget extends Component {
    state = {
        title: 'Legal',
        links: [
            {
                path: '/terms-of-service',
                title: 'Terms of Service'
            },
            {
                path: '/privacy-policy',
                title: 'Privacy Policy'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="col-lg-3 col-md-3 col-sm-3 column-td-6">
                    <div className="footer-item">
                        <h4 className="footer__title">
                            {this.state.title}
                        </h4>
                        <ul className="list-items">
                            <li key={0}>
                                <a href={`${buyerUrl}/terms-of-service`} >Terms of Service</a>
                            </li>
                            <li key={1}>
                                <a target='_blank' href={`${buyerUrl}/privacy-policy`} >Privacy Policy</a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterKatikaWidget;