import React from 'react';
import { Link } from "react-router-dom";

export default function Button({ text, url, className, children , target }) {
    return (
        <>
            <Link
                to={{ pathname: url }}
                target={target}
                className={'theme-btn ' + className}>{children} {text}</Link>
        </>
    );
}
