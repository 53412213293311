import React, { Component } from 'react';
import ImageSignUp from '../../assets/images/sign-up-img.png';
import { Row, Col } from "react-bootstrap";
import { AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Inputmask from "inputmask";

class SignUp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            SignUpImg: ImageSignUp,
        }
    }

    componentDidMount() {
        Inputmask({ "mask": "(999) 999-9999" }).mask('.phone');
    }

    render() {
        return (

            <Row classNam="step-1">

                <Col lg={12} md={12}>
                    <div className="about">
                        <h1>About You</h1>
                    </div>
                </Col>

                <Col lg={12} md={12}>
                    <div className="steps">
                        <h2>Step 1 of 3</h2>
                    </div>
                </Col>
                <div className="col-lg-6 mx-auto">
                    <div className="signup-desc">
                        <h5>Let’s get you started</h5>
                        <p>We’re excited you’ve decided to join and create your online business profile on Katika. Customers within your neighborhood will be able to find you and stay updated with your business. To get started we need to know who you are.</p>
                    </div>
                    <div className="sign-up-image"><img key={0} src={this.state.SignUpImg} alt="testimonial" className="random-img" /></div>
                </div>
                <div className="col-lg-6 mx-auto">
                    <div className="billing-form-item mb-0">

                        <div className="billing-content BSignupForm">
                            <div className="contact-form-action sign-up">
                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="input-box1">
                                            <AvGroup>
                                                <label style={{ color: "#000000" }}>Name</label><span class="start">*</span>
                                                <AvInput
                                                    placeholder="First name"
                                                    className="form-control"
                                                    name="fname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>


                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-box1">
                                            <AvGroup>
                                                <label>&nbsp;</label>
                                                <AvInput
                                                    placeholder="Last name"
                                                    className="form-control"
                                                    name="lname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>


                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Email (<small>Your verification email will be sent here</small>)</label><span class="start">*</span>
                                                <AvInput
                                                    className="form-control"
                                                    name="email"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                {/* <span class="start">*</span> */}
                                                <label>Phone Number </label>
                                                <AvInput
                                                    placeholder="(xxx) xxx-xxxx"
                                                    validate={{ pattern: { value: /^\([0-9]{3}\)[-\s\.][0-9]{3}-[0-9]{4}$/ } }}
                                                    className="form-control phone"
                                                    name="phone"
                                                />
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Password </label><span class="start">*</span>
                                                <AvInput
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    minLength={8}
                                                    title="Password must be at least 8 characters"
                                                    required />
                                                <AvFeedback> This field is invalid </AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>Confirm Password</label> <span class="start">*</span>
                                                <AvInput
                                                    className="form-control"
                                                    type="password"
                                                    minLength={8}
                                                    title="Confirm password must be at least 8 characters and same as password"
                                                    name="confirm_password"
                                                    validate={{ match: { value: 'password' } }}
                                                    required />
                                                <AvFeedback> This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <AvGroup>
                                                <label>How did you hear about us?</label><span class="start">*</span>
                                                <AvField type="select"
                                                    name="hear_about"
                                                    placeholder='Select Choice'
                                                    required
                                                >
                                                    <option disabled value="">Select Choice</option>
                                                    <option value="Word of mouth">Word of mouth</option>
                                                    <option value="Online Ad">Online Ad</option>
                                                    <option value="Social Media Post">Social Media Post</option>
                                                    <option value="Katika Team Member">Katika Team Member</option>
                                                    <option value="News/Media">News/Media</option>
                                                    <option value="Other">Other</option>
                                                </AvField>
                                                <AvFeedback> Required Field *</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

        );
    }
}

export default SignUp;