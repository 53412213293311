import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from "react-router-dom";
import img1 from '../../assets/images/img31.jpg';
import img2 from '../../assets/images/img32.jpg';
import img3 from '../../assets/images/img30.jpg';
import authorImg from '../../assets/images/testi-img7.jpg';
import { IoIosCheckmarkCircle, IoIosLink, IoMdStar, IoMdStarHalf } from "react-icons/io";
import TabsHeading from '../common/TabsHeading'
import 'react-tabs/style/react-tabs.css';
import { ApiUrl } from '../../config';
import axios from 'axios';
// import Rater from 'react-rater'
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';



export default class CityList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [
                {
                    title: 'Philadelphia',
                    id: 'philadelphia'
                }
            ],

            lists: [
                {
                    cards: [
                        
                    ]
                }
            ]
        };

    }

    componentDidMount() {
        this.getBusinessList();
    }

    getBusinessList = () => {
        var self = this;
        var loc = JSON.parse(localStorage.getItem('location'));
        let data = {};
        if (loc != null) {
            data = {
                'city': loc.city,
                'region': loc.region,
                'country': loc.country
            };
        } else {


            data = {
                'city': 'Philadelphia',
                'region': 'PA',
                'country': 'USA'
            };
        }

        axios.post(ApiUrl + 'locationBusiness', data, {})
            .then(function (res) {
                // console.log(res)
                self.setState({
                    tabs: [{ title: res.data.name, id: res.data.name }],
                    lists: [{ cards: res.data.lists }]
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <>
                <div className="cities">
                    <div className="container">
                        {/* <h2>Welcome to Katika</h2> */}
                        <div className="list">
                            <Tabs>
                                <TabList>
                                    {this.state.tabs.map((tab, index) => {
                                        return (
                                            <Tab key={index} > <h2> Katika {tab.title} </h2></Tab>
                                        )
                                    })}
                                </TabList>
                                <div className="tabs_heading" >
                                    <TabsHeading title="Most Recent Businesses on Katika" />
                                </div>
                                {this.state.lists.map((list, key) => {
                                    return (
                                        <TabPanel key={key}>
                                            <div className="row mt-5" key={key}>
                                                {list.cards.map((item, index) => {
                                                    return (
                                                        <div className="col-lg-4 column-td-6" key={index}>
                                                            <div className="card-item blog-card" key={index}>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/biz/${item.business_slug}`,
                                                                        state: {
                                                                            b_id: item.business_id,
                                                                            business_details: item,
                                                                            search_data: "",
                                                                            search: ""
                                                                        }
                                                                    }}

                                                                    className="card-image-wrap">
                                                                    <div className="card-image">
                                                                        <img width="100%" height="100%" src={item.img} alt="Blog" className="card__img img-thumbnail" />
                                                                    </div>
                                                                </Link>
                                                                <div className="card-content pl-0 pr-0">
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/biz/${item.business_slug}`,
                                                                            state: {
                                                                                b_id: item.business_id,
                                                                                business_details: item,
                                                                                search_data: "",
                                                                                search: "",
                                                                            }
                                                                        }}

                                                                        className="card-title">
                                                                        {item.title}
                                                                    </Link>
                                                                    <div className="">
                                                                        <div className="rating-rating rating-detail landing">
                                                                            {/* <Rater total={5} rating={item.rating} interactive={false} /> */}

                                                                            <Rating
                                                                                placeholderRating={item.rating}
                                                                                readonly={true}
                                                                                stop={5}
                                                                                emptySymbol={<img width="100%" height="100%" src={Empty} className="icon" />}
                                                                                placeholderSymbol={<img width="100%" height="100%"  src={Fill} className="icon" />}
                                                                                fullSymbol={<img width="100%" height="100%" src={Fill} className="icon" />}
                                                                            />

                                                                            {/* {item.ratings.map((rating, index) => {
                                                                                return (
                                                                                    <span key={index}>{rating}</span>
                                                                                )
                                                                            })} */}
                                                                            <span className="rating-count">{item.review} Reviews</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="card-sub card-custom mt-3">
                                                                        {item.business_category_id} , {item.business_sub_category_id}
                                                                    </p>
                                                                    <p className="card-sub card-custom mt-3">
                                                                        {item.business_neighborhoods}
                                                                    </p>


                                                                    <div className="update">
                                                                        <span>New</span> <span className="add-date">{item.diff}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </TabPanel>
                                    )
                                })}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
