import React, {Component} from 'react';
import Copyright from "../../common/footer/Copyright";
import FooterQuickLinkWidget from "../../common/footer/FooterQuickLinkWidget";
import FooterCategoryWidget from "./FooterCategoryWidget";
import FooterHelpWidget from "../../common/footer/FooterHelpWidget";
import FooterKatikaWidget from "../../common/footer/FooterKatikaWidget";
import { buyerUrl } from '../../../../config';
// import Logosrc from '../../../../assets/images/katika-logo.png';
class Footer extends Component {
    render() {
        return (
            <>
            <ul className="FooterNavBar">
                <li><a href="#">About</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Support</a></li>
                <li><a href={`${buyerUrl}/terms-of-service`}>Terms</a></li>
                <li><a href={`${buyerUrl}/privacy-policy`}>Privacy Policy</a></li>                
            </ul>
                  
                {/* Copyright */}
                <Copyright />
            </>
        );
    }
}

export default Footer;