import React, { Component } from 'react';

class WidgetSortBy extends Component {
    state = {
        title: '1 filter',
        items: [
            {
                id: 1,
                title: 'Open Now'
            }
        ],
        checkbox: [
            {
                id: 2,
                text: '$'
            },
            {
                id: 3,
                text: '$$'
            },
            {
                id: 4,
                text: '$$$'
            },
            {
                id: 5,
                text: '$$$$'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>

                    <div className="check-box-list filter-category mt-4">

                        {this.state.items.map(item => {
                            return (
                                <div className="custom-checkbox">
                                    <label>
                                        {item.title}
                                    </label>
                                </div>
                            )
                        })}

                    </div>
                    <div id="showfilterbyfeature" className="showmore-btn filter">
                        <span className="showmore-txt ">Clear all</span>
                    </div>
                    

                    <div className="check-box-list filter-category mt-4">
                        <div className="SelectPrice">

                        {this.state.checkbox.map(check => {
                            return (
                                <div className="custom-checkbox" key={check.id}>
                                    <input type="checkbox" id={'chb3-'+check.id} />
                                    <label htmlFor={'chb3-'+check.id}>
                                        {check.text}
                                    </label>
                                </div>
                            )
                        })}
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default WidgetSortBy;