import React, { Component } from 'react';
import { MdStar, MdStarHalf } from 'react-icons/md'
import { RiReplyLine } from 'react-icons/ri'
import { Button } from 'react-bootstrap';
import SectionDivider from "../common/SectionDivider";
import { Link } from "react-router-dom";
import commentimg1 from '../../assets/images/testi-img1.jpg';
import PhotoIcon from '../../assets/images/icon_photo.png';
import RatingStar from '../../assets/images/rating-star.png';
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Fill from '../../assets/images/fill.png';
import $ from 'jquery';
import UseFull from '../../assets/images/UseFull.png';
import Funny from '../../assets/images/funny.png';
import Cool from '../../assets/images/cool.png';
import NoCool from '../../assets/images/cool-1.png';
import NoUseFull from '../../assets/images/UseFull-1.png';
import NoFunny from '../../assets/images/funny-1.png';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import LoginBox from '../other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'
import PhotoGallery from '../directories/PhotoGallery';
class ListingDetailsComments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoginModal: false,
            reviews: [],
            comments: [
                {
                    img: commentimg1,
                    name: 'Adam Smith',
                    date: '12/2/2020',
                    content: "Osha does not have the best reviews but I actually like  this place a lot. I’ve only ordered Pad Thai, Pad See Ew, and spring rolls but my...",
                    stars: [
                        <MdStar />,
                        <MdStar />,
                        <MdStar />,
                        <MdStar />,
                        <MdStarHalf />,
                    ],
                    replyBtn: 'Reply',
                    replyBtnIcon: <RiReplyLine />,
                    replyComments: [
                        {
                            img: commentimg1,
                            name: 'Julian Smith',
                            date: 'Reviewed 3 Days ago',
                            content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                            stars: [
                                <MdStar />,
                                <MdStar />,
                                <MdStar />,
                                <MdStar />,
                                <MdStarHalf />,
                            ],
                            replyBtn: 'Reply',
                            replyBtnIcon: <RiReplyLine />,
                        }
                    ]
                },
                {
                    img: commentimg1,
                    name: 'Matt Derry',
                    date: 'Reviewed 4 Days ago',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                    stars: [
                        <MdStar />,
                        <MdStar />,
                        <MdStar />,
                        <MdStar />,
                        <MdStar />,
                    ],
                    replyBtn: 'Reply',
                    replyBtnIcon: <RiReplyLine />,
                    replyComments: []
                }
            ],
            business: this.props.business
        }

        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
    }

    handler1(e) {
        if (e) {
            this.props.loginActivity(true);
            this.setState({
                LoginModal: false,
            });
        }
        this.getBusinessList();
    }

    onHide() {
        this.setState({ LoginModal: false, GalleryModal: false });
    }

    changeCoolNo = (id, activty, count) => {
        var self = this;
        $('.cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('active');

        // let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(res)
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeCool = (id, activty, count) => {
        var self = this;
        $('.cool-' + id).addClass('none');
        $('.no-cool-' + id).removeClass('none');
        $('.no-cool-' + id).addClass('active');

        // let count = parseInt($('.cool-count-' + id).text());
        $('.cool-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    changeSmileNo = (id, activty, count) => {
        var self = this;
        $('.smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('active');

        // let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeSmile = (id, activty, count) => {
        var self = this;
        $('.smile-' + id).addClass('none');
        $('.no-smile-' + id).removeClass('none');
        $('.no-smile-' + id).addClass('active');

        // let count = parseInt($('.smile-count-' + id).text());
        $('.smile-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeUsefullNo = (id, activty, count) => {
        var self = this;
        $('.usefull-' + id).removeClass('none');
        $('.no-usefull-' + id).addClass('none');
        $('.no-usefull-' + id).removeClass('active');

        // let count = parseInt($('.useful-count-' + id).text());
        $('.useful-count-' + id).text(count - 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    changeUsefull = (id, activty, count) => {
        var self = this;
        $('.usefull-' + id).addClass('none');
        $('.no-usefull-' + id).removeClass('none');
        $('.no-usefull-' + id).addClass('active');

        // let count = parseInt($('.useful-count-' + id).text());
        $('.useful-count-' + id).text(count + 1);

        axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.getBusinessList(self.props.business.business_slug);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        console.log(this.props.business.business_id, 'saaaaaaaaaaaa')
        this.getBusinessList(this.props.business.business_slug);
    }
    getBusinessList = (id) => {
        var self = this;
        // console.log(data)
        axios.post(ApiUrl + 'singleBusiness', { business_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    reviews: res.data.reviews,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <ul className="comments-list padding-top-10px">
                    {this.state.reviews.map((item, i) => {
                        return (
                            <li key={i}>
                                <div className="comment listing-details">
                                    <div className="user-img">
                                        <img className="avatar__img" alt="Comment" src={item.image} />
                                    </div>
                                    <div className="comment-body">
                                        <div className="meta-data">
                                            <Link to={`/user-profile/${item.user_id}`} className="comment__author">
                                                {item.fname} {item.lname}
                                            </Link>

                                            <span className="photos"><img src={PhotoIcon} alt="Photos" />
                                            </span>
                                            <span className="photo_number">
                                                {/* {item.total_review_images} */} {item.total_images}
                                            </span>
                                            <span className="photos margin-left-20px">
                                                <img src={RatingStar} alt="RatingStar" />
                                            </span>
                                            <span className="photo_number">
                                                {/* {item.total_review_images} */} {item.total_reviews}
                                            </span>

                                        </div>

                                    </div>
                                </div>

                                <div className="rate-business">
                                    <div className="rating-rating rating-detail landing">
                                        <Rating
                                            placeholderRating={item.rating}
                                            readonly={true}
                                            stop={5}
                                            emptySymbol={<img src={Empty} className="icon" />}
                                            placeholderSymbol={<img src={Fill} className="icon" />}
                                            fullSymbol={<img src={Fill} className="icon" />}
                                        />
                                        {/* <Rater total={5} rating={item.rating} interactive={false} /> */}
                                        <span className="comment-date">
                                            {item.diff}
                                        </span>
                                    </div>
                                    <p className="comment-content">
                                        {item.description}
                                    </p>
                                    <div className="ReviewsBtn">
                                        <div className="use">
                                            <Button
                                                type="button" className="" variant="none">
                                                <span><img className="avatar__img" alt="Comment" src={UseFull} /></span>
                                                                        Useful
                                                                        <p className={`useful1`}>
                                                    <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewUseful > 0 ? item.totalBusinessReviewUseful : ""}</span> </p>
                                            </Button>
                                            <Button
                                                type="button" className="" variant="none">
                                                <span><img className="avatar__img" alt="Comment" src={Funny} /></span>
                                                                        Funny
                                                                        <p className={`funny`}>
                                                    <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewFunny > 0 ? item.totalBusinessReviewFunny : ""}</span> </p>

                                            </Button>
                                            <Button
                                                type="button" className="" variant="none">
                                                <span><img className="avatar__img" alt="Comment" src={Cool} /></span>
                                                                        Cool
                                                                        <p className={`cool`}>
                                                    <span className={` useful-count-${item.id}`} >{item.totalBusinessReviewCool > 0 ? item.totalBusinessReviewCool : ""}</span> </p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>


                            </li>
                        )
                    })}


                </ul>



                <SectionDivider />
                {/*<div className="button-shared padding-top-40px text-center">
                    <Button url="#" text="Load more review" className="border-0">
                        <FiRefreshCw />
                    </Button>
                    </div>*/}
            </>
        );
    }
}

export default ListingDetailsComments;