import React, { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlinePicture } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';

export default function MultiImageUpload(props) {


    const {
        acceptedFiles,
        open,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'image/*',
        maxFiles: 5,
        maxSize: 5194304
    });

    // video/*///
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            props.fileSet(acceptedFiles)
        }
    }, [acceptedFiles.length > 0])

    useEffect(() => {
        if (fileRejections.length > 0) {
            props.fileSet(false)
        }
    }, [fileRejections.length > 0])

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path} className='border-0'>
            <img src={URL.createObjectURL(file)} width={120} height={120} />
            {/* {file.path} - {file.size} bytes */}
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className="text-danger" key={file.path + errors[0].code}>{errors[0].message}</li>
    ));

    return (
        <section className="">
            <div className='row'>
                <div className='col-md-12'>

                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} multiple={true} />
                        <p> <AiOutlinePicture /> </p>
                    </div>
                    <aside>
                        <ul className='SocailImages d-flex'>{acceptedFileItems}</ul>
                        <ul>{fileRejectionItems}</ul>
                    </aside>
                </div>
                {/* <div className='col-md-4'>
                    <button className='btn btn-theme update-post-images' type="button" onClick={open}>
                        <BiRefresh />
                        Update
                    </button>
                </div> */}
            </div>

        </section>
    );
}