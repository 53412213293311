import React, { useState } from 'react';

import Slider from "react-slick";
import img1 from '../../assets/images/img31.jpg';
import img2 from '../../assets/images/img32.jpg';
import img3 from '../../assets/images/img30.jpg';
import img4 from '../../assets/images/s1.jpeg';
import img5 from '../../assets/images/s2.jpeg';

import ShareIcon from '../../assets/images/ShareIcon.png';
import MessageIcon from '../../assets/images/MessageIcon.png';
import HeartIcon from '../../assets/images/HeartIcon.png';

import video from '../../assets/video/ssstiktok_1623214518.mp4';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import { Component } from 'react';

import { RiCloseFill } from "react-icons/ri";
import katika_K from "../../assets/images/katika_K.png"
import { Link } from 'react-router-dom';
import { ShareButtonRectangle, ShareBlockStandard, ShareButton } from 'react-custom-share';
import { FaFacebook, FaExternalLinkAlt, FaPinterest } from 'react-icons/fa'
import { RiStarLine, RiFacebookCircleLine, RiTwitterFill } from 'react-icons/ri'
import { AiOutlineClose } from "react-icons/ai";
import { FaRedditAlien } from "react-icons/fa";
import axios from 'axios';
import { adminUrl, ApiUrl, buyerUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';

export default class Stories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            c_story: {
                name: '',
                stories: [],
            },
            data: [],
            shareModal: false,
            c_story_index: 0,
            datam: [],
            render: false,
        }
    }



    componentDidMount() {
        this.getLocationBusinessStory();

        // setTimeout(function () { //Start the timer
        //     this.setState({ render: true }) //After 1 second, set render to true
        // }.bind(this), 1000)
    }

    getLocationBusinessStory = () => {
        var self = this;
        var loc = JSON.parse(localStorage.getItem('location'));
        let data = {};
        if (loc != null) {
            data = {
                'city': loc.city,
                'region': loc.region,
                'country': loc.country
            };
        } else {


            data = {
                'city': 'Philadelphia',
                'region': 'PA',
                'country': 'USA'
            };
        }

        axios.post(ApiUrl + 'getLocationBusinessStory', data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                console.log(res)
                self.setState({
                    datam: res.data.lists,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    addStoryReaction = async (is_like) => {
        var self = this;
        let current_story_id = this.state.c_story.stories[this.state.c_story_index];
        let action = Number($(".like .actionType").text());

        axios.post(ApiUrl + 'addStoryReaction', { 'story_id': current_story_id.id, 'is_like': action }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    toast.success(res.data.message);
                    if (action == 0) {
                        $(".like img").attr("src", "https://katika-images.s3.us-east-2.amazonaws.com/category/Heart%20Icon1632822639.png");
                        let c = Number($(".like .like_count").text());
                        $(".like .like_count").text(c + 1);
                        $(".like .actionType").text(1);


                    } else {
                        $(".like img").attr("src", "https://katika-images.s3.us-east-2.amazonaws.com/category/HeartIcon1632822348.png");
                        let c = Number($(".like .like_count").text());
                        $(".like .like_count").text(c - 1);
                        $(".like .actionType").text(0);
                    }


                    self.getLocationBusinessStory();
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    addStoryComment = async (event) => {
        var self = this;
        let current_story_id = this.state.c_story.stories[this.state.c_story_index];
        if (event.key === 'Enter') {
            axios.post(ApiUrl + 'addStoryComment', { 'story_id': current_story_id.id, 'comment': event.target.value, 'reply_id': 0 }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success == true) {
                        $('.review-stroy').val(' ');
                        toast.success(res.data.message);
                    }
                    let c = Number($(".comment .comment_count").text());
                    $(".comment .comment_count").text(c + 1);

                    self.getLocationBusinessStory();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            console.log('event')
        }
    }






    render() {

        var self = this;
        var data = [];
        if (self.state.datam) {
            data = self.state.datam
        }
        // var data = this.state.datam;

        var config = {
            wait_time: 8,
            current_index: -1,
            current_story_index: -1,
            swipe: false,
            pageX: 0,
            pageY: 0,
            timer: 0,
            stoper: false,
            player: true,
            proWidth: 0,
            type: 'image',
        }

        function loadStories() {
            $("#storyList").html("");
            for (var i = 0; i < data.length; i++) {
                $("#storyList").append(`
                        <div class="storyItem " user="`+ data[i].user_id + `" index="` + i + `">
                            <div class="storyColor">
                                <img src="`+ data[i].profile + `" alt="` + data[i].business_name + `" />
                            </div>
                            <div class="story-meta">
                                
                                <div class="story-preview">
                                    <img src="`+ data[i].preview + `" alt="` + data[i].business_name + `" />
                                </div>
                                <h6 class="story-user-name"> `+ data[i].business_name + ` </h6>
                            </div>
                        </div>
                `);
            }
        }


        var timer;
        var story_index;
        var current_index;
        var redirect;
        var current_story_index;

        var interval;
        var width = 1;
        function startTimer() {
            // timer = setInterval(progress, 10);
            var progressList = $("#storyShowBox .storyProgressBar .progressBarOut");
            var elem = $(progressList[config.current_story_index]).find(".progressBarIn");

            clearInterval(interval);
            interval = setInterval(frame, 1000);

            if (config.type == 'video') {
                $(`video`).trigger('play');
            }

            $('.storyShowItem .StopNavigation .PauseNavigation').removeClass('none');
            $('.storyShowItem .StopNavigation  .playNavigation').addClass('none');

            function frame() {

                if (width >= 100) {
                    width = 1;
                    clearInterval(interval);
                    nextStory();
                } else {
                    if (config.type == 'image') {
                        width = width + 9;

                        console.log('width', width)
                    } else {
                        width = width + 100 / data[config.current_index].stories[story_index].time;
                    }
                    // elem.style.width = width + '%';
                    elem.css("width", width + '%');
                }
            }
        }

        let url1 = '';

        if (config.current_index != -1) {
            url1 = `${adminUrl}/story/${data[config.current_index].stories[story_index].id}`;
        }else{
            url1 = ''
        }



        const shareButtonPropsFb = {
            url: url1,
            network: "Facebook",
            text: "",
            longtext: ""
        };

        const shareButtonPropsPn = {
            url: url1,
            network: "Pinterest",
            text: "",
            longtext: "",
            media: ""
        };


        const shareButtonPropsTw = {
            url: url1,
            network: "Twitter",
            text: "",
            longtext: ""
        };

        function pause() {
            console.log('pause')
            if (config.type == 'video') {
                $(`video`).trigger('pause');
            }
            $('.storyShowItem .StopNavigation .PauseNavigation').addClass('none');
            $('.storyShowItem .StopNavigation  .playNavigation').removeClass('none');
            clearInterval(interval);
        }


        function mute() {
            console.log('mute')
            $('.storyShowItem .VolumeControl .unmute').removeClass('none');
            $('.storyShowItem .VolumeControl .mute').addClass('none');
            $(`video`).prop('muted', true);
        }


        function unmute() {
            console.log('unmute')
            $('.storyShowItem .VolumeControl .mute').removeClass('none');
            $('.storyShowItem .VolumeControl .unmute').addClass('none');
            $(`video`).prop('muted', false);
        }



        function openStory(index, selected_story_index = null) {
            console.log('timer here ', timer)
            clearInterval(timer);
            config.timer = 0;



            $("#storyShowBox .storyHeader img").attr("src", data[index].profile);
            $("#storyShowBox .storyHeader .name").text(data[index].business_name);
            $("#storyShowBox .storyHeader a").attr("href", `${buyerUrl}/biz/` + data[index].business_slug);
            $("#storyShowBox .storyProgressBar").html("");
            story_index = -1;
            for (var j = 0; j < data[index].stories.length; j++) {
                if (data[index].stories[j].watched == false && story_index == -1) {
                    story_index = j;
                }
                $("#storyShowBox .storyProgressBar").append(`
                    <div class="progressBarOut">
                        <div class="progressBarIn cI`+ j + ` " ></div>
                    </div>
                `);
            }
            if (story_index == -1) {
                story_index = 0;
                for (var y = 0; y < data[index].stories.length; y++) {
                    data[index].stories[y].watched = false;
                }
            }
            if (selected_story_index != null) {
                story_index = selected_story_index;
            }


            $("#storyShowBox .storyShowItem .ShowData").empty();
            if (data[index].stories[story_index].type == 'video') {
                config.type = 'video'
                $("#storyShowBox .storyShowItem .ShowData").append(
                    `<video  autoPlay  class="VideoShow" src="` + data[index].stories[story_index].url + `" alt="now-found" > </video>
                   ` );


            } else {
                config.type = 'image'
                $("#storyShowBox .storyShowItem .ShowData").append(
                    `
                    <img class="ImgShow" src="`+ data[index].stories[story_index].image + `" alt="now-found" />
                       
                `);
            }

            $("#storyShowBox").fadeIn();
            config.current_index = index;
            if (story_index + 1 == data[index].stories.length) {
                $("#storyList .storyItem").eq(index).addClass("watched");
            }
            config.current_story_index = story_index;
            if (story_index > 0) {
                var progressList = $("#storyShowBox .storyProgressBar .progressBarOut");
                for (var p = 0; p < story_index; p++) {
                    $(progressList[p]).find(".progressBarIn").css("width", "100%");
                }
            }

            let time = parseFloat(data[index].stories[story_index].time);
            $("#storyShowBox .storyHeader .time").text(time.toFixed(1) + ' s');
            $(".comment .comment_count").text(self.state.datam[index].stories[story_index].comment_count);

            $(".like .like_count").text(self.state.datam[index].stories[story_index].total_like);

            if (self.state.datam[index].stories[story_index].is_like == 1) {
                $(".like img").attr("src", "https://katika-images.s3.us-east-2.amazonaws.com/category/Heart%20Icon1632822639.png");

            } else {
                $(".like img").attr("src", "https://katika-images.s3.us-east-2.amazonaws.com/category/HeartIcon1632822348.png");

            }



            startTimer(story_index);

            self.setState({
                c_index: index,
                c_story_index: story_index,
                c_story: data[index]
            })



        }

        function nextStory() {
            width = 1;
            $('.storyShowItem .StopNavigation .PauseNavigation').addClass('none');
            $('.storyShowItem .StopNavigation  .playNavigation').removeClass('none');
            $('.storyShowItem .VolumeControl .unmute').addClass('none');
            $('.storyShowItem .VolumeControl .mute').removeClass('none');
            if (data[config.current_index]) {
                data[config.current_index].stories[config.current_story_index].watched = true;
                current_index = config.current_index;
                redirect = false;
                for (var x = 0; x < data[current_index].stories.length; x++) {
                    if (data[current_index].stories[x].watched == false) {
                        openStory(current_index);
                        redirect = true;
                        break;
                    }
                }

                if (redirect == false) {
                    if (config.current_index + 1 < data.length) {
                        openStory(current_index + 1);
                    } else {
                        $("#storyShowBox").fadeOut();
                        clearInterval(timer);
                        config.timer = 0;
                    }
                }
            }


        }



        function prevStory() {
            $('.storyShowItem .StopNavigation .PauseNavigation').addClass('none');
            $('.storyShowItem .StopNavigation  .playNavigation').removeClass('none');
            $('.storyShowItem .VolumeControl .unmute').addClass('none');
            $('.storyShowItem .VolumeControl .mute').removeClass('none');
            // data[config.current_index].stories[config.current_story_index].watched = true;
            current_index = config.current_index;
            current_story_index = config.current_story_index;
            if (current_story_index > 0) {
                if (data[current_index].stories.length > current_story_index) {
                    for (var c = current_story_index - 1; c < data[current_index].stories.length; c++) {
                        data[current_index].stories[c].watched = false;
                    }
                }
                openStory(current_index, current_story_index - 1);
            } else {
                if (current_index > 0) {
                    if (config.current_index > 0) {
                        openStory(config.current_index - 1);
                    } else {
                        $("#storyShowBox").fadeOut();
                        clearInterval(timer);
                        config.timer = 0;
                    }
                }
            }
        }

        $(function () {
            loadStories();


            // loadStories();

            // leftStories();

            $("#storyList .storyItem img").on("click", function () {
                var index = $(this).parent().parent().index();
                openStory(index);
                config.current_index = index;
            });

            $("#storyList .storyItem").on("click", function () {
                var index = $(this).index();
                openStory(index);
                config.current_index = index;
            });



            $(".leftMenu .storyItem img").on("click", function () {
                var index = $(this).parent().parent().index();
                openStory(index);
                config.current_index = index;
            });

            $(".leftMenu .storyItem").on("click", function () {
                var index = $(this).index();
                openStory(index);
                config.current_index = index;
            });

            $("#storyShowBox .storyNavigation .rightNavigation").on("click", function () {
                nextStory();
            });

            $("#storyShowBox .storyNavigation .leftNavigation").on("click", function () {
                prevStory();
            });


            $('.message .review-stroy').on("click", function () {
                pause();
                $('.submit-story-reaction').prop('disabled', false);
            });

            $("input.form-control.review-stroy").on("focusout", function () {
                $('.submit-story-reaction').prop('disabled', true);
            });


            $("#storyShowBox .StopNavigation .PauseNavigation").on("click", function () {
                pause();
            });

            $("#storyShowBox .StopNavigation .playNavigation").on("click", function () {
                startTimer();
            });

            $("#storyShowBox .VolumeControl .mute").on("click", function () {
                mute();
            });

            $("#storyShowBox .VolumeControl .unmute").on("click", function () {
                unmute();
            });


            $("#storyShowBox .share .direct").on("click", function () {
                pause();
                $('#storyShowBox .share-social').removeClass('none');
            });

            $("#storyShowBox .close-share").on("click", function () {
                startTimer();
                $('#storyShowBox .share-social').addClass('none');
            });




        });


        return (
            <>
                <div className="cities  stories-landing">
                    <div className="container-fluid">
                        <ToastContainer />
                        <h2>Discover Businesses on Katika Stories</h2>
                        <div className="reviews-tabs ">
                            <div className="App">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className="storyList main" id="storyList">

                                            </div>

                                            <div className="storyShowBox" id="storyShowBox" style={{ width: '100%' }}>

                                                <div className="leftMenu storyList" style={{ width: '30%' }}>
                                                    <div className="TopHeader">
                                                        <div className="close-story" onClick={() => window.location.reload()}>
                                                            <RiCloseFill />
                                                        </div>

                                                        <div className="k-story-logo">
                                                            <img src={katika_K} alt="katika_K" />
                                                        </div>
                                                    </div>
                                                    <div className="story-title">
                                                        <h4>Stories</h4>
                                                    </div>
                                                    <div className="stories-list">
                                                        {
                                                            this.state.datam.map((list, i) => {
                                                                return (
                                                                    <div key={i} className={`storyItemLeft storyItem ${this.state.c_index == i ? 'active' : 'no'}`} user={list.user_id} index={i}  >
                                                                        <div className="storyColor1">
                                                                            <img src={list.profile} alt={list.business_name} />
                                                                        </div>
                                                                        <div className="storyDescription">
                                                                            <h6> {list.business_name}</h6>
                                                                            <p className="story-c"> {list.new} <span className="time-story-dot"></span> <span className="time-story">{list.time}</span></p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                                <div className="storyShowItem" style={{ width: '70%' }}>
                                                    <div className="video-control">
                                                        <div className="storyProgressBar"></div>
                                                        <div className="storyHeader">
                                                            <a href="#"><img src="" alt="profile" /></a>
                                                            <a href="#"><span className="name"></span></a>
                                                            <span className="time"></span>
                                                        </div>
                                                        <div className="playVolume">
                                                            <div className="StopNavigation">
                                                                <div className="PauseNavigation"><img src="https://katika-images.s3.amazonaws.com/category/pause1621598496.png" /></div>
                                                                <div className="playNavigation none"><img src="https://katika-images.s3.amazonaws.com/category/Vector%20%282%291621597067.png" /></div>
                                                            </div>
                                                            <div className="VolumeControl">
                                                                <div className="mute"> <img src="https://katika-images.s3.amazonaws.com/category/Group1621599335.png" /> </div>
                                                                <div className="unmute none"> <img src="https://katika-images.s3.amazonaws.com/category/voulme-open1622111695.png" /> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="story-arrow">
                                                        <div className="ShowData">
                                                            <img src="" alt="" />
                                                        </div>


                                                        <div className="storyNavigation">
                                                            <div className="leftNavigation"><img src="https://katika-images.s3.amazonaws.com/category/left1622112236.png" /></div>
                                                            <div className="rightNavigation"><img src="https://katika-images.s3.amazonaws.com/category/right1622112269.png" /></div>
                                                        </div>


                                                    </div>

                                                    {uToken() && (
                                                        <>



                                                            <div className="story-reaction">
                                                                <div className="form-group message">
                                                                    <input onKeyUp={(event) => this.addStoryComment(event)} type="text" placeholder={`Reply to ${this.state.c_story.business_name}...`} className="form-control review-stroy" />
                                                                    <button disabled className="submit-story-reaction"><img className="direct" src="https://katika-images.s3.amazonaws.com/category/Vector%20%281%291621511115.png" /></button>
                                                                </div>
                                                            </div>



                                                            <div className="share-social none">

                                                                <div className="shareheading">Share in you social networks</div>
                                                                <div className="close-share">
                                                                    <AiOutlineClose />
                                                                </div>
                                                                <div className="row social-share">
                                                                    <div className="col-md-3 fb">
                                                                        <ShareButton {...shareButtonPropsFb}>
                                                                            <FaFacebook />
                                                                        </ShareButton>
                                                                    </div>

                                                                    <div className="col-md-3 fb">
                                                                        <ShareButton {...shareButtonPropsPn}>
                                                                            <FaPinterest />
                                                                        </ShareButton>
                                                                    </div>

                                                                    <div className="col-md-3 fb">
                                                                        <a className="rcs-button" href="https://www.reddit.com/submit?title=custom%20title&url=https%3A%2F%2Fdev.shopkatika.com%2Fbuyer-message">
                                                                            <FaRedditAlien />
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-3 tw">
                                                                        <ShareButton {...shareButtonPropsTw}>
                                                                            <RiTwitterFill />
                                                                        </ShareButton>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="story-footer">
                                                                <div className="like" onClick={() => this.addStoryReaction(this.state.c_story.stories[this.state.c_story_index].is_like)}>
                                                                    <img className="direct heart" src={HeartIcon} />
                                                                    <p className="like_count"> 2</p>
                                                                    <p className="actionType none">{this.state.c_story_index ? this.state.c_story.stories[this.state.c_story_index].is_like : "00"}</p>
                                                                </div>
                                                                <div className="comment">
                                                                    <img className="direct" src={MessageIcon} />
                                                                    <p className="comment_count">5</p>
                                                                </div>
                                                                <div className="share">
                                                                    {/* <Link className="item-link"
                                                                to={{
                                                                    pathname: `/view-story`,
                                                                    state: {
                                                                        current: this.state.c_story.stories[this.state.c_story_index],
                                                                        image_click: this.state.c_story_index,
                                                                        images: this.state.c_story.stories
                                                                    }
                                                                }}
                                                            > */}
                                                                    <img className="direct" src={ShareIcon} />
                                                                    <p>Share</p>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>


                                            </div>

                                        </div >
                                    </div >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        );
    }
}