import React, { Component } from 'react';
import WidgetSelectFilter from "./widgets/WidgetSelectFilter";
import WidgetFilterSuggested from "./widgets/WidgetFilterSuggested";
import WidgetFilterFeatures from "./widgets/WidgetFilterFeatures";
import WidgetNeighborhoods from "./widgets/WidgetNeighborhoods";
import WidgetFilterDistance from "./widgets/WidgetFilterDistance";
import $ from 'jquery'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Label, FormGroup, CustomInput } from 'reactstrap';
import { Card, Button } from 'react-bootstrap'
import Collapsible from 'react-collapsible';

class ListingListSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // title: '1 filter',
            items: [],
            checkbox: [
                {
                    id: 1,
                    name: '$'
                },
                {
                    id: 2,
                    name: '$$'
                },
                {
                    id: 3,
                    name: '$$$'
                },
                {
                    id: 4,
                    name: '$$$$'
                }
            ],
            SelectPrice: [],
            Suggestedtitle: 'Suggested',
            SelectSuggestion: [],
            suggestion: [],
            Suggestedcategories: [
                {
                    id: 1,
                    cat: 'Open Now',
                    catNum: 11
                },
                {
                    id: 2,
                    cat: 'Katika Delivery',
                    catNum: 22
                },
                {
                    id: 3,
                    cat: 'Katika Takeout',
                    catNum: 9
                }
            ],
            Featurestitle: 'Features',
            isFeatures: false,
            SelectFeatures: [],
            SubmitFeatureSelect: [],
            FeatureItem: [],
            features: [],
            allFeatures: [],
            Neighborhoodstitle: 'Neighborhoods',
            isNeighborhoods: false,
            SelectNeighborhoods: [],
            neighborhoods: [],
            Distancetitle: 'Distance',
            dSelect: '',
            SelectDistance: '',
            distance: [
                // {
                //     active: false,
                //     text: 'Bird’s-eye View',
                //     value: 25,

                // },
                {
                    active: false,
                    text: 'Driving (5 mi.)',
                    value: 5,

                },
                {
                    active: false,
                    text: 'Biking (2 mi.)',
                    value: 2,

                },
                {
                    active: false,
                    text: 'Walking (1 mi.)',
                    value: 1,

                },
                {
                    active: false,
                    text: 'Within 4 blocks',
                    value: 0.5,

                }
            ],
            filters: [],
            SubmitFeature: [],
            SubmitNeighborhood: [],
            SubmitNeighborhoodSelect: [],
            NeighborhoodItem: [],
        }
        this.handleSuggestion = this.handleSuggestion.bind(this);
        this.handleDistance = this.handleDistance.bind(this);
        this.handlePrice = this.handlePrice.bind(this);
        this.handleFeatures = this.handleFeatures.bind(this);
        this.handleNeighborhood = this.handleNeighborhood.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.onHide = this.onHide.bind(this);
        this.SubmitFeature = this.SubmitFeature.bind(this);
        this.SubmitNeighborhood = this.SubmitNeighborhood.bind(this);

    }


    SubmitNeighborhood = (event, errors, values) => {
        // // console.log(this.state.SubmitNeighborhoodSelect, 'hi check')
        // // console.log(this.state.items, 'hi Selectitems')
        // console.log(this.state.NeighborhoodItem, 'hi SelectFeatures')

        const oldBooks = this.state.items.filter(book => !this.state.NeighborhoodItem.some(newBook => newBook === book));
        // console.log(oldBooks.concat(this.state.NeighborhoodItem) ,'consat') ;
        this.setState({
            NeighborhoodsModal: false,
            SelectNeighborhoods: this.state.SubmitNeighborhoodSelect,
            items: oldBooks.concat(this.state.NeighborhoodItem)
        })

        // console.log(this.state.items, 'newItem')
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SubmitNeighborhoodSelect,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    SubmitFeature = (event, errors, values) => {
        // console.log(this.state.SubmitFeatureSelect, 'hi check')
        // console.log(this.state.items, 'hi Selectitems')
        // console.log(this.state.FeatureItem, 'hi SelectFeatures')
        this.forceUpdate();
        const oldBooks = this.state.items.filter(book => !this.state.FeatureItem.some(newBook => newBook === book));
        // console.log(oldBooks.concat(this.state.FeatureItem)) ;
        this.setState({
            FeaturesModal: false,
            SelectFeatures: this.state.SubmitFeatureSelect,
            items: oldBooks.concat(this.state.FeatureItem)
        })

        // console.log(this.state.items, 'newItem')
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SubmitFeatureSelect,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    onHide() {
        this.setState({ FeaturesModal: false, NeighborhoodsModal: false });
    }

    componentWillReceiveProps(newProps, prevprops) {
        console.log(newProps, 'newProps')
        this.setState({
            features: newProps.filters,
            neighborhoods: newProps.neighborhoods,
            suggestion: newProps.suggested,
            allFeatures: newProps.all_features,

            // items: []

        });

        if (newProps.handlerCall) {
            this.setState({
                items: [],
                dSelect: ''
            });
        }
    }


    componentDidMount() {
        console.log('hi', this.props)
        this.setState({
            features: this.props.filters,
            neighborhoods: this.props.neighborhoods,
            suggestion: this.props.suggested,
            allFeatures: this.props.all_features,
        });
        $(document).ready(function () {
            $(".sidebar-widget .filter-by-category").css({ height: 'auto', overflow: 'hidden' })
            // $(document).on('click', '#showfilterbycategory', function () {
            //     $(".sidebar-widget .filter-by-category").css({ height: '468px', overflow: 'hidden' })
            //     $(this).addClass('lessfilterbyfeature');
            // })
            // $(document).on('click', '.lessfilterbyfeature', function () {
            //     $(".sidebar-widget .filter-by-category").css({ height: 'auto', overflow: 'hidden' })
            //     $(this).removeClass('lessfilterbyfeature');
            // })


            ////////////////////////////// Features
            $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
            $(document).on('click', '#showfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).addClass('lessfilterbyfeature');
            })
            $(document).on('click', '.lessfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).removeClass('lessfilterbyfeature');
            })

            ////////////////////////////// Neighborhoods
            $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
            $(document).on('click', '#showfilterbyneighbour', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: '468px', overflow: 'hidden' })
                $(this).addClass('lessfilterbyneighbour');
            })
            $(document).on('click', '.lessfilterbyneighbour', function () {
                $(".sidebar-widget .filter-by-feature").css({ height: 'auto', overflow: 'hidden' })
                $(this).removeClass('lessfilterbyneighbour');
            })
        })
    }

    removeFilter = () => {
        this.state.SelectSuggestion = [];
        this.state.SelectFeatures = [];
        this.state.SelectNeighborhoods = [];
        this.state.SelectDistance = [];
        this.state.SelectPrice = [];
        this.state.items = [];
        this.state.dSelect = '';
        this.state.FeatureItem = [];
        this.state.SubmitFeatureSelect = [];

        this.state.NeighborhoodItem = [];
        this.state.SubmitNeighborhoodSelect = [];

        this.forceUpdate();
        $('input[type="checkbox"]:checked').prop('checked', false);
        $('input[type="radio"]:checked').prop('checked', false);
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    handleSuggestion = (event) => {
        const target = event.target;
        var value = target.value;
        var title = target.title;
        var number = Math.floor((Math.random() * 1000) + 1);
        if (target.checked) {
            this.state.SelectSuggestion[value] = value;
            this.state.items[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SelectSuggestion.indexOf(value);
            const index1 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SelectSuggestion.splice(index, 1);
                this.state.items.splice(index1, 1);
            }
            // this.state.SelectSuggestion.splice(value, 1);
        }
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    handlePrice = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = Math.floor((Math.random() * 1000) + 1);
        if (target.checked) {
            this.state.SelectPrice[value] = text;
            this.state.items[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SelectPrice.indexOf(value);
            const index1 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SelectPrice.splice(index, 1);
                this.state.items.splice(index1, 1);
            }
            // this.state.SelectPrice.splice(value, 1);
        }
        this.forceUpdate();
        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: this.state.SelectDistance,
            price: this.state.SelectPrice,
        })
    }

    handleFeaturesFilter = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 1234;
        if (target.checked) {
            this.state.SubmitFeatureSelect[value] = text;
            this.state.FeatureItem[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SubmitFeatureSelect.indexOf(value);
            const index1 = this.state.FeatureItem.indexOf(value + '@-' + title);
            const index2 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SubmitFeatureSelect.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.FeatureItem.splice(index1, 1);
            }
            if (index2 > -1) {
                this.state.items.splice(index2, 1);
            }
            // this.state.SelectFeatures.splice(value, 1);
        }
    }

    handleFeatures = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 1234;
        if (target.checked) {
            this.state.SelectFeatures[value] = text;
            this.state.SubmitFeatureSelect[value] = text;
            this.state.items[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SelectFeatures.indexOf(value);
            const index1 = this.state.SubmitFeatureSelect.indexOf(value + '@-' + title);
            const index2 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SelectFeatures.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.SubmitFeatureSelect.splice(index1, 1);
            }
            if (index2 > -1) {
                this.state.items.splice(index2, 1);
            }
        }

        if (!this.state.FeaturesModal) {
            this.forceUpdate();
            this.props.handler1({
                suggestion: this.state.SelectSuggestion,
                features: this.state.SelectFeatures,
                neighborhoods: this.state.SelectNeighborhoods,
                distance: this.state.SelectDistance,
                price: this.state.SelectPrice,
            })
        }

    }


    handleNeighborhoodFilter = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 7893;
        if (target.checked) {
            this.state.SubmitNeighborhoodSelect[value] = text;
            this.state.NeighborhoodItem[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SubmitNeighborhoodSelect.indexOf(value);
            const index1 = this.state.NeighborhoodItem.indexOf(value + '@-' + title);
            const index2 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SubmitNeighborhoodSelect.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.NeighborhoodItem.splice(index1, 1);
            }
            if (index2 > -1) {
                this.state.items.splice(index2, 1);
            }
            // this.state.SelectFeatures.splice(value, 1);
        }

        console.log(this.state.SubmitNeighborhoodSelect, 'this.state.SubmitNeighborhoodSelect')
    }

    handleNeighborhood = (event) => {
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = 7893;
        if (target.checked) {
            this.state.SelectNeighborhoods[value] = text;
            this.state.SubmitNeighborhoodSelect[value] = text;
            this.state.items[`${value}${number}`] = value + '@-' + title;
        } else {
            const index = this.state.SelectNeighborhoods.indexOf(value);
            const index1 = this.state.SubmitNeighborhoodSelect.indexOf(value + '@-' + title);
            const index2 = this.state.items.indexOf(value + '@-' + title);
            if (index > -1) {
                this.state.SelectNeighborhoods.splice(index, 1);
            }
            if (index1 > -1) {
                this.state.SubmitNeighborhoodSelect.splice(index1, 1);
            }
            if (index2 > -1) {
                this.state.items.splice(index2, 1);
            }
        }

        if (!this.state.NeighborhoodsModal) {
            this.forceUpdate();
            this.props.handler1({
                suggestion: this.state.SelectSuggestion,
                features: this.state.SelectFeatures,
                neighborhoods: this.state.SelectNeighborhoods,
                distance: this.state.SelectDistance,
                price: this.state.SelectPrice,
            })
        }
    }

    handleDistance = (event) => {
        const target = event.target;
        var value = target.value;
        var title = target.title;
        this.setState({
            SelectDistance: value,
            dSelect: title
        });

        this.props.handler1({
            suggestion: this.state.SelectSuggestion,
            features: this.state.SelectFeatures,
            neighborhoods: this.state.SelectNeighborhoods,
            distance: value,
            price: this.state.SelectPrice,
        })
    }

    handleSubmit = () => {
        console.log(this.state.SelectSuggestion)
    }
    render() {
        const membersToRender = this.state.items.filter(member => member)
        const numRows = membersToRender.length;
        const featureToRender = this.state.SelectFeatures.filter(feature => feature)
        const totalFeature = featureToRender.length;
        const neighborhoodToRender = this.state.SelectNeighborhoods.filter(neighborhood => neighborhood)
        const totalNeighborhood = neighborhoodToRender.length;
        return (
            <>

                <div className="sidebar listing-page">
                    {/*<WidgetSearch />*/}
                    <div className="sidebar-widget">
                        <h3 className="widget-title" style={{ padding: 0 }}>
                            {(this.state.dSelect && this.state.dSelect != '') ?
                                <>
                                    {numRows + 1} -Filters
                                </>
                                :
                                <>
                                    {(numRows > 0) ? `${numRows} - Filters` : `Filters`}
                                </>
                            }

                        </h3>

                        <div className="check-box-list filter-category mt-4">
                            <ul>
                                {this.state.items.map((item, i) => {
                                    let c = item.split('@-');
                                    return (
                                        <li key={i} className="custom-checkbox filter-c">
                                            <span></span><label>
                                                {
                                                    (c.length > 0) ?
                                                        c[1]
                                                        :
                                                        ""


                                                }
                                            </label>
                                        </li>
                                    )
                                })}

                                {(this.state.dSelect) ?
                                    <li className="custom-checkbox filter-c">
                                        <span></span><label>
                                            {this.state.dSelect}
                                        </label>
                                    </li>
                                    :
                                    ""
                                }
                            </ul>
                        </div>
                        <div id="removeFilter" className="showmore-btn filter" onClick={this.removeFilter.bind(this)}>
                            <span className="showmore-txt ">Clear all</span>
                        </div>


                        <div className="check-box-list filter-category mt-4">
                            <div className="SelectPrice">

                                {this.state.checkbox.map(check => {
                                    return (
                                        <div className="custom-checkbox" key={check.id}>
                                            <input type="checkbox" title={check.name} id={'chb3-' + check.id} name={check.id} value={check.id} onChange={this.handlePrice.bind(this)} />
                                            <label htmlFor={'chb3-' + check.id}>
                                                {check.name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                    {/* <WidgetFilterSuggested /> */}

                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Suggestedtitle}
                        </h3>
                        <div className="check-box-list show-more-item filter-by-category filter-category mt-4 mb-4">

                            {this.state.suggestion.map((item, i) => {
                                return (
                                    <div className="custom-checkbox hello" key={item.id}>
                                        <input type="checkbox" title={item.name} name={item.id} value={item.id} id={'chb' + item.id} onChange={this.handleSuggestion} />
                                        <label htmlFor={'chb' + item.id}>
                                            {item.name}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* <WidgetFilterFeatures /> */}

                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Featurestitle}
                        </h3>

                        <div className="check-box-list show-more-item-filter filter-by-feature filter-category-feature" style={{ height: "auto", maxHeight: "140px", overflow: "hidden" }}>

                            {
                                (totalFeature >= 4)
                                    ?

                                    this.state.SelectFeatures.map((sFeature, key) => {
                                        return (
                                            <>
                                                {this.state.features.map((item, i) => {
                                                    return (
                                                        <>
                                                            {(sFeature == item.id) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" defaultChecked className="active" id={`chb211-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                                    <label htmlFor={'chb211-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </>
                                        );
                                    })
                                    : (totalFeature == 0) ?
                                        this.state.features.map((item, i) => {
                                            return (
                                                (i == 0 || i <= 3) ?
                                                    <div className="custom-checkbox" key={i}>
                                                        <input type="checkbox" id={`chb211-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                        <label htmlFor={'chb211-' + i}>
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                    : ""
                                            )
                                        })

                                        : (totalFeature > 0 && totalFeature <= 4) ?
                                            [
                                                this.state.SelectFeatures.map((sFeature, key) => {
                                                    return (
                                                        <>
                                                            {this.state.features.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        {(sFeature == item.id) ?
                                                                            <div className="custom-checkbox" key={i}>
                                                                                <input type="checkbox" defaultChecked className="active" id={`chb211-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                                                <label htmlFor={'chb211-' + i}>
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }),
                                                this.state.features.map((item, i) => {
                                                    return (
                                                        (i == 0 || i <= 3) ?
                                                            (!this.state.SelectFeatures.includes(`${item.id}`)) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" id={`chb211-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                                    <label htmlFor={'chb211-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            : ""
                                                    )
                                                })
                                            ]
                                            : ""
                            }


                            {/* {

                                (this.state.SelectFeatures.length > 1) ? this.state.SelectFeatures.map((sFeature, key) => {
                                    return (
                                        <>
                                            {this.state.features.map((item, i) => {
                                                return (
                                                    <>
                                                        {(sFeature == item.id) ?
                                                            <div className="custom-checkbox" key={i}>
                                                                <input type="checkbox" defaultChecked className="active" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                                <label htmlFor={'chb2-' + i}>
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                            : ""
                                                        }
                                                    </>
                                                );
                                            })}
                                        </>
                                    )
                                }) : (this.state.features) ? this.state.features.map((item, i) => {
                                    return (
                                        (i == 0 || i <= 3) ?
                                            <div className="custom-checkbox" key={i}>
                                                <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                <label htmlFor={'chb2-' + i}>
                                                    {item.name}
                                                </label>
                                            </div>
                                            : (this.state.isFeatures) ?
                                                <div className="custom-checkbox" key={i} >
                                                    <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                    <label htmlFor={'chb2-' + i}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                                : ""
                                    )
                                }) : ""} */}
                            {/* {(this.state.features) ? this.state.features.map((item, i) => {
                                return (
                                    (i == 0 || i <= 3) ?
                                        <div className="custom-checkbox" key={i}>
                                            <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                            <label htmlFor={'chb2-' + i}>
                                                {item.name}
                                            </label>
                                        </div>
                                        : (this.state.isFeatures) ?
                                            <div className="custom-checkbox" key={i} >
                                                <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeatures} />
                                                <label htmlFor={'chb2-' + i}>
                                                    {item.name}
                                                </label>
                                            </div>
                                            : ""
                                )
                            }) : ""} */}


                        </div>
                        {(this.state.features) && this.state.features.length > 2 ?

                            <div id="" className="showmore-btn filter">
                                {this.state.isFeatures ?
                                    <span className="lessmore-txt" onClick={() => this.setState({ isFeatures: false })}>Show Less</span>
                                    :
                                    <span className="showmore-txt " onClick={() => this.setState({ FeaturesModal: !this.state.FeaturesModal })}>See all</span>
                                    // <span className="showmore-txt " onClick={() => this.setState({ isFeatures: true })}>See all</span>
                                }
                            </div>
                            : " "}
                    </div>

                    <Modal className="MoreFeatures" show={this.state.FeaturesModal} onHide={this.onHide} size="lg"
                    >
                        <AvForm onSubmit={this.SubmitFeature}>
                        <Modal.Header closeButton>
                            <Modal.Title> More Features </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="sidebar listing-page">
                                
                                    {this.state.allFeatures.map((all, k) => {
                                        return (
                                            <>
                                                <Collapsible trigger={all.name} open={(k == 0) ? true : false}>
                                                    <div className="row">
                                                        {all.sub_features.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className="col-md-4"  key={i}>
                                                                        <div className="panel-body">
                                                                            <div className="custom-checkbox pop-check-box" key={i}>
                                                                                {
                                                                                    (this.state.SelectFeatures.includes(`${item.id}`)) ?
                                                                                        <input type="checkbox" defaultChecked id={`chb2-${k}${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                                        :
                                                                                        <input type="checkbox" id={`chb2-${k}${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                                }
                                                                                <label htmlFor={`chb2-${k}${i}`}>
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </ div>
                                                </Collapsible>
                                            </>
                                        );
                                    })}
                                    {/* <Collapsible trigger="General" open={true}>
                                        <div className="row">

                                            {(this.state.features) ? this.state.features.map((item, i) => {
                                                return (
                                                    <>
                                                        <div className="col-md-4">
                                                            <div className="panel-body">
                                                                <div className="custom-checkbox pop-check-box" key={i}>
                                                                    {
                                                                        (this.state.SelectFeatures.includes(`${item.id}`)) ?
                                                                            <input type="checkbox" defaultChecked id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                            :
                                                                            <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                    }
                                                                    <label htmlFor={'chb2-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }) : ""}
                                        </ div>
                                    </Collapsible> */}

                                    {/* <Accordion defaultActiveKey="0">
                                        <div className="panel-wrap">
                                            <div className="panel-header">
                                                <Accordion.Toggle className="panel-toggle remove-btn-bg" variant="link" eventKey="0">
                                                    General</Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div className="row">
                                                    {(this.state.features) ? this.state.features.map((item, i) => {
                                                        return (
                                                            <>
                                                                <div className="col-md-4">
                                                                    <div className="panel-body">
                                                                        <div className="custom-checkbox pop-check-box" key={i}>
                                                                            {
                                                                                (this.state.SelectFeatures.includes(`${item.id}`)) ?
                                                                                    <input type="checkbox" defaultChecked id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                                    :
                                                                                    <input type="checkbox" id={`chb2-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleFeaturesFilter} />
                                                                            }
                                                                            <label htmlFor={'chb2-' + i}>
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    }) : ""}
                                                </ div>
                                            </Accordion.Collapse>
                                        </div>


                                    </Accordion> */}
                                    {/* {(this.state.features) ? this.state.features.map((item, i) => {
                                        return (
                                            <>
                                                <AvInput tag={CustomInput} type="checkbox" title={item.name}
                                                    label={item.name} name={item.name}
                                                />
                                            </>
                                        );
                                    }) : ""} */}

                                    
                                

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <div className="button-modal-footer">
                                        <Button variant="secondary" onClick={() => this.setState({ FeaturesModal: !this.state.FeaturesModal })}>Close</Button>
                                        <Button type="submit" variant="primary" >Save Changes</Button>
                                    </div>
                        </Modal.Footer>
                        </AvForm>
                    </Modal>
                    {/* <WidgetNeighborhoods /> */}

                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Neighborhoodstitle}
                        </h3>

                        <div className="check-box-list show-more-item-neighbour filter-by-neighbour filter-category-neighbour" style={{ height: "auto", maxHeight: "140px", overflow: 'hidden' }}>
                            {
                                (totalNeighborhood >= 4)
                                    ?

                                    this.state.SelectNeighborhoods.map((sFeature, key) => {
                                        return (
                                            <>
                                                {this.state.neighborhoods.map((item, i) => {
                                                    return (
                                                        <>
                                                            {(sFeature == item.id) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" defaultChecked className="active" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                    <label htmlFor={'chb411-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </>
                                        );
                                    })
                                    : (totalNeighborhood == 0) ?
                                        this.state.neighborhoods.map((item, i) => {
                                            return (
                                                (i == 0 || i <= 3) ?
                                                    <div className="custom-checkbox" key={i}>
                                                        <input type="checkbox" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                        <label htmlFor={'chb411-' + i}>
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                    : ""
                                            )
                                        })

                                        : (totalNeighborhood > 0 && totalNeighborhood <= 4) ?
                                            [
                                                this.state.SelectNeighborhoods.map((sFeature, key) => {
                                                    return (
                                                        <>
                                                            {this.state.neighborhoods.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        {(sFeature == item.id) ?
                                                                            <div className="custom-checkbox" key={i}>
                                                                                <input type="checkbox" defaultChecked className="active" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                                <label htmlFor={'chb411-' + i}>
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }),
                                                this.state.neighborhoods.map((item, i) => {
                                                    return (
                                                        (i == 0 || i <= 3) ?
                                                            (!this.state.SelectNeighborhoods.includes(`${item.id}`)) ?
                                                                <div className="custom-checkbox" key={i}>
                                                                    <input type="checkbox" id={`chb411-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhood} />
                                                                    <label htmlFor={'chb411-' + i}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                                : ""
                                                            : ""
                                                    )
                                                })
                                            ]



                                            : ""
                            }

                            {/* {(this.state.neighborhoods) ? this.state.neighborhoods.map((neigh, n) => {
                                return (
                                    (n == 0 || n <= 3) ?
                                        <div className="custom-checkbox" key={n}>
                                            <input type="checkbox" id={'chb4-' + n} title={neigh.name} name={neigh.id} value={neigh.id} onChange={this.handleNeighborhood} />
                                            <label htmlFor={'chb4-' + n}>
                                                {neigh.name}
                                            </label>
                                        </div>
                                        : (this.state.isNeighborhoods) ?
                                            <div className="custom-checkbox" key={n} >
                                                <input type="checkbox" id={'chb4-' + n} title={neigh.name} name={neigh.id} value={neigh.id} onChange={this.handleNeighborhood} />
                                                <label htmlFor={'chb4-' + n}>
                                                    {neigh.name}
                                                </label>
                                            </div>
                                            : ""
                                )
                            }) : ""} */}
                        </div>
                        {(this.state.neighborhoods) && this.state.neighborhoods.length > 2 ?
                            <div id="" className="showmore-btn filter">
                                {this.state.isNeighborhoods ?
                                    <span className="lessmore-txt" onClick={() => this.setState({ isNeighborhoods: false })}>Show Less</span>
                                    :
                                    <span className="showmore-txt " onClick={() => this.setState({ NeighborhoodsModal: !this.state.NeighborhoodsModal })}>See all</span>
                                    // <span className="showmore-txt " onClick={() => this.setState({ isNeighborhoods: true })}>See all</span>
                                }



                            </div>
                            : " "}
                    </div>

                    <Modal className="NeighborhoodsModal" show={this.state.NeighborhoodsModal} onHide={this.onHide} size="lg"
                    ><AvForm onSubmit={this.SubmitNeighborhood}>
                        <Modal.Header closeButton>
                            <Modal.Title> More Neighborhoods </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="sidebar listing-page">
                                
                                    <div className="row">
                                        {(this.state.neighborhoods) ? this.state.neighborhoods.map((item, i) => {
                                            return (
                                                <>
                                                    <div className="col-md-3"  key={i}>
                                                        <div className="panel-body">
                                                            <div className="custom-checkbox pop-check-box" key={i}>
                                                                {
                                                                    (this.state.SelectNeighborhoods.includes(`${item.id}`)) ?
                                                                        <input type="checkbox" defaultChecked id={`chb4-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhoodFilter} />
                                                                        :
                                                                        <input type="checkbox" id={`chb4-${i}`} title={item.name} name={item.id} value={item.id} onChange={this.handleNeighborhoodFilter} />
                                                                }
                                                                <label htmlFor={'chb4-' + i}>
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }) : ""}
                                    </ div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer closeButton>
                        <div className="button-modal-footer">
                                        <Button variant="secondary" onClick={() => this.setState({ NeighborhoodsModal: !this.state.NeighborhoodsModal })}>Close</Button>
                                        <Button type="submit" variant="primary" >Save Changes</Button>
                                    </div>
                        </Modal.Footer>
                        </AvForm>
                    </Modal>
                    {/* <WidgetFilterDistance /> */}

                    <div className="sidebar-widget">
                        <h3 className="widget-title font-size-14px">
                            {this.state.Distancetitle}
                        </h3>
                        <ul className="rating-list distance filter-category mt-4" onChange={this.handleDistance.bind(this)}>

                            {this.state.distance.map((item, index) => {
                                return (
                                    <li key={index}>

                                        <span className="la-star">{item.text}</span>
                                        <label className="review-label">
                                            <input type="radio" defaultChecked={item.active ? 'checked' : ''} title={item.text} value={item.value} name="review-radio" />
                                            <span className="review-mark"></span>
                                        </label>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>

                </div>
            </>
        );
    }
}

export default ListingListSidebar;