import React from "react"
import markericon from '../../assets/images/katika_map_marker.png'
import { AutoApiKey } from "../../config";

const { compose, withProps } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    BicyclingLayer,
    Marker
} = require("react-google-maps");

const GeneralMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${AutoApiKey}&libraries`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `190px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(props => {

    return (
        <>
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: props.state.business_lat, lng: props.state.business_long }}
                defaultOptions={{
                    disableDefaultUI: false, // disable default map UI
                    draggable: false, // make map draggable
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale control
                    scrollwheel: true, // allow scroll wheel
                    styles: [
                        {
                            "featureType": "road",
                            "stylers": [
                                { "color": "#ffffff" }
                            ]
                        }, {
                            "featureType": "landscape",
                            "stylers": [
                                { "color": "#f5f5f5" }
                            ]
                        }, {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                { "color": "transparent" }
                            ]
                        }, {
                            "featureType": "poi",
                            "stylers": [
                                { "color": "#fefefe" }
                            ]
                        }, {
                            "elementType": "labels.text",
                            "stylers": [
                                { "saturation": 1 },
                                { "weight": 0.1 },
                                { "color": "#737980" }
                            ]
                        }
                    ],
                    icon: markericon
                }}
            >
                <Marker
                    icon={{
                        url: markericon
                    }}
                    animation={(props.animation != 0) ? 1 : 0}

                    position={{
                        lat: props.state.business_lat, // latitude to position the marker
                        lng: props.state.business_long // longitude to position the marker
                    }}
                />
                <BicyclingLayer autoUpdate />
            </GoogleMap>
        </>

    );

})





export default GeneralMap