import React, { Component } from 'react'
import new_directory from '../../assets/images/new_directory.png'
import new_directory2 from '../../assets/images/new_directory2.png'
import new_directory3 from '../../assets/images/new_directory3.png'
import Button from "../common/Button";

export default class Bussiness extends Component {
    render() {
        return (
            <>
                <div className="k-class">
                    <div className="container">
                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Make a free business page for your company</h3>
                                    <p>Have a business but need an online presence? Now you can add your business to the
                                    Katika Directory for free to market your services. Join our directory today
                and be found by local and international customers awaiting your services.</p>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Search for a service or business" url="/about-us" />
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_directory} alt="new_directory" />

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Create a free e-store to sell product</h3>
                                    <p>Have products to sell? Create a store on the Katika
                                          Marketplace to sell to your products to friends, family,
                                          and new customers! You can market your store with
                                          our free tools..</p>
                                    <div className="btn-box padding-top-30px">
                                        <Button className="radius-rounded" text="Learn about selling in the U.S. Marketplace" url="/about-us" />
                                    </div>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Learn about selling in the S.A. Marketplace" url="/about-us" />
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_directory2} alt="new_directory2" />

                            </div>
                        </div>

                        <div className="row k-inner">
                            <div className="col-md-6">
                                <div className="k-box">
                                    <h3>Manage your e-store with our shop manager tool</h3>
                                    <p>From your shop manager dashboard, you'll be able to
                                          manage orders as they come in and keep up with
                                          your inventory as it fluctuates day to day.</p>
                                    <div className="btn-box padding-top-30px padding-bottom-30px">
                                        <Button className="radius-rounded" text="Learn more about selling" url="/about-us" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={new_directory3} alt="new_directory3" />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="k-class-2 bg-black1 min-height-130">
                    <div className="container">
                        <div className="row text-align" >
                            <div className="col-md-12">
                                <h3>Katika is also a resource for creativity, information and news</h3>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
