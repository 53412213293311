import React, { Component, useRef, useState } from 'react';
import {
    CardElement, Elements, ElementsConsumer, CardNumberElement,
    CardExpiryElement, CardCvcElement, useStripe, useElements
} from '@stripe/react-stripe-js';
import { logEvent, Result, ErrorResult } from '../../utils/util';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import paymentImg from '../../assets/images/paymentImg.png'

import { GiChickenOven } from 'react-icons/gi'
import { Link } from "react-router-dom";
import Select from 'react-select'
import { BiCheck } from 'react-icons/bi';
import secureConnection from '../../assets/images/secureConnection.svg'
import { toast } from 'react-toastify';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl, StripePublishableKey } from '../../config';
function CheckoutForm(props) {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    let form = useRef(null); // ref => { current: null }

    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (e, values) => {
        // e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setPaymentLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });

        if (error) {
            toast.error(error.message);
            setPaymentLoading(false);
        }

        if (paymentMethod) {
            values.token = paymentMethod;
            axios.post(ApiUrl + 'update/customer/information', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        setPaymentLoading(false);
                        window.location.reload();
                    } else {
                        toast.error(res.data.message);
                        setPaymentLoading(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setPaymentLoading(false);
                });
        }

        // if (paymentResult.error) {
        //     alert(paymentResult.error.message);
        // } else {
        //     if (paymentResult.paymentIntent.status === "succeeded") {
        //         alert("Success!");
        //     }
        // }
    };

    const ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };


    const states = [
        { "label": "Alabama" },
        { "label": "Alaska" },
        { "label": "Arizona" },
        { "label": "Arkansas" },
        { "label": "California" },
        { "label": "Colorado" },
        { "label": "Columbia" },
        { "label": "Connecticut" },
        { "label": "Delaware" },
        { "label": "Florida" },
        { "label": "Georgia" },
        { "label": "Hawaii" },
        { "label": "Idaho" },
        { "label": "Illinois" },
        { "label": "Indiana" },
        { "label": "Iowa" },
        { "label": "Kansas" },
        { "label": "Kentucky" },
        { "label": "Louisiana" },
        { "label": "Maine" },
        { "label": "Maryland" },
        { "label": "Massachusetts" },
        { "label": "Michigan" },
        { "label": "Minnesota" },
        { "label": "Mississippi" },
        { "label": "Missouri" },
        { "label": "Montana" },
        { "label": "Nebraska" },
        { "label": "Nevada" },
        { "label": "New Hampshire" },
        { "label": "New Jersey" },
        { "label": "New Mexico" },
        { "label": "New York" },
        { "label": "North Carolina" },
        { "label": "North Dakota" },
        { "label": "Ohio" },
        { "label": "Oklahoma" },
        { "label": "Oregon" },
        { "label": "Pennsylvania" },
        { "label": "Rhode Island" },
        { "label": "South Carolina" },
        { "label": "South Dakota" },
        { "label": "Tennessee" },
        { "label": "Texas" },
        { "label": "Utah" },
        { "label": "Vermont" },
        { "label": "Virginia" },
        { "label": "Washington" },
        { "label": "West Virginia" },
        { "label": "Wisconsin" },
        { "label": "Wyoming" }
    ];


    return (

        <div className="row">
            <div className="col-lg-8">
                <AvForm
                    role="form"
                    method="post"
                    class="require-validation"
                    data-cc-on-file="false"
                    data-stripe-publishable-key={StripePublishableKey}
                    id="payment-form"
                    // ref={form}
                    ref={(ref) => { form = ref; }}
                    onValidSubmit={handleSubmit}>
                    <div className="billing-form-item">
                        <div className="payment-option">
                            <div className="billing-title-wrap">
                                <h3 className="widget-title pb-0">Payment Method</h3>
                                <div className="title-shape margin-top-10px mb-4"></div>

                                <p className='p-method'>Please select a payment method most convenient to you.</p>
                            </div>
                            <div className="payment-method-wrap p-4">
                                <div className="payment-tab  p-2">
                                    <div className="payment-trigger">
                                        <div className="payment-radio">
                                            <input checked type="radio" name="radio" />
                                            <span className="checkmark"></span>
                                            <span>Pay with Credit Card</span>
                                            <span className="card-icon float-right">
                                                <img src={paymentImg} alt="Payment" />
                                            </span>
                                            </div>
                                            <div className="payment-content payment-active d-block mt-3">

                                            <div className="contact-form-action">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Card Number</label>
                                                            {/* <AvGroup> */}
                                                            {/* <CardElement /> */}
                                                            <CardNumberElement
                                                                id="cardNumber"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>


                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Expiration Date</label>
                                                            {/* <AvGroup> */}
                                                            <CardExpiryElement
                                                                id="expiry"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Card Security Code</label>
                                                            {/* <AvGroup> */}
                                                            <CardCvcElement
                                                                id="cvc"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <a className='cvv' href='#' >What is this</a>
                                                            {/* <label className="label-text">Card Security Code</label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-box mt-4">
                                    <div className="custom-checkbox">
                                        <label htmlFor="chb1" className='secure-note'>
                                            <img className='secure-connection mr-3' src={secureConnection} />
                                            <p className='p-method'>We protect your payment information using encryption to provide bank-level security.</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="billing-form-item">
                        <div className="billing-title-wrap">
                            <h3 className="widget-title pb-0">Billing Address</h3>
                            <div className="title-shape margin-top-10px"></div>
                        </div>
                        <div className="billing-content">
                            <div className="contact-form-action">
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">First Name</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="First Name"
                                                    className="form-control"
                                                    name="fname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Last Name</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Last Name"
                                                    className="form-control"
                                                    name="lname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Email</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Email"
                                                    className="form-control"
                                                    name="email"
                                                    type="email"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Address</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Address"
                                                    className="form-control"
                                                    name="address"
                                                    type="textarea"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12">

                                        <div className="input-box state-checkout-input">
                                            <label className="label-text" style={{zIndex: 999999}}>State</label>
                                            <Select
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.label}
                                                placeholder='Select State'
                                                options={states}
                                                isMulti={false}
                                                name="state"
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">City</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="City"
                                                    className="form-control"
                                                    name="city"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">ZipCode</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Zip Code"
                                                    className="form-control"
                                                    name="zipcode"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Phone</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Phone"
                                                    className="form-control"
                                                    name="phone"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-block-2 mt-4"></div>
                    </div>

                    <div className="btn-box mt-4 purchase text-align-center">
                        <button
                            disabled={isPaymentLoading}
                            type="submit" className="theme-btn border-0 mt-3">
                            {isPaymentLoading ? 'Loading' : 'Update Information'}
                        </button>

                        <button
                            onClick={() => window.history.go(-1)}
                            type="button" className="btn btn-lg  btn-danger border-0 ml-2">
                            Cancel
                        </button>
                    </div>


                </AvForm>

            </div>
        </div>

    );
}

export default CheckoutForm;
