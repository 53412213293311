import React, { Component } from 'react'
import directoryv3 from '../../assets/images/directoryv3.jpg'
import marketplacev3 from '../../assets/images/marketplacev3.jpg'
import experiencesv3 from '../../assets/images/experiencesv3.jpg'
import { Link } from "react-router-dom";



export default class BannerOne extends Component {
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 pl-0 pr-0">
                            <div className="landing-box">
                                <img src={directoryv3} alt="directoryv3" />
                                <div className="overlay">
                                    <div>
                                        <h1>Directory</h1>
                                        <Link to="/directories">Enter</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 pl-0 pr-0">
                            <div className="landing-box">
                                <img src={marketplacev3} alt="marketplacev3" />
                                <div className="overlay">
                                    <div>
                                        <h1>Marketplace</h1>
                                        <Link to="/marketplace">Enter</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 pl-0 pr-0">
                            <div className="landing-box">
                                <img src={experiencesv3} alt="experiencesv3" />
                                <div className="overlay">
                                    <div>
                                        <h1>Experiences</h1>
                                        <Link to="/experiences">Enter</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
