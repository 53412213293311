import React, { Component } from 'react'
/*import BannerOneHeroHeading from './BannerOneHeroHeading'*/
import BannerOneSearchInput from './BannerOneSearchInput'
import HighlightedCategory from "../other/categories/HighlightedCategory";
import banner_img from '../../assets/images/banner_img.png';
import BottomHeading from '../directories/BottomHeading'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';

export default class BannerOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: 'Philadelphia, PA',
            city: 'Philadelphia',
            externalData: false,
            img: '',
            isMouseInside: false,

        }
    }

    handleBoxToggle = () => this.setState({ isMouseInside: true });

    handleBoxHide = () => this.setState({ isMouseInside: false });


    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));

        if (loc != null) {
            this.setState({
                location: loc.city + ', ' + loc.region,
                city: loc.city
            })
            this.getLandingPageBackgroundImage(loc.city);
        } else {
            this.getLandingPageBackgroundImage(this.state.city);
        }

    }

    getLandingPageBackgroundImage = (city) => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getLandingPageBackgroundImage', { city: city }, {
            headers: {}
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        img: res.data.lists.background_image,
                        name: res.data.lists.business_name,
                        url: res.data.lists.business_url,
                        business_rating: res.data.lists.business_rating,
                        business_slug: res.data.lists.business_slug,
                        business_reviews: res.data.lists.business_reviews,
                        business_category: res.data.lists.business_category,
                        externalData: true,
                        uName: res.data.lists.user_name,
                    })
                } else {
                    self.setState({
                        img: banner_img,
                        externalData: true,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <section className="hero-wrapper hero-wrapper2" style={{ backgroundImage: 'url(' + this.state.img + ')' }}>
                    {/* <div className="hero-overlay"></div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                {/* Banner One Search Input */}
                                <BannerOneSearchInput />

                                {/* Banner One Categories */}
                                {/* <HighlightedCategory /> */}

                                {/* Banner One Hero Heading */}
                                {/* <BannerOneHeroHeading /> */}


                            </div>
                        </div>
                    </div>
                    <div className="bottom-heading main-page" >
                        <div className="section-heading display-inline">
                            <div className="col-lg-12">
                                <div className={`ShowHover ${(this.state.isMouseInside) ? " d-flex" : "d-none "}`} onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                    <div className="HoverItem" key={0}>
                                        <div className="hover-image">
                                            <img src={this.state.img} className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="DownArrow"></div>
                                    <div className="hover-active-details">
                                        <Link to={{
                                            pathname: `/biz/${this.state.business_slug}`,
                                        }} >{this.state.name}</Link>
                                        <div className="rating-rating  rating-detail landing">
                                            <Rating
                                                placeholderRating={this.state.business_rating}
                                                readonly={true}
                                                stop={5}
                                                emptySymbol={<img src={Empty} className="icon" />}
                                                placeholderSymbol={<img src={Fill} className="icon" />}
                                                fullSymbol={<img src={Fill} className="icon" />}
                                            />
                                            <p className="count">{this.state.business_reviews} reviews</p>
                                            <br />
                                            <p className="count">{this.state.business_category}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link to={{
                                pathname: `/biz/${this.state.business_slug}`,
                            }} className="mouseOver" onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                <h4 className={'sec__title ' + this.state.namea}>{this.state.name}</h4>
                            </Link>
                            <br />
                            <a href={this.state.url} className="display-inline">
                                <p className={'main-p sec__desc ' + this.state.uNamea}> {this.state.location}</p>
                            </a>
                        </div>
                    </div>
                </section>
            </>
        )
    }


}




