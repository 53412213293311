import React, { Component, useState } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import ScheduleTabs from "../";


import { ToastContainer, toast } from 'react-toastify';
import breadcrumbimg from '../../assets/images/bread-bg.jpg'
import placeimg from '../../assets/images/img25.jpg'


import { loadStripe } from '@stripe/stripe-js';

import { CardElement, Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import UpdateCheckoutForm from "./UpdateCheckoutForm.js"
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl, StripePublishableKey } from '../../config';


class CheckOut extends Component {
    constructor() {
        super();
      
    }


    render() {
        const stripePromise = loadStripe(StripePublishableKey);

        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>

                        <Col lg={12} md={12} id="page-content-wrapper" className='checkout-page'>
                            <section className="dashboard-area">
                                <Elements stripe={stripePromise}>
                                    <UpdateCheckoutForm  />
                                </Elements>

                            </section>
                            <ToastContainer />
                        </Col>
                    </Row >
                </Container >
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default CheckOut;

