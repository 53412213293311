import React, { Component } from 'react';
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Footer from "../../components/common/footer/Footer";
import BuyerForm from "../../components/user/BuyerForm";
import { Container } from "react-bootstrap";
import '../../assets/css/style2.css';
import { Link, Redirect } from "react-router-dom";
import { FaFacebook, FaTwitter, FaApple } from "react-icons/fa";
import NavbarListing from '../../components/common/NavbarListing';
import Tick from "../../assets/images/a-check.png";
import Google from "../../assets/images/google.png";
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';


class PrivacySettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: {
                apple: "No",
                facebook: "No",
                facebook_friends: "No",
                google: "No",
                twitter: "No",
            },
            user: '',
            externalData: false,
        }
        this.handler = this.handler.bind(this)
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    componentDidMount() {
        this.getProfile();
    }

    handleFBSubmit = (event) => {
        event.preventDefault()
        var self = this;
        if (event.target) {
            axios.post(ApiUrl + 'updateBuyerSocialMediaConnections', { 'facebook_friends': event.target.value }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.getProfile();
                    } else {
                        toast.error(res.data.message);
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    SociaConnection = (data) => {
        var self = this;
        axios.post(ApiUrl + 'updateBuyerSocialMediaConnections', data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getProfile();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });


    }

    getProfile = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getBuyerSocialMediaConnections', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        profile: res.data.lists,
                        emails: res.data.lists.user_email,
                        externalData: true,
                    })
                } else {
                    self.setState({
                        externalData: true,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerEmailNoti">
                {/* Header */}
                {/* <GeneralHeader /> */}
                <NavbarListing handler={this.handler} />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}



                <Container>

                    <div className="Buyer-wrapper BuyerEmail">
                        <div className="sidebarBuyer">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent">
                            <div className="faq-forum">
                                <div className="contact-form-action">
                                    <div className="BuyerEmailList">
                                        <div className="BuyerEmail">
                                            <div className="BuyerPagesHeading">Facebook</div>
                                            <p>Share your reviews on Facebook</p>
                                        </div>
                                    </div>
                                    <div className="EmailKatika E-Applications">

                                        <div class="CheckFB">
                                            {this.state.profile.facebook == 'Yes' ?
                                                <>
                                                    <img src={Tick} alt="Copy" />You’re connected to Facebook </>
                                                :
                                                <>
                                                    You’re not connected to Facebook </>
                                            }
                                        </div>
                                        <p>Automatically add friends from Facebook when they  join Katika</p>
                                        <AvForm onSubmit="#" id="form-button">
                                            <div className="input-box">
                                                <AvRadioGroup value={this.state.profile.facebook_friends} onChange={(e) => this.handleFBSubmit(e)} name="facebook_friends" required errorMessage="Pick one!">
                                                    <AvRadio value="Yes" label="Turn On" />
                                                    <AvRadio value="No" label="Turn Off" />
                                                </AvRadioGroup>
                                            </div>
                                        </AvForm>
                                        <div className="FBDisconnectBtn">
                                            {
                                                this.state.profile.facebook == 'Yes' ?
                                                    <button disabled type="button"><FaFacebook /> Disconnect from Facebook</button>
                                                    // <button onClick={() => this.SociaConnection({ 'facebook': 'No' })} type="button"><FaFacebook /> Disconnect from Facebook</button>
                                                    :
                                                    <button disabled type="button"><FaFacebook /> Connect from Facebook</button>
                                                    // <button onClick={() => this.SociaConnection({ 'facebook': 'Yes' })} type="button"><FaFacebook /> Connect from Facebook</button>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="BuyerEmailList padding-top-50px">
                                        <div className="BuyerEmail">
                                            <div className="BuyerPagesHeading">Twitter</div>
                                            <p>Tweet your reviews on Twitter</p>
                                        </div>
                                    </div>
                                    <div className="EmailKatika E-Applications Twitter">
                                        <p>When you write new reviews, automatically have Katika post a link to Twitter and spread word!</p>
                                        <p>You will always have an option not to post a review to Twitter</p>
                                        <div className="TWDisconnectBtn">
                                            {
                                                this.state.profile.twitter == 'Yes' ?
                                                    <button onClick={() => this.SociaConnection({ 'twitter': 'No' })}><FaTwitter /> Disconnect to Twitter</button>
                                                    :
                                                    <button onClick={() => this.SociaConnection({ 'twitter': 'Yes' })}><FaTwitter /> Connect to Twitter</button>
                                            }


                                        </div>
                                    </div> */}
                                    <div className="BuyerEmailList padding-top-50px">
                                        <div className="BuyerEmail">
                                            <div className="BuyerPagesHeading">Google</div>
                                            <p>Connect to Google</p>
                                        </div>
                                    </div>
                                    <div className="EmailKatika E-Applications Google">
                                        {
                                            this.state.profile.google == 'Yes' ?
                                                <div class="CheckFB"><img src={Tick} alt="Tick" /> You’re connected to Google</div>
                                                :
                                                <div class="CheckFB">You’re not connected to Google</div>
                                        }

                                        <div className="GDisconnectBtn">
                                            {
                                                this.state.profile.google == 'Yes' ?
                                                // <button onClick={() => this.SociaConnection({ 'google': 'No' })}><img src={Google} alt="Google" /> Disconnect from Google</button>
                                                    <button disabled><img src={Google} alt="Google" /> Disconnect from Google</button>
                                                    :
                                                    <button disabled><img src={Google} alt="Google" /> Connect from Google</button>
                                                    // <button onClick={() => this.SociaConnection({ 'google': 'Yes' })}><img src={Google} alt="Google" /> Connect from Google</button>
                                            }

                                        </div>
                                    </div>
                                    <div className="BuyerEmailList padding-top-50px">
                                        <div className="BuyerEmail">
                                            <div className="BuyerPagesHeading">Apple</div>
                                            <p>Connect to Apple</p>
                                        </div>
                                    </div>
                                    <div className="EmailKatika E-Applications Apple">
                                        <div className="AppleDisconnectBtn">
                                            {
                                                this.state.profile.apple == 'Yes' ?
                                                // <button onClick={() => this.SociaConnection({ 'apple': 'No' })}><FaApple /> Disconnect to Apple</button>
                                                    <button disabled><FaApple /> Disconnect to Apple</button>
                                                    :
                                                    <button disabled><FaApple /> Connect to Apple</button>
                                                    // <button onClick={() => this.SociaConnection({ 'apple': 'Yes' })}><FaApple /> Connect to Apple</button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default PrivacySettings;