import React, { useEffect } from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Press = () => {

  return (
    <main className="BusinessHome FaqPages PRESS">
      <Helmet>
        <title>{`Press`}</title>
        <meta name="title" content={`Press`} />
        <meta name="description"
          content={`Read about the latest happenings with Katika, as we are featured in media outlets.`} />
        <meta property="og:title" content={`Press`} />
        <meta property="og:description"
          content={`Read about the latest happenings with Katika, as we are featured in media outlets.`} />
      </Helmet>
      {/* Header */}
      <HomePageHeader />
      <div className='d-none d-lg-block d-xl-block'>
        <div className="CommonBreadcrumb">
          <h1>PRESS</h1>
        </div>
        <Container>
          <Row>
            <Col sm={12}>
              <div className="PressPage PressPages padding-top-60px border-bottom">
                <h2 className='mb-4'>Katika in the Press</h2>
                <p>Find stories of Katika in the news for events and initiatives impacting our local communities.</p>
                <div className="Lists padding-top-70px">
                  <ul>
                    <li>
                      <span></span><h5>The Grio - 7/06/20</h5>
                      <a target="_blank" href="https://thegrio.com/2020/07/06/buying-black/" >Why the buying Black trend should be a permanent lifestyle</a>
                    </li>
                    <li>
                      <span></span><h5>Philly Magazine - 7/06/20</h5>
                      <a target="_blank" href="https://www.phillymag.com/news/2020/07/06/philadelphia-black-owned-businesses-directory/" >We Need to Support Black-Owned Businesses. Here Are 300+ Places in Philly to Start</a>
                    </li>
                    <li>
                      <span></span><h5>NBC10 - 6/23/20</h5>
                      <a target="_blank" href="https://www.nbcphiladelphia.com/entertainment/philly-live/curbside-restaurant-week-aims-to-support-black-owned-restaurants/2446478/?fbclid=IwAR09329XnK6GillXSjaW1jp42f5YF9RlgBTv_2v9lJ6w6eK_SKKXl0W195w" >Curbside Restaurant Week Aims to Support Black Owned Restaurants</a>
                    </li>
                    <li>
                      <span></span><h5>NBC10 - 6/21/20</h5>
                      <a target="_blank" href="https://www.nbcphiladelphia.com/news/local/nbc10-issue/movement-grows-in-support-of-black-owned-businesses/2441453/" >Movement Grows in Support of Black-Owned Businesses</a>
                    </li>
                    <li>
                      <span></span><h5>IT Web - 6/18/19</h5>
                      <a target="_blank" href="https://www.itweb.co.za/content/Gb3Bw7Wo6e172k6V" >Directory app for black-owned business lands in SA</a>
                    </li>
                    <li>
                      <span></span><h5>The Philadelphia Tribune - 11/06/18</h5>
                      <a target="_blank" href="https://www.phillytrib.com/news/new-app-katika-aims-to-connect-consumers-to-minority-owned/article_5cc71a69-9192-5bbc-9909-a243b5e4842f.html" >New app Katika aims to connect consumers to minority owned businesses</a>
                    </li>
                  </ul>
                  <div className="PressKitBtn padding-top-140px padding-bottom-100px">
                    <a target="_blank" href="https://www.shopkatika.com/katika/press.pdf" download className="kit-btn">Press Kit</a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='d-block d-sm-none'>
        <div className='MobilePress'>
          <div className="CommonBreadcrumb">
            <h1>PRESS</h1>
          </div>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="PressPage padding-top-60px">
                  <h2>Katika in the Press</h2>
                  <p>Find stories of Katika in the news for events and initiatives impacting our local communities.</p>
                  <div className="Lists padding-top-70px">
                    <ul>
                      <li>
                        <span></span><h5>The Grio - 7/06/20</h5>
                        <a target="_blank" href="https://thegrio.com/2020/07/06/buying-black/" >Why the buying Black trend should be a permanent lifestyle</a>
                      </li>
                      <li>
                        <span></span><h5>Philly Magazine - 7/06/20</h5>
                        <a target="_blank" href="https://www.phillymag.com/news/2020/07/06/philadelphia-black-owned-businesses-directory/" >We Need to Support Black-Owned Businesses. Here Are 300+ Places in Philly to Start</a>
                      </li>
                      <li>
                        <span></span><h5>NBC10 - 6/23/20</h5>
                        <a target="_blank" href="https://www.nbcphiladelphia.com/entertainment/philly-live/curbside-restaurant-week-aims-to-support-black-owned-restaurants/2446478/?fbclid=IwAR09329XnK6GillXSjaW1jp42f5YF9RlgBTv_2v9lJ6w6eK_SKKXl0W195w" >Curbside Restaurant Week Aims to Support Black Owned Restaurants</a>
                      </li>
                      <li>
                        <span></span><h5>NBC10 - 6/21/20</h5>
                        <a target="_blank" href="https://www.nbcphiladelphia.com/news/local/nbc10-issue/movement-grows-in-support-of-black-owned-businesses/2441453/" >Movement Grows in Support of Black-Owned Businesses</a>
                      </li>
                      <li>
                        <span></span><h5>IT Web - 6/18/19</h5>
                        <a target="_blank" href="https://www.itweb.co.za/content/Gb3Bw7Wo6e172k6V" >Directory app for black-owned business lands in SA</a>
                      </li>
                      <li>
                        <span></span><h5>The Philadelphia Tribune - 11/06/18</h5>
                        <a target="_blank" href="https://www.phillytrib.com/news/new-app-katika-aims-to-connect-consumers-to-minority-owned/article_5cc71a69-9192-5bbc-9909-a243b5e4842f.html" >New app Katika aims to connect consumers to minority owned businesses</a>
                      </li>
                    </ul>
                    {/* <div className="PressKitBtn padding-top-140px padding-bottom-100px">
                      <a target="_blank" href="https://www.shopkatika.com/katika/press.pdf" download className="kit-btn">Press Kit</a>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>



      <Footer />
      <ScrollTopBtn />
    </main>
  )
}

export default Press;