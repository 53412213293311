import React, {Component} from 'react';
import { MdStar } from 'react-icons/md'

class WidgetFilterRatings extends Component {
    state = {
        title: 'Distance',
        ratings: [
            {
                active: true,
                text:'Bird’s-eye View',
               
            },
            {
                active: false,
                text:'Driving (5 mi.)',
               
            },
            {
                active: false,
                text:'Biking (2 mi.)',
               
            },
            {
                active: false,
                text:'Walking (1 mi.)',
                
            },
            {
                active: false,
                text:'Within 4 blocks',
                
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>
                    <ul className="rating-list distance filter-category mt-4">

                        {this.state.ratings.map((item, index) => {
                            return (
                                <li key={index}>
                                    
                                     <span  className="la-star">{item.text}</span>
                                

                                    <label className="review-label">
                                        <input type="radio" defaultChecked={item.active ? 'checked' : ''} name="review-radio" />
                                        <span className="review-mark"></span>
                                    </label>
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </>
        );
    }
}

export default WidgetFilterRatings;