import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import AccordionListGeneral from "../../components/business/AccordionListGeneral";
import AccordionListOwners from "../../components/business/AccordionListOwners";
import Member from '../../assets/images/TeamMember.png';
import umba from '../../assets/partners/blk_diamond.png';
import recphilly from '../../assets/partners/bscott.jpeg';
import philly from '../../assets/partners/day1day2.png';
import pact from '../../assets/partners/ncobra.png';
import bblc from '../../assets/partners/unia.png';
import b3401 from '../../assets/partners/unia.png';

import umba1 from '../../assets/images/umba.jpg';
import recphilly1 from '../../assets/images/recphilly.jpg';
import philly1 from '../../assets/images/philly.jpg';
import pact1 from '../../assets/images/pact.jpg';
import bblc1 from '../../assets/images/bblc.jpg';
import b34011 from '../../assets/images/3401.jpg';


import { Helmet } from "react-helmet";
import { buyerUrl } from '../../config';

const Business = () => {

    return (
        <main className="BusinessHome FaqPages Partners">
            {/* Header */}
            <Helmet>
                <title>{`Partners`}</title>
                <meta name="title" content={`Partners`} />
                <meta name="description"
                    content={`We work with amazing organizations who have similar ideals and interest for creating a more inclusive world.`} />
                <meta property="og:title" content={`Partners`} />
                <meta property="og:description"
                    content={`We work with amazing organizations who have similar ideals and interest for creating a more inclusive world.`} />
            </Helmet>
            <HomePageHeader />
            <div className='d-none d-lg-block d-xl-block'>
                <div className="CommonBreadcrumb">
                    <h1>Partners</h1>
                </div>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="PressPage padding-top-60px padding-bottom-10px">
                                <h2 className='color-primary'>Stronger Together</h2>
                                <p className='w-100 color-black'>Katika values strong allied partners to educate and support the <b>black community.</b></p>
                            </div>
                        </Col>
                        <section className="padding-bottom-40px">
                            <Row>
                                <Col sm={12}>
                                    <div className="partners text-start color-black">
                                        <p className='color-black'><b>A Katika partner is</b> an entity working toward connecting their resources to black businesses on our platform.  A Katika partner is truly an ally - working aongside us to educate, empower, and uplift our community.</p>
                                        <p className='color-black'>
                                            <b>Together, we create</b> a new standard of business engagement within the black community.
                                        </p>
                                        <p className='color-black'><b>Partnering with Katika looks like</b> advocating for, investing in, and fighting for black businesses. Here’s how some of our partners have invested in our  community:
                                            <ul className='text-start partners-bullet'>
                                                <li>Department of Commerce - promotes the Katika app during annual events such as Curbside Restaurant Week</li>
                                                <li>RecPhilly - creates a retail pipeline for black businesses on Katika’s platform to sell products in their stores</li>
                                                <li>Black Doctors Association - supports their directory of black doctors by connecting their private practices to Katika’s marketing tools and resources</li>
                                                <li>Universal Muslim Business Association - facilitates educational programming opportunities within their network of black businesses</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className='partnersBottom text-center margin-top-80px'>
                                        <h2 className='color-black margin-bottom-50px'>Inerested in partnering with Katika?</h2>
                                        <a href={`${buyerUrl}/get-help`} className='btn btn-primary rounded-50'>Contact Us</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='OfficialPartners margin-top-70px text-center'>
                                        <h2>Official Partners</h2>
                                    </div>
                                </Col>
                            </Row>
                        </section>
                        <section className="TeamItem about-team padding-bottom-40px border-bottom PartnerLOGO">
                            <Row>
                                <Col lg={12} className="d-flex justify-content-between mb-4">
                                    <div className="Items">
                                        <img src={umba1} alt="umba" />
                                    </div>
                                    <div className="Items">
                                        <img src={recphilly1} alt="recphilly" />
                                    </div>
                                    <div className="Items">
                                        <img src={philly1} alt="philly" />
                                    </div>
                                    <div className="Items">
                                        <img src={pact1} alt="pact" />
                                    </div>
                                    <div className="Items">
                                        <img src={b34011} alt="b3401" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="d-flex justify-content-between mb-4">

                                    <div className="Items">
                                        <img src={umba} alt="umba" />
                                    </div>
                                    <div className="Items">
                                        <img src={recphilly} alt="recphilly" />
                                    </div>
                                    <div className="Items">
                                        <img src={philly} alt="philly" />
                                    </div>
                                    <div className="Items">
                                        <img src={pact} alt="pact" />
                                    </div>
                                    <div className="Items">
                                        <img src={b3401} alt="b3401" />
                                    </div>

                                </Col>
                            </Row>

                        </section>
                    </Row>
                </Container>
            </div>
            <div className='d-block d-sm-none'>
                <div className="MobilePartner">
                    <div className="CommonBreadcrumb">
                        <h1>Partners</h1>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="PressPage padding-top-60px padding-bottom-20px color-black partners">
                                    <h2>Stronger Together.</h2>
                                    <p className='color-black mb-3'>Katika values strong allied partners to educate and support the <b>black community.</b> </p>
                                    <p className='color-black mb-3'><b>A Katika partner is</b>  an entity working toward connecting their resources to black businesses on our platform.  A Katika partner is truly an ally - working aongside us to educate, empower, and uplift our community.</p>
                                    <p className='color-black'><b>Together, we create</b>  a new standard of business engagement within the black community.</p>
                                    <h5 className='text-center color-primary mt-4'>Inerested in partnering with Katika?</h5>
                                    <a href={`${buyerUrl}/get-help`} className='btn btn-primary rounded-50 mt-4'>Contact Us</a>
                                    <p className='color-black mt-5'><b>Partnering with Katika looks like</b> advocating for, investing in, and fighting for black businesses. </p>
                                    <p className='color-black mt-5 mb-2'><b>Partners  can be:</b></p>
                                    <ul className='text-start'>
                                        <li>Government agencies</li>
                                        <li>Non-profit organizations</li>
                                        <li>Business associations</li>
                                        <li>Civic organizations</li>
                                        <li>For-profit corporations</li>
                                        <li>Charitable organizations</li>
                                        <li>Event Venues</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={12}>

                                <section className="TeamItem about-team padding-bottom-100px PartnerLOGO">
                                    <div className="Items">
                                        <img src={umba1} alt="umba" />
                                    </div>
                                    <div className="Items">
                                        <img src={recphilly1} alt="recphilly" />
                                    </div>
                                    <div className="Items">
                                        <img src={philly1} alt="philly" />
                                    </div>
                                    <div className="Items">
                                        <img src={pact1} alt="pact" />
                                    </div>
                                    <div className="Items">
                                        <img src={b34011} alt="b3401" />
                                    </div>
                                 
                                    <div className="Items">
                                        <img src={umba} alt="umba" />
                                    </div>
                                    <div className="Items">
                                        <img src={recphilly} alt="recphilly" />
                                    </div>
                                    <div className="Items">
                                        <img src={philly} alt="philly" />
                                    </div>
                                    <div className="Items">
                                        <img src={pact} alt="pact" />
                                    </div>
                                    <div className="Items">
                                        <img src={b3401} alt="b3401" />
                                    </div>
                                    {/* <div className="Items">
                                    <img src={bblc} alt="bblc" />
                                </div> */}
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
            <ScrollTopBtn />
        </main>
    )
}

export default Business;