import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { uInfo } from '../../useToken';




class Directory extends Component {
    state = {
        menus: [
            {
                title: 'Profile',
                url: '/update-buyer-profile'
            },
            {
                title: 'Password',
                url: '/user-security'
            },
            {
                title: 'Email / Notifications',
                url: '/user-email-notifications'
            },
            {
                title: 'Locations',
                url: '/user-location'
            },
            {
                title: 'Friends',
                url: '/user-manage-friends'
            },
            {
                title: 'Privacy Settings',
                url: '/user-privacy-settings'
            },
            {
                title: 'External Applications',
                url: '/external-applications'
            }

        ]
    }
    render() {
        return (
            <>
                <div className="sidebar section-bg mt-2 mb-4 BuyerProfile">
                    <div className="sidebar-widget">
                        <div className="section-block-2 mb-3"></div>
                        <div className="BuyerName">
                            {uInfo().full_name}’s Account Settings
                            </div>
                        <div className="info-list">

                            <ul className="list-items">
                                {this.state.menus.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={item.url} className={(window.location.pathname == item.url) ? 'active' : ''}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Directory;