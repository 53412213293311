import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai'


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 0,
    marginRight: 0,
    width: '100%',
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    alignItems: 'start'
};

const img = {
    display: 'block',
    width: 'auto',
    maxWidth: '200px',
    height: '100px'
};

function PhotoUploader2(props) {
    const [myFiles, setMyFiles] = useState([])
    const onDrop = useCallback(acceptedFiles => {
        // setMyFiles([...myFiles, ...acceptedFiles])
        setMyFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

    }, [myFiles]);

    const { getRootProps, getInputProps, acceptedFileItems, fileRejections } = useDropzone({
        onDrop, maxFiles: 1, accept: 'image/*',
    });

    const Collapse = () => {
        props.closeModal()
    }

    const submitForm = async e => {
        e.preventDefault();
        if (myFiles.length > 0) {

            var formData = new FormData();
            myFiles.map((item) =>
                formData.append('image', item)
            );

            // formData.append('business_images', myFiles);
            var type = myFiles[0].type;
            axios.post(ApiUrl + 'updateProfileImage', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        props.closeModal(res.data.message)
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                        // props.closeModal(res.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.warning('Select Images');
        }
    }

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }
    const thumbs = myFiles.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="Author Profile"
                />
                <button
                    data-toggle="tooltip" data-placement="bottom" title="Remove"
                    className="btn btn-default badge-toggle b-left"
                    onClick={removeFile(file)}>
                    <AiOutlineClose />
                </button>
            </div>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className="text-danger" key={errors[0].code}>{errors[0].message}</li>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        myFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [myFiles]);

    return (
        <>
            <form onSubmit={submitForm}>
                <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>(1 files are the maximum number of files you can drop here)</em>
                    </div>
                    <aside>
                        <ul>{thumbs}</ul>
                        <ul>{acceptedFileItems}</ul>
                        {/* <ul>{fileRejectionItems}</ul> */}
                        {(fileRejectionItems.length > 0) ?
                            <em className="text-danger">(maximum images allowed limit exceed or file you select may not be type of image)</em>
                            : ""
                        }
                    </aside>
                </section>

                <div className="modal-footer">
                    <button type="button" onClick={Collapse} className="btn btn-secondary">Close</button>
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>
            </form>
            <ToastContainer />
        </>
    )

}
// function PhotoUploader2(props) {
//     const [files, setFiles] = useState([]);
//     const { getRootProps, getInputProps } = useDropzone({
//         accept: 'image/*',
//         onDrop: acceptedFiles => {
//             setFiles(acceptedFiles.map(file => Object.assign(file, {
//                 preview: URL.createObjectURL(file)
//             })));
//         }
//     });

//     const removeFile = file => () => {
//         const newFiles = [...files]
//         newFiles.splice(newFiles.indexOf(file), 1)
//         setFiles(newFiles)
//     }
//     const thumbs = files.map(file => (
//         <div style={thumb} key={file.name}>
//             <div style={thumbInner}>
//                 <img
//                     src={file.preview}
//                     style={img}
//                     alt="Author Profile"
//                 />
//                 <button onClick={removeFile(file)}>Remove File</button>
//             </div>
//         </div>
//     ));



//     useEffect(() => () => {
//         // Make sure to revoke the data uris to avoid memory leaks
//         files.forEach(file => URL.revokeObjectURL(file.preview));
//     }, [files]);

//     return (
//         <>
//             <div className="billing-form-item">
//                 <div className="billing-title-wrap">
//                     <h3 className="widget-title pb-0">Photo</h3>
//                     <div className="title-shape margin-top-10px"></div>
//                 </div>
//                 <div className="billing-content">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div className="drag-and-drop-wrap text-center">
//                                 <div className="drag-and-drop-file">
//                                     <div {...getRootProps({ className: 'dropzone' })}>
//                                         <input {...getInputProps()} />
//                                         <span className="drag-drop-icon">
//                                             <BsCloudUpload />
//                                         </span>
//                                         <h3>Drag & Drop Files Here to Upload</h3>
//                                         <button type=  className="drag-drop-btn">Browse Files</button>
//                                     </div>
//                                     <aside style={thumbsContainer}>
//                                         {thumbs}
//                                     </aside>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

export default PhotoUploader2;