import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebar from "../../components/user/BuyerSidebar";
import UserInfo from "../../components/user/UserInfo";
import Footer from "../../components/common/footer/Footer";
import { Container} from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';
import CheckIn from '../../assets/images/CheckIn.png';
import { FaTrashAlt } from "react-icons/fa";
import { Link , Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
class BuyerReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [
                {
                    img: commentimg1,
                    name: 'Adam Smith',
                },
                {
                    img: commentimg1,
                    name: 'Matt Derry',
                    
                }
            ]
        }
        this.handler = this.handler.bind(this);
    }
    
    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="user-info">
                    <UserInfo />
                </div>
                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebar />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <h4>Checkins</h4>
                                <div className="reviews-tabs">
                                    <div className="form-area">
                                        <div className="tabs-content">
                                            <ul className="comments-list padding-top-10px">
                                                {this.state.comments.map((item, i) => {
                                                    return (
                                                        <>
                                                            <li key={i}>
                                                                <div className="comment listing-details">
                                                                    <div className="user-img">
                                                                        <img className="avatar__img" alt="Comment" src={item.img} />
                                                                    </div>
                                                                    <div className="comment-body">
                                                                        <div className="meta-data">
                                                                            <span className="comment__author">
                                                                                {item.name}
                                                                            </span>
                                                                            <p> <Link to="#" >Jamaican · Carribean</Link> 3492 48th Street Philadelphia, PA 19142</p>
                                                                            <img className="avatar__img" alt="Comment" src={CheckIn} />
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className="ReviewsDate">12/17/2020</div>
                                                                </div>
                                                                
                                                                <div className="DeleteReviews">
                                                                    <Link to="#" ><span><FaTrashAlt/></span></Link>
                                                                </div>

                                                            </li>
                                                        </>

                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerReviews;