import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from "react-router-dom";
import img1 from '../../assets/images/img31.jpg';
import img2 from '../../assets/images/img32.jpg';
import img3 from '../../assets/images/img30.jpg';
import authorImg from '../../assets/images/testi-img7.jpg';
import { IoIosCheckmarkCircle, IoIosLink, IoMdStar, IoMdStarHalf } from "react-icons/io";
import TabsHeading from '../common/TabsHeading'
import 'react-tabs/style/react-tabs.css';
import { ApiUrl } from '../../config';
import axios from 'axios';
// import Rater from 'react-rater'
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';

import Modal from 'react-bootstrap/Modal'
import RButton from 'react-bootstrap/Button'


export default class CityList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            businesses: props.businesses
        };
        this.onHide = this.onHide.bind(this);

    }

    onHide() {
        this.setState({ openModal: false, calandlyUrl: '' });
    }
    render() {
        return (
            <>
                <div className="cities">

                    <div className="list">
                        {this.state.businesses.map((item, index) => {
                            return (
                                <div className="col-lg-4 column-td-6" key={index}>
                                    <div className="Card">
                                        <div className="card-item blog-card">
                                            <Link
                                                to={{
                                                    pathname: `/biz/${item.business_slug}`,
                                                    state: {
                                                        b_id: item.business_id,
                                                        business_details: item,
                                                        search_data: "",
                                                        search: ""
                                                    }
                                                }}

                                                className="card-image-wrap">
                                                <div className="card-image">
                                                    <img src={item.img} alt="Blog" className="card__img" />
                                                </div>
                                            </Link>
                                            <div className="card-content pl-0 pr-0">
                                                <Link
                                                    to={{
                                                        pathname: `/biz/${item.business_slug}`,
                                                        state: {
                                                            b_id: item.business_id,
                                                            business_details: item,
                                                            search_data: "",
                                                            search: "",
                                                        }
                                                    }}

                                                    className="card-title">
                                                    {item.title}
                                                </Link>
                                                <div className="">
                                                    <div className="rating-rating rating-detail landing">
                                                        {/* <Rater total={5} rating={item.rating} interactive={false} /> */}

                                                        <Rating
                                                            placeholderRating={item.rating}
                                                            readonly={true}
                                                            stop={5}
                                                            emptySymbol={<img src={Empty} className="icon" />}
                                                            placeholderSymbol={<img src={Fill} className="icon" />}
                                                            fullSymbol={<img src={Fill} className="icon" />}
                                                        />
                                                        <span className="rating-count">{item.review} Reviews</span>
                                                    </div>
                                                </div>

                                                <p className="card-sub card-custom mt-3">
                                                    {item.category} , {item.subcategory}
                                                </p>
                                                <p className="card-sub card-custom address ">
                                                    {item.business_address}
                                                </p>
                                                <div className="PhoneNo">
                                                    {item.phone_number}
                                                </div>
                                                <div className="Descp">
                                                    <p>{item.business_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            item.business_service_type.length > 0 ?

                                                <div className="">
                                                    {
                                                        item.business_service_type.map((service, index) => {
                                                            return (
                                                                <div className="m-bottom_buttons">
                                                                    {
                                                                        index == 0 || index == 1 ?
                                                                            <>
                                                                                {(service.type == 'anchor') ?
                                                                                    <a target="_blank" className="btn-link" href={/(http(s?)):\/\//i.test(service.url) ? service.url : `https://${service.url}`}> {service.name}</a>
                                                                                    : (service.type == 'tel') ?
                                                                                        // ""
                                                                                        <a className="btn-link" href={`tel:${service.url}`}> {service.name}</a>
                                                                                        : (service.type == 'calandly') ?
                                                                                            <a target="_blank" className="btn-link" href={service.url}> {service.name}</a>
                                                                                            : ""
                                                                                }
                                                                                {/* <Link to={'/biz/' + item.business_slug} className="call-btn">{c.type}</Link> */}
                                                                            </>
                                                                            : ""
                                                                    }
                                                                </div>

                                                            );


                                                        })

                                                    }
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title> Make an Appointment </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}
