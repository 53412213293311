import React from 'react'
import { Link } from 'react-router-dom'
import { buyerUrl } from '../../config'

export default function Logo({url, className}) {
    return (
        <>
            <a href={buyerUrl} className={className}>
                <img width="160px" height="100%" className="auto-height" src={url} alt="logo" />
            </a>
        </>
    )
}
