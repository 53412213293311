import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
// import "./styles.css";
import { Line } from "react-chartjs-2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaPencilAlt } from "react-icons/fa";
import { BsFillTriangleFill } from "react-icons/bs";


import SocialOne from '../../assets/images/social-1.png';
import SocialTwo from '../../assets/images/social-2.png';
import SocialThree from '../../assets/images/social-3.png';
import SocialFour from '../../assets/images/social-4.png';
import Delivery from '../../assets/images/delivery.png';
import Profile from '../../assets/images/profile.svg';
import Requirements from '../../assets/images/requirements.svg';
import RequestStarted from '../../assets/images/request-started.svg';
import Delivered from '../../assets/images/delivered.svg';
import Completed from '../../assets/images/completed.svg';
import Review from '../../assets/images/review.svg';
import {
    AvForm, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckbox,
    AvCheckboxGroup
} from 'availity-reactstrap-validation';
import SingleFileUpload from '../../components/common/SingleFileUpload';
import MultiImageUpload from '../../components/common/MultiImageUpload';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import Rating from 'react-rating';
import leaveReviewDisabled from '../../assets/images/leaveReviewDisabled.svg';


class BusinessMediaManager extends Component {
    constructor() {
        super();
        this.state = {
            externalData: false,
            lists: {
                facebook_login: [],
                color_scheme: [],
                font_Type: [],
                a5_images: [],
                business_social_media_manager_ad_activity: [],
                business_social_media_manager_ad_comments: [],
                comments: [],

            },
            social_edit: true,
            selectedSocial: [],
            color_scheme_edit: true,
            selectedColorScheme: [],
            font_edit: true,
            selectedFontType: [],
            requirements_edit: true,
            current: "",
            weeks: [],
            file: [],
            request_edit: false,
            leave_review: false,
            rate: 1,
            currentMonth: 0,
        };
        this.fileSet = this.fileSet.bind(this);
        this.child = React.createRef()

    }


    componentDidMount = () => {
        if (this.props.match.params.date) {
            this.getSocialManager(this.props.match.params.date);
        } else {
            this.getSocialManager();
        }
    }

    fileSet = (files) => {
        if (files) {
            this.setState({
                file: files,
                photo: 'set'
            })
        } else {
            this.setState({
                file: null,
                photo: ''
            })
        }
    }


    getSocialManager = async (date = "", month = "") => {
        var self = this;
        // month: month, 
        await axios.post(ApiUrl + `get-business-social-media-manager`, { date: date }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        lists: res.data.lists,
                        current: res.data.current,
                        weeks: res.data.weeks,
                        selectedSocial: res.data.selectedSocial,
                        selectedColorScheme: res.data.selectedColorScheme,
                        selectedFontType: res.data.selectedFontType,
                        social_edit: res.data.lists.social_submit == 0 ? true : false,
                        color_scheme_edit: res.data.lists.color_submit == 0 ? true : false,
                        font_edit: res.data.lists.fonts_submit == 0 ? true : false,
                        requirements_edit: res.data.lists.requirement_submit == 0 ? true : false,
                        request_edit: false,
                        leave_review: false,
                        selectedSocial: [],
                        selectedColorScheme: [],
                        selectedFontType: [],
                        // currentMonth: 3
                    });

                    let objIndex = res.data.weeks.findIndex((obj => obj.date == res.data.current));
                    self.setState({
                        currentMonth: objIndex,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit = async (event, values) => {
        let data = {};

        if (values.selectedSocial) {
            data.social_login = this.state.lists.facebook_login;
        } else if (values.selectedColorScheme) {
            var filtered = this.state.lists.color_scheme.filter(function (el) {
                return el.label != null;
            });
            let array = filtered.map(a => a.label);
            data.color_scheme = array;
        } else if (values.selectedFontType) {
            data.font_Type = values.selectedFontType;
        } else {
            data = values;

        }

        data.id = this.state.lists.id;
        let self = this;



        axios.post(ApiUrl + 'update-business-social-media-manager', data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    toast.success(res.data.message);
                    self.getSocialManager(self.state.current);
                    self.setState({
                        // social_edit: true,
                        // color_scheme_edit: true,
                        // font_edit: true,
                        // requirements_edit: true,
                        request_edit: false,
                        leave_review: false,
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmitRequirement = async (event, values) => {
        var formData = new FormData();

        formData.append("id", this.state.lists.id);
        formData.append("a1", values.a1);
        formData.append("a2", values.a2);
        formData.append("a3", values.a3);
        formData.append("a4", values.a4);
        formData.append("a5", values.a5);

        if (this.state.file.length > 0) {
            this.state.file.map((item) => {
                formData.append('a5_images[]', item);
            });
        }


        let self = this;



        axios.post(ApiUrl + 'update-business-social-media-manager', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    toast.success(res.data.message);
                    self.getSocialManager(self.state.current);
                    self.setState({
                        // social_edit: false,
                        // color_scheme_edit: false,
                        // font_edit: false,
                        // requirements_edit: false,
                        request_edit: false,
                        leave_review: false,
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addMedaiaComment = async (event, values) => {
        values.id = this.state.lists.id;
        let self = this;
        axios.post(ApiUrl + 'store-business-social-media-manager-comment', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success == true) {
                    toast.success("Post Accepted");
                    self.getSocialManager(self.state.current);
                    self.setState({
                        // social_edit: false,
                        // color_scheme_edit: false,
                        // font_edit: false,
                        // requirements_edit: false,
                        request_edit: false,
                        leave_review: false,
                    })
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    mediaAction(status, comment) {
        var self = this;
        axios.post(ApiUrl + 'update-business-social-media-manager', { 'id': this.state.lists.id, status: status, comment: comment }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        // social_edit: false,
                        // color_scheme_edit: false,
                        // font_edit: false,
                        // requirements_edit: false,
                        request_edit: false,
                        leave_review: false,
                    })
                    self.getSocialManager(self.state.current);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        console.log(this.props.match.params.date);

        return (
            <main className="dashboard-page media-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar percent={this.state.percent} />
                        </Col>

                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area security">
                                <div className="y-page-link reviews">
                                    <h4>Social Media Manager</h4>
                                    <div className='MediaDrop d-flex gap-2 mt-3'>
                                        <button disabled={this.state.currentMonth <= 0 ? true : false}
                                            className='btn btn-link rotate-270'
                                            onClick={() => {
                                                if (this.state.currentMonth >= 0) {
                                                    this.setState({
                                                        currentMonth: this.state.currentMonth - 1
                                                    });

                                                    this.getSocialManager(this.state.weeks[this.state.currentMonth - 1]['date'] ?? '');
                                                }
                                            }}><BsFillTriangleFill /></button>
                                        {/* <h2>{this.state.currentMonth}</h2> */}
                                        <select disabled value={this.state.current}
                                            className='form-control'
                                            onChange={(e) => this.getSocialManager(e.target.value)}>
                                            {
                                                this.state.weeks.map((row, i) => {
                                                    return <option key={`mm${i}`} value={row.date}>{row.month} - Week {row.week} ({row.date_label})</option>
                                                })
                                            }

                                        </select>
                                        <button
                                            onClick={() => {
                                                if (this.state.currentMonth <= this.state.weeks.length) {
                                                    this.setState({
                                                        currentMonth: this.state.currentMonth + 1
                                                    });
                                                    this.getSocialManager(this.state.weeks[this.state.currentMonth + 1]['date'] ?? '');
                                                }
                                            }}
                                            disabled={this.state.currentMonth >= this.state.weeks.length - 1 ? true : false}
                                            className='btn btn-link rotate-220'
                                        ><BsFillTriangleFill />
                                        </button>
                                    </div>
                                    <div className="reviews-tabs SocialMedia">
                                        <div className="form-area">
                                            <Tabs>
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <TabList>
                                                            <Tab key={1} >Profile</Tab>
                                                            <Tab key={1} >Requirements</Tab>
                                                            <Tab key={1} >Activity</Tab>
                                                            <Tab key={1} >Delivery</Tab>
                                                        </TabList>

                                                        <TabPanel>
                                                            <div className="tabs-content pt-4 ">
                                                                <div className='d-flex'>
                                                                    <h4>Social Media Accounts</h4>
                                                                    <div className={`EditIcon ms-2 ${this.state.social_edit ? 'd-none' : ''}`}>
                                                                        <button onClick={() => this.setState({
                                                                            social_edit: true
                                                                        })} className='btn btn-link p-0'><FaPencilAlt /></button>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.social_edit ?
                                                                        <div className=''>
                                                                            <p>Enter your credentials for 2 social media accounts</p>
                                                                            <AvForm onValidSubmit={this.handleSubmit}>
                                                                                <div className="form-area">
                                                                                    <div className="tabs-content">
                                                                                        <div className="EditLocationForm">
                                                                                            <AvCheckboxGroup onClick={(e) => {
                                                                                                let myArray = this.state.lists.facebook_login;
                                                                                                let objIndex = myArray.findIndex((obj => obj.label == e.target.value));
                                                                                                if (myArray[objIndex]) {

                                                                                                    if (myArray[objIndex].is_selected == true) {
                                                                                                        myArray[objIndex].is_selected = false;
                                                                                                    } else {
                                                                                                        myArray[objIndex].is_selected = true;
                                                                                                    }


                                                                                                    var names = myArray.filter(obja => {
                                                                                                        return obja.is_selected === true
                                                                                                    })

                                                                                                    let result = names.map(a => a.label);


                                                                                                    this.setState(prevState => {
                                                                                                        let lists = Object.assign({}, prevState.lists);
                                                                                                        lists.facebook_login = myArray;
                                                                                                        return { lists };
                                                                                                    });


                                                                                                    this.setState({
                                                                                                        selectedSocial: result,
                                                                                                    })
                                                                                                }


                                                                                            }} inline value={this.state.selectedSocial} name="selectedSocial" label="" required validate={{ max: { value: 2 }, min: { value: 1 } }}>
                                                                                                <div className="row">
                                                                                                    {this.state.lists.facebook_login.map((item, i) => {
                                                                                                        return (
                                                                                                            <div key={`s${i}`} className="col-md-3">
                                                                                                                <AvCheckbox label={item.label ?? ''} value={item.label ?? ''} />
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </AvCheckboxGroup>
                                                                                            {
                                                                                                this.state.lists.facebook_login.map((row, i) => {
                                                                                                    return (
                                                                                                        <div className='social-account-edit' key={'r' + i}>

                                                                                                            {
                                                                                                                row.is_selected && (
                                                                                                                    <div className='account-details'>
                                                                                                                        <label>{row.label}</label>
                                                                                                                        <AvGroup>
                                                                                                                            <label>Username</label>
                                                                                                                            <AvInput
                                                                                                                                placeholder="Enter username"
                                                                                                                                className="form-control"
                                                                                                                                name={`user-${row.label}`}
                                                                                                                                value={row.user}
                                                                                                                                onChange={(e) => {
                                                                                                                                    let myArray = this.state.lists.facebook_login;
                                                                                                                                    let objIndex = myArray.findIndex((obj => obj.label == row.label));
                                                                                                                                    myArray[objIndex].user = e.target.value;
                                                                                                                                    this.setState(prevState => {
                                                                                                                                        let lists = Object.assign({}, prevState.lists);
                                                                                                                                        lists.facebook_login = myArray;
                                                                                                                                        return { lists };
                                                                                                                                    });
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                                                                        </AvGroup>

                                                                                                                        <AvGroup>
                                                                                                                            <label>Password </label>
                                                                                                                            <AvInput
                                                                                                                                placeholder="Enter password"
                                                                                                                                className="form-control"
                                                                                                                                name={`pass-${row.label}`}
                                                                                                                                value={row.pass}
                                                                                                                                onChange={(e) => {
                                                                                                                                    let myArray = this.state.lists.facebook_login;
                                                                                                                                    let objIndex = myArray.findIndex((obj => obj.label == row.label));
                                                                                                                                    myArray[objIndex].pass = e.target.value;
                                                                                                                                    this.setState(prevState => {
                                                                                                                                        let lists = Object.assign({}, prevState.lists);
                                                                                                                                        lists.facebook_login = myArray;
                                                                                                                                        return { lists };
                                                                                                                                    });
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                                                                        </AvGroup>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }



                                                                                                        </div>
                                                                                                    )

                                                                                                })
                                                                                            }




                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="FormButtonSocialManager">
                                                                                        <button className="btn btn-primary">Save</button>
                                                                                        <button type='bitton' className='btn btn-secondary d-none' onClick={() => this.setState({ social_edit: false })} >Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </AvForm>


                                                                        </div>
                                                                        :
                                                                        <ul className='SocialMediaList mt-4'>
                                                                            {
                                                                                this.state.lists.facebook_login.map((row, i) => {
                                                                                    if (row.is_selected) {
                                                                                        return (
                                                                                            <li className='border-0' key={`--${i}`}>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='ListNumber d-flex justify-content-center align-items-center me-3'>{i + 1}</div>
                                                                                                    <div className='SocialMediaName  d-flex flex-column'>
                                                                                                        <div className='SocialName  SocialMediaLinks'>
                                                                                                            {row.label}
                                                                                                        </div>
                                                                                                        <div className='SocialUser SocialMediaLinks'>
                                                                                                            Username: {row.user}
                                                                                                        </div>
                                                                                                        <div className='SocialPassword SocialMediaLinks'>
                                                                                                            Password: {row.pass}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                }


                                                                <div className='mt-4'>
                                                                    <div className='d-flex'>
                                                                        <h4>Color Scheme</h4>
                                                                        <div className={`EditIcon ms-2 ${this.state.color_scheme_edit ? 'd-none' : ''}`}>
                                                                            <button onClick={() => this.setState({
                                                                                color_scheme_edit: true
                                                                            })} className='btn btn-link p-0'><FaPencilAlt /></button>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className='d-flex my-4'>
                                                                    {
                                                                        this.state.color_scheme_edit == true ?

                                                                            <div className=''>
                                                                                <p>Choose up to 4 colors</p>
                                                                                <AvForm onValidSubmit={this.handleSubmit}>
                                                                                    <div className="form-area mt-0">
                                                                                        <div className="tabs-content">
                                                                                            <div className="EditLocationForm row mt-0">
                                                                                                {/* <AvCheckboxGroup onClick={(e) => {
                                                                                                    let myArray = this.state.lists.color_scheme;
                                                                                                    let objIndex = myArray.findIndex((obj => obj.label == e.target.value));
                                                                                                    if (myArray[objIndex]) {
                                                                                                        if (myArray[objIndex].is_selected == true) {
                                                                                                            myArray[objIndex].is_selected = false;
                                                                                                        } else {
                                                                                                            myArray[objIndex].is_selected = true;
                                                                                                        }


                                                                                                        var names = myArray.filter(obja => {
                                                                                                            return obja.is_selected === true
                                                                                                        })

                                                                                                        let result = names.map(a => a.label);


                                                                                                        this.setState(prevState => {
                                                                                                            let lists = Object.assign({}, prevState.lists);
                                                                                                            lists.color_scheme = myArray;
                                                                                                            return { lists };
                                                                                                        });


                                                                                                        this.setState({
                                                                                                            selectedColorScheme: result,
                                                                                                        })
                                                                                                    }


                                                                                                }} inline value={this.state.selectedColorScheme} name="selectedColorScheme" label="" required validate={{ max: { value: 4 }, min: { value: 1 } }}>
                                                                                                    <div className="row">
                                                                                                        {this.state.lists.color_scheme.map((item, i) => {
                                                                                                            return (
                                                                                                                <div key={`s${i}`} className="col-md-3">
                                                                                                                    <AvCheckbox label={item.label ?? ''} value={item.label ?? ''} />
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                </AvCheckboxGroup> */}
                                                                                                {Array.from({ length: 4 }, (_, i) => {
                                                                                                    return (
                                                                                                        <div key={`s----${i}`} className="col-md-3 mb-3">
                                                                                                            <div className='check-color'>
                                                                                                                {/* <AvCheckbox value={this.state.lists.color_scheme[i].label} /> */}
                                                                                                                <label>{this.state.lists.color_scheme[i] ? this.state.lists.color_scheme[i].label : "#999"}</label>
                                                                                                                <input className='form-control d-inline-block' type='color' value={this.state.lists.color_scheme[i] ? this.state.lists.color_scheme[i].label : "#999"} name='label' onChange={(e) => {
                                                                                                                    let newArray = [... this.state.lists.color_scheme]
                                                                                                                    newArray[i] = { ...newArray[i], label: e.target.value }
                                                                                                                    this.setState(prevState => {
                                                                                                                        let lists = Object.assign({}, prevState.lists);
                                                                                                                        lists.color_scheme = newArray;
                                                                                                                        return { lists };
                                                                                                                    });
                                                                                                                }
                                                                                                                } />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                                <AvInput name="selectedColorScheme" value={this.state.lists.color_scheme} required type="hidden" min={1} />

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="FormButtonSocialManager">
                                                                                            <button className="btn btn-primary">Save</button>
                                                                                            <button type='bitton' className='btn btn-secondary d-none' onClick={() => this.setState({ color_scheme_edit: false })} >Cancel</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </AvForm>


                                                                            </div>

                                                                            :

                                                                            <>
                                                                                {

                                                                                    this.state.lists.color_scheme.map((row, i) => {
                                                                                        if (row.is_selected) {
                                                                                            return (
                                                                                                <div className='CommonBoxColor' key={`cs-${i}`}>
                                                                                                    <div className='ColorBox m-auto' style={{ background: `${row.label}` }}></div>
                                                                                                    <div className='ColorCode'>{row.label}</div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                }

                                                                            </>
                                                                    }

                                                                </div>
                                                                <div className='mt-4'>
                                                                    <div className='d-flex'>
                                                                        <h4>Font Type Preference</h4>
                                                                        <div className={`EditIcon ms-2 ${this.state.font_edit ? 'd-none' : ''}`}>
                                                                            <button onClick={() => this.setState({
                                                                                font_edit: true
                                                                            })} className='btn btn-link p-0'><FaPencilAlt /></button>
                                                                        </div>
                                                                    </div>
                                                                    <p>Select the type that best fits your brands style (*we will use a mix of types)</p>
                                                                </div>

                                                                {
                                                                    this.state.font_edit ?
                                                                        <div className=''>
                                                                            <p>Choose up to 4 fonts</p>
                                                                            <AvForm onValidSubmit={this.handleSubmit}>
                                                                                <div className="form-area mt-0">
                                                                                    <div className="tabs-content">
                                                                                        <div className="EditLocationForm">
                                                                                            <AvCheckboxGroup onClick={(e) => {
                                                                                                let myArray = this.state.lists.font_Type;
                                                                                                let objIndex = myArray.findIndex((obj => obj.label == e.target.value));
                                                                                                if (myArray[objIndex]) {
                                                                                                    if (myArray[objIndex].is_selected == true) {
                                                                                                        myArray[objIndex].is_selected = false;
                                                                                                    } else {
                                                                                                        myArray[objIndex].is_selected = true;
                                                                                                    }


                                                                                                    var names = myArray.filter(obja => {
                                                                                                        return obja.is_selected === true
                                                                                                    })

                                                                                                    let result = names.map(a => a.label);


                                                                                                    this.setState(prevState => {
                                                                                                        let lists = Object.assign({}, prevState.lists);
                                                                                                        lists.font_Type = myArray;
                                                                                                        return { lists };
                                                                                                    });


                                                                                                    this.setState({
                                                                                                        selectedFontType: result,
                                                                                                    })
                                                                                                }


                                                                                            }} inline value={this.state.selectedFontType} name="selectedFontType" label="" required validate={{ max: { value: 4 }, min: { value: 1 } }}>
                                                                                                <div className="">
                                                                                                    {this.state.lists.font_Type.map((item, i) => {
                                                                                                        return (
                                                                                                            <div key={`ft${i}`} className="">
                                                                                                                <div className='d-inline-block FontFamiles mb-2'>

                                                                                                                    <AvCheckbox value={item.label ?? ''} className="mr-0" />
                                                                                                                    <img src={item.url} className="img img-thumbnail" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </AvCheckboxGroup>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="FormButtonSocialManager">
                                                                                        <button className="btn btn-primary">Save</button>
                                                                                        <button type='bitton' className='btn btn-secondary d-none' onClick={() => this.setState({ font_edit: false })} >Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </AvForm>


                                                                        </div>

                                                                        :
                                                                        <>

                                                                            {

                                                                                this.state.lists.font_Type.map((row, i) => {
                                                                                    if (row.is_selected) {
                                                                                        return (
                                                                                            <div className='FontFamiles mt-3' key={`fta${i}`}>
                                                                                                <input type='checkbox' checked></input>
                                                                                                <img src={row.url} className="img img-thumbnail" />
                                                                                                {/* <label className='FontFamily'>{row.label}</label> */}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                })
                                                                            }
                                                                        </>
                                                                }

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <div className="tabs-content pt-4 ">
                                                                    <div className='d-flex'>
                                                                        <h4>Requirements</h4>
                                                                        <div className={`EditIcon ms-2 ${this.state.requirements_edit ? 'd-none' : ''}`}>
                                                                            <button onClick={() => this.setState({
                                                                                requirements_edit: true
                                                                            })} className='btn btn-link p-0'><FaPencilAlt /></button>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.requirements_edit ?
                                                                            <>
                                                                                <AvForm encType="multipart/form-data" onValidSubmit={this.handleSubmitRequirement}>
                                                                                    <AvGroup>
                                                                                        <label>What is the purpose of this post? (e.g., promoting a product, sharing news, celebrating an event, etc.)</label>
                                                                                        <AvInput
                                                                                            type="input"
                                                                                            placeholder=""
                                                                                            className="form-control"
                                                                                            name="a1"
                                                                                            required
                                                                                            value={this.state.lists.a1}
                                                                                        />


                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>

                                                                                    <AvGroup>
                                                                                        <label>What keywords & industry do you want used for tags?</label>
                                                                                        <AvInput
                                                                                            type="input"
                                                                                            placeholder=""
                                                                                            className="form-control"
                                                                                            name="a2"
                                                                                            required
                                                                                            value={this.state.lists.a2}
                                                                                        />


                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>

                                                                                    <AvGroup>
                                                                                        <label>What message do you want to convey in this post? (e.g., a call-to-action, information, entertainment, etc.)</label>
                                                                                        <AvInput
                                                                                            type="input"
                                                                                            placeholder=""
                                                                                            className="form-control"
                                                                                            name="a3"
                                                                                            required
                                                                                            value={this.state.lists.a3}
                                                                                        />


                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>

                                                                                    <AvGroup>
                                                                                        <label>What day and time do you want your post to be posted?</label>
                                                                                        <AvInput
                                                                                            type="input"
                                                                                            placeholder=""
                                                                                            className="form-control"
                                                                                            name="a4"
                                                                                            required
                                                                                            value={this.state.lists.a4}
                                                                                        />


                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>

                                                                                    <AvGroup>
                                                                                        <label>Do you have any visual assets to include in this post? (e.g., images, videos, graphics, etc.)</label>
                                                                                        <AvInput
                                                                                            type="input"
                                                                                            placeholder=""
                                                                                            className="form-control"
                                                                                            name="a5"
                                                                                            required
                                                                                            value={this.state.lists.a5}
                                                                                        />


                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>

                                                                                    <div className='ms-5'>
                                                                                        <ul className='SocailImages d-flex'>
                                                                                            {
                                                                                                this.state.lists.a5_images.map((row, i) => {

                                                                                                    return (
                                                                                                        <li className='border-0' key={`av${i}`}>
                                                                                                            <img alt="Image" src={row} height={120} width={120} />
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            }


                                                                                        </ul>
                                                                                    </div>

                                                                                    <AvGroup>
                                                                                        <label>Add photos <p> Recommended dimensions:(520px520px) - Add up to 5 photos</p></label>
                                                                                        <MultiImageUpload fileSet={this.fileSet} ref="child" />
                                                                                        <AvInput type='hidden' name="selectfile" value={this.state.photo} />
                                                                                        <AvFeedback>This field is required</AvFeedback>
                                                                                    </AvGroup>
                                                                                    <div className=''>

                                                                                        <div className="FormButtonSocialManager">

                                                                                            <button className='btn btn-primary'>Submit</button>
                                                                                            <button type='button' className='btn btn-secondary d-none' onClick={() => this.setState({ requirements_edit: false })} >Cancel</button>

                                                                                        </div>

                                                                                        {/* <div className='UploadBtn mt-2'>
                                                                                        <button className='bg-white border-0'>Attachments</button>
                                                                                    </div>
                                                                                    <div className='SubmitBtn mt-3'>
                                                                                        <button>Submitted</button>
                                                                                    </div> */}
                                                                                    </div>
                                                                                    <div className='BottomDesc'>
                                                                                        <p><i>The information I provided is accurate and clear. Any changes after this point may have extra cost.</i></p>
                                                                                    </div>
                                                                                </AvForm>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <ul className='SocialMediaList mt-4'>
                                                                                    <li className='border-0 mb-4'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>1</div>
                                                                                            <div className='SocialQuestions'>
                                                                                                <h6>What is the purpose of this post? (e.g., promoting a product, sharing news, celebrating an event, etc.)</h6>
                                                                                                <p>{this.state.lists.a1}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='border-0 mb-4'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>2</div>
                                                                                            <div className='SocialQuestions'>
                                                                                                <h6>What keywords & industry do you want used for tags?</h6>
                                                                                                <p>{this.state.lists.a2}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='border-0 mb-4'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>3</div>
                                                                                            <div className='SocialQuestions'>
                                                                                                <h6>What message do you want to convey in this post? (e.g., a call-to-action, information, entertainment, etc.)</h6>
                                                                                                <p>{this.state.lists.a3}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='border-0 mb-4'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>4</div>
                                                                                            <div className='SocialQuestions'>
                                                                                                <h6>What day and time do you want your post to be posted?</h6>
                                                                                                <p>{this.state.lists.a4}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li className='border-0 mb-4'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>5</div>
                                                                                            <div className='SocialQuestions'>
                                                                                                <h6>Do you have any visual assets to include in this post? (e.g., images, videos, graphics, etc.)</h6>
                                                                                                <p>{this.state.lists.a5}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className='ms-5'>
                                                                                    <ul className='SocailImages d-flex'>
                                                                                        {
                                                                                            this.state.lists.a5_images.map((row, i) => {

                                                                                                return (
                                                                                                    <li className='border-0' key={`av${i}`}>
                                                                                                        <img alt="Image" src={row} height={120} width={120} />
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </ul>
                                                                                </div>
                                                                            </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <div className='ActivityBox mt-4'>
                                                                    {/* <div className='ActivityDate'>Jan 30</div> */}
                                                                    <ul className='ActivityList mt-4'>
                                                                        {
                                                                            this.state.lists.business_social_media_manager_ad_activity.map((row, i) => {
                                                                                return <li key={`img-${i}`}>
                                                                                    <div className='ActivityIcon'><img alt="Image" src={require('../../assets/media/' + row.image).default} /></div>
                                                                                    <div className='ActivityDetail'>
                                                                                        <span className='ActivityText float-start' >{row.title}</span>

                                                                                        <span className='DateActivity ms-3'><i>{row.created_at}</i></span>
                                                                                        <div className='description'>
                                                                                            {
                                                                                                row.rating > 0 && (
                                                                                                    <div className="rating-rating mb-3">
                                                                                                        <Rating
                                                                                                            placeholderRating={row.rating}
                                                                                                            readonly={true}
                                                                                                            stop={5}
                                                                                                            emptySymbol={<img src={Empty} className="icon" />}
                                                                                                            placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                                            fullSymbol={<img src={Fill} className="icon" />}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <p>{row.description}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            })
                                                                        }

                                                                        {
                                                                            this.state.lists.status == 'Inactive' ?

                                                                                <li className='disabled' title='Accept it first,Then you can give your review on it.'>
                                                                                    <div className='ActivityIcon'><img alt="Image" src={leaveReviewDisabled} /></div>
                                                                                    <div className='ActivityDetail'>
                                                                                        <span className='ActivityText text-secondary'> Leave a review</span>
                                                                                    </div>
                                                                                </li>
                                                                                :
                                                                                <li className='pointer' onClick={() => this.setState({ leave_review: true })}>
                                                                                    <div className='ActivityIcon'><img alt="Image" src={Review} /></div>
                                                                                    <div className='ActivityDetail'>
                                                                                        <span className='ActivityText'> Leave a review</span>
                                                                                    </div>
                                                                                </li>
                                                                        }
                                                                    </ul>
                                                                    {
                                                                        this.state.leave_review &&
                                                                        <AvForm encType="multipart/form-data" onValidSubmit={this.handleSubmit}>
                                                                            <div className='ms-5 mt-3'>
                                                                                <div className="rating-rating mb-3">
                                                                                    <Rating
                                                                                        placeholderRating={this.state.rate}
                                                                                        readonly={false}
                                                                                        stop={5}
                                                                                        onChange={(value) => { this.setState({ rate: value }) }}
                                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                                    />
                                                                                    {/* <Rater total={5} rating={rate} interactive={true} onRate={({ rating }) => { setRate(rating) }} /> */}
                                                                                    <span className="rating-count">Select rating</span>
                                                                                    <AvInput
                                                                                        type="hidden"
                                                                                        placeholder="Enter your review"
                                                                                        className="form-control"
                                                                                        name="rating"
                                                                                        required
                                                                                        value={this.state.rate}
                                                                                    />
                                                                                </div>

                                                                                <AvGroup>
                                                                                    <AvInput
                                                                                        type="textarea"
                                                                                        placeholder="Enter your review"
                                                                                        className="form-control"
                                                                                        name="review"
                                                                                        required
                                                                                        maxLength={200}
                                                                                    />
                                                                                    <AvFeedback>This field is required</AvFeedback>
                                                                                </AvGroup>



                                                                                <div className="FormButtonSocialManager">

                                                                                    <button className='btn btn-primary'>Save</button>
                                                                                    <button type='button' className='btn btn-secondary' onClick={() => this.setState({ leave_review: false })} >Cancel</button>

                                                                                </div>

                                                                                {/* <div className='UploadBtn mt-2'>
                                                                                        <button className='bg-white border-0'>Attachments</button>
                                                                                    </div>
                                                                                    <div className='SubmitBtn mt-3'>
                                                                                        <button>Submitted</button>
                                                                                    </div> */}
                                                                            </div>
                                                                        </AvForm>
                                                                    }
                                                                </div>
                                                                {/* <div className='ActivityBox mt-4'>
                                                                    <div className='ActivityDate'>Feb 6</div>
                                                                    <ul className='ActivityList mt-4'>
                                                                        <li>
                                                                            <div className='ActivityIcon'><img alt="Image" src={Delivered} /></div>
                                                                            <div className='ActivityDetail'>
                                                                                <span className='ActivityText'>Your request was delivered</span>
                                                                                <span className='DateActivity ms-3'><i>Feb 6, 2:31 PM</i></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='ActivityIcon'><img alt="Image" src={Completed} /></div>
                                                                            <div className='ActivityDetail'>
                                                                                <span className='ActivityText'>Your request was completed</span>
                                                                                <span className='DateActivity ms-3'><i>Feb 6, 7:25 PM</i></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='ActivityIcon'><img alt="Image" src={Review} /></div>
                                                                            <div className='ActivityDetail'>
                                                                                <span className='ActivityText'> Leave a review</span>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}
                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <div className="tabs-content pt-4">
                                                                <ul className='SocialMediaList Delivery mt-4'>
                                                                    <li className='border-0'>
                                                                        <div className='d-flex'>
                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>1</div>
                                                                            <div className='SocialMediaName  d-flex flex-column'>
                                                                                <div className='SocialName  SocialMediaDescp mb-3'>
                                                                                    <h6>Media</h6>
                                                                                </div>
                                                                                <div className='DeliveryImg'>
                                                                                    <img alt="Image" src={this.state.lists.media} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='border-0'>
                                                                        <div className='d-flex'>
                                                                            <div className='ListNumber d-flex justify-content-center align-items-center me-3'>2</div>
                                                                            <div className='SocialMediaName '>
                                                                                <div className='SocialName  SocialMediaDescp mb-3'>
                                                                                    <h6 className='mb-2'>Caption</h6>
                                                                                    <p>{this.state.lists.description}</p>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                {
                                                                    this.state.lists.status == 'Inactive' ?
                                                                        <div className='DeliveyBtns'>
                                                                            {
                                                                                this.state.lists.admin_status == 'Pending' ?

                                                                                    <>
                                                                                        {/* <button onClick={() => this.mediaAction('Active', '')} className='Btns Accpect'>Accept</button> */}
                                                                                        <button onClick={() => this.setState({ request_edit: true })} className='Btns Request'>Request Edit</button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button onClick={() => this.mediaAction('Active', '')} className='Btns Accpect'>Accept</button>
                                                                                        <button onClick={() => this.setState({ request_edit: true })} className='Btns Request'>Request Edit</button>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className='DeliveyBtns'>
                                                                            <button disabled className='btn btn-success rounded-pill mr-3'>Post Accepted</button>
                                                                            <button disabled className='btn btn-dark rounded-pill'>Request Edit</button>
                                                                        </div>
                                                                }


                                                                {
                                                                    this.state.request_edit &&
                                                                    <AvForm encType="multipart/form-data" onValidSubmit={this.addMedaiaComment}>
                                                                        <div className='ms-5 mt-3'>
                                                                            <AvGroup>
                                                                                <AvInput
                                                                                    type="textarea"
                                                                                    placeholder="Enter your feedback"
                                                                                    className="form-control"
                                                                                    name="comment"
                                                                                    required
                                                                                />
                                                                                <AvFeedback>This field is required</AvFeedback>
                                                                            </AvGroup>



                                                                            <div className="FormButtonSocialManager">

                                                                                <button className='btn btn-primary'>Save</button>
                                                                                <button type='button' className='btn btn-secondary' onClick={() => this.setState({ request_edit: false })} >Cancel</button>

                                                                            </div>

                                                                            {/* <div className='UploadBtn mt-2'>
                                                                                        <button className='bg-white border-0'>Attachments</button>
                                                                                    </div>
                                                                                    <div className='SubmitBtn mt-3'>
                                                                                        <button>Submitted</button>
                                                                                    </div> */}
                                                                        </div>
                                                                    </AvForm>
                                                                }

                                                                {
                                                                    this.state.lists.comments.length > 0 && (
                                                                        <div className='comment-list mt-2'>
                                                                            <h6 className='m-2'>Comments</h6>
                                                                            <ListGroup as="ol" numbered>
                                                                                {
                                                                                    this.state.lists.comments.map((row, i) => {
                                                                                        return (
                                                                                            <ListGroup.Item
                                                                                                as="li"
                                                                                                key={'comment-' + i}
                                                                                                className="d-flex justify-content-between align-items-start"
                                                                                                variant="secondary"
                                                                                            >
                                                                                                <div className="ms-2 me-auto">
                                                                                                    <div className="fw-bold">{row.user_name}</div>
                                                                                                    {row.comment}
                                                                                                </div>
                                                                                                <span className="badge badge-secondary text-light">{row.created_at}</span>
                                                                                            </ListGroup.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListGroup>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </TabPanel>
                                                    </Col>
                                                </Row>
                                            </Tabs>
                                        </div>
                                    </div>

                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>

                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}

export default BusinessMediaManager;