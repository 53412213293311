import React, { Component } from 'react';
import BuyerSidebar from "../../components/user/BuyerSidebar";
import Footer from "../../components/common/footer/Footer";
import UserInfo from "../../components/user/UserInfo";
import BLogo from '../../assets/images/katika_K.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { BsChevronUp, BsChevronDown, BsInfoCircle } from 'react-icons/bs'
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 1,
            tabs: [
                {
                    title: 'Home',
                    id: 'Home',
                },
                {
                    title: 'Using Katika Chat',
                    id: 'using-katika-chat',
                },
                {
                    title: 'Managing Your Account',
                    id: 'managing-your-account',
                },
                {
                    title: 'Privacy and Policy',
                    id: 'privacy-and-policy',
                },

            ],
            RighttabIndex: 0,
            Righttabs: [
                {
                    title: 'Sending Messages, Photos and Videos',
                    id: 'Sending',
                },
                {
                    title: 'Messenger Contacts',
                    id: 'messenger-contacts',
                },
                {
                    title: 'Secret Conversations',
                    id: 'secret-conversations',
                },
                {
                    title: 'Group Conversations',
                    id: 'group-conversations',
                },

            ],
        }

    }




    render() {

        return (
            <main className="chat-help">
                <div className="container">
                    <div className="navbar-chat-help">
                        <div className="row">
                            <div className="col-lg-1 col-md-1">
                                <div className="logo">
                                    <a href="/"><img src={BLogo} /></a>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <h6 className="help"> Help Center</h6>
                            </div>
                            <div className="col-lg-7 col-md-">
                                <div className="help-search form-group">
                                    <span className="search-icon">
                                        <FaSearch />
                                    </span>
                                    <input type="search" placeholder="Search" className="h-search form-control" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="help-tabs-top">
                                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex: tabIndex })}>
                                    <Row>
                                        <Col lg={12} md={12}>
                                            <TabList>
                                                {this.state.tabs.map((tab, index) => {
                                                    return (
                                                        <Tab key={index} >{tab.title}</Tab>
                                                    )
                                                })}
                                            </TabList>
                                            <TabPanel>
                                                <div className="tabs-content">
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="tabs-content sending">
                                                    <Container>
                                                        <div className="sidebarBuyer">
                                                            <Tabs selectedIndex={this.state.RighttabIndex} onSelect={tabIndex => this.setState({ RighttabIndex: tabIndex })}>
                                                                <Row>
                                                                    <Col lg={3} md={3}>
                                                                        <div className="right-bar">
                                                                            <TabList>
                                                                                {this.state.Righttabs.map((tab, index) => {
                                                                                    return (
                                                                                        <Tab key={index} >{tab.title}</Tab>
                                                                                    )
                                                                                })}
                                                                            </TabList>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={9} md={9}>
                                                                        <TabPanel>
                                                                            <div className="tabs-content right-sending-1 margin-botton-30px">
                                                                                <h2 className="select-right"> Sending Messages, Photos and Videos </h2>

                                                                                <div className="rules-area margin-top-50px">
                                                                                    <h4 className="sub-heading-rule"> Messaging Basics </h4>
                                                                                    <div className="rule-details">
                                                                                        <Accordion className="accordion accordion-item" allowZeroExpanded={true} id="accordionExample1">

                                                                                            <div className={'card'} key={0}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do I send a message on Katika Chat?

                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                            <div className={'card'} key={1}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do I send a photo, video, sticker or voice message in Katika Chat?
                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                            <div className={'card'} key={2}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do I know if someone has seen a m essage I sent in Katika Chat?
                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                            <div className={'card'} key={3}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do I remove or unsend a message that I’ve sent in Katika Chat?
                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                            <div className={'card'} key={4}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do I send an audio message or voice recording in Katika Chat?
                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                            <div className={'card'} key={5}>
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading className="card-header">
                                                                                                        <AccordionItemButton className="btn btn-link d-flex align-items-center">
                                                                                                            How do suggestions work in Messenger?
                                                                                                            <i className="minus pull-right rules-caret"><BsChevronDown /></i>
                                                                                                            <i className="plus pull-right rules-caret"><BsChevronUp /></i>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <p className="rule-detail">
                                                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                                                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                                                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                                                        </p>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </div>

                                                                                        </Accordion>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </TabPanel>

                                                                        <TabPanel>
                                                                            <div className="tabs-content">

                                                                            </div>
                                                                        </TabPanel>
                                                                        <TabPanel>
                                                                            <div className="tabs-content">

                                                                            </div>
                                                                        </TabPanel>
                                                                        <TabPanel>
                                                                            <div className="tabs-content">

                                                                            </div>
                                                                        </TabPanel>
                                                                    </Col>

                                                                </Row>
                                                            </Tabs>
                                                        </div>
                                                    </Container>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tabs-content">

                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tabs-content">

                                                </div>
                                            </TabPanel>
                                        </Col>
                                    </Row>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                </div>



            </main >




        );
    }
}

export default Dashboard;