import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col } from "react-bootstrap";
import Rater from 'react-rater';
import commentimg1 from '../../assets/images/avatar.jpg';
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import Button from '../../components/common/Button';
import RButton from 'react-bootstrap/Button'

import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import { toast } from 'react-toastify';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalData: false,
            recent: [],
            replied: [],
            no_reply: [],
            flaged: [],
            reply: '',
            tabs: [
                {
                    title: 'Most recent',
                    id: 'recent',
                },
                {
                    title: 'Replied to',
                    id: 'Replied',
                },
                {
                    title: 'No reply',
                    id: 'reply',
                },
                {
                    title: 'Flagged',
                    id: 'Flagged',
                },

            ],
            comments: [
                {
                    img: commentimg1,
                    name: 'Adam Smith',
                    user_addres: 'South Jersey, NJ',
                    date: '12/2/2020',
                    content: "I ordered quite a bit, and enjoyed everything. This was really my first experience with Dim Sum (since the only places are in the city and not in south NJ), so it's hard for to really compare to anything else.",
                    contentLineTwo: " My husband and I decided our two favorites were the pork soup dumpling and the fried pork dumpling. Yummy! But nothing that we ordered disappointed us.",
                    contentLineThree: "It was decently priced and easy to pick up. I would say give them a shot!",
                    replyBtn: 'Reply',
                    replyBtnIcon: '',

                },
                {
                    img: commentimg1,
                    name: 'Matt Derry',
                    date: 'Reviewed 4 Days ago',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                    replyBtn: 'Reply',
                    replyBtnIcon: '<RiReplyLine />',
                    replyComments: []
                }
            ]
        }
    }

    componentDidMount() {
        this.getBusinessReviews();
    }

    getBusinessReviews = () => {
        var self = this;

        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'userBusinessReviews', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    externalData: true,
                    recent: res.data.recent,
                    replied: res.data.replied,
                    no_reply: res.data.no_reply,
                    flaged: res.data.flaged
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handleChange(reply, reply_id, business_id) {
        let self = this;
        if (reply != '') {
            axios.post(ApiUrl + 'replyBusinessReview', { 'reply': reply, 'reply_id': reply_id, 'business_id': business_id }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.getBusinessReviews();

                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.error('Please add reply');
        }
    }



    render() {
        return (
            <main className="dashboard-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area security">
                                <div className="y-page-link reviews">
                                    <h4>Reviews</h4>
                                    <div className="reviews-tabs">
                                        <div className="form-area">
                                            <Tabs>
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <TabList>
                                                            {this.state.tabs.map((tab, index) => {
                                                                return (
                                                                    <Tab key={index} >{tab.title}</Tab>
                                                                )
                                                            })}
                                                        </TabList>
                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <ul className="comments-list padding-top-10px">
                                                                    {this.state.recent.map((item, i) => {
                                                                        return (

                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        <img className="avatar__img" alt="Comment" src={item.user_image} />
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {item.user_name}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="rating-rating rating-detail landing-3">
                                                                                    <Rating
                                                                                        placeholderRating={item.rating}
                                                                                        readonly={true}
                                                                                        stop={5}
                                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                                    />
                                                                                    <span className="comment-date">
                                                                                        {item.date}
                                                                                    </span>
                                                                                </div>
                                                                                <p className="comment-content">
                                                                                    {item.description}
                                                                                </p>
                                                                                <div className="write-b-review">
                                                                                    <div className="textarea">
                                                                                        <p>Reply to This Review</p>
                                                                                        <textarea value={this.state[`reply${item.id}`]} onChange={(e) => this.setState({ [`reply${item.id}`]: e.target.value })} className="form-control"></textarea>
                                                                                    </div>
                                                                                    <div className="review-btns react-review-button">
                                                                                        <p>&nbsp;</p>
                                                                                        <RButton disabled={this.state[`reply${item.id}`] ? false : true} onClick={() => { this.handleChange(this.state[`reply${item.id}`], item.id, item.business_id); this.setState({ [`reply${item.id}`]: '' }) }} className="r-button-reply" variant="none">
                                                                                            Reply Review</RButton>
                                                                                        <RButton onClick={() => this.setState({ [`reply${item.id}`]: '' })} className="r-button-cancel" variant="none" > Cancel </RButton>
                                                                                    </div>
                                                                                </div>
                                                                            </li>


                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <ul className="comments-list padding-top-10px">
                                                                    {this.state.replied.map((item, i) => {
                                                                        return (

                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        <img className="avatar__img" alt="Comment" src={item.user_image} />
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {item.user_name}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="rating-rating rating-detail landing-3">
                                                                                    <Rating
                                                                                        placeholderRating={item.rating}
                                                                                        readonly={true}
                                                                                        stop={5}
                                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                                    />
                                                                                    <span className="comment-date">
                                                                                        {item.date}
                                                                                    </span>
                                                                                </div>
                                                                                <p className="comment-content">
                                                                                    {item.description}
                                                                                </p>

                                                                                <div key={i + i}>
                                                                                    <p className="comment-content pl-5">
                                                                                        <span className="comment-date pr-3">
                                                                                            {item.r_date}
                                                                                        </span>
                                                                                        {item.r_description}
                                                                                    </p>
                                                                                </div>
                                                                            </li>


                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <ul className="comments-list padding-top-10px">
                                                                    {this.state.no_reply.map((item, i) => {
                                                                        return (

                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        <img className="avatar__img" alt="Comment" src={item.user_image} />
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {item.user_name}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="rating-rating rating-detail landing-3">
                                                                                    <Rating
                                                                                        placeholderRating={item.rating}
                                                                                        readonly={true}
                                                                                        stop={5}
                                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                                    />
                                                                                    <span className="comment-date">
                                                                                        {item.date}
                                                                                    </span>
                                                                                </div>
                                                                                <p className="comment-content">
                                                                                    {item.description}
                                                                                </p>

                                                                                <div className="write-b-review">
                                                                                    <div className="textarea">
                                                                                        <p>Reply to This Review</p>
                                                                                        <textarea value={this.state[`reply2${item.id}`]} onChange={(e) => this.setState({ [`reply2${item.id}`]: e.target.value })} className="form-control"></textarea>
                                                                                    </div>
                                                                                    <div className="review-btns react-review-button">
                                                                                        <p>&nbsp;</p>
                                                                                        <RButton disabled={this.state[`reply2${item.id}`] ? false : true} onClick={() => { this.handleChange(this.state[`reply2${item.id}`], item.id, item.business_id); this.setState({ [`reply2${item.id}`]: '' }) }} className="r-button-reply" variant="none">
                                                                                            Reply Review</RButton>
                                                                                        <RButton onClick={() => this.setState({ [`reply2${item.id}`]: '' })} className="r-button-cancel" variant="none" > Cancel </RButton>
                                                                                    </div>
                                                                                </div>
                                                                            </li>


                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="tabs-content">
                                                                <ul className="comments-list padding-top-10px">
                                                                    {this.state.flaged.map((item, i) => {
                                                                        return (

                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        <img className="avatar__img" alt="Comment" src={item.user_image} />
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {item.user_name}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="rating-rating rating-detail landing-3">
                                                                                    <Rating
                                                                                        placeholderRating={item.rating}
                                                                                        readonly={true}
                                                                                        stop={5}
                                                                                        emptySymbol={<img src={Empty} className="icon" />}
                                                                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                        fullSymbol={<img src={Fill} className="icon" />}
                                                                                    />
                                                                                    <span className="comment-date">
                                                                                        {item.date}
                                                                                    </span>
                                                                                </div>
                                                                                <p className="comment-content">
                                                                                    {item.description}
                                                                                </p>
                                                                            </li>

                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </TabPanel>
                                                    </Col>
                                                </Row>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;