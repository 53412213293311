import React, { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from "react-router-dom";
import $ from 'jquery';

export default function PrimaryNavbar() {
    const [navOpen, setNavOpen] = useState(false)

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    return (
        <>
            <div className="main-menu-content">
                <nav>
                    <ul>
                        <li><Link to="/faq">Help</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/sign-up">Sign up</Link></li>
                    </ul>
                </nav>
            </div>
            <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li><Link to="/faq">Help</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/sign-up">Sign up</Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
