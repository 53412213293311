import React, { Component } from 'react';
import Footer from "../components/business/Footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import { Container, Row, Col } from "react-bootstrap";
import ImageSignUp from '../assets/images/sign-up-img.png';
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/dashboard-logo.svg';
import { RiArrowRightFill, RiArrowLeftFill } from "react-icons/ri";
import Step1 from "./business/Step1";
import Step2 from "./business/Step2";
import Step3 from "./business/Step3";
import Step4 from "./business/Step4";
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import Button from 'react-bootstrap/Button'
import { ApiUrl } from '../config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


class BusinessSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SignUp: [
                {
                    SignUpImg: ImageSignUp
                }
            ],
            current: 1,
            location: 'Yes',
            submitForm1: "",
            submitForm2: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }







    page(page) {
        console.log(page, 'page')
        if (page) {
            this.setState({
                location: page,
                current: this.state.current + 1
            })
        }
    }

    next = () => {
        this.setState({
            current: this.state.current + 1
        })
    }

    prev = () => {
        this.setState({
            current: this.state.current - 1
        })
    }

    handleSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            if (this.state.current == 1) {
                self.setState({
                    current: this.state.current + 1,
                    submitForm1: values
                    // submitFormValue: values
                })
                console.log(values, 'values')
            } else if (this.state.current == 2) {
                self.setState({
                    current: this.state.current + 1,
                })
            } else if (this.state.current == 3) {
                let selected_pkg = new URLSearchParams(window.location.search).get("pkg")
                values.selected_pkg = selected_pkg;
                const object3 = { ...this.state.submitForm1, ...values }
                axios.post(ApiUrl + 'registerSeller', object3, {
                    headers: {
                        'Authorization': `Bearer`
                    }
                })
                    .then(function (res) {
                        if (res.data.success === true) {
                            // toast.success(res.data.message);
                            // self.setState({
                            //     current: self.state.current + 1,
                            // })
                            // window.history.pushState({}, "", window.location.origin + '/signupsuccess/');

                            self.props.history.push('/verify-business-account/' + object3.email)

                        } else {
                            toast.error(res.data.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }


        }



    }


    render() {



        return (
            <main className="signup-page BizSignUp">
                <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-12">
                                <div className="logo listing RemoveLogoWidth">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
                {/* Header */}
                {/* <GeneralHeader /> */}

                {/* Breadcrumb 
                <Breadcrumb CurrentPgTitle="Sign Up" img={this.state.breadcrumbimg} />*/}
                <section className="form-shared  login-page">

                    <Container>
                        <div className="SignUpBg padding-top-40px padding-bottom-50px">
                            <AvForm onSubmit={this.handleSubmit}>
                                {
                                    (this.state.current == 1) ?
                                        <Step1 formValue={this.state.submitForm1} />
                                        : (this.state.current == 2) ?
                                            <Step2 getPage={this.page.bind(this)} />
                                            : (this.state.current == 3) ?
                                                <Step3 formValue={this.state.submitForm1} location={this.state.location} />
                                                : (this.state.current == 4) ?
                                                    <Step4 />
                                                    :
                                                    ""
                                }
                                {
                                    (this.state.current != 2) ?
                                        <div className={`bottom-btn step-${this.state.current}`}>
                                            {this.state.current > 1 && this.state.current != 4 ? <button className="btn btn-default" onClick={this.prev}><RiArrowLeftFill /> Back</button> : ""}
                                            {this.state.current < 4 ? <Button type="submit" className="btn btn-default ml-5" variant="primary" >Next <RiArrowRightFill /></Button> : ""}
                                            {this.state.current == 4 ? <><a href={`/business-login`} className="btn btn-default">Go to Login</a></> : ""}
                                        </div>
                                        : ""
                                }


                            </AvForm>
                        </div>
                    </Container>

                    <ToastContainer />

                </section>


                {/* Newsletter 
            <NewsLetter />*/}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main >
        );
    }
}

export default BusinessSignUp;