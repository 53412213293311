import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import AccordionListGeneral from "../../components/business/AccordionListGeneral";
import AccordionListGeneralMobile from "../../components/business/AccordionListGeneralMobile";
import AccordionListOwners from "../../components/business/AccordionListOwners";
import AccordionListOwnersMobile from "../../components/business/AccordionListOwnersMobile";
import { Helmet } from "react-helmet";


const Business = () => {

    return (
        <main className="BusinessHome FaqPages FAQ">
            <Helmet>
                <title>{`Frequently Asked Questions`}</title>
                <meta name="title" content={`Frequently Asked Questions`} />
                <meta property="og:title" content={`Frequently Asked Questions`} />
            </Helmet>
            {/* Header */}
            <HomePageHeader />
            <div className='d-none d-lg-block d-xl-block'>
                <div className="CommonBreadcrumb">
                    <h1>FREQUENTLY ASKED QUESTIONS</h1>
                </div>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="PressPage padding-top-60px">
                                <h2>Need some clarity?</h2>
                                <p>Get answers to the frequently asked questions about using Katika for your business.</p>
                            </div>
                        </Col>
                        <Col sm={12} className='border-bottom'>
                            <div className="FaqsQuestion padding-top-60px padding-bottom-100px">
                                <div className="SubHeading padding-bottom-30px">General Users</div>
                                <section className="faq-area">
                                    <AccordionListGeneral />
                                </section>
                                <div className="SubHeading padding-top-70px padding-bottom-30px">Business Owners</div>
                                <section className="faq-area">
                                    <AccordionListOwners />
                                </section>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className='d-block d-sm-none'>
                <div className='MobileFaq'>
                    <div className="CommonBreadcrumb">
                        <h1>FREQUENTLY ASKED QUESTIONS</h1>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="PressPage padding-top-60px">
                                    <h2>Need some clarity?</h2>
                                    <p>Get answers to frequently asked questions about Katika.</p>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="FaqsQuestion padding-top-60px padding-bottom-100px">
                                    <div className="SubHeading padding-bottom-20px mt-5">General Users</div>
                                    <section className="faq-area">
                                        <AccordionListGeneralMobile />
                                    </section>
                                    <div className="SubHeading padding-top-70px padding-bottom-30px">Katika for Business users</div>
                                    <section className="faq-area">
                                        <AccordionListOwnersMobile />
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
            <ScrollTopBtn />
        </main>
    )
}

export default Business;