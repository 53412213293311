import React, { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { FbId, ApiUrl, GoogleClientId, FbSecertKey } from '../../../config'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import useToken from '../../../useToken';
import { useHistory } from "react-router-dom";
import Facebook from "facebook-js-sdk";
import AppleSignin from 'react-apple-signin-auth';
import jwt_decode from "jwt-decode";
import { FaFacebookF } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';


const facebook = new Facebook({
    appId: FbId,
    appSecret: FbSecertKey,
    redirectUrl: `${window.location.origin.toString()}/login`,
    graphVersion: "v17.0",
});

const SignInOptions = ({ redirect, handler1, goFarward }) => {
    const { setToken } = useToken();
    let history = useHistory();
    let _isMounted = true;
    const handleSubmit = (data) => {
        axios.post(ApiUrl + 'social-media', data, {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...'
        })
            .then(function (res) {
                if (res.data.success === true) {
                    setToken(res.data);
                    if (handler1) {
                        handler1(true)
                    }
                    if (redirect != '1234') {
                        if (goFarward != undefined) {
                            setTimeout(
                                () => history.go(goFarward),
                                4000
                            );
                        } else {
                            setTimeout(
                                () => history.push("/user-profile"),
                                4000
                            );
                        }
                    }
                    toast.success("Login Successful!");

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const responseGoogle = (response) => {
        if (response.error) {
            toast.error("Unknow error occur try again!");
        } else {
            const data = {
                email: response.profileObj.email,
                image: response.profileObj.imageUrl,
                login_id: response.googleId,
                login_type: 'Google',
                fname: response.profileObj.givenName,
                lname: response.profileObj.familyName
            }
            handleSubmit(data)
        }
    }

    const responseFacebook = async (response) => {
        // console.log(response, 'fb')
        let gender = '';
        let dob = '';
        let total_friends = 0;
        let city = '';
        let state = '';
        let country = '';
        let region = '';
        let latitude = '';
        let longitude = '';
        let friends = [];
        if (response.status === 'unknown') {
            toast.error("Unknow error occur try again!");
        } else {

            facebook.setAccessToken(response.accessToken);
            await facebook
                .get("/me?fields=id,name,gender,birthday,location{location{city,state,region,country,country_code,latitude,longitude}}")
                .then((response1) => {
                    // console.log('response1', response1)
                    gender = response1.data.gender ? response1.data.gender : '';
                    dob = response1.data.birthday ? response1.data.birthday : '';
                    if (response1.data.location) {
                        if (response1.data.location.location) {
                            city = response1.data.location.location.city;
                            state = response1.data.location.location.state ? response1.data.location.location.state : '';
                            country = response1.data.location.location.country_code;
                            region = response1.data.location.location.region ? response1.data.location.location.region : '';
                            latitude = response1.data.location.location.latitude;
                            longitude = response1.data.location.location.longitude;

                            var data = {
                                'city': city,
                                'region': response1.data.location.location.state ? state : region,
                                'country': country,
                                'country_name': '',
                                'latitude': latitude,
                                'longitude': longitude,
                            }
                            // localStorage.setItem('location', JSON.stringify(data));
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                });


            await facebook
                .get(`/${response.userID}/friends?fields=name,id,picture.type(large)`)
                .then((response2) => {
                    // console.log('res2', response2)
                    friends = response2.data ? response2.data.data : []
                    total_friends = response2.data.summary ? response2.data.summary.total_count : 0
                })
                .catch((error) => {
                    console.log(error)
                });


            const data = {
                email: response.email,
                image: response.picture.data.url,
                login_id: response.id,
                login_type: 'Facebook',
                fname: response.first_name,
                lname: response.last_name,
                fbtoken: response.accessToken,
                gender: gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : '',
                dob: dob,
                friends: friends,
                total_friends: total_friends,
                city: city,
                state: state,
                country: country,
                region: region,
            }

            // console.log(data)

            handleSubmit(data)
        }
    }

    const responseApple = async (response) => {
        if (response.error) {
            toast.error("Unknow error occur try again!");
        } else {

            let data = {};

            if (response.user) {
                data = {
                    email: response.user.email,
                    login_id: response.authorization.id_token,
                    login_type: 'Apple',
                    fname: response.user.name.firstName,
                    lname: response.user.name.lastName,
                    image: 'https://katika-images.s3.amazonaws.com/profile/user-profile1612873510.jpg',
                }
            } else {

                let decode = jwt_decode(response.authorization.id_token)
                data = {
                    email: decode.email,
                    login_id: response.authorization.id_token,
                    login_type: 'Apple',
                    image: '',
                }
            }

            handleSubmit(data)
        }
    }

    useEffect(() => {
        return () => {
            _isMounted = false;  // not good
        }
    }, []);

    return (
        <>

            {/* <div className="col-lg-12">
                <div className="form-group login-btn facebook">
                    <span className="theme-btn border-0 w-100 r-bg text-align-center" >
                        <FacebookLogin
                            appId={FbId}
                            fields="first_name,last_name,name,email,picture.width(300)"
                            scope="public_profile,user_friends,user_birthday,user_gender,email,user_location"
                            callback={responseFacebook}
                            textButton=""
                            cssClass="theme-btn bg-5 border-0 w-100"
                            icon={<FaFacebookF />}

                        />
                    </span>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group login-btn google">
                    <span className="theme-btn border-0 w-100 r-bg text-align-center" >
                        <GoogleLogin
                            clientId={GoogleClientId}
                            scope="profile email"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            className="height-48"
                            icon={false} >
                            <FcGoogle />
                        </GoogleLogin>
                    </span>
                </div>
            </div> */}

            {/* <div className="col-lg-12">
                <div className="form-group login-btn apple">
                    <span className="theme-btn border-0 w-100 r-bg text-align-center" style={{ height: "48px" }} >
                        <AppleSignin
                            authOptions={{
                                clientId: 'com.shopkatika.dev',
                                scope: 'name email',
                                state: 'initial',
                                redirectURI: "https://dev.shopkatika.com/login",
                                nonce: 'nonce',
                                usePopup: true,
                                sub: '',
                            }}
                            onSuccess={responseApple}
                            // onSuccess={(response) => console.log(response) console.log(jwt_decode(response.authorization.id_token))} // default = undefined
                            onError={(error) => console.error(error)}
                            uiType="dark"
                            className="apple-auth-btn"
                        />

                    </span>
                </div>
            </div> */}
            <div className="col-lg-12">


                <div className="form-group login-btn google loginPage">
                    <div className="form-group login-btn facebook">
                        <span className="theme-btn border-0 w-100 r-bg text-align-center" >
                            <FacebookLogin
                                appId={FbId}
                                fields="first_name,last_name,name,email,picture.width(300)"
                                scope="public_profile,user_friends,user_birthday,user_gender,email,user_location"
                                callback={responseFacebook}
                                textButton="Continue with Facebook"
                                cssClass="theme-btn bg-5 border-0 w-100"
                            />

                        </span>
                    </div>
                </div>
                <div className="form-group login-btn google loginPage mt-3">
                    <span className="theme-btn border-0 w-100 r-bg text-align-center btn btn-info" >
                        <GoogleLogin
                            clientId={GoogleClientId}
                            scope="profile email"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            className="btn btn-info"
                        >
                            Continue with Google
                        </GoogleLogin>
                    </span>
                    {/* <button className="theme-btn border-0 w-100" type="submit">
                        <i><FaGoogle /></i> Google
                        </button>*/}


                </div>
                <div className="section-devid">
                    <div className="line"></div>
                    <span>or</span>
                </div>

            </div>
            <ToastContainer />
        </>
    );
}


export default SignInOptions;