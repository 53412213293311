import React, { Component } from 'react';
import { Link } from "react-router-dom";
import BMIcon from '../../assets/images/bm-icon.png'
import Dashboard from '../../assets/images/dashboard.png'
import DetailedInformation from '../../assets/images/detailed-info.png'
import Account  from "../../assets/images/account-icon.png"
import Messages  from "../../assets/images/messages.png"
import Schedule  from "../../assets/images/schedule.png"
import Reviews  from "../../assets/images/reviews.png"
import Stats  from "../../assets/images/stats.png"
import BillingInformation  from "../../assets/images/billing-information.png"
import Security  from "../../assets/images/security.png"
import ProgressBar from "react-bootstrap/ProgressBar";



class Directory extends Component {
    state = {
        menus: [
            {
                icon: Dashboard,
                title: 'Dashboard',
                url: '/dashboard'
            },
            {
                icon: DetailedInformation,
                title: 'Detailed Information',
                url: '/detailed-info'
            },
            {
                icon: Messages,
                title: 'Messages',
                url: '/'
            },
            {
                icon: Schedule,
                title: 'Schedule',
                url: '/schedule'
            },
            {
                icon: Reviews,
                title: 'Reviews',
                url: '/reviews'
            },
            {
                icon: Stats,
                title: 'Stats',
                url: '/'
            },
            // {
            //     icon: BillingInformation,
            //     title: 'Billing Information',
            //     url: '/'
            // },
            {
                icon: Account,
                title: 'Account',
                url: '/account'
            },
            {
                icon: Security,
                title: 'Security',
                url: '/security'
            },
            
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar section-bg mt-2 mb-4 side-navbar">
                    <div className="sidebar-widget side-menu">
                        <div className="author-bio text-align-center">
                            <div className="d-flex align-items-center">
                                <div className="dm-icon">
                                <div className="dm-icon-img"><img src={BMIcon} alt="" /></div>
                                </div>
                                <div className="author-inner-bio pb-0">
                                    <h4 className="author__title pb-0 pl-1 ">
                                        Directory Manager
                                    </h4>
                                   
                                </div>
                                
                            </div>
                            <div className="ProgressBar">
                            <span>50% Profile Complete</span>
                                    <ProgressBar now={50} />
                                    </div>
                        </div>
                        <div className="section-block-2 mb-3"></div>
                        <div className="info-list">
                            <ul className="list-items">
                                {this.state.menus.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link  to={item.url}>
                                                <span className="dashboard-icon"><img src={item.icon} alt="Dashboard Icon"/></span>
                                                {item.title} 
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Directory;