import React, { Component } from 'react';

class CopyrightMenu extends Component {
    state = {
        links: [
            {
                path: '#',
                title: 'Terms of Use'
            },
            {
                path: '#',
                title: 'Privacy Policy'
            },
            {
                path: '#',
                title: 'Site Map'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="copyright-right-side justify-content-end d-flex align-items-center">
                    <ul className="list-items">
                        <li>&copy; 2021 Katika, LLC.</li>
                        {this.state.links.map((link, index) => {
                            return (
                                <li key={index}>
                                    <a href={link.path}>{link.title}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </>
        );
    }
}

export default CopyrightMenu;