import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai'


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 0,
    marginRight: 0,
    width: '100%',
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    alignItems: 'start'
};

const img = {
    display: 'block',
    width: 'auto',
    maxWidth: '200px',
    height: '100px'
};

function PhotoUploader4(props) {
    const [myFiles, setMyFiles] = useState([])
    const onDrop = useCallback(acceptedFiles => {
        // setMyFiles([...myFiles, ...acceptedFiles])
        setMyFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

    }, [myFiles]);

    const { getRootProps, getInputProps, acceptedFileItems, fileRejections } = useDropzone({
        onDrop, maxFiles: 1, accept: 'image/*',
    });

    const Collapse = () => {
        props.closeModal()
    }

    const submitForm = async e => {
        e.preventDefault();
        if (myFiles.length > 0) {

            var formData = new FormData();
            myFiles.map((item) =>
                formData.append('image', item)
            );

            formData.append('id', props.id)

            // formData.append('business_images', myFiles);
            var type = myFiles[0].type;
            axios.post(ApiUrl + 'updateGroupImage', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        props.closeModal(res.data.image)
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                        // props.closeModal(res.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.warning('Select Images');
        }
    }

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }
    const thumbs = myFiles.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="Author Profile"
                />
                <button
                    data-toggle="tooltip" data-placement="bottom" title="Remove"
                    className="btn btn-default badge-toggle b-left"
                    onClick={removeFile(file)}>
                    <AiOutlineClose />
                </button>
            </div>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li className="text-danger" key={errors[0].code}>{errors[0].message}</li>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        myFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, [myFiles]);

    return (
        <>
            <form onSubmit={submitForm} className="create-group">
                <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop photo</p>
                        {/* <em>(1 files are the maximum number of files you can drop here)</em> */}
                    </div>
                    <aside>
                        <ul>{thumbs}</ul>
                        <ul>{acceptedFileItems}</ul>
                        {/* <ul>{fileRejectionItems}</ul> */}
                        {(fileRejectionItems.length > 0) ?
                            <em className="text-danger">(maximum images allowed limit exceed or file you select may not be type of image)</em>
                            : ""
                        }
                    </aside>
                </section>

                <div className="modal-footer group">
                    <button type="button" onClick={Collapse} className="btn btn-secondary">Close</button>
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>
            </form>
            <ToastContainer />
        </>
    )

}
export default PhotoUploader4;