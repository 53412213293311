import React, { useState } from 'react';
import SignInOptions from "./SignInOptions";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import axios from "axios";
import { ApiUrl } from "../../../config"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import useToken from '../../../useToken';



function LoginBox({ title, subtitle, props, redirect, handler1 }) {
    const [email, setUserName] = useState();
    let history = useHistory();
    const handleSubmit = async e => {

        e.preventDefault();
        if (email != '' ) {
            axios.post(ApiUrl + 'forgotPasswordApp', {
                email,
                'device': 'buyerWeb'
            }, {
                'Content-Type': 'application/json',
                'Authorization': 'JWT fefege...'
            })
                .then(function (response) {
                    if (response.data.success === true) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            // setToken({"token":"abc"});
        }
    }

    return (
        <>

            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">

                    <p className="font-size-16 font-weight-medium">
                        <h2>Recover your password</h2>
                    </p>
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box login">
                                        <div className="form-group">
                                            <input required="required" onChange={e => setUserName(e.target.value)} className="form-control" type="email" name="text" placeholder="Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group no-margin">
                                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                            <div>
                                                &nbsp;
                                            </div>
                                            <div>
                                                <Link to="/login" className=" font-weight-medium">
                                                    Login
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="btn-box mt-0">
                                        <button className="theme-btn btn-login border-0" type="submit">
                                            Recover password
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="col-lg-12">
                                    <p className="font-weight-medium">Don’t have an account?
                                        <Link
                                            to={{ pathname: '/business-sign-up', state: { prevPath: history.location.pathname } }}
                                        > Sign Up</Link></p>
                                    <p className="text-term-condition-login">By logging in, you agree to Katika’s <Link to='#'>Terms of Service</Link> and <Link to='#'> Privacy Policy.</Link></p>

                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default LoginBox;

// LoginBox.propTypes = {
//     setToken: PropTypes.func.isRequired
// };