import React from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import Privacy from '../../components/business/terms-privacy/Privacy'
import BannerOne from '../../components/business/BannerOne'
import Testimonial from '../../components/business/Testimonial'
import { Row, Col } from "react-bootstrap";
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Helmet } from "react-helmet";




const Business = () => {
    return (
        <main className="BusinessHome FaqPages GetHelp TermsOfService">
            {/* Header */}
            <Helmet>
                <title>{`Privacy Policy`}</title>
                <meta name="title" content={`Privacy Policy`} />
                <meta property="og:title" content={`Privacy Policy`} />
            </Helmet>
            <HomePageHeader />
            <Privacy />
            <Footer />
            <ScrollTopBtn />
        </main>
    )
}

export default Business;