import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Overview from '../../assets/images/overview.svg';
import Friends from '../../assets/images/friends.svg';
import Reviews from '../../assets/images/reviews.svg';
import Messages from '../../assets/images/chat3.svg';
import Bookmarks from '../../assets/images/bookmark.svg';
import CheckIns from '../../assets/images/check-in.svg';
import Following from '../../assets/images/following.png';
import Followers from '../../assets/images/followers.png';
import Security from "../../assets/images/security.png"
import Photo from "../../assets/images/gallery.svg"
import Newsletters from '../../assets/images/newsletter.svg';
import MyStory from '../../assets/images/MyStory.svg';




class Directory extends Component {
    state = {
        menus: [
            {
                icon: Overview,
                title: 'Profile Overview',
                url: '/user-profile'
            },
            {
                icon: Friends,
                title: 'Friends',
                url: '/user-friends'
            },
            {
                icon: MyStory,
                title: 'My Stories',
                url: '/user-stories'
            },
            {
                icon: Reviews,
                title: 'Reviews',
                url: '/user-reviews'
            },
            {
                icon: Photo,
                title: 'Photos',
                url: '/user-photo'
            },
            {
                icon: Messages,
                title: 'Messages',
                url: '/user-message'
            },
            {
                icon: Bookmarks,
                title: 'Bookmarks',
                url: '/user-bookmarks'
            },
            {
                icon: CheckIns,
                title: 'Check-Ins',
                url: '/user-check-in'
            },
            // {
            //     icon: Following,
            //     title: 'Following',
            //     url: '/user-following'
            // },
            // {
            //     icon: Followers,
            //     title: 'Followers',
            //     url: '/user-followers'
            // },
            {
                icon: Newsletters,
                title: 'Newsletters',
                url: '/user-newsletters'
            },
            // {
            //     icon: Security,
            //     title: 'Security',
            //     url: 'buyer-security'
            // }

        ]
    }
    render() { 
        return (
            <>
                <div className="sidebar section-bg mt-2 mb-4 BuyerProfile">
                    <div className="sidebar-widget">
                        <div className="author-bio text-align-center">
                            <div className="d-flex align-items-center">
                                <div className="author-inner-bio pb-0">
                                    {/* <h4 className="author__title pb-0 pl-1 ">Jason’s Profile</h4> */}
                                </div>
                            </div>
                        </div>
                        <div className="section-block-2 mb-3"></div>
                        <div className="info-list">
                            <ul className="list-items">
                                {this.state.menus.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={item.url} className={(window.location.pathname == item.url) ? 'active' : ''}>
                                                <span className="dashboard-icon"><img src={item.icon} alt="Dashboard Icon" /></span>

                                                {item.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Directory;