import React, { useEffect, useState } from 'react'
import HomePageHeader from '../../components/business/common/HomePageHeader'
import Footer from "../../components/business/Footer/Footer";
import ScrollTopBtn from "../../components/business/common/ScrollTopBtn";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Incomplete from "../../assets/images/3401.jpg";
import Slack from "../../assets/images/discord-v2.svg";
import axios from 'axios';
import { ApiUrl } from '../../config';

const Community = (props) => {

  const [community, setCommunity] = useState([]);

  useEffect(() => {
    console.log(props, 'ssssssss')
    getCommunity();
  }, []);




  const getCommunity = () => {

    axios.get(ApiUrl + 'getLatestCommunityEvent', {
      headers: {}
    })
      .then(function (res) {
        if (res.data.success === true) {
          setCommunity(res.data.lists)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <main className="BusinessHome FaqPages Community">
      <Helmet>
        <title>{`Katika Community`}</title>
        <meta name="title" content={`Community`} />
        <meta name="description"
          content={`Read about the latest happenings with Katika, as we are featured in media outlets.`} />
        <meta property="og:title" content={`Community`} />
        <meta property="og:description"
          content={`Read about the latest happenings with Katika, as we are featured in media outlets.`} />
      </Helmet>
      {/* Header */}
      <HomePageHeader />
      <div className='d-none d-lg-block d-xl-block'>
        <div className="CommonBreadcrumb">
          <h1 className='text-white'>JOIN OUR COMMUNITY</h1>
        </div>
        <Container>
          <Row>
            <Col sm={12}>
              <div className="PressPage communityPage padding-top-60px border-bottom padding-bottom-200px">
                <h2 className='padding-bottom-20px'>A Community of Black Entrepreneurs </h2>
                <p>This is a group of first time and seasoned entrepreneurs sharing information and resources to help educate our businesses collectively, working together.  We host video workshops covering different topics monthly and have a Slack channel for people to connect.</p>
                <div className="Lists padding-top-70px">
                  <div className="CommShare">
                    <h4>COME READY TO LEARN AND SHARE</h4>
                    <img src={Slack} className="Slack icon" /><a target='_blank' href="https://discord.gg/F6PmJNSMSa">https://discord.gg/F6PmJNSMSa</a>
                  </div>
                  <div className="Upcomming text-center padding-bottom-80px"><h4>EXAMPLE VIDEO LESSON</h4></div>

                  <div className="ratio ratio-16x9 communityVideo">
                    <iframe
                      src="https://www.youtube.com/embed/KauaT5Zu5fQ"
                      title="YouTube video"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {/* <ul className="Community-Posts">
                    {
                      community.map((list, i) => {
                        return (

                          <li key={i}>
                            <div className="CommImg"><img src={list.image} className="icon" /></div>
                            <div className="CommDetail">
                              <div className="PostedDetails">
                                <div className="Date-TimePost">
                                  <h4>{list.event_date}</h4>
                                  <h6>{list.event_time}</h6>
                                </div>
                                <div className="PostedUser"><strong> Presenter:</strong> {list.presenter}</div>
                              </div>
                              <div className="PostTitle">
                                <h2>{list.title}</h2>
                                {/* <h2>Part 1 - Creating your Name & Brand</h2> 
                              </div>
                              <div className="PostDesc"><p>{list.description}</p></div>
                              <div className="businesses Community"><a target='_blank' href={`${list.join_link}`}>{list.join_link_title}</a></div>
                            </div>
                          </li>
                        );
                      })
                    }
                  </ul> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='d-block d-sm-none'>
        <div className='MobileCOMMUNITY'>
          <div className="CommonBreadcrumb">
            <h1>JOIN OUR COMMUNITY</h1>
          </div>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="PressPage communityPage padding-top-60px">
                  <h2>A Community of Black Entrepreneurs </h2>
                  <p>The Katika Community is a group of first time and seasoned entrepreneurs, community activist, and culture warriors sharing information and resources to better our community by working together.  Join the discussion on</p>
                  <div className="Lists padding-top-70px">
                    <div className="CommShare">
                      <h4>COME READY TO LEARN AND SHARE</h4>
                      <img src={Slack} className="Slack icon" /><a target='_blank' href="https://discord.gg/F6PmJNSMSa">https://discord.gg/F6PmJNSMSa</a>
                    </div>
                    <div className="Upcomming">
                      <h4>EXAMPLE VIDEO LESSON</h4>
                      <h6 className='color-black'>Lesson 1: Naming your company</h6>
                    </div>
                    <div className="ratio ratio-16x9 margin-top-40px margin-bottom-60px">
                      <iframe
                        src="https://www.youtube.com/watch?v=vGwP8EzJ5HQ&ab_channel=Katika"
                        title="YouTube video"
                        allowfullscreen
                      ></iframe>
                    </div>
                    {/* <ul className="Community-Posts">
                      {
                        community.map((list, i) => {
                          return (

                            <li key={i}>
                              <div className="CommImg"><img src={list.image} className="icon" /></div>
                              <div className="CommDetail">
                                <div className="PostedDetails">
                                  <div className="Date-TimePost">
                                    <h4>{list.event_date}</h4>
                                    <h6>{list.event_time}</h6>
                                  </div>
                                  <div className="PostedUser"><strong> Presenter:</strong> {list.presenter}</div>
                                </div>
                                <div className="PostTitle">
                                  <h2>{list.title}</h2>
                                  {/* <h2>Part 1 - Creating your Name & Brand</h2> 
                                </div>
                                <div className="PostDesc"><p>{list.description}</p></div>
                                <div className="businesses Community"><a target='_blank' href={`${list.join_link}`}>{list.join_link_title}</a></div>
                              </div>
                            </li>
                          );
                        })
                      }
                    </ul> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>


      <Footer />
      <ScrollTopBtn />
    </main>
  )
}

export default Community;