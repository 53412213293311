import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
// import "./styles.css";
import { Line } from "react-chartjs-2";

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            externalData: false,
            percent: 60,
            profile: 0,
            bookmark: 0,
            review: 0,
            photo: 0,
            business_profile_views_date: [],
            business_profile_views_total: [],
            bookmark_date: [],
            bookmark_total: [],
            review_date: [],
            review_total: [],
            image_date: [],
            image_total: [],
        };
    }


    componentDidMount() {
        this.getBusinessStats();
    }

    getBusinessStats = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getBusinessStats', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        externalData: true,
                        profile: res.data.profile,
                        bookmark: res.data.bookmark,
                        review: res.data.reviews,
                        photo: res.data.images,
                        percent: res.data.complete_percent,
                        profile_date: res.data.profile_date,
                        profile_total: res.data.profile_total,
                        bookmark_date: res.data.bookmark_date,
                        bookmark_total: res.data.bookmark_total,
                        review_date: res.data.reviews_date,
                        review_total: res.data.reviews_total,
                        image_date: res.data.images_date,
                        image_total: res.data.images_total,

                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        const data = {
            labels: this.state.profile_date,
            datasets: [
                {
                    label: "Profile Views",
                    data: this.state.profile_total,
                    fill: true,
                    backgroundColor: "rgba(255,255,255,0)",
                    borderColor: "rgba(166,206,227,1)"
                },

            ]
        };

        const bookmarks = {
            labels: this.state.bookmark_date,
            datasets: [
                {
                    label: "Bookmarks",
                    data: this.state.bookmark_total,
                    fill: true,
                    backgroundColor: "rgba(255,255,255,0)",
                    borderColor: "rgba(166,206,227,1)"
                },

            ]
        };

        const review = {
            labels: this.state.review_date,
            datasets: [
                {
                    label: "Reviews",
                    data: this.state.review_total,
                    fill: true,
                    backgroundColor: "rgba(255,255,255,0)",
                    borderColor: "rgba(166,206,227,1)"
                },

            ]
        };

        const photo = {
            labels: this.state.image_date,
            datasets: [
                {
                    label: "Photos",
                    data: this.state.image_total,
                    fill: true,
                    backgroundColor: "rgba(255,255,255,0)",
                    borderColor: "rgba(166,206,227,1)"
                },

            ]
        };

        return (
            <main className="dashboard-page stats-page">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            {(this.state.externalData) ? <Sidebar percent={this.state.percent} /> : ""}
                        </Col>
                        {(this.state.externalData) ?
                            <Col lg={9} md={9} id="page-content-wrapper">
                                <section className="dashboard-area security">
                                    <div className="y-page-link security-area">
                                        <h4>Stats</h4>
                                        <span className="small-heading">Daily statistics</span>
                                        <div className="total-stats">
                                            <ul>
                                                <li>
                                                    <h3>{this.state.profile}</h3>
                                                    <span>Profile views</span>
                                                </li>
                                                <li>
                                                    <h3>{this.state.bookmark}</h3>
                                                    <span>Bookmarks</span>
                                                </li>
                                                <li>
                                                    <h3>{this.state.review}</h3>
                                                    <span>Reviews</span>
                                                </li>
                                                <li>
                                                    <h3>{this.state.photo}</h3>
                                                    <span>Photos</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="margin-top-15px"></div>
                                    <div className="y-page-link security-area">
                                        <h4>Profile views</h4>
                                        <div className="total-stats">
                                            <div className="App">
                                                <Line data={data} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-top-15px"></div>
                                    <div className="y-page-link security-area">
                                        <h4>Bookmarks</h4>
                                        <div className="total-stats">
                                            <div className="App">
                                                <Line data={bookmarks} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-top-15px"></div>
                                    <div className="y-page-link security-area">
                                        <h4>Reviews</h4>
                                        <div className="total-stats">
                                            <div className="App">
                                                <Line data={review} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-top-15px"></div>
                                    <div className="y-page-link security-area">
                                        <h4>Photos from Reviews</h4>
                                        <div className="total-stats">
                                            <div className="App">
                                                <Line data={photo} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <ToastContainer />
                            </Col>
                            : ""}
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;