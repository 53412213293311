import React, { Component } from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import NavbarListing from '../components/common/NavbarListing'
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import { Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Rater from 'react-rater'
import 'react-tabs/style/react-tabs.css';
import img1 from '../assets/images/engage_2.jpg';
import img2 from '../assets/images/img32.jpg';
import img3 from '../assets/images/img30.jpg';
import BLogo from '../assets/images/img30.jpg';
import AddPhoto from '../assets/images/add-photos.png';
import RButton from 'react-bootstrap/Button'
import GalleryTabs from "../components/sliders/GalleryTabs";
import PhotoUploaderGallery from '../../src/components/addlisting/PhotoUploaderGallery';
import { AiOutlineCamera } from 'react-icons/ai'
import { RiSearchLine } from 'react-icons/ri'
import { ApiUrl } from '../config';
import axios from 'axios';
import { uToken } from '../useToken';
import Modal from 'react-bootstrap/Modal'
import LoginBox from '../components/other/account/LoginBox';
import Loader from 'react-loader';

import Rating from 'react-rating';
import Empty from '../assets/images/empty.png';
import Half from '../assets/images/half.png';
import Fill from '../assets/images/fill.png';



class ListingGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            location: '',
            isSignedUp: false,
            externalData: false,
            loaded: false,
            images: [],
            all: [],
            business: '',
            LoginModal: false,
            search_data: this.props.location.state

        }
        this.handler = this.handler.bind(this)
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
    }


    handler1(e) {
        var self = this;
        if (e) {
            const { id } = self.props.match.params;
            setTimeout(
                () => self.props.history.push({
                    pathname: `/add-photos/${id}`,
                    state: {
                        search: this.state.search_data ? this.state.search_data.search : '',
                        location: this.state.search_data ? this.state.search_data.location : ''
                    }
                }),
                4000
            );

        }
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    onHide() {
        this.setState({ LoginModal: false });
    }
    componentDidMount() {
        this.getBusinessImages();
    }

    getBusinessImages = () => {
        var self = this;
        const { id } = this.props.match.params;
        self.setState({
            externalData: false,
            loaded: false,
        })
        axios.post(ApiUrl + 'getBusinessImages', { business_slug: id }, {})
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        business_name: res.data.business_name,
                        business: res.data.business,
                        images: res.data.images,
                        externalData: true,
                        all: res.data.all,
                        loaded: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        const { id } = this.props.match.params;
        return (
            <main className="GalleryListing">
                <Loader loaded={this.state.loaded}></Loader>
                {/* Header */}
                <NavbarListing state={this.props.location ? this.props.location.state : ""} handler={this.handler} />

                <Container>
                    <Row>
                        <Col lg={12} md={12}>
                            <div className="GalleryHeading margin-top-30px">
                                <h1>Photos for {this.state.business.business_name}</h1>
                            </div>
                            <div className="BusinessDetail  margin-bottom-25px">
                                <div className="BusinessLogo">
                                    <img src={this.state.business.business_logo} alt="Blog" className="card__img" />
                                </div>
                                <div className="rating-rating rating-detail landing-2">
                                    <div className="BusinessName">
                                        <Link to={`/biz/${this.state.business.business_slug}`}>
                                            {this.state.business.business_name}
                                        </Link>
                                    </div>

                                    <Rating
                                        placeholderRating={this.state.business.business_rating}
                                        readonly={true}
                                        stop={5}
                                        emptySymbol={<img src={Empty} className="icon" />}
                                        placeholderSymbol={<img src={Fill} className="icon" />}
                                        fullSymbol={<img src={Fill} className="icon" />}
                                    />
                                    {/* <Rater total={5} rating={this.state.business.business_rating} interactive={false} /> */}
                                    <span className="rating-count">{this.state.business.total_business_reviews} Reviews</span>
                                </div>
                                <div className="uploads-photo">
                                    <div className="drag-and-drop-wrap text-center mb-3">
                                        <div className="drag-and-drop-file">
                                            <div className="dropzone">

                                                {(!uToken()) ?
                                                    <RButton onClick={() => this.setState({ LoginModal: true })}
                                                        type="button" className="drag-drop-btn" variant="none">
                                                        <img src={AddPhoto} alt="Blog" className="card__img" />
                                                    Add photos
                                            </RButton>
                                                    :
                                                    <Link to={{
                                                        pathname: `/add-photos/${id}`,
                                                        state: {
                                                            search: this.state.search_data ? this.state.search_data.search : '',
                                                            location: this.state.search_data ? this.state.search_data.location : ''
                                                        }
                                                    }} className="drag-drop-btn">
                                                        <img src={AddPhoto} alt="Blog" className="card__img" />
                                                    Add photos</Link>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/* <h5>Login required to perform this action </h5> */}
                                            <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />

                                        </Modal.Body>
                                    </Modal>
                                    {/* <Link to={{
                                        pathname: `/add-photos/${this.state.business.business_slug}`,
                                        state: {
                                            // business: this.state.business,
                                            // search: this.state.props_data.state.search,
                                            // location: this.state.props_data.state.location
                                        }
                                    }}><AiOutlineCamera /> Add Photo</Link> */}
                                    <div className="search-area-gallery">
                                        <input type="text" className="form-control" placeholder="Search photos..."></input>
                                        <button className="button search-btn" type="submit"><RiSearchLine /></button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12}>
                            {this.state.externalData ? <GalleryTabs business={this.state.business} all_images={this.state.all} images={this.state.images} /> : ""}
                        </Col>
                    </Row>
                </Container>




                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default ListingGallery;