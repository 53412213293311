import React, { Component } from 'react';
import { FiRefreshCw, FiChevronRight } from "react-icons/fi";
import NavbarListing from "../../components/common/NavbarListing";
import PostListing from "../../components/places/PostListing";
import PostListSidebar from "../../components/sidebars/PostListSidebar";
import Button from "../../components/common/Button";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from '../../assets/images/bread-bg.jpg';
import PostMapViewCluster from "../../components/contact/PostMapViewCluster";
import { getGeoInfo, uLocation, uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader';
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import MapFullImage from "../../assets/images/MapFullImage.png"
class PostsListingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            suggested: [],
            neighborhoods: [],
            zipCodes: [],
            businesses: [],
            search_data: {
                'location': this.props.match.params.loc.replaceAll('-', ' ') + ', ' + this.props.match.params.state,
                'lat': uLocation().latitude ?? 39.9525839,
                'lng': uLocation().longitude ?? -75.1652215,
            },
            externalData: false,
            next: "",
            previous: "",
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            defaultSearch: '',
            loaded: false,
            sortFilter: 'Default',
            handlerCall: false,
            map_lat: 39.9525839,
            map_long: -75.1652215,
            location: this.props.match.params.loc + ', ' + this.props.match.params.state,
            defaultLocation: this.props.match.params.loc.charAt(0).toUpperCase() + this.props.match.params.loc.slice(1) + ', ' + this.props.match.params.state.toUpperCase(),
            text: 'All Results',
            page: 0,
            prevY: 0,
            next_limit: 0,
            city_name: this.props.match.params.loc.charAt(0).toUpperCase() + this.props.match.params.loc.slice(1),
        }
        this.handler1 = this.handler1.bind(this);
        this.handler = this.handler.bind(this);
        // this.handleSortFilter = this.handleSortFilter.bind(this);
        // this.child = React.createRef();
        // this.child1 = React.createRef();
        this.handler2 = this.handler2.bind(this);
    }
    handler2() {
        this.setState({
            externalData: false
        })
        // this.props.history.go('/');
    }



    handler1 = async (e) => {

        console.log('heellooooooooo', e)
        var neighborhoods_filter = e.neighborhoods.filter(function (e2) {
            return e2 != null;
        });
        var suggestion = e.suggestion.filter(function (e4) {
            return e4 != null;
        });

        let zipcode = '';
        if (e.zipcodes != 'All') {
            zipcode = e.zipcodes;
        }
        var datam = {
            'neighborhood': neighborhoods_filter,
            'location': this.state.location.replaceAll('-', ' '),
            'distance': e.distance,
            'zip_code': zipcode,
            'type_id': suggestion,
            'lat': this.state.search_data.lat,
            'lng': this.state.search_data.lng,
        }
        this.setState({
            next_limit: 0,
            search_data: datam
        }, () => this.getBusinessList(datam, 0))
        // await 
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location2: e.location });
    }




    componentDidMount = async () => {
        console.log('abc , thiis', this.props)
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);

        await this.getBusinessList();

    }

    handleObserver = async (entities, observer) => {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y) {
            await this.getBusinessList(this.state.search_data, this.state.next_limit);
        }
        this.setState({ prevY: y });
    }

    getBusinessList = async (datam, count) => {
        var self = this;
        self.setState({
            loaded: false
        })
        var data = {}
        if (datam) {
            data = datam;
        } else {
            data = self.state.search_data;
        }


        let pg = 0;

        console.log('as', count)

        if (count != undefined) {
            pg = count;
        } else {
            pg = this.state.next_limit
        }

        await axios.post(ApiUrl + `searchBusinessPost?page=${pg}&limit=10`, data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)

                if (pg == 0) {
                    self.setState({ businesses: res.data.lists });
                } else {

                    self.setState({ businesses: [...self.state.businesses, ...res.data.lists] });
                }
                self.setState({
                    neighborhoods: res.data.neighborhoods,
                    zipCodes: res.data.zip_codes,
                    suggested: res.data.types,
                    search_data: data,
                    totalpage: res.data.total_pages,
                    handlerCall: false,
                    loaded: true,
                    map_lat: res.data.map_lat,
                    map_long: res.data.map_long,
                    next_limit: res.data.next_limit
                })
                // $('html, body').animate({ scrollTop: 0 }, 1000);
            })
            .catch(function (error) {
                self.setState({
                    businesses: [],
                    neighborhoods: [],
                    zipCodes: [],
                    search_data: data,
                    totalpage: 0,
                    loaded: true
                })
                console.log(error);
            });
    }



    render() {
        var div_top = 0;
        $(window).scroll(function () {
            var window_top = $(window).scrollTop() - 0;
            if (window_top > div_top) {
                // $('.listin_nav').addClass('navbar-none');
                if (!$('.map-side').is('.sticky')) {
                    $('.map-side').addClass('sticky');

                }
            } else {
                $('.map-side').removeClass('sticky');
                // $('.listin_nav').removeClass('navbar-none');
            }
        });

        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location2.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        return (
            <div className="application">
                <Helmet>
                    <title>Katika | The Block {`${this.state.defaultLocation.replaceAll('-', ' ')}`}</title>
                    <meta name="title" content={`Katika | The Block ${this.state.defaultLocation.replaceAll('-', ' ')}`}></meta>
                    <meta property="og:title" content={`${this.state.defaultLocation.replaceAll('-', ' ')}`} />
                    <meta property="og:description"
                        content={`${this.state.defaultLocation.replaceAll('-', ' ')}`} />
                    <meta property="og:image" content='https://katika-images.s3.amazonaws.com/profile/katika-default-logo1613972445.jpg' />
                </Helmet>
                <main className="listing-list post-list">
                    <Loader loaded={this.state.loaded}></Loader>
                    {/* Header */}
                    <NavbarListing handler={this.handler} />

                    {/* Place List */}
                    <section className="card-area" style={{ minHeight: "800px" }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="ListingLeftSide">
                                    <div className="ListingBox">
                                        <div className="side-bar-left">
                                            <div className="col-lg-12">

                                                <PostListSidebar handler1={this.handler1}
                                                    handlerCall={this.state.handlerCall}
                                                    filters={this.state.filters}
                                                    all_features={this.state.all_features}
                                                    suggested={this.state.suggested}
                                                    neighborhoods={this.state.neighborhoods}
                                                    postZipCodes={this.state.zipCodes}
                                                    state={this.props.location ? this.props.location.state : ""}
                                                    cityName={this.state.city_name}
                                                />

                                            </div>
                                        </div>
                                        <div className="b-listing">

                                            <div className="col-lg-12">
                                                <h6 className="pb-1">{this.state.defaultLocation.replaceAll('-', ' ')}</h6>

                                                <h2 className="heading-listing">{this.state.defaultLocation.replaceAll('-', ' ')} Businesses {this.state.search_data.zip_code ? `located in ${this.state.search_data.zip_code}` : "located in All"}</h2>

                                                <h2 className="all-result">{this.state.text}</h2>
                                                {(this.state.loaded) ? <PostListing handler2={this.handler2} businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}
                                                {/* <PlaceListing onRef={ref => (this.child = ref)} state={this.props.location.state} /> */}

                                                <div className="col-lg-12 text-center">
                                                    {
                                                        this.state.businesses.length < 1 ?
                                                            <p className="text-danger padding-top-80px"> No search  result found </p>
                                                            :
                                                            ""
                                                    }




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="map-side">

                                    {/* <img className='map-inside-image' src={MapFullImage} /> */}

                                    {/* {(this.state.externalData) ? <PostMapViewCluster map_long={this.state.map_long} map_lat={this.state.map_lat} businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""} */}

                                </div>


                            </div>




                        </div>
                    </section>
                    <div
                        ref={loadingRef => (this.loadingRef = loadingRef)}
                    >

                    </div>

                    {/* Newsletter 
                <NewsLetter />*/}

                    {/* Footer */}
                    <Footer />

                    <ScrollTopBtn />
                </main>
            </div>
        );


    }
}

export default PostsListingList;