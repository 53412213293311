import React from 'react';
import useToken, { uRole } from '../useToken';
import Login from "../pages/Login";
import { useHistory, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Error from '../pages/Error';
import { sellerHost } from '../config';
import BusinessLogin from '../pages/BusinessLogin';


const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  requiredRoles,
  ...rest
}) => {

  Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;
  const { token, setToken } = useToken();
  const userRole = uRole();
  const userHasRequiredRole = requiredRoles.includes(userRole)
  const message = userHasRequiredRole ? 'Please log in to view this page' : "You can't be here!"
  const history = useHistory();
  console.log(window.location.host)
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Layout>
            {token ? userHasRequiredRole ? (
              <Component {...props} />)
              :
              // <p>as</p>
              <Error />
              // history.goBack()
              : (

                window.location.host == sellerHost
                  ?
                  <BusinessLogin goFarward={history.location.pathname} setToken={setToken} />
                  :
                  <Login goFarward={history.location.pathname} setToken={setToken} />
              )}

          </Layout>
          <ToastContainer />
        </>
      )}
    />
  );
}

export default ProtectedRoute;