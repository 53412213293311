import React, { useState } from 'react';
import SignInOptionsPopup from "./SignInOptionsPopup";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import axios from "axios";
import { ApiUrl } from "../../../config"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import useToken from '../../../useToken';
import { FaEye } from 'react-icons/fa';



function LoginBox({ title, subtitle, props, redirect, handler1, goFarward }) {
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [show, setShow] = useState('password');
    const { setToken } = useToken();
    let history = useHistory();
    const handleSubmit = async e => {

        e.preventDefault();
        if (email != '' && password != '') {
            axios.post(ApiUrl + 'login', {
                email,
                password
            }, {
                'Content-Type': 'application/json',
                'Authorization': 'JWT fefege...'
            })
                .then(function (response) {
                    if (response.data.success === true) {
                        toast.success("Login Successful!");
                        setToken(response.data);
                        if (handler1) {
                            handler1(true)
                        }
                        if (redirect != '1234') {
                            if (response.data.role == '1') {

                                if (goFarward != undefined) {
                                    setTimeout(
                                        () => history.go(goFarward),
                                        4000
                                    );
                                } else {

                                    setTimeout(
                                        () => history.push("/user-profile"),
                                        4000
                                    );
                                }
                            } else if (response.data.role == '2' || response.data.role == '0') {
                                setTimeout(
                                    () => history.push("/dashboard"),
                                    4000
                                );
                            }

                        }
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            // setToken({"token":"abc"});
        }
    }

    return (
        <>

            <div className="billing-form-item mb-0 LoginPopup">
                <div className="border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {title}
                    </h3>
                    {/* <p className="font-size-16 font-weight-medium">
                        {subtitle} <Link to='/sign-up'> Sign up</Link>
                    </p>
                    <p>By logging in, you agree to Katika’s <Link to='/terms-of-service'>Terms of Service</Link> and */}
                    {/* <Link to='/privacy-policy'> Privacy Policy.</Link></p> */}
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                        <form onSubmit={handleSubmit}>
                            <div className="row">

                                <SignInOptionsPopup goFarward={goFarward} handler1={handler1} redirect={redirect} />

                                {/* <div className="col-lg-12">
                                    <div className="account-assist mt-4 mb-4 text-center">
                                        <p className="account__desc">or</p>
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                            <label className="label-login">Your email</label>
                                            <input required="required" onChange={e => setUserName(e.target.value)} className="form-control" type="email" name="text"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group no-margin">
                                        <label className="label-login">Password</label>
                                            <input required="required" onChange={e => setPassword(e.target.value)} className="form-control" type={show} name="text"/>
                                            <span onClick={() => show == 'password' ? setShow('text') :   setShow('password')} className="showPassword"><FaEye /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="btn-box mt-4">
                                        <button className="theme-btn btn-login border-0" type="submit">
                                            Login
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group no-margin">
                                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                            <div>
                                                <Link to="/recover" className=" font-weight-medium ForgotPass">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="font-weight-medium CreateOne">Don’t have an account?
                                        <Link
                                            to={{ pathname: '/sign-up', state: { prevPath: history.location.pathname } }}
                                        > Create One</Link></p>
                                </div>
                                <div className="col-lg-12">
                                    <p className="font-weight-medium1 termlogin">By logging in, you agree to Katika’s Terms of Service and Privacy Policy.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default LoginBox;

// LoginBox.propTypes = {
//     setToken: PropTypes.func.isRequired
// };