import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import useToken, { uToken, uInfo, uRole } from '../../useToken';
import { Container, Dropdown } from "react-bootstrap";
import UserInfoSignIn from "../../components/user/UserInfoSignIn";
import Chat1 from '../../assets/images/chat1.svg';
import Notification1 from '../../assets/images/bell1.svg';
import Pusher from 'pusher-js'
import { ApiUrl, buyerUrl, sellerUrl, PusherKey} from '../../config';
import axios from 'axios';

let pusher = new Pusher(PusherKey, {
    authEndpoint: `${buyerUrl}/pusher/auth`,
    cluster: "mt1"
})
var channel = pusher.subscribe('my-channel');

export default function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [token, setToken] = useState(!uToken() ? '' : uToken());
    const [count, setCount] = useState(0);
    let history = useHistory();

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    useEffect(() => {
        getUserMessageCount();
        if (uInfo()) {
            // console.log('check auth', uInfo().id)
            channel.bind("chat-event", function (data) {
                var message = data;
                if (message.data.sent_to == uInfo().id) {
                    // console.log('Message', message.data)
                    getUserMessageCount()
                }
            });
        }

    }, []);

    const getUserMessageCount = async e => {
        // console.log('sdddddddddddddddddddddddda')
        axios.post(ApiUrl + 'getUserMessagesCount', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    setCount(res.data.count)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
        // history.push("/");
        // setTimeout(
        //     () => history.push("/"),
        //     4000
        // );
    }

    function WarningBanner() {
        if (!token) {
            return (
                <ul>
                    <li>
                        <Dropdown className="dropdrown-login">
                            <Dropdown.Toggle  drop={'start'} variant="link" id="dropdown-basic">
                                Log in
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <Link to="/login">Katika for Shoppers</Link>
                                <a href={`${sellerUrl}/business-login`} > Katika for Business</a>
                            </Dropdown.Menu>

                        </Dropdown>
                    </li>
                    <li><Link to={{ pathname: '/signup', state: { prevPath: history.location.pathname } }}>Sign Up</Link></li>
                </ul>
            );
        } else {
            return (
                <ul>
                    {window.location.pathname == '/' ?
                        <li className="user-profile-name">
                            <div className="user-side directory">
                                {count > 0 ?
                                    <div className="notification">
                                        <span>{count}</span>
                                    </div>
                                    : ""
                                }
                                <Link to="/user-message"><img src={Chat1} alt="Chat" />
                                </Link>
                            </div>
                            <div className="user-side Chat">
                                {/* <div className="notification">
                                    <span>5</span>
                                </div> */}
                                <Link to="/user-message"><img src={Notification1} alt="Notification" />
                                </Link>
                            </div>
                            {/* <Link onClick={LogoutBtn} to="/"> LOGOUT </Link> */}
                            <div className="user-area">
                                <div className="user-side login">

                                    {
                                        (uRole() == 1) ?
                                            <>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <img src={uInfo().image} alt="user" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <UserInfoSignIn />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            : (uRole() == 2 || uRole() == 0) ?



                                                <Link to="/dashboard"><img src={uInfo().image} alt="user" /></Link>
                                                : ""

                                    }

                                </div>

                            </div>
                        </li>
                        :
                        <li> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li>
                    }
                </ul>
            );
        }
    }

    return (
        <>
            <div className="main-menu-content">
                <nav>
                    <WarningBanner />
                </nav>
            </div>
            <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li><Link to="/login">Log In</Link></li>
                        <li><Link to="/sign-up">Sign Up</Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
