import React, { Component } from "react"
import Autocomplete from 'react-google-autocomplete';
import { AutoApiKey } from '../../config';

import Autosuggest from 'react-autosuggest';
import { algoliaAppId, ApiUrl, algoliaAppKeyId } from '../../config';

import algoliasearch from "algoliasearch";
import { getCurrentLocation } from "../../useToken";

const getSuggestionValue = (suggestion) => suggestion.city + ', ' + suggestion.state
// const renderSuggestion = (suggestion) => (suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)
const renderSuggestion = (suggestion) => {
    return <span>{suggestion.city}, {suggestion.state}</span>;
}
export default class GoogleSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.location2 ? props.location2 : '',
            suggestions: [],
            location: '',
        }
        global = this;
    }


    getLocation = async () => {
        var loc = await getCurrentLocation();
        console.log(loc)
        this.setState({ value: 'Current Location', location: 'Current Location' });
        this.onSuggestionsClearRequested();
        setTimeout(() => {
            this.props.Chandler({ location: 'Current Location', loc: loc })
        }, 1000);


    }

    componentDidMount() {
        if(this.state.value == ''){
            var sLocation = JSON.parse(localStorage.getItem('location'));
            let completeLocation = '';
            if(sLocation){
                completeLocation = sLocation.city + ', ' + sLocation.region;
            }else{
                completeLocation = 'Philadelphia, PA';
            }

            this.setState({
                value: completeLocation
            })
        }
        
    }
    renderSuggestionsContainer = ({ containerProps, children, query }) => {
        return (
            <div {...containerProps}>
                <div className="suggestions-container">

                    {children !== null && !query ? (
                        <div className="suggest" onClick={() => global.getLocation()}>
                            <span >
                                Current Location
                 {/* Press Enter to search <strong>{query}</strong> */}
                            </span>
                        </div>
                    ) : null}

                    <div className="children">{children}</div>
                </div>
            </div>
        );
    };

    
    handleSearch = (place) => {
        // console.log(place)
        // var split = place.formatted_address.split(",");
        // var x = split.slice(0, split.length - 2);
        this.props.handler({ allloction: place, location: place.formatted_address })
    }

    onChange = async (event, { newValue, method }) => {

        this.setState({ value: newValue, search: newValue });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        fetch(`${ApiUrl}searchLocation?search=${value}`)
            .then(response => response.json())
            .then(data => this.setState({ suggestions: data.lists }))
    }

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionSelected = async (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        let datam = {
            'city': suggestion.city,
            'region': suggestion.state,
            'country': 'USA',
            'country_name': '',
            'latitude': parseFloat(suggestion.latitude),
            'longitude': parseFloat(suggestion.longitude),
        }
        localStorage.setItem('location', JSON.stringify(datam));
        this.props.Chandler({ location: suggestion.city + ', ' + suggestion.state, loc: datam })
    }

    shouldRenderSuggestions = () => {
        // this.setState({ suggestions: [] });
        return true;
    }
   
    render() {
        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Location',
            value,
            className: "form-control c1 near-feild",
            type: "text",
            onChange: this.onChange
        };
        return (
            <>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    highlightFirstSuggestion={true}
                    onSuggestionSelected={this.onSuggestionSelected}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    inputProps={inputProps}
                    renderSuggestion={renderSuggestion}
                />
            </>

        );
    }
}