import React  from 'react'
import Header from '../../components/common/Header'
import Banner from '../../components/home/Banner'

import Connection from '../../components/home/Connection'
import Kapp from '../../components/home/Kapp'
import Discover from '../../components/home/Discover'
import Bussiness from '../../components/home/Bussiness'
import Resource from '../../components/home/Resource'


import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import SectionDivider from "../../components/common/SectionDivider";


const Default = () => {
    return (
        <main className="home-1">
            {/* Header */}
            <Header />

            {/* Hero Banner */}
            <Banner />

            <Connection />

            <Kapp />

            <Discover />

            <Bussiness />

            <Resource />


            {/* /Hero Banner */}

            <SectionDivider />

            {/* NewsLetter */}
            <NewsLetter />

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Default;