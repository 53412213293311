import React, {Component} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

class AccordionList extends Component {
    state = {
        items: [
            // {
            //     title: 'How do I manage my selling account?',
            //     desc: 'Anyone and everyone over the age of 13+ is permitted to be a general user on the Katika platform.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do  add inventory?',
            //     desc: 'Simply go to your Katika Profile and select the link in the upper right that says Find Friends. From the Find Friends page, use the the search box in the lower left to search for friends who have an account on the platform.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do  add inventory?',
            //     desc: 'There are several ways that you can contact a business on Katika. When you go to a businesses detail page, you can select Message the Business on the right panel or if the business has virtual consultations available, you can choose that option.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How much does it cost to sell on Katika.com?',
            //     desc: 'Katika is currently featuring Philadelphia on the home page as this is our company headquarters but you will be able to register and find black businesses in any U.S. city.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'What are the differenes between professional and Individual accounts?',
            //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'When do I start getting charge the monthly subscription fee?',
            //     desc: 'The Android apps for Katika will be available in October. The iOS apps will be available by the end of the year. For general users, the app is called Katika. For business owners, the app is called Katika for Business.',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do I get paid?',
            //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: 'mb-3'
            // },
            // {
            //     title: 'How do I sell my products in other Katika stores?',
            //     desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text',
            //     plus: <AiOutlinePlus />,
            //     minus: <AiOutlineMinus />,
            //     cardClass: ''
            // },
            {
                title: 'What is Katika for Shoppers vs. Katika for Business?',
                desc: 'Katika for Shoppers is the type of account meant for general users who support black businesses.  Anyone can create a Katika for Shoppers account through the sign up process on the website or mobile app.  Katika for Business is soley for black business owners to create their business profile which will be included in the directory.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How can I connect with friends and follow my favorite businesses?',
                desc: 'The easiest way to connect with friends and to follow your favorite business is to create a Katika for Shoppers account.  Once you create an account you will be able to see other Katika users online as well as bookmark your favorite businesses.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I enable Location Services on my mobile device?',
                desc: "You will be prompted to enable Location Services on your mobile device once you download the app.  If you need to activate this feature, go into your phone's settings and under the Katika app you will be able to enable location services.",
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: "How do I enable or change the Katika app's push notification settings?",
                desc: "You can enable or disable the push notifications from the Katika app inside of the app under the settings tab.  You can also disable or enable push notifications in the phone's settings.",
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Why does the Katika app ask for access to my contacts?',
                desc: 'The Katika app wants to help make it easy for you to let others know about the app.  We therefore ask for access to your contacts so that you can easily invite them through the "Invite Friends" tab.',
                plus: <AiOutlinePlus />,
                minus: <AiOutlineMinus />,
                cardClass: 'mb-3'
            },
        ]
    }
    render() {
        return (
            <>
                <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                    {this.state.items.map((item, i) => {
                        return (
                            <div className={'card ' + item.cardClass} key={i}>
                                <AccordionItem>
                                    <AccordionItemHeading className="card-header">
                                        <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                            {item.title}
                                            <i className="minus">{item.minus}</i>
                                            <i className="plus">{item.plus}</i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="card-body">
                                            {item.desc}
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        )
                    })}

                </Accordion>
            </>
        );
    }
}

export default AccordionList;