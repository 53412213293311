import React, { Component } from 'react';
import HomePageHeader from '../../components/business/common/HomePageHeader';
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import '../../assets/css/style2.css';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { BiCheck } from 'react-icons/bi';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import Logo from '../../components/business/Logo';
import Logosrc from '../../assets/images/dashboard-logo.svg';
class Account extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [
                {
                    title: 'When is my Plan ready to use?',
                    desc: 'Your upgraded plan will be ready to use immediately once you complete the payment process.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Can I upgrade to a higher Plan later?',
                    desc: 'Yes. All businesses start off with a Free account and you can upgrade at anytime.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'How long is the contract term?',
                    desc: 'You are billed month to month. There are no long term contracts on Katika. You can cancel at anytime.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'How do I cancel my subscription?',
                    desc: 'Simply log into your account and go to the Billing section of your account and select "Cancel Plan".',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Do you offer any other Plans?',
                    desc: 'We are currently only offering these plans at this time to provide the best value for businesses.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
            ],
            monthly: [],
            annually: [
                {
                    id: 1,
                    PackageType: 'BASIC',
                    PackageName: 'Personal busines',
                    PackagePrice: 'Free',
                    CurrentPlan: 'Buy',
                    rules: ['Create a free business page 1', 'Real time page analytics', 'Basic messaging function', 'Photo gallery with API']
                },
                {
                    id: 4,
                    PackageType: 'BUSINESS',
                    PackageName: 'Small business',
                    CurrentPlan: 'Buy',
                    PackagePrice: '$14.99 /year',
                    rules: ['Create targeted campaigns by zipcode', 'Share updates and announcements', 'Use rich media images and videos', 'Trusted secure environment']
                },
                {
                    id: 5,
                    PackageType: 'PRO',
                    PackageName: 'Medium business',
                    CurrentPlan: 'Buy',
                    PackagePrice: '$24.99 /year',
                    rules: ['Create e-brand loyalty club', 'Curate local customer list', 'Use rich media images and videos', 'In-App messaging capabilities']
                }
            ],
            PackageType: 'BASIC',
            PackageName: 'Personal busines',
            PackagePrice: 'Free',
            CurrentPlan: 1,
            Terms: '*Free, forever. No credit cards required.',
            reason: ''

        }
    }


    componentDidMount() {
        this.getPlan();
    }

    getPlan = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getPlans', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    monthly: res.data.monthlyPlan,
                    annually: res.data.annualyPlan,
                    externalData: true,
                    CurrentPlan: res.data.subscription
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    render() {
        return (
            <main className="BusinessHome FaqPages GetHelp BizHome  dashboard-page Plans account-page">
                {/* Header */}
                <HomePageHeader />
                {/* <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-3">
                                <div className="logo listing text-center">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="CommonBreadcrumb">
                    <h1 className='text-white'>Plans & Pricing</h1>
                </div>
                <Container>
                    <Row>
                        <Col lg={12} md={12} id="page-content-wrapper" className='padding-bottom-70px border-bottom'>
                            <div className="pkg-title text-center margin-bottom-50px">
                                <h1 className='mb-4'>Let us do your online marketing</h1>
                                <p className='w-75 m-auto color-black text-start'>Organizations and businesses know that having a social media presence is important but may not have the expertise or capacity to keep up with posting and sharing. You can hire Katika to manage your social media accounts for marketing ideas, outreach and engagements.</p>
                            </div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Billed Monthly</Nav.Link>
                                        <Nav.Link eventKey="second">Billed Annually</Nav.Link>
                                    </Nav.Item>

                                </Nav>

                                <Tab.Content className='package_plan'>
                                    <Tab.Pane eventKey="first">
                                        <Row className='d-flex justify-content-center'>
                                            {this.state.monthly.map((monthly, i) => {
                                                return (
                                                    <Col lg={3} md={3} key={i}>
                                                        <section className="dashboard-area account-page">
                                                            <div className="y-page-link">
                                                                <div className='border-bootom'>
                                                                    <h6>{monthly.description}</h6>
                                                                    <h5>
                                                                        {monthly.sub_title}
                                                                    </h5>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    {/* <h2>{monthly.cost > 0 ? `$${monthly.cost} /monthly` : 'Free'}</h2> */}
                                                                    <div className="current-pkg">
                                                                        <div className={`pkg-box plans-${i}`}>
                                                                            {
                                                                                i == 0 ?
                                                                                    <Link to={`business-sign-up?pkg=${monthly.slug}`}>Sign Up</Link>
                                                                                    :
                                                                                    <Link to={`business-sign-up?pkg=${monthly.slug}`}>Buy Now</Link>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">
                                                                    {
                                                                        monthly.rules && monthly.rules.map((rule, k) => {
                                                                            return (
                                                                                <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                                {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                            </div>
                                                        </section>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            {this.state.annually.map((monthly, i) => {
                                                return (
                                                    <Col lg={3} md={3} key={i}>
                                                        <section className="dashboard-area account-page">
                                                            <div className="y-page-link">
                                                                <div className='border-bootom'>
                                                                    <h6>{monthly.description}</h6>
                                                                    <h5>
                                                                        {monthly.sub_title}
                                                                    </h5>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    {/* <h2>{monthly.cost > 0 ? `$${monthly.cost}/annualy` : 'Free'}</h2> */}
                                                                    <div className="current-pkg">
                                                                        <div className={`pkg-box plans-${i}`}>
                                                                            {
                                                                                i == 0 ?
                                                                                    <Link to={`business-sign-up?pkg=${monthly.slug}`}>Sign Up</Link>
                                                                                    :
                                                                                    <Link to={`business-sign-up?pkg=${monthly.slug}`}>Buy Now</Link>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">

                                                                    {
                                                                        monthly.rules && monthly.rules.map((rule, k) => {
                                                                            return (
                                                                                <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                            );
                                                                        })

                                                                    }
                                                                </ul>
                                                                {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                            </div>
                                                        </section>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>


                            </Tab.Container>

                            <div className="FaqsQuestion pt-3">
                                <div className="SubHeading text-align-center padding-bottom-30px  margin-top-90px">Get the details</div>
                                <section className="faq-area">
                                    <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                        {this.state.items.map((item, i) => {
                                            return (
                                                <div className={'card ' + item.cardClass} key={i}>
                                                    <AccordionItem>
                                                        <AccordionItemHeading className="card-header">
                                                            <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                {item.title}
                                                                <i className="minus">{item.minus}</i>
                                                                <i className="plus">{item.plus}</i>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <div className="card-body">
                                                                {item.desc}
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </div>
                                            )
                                        })}

                                    </Accordion>
                                </section>
                            </div>

                        </Col>

                    </Row>
                    <ToastContainer />
                </Container>

                {/* Footer */}
                <Footer />
            </main >




        );
    }
}

export default Account;