import React, {Component} from 'react';
import $ from 'jquery'

class WidgetFilterFeatures extends Component {
    componentDidMount() {
        $(document).ready(function () {
            $(".sidebar-widget .filter-by-feature").css({height: 'auto', overflow: 'hidden'})
            $(document).on('click', '#showfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({height: '468px', overflow: 'hidden'})
                $(this).addClass('lessfilterbyfeature');
            })
            $(document).on('click', '.lessfilterbyfeature', function () {
                $(".sidebar-widget .filter-by-feature").css({height: '244px', overflow: 'hidden'})
                $(this).removeClass('lessfilterbyfeature');
            })
        })
    }

    state = {
        title: 'Features',
        features: [
            {
                id: 1,
                text: 'Waiter Service'
            },
            {
                id: 2,
                text: 'Offers Delivery'
            },
            {
                id: 3,
                text: 'Offers Takeout'
            },
            {
                id: 4,
                text: 'Accepts Credit Cards'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>
                    
                    <div className="check-box-list show-more-item filter-by-feature filter-category mt-4 mb-4">
                        {this.state.features.map(item => {
                            return (
                                <div className="custom-checkbox" key={item.id}>
                                    <input type="checkbox" id={'chb2-'+item.id} />
                                    <label htmlFor={'chb2-'+item.id}>
                                        {item.text}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    <div id="showfilterbyfeature" className="showmore-btn filter">
                        <span className="showmore-txt ">See all</span>
                        <span className="lessmore-txt d-none">Show Less</span>
                    </div>
                </div>
            </>
        );
    }
}

export default WidgetFilterFeatures;