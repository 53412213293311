import React, { Component } from "react"
import Autocomplete from 'react-google-autocomplete';
import { AutoApiKey } from '../../config';

export default class GoogleSuggest extends Component {
    constructor(props) {
        super(props);
    }

    handleSearch = (place) => {
        // console.log(place)
        // var split = place.formatted_address.split(",");
        // var x = split.slice(0, split.length - 2);
        this.props.handler({ allloction: place, location: place.formatted_address })
    }

    render() {
        return (
            <>
                <Autocomplete
                    apiKey={AutoApiKey}
                    placeholder=""
                    style={{ width: '100%' }}
                    onPlaceSelected={(place) => {
                        this.handleSearch(place)
                    }}
                    onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                    }}
                    autoFocus={true}
                    className="form-control c1 near-feild"
                    fields={[
                        'address_components',
                        'geometry.location',
                        'place_id',
                        'formatted_address',
                        'name'
                    ]}
                    componentRestrictions={{ country: "us" }}
                    types={["(regions)"]}

                />
            </>

        );
    }
}