import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ProtectedRoute from './utils/ProtectedRoute';
import { Redirect } from 'react-router';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Pages
import Default from "./pages/homes/Default";
import Directories from "./pages/directories/index";
import Marketplace from "./pages/marketplace/index";

/// Business Profile
import UDashboard from "./pages/user/Dashboard";
import Account from "./pages/user/Account";
import DetailedInfo from "./pages/user/DetailedInfo";
import BuyerProfile from "./pages/user/BuyerProfile";
import Security from "./pages/user/Security";
import Schedule from "./pages/user/Schedule";
import Reviews from "./pages/user/Reviews"
import Message from "./pages/user/Message";
import Stats from "./pages/user/Stats";
import BillingHistory from "./pages/user/BillingHistory"

//// Buyer Profile

import BuyerProfileOverview from "./pages/user/BuyerProfileOverview"
import WriteReviews from "./pages/user/WriteReviews"
import AddPhotos from "./pages/user/AddPhotos"

import BuyerBookmark from "./pages/user/BuyerBookmark"
import BuyerCheckIn from "./pages/user/BuyerCheckIn"
import BuyerFollowers from "./pages/user/BuyerFollowers"
import BuyerFollowing from "./pages/user/BuyerFollowing"
import BuyerFriends from "./pages/user/BuyerFriends"
import BuyerMessage from "./pages/user/BuyerMessage"
import BuyerNewsletter from "./pages/user/BuyerNewsletter"
import BuyerReviews from "./pages/user/BuyerReviews"
import BuyerSecurity from "./pages/user/BuyerSecurity";
import BuyerLocation from "./pages/user/BuyerLocation";
import BuyerLocationEdit from "./pages/user/BuyerLocationEdit";
import BuyerEmailNotifications from "./pages/user/BuyerEmailNotifications";
import PrivacySettings from "./pages/user/PrivacySettings";
import BuyerManageFriends from "./pages/user/BuyerManageFriends";
import ExternalApplications from "./pages/user/ExternalApplications";
import BuyerPhoto from "./pages/user/BuyerPhoto";

import FindFriend from "./pages/user/FindFriend";
import FindFriendFB from "./pages/user/FindFriendFB";
import BuyerStory from "./pages/user/BuyerStory";

import Help from "./pages/user/Help";

//////////////////////////

import BuyerPublicProfileOverview from "./pages/user/public/BuyerProfileOverview";
import BuyerPublicReviews from "./pages/user/public/BuyerReviews"
import BuyerPublicFriends from "./pages/user/public/BuyerFriends"
import BuyerPublicBookmark from "./pages/user/public/BuyerBookmark"
import BuyerPublicCheckIn from "./pages/user/public/BuyerCheckIn"
import BuyerPublicPhoto from "./pages/user/public/BuyerPhoto";
import ViewSingleStory from "./pages/user/public/ViewSingleStory";

import ViewStory from "./pages/user/StoryGallery";
import AllKatikaCategories from './pages/directories/AllCategories';




///////////////////Footer Page ////////////////

import TermsOfService from "./pages/business/TermsOfService";
import PrivacyPolicy from "./pages/business/PrivacyPolicy";
import Press from "./pages/business/Press";
import FAQ from "./pages/business/FAQ";
import Partners from "./pages/business/Partners";
import Labs from "./pages/business/Labs";
import AboutUs from "./pages/business/AboutUs";
import GetHelp from "./pages/business/GetHelp";

/////////////////////Footer Page



////
import ListingGallery from "./pages/ListingGallery";

// Old Page
import Home from "./pages/homes/Home";
import Home2 from './pages/homes/Home2';
import Home3 from './pages/homes/Home3';
import Home4 from './pages/homes/Home4';
import Home5 from './pages/homes/Home5';
import AllCategories from './pages/categories/AllCategories';
import AllLocations from './pages/categories/AllLocations';
import TopPlaces from "./pages/categories/TopPlaces";
import ListingGrid from "./pages/listings/ListingGrid";
import ListingList from "./pages/listings/ListingList";
import PostList from "./pages/listings/PostList";
import PostDetails from "./pages/user/public/PostDetails";
import Direction from "./pages/listings/Directions";
import ListMapView from "./pages/listings/ListMapView";
import ListMapView2 from "./pages/listings/ListMapView2";
import ListLeftSidebar from "./pages/listings/ListLeftSidebar";
import ListRightSidebar from "./pages/listings/ListRightSidebar";
import ListingDetails from "./pages/listings/ListingDetails";
import AddListing from "./pages/listings/AddListing";
import UserProfile from "./components/other/account/UserProfile";
import TopAuthors from "./pages/TopAuthors";
import Dashboard from "./pages/dashboard/Dashboard";
import Booking from "./pages/dashboard/Booking";
import BookingConfirmation from "./pages/dashboard/BookingConfirmation";
import Invoice from "./pages/dashboard/Invoice";
import PricingPlan from "./pages/PricingPlan";
import About from "./pages/About";
import Faq from "./pages/FAQ";
import Contact from "./pages/Contact";
import RecoverPassword from "./pages/RecoverPassword";
import BlogFullWidth from "./pages/blogs/BlogFullWidth";
import BlogGrid from "./pages/blogs/BlogGrid";
import BlogLeftSidebar from "./pages/blogs/BlogLeftSidebar";
import BlogRightSidebar from "./pages/blogs/BlogRightSidebar";
import BlogDetail from "./pages/blogs/BlogDetail";
import Login from "./pages/Login";
import BuyerRecoverPassword from "./pages/BuyerRecoverPassword";
import SignUp from "./pages/SignUp";
import VerifyAccount from "./pages/VerifyAccount";
import PSignUp from "./pages/PSignUp";
import BusinessSignUp from "./pages/BusinessSignUp";
import Error from "./pages/Error";
import useToken, { uInfo } from './useToken';
import Pusher from 'pusher-js'
import $ from 'jquery'
import { ApiUrl, MixpanelToken } from './config';
import ReactGA from 'react-ga';
import axios from 'axios';

import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
mixpanel.init(MixpanelToken, { debug: false });


const App = (props) => {
  const childRef = useRef();
  let location = useLocation();
  const { setToken } = useToken();

  // if(!token) {
  //   return <Login setToken={setToken} />
  // }


  useEffect(() => {

    // $('img').attr({ width: '100%', height: '100%' });

    document.body.classList.add('is-loaded')


    axios.get(ApiUrl + 'getGoogleAnalyticsKey', {}, {
      headers: {}
    })
      .then(function (res) {
        let key = 'UA-192424311-1';
        if (res.data.success === true) {
          key = res.data.lists.google_analytics;
        }
        ReactGA.initialize(key);

        if (uInfo()) {
          ReactGA.set({ userId: uInfo().analytic_id });
          mixpanel.identify(uInfo().analytic_id);
        } else {
          ReactGA.set({ userId: `Visitor-${Math.floor(Math.random() * 90000) + 10000}` });
          mixpanel.identify(`Visitor-${Math.floor(Math.random() * 90000) + 10000}`);

        }

        mixpanel.track(window.location.href, { 'source': window.location.href });
        ReactGA.pageview(window.location.href);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (window.location.hash == '') {
      $('html, body').animate({ scrollTop: 0 }, 1000);
    } else {
      console.log('hasghfound')
    }


    // window.scrollTo(0, 0);
    // childRef.current.init();
    // if (location.pathname != '/message') {
    //   let pusher = new Pusher('3086524ac1732fefac3c', {
    //     authEndpoint: `${ApiUrl}pusher/auth`,
    //     cluster: "mt1"
    //   })
    //   pusher.unsubscribe('my-channel');
    // }
  }, [location]);

  return (
    // %s - Katika string.includes(substring)
    <div className="application_route">

      <Helmet titleTemplate={window.location.href.includes('theblock') ? '' : '%s - Katika'} defaultTitle="Katika | Connecting everyone to black owned businesses">
        <meta name="title" content="Katika | Connecting everyone to black owned businesses" />
        <meta name="description" content="Find black owned businesses in our directory or products in our marketplace from entrepreneurs of the African-Diapsora today." />
        <meta name="og:image" content='https://katika-images.s3.amazonaws.com/profile/katika-default-logo1613972445.jpg' />
        <meta property="og:title" content="Katika | Connecting everyone to black owned businesses" />
        <meta property="og:description"
          content="Find black owned businesses in our directory or products in our marketplace from entrepreneurs of the African-Diapsora today" />
        <meta property="og:image" content='https://katika-images.s3.amazonaws.com/profile/katika-default-logo1613972445.jpg' />
      </Helmet>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>

            {/* //////////// Login Account */}

            {/* Business Profile */}

            {/* <ProtectedRoute path="/dashboard" component={UDashboard} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/account" component={Account} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/detailed-info" component={DetailedInfo} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/security" component={Security} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/schedule" component={Schedule} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/reviews" component={Reviews} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/message" component={Message} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/stats" component={Stats} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/billing-history" component={BillingHistory} requiredRoles={[0, 2]} /> */}


            {/* ///////////////////public */}

            <AppRoute path="/user-profile/:id" component={BuyerPublicProfileOverview} />
            <AppRoute path="/user-reviews/:id" component={BuyerPublicReviews} />
            <AppRoute path="/user-friends/:id" component={BuyerPublicFriends} />
            <AppRoute path="/user-bookmarks/:id" component={BuyerPublicBookmark} />
            <AppRoute path="/user-check-in/:id" component={BuyerPublicCheckIn} />
            <AppRoute path="/user-photo/:id" component={BuyerPublicPhoto} />
            <AppRoute path="/view-story" component={ViewStory} />
            <AppRoute path="/story/:id" component={ViewSingleStory} />

            <AppRoute path="/user-message/:id" component={Error} requiredRoles={[1]} />
            <AppRoute path="/user-newsletters/:id" component={Error} />
            <AppRoute path="/user-security/:id" component={Error} />
            <AppRoute path="/user-location/:id" component={Error} />
            <AppRoute path="/user-email-notifications/:id" component={Error} />

            <AppRoute path="/user-privacy-settings/:id" component={Error} />
            <AppRoute path="/user-manage-friends/:id" component={Error} />
            <AppRoute path="/external-applications/:id" component={Error} />




            {/* buyer Profile */}
            <ProtectedRoute path="/update-buyer-profile" component={BuyerProfile} requiredRoles={[1]} />
            <ProtectedRoute path="/user-profile" component={BuyerProfileOverview} requiredRoles={[1]} />
            <ProtectedRoute path="/write-review/:id" component={WriteReviews} requiredRoles={[0, 1, 2]} />
            <ProtectedRoute path="/add-photos/:id" component={AddPhotos} requiredRoles={[0, 1, 2]} />
            <ProtectedRoute path="/user-bookmarks" component={BuyerBookmark} requiredRoles={[1]} />
            <ProtectedRoute path="/user-check-in" component={BuyerCheckIn} requiredRoles={[1]} />
            <ProtectedRoute path="/user-followers" component={BuyerFollowers} requiredRoles={[1]} />
            <ProtectedRoute path="/user-following" component={BuyerFollowing} requiredRoles={[1]} />
            <ProtectedRoute path="/user-friends" component={BuyerFriends} requiredRoles={[1]} />
            <ProtectedRoute path="/user-message" component={BuyerMessage} requiredRoles={[1]} />
            <ProtectedRoute path="/user-newsletters" component={BuyerNewsletter} requiredRoles={[1]} />
            <ProtectedRoute path="/user-reviews" component={BuyerReviews} requiredRoles={[1]} />
            <ProtectedRoute path="/user-security" component={BuyerSecurity} requiredRoles={[1]} />
            <ProtectedRoute path="/user-location" component={BuyerLocation} requiredRoles={[1]} />
            <ProtectedRoute path="/user-location-edit/:id?" component={BuyerLocationEdit} requiredRoles={[1]} />
            <ProtectedRoute path="/user-email-notifications" component={BuyerEmailNotifications} requiredRoles={[1]} />
            <ProtectedRoute path="/user-privacy-settings" component={PrivacySettings} requiredRoles={[1]} />
            <ProtectedRoute path="/user-manage-friends" component={BuyerManageFriends} requiredRoles={[1]} />
            <ProtectedRoute path="/external-applications" component={ExternalApplications} requiredRoles={[1]} />
            <ProtectedRoute path="/user-photo" component={BuyerPhoto} requiredRoles={[1]} />

            <ProtectedRoute path="/find-friends" component={FindFriend} requiredRoles={[1]} />
            <ProtectedRoute path="/find-friends-fb" component={FindFriendFB} requiredRoles={[1]} />
            <ProtectedRoute path="/user-stories" component={BuyerStory} requiredRoles={[1]} />







            {/* ///////////////////Footer Page //////////////// */}

            <AppRoute path="/terms-of-service" component={TermsOfService} />
            <AppRoute path="/privacy-policy" component={PrivacyPolicy} />
            <AppRoute path="/press" component={Press} />
            <AppRoute path="/faq" component={FAQ} />
            <AppRoute path="/labs" component={Labs} />
            <AppRoute path="/partners" component={Partners} />
            <AppRoute path="/about-us" component={AboutUs} />
            <AppRoute path="/help" component={Help} />
            <AppRoute path="/get-help" component={GetHelp} />



            {/* ///////////////////Footer Page //////////////// */}

            {/* /////////////// login user ///////////// */}
            <AppRoute exact path="/" component={Directories} />
            {/* <AppRoute path="/directories" component={Directories} /> */}
            {/* <AppRoute path="/marketplace" component={Marketplace} /> */}
            {/* <AppRoute path="/index1" component={Home} /> */}
            <AppRoute path="/index2" component={Home2} />
            <AppRoute path="/index3" component={Home3} />
            <AppRoute path="/index4" component={Home4} />
            <AppRoute path="/index5" component={Home5} />
            <AppRoute path="/all-categoriesss" component={AllCategories} />
            <AppRoute path="/all-categories" component={AllKatikaCategories} />


            <AppRoute path="/all-locations" component={AllLocations} />
            <AppRoute path="/top-place" component={TopPlaces} />
            <AppRoute path="/listing-grid" component={ListingGrid} />
            <AppRoute path="/listing-list" component={ListingList} />
            <AppRoute path="/listing/:id/:state/:loc" component={ListingList} />
            <AppRoute path="/theblock/:state/:loc" component={PostList} />
            <AppRoute path="/block/details/:id" component={PostDetails} />

            <AppRoute path="/maps/:id" component={Direction} />
            <AppRoute path="/listing-gallery/:id" component={ListingGallery} />
            <AppRoute path="/list-map-view" component={ListMapView} />
            <AppRoute path="/list-map-view2" component={ListMapView2} />
            <AppRoute path="/list-left-sidebar" component={ListLeftSidebar} />
            <AppRoute path="/list-right-sidebar" component={ListRightSidebar} />
            <AppRoute path="/biz/:id" component={ListingDetails} />
            <AppRoute path="/add-listing/new" component={AddListing} />
            {/* <AppRoute path="/user-profile" component={UserProfile} /> */}
            <AppRoute path="/top-author" component={TopAuthors} />
            <AppRoute path="/dashboard123" component={Dashboard} />
            <AppRoute path="/booking" component={Booking} />
            <AppRoute path="/booking-confirmation" component={BookingConfirmation} />
            <AppRoute path="/invoice" component={Invoice} />
            <AppRoute path="/pricing" component={PricingPlan} />
            <AppRoute path="/about" component={About} />
            <AppRoute path="/faq" component={Faq} />
            <AppRoute path="/contact" component={Contact} />
            <AppRoute path="/recover" component={BuyerRecoverPassword} />
            {/* <AppRoute path="/recover" component={RecoverPassword} /> */}
            <AppRoute path="/blog-full-width" component={BlogFullWidth} />
            <AppRoute path="/blog-grid" component={BlogGrid} />
            <AppRoute path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <AppRoute path="/blog-right-sidebar" component={BlogRightSidebar} />
            <AppRoute path="/blog-single" component={BlogDetail} />
            <AppRoute path="/login"> <Login setToken={setToken} />
            </AppRoute>
            {/* <AppRoute path="/login" setToken={setToken} component={Login} /> */}
            <AppRoute path="/sign-up" component={SignUp} />
            <AppRoute path="/signup" component={PSignUp} />
            <AppRoute path="/verify-account/:email" component={VerifyAccount} />

            {/* <AppRoute path="/assetlinks" render={() => <Redirect
              to={{
                pathname: "/.well-known/assetlinks.json"
              }}
            />}
            />

            <AppRoute path="/.well-known/apple-app-site-association" render={() => <Redirect
              to={{
                pathname: "/.well-known/apple-app-site-association"
              }}
            />}
            /> */}

            {/* <AppRoute path="/.well-known/apple-app-site-association" render={() => window.location.reload()} /> */}

            {/* <AppRoute path="/business-sign-up" component={BusinessSignUp} /> */}
            <AppRoute component={Error} />
          </Switch>
        )} />
    </div>
  );
}

export default App;