import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import PhotoUploader2 from '../../components/addlisting/PhotoUploader2';
import { uToken } from '../../useToken';
import { ApiUrl, sellerUrl } from '../../config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import InstagramLogin from 'react-instagram-login';
import Complete from "../../assets/images/complete.png";
import Incomplete from "../../assets/images/incomplete.png";
import $ from 'jquery'
import Loader from 'react-loader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaInfo } from 'react-icons/fa';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalData: false,
            InstaModal: false,
            businessImages: [],
            InstaImages: [],
            complete_section: [],
            SelectedPhoto: [],
            photo: 0,
            loaded: false,
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handlePhoto = this.handlePhoto.bind(this);
    }

    onHide() {
        this.setState({ InstaModal: false });
    }

    removeImage(id) {
        var self = this;
        const newList = this.state.businessImages.filter((item) => item.id !== id);
        self.setState({
            businessImages: newList
        });
        axios.post(ApiUrl + 'removeBusinessImage ', { 'image_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        businessImages: newList
                    });

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    approveImage(id, index) {
        var self = this;
        let businessImages = [...this.state.businessImages];
        let item = { ...businessImages[index] };
        item.status = 1;
        businessImages[index] = item;
        this.setState({ businessImages });
        axios.post(ApiUrl + 'approveBusinessImage ', { 'image_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    // self.setState({
                    //     businessImages: newList
                    // });

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    close(message) {

        if (message) {
            this.getBusinessDetail();
            this.setState({
                editPhotoModal: false,
            })
        } else {
            this.setState({
                editPhotoModal: false,
            })

        }
    }

    update() {
        this.setState({
            editPhotoModal: false,
        })
    }
    componentDidMount() {
        this.getBusinessDetail();
    }

    onFileChange(e) {
        var self = this;
        const file = e.target.files;
        self.setState({
            images: file
        })
    }
    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'userBusinessDetails', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    business: res.data.data,
                    businessId: res.data.data.business_id,
                    businessPercent: res.data.data.complete_percent,
                    businessImages: res.data.data.business_images,
                    complete_section: res.data.complete_section,
                    externalData: true,
                    loaded: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    responseInstagram = async (response) => {
        var self = this;
        // self.setState({
        //     externalData: false,

        // })

        await axios.post(ApiUrl + 'InstragramPhoto', { code: response, business_id: this.state.businessId }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                console.log(res.data.success, 'res')
                if (res.data.success == true) {
                    self.setState({
                        InstaModal: true,
                        InstaImages: res.data.lists
                    })
                    console.log(res, 'hello res')
                    // window.location.href = `${sellerUrl}/business-photos`;
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        // fetch(`${ApiUrl}InstragramPhoto?code=${response}`)
        //     .then(response => response.json())
        //     .then(data => setSuggestion(data.lists))
    }

    handlePhoto = (event) => {
        var limit = 9;
        const target = event.target;
        var value = target.value;
        var text = target.name;
        var title = target.title;
        var number = Math.floor((Math.random() * 1000) + 1);
        if (target.checked) {
            this.state.photo = this.state.photo + 1;
            this.state.SelectedPhoto[`${number}`] = value;
        } else {
            const index = this.state.SelectedPhoto.indexOf(value);
            console.log(index, 'sdsds')
            this.state.photo = this.state.photo - 1;
            if (index > -1) {
                this.state.SelectedPhoto.splice(index, 1);
            }
        }


        if ($('input[type="checkbox"]:checked').length > limit) {
            $('input[type="checkbox"]:not(:checked)').attr('disabled', 'disabled');
        } else {
            $('input[type="checkbox"]:not(:checked)').removeAttr('disabled');
        }


        this.forceUpdate();
    }

    Submit = () => {
        var self = this;
        self.setState({
            loaded: false,
        })
        var photo = this.state.SelectedPhoto.filter(function (e3) {
            return e3 != null;
        });

        var datam = {
            'photo': photo,
        }

        axios.post(ApiUrl + 'addInstragramPhoto', datam, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.setState({
                        SelectedPhoto: [],
                        InstaModal: false,
                        loaded: true,
                    });
                    window.location.href = `${sellerUrl}/business-photos`;

                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        console.log(this.state.SelectedPhoto, 'all')
    }
    render() {


        return (
            <main className="dashboard-page details-info in-loader">
                <Loader loaded={this.state.loaded}></Loader>
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                {(this.state.externalData) ?
                    <Container>
                        <Row>
                            <Col lg={3} md={3} id="sidebar-wrapper">
                                <Sidebar percent={this.state.businessPercent} />
                            </Col>
                            <Col lg={9} md={9} id="page-content-wrapper">
                                <section className="dashboard-area business-detail u-photos">
                                    <div className="y-page-link">
                                        <h2>Photos
                                            <div className="info-box-overlay">
                                                <OverlayTrigger
                                                    delay={200}
                                                    trigger={["hover", "hover"]}
                                                    placement={'auto'}
                                                    overlay={
                                                        <Popover id={`popover-positioned`}>
                                                            <Popover.Title as="h3">Photo Gallery
                                                            </Popover.Title>
                                                            <Popover.Content>
                                                                These are your main header photos that appear on your business detail page.
                                                            </Popover.Content>
                                                        </Popover>
                                                    }>
                                                    <FaInfo />
                                                </OverlayTrigger>
                                            </div>

                                            {this.state.complete_section.includes('images') ?
                                                <img src={Complete} alt="complete" />
                                                :
                                                <img src={Incomplete} alt="incomplete" />
                                            }
                                        </h2>
                                        <p className="files">Files will appear on your page header</p>
                                        <PhotoUploader2 businessId={this.state.businessId} closeModal={this.close.bind(this)} data={this.update.bind(this)} />
                                        {
                                            <>
                                                <div className="row two-clmn margin-top-35px">
                                                    {this.state.businessImages.map((item, index) => {
                                                        return (
                                                            <div className="col-lg-2 column-td-2" key={index}>
                                                                <div className="card-item">
                                                                    {/* <a class="card-image-wrap" href="/listing-details"> */}
                                                                    <div className="card-image">
                                                                        <img src={item.image} className="card__img custom-img" alt="Place" />
                                                                        <span onClick={() => this.removeImage(item.id)} className="badge-toggle b-left" data-toggle="tooltip" data-placement="bottom" title="Remove">
                                                                            <AiOutlineClose />
                                                                        </span>
                                                                        {
                                                                            item.status == 0
                                                                                ?
                                                                                <span onClick={() => this.approveImage(item.id, index)} className="badge-toggle b-right" data-toggle="tooltip" data-placement="bottom" title="Approve">
                                                                                    <AiOutlineCheck />
                                                                                </span>
                                                                                : ""
                                                                        }

                                                                    </div>
                                                                    {/* </a> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="LoginORInsta">
                                                    <span>or</span>
                                                </div>
                                                <div className="ConnectInsta">
                                                    <h4>Connect Your Instagram Account</h4>
                                                </div>
                                                <div className="InstaBtn">
                                                    <InstagramLogin
                                                        clientId="755212932023594"
                                                        scope="user_profile,user_media"
                                                        redirectUri="https://biz.katika.us/business-photos/"
                                                        useRedirect={false}
                                                        implicitAuth={false}
                                                        // scope="instagram_graph_user_media"
                                                        buttonText="Sync to Instagram"
                                                        onSuccess={this.responseInstagram}
                                                        onFailure={this.responseInstagram}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </section>
                            </Col>
                        </Row>
                        <Modal className="insta-gram-photo" show={this.state.InstaModal} onHide={this.onHide}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Instagram Gallery Upload
                                    <p>Please select your photos (10 max)</p>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.InstaImages.map((media, i) => {
                                        i++;
                                        return (
                                            <div className="col-lg-4 col-sm-4 col-md-4 InstaPhoto SelectPrice" key={i}>
                                                <div className="card-item Iphoto-card">
                                                    <div className="custom-checkbox" key={i}>
                                                        <div className="card-image " >
                                                            <input onChange={this.handlePhoto.bind(this)} className="single-checkbox" type="checkbox" title={media.id} id={'chb3-' + media.id} name={media.id} value={media.id} />
                                                            <label htmlFor={'chb3-' + media.id}> hello </label>
                                                            <img src={media.url} alt="Blog" className={`card__img${i}`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    })
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="secondary" onClick={() => this.setState({ InstaModal: false })}>Close</Button> */}
                                <Button onClick={() => this.Submit()} type="button" variant="primary" >Save Changes</Button>
                            </Modal.Footer>
                        </Modal>

                    </Container>
                    :
                    ""
                }
                <ToastContainer />
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}

export default Dashboard;