import React, { Component } from 'react';
import { FiRefreshCw, FiChevronRight } from "react-icons/fi";
import NavbarListing from "../../components/common/NavbarListing";
import PostListing from "../../components/places/PostListing";
import PostListSidebar from "../../components/sidebars/PostListSidebar";
import Button from "../../components/common/Button";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from '../../assets/images/bread-bg.jpg';
import PostMapViewCluster from "../../components/contact/PostMapViewCluster";
import { getGeoInfo, uInfo, uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader';
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import MapFullImage from "../../assets/images/MapFullImage.png"
import SectionsHeading from '../../components/common/TabsHeading';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

class PostsListingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            suggested: [],
            neighborhoods: [],
            zipCodes: [],
            businesses: [],
            search_data: {
                'location': this.props.location.city + ', ' + this.props.location.region,
                'lat': this.props.location.lat,
                'lng': this.props.location.lng,
            },
            externalData: false,
            next: "",
            previous: "",
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            defaultSearch: '',
            loaded: false,
            sortFilter: 'Default',
            handlerCall: false,
            map_lat: 39.9525839,
            map_long: -75.1652215,
            location: this.props.location.city + ', ' + this.props.location.region,
            defaultLocation: this.props.location.city + ', ' + this.props.location.region,
            text: 'All Results',
            page: 0,
            prevY: 0,
            next_limit: 0,
            comments: [],
            totalpagebookmark: 0,
            city_name: this.props.location.city.charAt(0).toUpperCase() + this.props.location.city.slice(1),

        }
        this.handler1 = this.handler1.bind(this);
        this.handler = this.handler.bind(this);
        // this.handleSortFilter = this.handleSortFilter.bind(this);
        // this.child = React.createRef();
        // this.child1 = React.createRef();
        this.handler2 = this.handler2.bind(this);
    }
    handler2() {
        this.setState({
            externalData: false
        })
        // this.props.history.go('/');
    }



    handler1 = async (e) => {

        console.log('heellooooooooo', e)
        var neighborhoods_filter = e.neighborhoods.filter(function (e2) {
            return e2 != null;
        });
        var suggestion = e.suggestion.filter(function (e4) {
            return e4 != null;
        });

        let zipcode = '';
        if (e.zipcodes != 'All') {
            zipcode = e.zipcodes;
        }
        var datam = {
            'neighborhood': neighborhoods_filter,
            'location': this.state.location,
            'distance': e.distance,
            'zip_code': zipcode,
            'type_id': suggestion,
            'lat': this.state.search_data.lat,
            'lng': this.state.search_data.lng,
        }
        this.setState({
            next_limit: 0,
            search_data: datam
        }, () => this.getBusinessList(datam, 0))
        // await 
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location2: e.location });
    }




    componentDidMount = async () => {
        console.log(this.props)
        // var options = {
        //     root: null,
        //     rootMargin: "20px",
        //     threshold: 0
        // };

        // this.observer = new IntersectionObserver(
        //     this.handleObserver.bind(this),
        //     options
        // );
        // this.observer.observe(this.loadingRef);

        if (uToken()) {
            this.getUserBookmarks();
        }

        await this.getBusinessList();

    }

    // handleObserver = async (entities, observer) => {
    //     const y = entities[0].boundingClientRect.y;
    //     if (this.state.prevY > y) {
    //         await this.getBusinessList(this.state.search_data,this.state.next_limit);
    //     }
    //     this.setState({ prevY: y });
    // }

    getBusinessList = async (datam, count) => {
        var self = this;
        self.setState({
            loaded: false
        })
        var data = {};
        if (datam) {
            data = datam;
        } else {
            data = self.state.search_data;
        }


        let pg = 0;

        console.log('as', count)

        if (count != undefined) {
            pg = count;
        } else {
            pg = this.state.next_limit
        }

        await axios.post(ApiUrl + `searchBusinessPost?page=${pg}&limit=20`, data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)

                if (pg == 0) {
                    self.setState({ businesses: res.data.lists });
                } else {

                    self.setState({ businesses: [...self.state.businesses, ...res.data.lists] });
                }
                self.setState({
                    neighborhoods: res.data.neighborhoods,
                    zipCodes: res.data.zip_codes,
                    suggested: res.data.types,
                    search_data: data,
                    totalpage: res.data.total_pages,
                    handlerCall: false,
                    loaded: true,
                    map_lat: res.data.map_lat,
                    map_long: res.data.map_long,
                    next_limit: res.data.next_limit
                })
                // $('html, body').animate({ scrollTop: 0 }, 1000);
            })
            .catch(function (error) {
                self.setState({
                    businesses: [],
                    neighborhoods: [],
                    zipCodes: [],
                    search_data: data,
                    totalpage: 0,
                    loaded: true
                })
                console.log(error);
            });
    }


    getUserBookmarks = (count) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserBookmarks?page=${1}&limit=5`, {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        comments: res.data.lists,
                        totalpagebookmark: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {

        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location2.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }

        return (
            <div className="application">
                <div className='container'>
                    <div className="cities">
                        {/* <h2>Welcome to Katika</h2> */}
                        <div className="list">
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        <h2> Welcome to Katika  </h2>
                                        <div className="tabs_heading" >
                                            <div className="section-heading display-inline">
                                                <p className={'sec__desc '}>{this.state.location}</p>
                                            </div>
                                        </div>
                                    </Tab>
                                </TabList>

                            </Tabs>
                        </div>
                    </div>
                    <main className="listing-list post-list banner">
                        <Loader loaded={this.state.loaded}></Loader>
                        <section className="card-area" style={{ minHeight: "500px" }}>
                            <div className="row">
                                <div className='col-lg-3'>
                                    <PostListSidebar handler1={this.handler1}
                                        handlerCall={this.state.handlerCall}
                                        filters={this.state.filters}
                                        all_features={this.state.all_features}
                                        suggested={this.state.suggested}
                                        neighborhoods={this.state.neighborhoods}
                                        postZipCodes={this.state.zipCodes}
                                        state={this.props.location ? this.props.location.state : ""}
                                        cityName={this.state.city_name}
                                        from="Banner"
                                    />

                                </div>
                                <div className='col-lg-6'>

                                    {(this.state.loaded) ? <PostListing from="Banner" handler2={this.handler2} businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}
                                    <div className="text-center">
                                        {
                                            this.state.businesses.length < 1 ?
                                                <p className="text-danger padding-top-80px"> No result found </p>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.businesses.length > 1 && (
                                                <span className='see-more'><Link
                                                    to={{
                                                        pathname: `/theblock/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                        // pathname: `/listing/${(item.name) ? item.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : ""}`,
                                                    }}
                                                >Show more activity in {this.state.location}</Link></span>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className='col-lg-3'>
                                    <div className='saved-bookmark'>
                                        <p className='bookmark-post-title'>My saved bookmarks</p>

                                        {
                                            uInfo().id ?
                                                <div className='list-post-bookmark'>
                                                    <ul className="comments-list padding-top-10px">
                                                        {this.state.comments.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <li key={i}>
                                                                        <Link to={`/biz/${item.business_slug}`} >
                                                                            <div className="comment listing-details">
                                                                                <div className="user-img">
                                                                                    <img className="avatar__img" alt="Comment" src={item.business_logo} />
                                                                                </div>
                                                                                <div className="comment-body">
                                                                                    <div className="meta-data">
                                                                                        <span>{item.business_name}</span>
                                                                                        <p> {item.business_address.replace(", USA", "")}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                </>

                                                            )
                                                        })}
                                                    </ul>
                                                    {
                                                        this.state.totalpagebookmark > 1 && (
                                                            <div class="text-center">
                                                                <span class="see-more">
                                                                    <Link href="/user-bookmarks">Show more</Link>
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                :

                                                <p className='bookmark-post-subtitle'>  <Link to={'/login'} >Login</Link>  or <Link to={'/sign-up'}> create an account</Link> </p>
                                        }

                                    </div>

                                </div>

                            </div>
                        </section>
                    </main>
                </div>
            </div>
        );


    }
}

export default PostsListingList;