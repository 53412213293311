import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Reservations from "../../../assets/images/bi_calendar-event.png";
import TakeOut from "../../../assets/images/TakeOut.png";
import Waiter from "../../../assets/images/fa-solid_concierge-bell.png";
import Delivery from "../../../assets/images/dashicons_car.png";
import Wi_Fi from "../../../assets/images/ant-design_wifi-outlined.png";
import Credit_Cards from "../../../assets/images/bx_bx-check.png";
import Seating from "../../../assets/images/ic_twotone-airline-seat-recline-normal.png";
import Alcohol from "../../../assets/images/fa-solid_glass-martini-alt.png";


import icons_linkedin from "../../../assets/images/akar-icons_linkedin-fill.svg";
import design_instagram from "../../../assets/images/ant-design_instagram-filled.svg";
import brandico_facebook from "../../../assets/images/brandico_facebook-rect.svg";

class WidgetCategory extends Component {
    state = {
        title: 'Social Media',
        lists: [
            {
                icon: Reservations,
                cat: 'Takes Reservations',
                url: '#'
            },
            {
                icon: TakeOut,
                cat: 'Offers Takeout',
                url: '#'
            },
            {
                icon: Waiter,
                cat: 'Waiter Service',
                url: '#'
            },
            {
                icon: Delivery,
                cat: 'Offers Delivery',
                url: '#'
            },
            {
                icon: Wi_Fi,
                cat: 'Free Wi-Fi',
                url: '#'
            },
            {
                icon: Credit_Cards,
                cat: 'Accepts Credit Cards',
                url: '#'
            },
            {
                icon: Seating,
                cat: 'Outdoor Seating',
                url: '#'
            },
            {
                icon: Alcohol,
                cat: 'Serves Alcohol',
                url: '#'
            },

        ]
    }
    render() {
        return (
            <>
                {
                    this.props.business.fb_url != '' || this.props.business.insta_url != '' || this.props.business.linkedin_url != ''
                        ?
                        <div className="sidebar-widget amenities">

                            <h3 className="widget-title">
                                {this.state.title}
                            </h3>
                            <div className="cat-list padding-top-0px social">
                                <ul className="list-items">
                                    {
                                        this.props.business.linkedin_url != '' && (
                                            <li className="mb-2 pb-2" key={0}>
                                                <a target="_blank" href={this.props.business.linkedin_url} className="d-flex align-items-center before-none">
                                                    <span><img src={icons_linkedin} alt="Amenities" className="icons" /></span> LinkedIn
                                                </a>
                                            </li>
                                        )
                                    }

                                    {
                                        this.props.business.insta_url != '' && (
                                            <li className="mb-2 pb-2" key={1}>
                                                <a target="_blank" href={this.props.business.insta_url} className="d-flex align-items-center before-none">
                                                    <span><img src={design_instagram} alt="Amenities" className="icons" /></span> Instagram
                                                </a>
                                            </li>
                                        )
                                    }

                                    {
                                        this.props.business.fb_url != '' && (
                                            <li className="mb-2 pb-2" key={2}>
                                                <a target="_blank" href={this.props.business.fb_url} className="d-flex align-items-center before-none">
                                                    <span><img src={brandico_facebook} alt="Amenities" className="icons" /></span> Facebook
                                                </a>
                                            </li>
                                        )
                                    }





                                </ul>
                            </div>
                        </div>
                        : <> </>
                }
            </>
        );
    }
}

export default WidgetCategory;