import React, { Component } from 'react';
import axios from "axios";
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { FiBox, FiPhone } from 'react-icons/fi'
import DirectoryManager from './DirectoryManager'
import BusinessManager from './BusinessManager'
import { FaCamera } from "react-icons/fa";
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

import Artboard from "../../assets/images/Artboard 1default_biz.png"

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userImg: '',
            userName: '',
            userStatus: '',
            userType: '',
            userFrom: '',
            userMembership: '',
            userRole: '',
            uploadfImage: false,
            percent: 0,
            externalData: false,
        };

    }


    componentDidMount() {
        console.log(this.props, 'Sidebar')
        if (this.props.percent) {
            this.setState({
                percent: this.props.percent
            })
        }
        this.getProfileInfo();
    }


    getProfileInfo = () => {
        var self = this;
        this.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'user', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    userImg: res.data.user.image,
                    userName: res.data.user.business_name,
                    userStatus: res.data.user.status,
                    userType: res.data.user.user_type,
                    userFrom: res.data.user.address,
                    userMembership: res.data.user.member_science,
                    userRole: res.data.user.role_id,
                    percent: res.data.user.business_al[0].complete_percent,
                    uploadfImage: false,
                    externalData: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getImage = () => {
        $('#file-input').trigger('click');
    }

    uploadfImage = (file) => {
        var self = this;
        if (!this.state.uploadfImage) {
            var formData = new FormData();
            formData.append('image', file)
            var type = file.type;
            axios.post(ApiUrl + 'updateBusinessLogo', formData, {
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({
                            uploadfImage: false,
                            userImg: res.data.image
                        })

                        let token = JSON.parse(localStorage.getItem('token'));
                        token.image = res.data.image;
                        localStorage.setItem('token', JSON.stringify(token))
                        // self.getProfileInfo();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }


    render() {
        var self = this;
        $('#file-input').on('change', function () {
            if (this.files.length > 0) {
                self.uploadfImage(this.files[0]);
                self.setState({
                    uploadfImage: true,
                })
            }
        });

        return (
            <>
                <section>
                    <div className="sidebar section-bg">

                        <div className="sidebar-widget">
                            <div className="author-bio text-align-center mb-1 author-bio-img">
                                <div className="align-items-center">
                                    <div className="UserPicture businessLogo" onClick={() => this.getImage()} >
                                        {this.state.userImg != '' ?
                                            <img src={this.state.userImg} alt="author" />
                                            :
                                            < img src={Artboard} alt="author" />
                                        }
                                        <input id="file-input" name="profile" type="file" className="none" />
                                        <div className="UpdatePicture">
                                            <div className="hover-text">Change Profile Image</div>
                                            <FaCamera />
                                        </div>
                                    </div>
                                    <div className="author-inner-bio">
                                        <h4 className="author__title font-weight-bold pt-2 pb-0 mb-1">
                                            {this.state.userName}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="section-block-2 mb-3"></div>
                            <div className="info-list">
                                <ul className="list-items">
                                    <li className="d-flex justify-content-between">
                                        Status
                                        {
                                            this.state.percent <= 69 ?
                                                <strong className="font-weight-bold bpending">Pending</strong>

                                                :

                                                <strong className="font-weight-bold bactive">Active</strong>

                                        }
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        Account Type <strong className="font-weight-bold">{this.state.userType}</strong>
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        Location <strong className="font-weight-bold">{this.state.userFrom}</strong>
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        Member since <strong className="font-weight-bold">{this.state.userMembership}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    {(this.state.userRole == 1) ?
                        <DirectoryManager />
                        :
                        <BusinessManager percent={this.props.percent} />

                    }
                </section>
            </>
        );

    }
}


export default Sidebar;