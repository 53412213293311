import React, { Component } from 'react';

import { Link } from "react-router-dom";
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import RButton from 'react-bootstrap/Button'

import Button from "../common/Button";
import Modal from 'react-bootstrap/Modal'

class PlaceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_data: {},
            items: [],
        };
        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.setState({ openModal: false, calandlyUrl: '' });
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            items: newProps.businesses,
            search_data: newProps.searchdata,
        });
    }
    componentDidMount() {
        this.setState({
            items: this.props.businesses,
            search_data: this.props.searchdata
        });
    }


    render() {
        return (
            <>
                {this.state.items.map((item, index) => {
                    return (
                        <div className="card-item card-listing d-flex listing-page" key={index}>
                            <Link
                                to={{
                                    pathname: `/biz/${item.business_slug}`,
                                    state: {
                                        b_id: item.business_id,
                                        business_details: item,
                                        search_data: this.state.search_data,
                                        search: this.state.search_data.search,
                                        location: this.state.search_data.location
                                    }
                                }}
                                className="card-image-wrap">
                                <div className="card-image">
                                    <img src={item.img} className="card__img" alt="Place" />
                                    <span className={item.titleIcon ? 'badge' : 'badge badge-closed'}>{item.bedge}</span>

                                </div>
                            </Link>

                            <div className="card-content-wrap lists">

                                <div className="card-content">
                                    <div className="top-info">
                                        <div className="product">
                                            <Link
                                                title={item.title}
                                                to={{
                                                    pathname: `/biz/${item.business_slug}`,
                                                    state: {
                                                        b_id: item.business_id,
                                                        business_details: item,
                                                        search_data: this.state.search_data,
                                                        search: this.state.search_data.search,
                                                        location: this.state.search_data.location
                                                    }
                                                }}
                                            >

                                                <h4 className="card-title">{item.title}<i> {item.titleIcon}</i>
                                                </h4>
                                                <div className="rating-row stars">
                                                    <div className="rating-rating rating-detail landing">
                                                        {/* <Rater total={5} rating={item.rating} interactive={false} /> */}
                                                        <Rating
                                                            placeholderRating={item.rating}
                                                            readonly={true}
                                                            stop={5}
                                                            emptySymbol={<img src={Empty} className="icon" />}
                                                            placeholderSymbol={<img src={Fill} className="icon" />}
                                                            fullSymbol={<img src={Fill} className="icon" />}
                                                        />
                                                        <span className="rating-count">{item.review}</span>
                                                    </div>
                                                    <div className="listing-info">

                                                    </div>
                                                </div>

                                                <p className="card-sub">
                                                    {item.category} , {item.subcategory}
                                                </p>
                                            </Link>
                                        </div>
                                        <div className="address">
                                            <ul>
                                                <li> {item.phone_number}</li>
                                                <li> {item.suite_number}</li>
                                                <li> {item.business_address}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p className="card-desc">
                                        {item.business_description}
                                    </p>

                                    {
                                        item.business_service_type.length > 0 ?

                                            <div className="bottom_buttons">
                                                {
                                                    item.business_service_type.map((service, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    index == 0 || index == 1 ?
                                                                        <>
                                                                            {(service.type == 'anchor') ?
                                                                                <a target="_blank" className="btn-link" href={/(http(s?)):\/\//i.test(service.url) ? service.url : `https://${service.url}`}> {service.name}</a>
                                                                                : (service.type == 'tel') ?
                                                                                    // ""
                                                                                    <a className="btn-link" href={`tel:${service.url}`}> {service.name}</a>
                                                                                    : (service.type == 'calandly') ?
                                                                                        <RButton onClick={() => this.setState({ openModal: true, calandlyUrl: service.url })}
                                                                                            type="button" className="r-button-d-block " variant="none">
                                                                                            {service.name}
                                                                                        </RButton>
                                                                                        : ""
                                                                            }
                                                                            {/* <Link to={'/biz/' + item.business_slug} className="call-btn">{c.type}</Link> */}
                                                                        </>
                                                                        : ""
                                                                }
                                                            </>

                                                        );


                                                    })

                                                }
                                                {/* <Link to={'/biz/' + item.business_slug} className="call-btn">Order Now</Link>
                                        <Link to={'/biz/' + item.business_slug} className="call-btn"><img src={view} alt="View" /> View Menu</Link>
                                        <a href={`tel:${item.phone_number}`} className="call-btn"><img src={call} alt="Call" /> Call</a> */}
                                            </div>
                                            : ""
                                    }

                                </div>


                            </div>
                        </div>
                    )
                })}


                <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Make an Appointment </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default PlaceListing;