import React, { Component } from 'react';
import BuyerSidebarTwo from "../../components/user/BuyerSidebarTwo";
import Footer from "../../components/common/footer/Footer";
import { Container, Dropdown } from "react-bootstrap";
import commentimg1 from '../../assets/images/avatar.jpg';
import { FaTrashAlt } from "react-icons/fa";
import { Link, Redirect } from 'react-router-dom';
import NavbarListing from '../../components/common/NavbarListing';
import { FaUserFriends, FaUserPlus } from "react-icons/fa";
import { MdStar } from "react-icons/md";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Collapse, CardBody, Card } from 'reactstrap';
import Button from 'react-bootstrap/Button'
import Edit from "../../assets/images/bx_bx-edit.png";
import { AvForm, AvGroup, AvInput, AvFeedback, } from 'availity-reactstrap-validation';
import { RiSearchLine } from 'react-icons/ri'
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import BLogo from '../../assets/images/img30.jpg';
import { ToastContainer, toast } from 'react-toastify';

// import GeneralMapDashboard from "../../components/contact/GeneralMapDashboard";
// import WidgetOpenHours from "../../components/sidebars/widgets/WidgetOpenHoursDashboard";
class BuyerManageFriends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            friends: [],
            InTotalpage: "",
            InFriends: [],
            externalData: false,
            currentPage: 1,
            ActiveKey: 0,
            searchFriend: '',
            sortFilter: 'Alphabetic A-Z',
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this);
    }
    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getFriends(count);
    }
    componentDidMount() {
        this.getFriends();
        this.getInviteFriends();
    }



    removeFriend(id) {
        var self = this;
        axios.post(ApiUrl + 'deleteUserFacebookFriend', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getFriends();
                    self.getInviteFriends();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }


    acceptFriend(id) {
        var self = this;
        axios.post(ApiUrl + 'acceptUserRequest', { 'id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getInviteFriends();
                    self.getFriends();
                } else {
                    toast.error(res.data.message);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getFriends = (count, sort, searchfriend) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        if (sort) {
            self.setState({
                sortFilter: sort
            })
        }

        if (searchfriend) {
            self.setState({
                searchFriend: searchfriend
            })
        }

        let data = {
            'sorting': sort,
            'search': searchfriend
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserFacebookFriends?page=${pg}&limit=2`, data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        friends: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getInviteFriends = (count, sort, searchfriend) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        if (sort) {
            self.setState({
                sortFilter: sort
            })
        }

        if (searchfriend) {
            self.setState({
                searchFriend: searchfriend
            })
        }

        let data = {
            'sorting': sort,
            'search': searchfriend
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserInviteFriends?page=${pg}&limit=2`, data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        InFriends: res.data.lists,
                        InTotalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebarTwo />
                        </div>
                        <div className="BuyerContent FriendsManage">
                            <div className="y-page-link reviews">
                                <div className="BuyerEmailList">
                                    <div className="BuyerEmail">
                                        <div className="BuyerPagesHeading">Manage Friends</div>
                                        <div className="sorted">
                                            <Dropdown onSelect={(sort) => this.getFriends(this.state.currentPage, sort, this.state.searchFriend)}>
                                                <label>Sort by:&nbsp;</label>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <strong>{this.state.sortFilter}</strong>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="Alphabetic A-Z">Alphabetic A-Z</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Alphabetic Z-A">Alphabetic Z-A</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </div>
                                    <div className="SearchArea">
                                        <AvForm onSubmit="#">
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Search Friends"
                                                    className="form-control search-friend"
                                                    name="title"
                                                    onChange={(e) => this.setState({ searchFriend: e.target.value })}
                                                />
                                                <AvFeedback>Required Field *</AvFeedback>
                                            </AvGroup>
                                            <div className="EmailAdd friend">
                                                <button type="button" onClick={() => this.getFriends(this.state.currentPage, this.state.sortFilter, this.state.searchFriend)}><RiSearchLine /></button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </div>
                                <section className="TabsArea">
                                    <div className="y-page-link">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={this.state.ActiveKey}
                                            onSelect={(k) => this.setState({ ActiveKey: k })}
                                        >
                                            <Tab eventKey={0} title='Manage your current friends'>
                                                <div className="reviews-tabs ManageFriends">
                                                    <div className="form-area">
                                                        <div className="tabs-content">
                                                            <ul className="comments-list">
                                                                {this.state.friends.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        <img className="avatar__img" alt="Comment" src={item.image} />
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {
                                                                                                    item.type == 'Manual' ?

                                                                                                        item.manual_user.id ?
                                                                                                            <Link to={`/user-profile/${item.manual_user.id}`} >{item.manual_user.fname} {item.manual_user.lname}</Link>
                                                                                                            :
                                                                                                            <Link to="#" >{item.name}</Link>
                                                                                                        :
                                                                                                        item.user.id ?
                                                                                                            <Link to={`/user-profile/${item.user.id}`} >{item.user.fname} {item.user.lname}</Link>
                                                                                                            :
                                                                                                            <Link to="#" >{item.name}</Link>

                                                                                                }
                                                                                            </span>
                                                                                            {/* <p> Woodbridge, VA</p> */}
                                                                                        </div>
                                                                                        {/* <div className="TotalFriends">
                                                                                            <div className="Friends"><FaUserFriends /> 6777 </div>
                                                                                            <div className="BookMark"><span className="Icon"><MdStar /></span> 0</div>
                                                                                        </div> */}

                                                                                    </div>
                                                                                    <div className="DeleteReviews">
                                                                                        <button title="Remove friend" onClick={() => this.removeFriend(item.id)} ><span><FaTrashAlt /></span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-center mt-3">
                                                        {
                                                            (this.state.totalpage > 1) ?
                                                                Array.from(Array(this.state.totalpage), (e, i) => {
                                                                    return (
                                                                        <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                                            <span className="">
                                                                                <span className="icon-label">{i + 1}</span></span>
                                                                        </button>
                                                                    );
                                                                })
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={1} title='Pending Friend Invitations'>
                                                <div className="reviews-tabs ManageFriends">
                                                    <div className="form-area">
                                                        <div className="tabs-content">
                                                            <ul className="comments-list">
                                                                {this.state.InFriends.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <li key={i}>
                                                                                <div className="comment listing-details">
                                                                                    <div className="user-img">
                                                                                        {
                                                                                            item.request_user.image ? 
                                                                                            <img className="avatar__img" alt="Comment" src={item.request_user.image} />
                                                                                            : 
                                                                                            <img className="avatar__img" alt="Comment" src={item.image} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="meta-data">
                                                                                            <span className="comment__author">
                                                                                                {
                                                                                                    item.type == 'Manual' ?

                                                                                                        item.request_user.id ?
                                                                                                            <Link to={`/user-profile/${item.request_user.id}`} >{item.request_user.fname} {item.request_user.lname}</Link>
                                                                                                            :
                                                                                                            <Link to="#" >{item.request_user.fname} {item.request_user.lname}</Link>
                                                                                                        :
                                                                                                        ""

                                                                                                }
                                                                                            </span>
                                                                                            {/* <p> Woodbridge, VA</p> */}
                                                                                        </div>
                                                                                        {/* <div className="TotalFriends">
                                                                                            <div className="Friends"><FaUserFriends /> 6777 </div>
                                                                                            <div className="BookMark"><span className="Icon"><MdStar /></span> 0</div>
                                                                                        </div> */}

                                                                                    </div>
                                                                                    <div className="DeleteReviews">
                                                                                        <button title="Accept Request" onClick={() => this.acceptFriend(item.id)} ><span><FaUserPlus /></span></button>
                                                                                        <button title="Remove friend" onClick={() => this.removeFriend(item.id)} ><span><FaTrashAlt /></span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerManageFriends;