import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Reservations from "../../../assets/images/bi_calendar-event.png";
import TakeOut from "../../../assets/images/TakeOut.png";
import Waiter from "../../../assets/images/fa-solid_concierge-bell.png";
import Delivery from "../../../assets/images/dashicons_car.png";
import Wi_Fi from "../../../assets/images/ant-design_wifi-outlined.png";
import Credit_Cards from "../../../assets/images/bx_bx-check.png";
import Seating from "../../../assets/images/ic_twotone-airline-seat-recline-normal.png";
import Alcohol from "../../../assets/images/fa-solid_glass-martini-alt.png";


class WidgetCategory extends Component {
    state = {
        title: 'Amenities and More',
        lists: [
            {
                icon: Reservations,
                cat: 'Takes Reservations',
                url: '#'
            },
            {
                icon: TakeOut,
                cat: 'Offers Takeout',
                url: '#'
            },
            {
                icon: Waiter,
                cat: 'Waiter Service',
                url: '#'
            },
            {
                icon: Delivery,
                cat: 'Offers Delivery',
                url: '#'
            },
            {
                icon: Wi_Fi,
                cat: 'Free Wi-Fi',
                url: '#'
            },
            {
                icon: Credit_Cards,
                cat: 'Accepts Credit Cards',
                url: '#'
            },
            {
                icon: Seating,
                cat: 'Outdoor Seating',
                url: '#'
            },
            {
                icon: Alcohol,
                cat: 'Serves Alcohol',
                url: '#'
            },

        ]
    }
    render() {
        return (
            <>
                {
                    this.props.business.business_features.length > 0 && (


                        <div className="sidebar-widget amenities">
                            <h3 className="widget-title">
                                {this.state.title}
                            </h3>
                            <div className="cat-list padding-top-0px">
                                <ul className="list-items">
                                    {this.props.business.business_features.map((item, i) => {
                                        if (item) {
                                            return (
                                                <li className="mb-2 pb-2" key={i}>
                                                    <Link to='#' className="d-flex align-items-center before-none">
                                                        <span><img src={item.icon} alt="Amenities" className="icons" /></span> {item.name}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    })}

                                </ul>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}

export default WidgetCategory;