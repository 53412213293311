import React, { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import useToken, { uToken, uInfo, uRole } from '../../../useToken';
import User from '../../../assets/images/user.png';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from "../../../components/business/Logo";
import Logosrc from '../../../assets/images/katika-logo.png';
import { buyerUrl, sellerUrl, communityUrl } from '../../../config';
export default function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [token, setToken] = useState(!uToken() ? '' : uToken());
    let history = useHistory();
    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })
    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })
    useEffect(() => {
    }, []);
    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
        // history.push("/");
        // setTimeout(
        //     () => history.push("/"),
        //     4000
        // );
    }
    function WarningBanner() {
        if (!token) {
            return (
                <div className="TopHeader">
                    <Container>
                        <Row>
                            <Col lg={3} sm={3}>
                                <div className="logo listing webapp">
                                    <Logo url={Logosrc} />
                                </div>
                            </Col>
                            <Col lg={7}>

                            </Col>
                            <Col lg={2} sm={2}>
                                <div className="login section biz">
                                    <div className="login">
                                        <Link to={`/business-login`}>Login</Link>
                                    </div>

                                    <div className="signup">
                                        <Link to={{ pathname: '/business-sign-up', state: { prevPath: history.location.pathname } }}>Join</Link>
                                    </div>
                                </div>
                                {/* <ul className="BNavebar">
                                    <li><Link className="LoginBtn" to="/business-login">Log In</Link></li>
                                    <li><Link to={{ pathname: '/business-sign-up', state: { prevPath: history.location.pathname } }}>Join</Link></li>
                                </ul> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        } else {
            return (
                <div className="TopHeader">
                    <Container>
                        <Row>
                            <Col lg={3} sm={3}>
                                <div className="logo listing">
                                    <Logo url={Logosrc} />
                                </div>
                            </Col>
                            <Col lg={6}>

                            </Col>
                            <Col lg={3} sm={3}>
                                <ul className="business">
                                    {window.location.pathname == '/' ?
                                        <>
                                            <li className="user-profile-name">
                                                {/* <Link onClick={LogoutBtn} to="/"> LOGOUT </Link> */}
                                                <div className="user-area">
                                                    <div className="user-side login">
                                                        {
                                                            (uRole() == 1) ?
                                                                <Link to="/buyer-profile"><img src={uInfo().image} alt="user" />
                                                                    <span>{uInfo().full_name}</span></Link>
                                                                : (uRole() == 2 || uRole() == 0) ?
                                                                    <Link to="/dashboard"><img src={uInfo().image} alt="user" />
                                                                        <span>{uInfo().full_name}</span></Link>
                                                                    : ""
                                                        }
                                                    </div>
                                                    <div className="b-logout">
                                                        <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>
                                                    </div>
                                                </div>
                                            </li>

                                        </>
                                        :
                                        <li> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li>
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }
    return (
        <>



            <div className='BLanding BNavabar bLanding'>
                <div className="main-menu-content BHome BLanding d-none d-sm-block">
                    <WarningBanner />
                </div>
                <div className='header-login-b d-block d-sm-none position-relative'>
                    <div className="logo listing">
                        <Logo url={Logosrc} />
                    </div>
                    <div className="side-menu-open d-block d-sm-none" onClick={() => setNavOpen(!navOpen)}>
                        <span className="menu__bar"></span>
                        <span className="menu__bar"></span>
                        <span className="menu__bar"></span>
                    </div>
                </div>
                <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                    <div className="humburger-menu">
                        <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                    </div>
                    <div className="side-menu-wrap">
                        {
                            !token ?
                                <ul className="side-menu-ul">
                                    {/* <li><Link to={`/business-login`}>Login</Link></li>
                                    <li><Link to={{ pathname: '/business-sign-up', state: { prevPath: history.location.pathname } }}>Join</Link></li> */}
                                    <li key={0}>
                                        <a href={`${buyerUrl}`}> Katika</a>
                                    </li>
                                    <li key={50}>
                                        <a href={`${sellerUrl}`}> Katika for Business</a>
                                    </li>
                                    <li key={1}>
                                        <a href="#" className='disabled-link'> Get to Know Us</a>
                                        <ul className="dropdown-menu-item">
                                            <li key={10}><a href={`${buyerUrl}/about-us`} >About Katika</a></li>
                                            <li key={2}><a href={`${buyerUrl}/labs`} >Katika Labs</a></li>
                                            <li key={3}><a href={`${buyerUrl}/partners`}>Partners</a></li>
                                            <li key={11}><a href={`${communityUrl}`}>Katika Community</a></li>
                                        </ul>
                                    </li>
                                    <li key={4}>
                                        <Link to="#" className='disabled-link'> Let us help you </Link>
                                        <ul className="dropdown-menu-item">
                                            <li key={5}><a href={`${sellerUrl}`}>List Your Business</a></li>
                                            <li key={6}><a href={`${buyerUrl}/faq`} >Frequently Asked Questions</a></li>
                                            <li key={15}>
                                                <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                                            </li>
                                            <li key={10}><a href={`${buyerUrl}/get-help`} >Contact Us</a></li>
                                        </ul>
                                    </li>
                                    <li key={7}>
                                        <Link to="#" className='disabled-link'> Discover</Link>
                                        <ul className="dropdown-menu-item">
                                            <li key={8}><a href={`${buyerUrl}/press`} > Press</a></li>
                                            <li key={9}><a href="https://blog.katika.us/">Blog</a></li>
                                        </ul>
                                    </li>
                                    <li key={7}>
                                        <Link to="#" className='disabled-link'> Legal</Link>
                                        <ul className="dropdown-menu-item">
                                            <li key={18}><a href={`${buyerUrl}/terms-of-service`} > Terms of Use</a></li>
                                            <li key={19}><a href={`${buyerUrl}/privacy-policy`}>Privacy Policy</a></li>
                                        </ul>
                                    </li>
                                </ul>
                                :
                                <ul className="side-menu-ul">

                                    {window.location.pathname == '/' ?
                                        <>
                                            <li className='b-img-user'>

                                                {(uRole() == 1) ?
                                                    <a href={`${buyerUrl}/user-profile`}><img className='img-thumbnail' src={uInfo().image} alt="user" />
                                                        <span>{uInfo().full_name}</span></a>
                                                    : (uRole() == 2) ?
                                                        <Link to="/dashboard"><img src={uInfo().image} className='img-thumbnail' alt="user" />
                                                            <span>{uInfo().full_name}</span></Link>
                                                        : ""
                                                }
                                            </li>
                                            <li key={0}>
                                                <a href={`${buyerUrl}`}> Katika  </a>
                                            </li>
                                            <li key={50}>
                                                <a href={`${sellerUrl}`}> Katika for Business</a>
                                            </li>
                                            <li key={1}>
                                                <a href="#" className='disabled-link'> Get to Know Us</a>
                                                <ul className="dropdown-menu-item">
                                                    <li key={10}><a href={`${buyerUrl}/about-us`} >About Katika</a></li>
                                                    <li key={2}><a href={`${buyerUrl}/labs`} >Katika Labs</a></li>
                                                    <li key={3}><a href={`${buyerUrl}/partners`}>Partners</a></li>
                                                    <li key={11}><a href={`${communityUrl}`}>Katika Community</a></li>
                                                </ul>
                                            </li>
                                            <li key={4}>
                                                <Link to="#" className='disabled-link'> Let us help you </Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={5}><a href={`${sellerUrl}`}>List Your Business</a></li>
                                                    <li key={6}><a href={`${buyerUrl}/faq`} >Frequently Asked Questions</a></li>
                                                    <li key={15}>
                                                        <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                                                    </li>
                                                    <li key={10}><a href={`${buyerUrl}/get-help`} >Contact Us</a></li>
                                                </ul>
                                            </li>
                                            <li key={7}>
                                                <Link to="#" className='disabled-link'> Discover</Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={8}><a href={`${buyerUrl}/press`} > Press</a></li>
                                                    <li key={9}><a href="https://blog.katika.us/">Blog</a></li>
                                                </ul>
                                            </li>
                                            <li key={7}>
                                                <Link to="#" className='disabled-link'> Legal</Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={18}><a href={`${buyerUrl}/terms-of-service`} > Terms of Use</a></li>
                                                    <li key={19}><a href={`${buyerUrl}/privacy-policy`}>Privacy Policy</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="b-logout">
                                                    <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>
                                                </div>
                                            </li>

                                        </>
                                        :
                                        <>
                                            <li key={0}>
                                                <a href={`${buyerUrl}`}> Katika</a>
                                            </li>
                                            <li key={50}>
                                                <a href={`${sellerUrl}`}> Katika for Business</a>
                                            </li>
                                            <li key={1}>
                                                <a href="#" className='disabled-link'> Get to Know Us</a>
                                                <ul className="dropdown-menu-item">
                                                    <li key={10}><a href={`${buyerUrl}/about-us`} >About Katika</a></li>
                                                    <li key={2}><a href={`${buyerUrl}/labs`} >Katika Labs</a></li>
                                                    <li key={3}><a href={`${buyerUrl}/partners`}>Partners</a></li>
                                                    <li key={11}><a href={`${communityUrl}`}>Katika Community</a></li>
                                                </ul>
                                            </li>
                                            <li key={4}>
                                                <Link to="#" className='disabled-link'> Let us help you </Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={5}><a href={`${sellerUrl}`}>List Your Business</a></li>
                                                    <li key={6}><a href={`${buyerUrl}/faq`} >Frequently Asked Questions</a></li>
                                                    <li key={15}>
                                                        <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                                                    </li>
                                                    <li key={10}><a href={`${buyerUrl}/get-help`} >Contact Us</a></li>
                                                </ul>
                                            </li>
                                            <li key={7}>
                                                <Link to="#" className='disabled-link'> Discover</Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={8}><a href={`${buyerUrl}/press`} > Press</a></li>
                                                    <li key={9}><a href="https://blog.katika.us/">Blog</a></li>
                                                </ul>
                                            </li>
                                            <li key={7}>
                                                <Link to="#" className='disabled-link'> Legal</Link>
                                                <ul className="dropdown-menu-item">
                                                    <li key={18}><a href={`${buyerUrl}/terms-of-service`} > Terms of Use</a></li>
                                                    <li key={19}><a href={`${buyerUrl}/privacy-policy`}>Privacy Policy</a></li>
                                                </ul>
                                            </li>
                                            <li> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li>
                                        </>
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
