import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { RiCloseLine } from 'react-icons/ri'
import { Row, Col } from "react-bootstrap";
import img1 from '../../assets/images/l-1.jpg';
import img2 from '../../assets/images/l-2.jpg';
import img3 from '../../assets/images/l-3.jpg';
import img4 from '../../assets/images/l-4.jpg';
import img5 from '../../assets/images/l-5.jpg';
import img6 from '../../assets/images/l-6.jpg';
import PhotoIcon from '../../assets/images/icon_photo.png';

import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'



import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

class PhotoGallery extends React.Component {
    constructor() {
        super()
        this.state = {
            value: this.props ? this.props.image_click : 0,
            images: [],
            thumbnailsImg: [],
            business: "",
            count: 0,
            currentImage: this.props ? this.props.current : {
                title: '',
                date: '',
                user_image: '',
                fname: '',
                lname: '',
                friends: '',
                total_reviews: '',
            },
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        console.log(this.state.images[value], 'currentImage')
        this.setState({ value });
        if (this.state.images[value]) {
            this.setState({
                currentImage: this.state.images[value]
            });
        }

    }

    componentDidMount() {
        const imagess = [];
        this.props.images.map((slide, i) => {
            imagess.push(<img key={i + 1} src={slide.image} alt="Gallery" />,)
        });
        if (imagess) {
            this.setState({
                value: this.props.image_click,
                images: this.props.images,
                thumbnailsImg: imagess,
                business: this.props.business,
                count: this.props.images.length,
            });
            if (this.props.images[this.props.image_click]) {
                this.setState({
                    currentImage: this.props.images[this.props.image_click]
                });
            }
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        const imagess = [];
        nextProps.images.map((slide, i) => {
            imagess.push(<img key={i + 1} src={slide.image} alt="Gallery" />,)
        });
        if (imagess) {
            this.setState({
                value: nextProps.image_click,
                images: nextProps.images,
                thumbnailsImg: imagess,
                business: nextProps.business,
                count: nextProps.images.length,
            });

            if (nextProps.images[nextProps.image_click]) {
                this.setState({
                    currentImage: nextProps.images[nextProps.image_click]
                });
            }
        }

        console.log(this.state, 'aj')





        console.log(nextProps.images.length, 'nextProps')
        console.log(prevProps, 'prevProps')
        // this.onImage();
    }



    render() {
        console.log(this.props, 'props2')
        return (
            <div>
                <input
                    type="hidden"
                    value={this.props.image_click ? this.props.image_click : this.state.value}
                    onChange={e => this.onChange(parseInt(e.target.value || 0))}
                />

                <Row>
                    <Col lg={12} md={12}>
                        <div className="btn-box">
                            <button type="button" className="theme-btn close-btn border-0 border-0 mr-1" data-dismiss="modal">
                                Close <RiCloseLine />
                            </button>
                        </div>
                    </Col>
                    <Col lg={8} md={8}>
                        <div className="craousel-slider">
                            <Carousel
                                arrows
                                value={this.state.value}
                                onChange={this.onChange}
                                animationSpeed={0}
                            >
                                {this.state.images.map((slide, i) => {
                                    return (
                                        <img key={i} className="img-example" src={slide.image} alt="Gallery" />
                                    )
                                })}
                            </Carousel>

                        </div>
                    </Col>
                    <Col lg={4} md={4}>
                        <div className="" >
                            <div className="slider-content">


                                <div className="user-info-details">

                                    <div className="media-info display-flex">
                                        <div className="media-story">
                                            <ul>
                                                <li>
                                                    <p>
                                                        {this.state.currentImage.title}
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul >
                                                <li className="friend-count">
                                                    {this.state.currentImage.current_date}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul className="comments-list listing-gallery-p padding-top-10px">
                                        <li>
                                            <div className="comment listing-details">
                                                <div className="user-img">
                                                    <img className="avatar__img" alt="Comment"
                                                        src={this.state.business.business_logo} />
                                                </div>
                                                <div className="comment-body">
                                                    <div className="meta-data">
                                                        <span className="comment__author">
                                                            {`${this.state.business.business_name}`} </span>
                                                        <span className="comment__author">
                                                            {`${this.state.business.full_name}`} </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <p className="description">{this.state.business.description}</p>
                                            <p className="date">{`${this.state.business.date}`}</p>
                                        </li>
                                    </ul>
                                </div>

                            </div>


                            {/* <div className="slider-dots">
                                <Dots
                                    value={this.state.value}
                                    onChange={this.onChange}
                                    thumbnails={this.state.thumbnailsImg}
                                />
                            </div> */}
                        </div>
                    </Col>



                </Row>

            </div>
        );
    }
}

export default PhotoGallery;