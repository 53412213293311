import React, { Component } from 'react';
import Logo from "../Logo";
import SocialProfile from "../../other/account/SocialProfile";
import logosrc from '../../../assets/images/katika-logo.png'
import iosapp from '../../../assets/images/ios-app.png'
import googleapp from '../../../assets/images/google-app.png'

class FooterLeftWidget extends Component {
    state = {
        logo: logosrc,
        desc: 'Download the App',
    }
    render() {
        return (
            <>
                <div className="col-lg-4 col-md-4 col-sm-4 column-td-6">
                    <div className="footer-item">
                        <div className="logo">
                            <Logo url={this.state.logo} className="foot-logo" />
                           {/* <p className="footer__desc text-black">
                                {this.state.desc}
                            </p>
                            <ul className="inline-flex">
                                <li className="pr-10"> <a href="#"> <img  height="45px" src={iosapp} alt="ios-app" /> </a> </li>
                                <li className="pr-10"> <a href="#"> <img  height="45px" src={googleapp} alt="googleapp" /> </a> </li>
                            </ul>*/}
                            {/* <div className="inline-flex">
                                
                                <img  height="45px" src={googleapp} alt="ios-app" />
                            </div> */}
                            {/* <SocialProfile /> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterLeftWidget;