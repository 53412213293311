import React, { Component } from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import GeneralHeader from '../../components/webapp/common/GeneralHeader'
import Footer from "../../components/webapp/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import { Container, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Loader from 'react-loader';
import PhotoGallery from '../../components/webapp/PhotoGallery';



class ListingGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            location: '',
            isSignedUp: false,
            externalData: false,
            loaded: false,
            images: [],
            all: [],
            business: '',
            LoginModal: false,
            search_data: this.props.location.state

        }
        this.handler = this.handler.bind(this)
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
    }


    handler1(e) {
        var self = this;
        if (e) {
            const { id } = self.props.match.params;
            setTimeout(
                () => self.props.history.push({
                    pathname: `/add-photos/${id}`,
                    state: {
                        search: this.state.search_data ? this.state.search_data.search : '',
                        location: this.state.search_data ? this.state.search_data.location : ''
                    }
                }),
                4000
            );

        }
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }
    onHide() {
        this.setState({ LoginModal: false });
    }
    componentDidMount() {
        this.getBusinessImages();
    }

    getBusinessImages = () => {
        var self = this;
        const { id } = this.props.match.params;
        self.setState({
            externalData: false,
            loaded: false,
        })
        axios.post(ApiUrl + 'getBusinessImages', { business_slug: id }, {})
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        business_name: res.data.business_name,
                        business: res.data.business,
                        images: res.data.images,
                        externalData: true,
                        all: res.data.all,
                        loaded: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <main className="Mobile GalleryListing">
                <Loader loaded={this.state.loaded}></Loader>
                <GeneralHeader />
                {/* Header */}


                <div className="mobile-gallery">
                        {this.state.externalData ? <PhotoGallery current={this.state.all[0]} business={this.state.business} images={this.state.all} image_click={0} />
                        : " "}
                </div>





                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default ListingGallery;