import React, { Component } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import review from '../../assets/images/useful-2.png';
import smile from '../../assets/images/smile-2.png';
import happy from '../../assets/images/cool-2.png';
import review_1 from '../../assets/images/review-1.png';
import smile_1 from '../../assets/images/smile-1.png';
import happy_1 from '../../assets/images/happy-1.png';
import { ApiUrl } from '../../config';
import axios from 'axios';
import Rater from 'react-rater';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import { uToken } from '../../useToken';
import LoginBox from '../other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'
import PhotoGallery from './PhotoGallery';
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';

export default class RecentActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 1,
      pageNumber: 1,
      totalpage: "",
      reviews: [],
      externalData: false,
      loginTrue: false,
      currentPage: 1,
      HideShowMore: true,
      like: false,
    }
    this.sendRequest = this.sendRequest.bind(this);
    this.handler1 = this.handler1.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    this.setState({ LoginModal: false, GalleryModal: false });
  }
  handler1(e) {
    if (e) {
      this.setState({
        LoginModal: false,
      });

    }
    this.props.history.go('/')
    console.log(e, 'saaaaaaaaaaaaaaaaaa')
  }



  changeCoolNo = (id, activty) => {
    $('.cool-' + id).removeClass('none');
    $('.no-cool-' + id).addClass('none');
    $('.no-cool-' + id).removeClass('active');
    $('.cool-count-' + id).addClass('default');
    let count = parseInt($('.cool-count-' + id).text());
    $('.cool-count-' + id).text(count - 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  changeCool = (id, activty) => {
    $('.cool-' + id).addClass('none');
    $('.no-cool-' + id).removeClass('none');
    $('.no-cool-' + id).addClass('active');
    $('.cool-count-' + id).removeClass('default');
    let count = parseInt($('.cool-count-' + id).text());
    $('.cool-count-' + id).text(count + 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionCool', { 'review_cool': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  changeSmileNo = (id, activty) => {
    $('.smile-' + id).removeClass('none');
    $('.no-smile-' + id).addClass('none');
    $('.no-smile-' + id).removeClass('active');
    $('.smile-count-' + id).addClass('default');
    let count = parseInt($('.smile-count-' + id).text());
    $('.smile-count-' + id).text(count - 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  changeSmile = (id, activty) => {
    $('.smile-' + id).addClass('none');
    $('.no-smile-' + id).removeClass('none');
    $('.no-smile-' + id).addClass('active');
    $('.smile-count-' + id).removeClass('default');
    let count = parseInt($('.smile-count-' + id).text());
    $('.smile-count-' + id).text(count + 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionFunny', { 'review_funny': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  changeUsefullNo = (id, activty) => {
    $('.usefull-' + id).removeClass('none');
    $('.no-usefull-' + id).addClass('none');
    $('.no-usefull-' + id).removeClass('active');
    $('.useful-count-' + id).addClass('default');
    let count = parseInt($('.useful-count-' + id).text());
    $('.useful-count-' + id).text(count - 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  changeUsefull = (id, activty) => {
    $('.usefull-' + id).addClass('none');
    $('.no-usefull-' + id).removeClass('none');
    $('.no-usefull-' + id).addClass('active');
    $('.useful-count-' + id).removeClass('default');
    let count = parseInt($('.useful-count-' + id).text());
    $('.useful-count-' + id).text(count + 1);

    axios.post(ApiUrl + 'addBusinessReviewReactionUseful', { 'review_useful': activty, 'review_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // console.log(res)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  changeActivityUnlike = (id, activty) => {
    $('.activity-button-count-' + id).addClass('default');
    $('.activity-button-like-' + id).removeClass('none');
    $('.activity-button-unlike-' + id).addClass('none');
    let count = parseInt($('.activity-button-count-' + id).text());
    $('.activity-button-count-' + id).text(count - 1);
    axios.post(ApiUrl + 'addBusinessReviewImageReaction', { 'image_like': activty, 'review_image_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeActivity = (id, activty) => {
    var self = this;
    $('.activity-button-like-' + id).addClass('none');
    $('.activity-button-unlike-' + id).removeClass('none');
    $('.activity-button-count-' + id).removeClass('default');

    let count = parseInt($('.activity-button-count-' + id).text());
    $('.activity-button-count-' + id).text(count + 1);
    axios.post(ApiUrl + 'addBusinessReviewImageReaction', { 'image_like': activty, 'review_image_id': id }, {
      headers: {
        'Authorization': `Bearer ${uToken()}`
      }
    })
      .then(function (res) {
        // self.getBusinessList();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendRequest = (count) => {
    this.setState({ currentPage: count })
    this.getBusinessList(count);
  }

  componentDidMount() {

    $(document).on('click', '.delete-account-info', function (e) {
      $('body').addClass('modal-open').css({ paddingRight: '17px' });
      $(".account-delete-modal").addClass('show')
      e.preventDefault();
    })
    $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
      $('body').removeClass('modal-open').css({ paddingRight: '0' });
      $(".account-delete-modal").removeClass('show')
      e.preventDefault();
    })
    this.getBusinessList();
  }

  ShowMoreText(id) {
    $('.length-limit-' + id).css("display", "none");
    $('.hide-more-' + id).css("display", "none");
    $('.r-length-limit-' + id).css("display", "block");
  }

  getBusinessList = (count) => {
    var loc = JSON.parse(localStorage.getItem('location'));
    let data = {};
    if (loc != null) {
      data = {
        'city': loc.city,
        'region': loc.region,
        'country': loc.country
      };
    } else {


      data = {
        'city': 'Philadelphia',
        'region': 'PA',
        'country': 'USA'
      };
    }
    var self = this;
    self.setState({
      externalData: false,
    })
    let pg = 1;
    if (count != undefined && count != '') {
      pg = count;
    }

    // console.log(data)
    axios.post(ApiUrl + `getRecentReviews?page=${pg}&limit=9`, data,
      {
        headers: {
          'Authorization': `Bearer ${uToken()}`
        }
      })
      .then(function (res) {
        // console.log(data)
        if (res.data.success == true) {
          if (res.data.total_pages < 1) {
            self.setState({
              HideShowMore: false,
              externalData: true,
            })
          }
          self.setState({
            externalData: true,
          })

          self.setState(prevState => ({
            reviews: [...prevState.reviews, res.data.lists],
          }))

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <>
        {this.state.externalData ?
          <div className="cities recent recentReviewSummary" style={{ paddingTop: "30px !important" }}>
            <div className="container">
              <h2>Most Recent Customer Reviews</h2>
              <div className="list1">
                <div className="row mt-5">
                  {this.state.reviews.map((list, key) => {
                    return (
                      list.map((item, i) => {
                        return (
                          (item.total_images >= 3 && item.type == 'photo') ?
                            <div key={i + '-' + key + '-' + item.full_name} className="col-lg-4">
                              <div className='card-item'>
                                <div className="media m-round">
                                  <div className="media-left">
                                    {
                                      item.is_business ?
                                        <Link to={{
                                          pathname: `/biz/${item.user_business_slug}`,
                                        }}>
                                          <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                        </Link>
                                        :

                                        <Link to={{
                                          pathname: `/user-profile/${item.user_id}`,
                                        }}>
                                          <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                        </Link>

                                    }
                                  </div>
                                  <div className="media-body m-body">
                                    <h4 className="media-heading m-h4">
                                      {
                                        item.is_business ?
                                          <Link to={{
                                            pathname: `/biz/${item.user_business_slug}`
                                          }}> {item.full_name}
                                          </Link>
                                          :

                                          <Link to={{
                                            pathname: `/user-profile/${item.user_id}`,
                                          }}> {item.full_name}
                                          </Link>
                                      }
                                    </h4>
                                    <p className="m-p">Added {item.total_images} photos</p>
                                  </div>
                                </div>
                                <div className="media-body item-heading">
                                  <h4 className="media-heading m-h4">
                                    <Link to={{
                                      pathname: `/biz/${item.business_slug}`,
                                    }}> {item.business_name}
                                    </Link>
                                  </h4>
                                </div>
                                <div className="card-third">
                                  <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                    currentImg: item.business_review_image[0],
                                    business: item,
                                    all_images: item.business_review_image,
                                    image_click: 0,
                                    click: true

                                  })}>
                                    <div className="card-image">
                                      <img width="100%" height="100%" src={item.business_review_image[0].image} className="card__img img-thumbnail img-thumbnail" alt="Place" />
                                    </div>
                                  </div>


                                  {(!uToken()) ?
                                    <div className="like_class">
                                      <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-like-${item.business_review_image[0].id}`}> <RiThumbUpLine /> Like</Button>
                                      <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-unlike-${item.business_review_image[0].id} none`}> <RiThumbUpFill /> Like</Button>
                                    </div>
                                    :
                                    <div className="like_class">
                                      {item.business_review_image[0].liked == 1 ?
                                        <>
                                          <p className={`activity-button-count-${item.business_review_image[0].id}`}> {item.business_review_image[0].total_likes} </p>
                                          <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)}
                                            className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id}`}>
                                            <RiThumbUpFill /><span className="ml-20"></span>
                                            Like</Button>
                                          <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[0].id} none`}>
                                            <RiThumbUpLine /><span className="ml-20"></span>
                                            Like</Button>
                                        </>
                                        :
                                        <>
                                          <p className={`default activity-button-count-${item.business_review_image[0].id}`}>
                                            {item.business_review_image[0].total_likes} </p>
                                          <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[0].id}`}>
                                            <RiThumbUpLine /><span className="ml-20"></span>
                                            Like</Button>

                                          <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id} none`}>
                                            <RiThumbUpFill /> <span className="ml-20"></span>
                                            Like</Button>

                                        </>
                                      }

                                    </div>
                                  }
                                </div>


                                <div className="card-content-wrap two-part">
                                  <div className="card-content">
                                    <div className="part">
                                      <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                        currentImg: item.business_review_image[1],
                                        business: item,
                                        all_images: item.business_review_image,
                                        image_click: 1,
                                        click: true

                                      })}>
                                        <img width="100%" height="100%" src={item.business_review_image[1].image} className="card__img img-thumbnail" alt="Place" />
                                      </div>
                                      {(!uToken()) ?
                                        <div className="like_class">
                                          <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id}`}> <RiThumbUpLine /> Like</Button>
                                          <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-unlike-${item.business_review_image[1].id} none`}> <RiThumbUpFill /> Like</Button>
                                        </div>
                                        :
                                        <div className="like_class">
                                          {item.business_review_image[1].liked == 1 ?
                                            <>
                                              <p className={`activity-button-count-${item.business_review_image[1].id}`}> {item.business_review_image[1].total_likes} </p>
                                              <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[1].id, 0)}
                                                className={`btn btn-link activity-button-unlike-${item.business_review_image[1].id}`}>
                                                <RiThumbUpFill /><span className="ml-20"></span>
                                                Like</Button>
                                              <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[1].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id} none`}>
                                                <RiThumbUpLine /><span className="ml-20"></span>
                                                Like</Button>
                                            </>
                                            :
                                            <>
                                              <p className={`default activity-button-count-${item.business_review_image[1].id}`}>
                                                {item.business_review_image[1].total_likes} </p>
                                              <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[1].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id}`}>
                                                <RiThumbUpLine /><span className="ml-20"></span>
                                                Like</Button>

                                              <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[1].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[1].id} none`}>
                                                <RiThumbUpFill /> <span className="ml-20"></span>
                                                Like</Button>

                                            </>
                                          }

                                        </div>
                                      }
                                    </div>
                                    <div className="part">
                                      <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                        currentImg: item.business_review_image[2],
                                        business: item,
                                        all_images: item.business_review_image,
                                        image_click: 2,
                                        click: true

                                      })}>
                                        <img width="100%" height="100%" src={item.business_review_image[2].image} className="card__img img-thumbnail" alt="Place" />
                                      </div>
                                      {(!uToken()) ?
                                        <div className="like_class">
                                          <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-like-${item.business_review_image[2].id}`}> <RiThumbUpLine /> Like</Button>
                                          <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-unlike-${item.business_review_image[2].id} none`}> <RiThumbUpFill /> Like</Button>
                                        </div>
                                        :
                                        <div className="like_class">
                                          {item.business_review_image[2].liked == 1 ?
                                            <>
                                              <p className={`activity-button-count-${item.business_review_image[2].id}`}> {item.business_review_image[2].total_likes} </p>
                                              <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[2].id, 0)}
                                                className={`btn btn-link activity-button-unlike-${item.business_review_image[2].id}`}>
                                                <RiThumbUpFill /><span className="ml-20"></span>
                                                Like</Button>
                                              <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[2].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[2].id} none`}>
                                                <RiThumbUpLine /><span className="ml-20"></span>
                                                Like</Button>
                                            </>
                                            :
                                            <>
                                              <p className={`default activity-button-count-${item.business_review_image[2].id}`}>
                                                {item.business_review_image[2].total_likes} </p>
                                              <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[2].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[2].id}`}>
                                                <RiThumbUpLine /><span className="ml-20"></span>
                                                Like</Button>

                                              <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[2].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[2].id} none`}>
                                                <RiThumbUpFill /> <span className="ml-20"></span>
                                                Like</Button>

                                            </>
                                          }

                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className="show_photo">
                                    <h4 className="media-heading m-h4">
                                      <Link to={{
                                        pathname: `/listing-gallery/${item.business_slug}`,
                                      }}> Show all photo</Link>
                                    </h4>
                                  </div>
                                </div>
                              </div>

                            </div>

                            : (item.total_images >= 2 && item.total_images < 3 && item.type == 'photo') ?
                              <div key={i + '-' + key + '-' + item.full_name} className="col-lg-4 column-td-6">
                                <div className="card-item">
                                  <div className="media m-round">
                                    <div className="media-left">

                                      {
                                        item.is_business ?
                                          <Link to={{
                                            pathname: `/biz/${item.user_business_slug}`,
                                          }}>
                                            <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                          </Link>
                                          :
                                          <Link to={{
                                            pathname: `/user-profile/${item.user_id}`,
                                          }}>
                                            <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                          </Link>
                                      }
                                    </div>
                                    <div className="media-body m-body">
                                      <h4 className="media-heading m-h4">
                                        {
                                          item.is_business ?
                                            <Link to={{
                                              pathname: `/biz/${item.user_business_slug}`,
                                            }}> {item.full_name}
                                            </Link>
                                            :
                                            <Link to={{
                                              pathname: `/user-profile/${item.user_id}`,
                                            }}> {item.full_name}
                                            </Link>
                                        }
                                      </h4>
                                      <p className="m-p">Added {item.total_images} photos
                                      </p>
                                    </div>
                                  </div>
                                  <div className="media-body item-heading">
                                    <h4 className="media-heading m-h4">
                                      <Link to={{
                                        pathname: `/biz/${item.business_slug}`,
                                      }} > {item.business_name}
                                      </Link>
                                    </h4>
                                  </div>
                                  <div className="card-content-wrap two-part">
                                    <div className="card-content">
                                      <div className="part">
                                        <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                          currentImg: item.business_review_image[0],
                                          business: item,
                                          all_images: item.business_review_image,
                                          image_click: 0,
                                          click: true

                                        })}>
                                          <img width="100%" height="100%" src={item.business_review_image[0].image} className="card__img img-thumbnail" alt="Place" />
                                        </div>
                                        {(!uToken()) ?
                                          <div className="like_class">
                                            <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`btn btn-link default activity-button-like-${item.business_review_image[0].id}`}> <RiThumbUpLine /> Like</Button>
                                            <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`btn btn-link default activity-button-unlike-${item.business_review_image[0].id} none`}> <RiThumbUpFill /> Like</Button>
                                          </div>
                                          :
                                          <div className="like_class">
                                            {item.business_review_image[0].liked == 1 ?
                                              <>
                                                <p className={`activity-button-count-${item.business_review_image[0].id}`}> {item.business_review_image[0].total_likes} </p>
                                                <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)}
                                                  className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id}`}>
                                                  <RiThumbUpFill /><span className="ml-20"></span>
                                                  Like</Button>
                                                <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`btn btn-link default activity-button-like-${item.business_review_image[0].id} none`}>
                                                  <RiThumbUpLine /><span className="ml-20"></span>
                                                  Like</Button>
                                              </>
                                              :
                                              <>
                                                <p className={`default activity-button-count-${item.business_review_image[0].id}`}>
                                                  {item.business_review_image[0].total_likes} </p>
                                                <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`btn btn-link default activity-button-like-${item.business_review_image[0].id}`}>
                                                  <RiThumbUpLine /><span className="ml-20"></span>
                                                  Like</Button>

                                                <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id} none`}>
                                                  <RiThumbUpFill /> <span className="ml-20"></span>
                                                  Like</Button>

                                              </>
                                            }

                                          </div>
                                        }
                                      </div>
                                      <div className="part">
                                        <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                          currentImg: item.business_review_image[1],
                                          business: item,
                                          all_images: item.business_review_image,
                                          image_click: 1,
                                          click: true

                                        })}>
                                          <img width="100%" height="100%" src={item.business_review_image[1].image} className="card__img img-thumbnail" alt="Place" />
                                        </div>
                                        {(!uToken()) ?
                                          <div className="like_class">
                                            <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id}`}> <RiThumbUpLine /> Like</Button>
                                            <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`default btn btn-link activity-button-unlike-${item.business_review_image[1].id} none`}> <RiThumbUpFill /> Like</Button>
                                          </div>
                                          :
                                          <div className="like_class">
                                            {item.business_review_image[1].liked == 1 ?
                                              <>
                                                <p className={`activity-button-count-${item.business_review_image[1].id}`}> {item.business_review_image[1].total_likes} </p>
                                                <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[1].id, 0)}
                                                  className={`btn btn-link activity-button-unlike-${item.business_review_image[1].id}`}>
                                                  <RiThumbUpFill /><span className="ml-20"></span>
                                                  Like</Button>
                                                <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[1].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id} none`}>
                                                  <RiThumbUpLine /><span className="ml-20"></span>
                                                  Like</Button>
                                              </>
                                              :
                                              <>
                                                <p className={`default activity-button-count-${item.business_review_image[1].id}`}>
                                                  {item.business_review_image[1].total_likes} </p>
                                                <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[1].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[1].id}`}>
                                                  <RiThumbUpLine /><span className="ml-20"></span>
                                                  Like</Button>

                                                <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[1].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[1].id} none`}>
                                                  <RiThumbUpFill /> <span className="ml-20"></span>
                                                  Like</Button>

                                              </>
                                            }

                                          </div>
                                        }
                                      </div>
                                    </div>
                                    <div className="show_photo">
                                      <h4 className="media-heading m-h4">
                                        <Link to={{
                                          pathname: `/listing-gallery/${item.business_slug}`,
                                        }}> Show all photo
                                        </Link>
                                      </h4>
                                    </div>

                                  </div>
                                </div>
                              </div>

                              : (item.total_images > 0 && item.total_images < 2 && item.type == 'photo') ?
                                <div key={i + '-' + key + '-' + item.full_name} className="col-md-4" >
                                  <div className="card-item col-last first-col single-img">

                                    <div className="media m-round last-col">
                                      <div className="media-left">

                                        {
                                          item.is_business ?
                                            <Link to={{
                                              pathname: `/biz/${item.user_business_slug}`,
                                            }}>
                                              <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                            </Link>
                                            :
                                            <Link to={{
                                              pathname: `/user-profile/${item.user_id}`,
                                            }}>
                                              <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                            </Link>
                                        }

                                      </div>
                                      <div className="media-body m-body">
                                        <h4 className="media-heading m-h4">
                                          {
                                            item.is_business ?
                                              <Link to={{
                                                athname: `/biz/${item.user_business_slug}`,
                                              }}> {item.full_name}</Link>
                                              :
                                              <Link to={{
                                                pathname: `/user-profile/${item.user_id}`,
                                              }}> {item.full_name}</Link>
                                          }
                                        </h4>
                                        <p className="m-p">Added {item.total_images} photos </p>
                                        {/* <p className="m-p">Added 8 photos</p> */}
                                      </div>

                                    </div>
                                    <div className="card-image-wrap delete-account-info" onClick={() => this.setState({
                                      currentImg: item.business_review_image[0],
                                      business: item,
                                      all_images: item.business_review_image,
                                      image_click: 0,
                                      click: true

                                    })}>
                                      <div className="card-image">
                                        <img width="100%" height="100%" src={item.business_review_image[0].image} className="card__img img-thumbnail" alt="Place" />
                                      </div>
                                    </div>
                                    <div className="media-body item-heading b-heading">
                                      <h4 className="media-heading m-h4">
                                        <Link to={{
                                          pathname: `/biz/${item.business_slug}`,
                                        }} > {item.business_name} </Link>
                                      </h4>
                                    </div>
                                    {(!uToken()) ?
                                      <div className="like_class">
                                        <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`btn btn-link default activity-button-like-${item.business_review_image[0].id}`}> <RiThumbUpLine /> Like</Button>
                                        <Button variant="none" onClick={() => this.setState({ LoginModal: true })} className={`btn btn-link default activity-button-unlike-${item.business_review_image[0].id} none`}> <RiThumbUpFill /> Like</Button>
                                      </div>
                                      :
                                      <div className="like_class">
                                        {item.business_review_image[0].liked == 1 ?
                                          <>
                                            <p className={`activity-button-count-${item.business_review_image[0].id}`}> {item.business_review_image[0].total_likes} </p>
                                            <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)}
                                              className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id}`}>
                                              <RiThumbUpFill /><span className="ml-20"></span>
                                              Like</Button>
                                            <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`default btn btn-link activity-button-like-${item.business_review_image[0].id} none`}>
                                              <RiThumbUpLine /><span className="ml-20"></span>
                                              Like</Button>
                                          </>
                                          :
                                          <>
                                            <p className={`default activity-button-count-${item.business_review_image[0].id}`}>
                                              {item.business_review_image[0].total_likes} </p>
                                            <Button variant="none" onClick={() => this.changeActivity(item.business_review_image[0].id, 1)} className={`btn btn-link default activity-button-like-${item.business_review_image[0].id}`}>
                                              <RiThumbUpLine /><span className="ml-20"></span>
                                              Like</Button>

                                            <Button variant="none" onClick={() => this.changeActivityUnlike(item.business_review_image[0].id, 0)} className={`btn btn-link activity-button-unlike-${item.business_review_image[0].id} none`}>
                                              <RiThumbUpFill /> <span className="ml-20"></span>
                                              Like</Button>

                                          </>
                                        }

                                      </div>
                                    }
                                    {/* <div className="rating-rating">
                                      <Rater total={5} rating={item.rating} interactive={false} />
                                    </div>
                                    {
                                      item.description.length > 170 ?
                                        <>
                                          <p className={`descs length-limit-${item.id}`}> {item.description.substring(0, 169) + "..."} </p>
                                          <button className={`btn btn-link hide-more-${item.id}`} onClick={() => this.ShowMoreText(item.id)}>Read more</button>
                                          <p className={`display-none descs r-length-limit-${item.id}`}> {item.description} </p>
                                        </>
                                        :
                                        <p className={`descs`}> {item.description} </p>
                                    } */}
                                    {/* <div className="user_review">
                                      <a href="">
                                        <img width="100%" height="100%" src={review} className="card__img img-thumbnail" alt="review" />
                                      </a>
                                      <a href="">
                                        <img width="100%" height="100%" src={smile} className="card__img img-thumbnail" alt="smile" />
                                      </a>
                                      <a href="">
                                        <img width="100%" height="100%" src={happy} className="card__img img-thumbnail" alt="happy" />
                                      </a>
                                    </div> */}
                                  </div>
                                </div>
                                : (item.type == 'review') ?
                                  <div key={i + '-' + key + '-' + item.full_name} className="col-md-4" >
                                    <div className="card-item col-last first-col">
                                      <div className="ReviewsWrote">
                                        <div className="media m-round last-col">
                                          <div className="media-left">
                                            {
                                              item.is_business ?
                                                <Link to={{
                                                  pathname: `/biz/${item.user_business_slug}`,
                                                }}>
                                                  <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                                </Link>
                                                :
                                                <Link to={{
                                                  pathname: `/user-profile/${item.user_id}`,
                                                }}>
                                                  <img width="100%" height="100%" className="media-object" src={item.user_image} alt="author-img" />
                                                </Link>
                                            }
                                          </div>
                                          <div className="media-body m-body">
                                            <h4 className="media-heading m-h4">

                                              {
                                                item.is_business ?
                                                  <Link to={{
                                                    pathname: `/biz/${item.user_business_slug}`,
                                                  }}> {item.full_name}</Link>
                                                  :
                                                  <Link to={{
                                                    pathname: `/user-profile/${item.user_id}`,
                                                  }}> {item.full_name}</Link>
                                              }
                                            </h4>
                                            <p className="m-p">Wrote a review	</p>
                                          </div>

                                        </div>
                                        <Link to={{
                                          pathname: `/biz/${item.business_slug}`,
                                        }} className="card-image-wrap">
                                          <div className="card-image">
                                            {
                                              item.business_review_image.length > 0 ?
                                                <img width="100%" height="100%" src={item.business_review_image[0].image} className="card__img img-thumbnail" alt="Place" />
                                                :
                                                <img width="100%" height="100%" src={item.business_logo} className="card__img img-thumbnail" alt="Place" />
                                            }

                                          </div>
                                        </Link>
                                        <div className="media-body item-heading b-heading">
                                          <h4 className="media-heading m-h4">
                                            <Link to={{
                                              pathname: `/biz/${item.business_slug}`,
                                            }} > {item.business_name} </Link>
                                          </h4>
                                        </div>
                                        <div className="rating-rating  rating-detail landing">
                                          {/* <Rater total={5} rating={item.rating} interactive={false} /> */}
                                          <Rating
                                            placeholderRating={item.rating}
                                            readonly={true}
                                            stop={5}
                                            emptySymbol={<img width="100%" height="100%" src={Empty} className="icon" />}
                                            placeholderSymbol={<img width="100%" height="100%" src={Fill} className="icon" />}
                                            fullSymbol={<img width="100%" height="100%" src={Fill} className="icon" />}
                                          />
                                        </div>
                                        {
                                          item.description.length > 190 ?
                                            <>
                                              <p className={`descs length-limit-${item.id}`}> {item.description.substring(0, 189) + "..."} </p>
                                              <button className={`btn btn-link hide-more-${item.id}`} onClick={() => this.ShowMoreText(item.id)}>Continue reading</button>
                                              <p className={`display-none descs r-length-limit-${item.id}`}> {item.description} </p>
                                            </>
                                            :
                                            <p className={`descs`}> {item.description} </p>

                                        }
                                      </div>
                                      {(!uToken()) ?
                                        <div className="user_review">
                                          <Button variant="none" title="Usefull"
                                            onClick={() => this.setState({ LoginModal: true })}
                                            className={`btn btn-link card__img img-thumbnail default`}
                                          >
                                            <img width="100%" height="100%" src={review} className="card__img img-thumbnail" alt="review" />
                                          </Button>


                                          <Button variant="none" title="Smile"
                                            onClick={() => this.setState({ LoginModal: true })}
                                            className={`btn btn-link card__img img-thumbnail default`}
                                          >
                                            <img width="100%" height="100%" src={smile} className="card__img img-thumbnail" alt="smile" />
                                          </Button>

                                          <Button variant="none" title="Cool"
                                            onClick={() => this.setState({ LoginModal: true })}
                                            className={`btn btn-link card__img img-thumbnail default`}
                                          >
                                            <img width="100%" height="100%" src={happy} className="card__img img-thumbnail" alt="happy" />
                                          </Button>

                                        </div>
                                        :
                                        <div className="user_review">

                                          {item.review_useful == 1 ?

                                            <div className="use">

                                              <p className={`useful`}>
                                                <span className={`CountNumber useful-count-${item.id}`} >{item.totalBusinessReviewUseful}</span> </p>
                                              <Button variant="none" title="Usefull"
                                                onClick={() => this.changeUsefull(item.id, 1)}
                                                className={`btn btn-link card__img img-thumbnail none usefull-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={review} className="card__img img-thumbnail" alt="review" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Usefull"
                                                onClick={() => this.changeUsefullNo(item.id, 0)}
                                                className={`btn btn-link card__img img-thumbnail active default  no-usefull-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={review_1} className="card__img img-thumbnail" alt="review" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                            :

                                            <div className="use">
                                              <p className={`useful`}>
                                                <span className={`CountNumber default useful-count-${item.id}`} >{item.totalBusinessReviewUseful}</span> </p>

                                              <Button variant="none" title="Usefull"
                                                onClick={() => this.changeUsefull(item.id, 1)}
                                                className={`btn btn-link default card__img img-thumbnail usefull-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={review} className="card__img img-thumbnail" alt="review" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Usefull"
                                                onClick={() => this.changeUsefullNo(item.id, 0)}
                                                className={`btn btn-link card__img img-thumbnail none no-usefull-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={review_1} className="card__img img-thumbnail" alt="review" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                          }

                                          {/* ////////////////////// */}

                                          {item.review_funny == 1 ?

                                            <div className="smile">
                                              <p className={`smile-p`}>
                                                <span className={`CountNumber smile-count-${item.id}`} >{item.totalBusinessReviewFunny}</span> </p>

                                              <Button variant="none" title="Smile"
                                                onClick={() => this.changeSmile(item.id, 1)}
                                                className={`btn btn-link none card__img img-thumbnail smile-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={smile} className="card__img img-thumbnail" alt="smile" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Smile"
                                                onClick={() => this.changeSmileNo(item.id, 0)}
                                                className={`btn btn-link default card__img img-thumbnail active no-smile-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={smile_1} className="card__img img-thumbnail" alt="smile" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                            :

                                            <div className="smile">

                                              <p className={`smile-p`}>
                                                <span className={`CountNumber default smile-count-${item.id}`} >{item.totalBusinessReviewFunny}</span> </p>

                                              <Button variant="none" title="Smile"
                                                onClick={() => this.changeSmile(item.id, 1)}
                                                className={`btn btn-link default card__img img-thumbnail smile-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={smile} className="card__img img-thumbnail" alt="smile" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Smile"
                                                onClick={() => this.changeSmileNo(item.id, 0)}
                                                className={`btn btn-link card__img img-thumbnail none no-smile-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={smile_1} className="card__img img-thumbnail" alt="smile" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                          }


                                          {/* ////////////////////////////// */}

                                          {item.review_cool == 1 ?

                                            <div className="cool">
                                              <p className={`cool-p`}>
                                                <span className={`CountNumber cool-count-${item.id}`} >{item.totalBusinessReviewCool}</span> </p>
                                              <Button variant="none" title="Cool"
                                                onClick={() => this.changeCool(item.id, 1)}
                                                className={`btn btn-link none card__img img-thumbnail cool-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={happy} className="card__img img-thumbnail" alt="cool" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Cool"
                                                onClick={() => this.changeCoolNo(item.id, 0)}
                                                className={`btn btn-link default card__img img-thumbnail active no-cool-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={happy_1} className="card__img img-thumbnail" alt="cool" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                            :

                                            <div className="cool">

                                              <p className={`cool-p`}>
                                                <span className={`CountNumber default cool-count-${item.id}`} >{item.totalBusinessReviewCool}</span> </p>

                                              <Button variant="none" title="Cool"
                                                onClick={() => this.changeCool(item.id, 1)}
                                                className={`btn btn-link default card__img img-thumbnail cool-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={happy} className="card__img img-thumbnail" alt="cool" />
                                                <span className="ml-20"></span>
                                              </Button>
                                              <Button variant="none" title="Cool"
                                                onClick={() => this.changeCoolNo(item.id, 0)}
                                                className={`btn btn-link card__img img-thumbnail none no-cool-${item.id}`}
                                              >
                                                <img width="100%" height="100%" src={happy_1} className="card__img img-thumbnail" alt="cool" />
                                                <span className="ml-20"></span>
                                              </Button>
                                            </div>
                                          }




                                          {/* <a href="">
                                            <img width="100%" height="100%" src={smile} className="card__img img-thumbnail" alt="smile" />
                                          </a> */}
                                          {/* <a href="">
                                            <img width="100%" height="100%" src={happy} className="card__img img-thumbnail" alt="happy" />
                                          </a> */}
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  : ""


                        )
                      })
                    )
                  })}
                </div>
              </div>


              <div className="modal-form text-center">
                <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                  <div className="modal-bg"></div>
                  <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content p-4">
                      <div className="modal-top border-0 mb-4 p-0">
                        <div className="alert-content">
                          {this.state.click ? <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              {/* <Modal show={this.state.GalleryModal} onHide={this.onHide} size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} />
                </Modal.Body>
              </Modal> */}


              <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
              >
                <Modal.Header closeButton>
                  <Modal.Title>  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <h5>Login required to perform this action </h5> */}
                  <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                </Modal.Body>
              </Modal>

              {
                this.state.HideShowMore ?
                  <button onClick={() => this.sendRequest(this.state.currentPage + 1)} className="remove-btn-bg border-0 mr-2" >
                    <span className=""><IoIosArrowDown /> Show more activity</span>
                  </button>
                  : ""
              }
            </div>
          </div>
          : ""
        }
      </>
    )
  }
}
