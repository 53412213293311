import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Overview from '../../../assets/images/overview.svg';
import Friends from '../../../assets/images/friends.svg';
import Reviews from '../../../assets/images/reviews.svg';
import Messages from '../../../assets/images/message.png';
import Bookmarks from '../../../assets/images/bookmark.svg';
import CheckIns from '../../../assets/images/check-in.svg';
import Following from '../../../assets/images/following.png';
import Followers from '../../../assets/images/followers.png';
import Photo from "../../../assets/images/gallery.svg"

import Newsletters from '../../../assets/images/newsletter.svg';
import Flag from '../../../assets/images/flag.png';
import Block from '../../../assets/images/block.png';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import { ApiUrl } from '../../../config';
import { uInfo, uToken } from '../../../useToken';
import Modal from 'react-bootstrap/Modal'
import LoginBox from '../../other/account/LoginBox';
import { ToastContainer, toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';



class Directory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externaData: false,
            menus: [
                {
                    icon: Overview,
                    title: 'Profile Overview',
                    url: '/user-profile/' + props.userID
                },
                {
                    icon: Friends,
                    title: 'Friends',
                    url: '/user-friends/' + props.userID
                },
                {
                    icon: Reviews,
                    title: 'Reviews',
                    url: '/user-reviews/' + props.userID
                },

                {
                    icon: Photo,
                    title: 'Photos',
                    url: '/user-photo/' + props.userID
                },

                {
                    icon: Bookmarks,
                    title: 'Bookmarks',
                    url: '/user-bookmarks/' + props.userID
                },
                {
                    icon: CheckIns,
                    title: 'Check-Ins',
                    url: '/user-check-in/' + props.userID
                },

                // {
                //     icon: Following,
                //     title: 'Following',
                //     url: '/buyer-following'
                // },
                // {
                //     icon: Followers,
                //     title: 'Followers',
                //     url: '/buyer-followers'
                // },
                // {
                //     icon: Newsletters,
                //     title: 'Newsletters',
                //     url: '/buyer-newsletters/'+props.userID
                // },
                // {
                //     icon: Security,
                //     title: 'Security',
                //     url: 'buyer-security'
                // }

            ],
            BottomMenu: [
                {
                    icon: Flag,
                    title: 'Report this profile',
                    url: '/buyer-profile'
                },
                {
                    icon: Block,
                    title: 'Block Sally L.',
                    url: '/buyer-profile'
                },
            ]
        }
        this.onHide = this.onHide.bind(this);
        this.handler1 = this.handler1.bind(this);
        this.ReportProfile = this.ReportProfile.bind(this);
    }

    componentDidMount() {
        this.getProfile();
    }

    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            })
            if (this.state.type == '1') {
                this.ReportProfile();
            } else {
                this.setState({ RequestModal: true, LoginModal: false });
            }
        }
    }

    onHide() {
        this.setState({ RequestModal: false, LoginModal: false });
    }


    ReportProfile(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.to_user = this.props.userID;
            axios.post(ApiUrl + 'addReportProfile', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        window.location.reload();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    BlockUser = (block) => {
        var self = this;
        axios.post(ApiUrl + 'addBlockFriend', { 'to_user': self.props.userID, 'block': block }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    window.location.reload();

                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getProfile = () => {
        var self = this;
        const id = this.props.userID;
        axios.post(ApiUrl + 'getUserProfile', { user_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        user: res.data.user,
                        externaData: true,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        const id = this.props.userID;
        return (
            <>

                {this.state.externaData ?
                    <div className="sidebar section-bg mt-2 mb-4 BuyerProfile">
                        <div className="sidebar-widget">
                            <div className="author-bio text-align-center">
                            <div className="d-flex align-items-center">
                                <div className="author-inner-bio pb-0">
                                     {/* <h4 className="author__title pb-0 pl-1 ">Jason’s Profile</h4>  */}
                                </div>
                            </div>
                        </div>
                            <div className="section-block-2 mb-3"></div>
                            <div className="info-list">
                                <ul className="list-items">
                                    {this.state.menus.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={item.url} className={(window.location.pathname == item.url) ? 'active' : ''}>
                                                    <span className="dashboard-icon"><img src={item.icon} alt="Dashboard Icon" /></span>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                                {(uToken()) ?
                                    <ul className="BottomMenu">
                                        {id == uInfo().id ? "" :
                                            <>
                                                <li key={0}>
                                                    <Button className="remove-btn-bg  btn-link"
                                                        disabled={this.state.user.report == 1 ? true : false}
                                                        onClick={() => this.setState({ RequestModal: true })} >
                                                        <span className="dashboard-icon"><img src={Flag} alt="Dashboard Icon" /></span>
                                                    Report this profile
                                                </Button>
                                                </li>
                                                <li key={1}>
                                                    {
                                                        this.state.user.block == 0
                                                            ?
                                                            <Button className="remove-btn-bg  btn-link" onClick={() => this.BlockUser(1)}>
                                                                <span className="dashboard-icon"><img src={Block} alt="Dashboard Icon" /></span>
                                                    Block {this.state.user.fname} {this.state.user.lname ? this.state.user.lname : ""}
                                                            </Button>

                                                            :
                                                            <Button className="remove-btn-bg  btn-link" onClick={() => this.BlockUser(0)}>
                                                                <span className="dashboard-icon"><img src={Block} alt="Dashboard Icon" /></span>
                                                            Unblock {this.state.user.fname} {this.state.user.lname ? this.state.user.lname : ""}
                                                            </Button>
                                                    }
                                                </li>
                                            </>
                                        }
                                    </ul>
                                    :
                                    <ul className="BottomMenu">
                                        <li key={0}>
                                            <Button className="remove-btn-bg  btn-link" onClick={() => this.setState({ LoginModal: true, type: '1' })} >
                                                <span className="dashboard-icon"><img src={Flag} alt="Dashboard Icon" /></span>
                                                Report this profile
                                            </Button>
                                        </li>
                                        <li key={1}>
                                            <Button className="remove-btn-bg  btn-link" onClick={() => this.setState({ LoginModal: true, type: '2' })}>
                                                <span className="dashboard-icon"><img src={Block} alt="Dashboard Icon" /></span>
                                                Block {this.state.user.fname} {this.state.user.lname ? this.state.user.lname : ""}
                                            </Button>
                                        </li>
                                    </ul>
                                }
                            </div>
                            <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                            >
                                <Modal.Header closeButton>
                                    {/* <Modal.Title> Send Message </Modal.Title> */}
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        {/* <h5>Login required to perform this action </h5> */}
                                        <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                                    </>
                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.RequestModal} onHide={this.onHide} size="md"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title> Report Profile </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        <AvForm onSubmit={this.ReportProfile}>
                                            <AvGroup>
                                                <AvInput
                                                    type="textarea"
                                                    placeholder="Please identify what's inappropriate about this user profile"
                                                    className="form-control"
                                                    name="message"
                                                    rows={5}
                                                    required />
                                                <AvFeedback>Required Field *</AvFeedback>
                                            </AvGroup>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => this.setState({ RequestModal: false, LoginModal: false })}>Close</Button>
                                                <Button type="submit" variant="primary" >Save Changes</Button>
                                            </Modal.Footer>
                                        </AvForm>
                                    </>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <ToastContainer />
                    </div>
                    : ""
                }
            </>
        );
    }
}

export default Directory;