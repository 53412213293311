import React, { Component } from 'react';
import FindFriend from "../../../assets/images/find-friend.png"
import SendMessage from "../../../assets/images/send-messages.png"

import { Container, Row, Col } from "react-bootstrap";
import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'
import { AiFillCamera } from 'react-icons/ai'

import { Link, useLocation } from 'react-router-dom';
import { uInfo, uToken } from '../../../useToken';
import { ApiUrl } from '../../../config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import RButton from 'react-bootstrap/Button'
import { ToastContainer, toast } from 'react-toastify';
import LoginBox from '../../other/account/LoginBox';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

class Directory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            editPhotoModal: false,
            state: false,
            photeUpdate: false,
            type: 'Friend'
        }
        this.onHide = this.onHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handler1 = this.handler1.bind(this);
    }

    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            })
            if (this.state.type != 'Friend') {
                this.setState({
                    RequestModal: true,
                })
            }
        }
    }

    onHide() {
        this.setState({ RequestModal: false, LoginModal: false });
    }

    handleSubmit(event, errors, values) {
        var self = this;
        if (errors.length < 1) {
            values.user_id = this.props.userID;
            axios.post(ApiUrl + 'sendUserMessage', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({ RequestModal: false, LoginModal: false });
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    componentDidMount() {
        this.getProfile();
    }

    addFriend = () => {
        var self = this;
        const id = this.props.userID;

        let data = {
            user_id: this.props.userID,
            name: this.state.user.fname + ' ' + this.state.user.lname,
            image: this.state.user.image
        }
        axios.post(ApiUrl + 'addFriend', data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addFollow = () => {
        var self = this;
        const id = this.props.userID;

        let data = {
            user_id: this.props.userID,
        }
        axios.post(ApiUrl + 'addToFollow', data, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getProfile();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getProfile = () => {
        var self = this;
        const id = this.props.userID;
        axios.post(ApiUrl + 'getUserProfile', { user_id: id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        user: res.data.user,
                        state: true,
                        photeUpdate: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    render() {
        const id = this.props.userID;
        return (
            <>
                <Container>
                    <Row>
                        <ToastContainer />
                        <Col lg={12} md={12} id="user-picture">

                            <div className="user-profile">
                                <img src={this.state.user.image} alt="User" />
                            </div>
                            <div className="user-information pt-0">
                                <div className="user-name follow-button">
                                    {
                                        this.state.user.id != uInfo().id && (
                                            !uToken() ?
                                                <RButton onClick={() => this.setState({ LoginModal: true, type: 'Friend' })}
                                                    type="button" className="btn " variant="primary">
                                                    Follow
                                                </RButton>

                                                :
                                                this.state.user.is_following == 1 ?
                                                    <RButton
                                                        type="button" disabled={true} className="btn" variant="primary">
                                                        Following
                                                    </RButton>
                                                    :
                                                    <RButton onClick={() => this.addFollow()}
                                                        type="button" className="btn" variant="primary">
                                                        Follow
                                                    </RButton>
                                        )

                                    }
                                    <h2>{this.state.user.fname} {this.state.user.lname}</h2>
                                    <h6>{this.state.user.address}</h6>
                                </div>
                                <ul className="UserSocialInfo">
                                    <li><FaUserFriends /> <strong>{this.state.user.total_friends}</strong> Friends</li>
                                    <li><span><GoStar /></span> <strong>{this.state.user.reviews}</strong> Reviews</li>
                                    <li><AiFillCamera /> <strong>{this.state.user.images}</strong> Photos</li>
                                </ul>
                            </div>

                            {
                                id == uInfo().id ? ""
                                    :
                                    <div className="uploads-update">
                                        <ul className="uploads user-2">


                                            <li>
                                                <div className="upload-photos">

                                                    {(!uToken()) ?
                                                        <>

                                                            <RButton onClick={() => this.setState({ LoginModal: true, type: 'Friend' })}
                                                                type="button" className="remove-btn-bg " variant="none">
                                                                <img src={FindFriend} alt="User" /> Add Friends
                                                            </RButton>

                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                this.state.user.friend_status == 4 ?
                                                                    < RButton onClick={() => this.addFriend()}
                                                                        type="button" disabled={this.state.user.block == 1 || this.state.user.report == 1 ? true : false} className="remove-btn-bg " variant="none">
                                                                        <img src={FindFriend} alt="User" />  Add Friends
                                                                    </RButton>

                                                                    :

                                                                    this.state.user.friend_status == 0 ?
                                                                        <RButton
                                                                            type="button" disabled className="remove-btn-bg " variant="none">
                                                                            <img src={FindFriend} alt="User" />   Request canceled
                                                                        </RButton>

                                                                        :
                                                                        this.state.user.friend_status == 1 ?
                                                                            <RButton
                                                                                type="button" disabled className="remove-btn-bg " variant="none">
                                                                                <img src={FindFriend} alt="User" />   You’re friends
                                                                            </RButton>

                                                                            :
                                                                            this.state.user.friend_status == 2 ?
                                                                                <RButton
                                                                                    type="button" disabled className="remove-btn-bg " variant="none">
                                                                                    <img src={FindFriend} alt="User" />   Request pending
                                                                                </RButton>
                                                                                : ""


                                                            }
                                                        </>
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <div className="upload-photos">

                                                    {(!uToken()) ?
                                                        <>

                                                            <RButton onClick={() => this.setState({ LoginModal: true, type: 'Message' })}
                                                                type="button" className="remove-btn-bg " variant="none">
                                                                <img src={SendMessage} alt="User" /> Send Message
                                                            </RButton>

                                                        </>
                                                        :
                                                        <>

                                                            <RButton onClick={() => this.setState({ RequestModal: true })}
                                                                type="button" disabled={this.state.user.block == 1 || this.state.user.report == 1 ? true : false} className="remove-btn-bg" variant="none">
                                                                <img src={SendMessage} alt="User" />   Send Message
                                                            </RButton>
                                                        </>
                                                    }
                                                    {/* <Link to="#" ></Link> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                            }
                            <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title> Send Message </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        {/* <h5>Login required to perform this action </h5> */}
                                        <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                                    </>
                                </Modal.Body>
                            </Modal>

                            <Modal show={this.state.RequestModal} onHide={this.onHide} size="md"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title> Send Message </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        <AvForm onSubmit={this.handleSubmit}>
                                            <AvGroup>
                                                <AvInput
                                                    type="textarea"
                                                    placeholder="Enter message here"
                                                    className="form-control"
                                                    name="message"
                                                    required />
                                                <AvFeedback>Required Field *</AvFeedback>
                                            </AvGroup>
                                            <Modal.Footer>
                                                {/* <RButton variant="secondary" onClick={() => this.setState({ RequestModal: false, LoginModal: false })}>Close</RButton> */}
                                                <RButton type="submit" variant="primary" >Send Message</RButton>
                                            </Modal.Footer>
                                        </AvForm>
                                    </>
                                </Modal.Body>
                            </Modal>
                        </Col>



                    </Row>
                </Container>



            </>
        );
    }
}

export default Directory;