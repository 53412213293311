import React, {Component} from 'react';

class WidgetSortBy extends Component {
    state = {
        title: 'Neighborhoods',
        items: [
            {
                id: 1,
                title: 'Outer Sunset'
            },
            {
                id: 2,
                title: 'Forest Hill'
            },
            {
                id: 3,
                title: 'Stonestown'
            },
            {
                id: 4,
                title: 'Inner Richmond'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>
                    
                    <div className="check-box-list filter-category mt-4">

                        {this.state.items.map(item => {
                            return (
                                <div className="custom-checkbox" key={item.id}>
                                    <input type="checkbox" id={'chb3-'+item.id} />
                                    <label htmlFor={'chb3-'+item.id}>
                                        {item.title}
                                    </label>
                                </div>
                            )
                        })}

                    </div>
                    <div id="showfilterbyfeature" className="showmore-btn filter">
                        <span className="showmore-txt ">See all</span>
                        <span className="lessmore-txt d-none">Show Less</span>
                    </div>
                </div>
            </>
        );
    }
}

export default WidgetSortBy;