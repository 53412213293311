import React, { Component } from 'react';
import { FiRefreshCw, FiChevronRight } from "react-icons/fi";
import NavbarListing from "../../components/common/NavbarListing";
import PlaceListing from "../../components/places/PlaceListing";
import ListingListSidebar from "../../components/sidebars/ListingListSidebar";
import Button from "../../components/common/Button";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from '../../assets/images/bread-bg.jpg';
import MapViewCluster from "../../components/contact/MapViewCluster";
import { getGeoInfo } from '../../useToken';
import { ApiUrl } from '../../config';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader';
import { Container, Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import DocumentMeta from 'react-document-meta';

class ListingList extends Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this)
        this.handler1 = this.handler1.bind(this)
        this.child = React.createRef();
        this.child1 = React.createRef();
        this.state = {
            data: '',
            filters: [],
            all_features: [],
            suggested: [],
            neighborhoods: [],
            businesses: [],
            search_data: {},
            externalData: false,
            next: "",
            previous: "",
            limit: 1,
            pageNumber: 1,
            currentPage: 1,
            totalpage: "",
            defaultSearch: '',
            defaultLocation: '',
            loaded: false,
            sortFilter: 'Default',
            handlerCall: false,
            map_lat: 39.9525839,
            map_long: -75.1652215,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handleSortFilter = this.handleSortFilter.bind(this);
    }


    handler1(e) {
        var neighborhoods_filter = e.neighborhoods.filter(function (e2) {
            return e2 != null;
        });

        var suggestion = e.suggestion.filter(function (e4) {
            return e4 != null;
        });

        var features = e.features.filter(function (el) {
            return el != null;
        });

        console.log(e, 'features')

        var prices = e.price.filter(function (e3) {
            return e3 != null;
        });

        var datam = {
            'search': this.state.search_data.search,
            'location': this.state.search_data.location,
            'lat': this.state.search_data.lat,
            'lng': this.state.search_data.lng,
            'category_id': this.state.search_data.category_id,
            'sub_category_id': this.state.search_data.sub_category_id,
            'features': features,
            'neighborhood': neighborhoods_filter,
            'suggested': suggestion,
            'distance': e.distance,
            'amount_value': prices,
            'sort': this.state.search_data.sort
        }

        // console.log(datam , 'all')

        this.getBusinessList(datam);
    }

    handleSortFilter = (e) => {
        this.setState({
            sortFilter: e,
        })

        var datam = {
            'search': this.state.search_data.search,
            'location': this.state.search_data.location,
            'lat': this.state.search_data.lat,
            'lng': this.state.search_data.lng,
            'category_id': this.state.search_data.category_id,
            'sub_category_id': this.state.search_data.sub_category_id,
            'features': this.state.search_data.features,
            'neighborhood': this.state.search_data.neighborhood,
            'suggested': this.state.search_data.suggested,
            'distance': this.state.search_data.distance,
            'amount_value': this.state.search_data.amount_value,
            'sort': e
        }
        this.getBusinessList(datam);
    }

    handler(e) {
        $('input[type="checkbox"]:checked').prop('checked', false);
        $('input[type="radio"]:checked').prop('checked', false);

        let completeLocation = e.location;
        var lastIndex = completeLocation.lastIndexOf(",")
        var s1 = completeLocation.substring(0, lastIndex);

        if (e.search != '' && e.search != undefined) {
            this.setState({
                data: e,
                defaultSearch: (e.search != '') ? e.search : "",
                title: 'Best ' + e.search + ' near ' + completeLocation,
                defaultLocation: completeLocation,
                handlerCall: true
            })
            window.history.pushState({}, "", window.location.origin + '/listing/' + e.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase() + '/' + completeLocation.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/"));
        } else {
            this.setState({
                data: e,
                defaultSearch: "",
                defaultLocation: completeLocation,
                title: 'Best places near ' + completeLocation,
                handlerCall: true
            })
            window.history.pushState({}, "", window.location.origin + '/listing/all/' + completeLocation.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/"));
        }

        var datam = {};

        if (e.search != '') {
            datam = {
                'search': e.search,
                'location': e.location,
                'category_id': '',
                'sub_category_id': '',
                'lat': this.state.search_data.lat,
                'lng': this.state.search_data.lng,
            }
        } else {
            datam = {
                'search': e.search,
                'location': e.location,
                'category_id': '',
                'sub_category_id': '',
                'lat': this.state.search_data.lat,
                'lng': this.state.search_data.lng,
            }
        }

        this.getBusinessList(datam);
    }

    state = {
        breadcrumbimg: breadcrumbimg,
        title: 'Browsing Philadelphia, PA Businesses',
        metaTitle: 'The Best Place Near Philadelphia, PA - Katika ',
        text: 'All Results',
        breadcrumb_city: '',
        breadcrumb_category: '',
        breadcrumb_subcategory: '',

    }



    componentDidMount() {
        var sLocation = JSON.parse(localStorage.getItem('location'));
        let completeLocation = '';
        if (sLocation) {
            completeLocation = sLocation.city + ', ' + sLocation.region;
        } else {
            completeLocation = 'Philadelphia, PA';
        }

        if (this.props.match.params.id != undefined) {
            this.setState(
                {
                    defaultSearch: this.props.match.params.id.replace('-and-', ' & ').replace('-', ' '),
                    defaultLocation: this.props.match.params.loc.replace('-', ' ') + ', ' + this.props.match.params.state.toUpperCase(),
                    title: 'Browsing ' + this.props.match.params.id.replace('-and-', ' & ').replaceAll('-', ' ') + ' near ' + this.props.match.params.loc.replace('-', ' ') + ', ' + this.props.match.params.state.toUpperCase(),
                    metaTitle: 'The Best ' + this.props.match.params.id.replace('-and-', ' & ').replace('-', ' ') + ' Near ' + this.props.match.params.loc.replace('-', ' ') + ', ' + this.props.match.params.state.toUpperCase(),
                }
            );
        } else {
            this.setState(
                {
                    defaultSearch: '',
                    title: 'Best place' + ' near ' + completeLocation + ', ' + this.props.match.params.state.toUpperCase(),
                    metaTitle: 'The Best Place Near' + this.props.match.params.loc.replace('-', ' ') + ', ' + this.props.match.params.state.toUpperCase() + '- Katika',
                    defaultLocation: completeLocation + ', ' + this.props.match.params.state.toUpperCase(),
                }
            );
        }



        // if (this.props.location.state && this.props.location.state.category && this.state.defaultSearch == '') {
        //     // let completeLocation = this.props.location.state.location;
        //     // var lastIndex = completeLocation.lastIndexOf(",")
        //     // var s1 = completeLocation.substring(0, lastIndex);
        //     this.setState(
        //         { title: 'Best ' + this.props.location.state.category + ' near ' + completeLocation }
        //     );
        // } else if (this.props.location.state && sLocation) {

        //     this.setState(
        //         {
        //             defaultSearch: this.props.location.state.search != 'All' ? this.props.location.state.search : '',
        //             defaultLocation: completeLocation,
        //             title: this.props.location.state.search != 'All' ? 'Browsing ' + this.props.location.state.search + ' near ' + completeLocation : 'Best places' + ' near ' + completeLocation
        //         }
        //     );
        // }
        // else {
        //     let last = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1).replace('-', ' ');

        //     if (last != 'listing') {

        //         this.setState(
        //             {
        //                 title: 'Browsing ' + last + ' near ' + completeLocation,
        //                 defaultLocation: completeLocation,
        //             })
        //     } else {
        //         // var Index = location.lastIndexOf(",")
        //         // var s2 = location.substring(0, Index);
        //         this.setState(
        //             { title: 'Browsing ' + completeLocation + ' Businesses', defaultLocation: completeLocation, }
        //         );
        //     }


        // }
        this.getBusinessList();
    }

    sendRequest = (count) => {
        // this.setState({pageNumber: count});
        // console.log('new' , this.state.pageNumber)
        this.getBusinessList(this.state.search_data, count);
    }
    getBusinessList = async (datam, count) => {

        var self = this;
        self.setState({
            externalData: false,
            loaded: false,
        })
        var data = {};
        if (datam) {
            data = datam;
        } else if (this.props.location.state || this.props.match.params) {

            if (this.props.location.state) {
                data = {
                    'search': this.props.location.state.search != 'all' ? this.props.location.state.search : '',
                    'location': this.props.location.state.location,
                    'category_id': this.props.location.state.category_id,
                    'sub_category_id': this.props.location.state.sub_category_id,
                    'lat': this.props.location.state.lat,
                    'lng': this.props.location.state.lng,
                };

                console.log('All Here', data)
            }


            if (this.props.match.params.id != undefined) {
                data.search = this.props.match.params.id != 'all' ? this.props.match.params.id.replace('-and-', ' & ').replace('-', ' ') : '';
                data.location = this.props.match.params.loc.replaceAll('-', ' ') + ', ' + this.props.match.params.state.toUpperCase();
            }
        } else {
            let last = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1).replace('-', ' ');
            let search = '';
            if (last != 'listing') {
                search = last
            }

            data = {
                'search': search,
                'location': getGeoInfo(),
                'category_id': '',
                'sub_category_id': '',
                'lat': 39.9525839,
                'lng': -75.1652215,
            };
        }

        // console.log('as',data)

        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        // console.log(data)
        await axios.post(ApiUrl + `searchBusiness?page=${pg}&limit=10`, data, {})
            .then(function (res) {
                // console.log(data)

                self.setState({
                    filters: res.data.filters,
                    all_features: res.data.all_features,
                    suggested: res.data.suggested,
                    businesses: res.data.lists,
                    neighborhoods: res.data.neighborhoods,
                    search_data: data,
                    externalData: true,
                    totalpage: res.data.total_pages,
                    currentPage: res.data.current_page,
                    breadcrumb_city: res.data.city,
                    breadcrumb_category: res.data.category,
                    breadcrumb_subcategory: res.data.sub_category,
                    handlerCall: false,
                    loaded: true,
                    map_lat: res.data.map_lat,
                    map_long: res.data.map_long,
                })
                // $('html, body').animate({ scrollTop: 0 }, 1000);
            })
            .catch(function (error) {
                self.setState({
                    filters: [],
                    all_features: [],
                    suggested: [],
                    businesses: [],
                    neighborhoods: [],
                    search_data: data,
                    totalpage: 0,
                    externalData: true,
                    loaded: true
                })
                console.log(error);
            });
    }

    render() {
        console.warn(this.props, '123465')
        // let last = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1).replace('-', ' ');
        // let index = '';
        // if (last != 'listing') {
        //     index = last
        // }  

        var div_top = 0;
        $(window).scroll(function () {
            console.log('listing-scroll')
            var window_top = $(window).scrollTop() - 0;
            if (window_top > div_top) {
                // $('.listin_nav').addClass('navbar-none');
                if (!$('.map-side').is('.sticky')) {
                    $('.map-side').addClass('sticky');

                }
            } else {
                $('.map-side').removeClass('sticky');
                // $('.listin_nav').removeClass('navbar-none');
            }
        });



        return (
            <div className="application">
                <Helmet>
                    <title>{`${this.state.metaTitle}`}</title>
                    <meta name="title" content={`${this.state.metaTitle}`}></meta>
                    <meta property="og:title" content={`${this.state.metaTitle}`} />
                    <meta property="og:description"
                        content={`${this.state.metaTitle}`} />
                    <meta property="og:image" content='https://katika-images.s3.amazonaws.com/profile/katika-default-logo1613972445.jpg' />
                </Helmet>
                <main className="listing-list">
                    <Loader loaded={this.state.loaded}></Loader>
                    {/* Header */}
                    <NavbarListing
                        defaultSearch={this.state.defaultSearch}
                        state={this.props.match ? this.props.match.params : "saasas"}
                        defaultLocation={this.state.defaultLocation}
                        handler={this.handler}
                        url={this.state.defaultSearch} />

                    {/* Breadcrumb 
                <Breadcrumb CurrentPgTitle="Listing List" MenuPgTitle="Listings" img={this.state.breadcrumbimg} />
*/}
                    {/* Place List */}
                    <section className="card-area">
                        <div className="container-fluid">
                            <div className="row">
                                {/*<div className="col-lg-12">
                                <GenericHeader />
                            </div>*/}
                                <div className="ListingLeftSide">
                                    <div className="ListingBox">
                                        <div className="side-bar-left">
                                            <div className="col-lg-12">

                                                <ListingListSidebar handler1={this.handler1}
                                                    handlerCall={this.state.handlerCall}
                                                    filters={this.state.filters}
                                                    all_features={this.state.all_features}
                                                    suggested={this.state.suggested}
                                                    neighborhoods={this.state.neighborhoods}
                                                    state={this.props.location ? this.props.location.state : ""}
                                                />

                                            </div>
                                        </div>
                                        <div className="b-listing">

                                            <div className="col-lg-12">
                                                <h6 className="pb-1">
                                                    {
                                                        (this.state.search_data.category_id != undefined && this.state.search_data.category_id != '') ?
                                                            (this.state.breadcrumb_category != '' && this.state.breadcrumb_subcategory != '') ?
                                                                <>
                                                                    {this.state.breadcrumb_city} &nbsp; <FiChevronRight /> &nbsp; {this.state.breadcrumb_category} &nbsp; <FiChevronRight />
                                                                    &nbsp; {this.state.breadcrumb_subcategory}
                                                                </>
                                                                : (this.state.breadcrumb_category != '' && this.state.breadcrumb_subcategory == '') ?
                                                                    <>
                                                                        {this.state.breadcrumb_city} &nbsp; <FiChevronRight /> &nbsp; {this.state.breadcrumb_category}
                                                                    </>
                                                                    : (this.state.breadcrumb_category == '' && this.state.breadcrumb_subcategory == '' && this.state.defaultSearch != '') ?
                                                                        <>
                                                                            {this.state.breadcrumb_city} &nbsp; <FiChevronRight /> &nbsp; {this.state.defaultSearch}
                                                                        </>
                                                                        : ""
                                                            : ""
                                                    }</h6>
                                                <div className="sort-by">
                                                    <div className="sorted">
                                                        <Dropdown onSelect={this.handleSortFilter}>
                                                            <label>Sort by:&nbsp;</label>
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                <strong>{this.state.sortFilter}</strong>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item eventKey="Default">Default</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Most Reviewed">Most Reviewed</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Heighest Rated">Heighest Rated</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <h2 className="heading-listing">{this.state.title}</h2>

                                                <h2 className="all-result">{this.state.text}</h2>
                                                {(this.state.externalData) ? <PlaceListing businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}
                                                {/* <PlaceListing onRef={ref => (this.child = ref)} state={this.props.location.state} /> */}

                                                <div className="col-lg-12 text-center">
                                                    {
                                                        this.state.businesses.length < 1 ?
                                                            <p className="text-danger padding-top-80px"> No search  result found </p>
                                                            :
                                                            ""
                                                    }
                                                    {


                                                        (this.state.totalpage > 1) ?
                                                            <>
                                                                {
                                                                    this.state.currentPage > 1 ? (
                                                                        <button onClick={() => this.sendRequest(this.state.currentPage - 1)} className="theme-btn border-0 mr-2" >
                                                                            <span className="">
                                                                                <span className="icon-label">Prev</span></span>
                                                                        </button>
                                                                    )
                                                                        :
                                                                        <button disabled className="theme-btn border-0 mr-2" >
                                                                            <span className="">
                                                                                <span className="icon-label">Prev</span></span>
                                                                        </button>

                                                                }


                                                                {Array.from(Array(this.state.totalpage), (e, i) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                i >= this.state.currentPage - 1 && i <= this.state.currentPage + 3 && (
                                                                                    <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                                                        <span className="">
                                                                                            <span className="icon-label">{i + 1}</span></span>
                                                                                    </button>
                                                                                )
                                                                            }


                                                                        </>
                                                                    );

                                                                })}
                                                                {
                                                                    this.state.totalpage != this.state.currentPage + 3 ? (
                                                                        <button onClick={() => this.sendRequest(this.state.currentPage + 1)} className="theme-btn border-0 mr-2" >
                                                                            <span className="">
                                                                                <span className="icon-label">Next</span></span>
                                                                        </button>
                                                                    )

                                                                        :

                                                                        <button disabled className="theme-btn border-0 mr-2" >
                                                                            <span className="">
                                                                                <span className="icon-label">Next</span></span>
                                                                        </button>
                                                                }
                                                            </>

                                                            : ""
                                                    }



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="map-side">

                                    {(this.state.externalData) ? <MapViewCluster map_long={this.state.map_long} map_lat={this.state.map_lat} businesses={this.state.businesses} searchdata={this.state.search_data} /> : ""}
                                    {/* <MapViewCluster onRef={ref => (this.child1 = ref)} state={this.props.location.state} /> */}

                                </div>


                            </div>

                        </div>
                    </section>

                    {/* Newsletter 
                <NewsLetter />*/}

                    {/* Footer */}
                    <Footer />

                    <ScrollTopBtn />
                </main>
            </div>
        );


    }
}

export default ListingList;