import React, { Component } from "react"
import { compose } from "recompose"
import markericon from '../../assets/images/katika_map_marker.png'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps"
import { AutoApiKey } from "../../config"

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    var loc = JSON.parse(localStorage.getItem('location'));
    let lat = 40.712776;
    let lng = -74.005974;
    if (loc) {
        lat = loc.latitude;
        lng = loc.longitude;
    }
    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: lat, lng: lng }}
            onLoad={map => (this._map = map)}
            defaultOptions={{
                disableDefaultUI: false, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: false, // allow scale control
                scrollwheel: true, // allow scroll wheel
                styles: [
                    {
                        "featureType": "road",
                        "stylers": [
                            { "color": "#ffffff" }
                        ]
                    }, {
                        "featureType": "landscape",
                        "stylers": [
                            { "color": "#f5f5f5" }
                        ]
                    }, {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            { "color": "transparent" }
                        ]
                    }, {
                        "featureType": "poi",
                        "stylers": [
                            { "color": "#fefefe" }
                        ]
                    }, {
                        "elementType": "labels.text",
                        "stylers": [
                            { "saturation": 1 },
                            { "weight": 0.1 },
                            { "color": "#737980" }
                        ]
                    }
                ],
                icon: markericon
            }}
        >
            {props.markers.map(marker => {
                const onClick = props.onClick.bind(this, marker)
                return (
                    <Marker
                        key={marker.id}
                        onClick={onClick}
                        icon={{
                            url: markericon
                        }}
                        animation={1}
                        position={{ lat: marker.lat, lng: marker.long }}

                    >
                        {props.selectedMarker === marker &&
                            <InfoWindow>
                                <div style={{ color: "#000" }}>
                                    {marker.business_name}
                                </div>
                            </InfoWindow>}
                    </Marker>

                )
            })}


        </GoogleMap>
    )
})

export default class ShelterMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shelters: [
                {
                    latitude: 40.6653482,
                    longitude: -73.939501,
                    shelter: "test 1"
                },
                {
                    latitude: 40.63482,
                    longitude: -73.939501,
                    shelter: "test 2"
                }
            ],
            selectedMarker: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            shelters: newProps.businesses,
        });
    }

    componentDidMount() {
        this.setState({
            shelters: this.props.businesses,
        });
    }

    handleClick = (marker, event) => {
        this.setState({ selectedMarker: marker })
    }
    render() {
        return (
            <div className="map-container map-height ">
                <MapWithAMarker
                    selectedMarker={this.state.selectedMarker}
                    markers={this.state.shelters}
                    onClick={this.handleClick}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AutoApiKey}&libraries`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div className="MapArea" style={{ height: "calc(100vh - 131px)", marginTop: "0", top: "0px" }} />}
                    mapElement={<div className="MapBox" style={{ height: `100%` }} />}

                />
            </div>
        )
    }
}