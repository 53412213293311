import React, { Component } from 'react';
import LoginBoxPage from "../components/other/account/LoginBoxPage";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg';
import { Link } from "react-router-dom";
import ImageSignUp from '../assets/images/katika_signup.png';
import Logo from "../components/business/Logo";
import Logosrc from '../assets/images/katika-logo_wht.svg';
import { Container, Row, Col } from "react-bootstrap";

class Login extends Component {
    state = {
        SignUpImg: ImageSignUp,
    }
    render() {

        const { setToken } = this.props;
        const { goFarward } = this.props;
        return (
            <main className="login-page">
                <div className="SignUpTopBar">
                    <Container>
                        <Row>
                            <div className="col-lg-12">
                                <div className="logo listing center ">
                                    <Logo url={Logosrc} />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
                {/* Header */}
                {/* <GeneralHeader />  */}

                {/* Breadcrumb */}
                {/*    <Breadcrumb CurrentPgTitle="Login" img={this.state.breadcrumbimg} />  */}

                {/* <div className="logo">
                                             <Logo url={logosrc} />
                                        </div> */}


                {/* <section className="form-shared login-page padding-top-40px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-4 signup-picture">
                                <div className="login-detais">
                                    <h5>Inclusion made easy.<sup><small>TM</small></sup></h5>
                                    <p className="muted">No filters or labels required.</p>
                                </div>
                                <div className="user-img-place">
                                    <img key={0} src={this.state.SignUpImg} alt="SignUpImage" className="random-img" />
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="login-top">
                                    <LoginBoxPage goFarward={goFarward} setToken={setToken} title="Sign in to Katika" subtitle="" />
                                </div>
                            </div>

                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section> */}
                <Container>
                    <Row>
                        <Col lg={6} className="offset-lg-3">
                            <div className='SignUpSelection LoginSection padding-top-100px padding-bottom-100px padding-left-40px padding-right-40px margin-top-70px text-center margin-bottom-100px'>
                                <h3 className='color-black'>Login in to Katika</h3>
                                <LoginBoxPage goFarward={goFarward} setToken={setToken} title="Sign in to Katika" subtitle="" />
                                <Link to={`sign-up`} className='btn btn-outline-primary signupBtn mb-4 padding-right-30px padding-left-30px'>Sign Up</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Newsletter 
                <NewsLetter />*/}

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default Login;