import React, { Component } from 'react';
import Weavaillble from "../../../assets/images/Weavaillble.png";

class WidgetOpenHours extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: 'Opening Hours',
            hours: this.props.state,
            online: this.props.online,

            items: [
                {
                    day: 'Mon',
                    time: 'Closed',
                    close: false,
                    today: false,
                },
                {
                    day: 'Tue',
                    time: '5:00 PM - 9:30 PM',
                    close: false,
                    today: false,
                },
                {
                    day: 'Wed',
                    time: '5:00 PM - 9:30 PM',
                    close: false,
                    today: false,
                },
                {
                    day: 'Thu',
                    time: '5:00 PM - 9:30 PM',
                    close: false,
                    today: false,
                },
                {
                    day: 'Fri',
                    time: '5:00 PM - 9:30 PM',
                    close: false,
                    today: true,
                },
                {
                    day: 'Sat',
                    time: '5:00 PM - 9:30 PM',
                    close: false,
                    today: false,
                }
                ,
                {
                    day: 'Sun',
                    time: 'Closed',
                    close: false,
                    today: false,
                }
            ]
        }
    }


    render() {
        return (
            <>
                <div className="sidebar-widget single-h">
                    <div className="opening-hours">
                        {
                            this.state.online == 'No' ?

                                <div className="online-business-images">
                                    <img src={Weavaillble} />
                                </div>
                                :
                                <>
                                    <ul className="list-items">
                                        {this.state.hours.map((item, i) => {
                                            return (
                                                <li key={i} className="d-flex days_time">
                                                    <div className="days"> {item.day}</div>
                                                    <strong className={item.close ? 'color-text' : 'font-weight-medium'}>{item.close ? item.start_time : item.start_time + `${item.start_time != '' ? ' - ' : ''}` + item.end_time}
                                                        <span className="closed-now">{item.today ? "Closed Now" : ""}</span>
                                                    </strong>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                        }

                    </div>
                </div>
            </>
        );
    }
}

export default WidgetOpenHours;