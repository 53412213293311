import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Reservations from "../../../assets/images/bi_calendar-event.png";
import TakeOut from "../../../assets/images/TakeOut.png";
import Waiter from "../../../assets/images/fa-solid_concierge-bell.png";
import Delivery from "../../../assets/images/dashicons_car.png";
import Wi_Fi from "../../../assets/images/ant-design_wifi-outlined.png";
import Credit_Cards from "../../../assets/images/bx_bx-check.png";
import Seating from "../../../assets/images/ic_twotone-airline-seat-recline-normal.png";
import Alcohol from "../../../assets/images/fa-solid_glass-martini-alt.png";


class WidgetCategory extends Component {
    state = {
        title: 'Certifications',
        lists: [
            {
                icon: Reservations,
                cat: 'Takes Reservations',
                url: '#'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="sidebar-widget certification">
                    <h3 className="widget-title">
                        {this.state.title}
                    </h3>
                    <div className="cat-list padding-top-0px">
                        <div className="info-list static-info padding-top-0px">
                            <ul>
                                {this.props.business.business_certificates.map((item, i) => {
                                    return (
                                        <li className="justify-content-between" key={i}>
                                            <div className="">
                                                <a href="#"  >{item.title} </a>
                                                <p>{item.description}</p>
                                                <span className="">{item.year}</span>
                                            </div>

                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WidgetCategory;