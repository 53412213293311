import React, { Component } from 'react'
import Navbar from './Navbar'
import { Link } from "react-router-dom";


export default class GeneralHeader extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <Navbar />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
