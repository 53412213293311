import React, { Component } from 'react';
import { BsMap } from "react-icons/bs";
import { GiChickenOven } from "react-icons/gi";
import { AiOutlineEllipsis } from "react-icons/ai";
import work1 from '../../assets/images/iPhone.png'
import work2 from '../../assets/images/Android.png'




class BrowseCategories extends Component {
    infoboxState = {
        items: [
            {
                active: false,
                icon: <BsMap />,
                img: work1,
                title: 'Katika for iOS',
                url: "https://apps.apple.com/us/app/katika/id1595089103"
            },
            {
                active: false,
                icon: <GiChickenOven />,
                img: work2,
                title: 'Katika for Android',
                url: "https://play.google.com/store/apps/details?id=com.katikabuyer"
            }
        ]
    }
    render() {
        return (
            <>
                <div className="katika-app text-center">
                    <div className="container">
                        <h2>Download Katika Mobile Apps</h2>
                        <div className="row mt-5">
                            {this.infoboxState.items.map((item, index) => {
                                return (
                                    <div className="col-lg-6 column-td-6" key={index}>
                                        <a href={item.url} target="_blank">
                                            <div className={item.active ? 'icon-box icon-box-layout info-more-content' : 'icon-box icon-box-layout'}>

                                                <div className="info-content">
                                                    <h4 className="info__title">
                                                        {item.title}
                                                    </h4>
                                                    <div className="info-icon mb-0">
                                                        <span>
                                                            <img className="img-thumbnail card__img" width="100%" height="100%" src={item.img}  />
                                                            {/* {item.img} */}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BrowseCategories;